/**
 * 保司运行月报
 */
import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row, Select} from 'antd';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  ToolboxComponent,
  TooltipComponent
} from 'echarts/components';
import {BarChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import {UniversalTransition} from 'echarts/features';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import {fleetRiskMonthReportExportRequestPath, fleetRiskMonthReportRequest} from '@/service/fleetRisk';
import styles from './index.module.scss';
import {insureListRequest} from "@/service/insurerManage";
import { getChannelListRequest } from '@/service/dataDepartment';
import { channelListRequest } from '@/service/channelRecode';

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  BarChart,
  CanvasRenderer,
  ToolboxComponent,
  UniversalTransition
]);

const FleetRiskMonth = () => {
  const [data, setData] = useState<any>({}); // 报告相关数据
  const [channelName, setChannelName] = useState<any>({}); // 渠道名称
  const [carEvaluate, setCarEvaluate] = useState<any>([]); // 设备安装数据
  const [carEvaluateTwo, setCarEvaluateTwo] = useState<any>([]); // 设备运维数据
  const [searchMonth, setSearchMonth] = useState<any>({current: '', last: ''}); // 储存搜索月份
  const [loading, setLoading] = useState(false); // 是否在获取报告中
  const [form] = Form.useForm();
  const [commercialCompany, setCommercialCompany] = useState<any>([]); // 所有承保保司
  const [selectedInsuranceId,setSelectedInsuranceId] = useState(null)//选中的保司名称
  const [channelData, setChannelData] = useState([]); // 渠道

  // 获取承保保司
  const getCommercialCompany = async () => {
    const res = await insureListRequest('');
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {name,id} = item;
      tags.push({label: name, value: id});
    });
    setCommercialCompany(tags);
  }

  // 获取车队风险报告数据
  const getFleetRiskReport = async (data: any) => {
    try {
      setLoading(true);
      const res = await fleetRiskMonthReportRequest(data);
      const reportData = res.data?.data || {};
      setData(reportData);
      // 设备安装数据
      const carEvaluates = [
        {
          one: reportData?.total?.lastMonthInstall,
          two: reportData?.total?.currentMonthInstall,
          three: reportData?.total?.lastMonthUnstall,
          four: reportData?.total?.currentMonthUnstall,
          five: reportData?.total?.total,
        },
      ]
      setCarEvaluate(carEvaluates)

      // 设备运维数据
      const carEvaluateTwos = [
        {
          one: reportData?.runtimeInfo?.online * 100 + '%',
          two: reportData?.runtimeInfo?.durate,
          three: reportData?.runtimeInfo?.distance,
          four: reportData?.runtimeInfo?.hmw,
          five: reportData?.runtimeInfo?.aeb,
          six: reportData?.runtimeInfo?.ldw,
          seven: reportData?.runtimeInfo?.tir,
          eight: reportData?.runtimeInfo?.workTime * 100 + '%',
          nine: reportData?.runtimeInfo?.maintenans,
          ten: reportData?.runtimeInfo?.damage,
        },
      ]
      setCarEvaluateTwo(carEvaluateTwos)

      // 渲染图表
      renderChart(reportData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 渲染图表
  const renderChart = (data: any) => {
    const oneTimeData: any[] = []
    const oneStandardData: any[] = []
    const oneActualData: any[] = []
    data?.onlineCountDtos?.map((item: any) => {
      oneTimeData.push(item.day + '号')
      oneStandardData.push(item.current)
      oneActualData.push(item.last)
    })
    const OneOptionMonth = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      color: ['#009DFF', '#22E4FF'],
      title: {
        left: 'center',
        text: `${searchMonth.current}月设备在线数量`,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          show: true,
          type: 'cross',
          lineStyle: {
            type: 'dashed',
            width: 1
          }
        },
      },
      legend: {
        left: 'right'
      },
      xAxis: {type: 'category', data: oneTimeData},
      yAxis: {type: 'value', name: '在线数量'},
      series: [
        {
          data: oneActualData,
          type: 'line',
          name: '上月',
        },
        {
          data: oneStandardData,
          type: 'line',
          name: '本月',
        }
      ]
    };
    const OneChartMonth = echarts.init(document.getElementById('OneChartMonth') as HTMLElement)
    OneChartMonth.setOption(OneOptionMonth, true);

    const twoTimeData: any[] = []
    data?.offlineReasonCountDtos?.map((item: any) => {
      item.value = item.count;
      delete item.count;
      twoTimeData.push(item)
    })
    const TwoOptionMonth = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: `${searchMonth.current}月份设备不在线情况汇总`,
      },
      tooltip: {trigger: 'item'},
      legend: {orient: 'vertical', left: 'left'},
      series: [
        {
          name: '离线原因',
          type: 'pie',
          radius: '50%',
          data: twoTimeData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    const TwoChartMonth = echarts.init(document.getElementById('TwoChartMonth') as HTMLElement)
    TwoChartMonth.setOption(TwoOptionMonth, true);

    const threeTimeData: any[] = []
    data?.problemStatisticDtos?.map((item: any) => {
      item.value = item.count;
      delete item.count;
      threeTimeData.push(item)
    })
    const ThreeOptionMonth = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: '运维车辆情况汇总',
      },
      tooltip: {trigger: 'item'},
      legend: {orient: 'vertical', left: 'left'},
      series: [
        {
          name: '问题原因',
          type: 'pie',
          radius: '50%',
          data: threeTimeData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    const ThreeChartMonth = echarts.init(document.getElementById('ThreeChartMonth') as HTMLElement)
    ThreeChartMonth.setOption(ThreeOptionMonth, true);

    const fourTimeData: any[] = []
    const fourStandardData: any[] = []
    const fourActualData: any[] = []
    data?.companyHmwChangeDtos?.map((item: any) => {
      fourTimeData.push(item.name)
      fourStandardData.push(Math.trunc(item.last * 100))
      fourActualData.push(Math.trunc(item.current * 100))
    })
    const FourOptionMonth = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: '前十企业HMW数据变化'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        bottom: '20%'
      },
      legend: {left: 'right'},
      xAxis: {
        type: 'category',
        data: fourTimeData,
        axisLabel: {
          interval: 0,
          formatter: function (value: any) {
            return value.split('').join('\n')
          }
        }
      },
      yAxis: {type: 'value', name: '风险日占比%'},
      series: [
        {
          name: '上月',
          type: 'bar',
          data: fourStandardData
        },
        {
          name: '本月',
          type: 'bar',
          data: fourActualData
        }
      ]
    };
    const FourChartMonth = echarts.init(document.getElementById('FourChartMonth') as HTMLElement)
    FourChartMonth.setOption(FourOptionMonth, true);

    const fiveTimeData: any[] = []
    const fiveStandardData: any[] = []
    const fiveActualData: any[] = []
    data?.companyAebChangeDtos?.map((item: any) => {
      fiveTimeData.push(item.name)
      fiveStandardData.push(Math.trunc(item.last * 100))
      fiveActualData.push(Math.trunc(item.current * 100))
    })
    const FiveOptionMonth = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: '前十企业AEB数据变化'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {left: 'right'},
      grid: {
        bottom: '20%'
      },
      xAxis: {
        type: 'category',
        data: fiveTimeData,
        axisLabel: {
          interval: 0,
          formatter: function (value: any) {
            return value.split('').join('\n')
          }
        }
      },
      yAxis: {type: 'value', name: '风险日占比%'},
      series: [
        {
          name: '上月',
          type: 'bar',
          data: fiveStandardData
        },
        {
          name: '本月',
          type: 'bar',
          data: fiveActualData
        }
      ]
    };
    const FiveChartMonth = echarts.init(document.getElementById('FiveChartMonth') as HTMLElement)
    FiveChartMonth.setOption(FiveOptionMonth, true);

    const sixTimeData: any[] = []
    const sixStandardData: any[] = []
    const sixActualData: any[] = []
    data?.carHmwChangeDtos?.map((item: any) => {
      sixTimeData.push(item.name)
      sixStandardData.push(Math.trunc(item.last * 100))
      sixActualData.push(Math.trunc(item.current * 100))
    })
    const SixOptionMonth = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: '前十车辆HMW数据变化'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {left: 'right'},
      grid: {
        bottom: '20%'
      },
      xAxis: {
        type: 'category',
        data: sixTimeData,
        axisLabel: {
          interval: 0,
          formatter: function (value: any) {
            return value.split('').join('\n')
          }
        }
      },
      yAxis: {type: 'value', name: '风险日占比%'},
      series: [
        {
          name: '上月',
          type: 'bar',
          data: sixStandardData
        },
        {
          name: '本月',
          type: 'bar',
          data: sixActualData
        }
      ]
    };
    const SixChartMonth = echarts.init(document.getElementById('SixChartMonth') as HTMLElement)
    SixChartMonth.setOption(SixOptionMonth, true);

    const sevenTimeData: any[] = []
    const sevenStandardData: any[] = []
    const sevenActualData: any[] = []
    data?.carAebChangeDtos?.map((item: any) => {
      sevenTimeData.push(item.name)
      sevenStandardData.push(Math.trunc(item.last * 100))
      sevenActualData.push(Math.trunc(item.current * 100))
    })
    const SevenOptionMonth = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: '前十车辆AEB数据变化'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {left: 'right'},
      grid: {
        bottom: '20%'
      },
      xAxis: {
        type: 'category',
        data: sixTimeData,
        axisLabel: {
          interval: 0,
          formatter: function (value: any) {
            return value.split('').join('\n')
          }
        }
      },
      yAxis: {type: 'value', name: '风险日占比%'},
      series: [
        {
          name: '上月',
          type: 'bar',
          data: sevenStandardData
        },
        {
          name: '本月',
          type: 'bar',
          data: sevenActualData
        }
      ]
    };
    const SevenChartMonth = echarts.init(document.getElementById('SevenChartMonth') as HTMLElement)
    SevenChartMonth.setOption(SevenOptionMonth, true);
  }

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = {...params};
    delete newParams.time;
    newParams.month = dayjs(params.time).startOf('month').format('YYYY-MM-DD');
    const thisMonth = Number(dayjs(params.time).startOf('month').format('YYYY-MM-DD').slice(5, 7));
    setSearchMonth({current: thisMonth, last: thisMonth - 1 == 0 ? 12 : thisMonth - 1})

    const insurance = commercialCompany.find((item: any) => item.value == newParams.insuranceId)
    newParams.name = insurance?.label || ''

    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    // newSearchParams.name = channelName.name
    // newSearchParams.insuranceId = channelName.insuranceId
    if (newSearchParams) {

      getFleetRiskReport(newSearchParams);
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();

    const newSearchParams = operateSearchParams(values);
    // newSearchParams.name = channelName.name
    // newSearchParams.cateId = channelName.cateId
    return newSearchParams;
  }

  // const changeChannel = (value:any, options: any) => {

//     if (value == 1) {
//       setChannelName({name:options.label,cateId:[1738]})
//     } else if (value == 2) {
//       setChannelName({name:options.label,cateId:[1916,1917,2050,3298]})
//     } else if (value == 3) {
//       setChannelName({name:options.label,cateId:[1852]})
//     }else if (value == 4) {
//       setChannelName({name:options.label,cateId:[3729]})
//     }
//     else if (value == 5) {
//       setChannelName({name:options.label,cateId:[3730]})
//     }else if (value == 6) {
//       setChannelName({name:options.label,cateId:[3752]})
//     }else if (value ==7) {
//     setChannelName({name:options.label,cateId:[1739,1740,2761,4017,4124,4250,4311,4574]})
//     }else if (value == 8) {
//     setChannelName({name:options.label,cateId:[4119,4851]})
//     }
// }


  // 表格列显示
  const columnsOne: any = [
    {
      title: `${searchMonth.last}月总安装数量(台)`,
      dataIndex: 'one',
    },
    {
      title: `${searchMonth.current}月总安装数量(台)`,
      dataIndex: 'two',
    },
    {
      title: `${searchMonth.last}月拆除量(台)`,
      dataIndex: 'three',
    },
    {
      title: `${searchMonth.current}月拆除量(台)`,
      dataIndex: 'four',
    },
    {
      title: '项目服务量(台)',
      dataIndex: 'five',
    },
  ];

  const columnsTwo: any = [
    {
      title: '月度在线率',
      dataIndex: 'one',
      width: 120,
    },
    {
      title: '日均运行时长',
      dataIndex: 'two',
      width: 120,
      render: (value: any) => <div>{Math.trunc(value / 3600)}小时</div>,
    },
    {
      title: '日均公里数',
      dataIndex: 'three',
      width: 120,
    },
    {
      title: '百公里HMW日均触发',
      dataIndex: 'four',
      width: 170,
    },
    {
      title: '百公里AEB日均触发',
      dataIndex: 'five',
      width: 170,
    },
    {
      title: '百公里LDW日均触发',
      dataIndex: 'six',
      width: 170,
    },
    {
      title: '日均疲劳驾驶次数',
      dataIndex: 'seven',
      width: 170,
    },
    {
      title: '日均夜间工作比例',
      dataIndex: 'eight',
      width: 170,
    },
    {
      title: '月度车均运维次数',
      dataIndex: 'nine',
      width: 170,
    },
    {
      title: '月度破坏设备台数',
      dataIndex: 'ten',
      width: 180,
    },
  ];

  const columnsThree: any = [
    {
      title: '企业名称',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: '排名',
      dataIndex: 'rank',
      width: 100,
    },
    {
      title: '百公里HMW日均超标率',
      dataIndex: 'hmw',
      width: 190,
      render: (value: any) => <div>{Math.trunc(value * 100) == 0 ? 0 : Math.trunc(value * 100) + '%'}</div>,
    },
    {
      title: '百公里AEB日均超标率',
      dataIndex: 'aeb',
      width: 190,
      render: (value: any) => <div>{Math.trunc(value * 100) == 0 ? 0 : Math.trunc(value * 100) + '%'}</div>,
    },
    {
      title: '日均疲劳驾驶数',
      dataIndex: 'tir',
      width: 190,
    },
    {
      title: '月度破坏设备运转次数',
      dataIndex: 'damage',
      width: 190,
      render: (value: any) => {
        if (value == null) {
          return 0
        } else {
          return value
        }
      }
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 300,
    },
  ];

  const columnsFour: any = [
    {
      title: '车牌号',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: '排名',
      dataIndex: 'rank',
      width: 100,
    },
    {
      title: '百公里HMW日均超标率',
      dataIndex: 'hmw',
      width: 190,
      render: (value: any) => <div>{Math.trunc(value * 100) == 0 ? 0 : Math.trunc(value * 100) + '%'}</div>,
    },
    {
      title: '百公里AEB日均超标率',
      dataIndex: 'aeb',
      width: 190,
      render: (value: any) => <div>{Math.trunc(value * 100) == 0 ? 0 : Math.trunc(value * 100) + '%'}</div>,
    },
    {
      title: '日均疲劳驾驶数',
      dataIndex: 'tir',
      width: 190,
    },
    {
      title: '月度破坏设备运转次数',
      dataIndex: 'damage',
      width: 190,
      render: (value: any) => {
        if (value == null) {
          return 0
        } else {
          return value
        }
      }
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 300,
    },
  ];

  const channelSelectItem = [
    {label: '中华财险', value: 1},
    {label: '中华财险（北分）', value: 7},
    {label: '中华财险（湖北）', value: 8},
    {label: '京东财险（北分）', value: 2},
    {label: '京东财险（川分）', value: 3},
    {label: '国任保险', value: 4},
    {label: '宁波国任保险', value: 5},
    {label: '四川国任保险', value: 6},
  ]

    // 获取渠道
    const getChannelData = async() => {
      const res = await channelListRequest({ page: 1, limit: 50000, name: "" });;
      const tags: any = [];
      (res.data?.data || []).forEach((item: any) => {
        const { name } = item;
        tags.push({ label: name, value: name });
      });
      setChannelData(tags)
    }


  useEffect(() => {
    getCommercialCompany()
    getChannelData()
  }, []);

  return (
    <Card bordered={false} className={styles.fleetRisk}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="time"
              initialValue={dayjs(`${dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')}`)}
            >
              <DatePicker
                allowClear={false}
                style={{width: '100%'}}
                disabledDate={(currentDate: any) => currentDate && (currentDate < dayjs().subtract(999, 'months') || currentDate >= dayjs().subtract(0, 'months'))}
                picker="month"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="承保保司"
              name="insuranceId"
              rules={[
                {required: true, message: '请选择承保保司'}
              ]}
            >
              <Select placeholder='请选择承保保司' options={commercialCompany} allowClear onChange={(e, options: any) => {setChannelName({name: options.label})}}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="渠道"
              name="channelIds"
            >
              <Select placeholder='请选择承保保司' options={channelData} allowClear mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">搜索</Button>
                  <ExportButton
                    url={fleetRiskMonthReportExportRequestPath}
                    fileName={`${channelName.name}月报`}
                    getParamsFunc={getExportParams}
                    requestMethod="POST"
                    fileSuffix="docx"
                    permissionKey='/car-admin/fleet-risk-month/export'
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <div style={{visibility: isEmpty(data) ? 'hidden' : 'visible'}}>
          <h2>一、安装与运维数据</h2>
          <h3>（一）设备安装数据</h3>
          <CommonTable
            columnFilter={false}
            rowKey="one"
            columns={columnsOne}
            dataSource={carEvaluate}
            pagination={false}
            bordered
            scroll={{y: 400}}
          /><br/>
          <h3>（二）设备运维数据</h3>
          <CommonTable
            columnFilter={false}
            rowKey="one"
            columns={columnsTwo}
            dataSource={carEvaluateTwo}
            pagination={false}
            bordered
            scroll={{y: 400}}
          /><br/>
          <h2>二、设备在线情况展示</h2>
          <div id="OneChartMonth" style={{height: '400px', marginTop: '12px'}}/>
          <h2>三、设备离线率分析</h2>
          <div id="TwoChartMonth" style={{height: '400px', marginTop: '12px'}}/>
          <h2>四、设备维护数据分析</h2>
          <div id="ThreeChartMonth" style={{height: '400px', marginTop: '12px'}}/>
          <h2>五、高风险企业、车辆汇总</h2>
          <h3>{searchMonth.current}月高风险企业汇总</h3>
          <CommonTable
            columnFilter={false}
            rowKey="rank"
            columns={columnsThree}
            dataSource={data.companyMonthRankDto}
            pagination={false}
            bordered
            scroll={{y: 400}}
          /><br/>
          <h3>{searchMonth.current}月高风险车辆汇总</h3>
          <CommonTable
            columnFilter={false}
            rowKey="rank"
            columns={columnsFour}
            dataSource={data.carMonthRankDto}
            pagination={false}
            bordered
            scroll={{y: 400}}
          /><br/>
          <h3>高风险企业数据变化</h3>
          <div id="FourChartMonth" style={{height: '500px', marginTop: '12px'}}/>
          <div id="FiveChartMonth" style={{height: '500px', marginTop: '12px'}}/>
          <br/><br/>
          <h3>高风险车辆数据变化</h3>
          <div id="SixChartMonth" style={{height: '500px', marginTop: '12px'}}/>
          <div id="SevenChartMonth" style={{height: '500px', marginTop: '12px'}}/>
        </div>
      </LoadingWrapper>
    </Card>
  )
}

export default FleetRiskMonth;