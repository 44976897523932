/**
 * 记录仪视频评价
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Radio, Rate, message, Input, Checkbox } from 'antd';
import { tagListRequest } from '@/service/tagManage';
import { commentVideoRequest } from '@/service/driveVideo';
import { getGroupVideoTag } from '@/utils/driveVideo';
import dayjs from 'dayjs';

interface CommentVideoModalProps {
  resourceKey: string;
  closeModal: () => void;
  reloadData?: () => void;
  editDetail?: any; // 编辑评价时的详情数据
}
const CommentVideoModal = (props: CommentVideoModalProps) => {
  const [tagData, setTagData] = useState<any>({}) // 视频评论的标签数据
  const [targetDisabled, setTargetDisabled] = useState<any>(false) // 目标位置、目标行为是否禁用
  const [timelyDisabled, setTimelyDisabled] = useState<any>(false) // 及时性是否禁用
  const [rateRequired, setRateRequired] = useState<any>(true) // 评分是否必选
  const [form] = Form.useForm();
  const { resourceKey, closeModal, reloadData, editDetail } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
 
  useEffect(() => {
    getTagList();
    setEditValue();
  }, []);
  
  // 获取评价视频需要使用的标签
  const getTagList = async() => {
    const res = await tagListRequest();
    const tagData = res.data?.data || [];
    setTagData(getGroupVideoTag(tagData))
  }

  // 编辑时赋值
  const setEditValue = () => {
    if (editDetail) {
      form.setFieldsValue(JSON.parse(editDetail.comment || '{}'))
    }
  }

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      // 评论字段不是标签内容，所以不当做标签处理
      delete params.comment;
      // 特殊处理评分字段
      if (!values.rate) {
        values.rate = 0;
      }
      const matchRate = (tagData.rateTag || []).find((item: any) => (item.label || '').includes(values.rate));
      params.rate = matchRate.value;
      const requestParams: any = {
        resourceKey,
        tagIds: Object.values(params).flat(Infinity).filter((item: any) => Boolean(item)),
        comment: JSON.stringify(values),
      }
      // 编辑时加额外参数
      if (editDetail) {
        requestParams.id = editDetail.id;
        requestParams.optUser = editDetail.userId;
        requestParams.updatedAt = editDetail.updatedAt ? dayjs(editDetail.updatedAt).format('YYYY-MM-DD HH:mm:ss') : null;
      }
      const res = await commentVideoRequest(requestParams);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success('评价成功');
        closeModal();
        reloadData && reloadData();
      }
    }
  }

  // 目标种类选择无时，清空目标位置、目标行为
  const targetTypeChange = (e: any) => {
    setTargetDisabled(e.target.value === 6894);
    if (e.target.value === 6894) {
      form.setFieldsValue({
        targetPosition: '',
        targetBehavior: '',
      });
    }
  }

  // 预警结果选择误报时 及时性选项禁用
  const resultChange = (e: any) => {
    setTimelyDisabled(e.target.value === 6835);
    if (e.target.value === 6835) {
      form.setFieldsValue({
        timely: '',
      });
    }
  }

  // 预警效果选择无效时 评分可以为0
  const effectChange = (e: any) => {
    setRateRequired(e.target.value !== 6837);
    if (e.target.value === 6837) {
      form.setFields([{ name: 'rate', errors: [], value: 0 }]);
    }
  }

  return (
    <Modal
      title={editDetail ? '编辑视频评价' : '添加视频评价'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="预警结果"
          name="result"
          rules={[{ required: true, message: '请选择预警结果' }]}
        >
          <Radio.Group options={tagData.resultTag || []} onChange={resultChange} />
        </Form.Item>
        <Form.Item
          label="预警效果"
          name="effect"
          rules={[{ required: true, message: '请选择预警效果' }]}
        >
          <Radio.Group options={tagData.effectTag || []} onChange={effectChange} />
        </Form.Item>
        <Form.Item
          label="预警评分"
          name="rate"
          rules={[{ required: rateRequired, message: '请给预警评分' }]}
        >
          <Rate />
        </Form.Item>
        <Form.Item label="时间" name="time">
          <Radio.Group options={tagData.timeTag || []} />
        </Form.Item>
        <Form.Item label="天气" name="weather">
          <Radio.Group options={tagData.weatherTag || []} />
        </Form.Item>
        <Form.Item label="路况" name="road">
          <Checkbox.Group options={tagData.roadTag || []} />
        </Form.Item>
        <Form.Item label="自车行为" name="carBehavior">
          <Radio.Group options={tagData.carBehaviorTag || []} />
        </Form.Item>
        <Form.Item label="逆光" name="light">
          <Radio.Group options={tagData.lightTag || []} />
        </Form.Item>
        <Form.Item label="及时性" name="timely">
          <Radio.Group options={tagData.timelyTag || []} disabled={timelyDisabled} />
        </Form.Item>
        <Form.Item label="目标种类" name="targetType">
          <Radio.Group options={tagData.targetTypeTag || []} onChange={targetTypeChange} />
        </Form.Item>
        <Form.Item label="目标位置" name="targetPosition">
          <Radio.Group options={tagData.targetPositionTag || []} disabled={targetDisabled} />
        </Form.Item>
        <Form.Item label="目标行为" name="targetBehavior">
          <Radio.Group options={tagData.targetBehaviorTag || []} disabled={targetDisabled} />
        </Form.Item>
        <Form.Item label="预警评价" name="comment">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CommentVideoModal;
    