/**
 * 标签管理相关请求
 */
import request from "@/utils/request";

// 获取标签分组数据
export const tagSortTreeRequest = () => {
  return request.get('/api/v2/tag/sort/tree')
}

// 添加或编辑标签分组
export const upsertTagSortRequest = (data: any) => {
  return request.post('/api/v2/tag/sort/upsert', data)
}

// 获取标签数据
export const tagListRequest = (params?: any) => {
  return request.get('/api/v2/tag/list', {
    params
  })
}

// 添加或编辑标签
export const upsertTagRequest = (data: any) => {
  return request.post('/api/v2/tag/upsert', data)
}