/**
 * 操作出险记录(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, InputNumber } from 'antd';
import dayjs from 'dayjs';
import CarSelect from '@/components/carSelect';
import DatePicker from '@/components/datePicker';
import { TagCategoryEnum } from '@/constants/tagManage';
import { formatTime } from '@/utils/formatTime';
import { promiseDebounce } from '@/utils/common';
import { upsertAccidentRecordRequest, validCaseNumberRequest } from '@/service/accidentRecord';
import { tagListRequest } from '@/service/tagManage';
import UploadImageVideo from "@/components/uploadImageVideo";

import {insureListRequest} from '@/service/insurerManage';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const { reloadData, closeModal, type, data } = props;
  const [accidentEnum, setAccidentEnum] = useState<any>({}); // 出险记录相关枚举
  const [organizations, setOrganizations] = useState<any>([]); // 承保机构
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [documentResourcesList, setDocumentResourcesList] = useState<any>([]); // 单证
  const [targetCarResourcesList, setTargetCarResourcesList] = useState<any>([]); // 标的车损
  const [targetPersonResourcesList, setTargetPersonResourcesList] = useState<any>([]); // 标的人伤
  const [otherCarResourcesList, setOtherCarResourcesList] = useState<any>([]); // 三方车损
  const [otherObjectResourcesList, setOtherObjectResourcesList] = useState<any>([]); // 三方无损
  const [otherPersonResourcesList, setOtherPersonResourcesList] = useState<any>([]); // 三方人伤
  const [payResourcesList, setPayResourcesList] = useState<any>([]); // 支付
  const [otherResourcesList, setOtherResourcesList] = useState<any>([]); // 其他照片


  // 编辑的时候赋值
  useEffect(() => {
    getAccidentTags();
    getOrganization()
    if (type === 'edit') {
      const params = { ...data };
      params.insureDateTime = dayjs(data.insureDateTime);
      params.tagIds = data.tagId ? data.tagId.split(',').map((item: string) => Number(item)) : [];
      form.setFieldsValue(params);
    }
  }, []);

    //自车车速标签
    const selfCarSpeedData = [
      { label: '≤10', value:'≤10' },
      { label: '10~30', value: '10~30' },
      { label: '30~50', value: '30~50' },
      { label: '50~70', value: '50~70' },
      { label: '70~90', value: '70~90' },
      { label: '≥90', value: '≥90' }
    ]
     //自车行为标签
     const selfCarBehaviorData = [
      { label: '直行', value:'直行' },
      { label: '左转', value: '左转' },
      { label: '右转', value: '右转' },
      { label: '倒车', value: '倒车' },
      { label: '起步', value: '起步' },
      { label: '其他', value: '其他' }
    ]
      //路段类型标签
      const  roadTypeData = [
        { label: '直道', value:'直道' },
        { label: '弯道', value: '弯道' },
        { label: '交叉路口', value: '交叉路口' },
        { label: '其他', value: '其他' }
      ]
        //障碍物类型标签
     const obstacleTypeData = [
      { label: '机动车', value:'机动车' },
      { label: '非机动车', value: '非机动车' },
      { label: '行人', value: '行人' },
      { label: '其他', value: '其他' },
      { label: '无', value: '无' }
    ]

  // 获取出险相关标签
  const getAccidentTags = async () => {
    const res = await tagListRequest({
      tagSorts: [
        TagCategoryEnum.accident,
        TagCategoryEnum.accidentType,
        TagCategoryEnum.accidentCollisionLocation,
        TagCategoryEnum.accidentClassification,
      ],
    });
    const enumData: any = {
      accident: [],
      accidentType: [],
      accidentCollisionLocation: [],
      accidentClassification: [],
    };
    (res.data?.data || []).forEach((item: any) => {
      const { title, id, isDisabled } = item;
      const tag = { label: title, value: id, disabled: isDisabled };
      if (item.tagSort === TagCategoryEnum.accident) {
        enumData.accident.push(tag);
      }
      if (item.tagSort === TagCategoryEnum.accidentType) {
        enumData.accidentType.push(tag);
      }
      if (item.tagSort === TagCategoryEnum.accidentCollisionLocation) {
        enumData.accidentCollisionLocation.push(tag);
      }
      if (item.tagSort === TagCategoryEnum.accidentClassification) {
        enumData.accidentClassification.push(tag);
      }
    })
    setAccidentEnum(enumData);
  }

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      params.insureDateTime = formatTime(values.insureDateTime);
      params.personAmount = values.personAmount || 0;
      params.documentResources = documentResourcesList;
      params.targetCarResources = targetCarResourcesList;
      params.targetPersonResources = targetPersonResourcesList;
      params.otherCarResources = otherCarResourcesList;
      params.otherObjectResources = otherObjectResourcesList;
      params.otherPersonResources = otherPersonResourcesList;
      params.payResources = payResourcesList;
      params.otherResources = otherResourcesList;
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertAccidentRecordRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '编辑成功');
        closeModal();
        reloadData();
      }
    }
  }

  // 车辆选择自动填入所属车队
  const onCarSelect = (value: any, options: any) => {

    const company = options.company;
    form.setFieldsValue({
      motorcade: company,
      channel: options.insurance
    })
  }
    // 获取承保保司
    const getOrganization = async () => {
      const res = await insureListRequest('');
      const tags: any = [];
      (res.data?.data || []).forEach((item: any) => {
        const {name, id} = item;
        tags.push({label: name, value: name});
      });
      setOrganizations(tags);
    }
  

  // 出险金额联动重大案件数量
  const onClaimAmountChange = (value: any) => {
    form.setFieldsValue({
      majorCaseCount: value >= 100000 ? 1 : 0,
    })
  }

  // 校验报案号是否重复
  const validatorCaseNumber = async (rule: any, value: string) => {
    const res = await validCaseNumberRequest({ caseNumber: value });
    if (res.data?.code === 0) {
      if (res.data?.data) {
        return Promise.resolve();
      } else {
        if (type === 'edit' && data.caseNumber === value) {
          // 编辑时如果是当前报案号校验可以通过
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('报案号重复'));
        }
      }
    }
  }

  // 校验人伤金额不能大于出现金额
  const validatorPersonAmount = (rule: any, value: string) => {
    const { getFieldValue } = form;
    const claimAmountValue = getFieldValue('claimAmount');
    if (value > claimAmountValue) {
      return Promise.reject(new Error('人伤金额不能大于出险金额'));
    } else {
      return Promise.resolve();
    }
  }


  return (
    <Modal
      title={type === 'add' ? '添加出险记录' : '编辑出险记录'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        scrollToFirstError
      >
        <Form.Item
          label="车牌号"
          name="plateNumber"
          rules={[{ required: true, message: '请选择车牌号' }]}
        >
          <CarSelect valueField='plate_number' onChange={onCarSelect} />
        </Form.Item>
        <Form.Item
          label="承保机构"
          name="channel"
          rules={[{ required: true, message: '请选择承保机构' }]}
        >
           <Input placeholder='承保机构' disabled />
          {/* <Select
            options={organizations}
            placeholder="请选择承保机构"
            showSearch
          /> */}
        </Form.Item>
        <Form.Item
          label="所属车队"
          name="motorcade"
          required
        >
          <Input placeholder='所属车队' disabled />
        </Form.Item>
        <Form.Item
          label="报案号"
          name="caseNumber"
          validateFirst
          rules={[
            { required: true, message: '请输入报案号' },
            { validator: promiseDebounce(validatorCaseNumber, 600) }
          ]}
        >
          <Input placeholder='请输入报案号' />
        </Form.Item>
        <Form.Item
          label="被保险人"
          name="insurer"
          rules={[{ required: true, message: '请输入被保险人' }]}
        >
          <Input placeholder='请输入被保险人' />
        </Form.Item>
        <Form.Item
          label="出险时间"
          name="insureDateTime"
          rules={[{ required: true, message: '请选择出险时间' }]}
        >
          <DatePicker placeholder='请选择出险时间' showTime style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="出险金额(整案预估)"
          name="claimAmount"
          rules={[{ required: true, message: '请输入出险金额(整案预估)' }]}
        >
          <InputNumber placeholder='请输入出险金额(整案预估)' style={{ width: '100%' }} onChange={onClaimAmountChange} />
        </Form.Item>
        <Form.Item
          label="人伤金额"
          name="personAmount"
          rules={[{ validator: validatorPersonAmount }]}
        >
          <InputNumber placeholder='请输入人伤金额' style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="重大案件数量"
          name="majorCaseCount"
          required
        >
          <InputNumber placeholder='重大案件数量' disabled style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="事故类型"
          name="accidentType"
          rules={[{ required: true, message: '请选择事故类型' }]}
        >
          <Select options={accidentEnum.accidentType} placeholder="请选择事故类型" allowClear showSearch optionFilterProp="label" />
        </Form.Item>
        <Form.Item
          label="事故碰撞方位"
          name="accidentCollisionLocation"
          rules={[{ required: true, message: '请选择事故碰撞方位' }]}
        >
          <Select options={accidentEnum.accidentCollisionLocation} placeholder="请选择事故碰撞方位" allowClear showSearch optionFilterProp="label" />
        </Form.Item>
        <Form.Item
          label="事故分类"
          name="accidentClassification"
          rules={[{ required: true, message: '请选择事故分类' }]}
        >
          <Select options={accidentEnum.accidentClassification} placeholder="请选择事故分类" showSearch optionFilterProp="label" />
        </Form.Item>
        <Form.Item
          label="自车车速"
          name="selfCarSpeed"
          // rules={[{ required: true, message: '请选择自车车速' }]}
        >
          <Select options={selfCarSpeedData} placeholder="请选择自车车速" showSearch allowClear optionFilterProp="label" />
        </Form.Item>
        <Form.Item
          label="自车行为"
          name="selfCarBehavior"
          // rules={[{ required: true, message: '请选择自车行为' }]}
        >
          <Select options={selfCarBehaviorData} placeholder="请选择自车行为" showSearch  allowClear optionFilterProp="label" />
        </Form.Item>
        <Form.Item
          label="路段类型"
          name="roadType"
          // rules={[{ required: true, message: '请选择路段类型' }]}
        >
          <Select options={roadTypeData} placeholder="请选择路段类型" showSearch allowClear optionFilterProp="label" />
        </Form.Item>
        <Form.Item
          label="障碍物类型"
          name="obstacleType"
          // rules={[{ required: true, message: '请选择障碍物类型' }]}
        >
          <Select options={obstacleTypeData} placeholder="请选择障碍物类型" showSearch allowClear optionFilterProp="label" />
        </Form.Item>
        <Form.Item label="事故标签" name="tagIds">
          <Select
            placeholder="请选择事故标签"
            options={accidentEnum.accident}
            mode="multiple"
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="备注"
          name="ps" >
          <Input.TextArea placeholder="请输入备注" />
        </Form.Item>
        <Form.Item label="单证" >
          <UploadImageVideo
            businessName="单证"
            uploadFilesChange={(successList: any) => setDocumentResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.documentResources == null ? [] : data.documentResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            limitSize={500}
            multiple
          />
        </Form.Item>
        <Form.Item label="标的车损">
          <UploadImageVideo
            businessName="标的车损"
            uploadFilesChange={(successList: any) => setTargetCarResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.targetCarResources == null ? [] : data.targetCarResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            multiple
          />
        </Form.Item>
        <Form.Item label="标的人伤" name="targetPersonResources">
          <UploadImageVideo
            businessName="标的人伤"
            uploadFilesChange={(successList: any) => setTargetPersonResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.targetPersonResources == null ? [] : data.targetPersonResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            limitSize={500}
            multiple
          />
        </Form.Item>
        <Form.Item label="三者车损">
          <UploadImageVideo
            businessName="三者车损"
            uploadFilesChange={(successList: any) => setOtherCarResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.otherCarResources == null ? [] : data.otherCarResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            multiple
          />
        </Form.Item>
        <Form.Item label="三者物损">
          <UploadImageVideo
            businessName="三者物损"
            uploadFilesChange={(successList: any) => setOtherObjectResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.otherObjectResources == null ? [] : data.otherObjectResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            multiple
          />
        </Form.Item>
        <Form.Item label="三者人伤">
          <UploadImageVideo
            businessName="三者人伤"
            uploadFilesChange={(successList: any) => setOtherPersonResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.otherPersonResources == null ? [] : data.otherPersonResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            multiple
          />
        </Form.Item>
        <Form.Item label="支付手续">
          <UploadImageVideo
            businessName="支付手续"
            uploadFilesChange={(successList: any) => setPayResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.payResources == null ? [] : data.payResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            multiple
          />
        </Form.Item>
        <Form.Item label="其他照片">
          <UploadImageVideo
            businessName="其他照片"
            uploadFilesChange={(successList: any) => setOtherResourcesList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.otherResources == null ? [] : data.otherResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            multiple
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;