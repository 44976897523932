/**
 * 车辆管理
 */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Card, Modal, message, Form, Row, Col, Button, Input } from 'antd';
import dayjs from 'dayjs';
import { groupCategoryRequest, deleteGroupRequest } from '@/service/groupManage';
import { operationGroupListRequest, upsertOperationGroupRequest } from '@/service/operationGroupManage';
import PermissionButton from '@/components/permissionButton';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import OperateGroup from './components/operateGroup';
import { filterMultipleCateData } from '@/utils/cate';
import styles from '@/styles/pageContent.module.scss';

const { confirm } = Modal;
const GroupManage = () => {
  const { userDetail } = useSelector((state: any) => state.userStore);
  const expandRef = useRef<any>([]); // 存储展开的keys
  const [allGroupData, setAllGroupData] = useState<any>([]); // 全量的分组数据
  const [groupData, setGroupData] = useState<any>([]); // 分组数据
  const [loading, setLoading] = useState<boolean>(false); // 表格加载中
  const [operateParams, setOperateParams] = useState<any>({}); // 表格操作时对应的数据
  const [expandedKeys, setExpandedKeys] = useState<any>([]); // 展开的keys

  // 获取分组数据
  const getGroupData = async () => {
    try {
      setLoading(true);
      const res = await groupCategoryRequest();
      let finalGroupData = res.data?.data || [];
      // 针对有多个分组的账号特殊过滤分组数据
      if (userDetail.multiCate) {
        finalGroupData = filterMultipleCateData(finalGroupData, JSON.parse(userDetail.multiCate))
      }
      setAllGroupData(finalGroupData);
      setGroupData(finalGroupData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getGroupData();
  }, []);

  // 删除分组时更新运维组的数据
  const changeOperationGroup = async(deleteId: number) => {
    const res = await operationGroupListRequest();
    const operationGroupData = res.data?.data || [];
    let updateParams = null;
    // 修改删除的分组关联运维组的数据
    operationGroupData.forEach((item: any) => {
      if (item.cateId.includes(deleteId)) {
        updateParams = {
          id: item.id,
          title: item.title,
          cateIds: item.cateId.filter((item1: number) => item1 !== deleteId)
        };
      }
    })
    if (updateParams) {
      const upsertRes = await upsertOperationGroupRequest(updateParams);
      if (upsertRes.data?.code === 0) {
        message.success("该分组从关联运维组删除成功");
      } else {
        message.warning("该分组从关联运维组删除失败，请手动修改运维组数据");
      }
    }
  }

  // 删除分组
  const deleteGroup = async(id: number) => {
    const res = await deleteGroupRequest(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getGroupData();
      changeOperationGroup(id);
    }
  }

  // 删除的弹窗
  const deleteConfirm = (data: any) => {
    confirm({
      title: `确定要删除${data.title}吗？`,
      onOk() {
        deleteGroup(data.id)
      },
      centered: true,
    });
  }

  // 过滤匹配搜索的数据
  const filterCategoryData = (data: any, value: string) => {
    const newData: any = [];
    data.forEach((item: any) => {
      let obj: any = {};
      if (item.title.includes(value)) {
        obj = {...item};
        newData.push(obj);
      } else if (Array.isArray(item.children) && item.children.length > 0) {
        const tempData = filterCategoryData(item.children, value);
        obj = {
          ...item,
          children: tempData,
        };
        // 当children不为空时才保留该数据
        if (obj.children.length > 0) {
          expandRef.current.push(item.id);
          newData.push(obj);
        }
      }
    });
    return newData;
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.name = (values.name || '').trim();
    if (newSearchParams.name) {
      setLoading(true);
      expandRef.current = [];
      setTimeout(() => {
        const filterGroupData = filterCategoryData(allGroupData, newSearchParams.name);
        setExpandedKeys(expandRef.current);
        setGroupData(filterGroupData);
        setLoading(false);
      }, 0);
    } else {
      expandRef.current = [];
      setExpandedKeys([]);
      setGroupData(allGroupData);
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '名称',
      dataIndex: 'title',
      width: 220,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '分组编号',
      dataIndex: 'id',
      width: 90,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 140,
      render: (value: string) => <RenderEllipsisColumn text={value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : ''} />,
    },
    {
      title: '最后更改时间',
      dataIndex: 'updatedAt',
      width: 140,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : ''} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton operateType='/car-admin/group-manage/add' type="link" onClick={() => setOperateParams({ data: item, type: 'add' })}>添加子分类</PermissionButton>
            <PermissionButton operateType='/car-admin/group-manage/edit' type="link" onClick={() => setOperateParams({ data: item, type: 'edit' })}>编辑</PermissionButton>
            <PermissionButton operateType='/car-admin/group-manage/delete' type="link" onClick={() => deleteConfirm(item)}>删除</PermissionButton>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} autoComplete='off'>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item label="分组名称" name="name">
              <Input placeholder='请输入分组名称' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit">搜索</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={groupData}
        pagination={false}
        bordered
        loading={loading}
        expandedRowKeys={expandedKeys}
        onExpandedRowsChange={(expandedRows) => setExpandedKeys(expandedRows)}
      />
      {['add', 'edit'].includes(operateParams.type) &&
        <OperateGroup
          data={operateParams.data}
          type={operateParams.type}
          closeModal={() => setOperateParams({})}
          reloadData={getGroupData}
        />
      }
    </Card>
  )
}

export default GroupManage;
  