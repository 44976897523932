/**
 * 传感器方案-项目添加/编辑
 */
import React, { useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { upsertSensorProjectRequest } from '@/service/sensorProjectManage';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();

  // 编辑时赋值
  useEffect(() => {
    if (['edit', 'detail'].includes(type)) {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存项目管理
  const upsertProject = async (values: any) => {
    const params = { ...values };
    if (type === 'edit') {
      params.id = data.id;
    }
    const res = await upsertSensorProjectRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  return (
    <Modal
      title={
        type === 'detail'
          ? '项目详情'
          : type === 'add'
          ? '新建项目'
          : '编辑项目'
      }
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okButtonProps={{ style: type === 'detail' ? { display: 'none' } : {} }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={upsertProject}
        layout="vertical"
        disabled={type === 'detail'}
      >
        <Form.Item
          label="项目名称"
          name="name"
          rules={[{ required: true, message: '请输入项目名称' }]}
        >
          <Input placeholder="请输入项目名称" />
        </Form.Item>
        <Form.Item label="项目备注" name="ps">
          <Input.TextArea placeholder="请输入项目备注" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
