/**
 * 用户管理
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Select, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { userListRequest, userDeleteRequest, userListRequestPath } from '@/service/userManage';
import { roleListRequest } from '@/service/roleManage';
import OperateModal from './components/operateModal';
import EditPasswordModal from './components/editPasswordModal';
import styles from '@/styles/pageContent.module.scss';
import { groupCategoryRequest } from '@/service/groupManage';

const UserManage = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [roleList, setRoleList] = useState<any>([]); // 角色列表
  const [fleetList, setFleetList] = useState<any>([]); // 车队列表数据

  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: userListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 获取角色列表
  const getRoleList = async() => {
    const res = await roleListRequest({page:1,limit:1000});
    const roleData: any = [];
    (res.data?.data || []).forEach((item: any) => {
      roleData.push({
        ...item,
        label: `${item.name}(${item.groupName})`
      })
    });
    setRoleList(roleData);
  }

  useEffect(() => {
    getRoleList();
    getCategoryData()
  }, []);

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    newSearchParams.nickName = (values.nickName || '').trim();
    newSearchParams.mobile = (values.mobile || '').trim();
    setSearchParams(newSearchParams);
  };

  // 获取导出参数
  const getExportParams = () => {
    const params = form.getFieldsValue();
    params.nickName = (params.nickName || '').trim();
    params.mobile = (params.mobile || '').trim();
    params.export = true;
    return params;
  }

  // 删除用户
  const deleteUser = async(id: number) => {
    const res = await userDeleteRequest(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 渲染角色列
  const renderRoleColumns = (value: any, data: any) => {
    if (value?.length) {
      const roleIds = value.map((item: any) => item.id);
      const roleLabel: Array<string> = [];
      roleList.forEach((item: any) => {
        if (roleIds.includes(item.id)) {
          roleLabel.push(item.label);
        }
      });
      return roleLabel.join('、');
    } else {
      return roleList.find((item: any) => Number(item.id) === Number(data.roleNew))?.label;
    }
  }

  //车队列表数据(递归)
  const getLastLevelData = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      const newDataObj: any = {};
      const newData: any = [];
      const deep = (innerData: any) => {
        innerData.forEach((item: any) => {
          newData.push({id:item.id,name:item.title});
          if (Array.isArray(item.children) && item.children.length > 0) {
            deep(item.children);
          } else {
            if (newDataObj[item.title]) {
              newDataObj[item.title].push(item.id);
            } else {
              newDataObj[item.title] = [item.id];
            }
          }
        });
      };
      deep(data);
      return newData;
    } else {
      return [];
    }
  }

  // 获取分组树形数据
  const getCategoryData = async () => {
    const res = await groupCategoryRequest();
    const data = res.data?.data || [];
    setFleetList(getLastLevelData(data));
  }

  // 车队
  const motorcadeData = (value: any, item: any) => {
    if (item.multiCate == '') {
      const aaa:any = fleetList.find((item: any) => item.id == value);
      return <RenderEllipsisColumn text={aaa?.name} />
    } else {
      const bbb:any = []
      JSON.parse(item.multiCate).map((item:any) => {
        const aaa:any = fleetList.find((itemm: any) => itemm.id == item);
        bbb.push(aaa?.name)
      })
      // console.log('JSON.parse(item.multiCate)',JSON.parse(item.multiCate))
      // console.log('bbb',bbb)
      return <RenderEllipsisColumn text={bbb.join('、')} />;
    }
  }
  // 表格列显示
  const columns = [
    {
      title: '账号',
      dataIndex: 'mobile',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '用户名',
      dataIndex: 'nickname',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '部门',
      dataIndex: 'department',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '岗位',
      dataIndex: 'position',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '负责人',
      dataIndex: 'header',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '包含车队',
      dataIndex: 'cateId',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string, item: any) => motorcadeData(value, item),
    },
    {
      title: '角色',
      dataIndex: 'roles',
      width: 150,
      render: (value: any, item: any) => renderRoleColumns(value, item),
    },
    {
      title: '状态',
      dataIndex: 'disabled',
      width: 70,
      render: (value: boolean) => value ? <span className='dangerText'>禁用</span> : <span className='successText'>正常</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 150,
      render: (value: string) => dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '剩余视频时长(分钟)',
      dataIndex: 'videoDuration',
      width: 150,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/user-manage/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <PermissionButton
              operateType='/car-admin/user-manage/changePassword'
              type="link"
              onClick={() => setOperateInfo({ type: 'password', data: item })}
            >
              修改密码
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该用户吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/user-manage/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" autoComplete='off' form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="账号" name="mobile">
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="用户名" name="nickName">
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="角色" name="roleIds">
              <Select
                showSearch
                placeholder='请选择角色'
                options={roleList}
                allowClear
                fieldNames={{
                  value: 'id',
                }}
                optionFilterProp="name"
                mode="multiple"
                showArrow
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton
                  url={userListRequestPath}
                  fileName='用户管理'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/user-manage/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/user-manage/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
      {/* 修改密码 */}
      {operateInfo.type === 'password' &&
        <EditPasswordModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default UserManage;
