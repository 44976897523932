import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import 'antd/dist/antd.css'
import 'xgplayer/dist/index.min.css';
import { useWinSize } from '@/utils/ownHooks';
import Routes from './routes';
import store from './store'
import './index.css';

import { debounce } from "lodash";
	
const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 100);
    super(callback);
  }
};


const App = () => {
  const { winHeight } = useWinSize();
  // 根据浏览器调整antd组件尺寸
  const componentSize = winHeight < 700 ? 'small' : 'middle';
  

  return (
    <Provider store={store}>
      <ConfigProvider locale={zhCN} componentSize={componentSize}>
        <Routes />
      </ConfigProvider>
    </Provider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

