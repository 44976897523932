/**
 * 运维组评价指标
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, InputNumber } from 'antd';
import dayjs from 'dayjs';
import NP from 'number-precision';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import { operationGroupAssessmentRequest, operationGroupAssessmentRequestPath } from '@/service/operationGroupAssessment';
import { errTypeData, defaultTableFormData } from '@/constants/abnormalSummary';
import IndicatorDescriptionModal from './components/indicatorDescriptionModal';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker

// 通过异常车辆汇总的阈值生成评价的指标参数
const operationGroupAssessmentField = ['vs0', 'gs0', 'vsm120', 'err2', 'err31', 'err91', 'err130', 'err133', 'offline', 'hmwMin'];
const specialAssessmentFieldValue: any = { offline: 1, hmwMin: 90 }; // 特殊的初始值
const assessmentFieldParams: any = {};
errTypeData.forEach((item: any, index: number) => {
  if (operationGroupAssessmentField.includes(item.value)) {
    const value = {...defaultTableFormData[index]};
    if (specialAssessmentFieldValue[item.value]) {
      value.count = specialAssessmentFieldValue[item.value];
    }
    assessmentFieldParams[item.value] = value;
  }
});

const OperationGroupAssessment = () => {
  const [data, setData] = useState([]); // 数据
  const [loading, setLoading] = useState(false); // 是否在获取数据中
  const [operateInfo, setOperateInfo] = useState<any>({}); // 相关操作的信息
  const [form] = Form.useForm();

  useEffect(() => {
    form.submit();
  }, [])
  
  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    delete newParams.time;
    newParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD');
    newParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD');
    newParams.faultDurationWeight = NP.divide(params.faultDurationWeight, 100);
    newParams.faultRateWeight = NP.divide(params.faultRateWeight, 100);
    newParams.orderReopenWeight = NP.divide(params.orderReopenWeight, 100);
    newParams.customerComplaintRateWeight = NP.divide(params.customerComplaintRateWeight, 100);
    newParams.hmwIndexWeight = NP.divide(params.hmwIndexWeight, 100);
    newParams.onlineRateWeight = NP.divide(params.onlineRateWeight, 100);
    if (isExport) {
      newParams.export = true;
    }
    return {...newParams, ...assessmentFieldParams};
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    getData(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 获取数据
  const getData = async(params: any) => {
    try {
      setLoading(true);
      const res = await operationGroupAssessmentRequest(params);
      setData(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 校验所有占比相加等于100
  const validatorWeightTotal = () => {
    const { getFieldsValue } = form;
    const { faultDurationWeight, faultRateWeight, hmwIndexWeight, onlineRateWeight, orderReopenWeight, customerComplaintRateWeight } = getFieldsValue();
    if (NP.plus(faultDurationWeight, faultRateWeight, hmwIndexWeight, onlineRateWeight, orderReopenWeight, customerComplaintRateWeight) === 100) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('请保证所有占比相加等于100'));
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '运维组',
      dataIndex: 'title',
      width: 110,
      fixed: 'left' as 'left',
    },
    {
      title: '故障持续时间得分',
      dataIndex: 'faultDurationScore',
      width: 200,
    },
    {
      title: '故障率得分',
      dataIndex: 'faultRateScore',
      width: 130,
    },
    {
      title: 'HMW指标达标率得分',
      dataIndex: 'hmwIndexScore',
      width: 130,
    },
    {
      title: '在线率得分',
      dataIndex: 'onlineRateScore',
      width: 130,
    },
    {
      title: '工单重开率得分',
      dataIndex: 'orderReopenScore',
      width: 130,
    },
    {
      title: '客诉率得分',
      dataIndex: 'customerComplaintRateScore',
      width: 110,
    },
    {
      title: '总分',
      dataIndex: 'totalScore',
      width: 110,
    },
  ];

  // 原始数据表格列显示
  const originalColumns = [
    {
      title: '运维组',
      dataIndex: 'title',
      width: 110,
      fixed: 'left' as 'left',
    },
    {
      title: '故障持续时间-比率',
      dataIndex: 'faultDurationScore',
      width: 200,
      render: (value: number) => `${NP.minus(100, value)}%`,
    },
    {
      title: '故障率',
      dataIndex: 'faultRateScore',
      width: 130,
      render: (value: number) => `${NP.minus(100, value)}%`,
    },
    {
      title: 'HMW指标达标率',
      dataIndex: 'hmwIndexScore',
      width: 130,
      render: (value: number) => `${value}%`,
    },
    {
      title: '在线率',
      dataIndex: 'onlineRateScore',
      width: 130,
      render: (value: number) => `${value}%`,
    },
    {
      title: '工单重开率',
      dataIndex: 'orderReopenScore',
      width: 130,
      render: (value: number) => `${NP.minus(100, value)}%`,
    },
    {
      title: '客诉率',
      dataIndex: 'customerComplaintRateScore',
      width: 110,
      render: (value: number) => `${NP.minus(100, value)}%`,
    },
  ];

  return (
    <Card bordered={false} className={styles.pageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs().subtract(1, 'month'), dayjs()]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }}
                // disabledDate={(currentDate: any) => currentDate && currentDate >= dayjs().subtract(1, 'months')}
                // picker="month"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="故障持续时间占比"
              name="faultDurationWeight"
              rules={[
                { required: true, message: '请输入故障持续时长占比' },
                { validator: validatorWeightTotal }
              ]}
              initialValue={30}
            >
              <InputNumber addonAfter="%" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="故障率占比"
              name="faultRateWeight"
              rules={[
                { required: true, message: '请输入故障率占比' },
                { validator: validatorWeightTotal }
              ]}
              initialValue={20}
            >
              <InputNumber addonAfter="%" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="HMW指标达标率占比"
              name="hmwIndexWeight"
              rules={[
                { required: true, message: '请输入HMW指标达标率占比' },
                { validator: validatorWeightTotal }
              ]}
              initialValue={30}
            >
              <InputNumber addonAfter="%" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="在线率占比"
              name="onlineRateWeight"
              rules={[
                { required: true, message: '请输入在线率占比' },
                { validator: validatorWeightTotal }
              ]}
              initialValue={10}
            >
              <InputNumber addonAfter="%" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="工单重开率占比"
              name="orderReopenWeight"
              rules={[
                { required: true, message: '请输入工单重开率占比' },
                { validator: validatorWeightTotal }
              ]}
              initialValue={5}
            >
              <InputNumber addonAfter="%" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="客诉率占比"
              name="customerComplaintRateWeight"
              rules={[
                { required: true, message: '请输入客诉率占比' },
                { validator: validatorWeightTotal }
              ]}
              initialValue={5}
            >
              <InputNumber addonAfter="%" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" ">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">生成报告</Button>
                <ExportButton 
                  url={operationGroupAssessmentRequestPath}
                  fileName='运维组评价指标'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/operation-group-assessment/export'
                />
                <Button onClick={() => setOperateInfo({ type: 'indicatorDescription' })}>指标说明</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', marginBottom: '12px' }}>运维组评分报告</div>
      <CommonTable
        rowKey="title"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
        scroll={{ y: 400 }}
      />
      <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', margin: '24px 0 12px' }}>评价指标原始数据</div>
      <CommonTable
        rowKey="title"
        columns={originalColumns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
        scroll={{ y: 400 }}
      />
      {/* 指标说明弹窗 */}
      {operateInfo.type === 'indicatorDescription' && <IndicatorDescriptionModal closeModal={() => setOperateInfo({})} />}
    </Card>
  )
}

export default OperationGroupAssessment;
