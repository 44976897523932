export enum ArchiveTypeEnum {
  install = 0,
  underwriting = 1,
  maintenance = 2, 
  accident = 3, 
};

export const archiveTypeTitleObj: any = {
  [ArchiveTypeEnum.install]: '安装记录',
  [ArchiveTypeEnum.underwriting]: '承保记录',
  [ArchiveTypeEnum.maintenance]: '维保记录',
  [ArchiveTypeEnum.accident]: '事故记录',
}