/**
 * 客服统计相关请求
 */
import request from "@/utils/request";

// 获取任务量数据
export const workLoadRequest = (params:any) => {
    return request.get('/api/v2/customerTaskStatistic/workload', {params})
  }

// 获取业务状态数据
export const workStateRequest = (params:any) => {
    return request.get('/api/v2/customerTaskStatistic/workState',{params})
  }
// 获取平均业务处理时长
export const dealTimeRequest = (params:any) => {
    return request.get('/api/v2/customerTaskStatistic/dealTime',{params})
  }

// 客服处理任务总量分布
export const totalTaskRequest = (params:any) => {
  return request.get('/api/v2/customerTaskStatistic/totalTask',{params})
}

// 客服各状态任务数量
export const customerTakStateRequest = (params:any) => {
  return request.get('/api/v2/customerTaskStatistic/customerTakState',{params})
}

