/**
 * adas报警查询相关请求
 */
import request from "@/utils/request";

export interface AdasAlarmListRequestParams {
  page: number;
  limit: number;
  startTime?: string;
  endTime?: string;
  idList?: Array<number>;
  typeList?: Array<number>;
}

export const adasAlarmListRequestPath = '/api/v2/car/adaAlarmList';
// 获取报警数据
export const adasAlarmListRequest = (data: AdasAlarmListRequestParams) => {
  return request.post(adasAlarmListRequestPath, data)
}
