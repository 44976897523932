/**
 * 判断是否有页面权限
 */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getPagePermissionEnable } from '@/utils/permission';

const AuthRoute = ({ component: Component, permissionKey, ...rest }: any) => {
  const userStore = useSelector((state: any) => state.userStore);
  const { access_token: isLogin } = userStore;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLogin) {
      dispatch({ type: 'logout', payload: { loginSuccessJumpPath: rest.path } });
      dispatch({ type: 'resetTab' });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isLogin) {
          // 判断用户是否有当前页面的权限
          if (permissionKey) {
            let hasPermssion = getPagePermissionEnable(permissionKey);
            if (!hasPermssion) {
              // 没有权限跳转到无权限页面
              return <Redirect to="/no-permssion" />;
            }
          }
          return <Component {...props} />;
        } else {
          // 没有登录直接重定向到登录
          return <Redirect to="/login" />;
        }
      }}
    />
  )
}

export default AuthRoute;