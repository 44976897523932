import gcoord from 'gcoord';
import {message} from 'antd';
import {faultCodeData} from '@/constants/faultCode';
import {registerClipboard} from '@/utils//common';
import {formatTime} from '@/utils/formatTime';
import {carDetailRequest} from '@/service/carManage';
import carIcon from '@/assets/image/carIcon.png';
import onlineCarIcon from '@/assets/image/onlineCarIcon.png';
import abnormalCarIcon from '@/assets/image/abnormalCarIcon.png';
import highAbnormalCarIcon from '@/assets/image/highAbnormalCarIcon.png';

// 车辆分布中获取设备异常等级
export const getAbnormalDeviceLevel = (
  deviceStatus: any,
  judegeCount: number
) => {
  const abnormalLevel = {
    abnormal: false,
    highAbnormal: false
  };
  if (Array.isArray(deviceStatus)) {
    // 车辆分布接口设备状态为数组形式处理
    deviceStatus.forEach(item => {
      if (Number(item.code) !== 0) {
        if (item.count > 0) {
          abnormalLevel.abnormal = true;
        }
        if (item.count >= judegeCount) {
          abnormalLevel.highAbnormal = true;
        }
      }
    });
  } else if (deviceStatus !== '0') {
    // 车辆列表接口设备状态为字符串形式处理
    abnormalLevel.abnormal = true;
  }
  return abnormalLevel;
};

// 复制车辆位置链接
export const copyLocationLink = (locationLink: string, carInfoWindow: any) => {
  function copy() {
    const copyLinkButtonDom: any = document.getElementById("copyLinkButton")
    copyLinkButtonDom.onclick = function () {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(locationLink).then(() => {
          message.success('复制成功');
        }, (err) => {
          message.error('复制失败');
        });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = locationLink;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          message.success('复制成功');
        } catch (err) {
          message.info('浏览器不支持，请手动复制');
        }
        document.body.removeChild(textArea);
      }
    }
  }

  if (!carInfoWindow.isOpen()) {
    // 如果没有打开，则监听打开事件，获取按钮，添加事件
    carInfoWindow.addEventListener("open", function () {
      copy()
    })
  } else {
    copy()
  }
}

// 点击汽车点位展示汽车信息
export const showCarInfo = async (
  info: any,
  carLnglat: any,
  map: any,
  judegeCount: number,
  copyButtonId: string
) => {
  const plateNumber = info.plate_number;
  const isKls = info?.isKls||false;
  const deviceStatus = info.device_status;
  let content = `<div style="max-width: 300px; font-size: 12px">车牌号：${plateNumber}`;
  const locationLink = `https://uri.amap.com/marker?position=${carLnglat.lng},${carLnglat.lat}&name=${plateNumber}`;
  // 根据id获取车辆详细数据
  if (info.id) {
    const res = await carDetailRequest(info.id);
    const carDetail = res.data?.data || {};
    const {
      sn,
      adas_sn,
      online,
      adasOnline,
      online_at,
      adas_online_at,
      cate_path_name,
      car_principal,
      car_principal_phone,
      car_driver,
      car_driver_phone,
      versionInfo
    } = carDetail;
    // 处理设备状态的显示
    if (deviceStatus) {
      let deviceStatusString = '';
      let deviceStatusCountString = '';
      const isAbnormalDevice = getAbnormalDeviceLevel(
        deviceStatus,
        judegeCount
      ).abnormal;
      if (isAbnormalDevice) {
        if (Array.isArray(deviceStatus)) {
          // 车辆分布接口设备状态为数组形式处理
          deviceStatus.forEach((item: any) => {
            const code = item.code;
            if (Number(code) !== 0) {
              const matchString = faultCodeData[code];
              if (deviceStatusString) {
                deviceStatusString += '，';
                deviceStatusCountString += '，';
              }
              deviceStatusString += matchString || code;
              deviceStatusCountString += `故障码${code}：${item.count}次`;
            }
          });
          if (deviceStatusString) {
            content += `<br>设备状态: ${deviceStatusString}<br>${deviceStatusCountString}`;
          }
        } else if (deviceStatus !== '0') {
          // 车辆列表接口设备状态为字符串形式处理
          const matchString = faultCodeData[deviceStatus];
          deviceStatusString += matchString || deviceStatus;
          content += `<br>设备状态: ${deviceStatusString}`;
        }
      } else {
        content += `<br>设备状态: 正常`;
      }
    }
    content += `<br>是否在线: ${isKls?online:adasOnline}`;
    content += `<br>最后一次在线时间: ${isKls?formatTime(online_at):formatTime(adas_online_at)}`;
    content += `<br>设备SN: ${isKls?sn:adas_sn}`;
    if(isKls){ //只有kls设备显示版本信息
      content += `<br>版本信息: ${versionInfo || '未知'}`;
    }
    if (car_principal) {
      content += `<br>负责人: ${car_principal}`;
    }
    if (car_principal_phone) {
      content += `<br>负责人电话: ${car_principal_phone}`;
    }
    if (car_driver) {
      content += `<br>司机: ${car_driver}`;
    }
    if (car_driver_phone) {
      content += `<br>司机电话: ${car_driver_phone}`;
    }
    content += `<br>分组: ${cate_path_name}`;
  }
  content += `<br>地址链接: <a target='_blank' href="${locationLink}">跳转到地址链接</a><button data-clipboard-text=${locationLink} id=${copyButtonId}>复制链接</button></div>`;
  // 车辆信息的显示
  const carInfoWindow = new window.AMap.InfoWindow({
    content,
    autoMove: true,
    offset: [5, -28]
  });
  carInfoWindow.open(map, carLnglat);
  registerClipboard(copyButtonId);
  // 监听地图缩放时清空信息弹窗
  map.on('zoomend', () => {
    carInfoWindow.close();
  });
};

// 绘制地图上的车辆点位
export const drawCar = (
  carLocation: any,
  map: any,
  judegeCount: number,
  copyButtonId: string,
  judgeLocationFunc?: any
) => {
  const points = [];
  const filterCarLocation = [];
  for (let i = 0; i < carLocation.length; i++) {
    const currCarLocation = carLocation[i];
    const {device_status, lat, lon} = currCarLocation;
    // 获取设备故障等级
    const abnormalDeviceLevel = getAbnormalDeviceLevel(
      device_status,
      judegeCount
    );
    // 转换坐标系
    if (lat && lon) {
      // 有判断条件时执行判断条件
      if (
        judgeLocationFunc &&
        !judgeLocationFunc(abnormalDeviceLevel, currCarLocation)
      ) {
        continue;
      }
      const transformGcoord = gcoord.transform(
        [lat, lon],
        gcoord.WGS84,
        gcoord.AMap
      );
      points.push({
        lnglat: transformGcoord,
        carData: {...currCarLocation, abnormalDeviceLevel}
      });
      filterCarLocation.push(currCarLocation);
    }
  }
  // 渲染单一车辆marker
  const _renderMarker = (context: any) => {
    const carData = context.data[0].carData;
    const lnglat = context.data[0].lnglat;
    let icon = carData.online ? onlineCarIcon : carIcon;
    if (carData.abnormalDeviceLevel.abnormal) {
      // 区分故障次数分级显示不同的图标
      icon = carData.abnormalDeviceLevel.highAbnormal
        ? highAbnormalCarIcon
        : abnormalCarIcon;
    }
    context.marker.setContent(`<div>
      <div style="background: #fff; font-size: 12px; border: 1px solid #000; padding: 0 2px; white-space: nowrap; transform: translateX(-18px)">${carData.plate_number}</div>
      <img width="28" height="28" src=${icon} />
    </div>`);
    context.marker.on('click', () => {
      showCarInfo(carData, lnglat, map, judegeCount, copyButtonId);
    });
  };

  const markerClusterer = new window.AMap.MarkerCluster(map, points, {
    renderMarker: _renderMarker
  });
  // 点击聚合点增大地图缩放级别
  markerClusterer.on('click', (e: any) => {
    // 点击聚合点时才进行地图缩放
    if (e.clusterData?.length > 1) {
      let curZoom = map.getZoom();
      if (curZoom < 20) {
        curZoom += 2;
      }
      map.setZoomAndCenter(curZoom, e.lnglat);
    }
  });
  // 返回要展示的车辆信息以及聚合点信息
  return {
    filterCarLocation,
    markerClusterer
  };
};