/**
 * 单一故障查重车辆信息弹窗
 */
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, message } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { ownRound } from '@/utils/math';
import { errCollectGroupDayRequest, klsErrCollectGroupDayRequest, errCollectDistinctRequest, klsErrCollectDistinctRequest } from '@/service/abnormalSummary';
import styles from '@/styles/pageContent.module.scss';

interface FilterTypeTableModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  dayColumn: any; // 日期列数据
  searchParams: any; // 异常汇总的搜索参数
  errTypeData: any; // 故障项数据
  isKls?: boolean; // 是否是昆仑山设备
}
const FilterTypeTableModal = (props: FilterTypeTableModalProps) => {
  
  const dispatch = useDispatch();
  const [exportLoading, setExportLoading] = useState<boolean>(false); // 导出按钮的loading
  const [visitedCar, setVisitedCar] = useState<any>([]); // 已经查看的车辆
  const [tableData, setTableData] = useState<any>([]); // 表格数据
  const [loading, setLoading] = useState(false); // 表格loading
  const { data, closeModal, dayColumn, searchParams, errTypeData, isKls } = props;
  // 获取数据
  const getData = async() => {
    try {
      setLoading(true);
      const params = {
        ...searchParams,
      }
      let res: any = {};
      // 处理查重时的请求参数(合计查重与其它查重接口不同,针对昆仑山和黄河版本调用不同的接口)
      if (data.isSum) {
        const errorResult: any = {};
        data.checkIndexs.forEach((item: number) => {
          const field = errTypeData[item].value;
          errorResult[field] = data.allData[field];
        })
        params.errorResult = errorResult;
        const requestMethod = isKls ? klsErrCollectDistinctRequest : errCollectDistinctRequest;
        res = await requestMethod(params);
      } else {
        params[errTypeData[data.index].value] = data.tableFormValue[data.index];
        const requestMethod = isKls ? klsErrCollectGroupDayRequest : errCollectGroupDayRequest;
        res = await requestMethod(params);
      }
      // 处理表格数据
      const newTableData: any = [];
      (res.data?.data || []).forEach((item: any) => {
        const obj: any = {
          plate_number: item.plate_number,
          dayCount: item.days,
          ...item,
        };
        if (data.isSum) {
          item.dayCountList.forEach((item1: any) => {
            obj[item1.day] = item1.errors;
          });
        } else {
          item.dayCountList.forEach((item1: any) => {
            obj[item1.day] = item1.total;
          });
        }
        newTableData.push(obj);
      })
      setTableData(newTableData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [])

  // 点击车牌跳转到车辆诊断页面
  const onPlateNumberClick = (plateNumber: string) => {
    setVisitedCar([...visitedCar, plateNumber]);
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: { plateNumber, beginDate: searchParams.beginDate, endDate: searchParams.endDate, }
      }
    })
  }

  // GPS跳跃的查重点击数量跳转到驾驶行为分析页面
  const onCountClick = (plateNumber: string, date: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '驾驶行为分析',
        key: '/car-admin/drive-analysis',
        params: { plateNumber, date }
      }
    })
  }

  // 导出
  const onExport = async () => {
    setExportLoading(true);
    const xlsx = await import('xlsx');
    if (xlsx) {
      const filename = `${data.title.split('(')[0]}复现分布.xlsx`; //文件名称
      // excelData二维数组第一个表示列表名，后边的则是列表名对应的值
      const excelData = [
        [
          '车牌号', '复现天数', ...dayColumn, '车辆SN', '相机SN', 'SIM卡号', '车架号',
          '品牌', '车辆型号', '负责人', '负责人电话', '司机', '司机电话', '参数信息',
          '控制盒固件版本', '安装队长', '在线状态', '安装日期', '分组1','分组2','分组3','分组4',
          '分组5','分组6','分组7','分组8','分组9',
        ],
      ];
      const carInfoField = [
        'sn', 'camera_sn', 'sim', 'frame_number', 'car_brand', 'car_model',
        'car_principal', 'car_principal_phone', 'car_driver', 'car_driver_phone',
        'paramInfo', 'versionInfo', 'installCaptain', 'online', 'installTime', 'cate_path_name'
      ]
      tableData.forEach((item: any) => {
        const rowData = [item.plate_number, item.dayCount];
        dayColumn.forEach((day: string) => {
          rowData.push(renderDayColumn(item[day], item, day))
        });
        carInfoField.forEach((field: string) => {
          if(field =='cate_path_name' && item[field]){
            const  items=  item[field].split('/')
            rowData.push( items[1])
            rowData.push( items[2]) 
            rowData.push( items[3])
            rowData.push( items[4])
            rowData.push( items[5])
            rowData.push( items[6])
            rowData.push( items[7])
            rowData.push( items[8])
            rowData.push( items[9])
          }else{
          rowData.push(item[field])
          }
        });

        excelData.push(rowData);
      });
      const ws_name = "Sheet1"; // Excel第一个sheet的名称
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet(excelData);
      xlsx.utils.book_append_sheet(wb, ws, ws_name);  // 将数据添加到工作薄
      xlsx.writeFile(wb, filename); // 导出Excel
      setExportLoading(false);
      message.success('导出成功');
    } else {
      setExportLoading(false);
      message.error('资源加载失败');
    }
  }

  // 车辆合计去重时显示每天的异常内容
  const renderDayColumn = (value: any, record: any, date: string, isRender?: boolean) => {
    if (data.isSum) {
      const matchErr = errTypeData.filter((item: any) => (value || []).includes(item.value));
      const matchErrLable = matchErr.map((item: any) => item.label);
      return matchErrLable.join('、');
    } else if (data.title === 'GPS定位异常跳跃次数(最小值)' && isRender) {
      return (
        <div className={styles.tableOperateColumn}>
          <Button type="link" onClick={() => onCountClick(record.plate_number, date)}>{ownRound(value, 0)}</Button>
        </div>
      )
    } else {
      return ownRound(value, 0);
    }
  }

  // 表格列显示
  const columns: any = [
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 130,
      fixed: 'left' as 'left',
      render: (value: string) => (
        <div className={styles.tableOperateColumn}>
          {visitedCar.includes(value) ?
            <span style={{ cursor: 'pointer' }} onClick={() => onPlateNumberClick(value)}>{value}(已查看)</span> :
            <Button type="link" onClick={() => onPlateNumberClick(value)}>{value}</Button>
          }
        </div>
      )
    },
    {
      title: '复现天数',
      dataIndex: 'dayCount',
      width: 80,
      fixed: 'left' as 'left',
    },
  ];

  // 添加日期列
  dayColumn.forEach((item: string) => columns.push({
    title: item,
    dataIndex: item,
    width: 100,
    render: (value: any, record: any) => renderDayColumn(value, record, item, true)
  }))

  return (
    <Fragment>
      <Modal
        title={`${data.title.split('(')[0]}复现分布`}
        visible
        footer={null}
        onCancel={closeModal}
        width='70%'
        centered
        getContainer={false}
        maskClosable={false}
      >
        <PermissionButton
          type="primary"
          onClick={onExport}
          operateType='/car-admin/abnormal-summary/filterExport'
          style={{ marginBottom: '12px' }}
          loading={exportLoading}
        >
          导出
        </PermissionButton>
        <CommonTable
          columnFilter={false}
          rowKey='plate_number'
          size='small'
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
          scroll={{ y: 320 }}
          loading={loading}
        />
      </Modal>
    </Fragment>
    
  )
}

export default FilterTypeTableModal;
    