/**
 * 单条诉讼记录
 */
import { Button, Descriptions, Tag } from "antd";
import { useState } from "react";
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import { downloadFile } from '@/utils/download';
import PdfPreviewModal from "@/components/pdfPreviewModal";
import dayjs from "dayjs";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";

interface CarProceedItemProps {
  column: {[key: string]: number};
  data: any;
  type: string;
}
const CarProceed = (props: CarProceedItemProps) => {
  const { column, data } = props;
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  return (
    <Descriptions bordered size="small" column={column}>
      <Descriptions.Item label="案件编号">{data.caseNo}</Descriptions.Item>
      <Descriptions.Item label="合同编号">{data.contractNo}</Descriptions.Item>
      <Descriptions.Item label="诉讼阶段">{data.state}</Descriptions.Item>
      <Descriptions.Item label="处理日期" span={2}>{
        <RenderEllipsisColumn text={data.createdAt ? dayjs(data.createdAt ).format('YYYY-MM-DD') : ''} />
        }</Descriptions.Item>
      <Descriptions.Item label="备注" span={3}>{data.remark}</Descriptions.Item>
      <Descriptions.Item label="附件" span={3}>
        {
              data?.attachments.map((item: any, index: any) => (
                <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
                  <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
                  </div>
                  <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                      type="text"
                      onClick={() => onPdfPreview(item.url, item.fileName)}
                    >
                      <EyeOutlined />
                    </Button>
                    <Button
                      type="link"
                      onClick={() => downloadFile(item.url, item.fileName)}
                      style={{ paddingLeft: 0 }}
                    >
                      <DownloadOutlined />
                    </Button>
                  </div>
                </div>
              ))
        }
      </Descriptions.Item>
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
    </Descriptions>
  )
  
}

export default CarProceed;