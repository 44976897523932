/**
 * 收费记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import { Button, Drawer, Timeline, Divider, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { costRecordListRequest, costRecordDelete } from '@/service/workOrderCostRecord';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import OperateModal from '../../../workOrderCostRecord/components/operateModal';
import CostItem from '../costItem';
import styles from './index.module.scss';

interface MaintenanceRecordProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭收费记录弹层的方法
}
const CostRecord = (props: MaintenanceRecordProps) => {
  const { carInfo, carInfo: { plate_number, id }, onClose } = props;
  const [page, setPage] = useState(1); // 页码
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [recordData, setRecordData] = useState<any>([]); // 收费记录数据
  const [loading, setLoading] = useState<boolean>(false) // 收费记录数据是否在请求中
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息

  // 获取收费记录
  const getMaintenancesRecord = async(newPage: number) => {
    try {
      setLoading(true);
      const res = await costRecordListRequest({ limit: 40, page: 1, carId: id});
      const data = res.data?.data || [];
      if (newPage === 1) {
        setRecordData(data);
      } else {
        setRecordData([...recordData, ...data]);
      }
      setPage(newPage);
      setLoading(false);
      setTotalCount(res.data?.count || 0);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMaintenancesRecord(1);
  }, []);

  // 加载更多
  const loadMore = () => {
    getMaintenancesRecord(page + 1);
  }

  // 删除收费记录
  const deleteRecord = async (id: number) => {
    const res = await costRecordDelete(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getMaintenancesRecord(1);
    }
  }

  const getData = () => {
    getMaintenancesRecord(1);
  }

  return (
    <Drawer
      title={`${plate_number}收费记录`}
      visible
      onClose={onClose}
      className={styles.recordDrawer}
      size="large"
    >
      <PermissionButton
        operateType='/car-admin/car-diagnosis/cost-recoed/add'
        type="primary"
        style={{ marginBottom: '16px' }}
        onClick={() => setOperateInfo({ type: 'add', data: {plateNumber:plate_number} })}
      >
        新增收费记录
      </PermissionButton>
      <LoadingWrapper spinning={loading}>
        <Timeline>
          {recordData.map((item: any) => {
            return (
              <Timeline.Item>
                <div>
                  {item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : item.createdAt}
                  <Popconfirm
                    title="此操作将删除该条收费记录, 是否继续?"
                    onConfirm={() => deleteRecord(item.id)}
                  >
                    <PermissionButton
                      operateType='/car-admin/car-diagnosis/cost-recoed/delete'
                      type="link"
                      style={{ padding: '0 0 0 6px', height: '26px' }}
                    >
                      <DeleteOutlined style={{ color: 'red' }} />
                    </PermissionButton>
                  </Popconfirm>
                </div>
                <CostItem column={{ xs: 1, sm: 2 }} data={item} type="maintenance" />
              </Timeline.Item>
              )
          })}
        </Timeline>
        {recordData.length ?
          <Divider plain>
            {totalCount > recordData.length ?
              <Button type="primary" onClick={loadMore}>加载更多</Button> :
              "已加载全部数据"
            }
          </Divider> :
          <Divider plain>暂无收费记录</Divider>
        }
      </LoadingWrapper>
      {/* 添加、编辑记录 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Drawer>
  )
}

export default CostRecord;