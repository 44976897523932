/**
 * 制动减速异常车辆相关请求
 */
import request from "@/utils/request";

export interface BreakAbnormalParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cate_id?: number | Array<number>;
  min?: number | string;
  max?: number | string;
}

// 获取制动减速异常数据
export const breakAbnormalRequestPath = '/api/v2/car/breakAnomaly';
export const breakAbnormalRequest = (data: BreakAbnormalParams) => {
  return request.post(breakAbnormalRequestPath, data)
}