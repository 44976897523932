import React from 'react';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { klsDeviceStatusData } from '@/constants/klsDeviceStatus';

// 渲染昆仑山设备状态表格列内容
export const renderKlsDeviceStatusColumnText = (
  value: any,
  field: string,
  isKlsV3?: boolean
) => {
  const matchValues = klsDeviceStatusData[field];
  if (matchValues) {
    let showText = matchValues.values[value] || value;
    // 对特殊字段进行特殊格式化展示
    const formatValueFunc = matchValues.formatValue;
    if (formatValueFunc) {
      showText = formatValueFunc(value, isKlsV3);
    }
    return <RenderEllipsisColumn text={showText} ellipsis />;
  } else {
    return value;
  }
};
