/**
 * AEB报警表格配置
 */
import React from 'react';
import { snColumn } from '@/columns/carDetail';
import { getAlarmTypeDisplay } from '@/utils/alarm';
import { formatTime } from '@/utils/formatTime';
import { ownRound } from '@/utils/math';
import { formatGpsSpeed } from '@/utils/gps';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';

// 表格列配置
export const aebAlarmColumns = [
  {
    title: '车牌号',
    dataIndex: 'plateNumber',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />
  },
  snColumn,
  {
    title: '报警类型',
    dataIndex: 'alarmType',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string, item: any) => (
      <RenderEllipsisColumn text={getAlarmTypeDisplay(item)} />
    )
  },
  {
    title: '上报时间',
    dataIndex: 'reportedTime',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={formatTime(value)} />
  },
  {
    title: '车身速度',
    dataIndex: 'vehicleSpeed',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => (
      <RenderEllipsisColumn text={ownRound(value, 1)} />
    )
  },
  {
    title: 'gps速度',
    dataIndex: 'speed',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => (
      <RenderEllipsisColumn text={formatGpsSpeed(value)} />
    )
  },
  {
    title: '协议版本',
    dataIndex: 'version',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />
  },
  {
    title: '雷达方向',
    dataIndex: 'radarLocation',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => (
      <RenderEllipsisColumn text={value === '[]' ? '' : value} />
    )
  },
  {
    title: '雷达ID',
    dataIndex: 'radarId',
    width: 120,
    ellipsis: { showTitle: false },
    // 雷达方向有值时才显示雷达ID内容(过滤雷达ID无效值的显示)
    render: (value: string, item: any) => (
      <RenderEllipsisColumn
        text={
          item.radarLocation && item.radarLocation !== '[]' && value !== '[]'
            ? value
            : ''
        }
      />
    )
  }
];
