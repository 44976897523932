import { carTreeRequest } from '@/service/carTree';

const reloadInterval = 25000; // 定时请求的间隔
let reloadTimer: any = null; // 定时请求数据的定时器

// 初始车辆树数据
export const initCarTreeData = (addLoading: boolean, addCount: boolean) => {
  return async (dispatch: any) => {
    try {
      reloadTimer && clearTimeout(reloadTimer);
      // 添加loading状态
      if (addLoading) {
        dispatch({
          type: 'changeCarTreeLoading',
          payload: true
        });
      }
      const res = await carTreeRequest();
      dispatch({
        type: 'initCarTree',
        payload: {
          data: res.data?.data || [],
          addCount
        }
      });
    } catch (error) {
      /* empty */
    } finally {
      reloadTimer = setTimeout(
        () => dispatch(initCarTreeData(false, false)),
        reloadInterval
      );
    }
  };
};

// 减少使用车辆树数据的组件个数
export const removeCarTreeData = () => {
  return (dispatch: any) => {
    dispatch({ type: 'removeCarTree', payload: reloadTimer });
  };
};
