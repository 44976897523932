/**
 * 运营知识库评论
 */
import React, { useEffect, useState } from "react";
import { Drawer, Timeline, Input, message, Modal, Form, Popconfirm, Button } from 'antd';
import { formatTime } from "@/utils/formatTime";
import LoadingWrapper from '@/components/loadingWrapper';
import { DeleteOutlined } from '@ant-design/icons';
import PermissionButton from "@/components/permissionButton";
import { deleteDocumentComment, documentCommentListRequest, upsertDocumentComment } from "@/service/documentData";
import { useSelector } from "react-redux";

interface DoucumentCommentDetailProps {
  item: any; // 运营知识信息
  onClose: () => void; // 关闭详情弹层的方法
}
const TaskDetail = (props: DoucumentCommentDetailProps) => {
  const [form] = Form.useForm();
  const { item, onClose } = props;
  const [orderDetail, setOrderDetail] = useState<any>([]); // 评论详情
  const [loading, setLoading] = useState<boolean>(false) // 评论详情是否在请求中
  const [isModalOpen, setIsModalOpen] = useState(false); // 评论弹框是否开启
  const [isModalOpenItem, setIsModalOpenItem] = useState<any>(); // 评论那条信息的数据
  const { userDetail } = useSelector((state: any) => state.userStore);

  useEffect(() => {
    getCommentDetail();
  }, []);

  // 获取评论
  const getCommentDetail = async () => {
    try {
      setLoading(true);
      const params: any = { documentId: item.id }
      const res = await documentCommentListRequest(params);
      const data = res.data?.data || {};
      setOrderDetail(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 评论
  const submit = async (values: any) => {
    const params = values;
    //当条知识数据的id
    params.documentId = isModalOpenItem.id
    const res = await upsertDocumentComment(params);
    if (res.data?.code === 0) {
      message.success('评论成功');
      getCommentDetail()
    }
    setIsModalOpen(false);
  }

  // 删除评论
  const deleteComment = async (id: number) => {
    const res = await deleteDocumentComment(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getCommentDetail()
    }
  }

  //新增评论
  const showModal = (item: any) => {
    setIsModalOpenItem(item)
    setIsModalOpen(true);
  };

  //关闭评论框
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Drawer
      title='运营知识评论'
      visible
      size="large"
      onClose={onClose}
    >
      <LoadingWrapper spinning={loading}>
        {/* <Button type="primary" onClick={() => showModal(item)}>新增评论</Button> */}
        <PermissionButton operateType='/car-admin/service-task/addcomment'  type="primary" onClick={() => showModal(item)}>新增评论</PermissionButton>
        <Timeline style={{ marginTop: '24px' }}>
          {(orderDetail || []).map((item: any) => (
            <Timeline.Item key={item.id}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', height: '26px', lineHeight: '26px' }}>
                <div>评论时间：{formatTime(item.createdAt)}</div>
                <div style={{ display: 'flex', paddingRight: '30px' }}>
                  <div>
                    评论人：{item.creator}
                  </div>
                  <div style={{ width: '20px' }}>
                    {item.creator == userDetail.nickname ? <Popconfirm
                      title="是否删除此条评论?"
                      onConfirm={() => deleteComment(item.id)}
                    >
                      <Button type="link" style={{ padding: '0 0 0 6px', height: '26px' }}>

                        <DeleteOutlined style={{ color: 'red' }} />
                      </Button>
                    </Popconfirm> : userDetail.id == 1 ?
                      <Popconfirm
                        title="是否删除此条评论?"
                        onConfirm={() => deleteComment(item.id)}
                      >
                        <Button type="link" style={{ padding: '0 0 0 6px', height: '26px' }}>

                          <DeleteOutlined style={{ color: 'red' }} />
                        </Button>
                      </Popconfirm> : <></>
                    }

                  </div>
                </div>
              </div>
              <div>评论内容：{item.content}</div>
            </Timeline.Item>
          ))}
        </Timeline>
      </LoadingWrapper>
      <Modal title="评论" open={isModalOpen} onOk={() => form.submit()} onCancel={handleCancel}>
        <Form
          form={form}
          autoComplete="off"
          onFinish={submit}
          layout="vertical"
          scrollToFirstError
        >
          <Form.Item
            label="评论内容"
            name="content"
          >
            <Input placeholder="请输入评论内容" />
          </Form.Item>
        </Form>
      </Modal>
    </Drawer>
  )
}

export default TaskDetail;