/**
 * JSON Diff弹窗
 */
import React from 'react';
import { Modal } from 'antd';
import ReactDiffViewer from 'react-diff-viewer';

interface JsonDiffModalProps {
  title: string; // 弹窗标题
  closeModal: () => void; // 关闭弹窗的方法
  oldValue: any;
  newValue: any;
  leftTitle: string;
  rightTitle: string;
}
const JsonDiffModal = (props: JsonDiffModalProps) => {
  const { title, closeModal, oldValue, newValue, leftTitle, rightTitle } =
    props;

  // 对齐前后数据key的显示顺序
  let orderNewValue: any = {};
  if (oldValue && newValue) {
    // 使用新数据中的值对应旧数据key的顺序添加到orderNewValue中
    for (const key in oldValue) {
      if (Object.prototype.hasOwnProperty.call(newValue, key)) {
        orderNewValue[key] = newValue[key];
      }
    }
    // 新数据中有旧数据中没有的key添加到orderNewValue中
    for (const key in newValue) {
      if (!Object.prototype.hasOwnProperty.call(orderNewValue, key)) {
        orderNewValue[key] = newValue[key];
      }
    }
  } else {
    orderNewValue = newValue;
  }

  return (
    <Modal
      title={title}
      open
      footer={null}
      onCancel={closeModal}
      centered
      bodyStyle={{ height: '70vh', overflow: 'auto' }}
      width="70%"
    >
      <ReactDiffViewer
        oldValue={JSON.stringify(oldValue, null, 2)}
        newValue={JSON.stringify(orderNewValue, null, 2)}
        splitView={true}
        leftTitle={leftTitle}
        rightTitle={rightTitle}
      />
    </Modal>
  );
};

export default JsonDiffModal;
