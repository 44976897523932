/**
 * GPS信息
 */
import React, { useState } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { snColumn } from '@/columns/carDetail';
import { formatGpsSpeed } from '@/utils/gps';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { listGpsRequest, listGpsRequestPath } from '@/service/trackPlayback';
import styles from '@/styles/pageContent.module.scss';

const GPSTable = (props: any) => {
  const { beginTime, endTime, virtualCarId } = props;
  const [searchParams, setSearchParams] = useState({
    beginTime: beginTime,
    endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss'),
    carId: virtualCarId
  }); // 搜索相关的参数
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: listGpsRequest,
    initPageSize: 40,
    searchParams
  });

  // 获取导出参数
  const getExportParams = () => {
    return {
      ...searchParams,
      export: true
    };
  };

  // 刷新列表数据
  const onRefresh = () => {
    // 没有结束时间的检测任务使用当前时间作为endTime
    setSearchParams({
      ...searchParams,
      endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss')
    });
  };

  // 表格列显示
  const columns = [
    snColumn,
    {
      title: '时间',
      dataIndex: 'reportedTime',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: 'GPS车速',
      dataIndex: 'speed',
      width: 100,
      render: (value: string) => (
        <RenderEllipsisColumn text={formatGpsSpeed(value)} />
      )
    },
    {
      title: '经度',
      dataIndex: 'lat',
      width: 90
    },
    {
      title: '纬度',
      dataIndex: 'lon',
      width: 90
    }
  ];

  return (
    <div className={styles.commonTableWrapper}>
      <div className={styles.operateBox} style={{ textAlign: 'right' }}>
        <Button onClick={onRefresh}>刷新</Button>
        <ExportButton
          type="primary"
          url={listGpsRequestPath}
          fileName="GPS信息"
          getParamsFunc={getExportParams}
          requestMethod="GET"
          permissionKey="/car-admin/device-inspect/export"
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        size="small"
      />
    </div>
  );
};

export default GPSTable;
