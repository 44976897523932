/**
 * 视频观看记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm, Image, Tag, Select } from 'antd';
import { useSelector } from 'react-redux';
import CommonTable from '@/components/commonTable';
import { operateTimeRange } from '@/utils/operateSearchParams';
import { getInnerPermissionEnable } from '@/utils/permission';
import DatePicker from '@/components/datePicker';
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';
import { videoRequest, videoRequestPath } from '@/service/monitoringRecord'
import { userListRequest } from '@/service/userManage';
import ExportButton from '@/components/exportButton';

const { RangePicker } = DatePicker;
const MonitoringClientRecord = () => {
  const { userDetail } = useSelector((state: any) => state.userStore);

  // 获取接口请求的issuId参数值
  const getSearchIssuId = () => {
    if (getInnerPermissionEnable('/car-admin/work-order/work-order-super')) {
      return undefined;
    } else {
      return userDetail.id;
    }
  }

  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    userId: getSearchIssuId()
  }); // 搜索相关的参数

  const [form] = Form.useForm();
  const [userData, setUserData] = useState([]); // 人员数据
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: videoRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  
  // 获取用户数据
  const getUserData = async () => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setUserData(res.data?.data || [])
  }

  useEffect(() => {
    getUserData();
  }, []);

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
    newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
    delete newSearchParams.day;
    setSearchParams(newSearchParams);
    getData(newSearchParams)
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const newSearchParams = { ...values };
    newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
    newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
    newSearchParams.userId = userDetail.id;
    delete newSearchParams.day;
    values.export = true;
    return values;
}

  // 表格列显示
  const columns = [
    {
      title: '用户名',
      dataIndex: 'userName',
      width: 110,
    },
    {
      title: '车辆',
      dataIndex: 'plateNumber',
      width: 110,
    },
    {
      title: '开始时间',
      dataIndex: 'beginTime',
      width: 110,
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      width: 110,
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '观看时长(s)',
      dataIndex: 'duration',
      width: 110,
    }
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="day"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="用户" name="userId">
              <Select
                placeholder="请选择搜索用户"
                options={userData}
                allowClear
                showArrow
                showSearch
                fieldNames={{
                  label: 'nickname',
                  value: 'id'
                }}
                optionFilterProp="nickname"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={videoRequestPath}
                  fileName={`用户观看记录`}
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/drive-analysis/export'
              />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
    </Card>
  )
}

export default MonitoringClientRecord;
