import {Modal} from "antd";
import React, {useEffect, useState} from "react";
import Thumbnail from "@/components/thumbnail";
import UploadImageVideo from "@/components/uploadImageVideo";
import {addPremiumRequest} from "@/service/offerRecord";


interface AttachmentModalProps {
  open: boolean;
  onClose: (...args: any[]) => any;
  data?: any; //用于回显的数据
}

function AttachmentModal(props: AttachmentModalProps) {

  const {open, onClose, data} = props;

  const fileListWithPreview = (data?.attacheList || []).map((item: any) => {
    return {
      ...item,
      preview: item.url,
    }
  })
  const [fileList, setFileList] = useState<any>(fileListWithPreview);
  //附件上传组件的回调
  const updateFileList = (item: any) => {
    setFileList(item);
  }

  const handleCancel = () => {
    setFileList([])
    onClose();
  }

  const handleOk = async () => {

    const params = {
      ...data
    }

    //删除原有的附件
    if (params.attacheList) {
      delete params.attacheList;
    }
    if (params.attache) {
      delete params.attache;
    }

    params.attaches = fileList.map((item: any, idx: number) => {
      return {
        contentType: item.contentType,
        key: item.key,
        fileName: item.fileName,
        order: idx
      }
    })


    //如果没有附件，删除attaches字段，并且设置attache为空字符串，不然删除会删除会失效
    if (params.attaches.length===0){
      params.attache = '';
      delete params.attaches;
    }
    const res = await addPremiumRequest(params);
    handleCancel();
  }

  return (
    <Modal width={'40vw'} title={'报价附件'} open={open} destroyOnClose={true} onCancel={handleCancel} onOk={handleOk}>
      <UploadImageVideo businessName={'上传附件'} defaultUploadFiles={fileList}
                        uploadFilesChange={updateFileList}
                        canUploadVideo={false} limitSize={10} maxCount={20} multiple={true}
      />
    </Modal>
  )

}


export default AttachmentModal;