import React, { useEffect } from 'react'

import {
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import { devicveDelStatusData } from '@/constants/deviceManage';
import dayjs from 'dayjs';
import { param } from 'jquery';

interface unbindModalProps {
  data: {[key: string]: any};
  closeModal: () => void;
  onOk: (params: any) => void;
}

const unbindModal = (props: unbindModalProps) => {
  const { closeModal } = props
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('removeReason', props.data.operateRemark)
  }, [])
  // 处理提交的参数
  const operateSubmitParams = (values: any) => {
    const {
      deviceRemoveTime,
      removeReason
    } = values

    const params = {
      deviceRemoveTime: deviceRemoveTime ? dayjs(deviceRemoveTime).format('YYYY-MM-DD HH:mm:ss') : undefined,
      removeReason,
      carId: props.data.id
    }
    props.onOk(params);
  }
  // 提交
  const submit = async (values: any) => {
    try {
      operateSubmitParams(values);
    } catch (error: any) {
      message.error(error);
    }
  };

  return (<>
    <Modal onCancel={closeModal} maskClosable={false} onOk={() => form.submit()} title={'解绑设备'} visible >
      <Form onFinish={submit} form={form}>
        <Form.Item label="拆除日期" name="deviceRemoveTime">
          <DatePicker placeholder="请选择时间" style={{ width: "100%" }} showTime allowClear />
        </Form.Item>
        <Form.Item label="拆除原因" name="removeReason">
          <Select options={devicveDelStatusData} allowClear defaultValue={props.data.operateRemark}>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  </>)
}

export default unbindModal