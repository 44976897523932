export const weekDays = [
  { label: '周一', value: 1 },
  { label: '周二', value: 2 },
  { label: '周三', value: 3 },
  { label: '周四', value: 4 },
  { label: '周五', value: 5 },
  { label: '周六', value: 6 },
  { label: '周日', value: 7 },
]

// 表单搜索项的间距
export const formSearchGutter = 12;

// 需要收起左侧menu的页面key
export const collapsedKeys = [
  '/car-admin/real-monitoring',
  '/car-admin/playback-monitoring'
];

// 游客账号分组ID
export const guestCateId = 4577;
