// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","commonTableBox":"commonTable_commonTableBox__HiXdl","filterColumn":"commonTable_filterColumn__HzMNB","rightAction":"commonTable_rightAction__PXn92","tableWrapper":"commonTable_tableWrapper__vYJiI","reactResizableHandle":"commonTable_reactResizableHandle__csabE","filterColumnCheckBox":"commonTable_filterColumnCheckBox__d37Zk"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"commonTableBox\":\"commonTable_commonTableBox__HiXdl\",\"filterColumn\":\"commonTable_filterColumn__HzMNB\",\"rightAction\":\"commonTable_rightAction__PXn92\",\"tableWrapper\":\"commonTable_tableWrapper__vYJiI\",\"reactResizableHandle\":\"commonTable_reactResizableHandle__csabE\",\"filterColumnCheckBox\":\"commonTable_filterColumnCheckBox__d37Zk\"}";
        // 1737772155576
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  