/**
 * 所有账户充值记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm, Image, Tag, Select } from 'antd';
import { useSelector } from 'react-redux';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { operateTimeRange } from '@/utils/operateSearchParams';
import { getInnerPermissionEnable } from '@/utils/permission';
import DatePicker from '@/components/datePicker';
import { useTable } from '@/utils/useTable';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';
import { userListRequest } from '@/service/userManage';
import {videoRequest,delectVideoRequest } from '@/service/videoInvest';
const { RangePicker } = DatePicker;

const VideoInvestRecord = () => {
  const { userDetail } = useSelector((state: any) => state.userStore);
  // 获取接口请求的issuId参数值
  const getSearchIssuId = () => {
    if (getInnerPermissionEnable('/car-admin/work-order/work-order-super')) {
      return undefined;
    } else {
      return userDetail.id;
    }
  }

  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    userId: getSearchIssuId()
  }); // 搜索相关的参数

  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [form] = Form.useForm();
  const [userData, setUserData] = useState([]); // 人员数据

  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: videoRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });


  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
    newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
    delete newSearchParams.day;
    setSearchParams(newSearchParams);
    getData(newSearchParams)
  };


  // 获取用户数据
  const getUserData = async () => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setUserData(res.data?.data || [])
  }

  useEffect(() => {
    getUserData();
  }, []);

  // 删除
  const deleteUser = async (id: number) => {
    const res = await delectVideoRequest(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '订单号',
      dataIndex: 'orderNo',
      width: 110
    },
    {
      title: '是否充值',
      dataIndex: 'payFlag',
      width: 80,
      render: (value:Number) => value == 1 ? '是' : '否',
    },
    {
      title: '充值日期',
      dataIndex: 'investTime',
      width: 110,
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '用户',
      dataIndex: 'userName',
      width: 110,
    },
    {
      title: '充值时长(分钟)',
      dataIndex: 'duration',
      width: 110,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {/* <PermissionButton
              operateType='/car-admin/video-invest/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton> */}
            <Popconfirm
              title="您确定要删除该用户吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/video-invest/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="day"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="用户" name="userId">
              <Select
                placeholder="请选择搜索用户"
                options={userData}
                allowClear
                showArrow
                showSearch
                fieldNames={{
                  label: 'nickname',
                  value: 'id'
                }}
                optionFilterProp="nickname"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/work-order-cost-record/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add', data: {} })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />

      {/* 添加、编辑记录 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
          userId ={getSearchIssuId()}
        />
      }
    </Card>
  )
}

export default VideoInvestRecord;
