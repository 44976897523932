/**
 * 出险记录相关请求
 */
import request from "@/utils/request";

// 获取出险记录
export const accidentRecordRequestPath = '/api/v2/claim/list';
export const accidentRecordRequest = (params: any) => {
  return request.get(accidentRecordRequestPath, {
    params,
  })
}

// 操作出险记录
export const upsertAccidentRecordRequest = (data: any) => {
  return request.post('/api/v2/claim/upsert', data)
}

// 批量处理重复的出险记录
export const insertBatchAccidentRecordRequest = (data: any) => {
  return request.post('/api/v2/claim/insertBatch', data)
}

// 删除出险记录
export const deleteAccidentRecordRequest = (id: number) => {
  return request.delete(`/api/v2/claim/${id}`)
}

// 校验报案号是否重复
export const validCaseNumberRequest = (params: { caseNumber: string }) => {
  return request.get('/api/v2/claim/caseNumberValid', {
    params
  })
}

// 出险报告
export const accidentReportRequestPath = '/api/v2/claim/report';
// 批量导入出险记录
export const importAccidentRecordRequestPath = '/api/v2/claim/import';
