/**
 * 昆仑山AEB参数表格配置
 */
import { canBaudRates, connectTypes } from '@/constants/parameterConfiguration';
import { renderDifferentTableCell } from '@/utils/common';

// 渲染开关
const renderSwitchText = (value: any) => (value ? '开' : '关');

// 渲染获取方式配置
const renderConnectTypes = (value: any) => {
  const matchData = connectTypes.find((item: any) => item.value === value);
  return matchData?.label || value;
};

// 渲染波特率配置
const renderCanBaudRates = (value: any) => {
  const matchData = canBaudRates.find((item: any) => item.value === value);
  return matchData?.label || value;
};

// 表格列配置
export const getKlsAebParamsColumns = (allData: any, isKlsV3?: boolean) => {
  return [
    { title: '参数版本', dataIndex: 'paramsVersion', width: 50 },
    {
      title: 'AEB_CMS全系统使能',
      dataIndex: 'aebCmsEnable',
      render: renderSwitchText,
      width: 90
    },
    {
      title: 'CMS_HMW使能',
      dataIndex: 'cmsHmwEnable',
      render: renderSwitchText,
      width: 80
    },
    {
      title: 'CMS_TTC使能',
      dataIndex: 'cmsTtcEnable',
      render: renderSwitchText,
      width: 70
    },
    {
      title: 'AEB_TTC使能',
      dataIndex: 'aebTtcEnable',
      render: renderSwitchText,
      width: 70
    },
    {
      title: 'SSS使能',
      dataIndex: 'sssEnable',
      render: renderSwitchText,
      width: 50
    },
    {
      title: 'HMW动态阈值使能',
      dataIndex: 'hmwDynamicThrEnable',
      render: renderSwitchText,
      width: 90
    },
    {
      title: '底盘安全策略使能',
      dataIndex: 'chassisSafeStrategyEnable',
      render: renderSwitchText,
      width: 80
    },
    {
      title: '车道偏离预警使能',
      dataIndex: 'ldwEnable',
      render: renderSwitchText,
      width: 80
    },
    {
      title: '显示器开关使能',
      dataIndex: 'displayerSwitchEnable',
      render: renderSwitchText,
      width: 80
    },
    {
      title: '收刹车功能使能',
      dataIndex: 'retractBrakeEnable',
      render: renderSwitchText,
      width: 80
    },
    { title: '刹车冷却时间', dataIndex: 'brakeCoolingTime', width: 70 },
    {
      title: '司机介入后冷却时间',
      dataIndex: 'driverBrakeCoolingTime',
      width: 90
    },
    { title: 'AEB_CMS最低启用车速', dataIndex: 'minEnableSpeed', width: 100 },
    {
      title: 'AEB/CMS允许输出的最大刹车减速度',
      dataIndex: 'maxOutputDec',
      width: 140
    },
    { title: '刹车力度系数', dataIndex: 'ratioForceToDeceleration', width: 70 },
    { title: 'HMW刹车时间阈值', dataIndex: 'cmsHmwBrakeTimeThr', width: 90 },
    {
      title: 'HMW刹车力度参数(体感)',
      dataIndex: 'cmsHmwBrakeForceFeelPara',
      width: 110
    },
    { title: 'HMW预警时间阈值', dataIndex: 'cmsHmwWarningTimeThr', width: 90 },
    {
      title: 'HMW阈值:夜间偏置量',
      dataIndex: 'cmsHmwTimeOffsetNight',
      width: 90
    },
    {
      title: '夜间时间段定义：开始时间(北京时间：24小时)',
      dataIndex: 'cmsHmwTimeOffsetNightStartT',
      width: 170
    },
    {
      title: '夜间时间段定义：结束时间(北京时间：24小时)',
      dataIndex: 'cmsHmwTimeOffsetNightEndT',
      width: 170
    },
    {
      title: 'CMS_TTC缓刹刹车时间阈值',
      dataIndex: 'cmsTtcBrakeTimeThr',
      width: 110
    },
    {
      title: 'CMS_TTC缓刹固定刹车力度值(体感)',
      dataIndex: 'cmsTtcBrakeForceFeelPara',
      width: 140
    },
    {
      title: 'CMS_TTC缓刹一级预警时间',
      dataIndex: 'cmsTtcWarningTimeLevelFirst',
      width: 110
    },
    {
      title: 'CMS_TTC缓刹二级预警时间',
      dataIndex: 'cmsTtcWarningTimeLevelSecond',
      width: 110
    },
    { title: '预设刹车减速度', dataIndex: 'aebDecelerateSet', width: 80 },
    { title: '刹停距离', dataIndex: 'aebStopDistance', width: 50 },
    {
      title: '紧急制动TTC一级预警时间',
      dataIndex: 'aebTtcWarningTimeLevelFirst',
      width: 110
    },
    {
      title: '紧急制动TTC二级预警时间',
      dataIndex: 'aebTtcWarningTimeLevelSecond',
      width: 110
    },
    { title: '刹车力度', dataIndex: 'sssBrakeForce', width: 50 },
    {
      title: '刹车使能距离阈值',
      dataIndex: 'sssBreakEnableDistance',
      width: 80
    },
    {
      title: '预警使能距离阈值',
      dataIndex: 'sssWarningEnableDistance',
      width: 80
    },
    { title: '最大有效车速', dataIndex: 'sssMaxEnableSpeed', width: 70 },
    {
      title: '前向雷达距离侧面安装距离',
      dataIndex: 'sssFrFlInstallDistanceToSide',
      width: 110
    },
    { title: '刹停后距离障碍物距离', dataIndex: 'sssStopDistance', width: 100 },
    { title: '默认转向角度', dataIndex: 'sssDefaultTurnAngle', width: 70 },
    { title: '轮速调整系数', dataIndex: 'wheelSpeedCoefficient', width: 70 },
    {
      title: '电磁阀PWM控制，单周期高电平时间',
      dataIndex: 'pwmMagneticValveT',
      width: 140
    },
    {
      title: '电磁阀PWM控制，每秒高电平次数',
      dataIndex: 'pwmMagneticValveN',
      width: 140
    },
    {
      title: '电磁阀PWM控制，开关',
      dataIndex: 'pwmMagneticValveSwitch',
      render: renderSwitchText,
      width: 100
    },
    {
      title: '电磁阀PWM控制，比例系数',
      dataIndex: 'pwmMagneticValveScale',
      width: 120
    },
    { title: '车身宽度', dataIndex: 'vehicleWidth', width: 60, hide: isKlsV3 },
    {
      title: '车速获取方式',
      dataIndex: 'vehicleSpeedObtainMethod',
      render: renderConnectTypes,
      width: 70
    },
    {
      title: '车身状态获取方式',
      dataIndex: 'vehicleStateObtainMethod',
      render: renderConnectTypes,
      width: 80
    },
    {
      title: 'CAN0波特率：相机',
      dataIndex: 'can0BaudRate',
      render: renderCanBaudRates,
      width: 90,
      hide: isKlsV3
    },
    {
      title: 'CAN1波特率：毫米波雷达',
      dataIndex: 'can1BaudRate',
      render: renderCanBaudRates,
      width: 120,
      hide: isKlsV3
    },
    {
      title: 'CAN2波特率：整车',
      dataIndex: 'can2BaudRate',
      render: renderCanBaudRates,
      width: 90,
      hide: isKlsV3
    },
    {
      title: 'CAN3波特率：比例阀',
      dataIndex: 'can3BaudRate',
      render: renderCanBaudRates,
      width: 100,
      hide: isKlsV3
    },
    {
      title: 'CAN4波特率：显示屏',
      dataIndex: 'can4BaudRate',
      render: renderCanBaudRates,
      width: 100,
      hide: isKlsV3
    },
    {
      title: 'CAN5波特率：超声波雷达',
      dataIndex: 'can5BaudRate',
      render: renderCanBaudRates,
      width: 120,
      hide: isKlsV3
    },
    { title: '车身宽度(角雷达)', dataIndex: 'vehicleWidth2', width: 80 },
    { title: '车身长度(角雷达)', dataIndex: 'vehicleLength', width: 80 },
    { title: '车身高度(角雷达)', dataIndex: 'vehicleHeight', width: 80 },
    {
      title: '车头到后轴距离(角雷达)',
      dataIndex: 'vehicleFrontToRearAxle',
      width: 100
    },
    { title: '刹车时机(角雷达)', dataIndex: 'srrBrakingTime', width: 80 },
    { title: '刹车力度(角雷达)', dataIndex: 'srrBrakingForce', width: 80 },
    {
      title: '角雷达到车头轴距离',
      dataIndex: 'srrDistToVehicleFront',
      width: 90
    },
    {
      title: '角雷达到车辆中心距离',
      dataIndex: 'srrDistToVehicleCenter',
      width: 100
    },
    {
      title: '演示场景V1版本开关',
      dataIndex: 'aebTtcMagicSw',
      render: renderSwitchText,
      width: 90
    },
    {
      title: '演示场景V2版本开关',
      dataIndex: 'aebTtcDemoSw',
      render: renderSwitchText,
      width: 90
    },
    {
      title: '刹车力度(演示场景)',
      dataIndex: 'aebStopDistanceDemo',
      width: 90
    },
    {
      title: '刹车力度系数(演示场景)',
      dataIndex: 'ratioForceToDecelerationDemo',
      width: 100
    },
    {
      title: '刹停后与目标预计距离(演示场景)',
      dataIndex: 'aebDecelerateSetDemo',
      width: 130
    }
  ]
    .filter((item: any) => !item.hide)
    .map((item: any) => {
      const returnItem = { ...item };
      const { noCommonCell, dataIndex } = item;
      if (!noCommonCell) {
        returnItem.onCell = (record: any, rowIndex: number) =>
          renderDifferentTableCell(record, rowIndex, dataIndex, allData);
      }
      return returnItem;
    });
};
