/**
 * AEB制动关闭
 */
import React, { Fragment, useState } from 'react';
import { Form, Button, message, Row, Col } from 'antd';
import dayjs from 'dayjs';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import {
  aebCloseListRequest,
  aebCloseListExportRequestPath,
  aebCloseTimeChartRequest,
  aebCloseCountChartRequest
} from '@/service/aebOff';
import LoadingWrapper from '@/components/loadingWrapper';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import EchartsWrapper from '@/components/echartsWrapper';
import { formSearchGutter } from '@/constants/common';
import { useTable } from '@/utils/useTable';
import { ownEffectiveRound } from '@/utils/math';
import AebOffInfoModal from './components/aebOffInfoModal';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

const AebOff = () => {
  const [selectCarIds, setSelectCarIds] = useState<Array<number>>([]); // 选择的车辆id
  const [hasSearched, setHasSearched] = useState<boolean>(false); // 是否搜索过
  const [timeChartLoading, setTimeChartLoading] = useState<boolean>(false); // 时间图表的loading
  const [timeChartOption, setTimeChartOption] = useState<any>(null); // 时间图表的配置
  const [countChartLoading, setCountChartLoading] = useState<boolean>(false); // 数量图表的loading
  const [countChartOption, setCountChartOption] = useState<any>(null); // 数量图表的配置
  const [searchParams, setSearchParams] = useState<any>({
    beginDate: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    orderQueries: [
      {
        orderField: 'aeb_closed_time',
        orderType: 'DESC'
      }
    ]
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: aebCloseListRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams
  });

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    if (selectCarIds.length === 0) {
      message.info('请选择车辆');
      return;
    }
    const newParams = { ...params };
    delete newParams.time;
    newParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD');
    newParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD');
    newParams.idList = selectCarIds;
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  };

  // 获取时间图表数据
  const getAebCloseTimeChart = async (params: any) => {
    try {
      setTimeChartLoading(true);
      // 获取关闭时长的数据
      const timeRes = await aebCloseTimeChartRequest(params);
      const xData: string[] = [];
      const closeTimes: number[] = [];
      const openTimes: number[] = [];
      (timeRes.data?.data || []).reverse().forEach((item: any) => {
        const { day, aebClosedTime, aebOpenedTime } = item;
        xData.push(day);
        closeTimes.push(ownEffectiveRound(aebClosedTime / 3600, 2));
        openTimes.push(ownEffectiveRound(aebOpenedTime / 3600, 2));
      });
      const chartOption = {
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true, title: '保存为图' }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          }
        },
        legend: {},
        grid: { top: '20%' },
        calculable: true,
        xAxis: { type: 'category', data: xData, offset: 12 },
        yAxis: [
          {
            type: 'value',
            name: '小时',
            position: 'left',
            alignTicks: true
          }
        ],
        series: [
          {
            data: openTimes,
            type: 'line',
            name: 'AEB开启时长',
            itemStyle: {
              color: '#52c41a',
              normal: { label: { show: true } }
            }
          },
          {
            data: closeTimes,
            type: 'line',
            name: 'AEB关闭时长',
            itemStyle: {
              color: '#ff4d4f',
              normal: { label: { show: true, position: 'bottom' } }
            }
          }
        ]
      };
      setTimeChartOption(chartOption);
      setTimeChartLoading(false);
    } catch (error) {
      setTimeChartLoading(false);
    }
  };

  // 获取数量图表数据
  const getAebCloseCountChart = async (params: any) => {
    try {
      setCountChartLoading(true);
      // 获取关闭车辆数的数据
      const countRes = await aebCloseCountChartRequest(params);
      const xData: string[] = [];
      const onlineData: number[] = [];
      const aebOffData: number[] = [];
      (countRes.data?.data || []).forEach((item: any) => {
        const { day, onlineCount, aebOffCount } = item;
        xData.push(day);
        onlineData.push(onlineCount);
        aebOffData.push(aebOffCount);
      });
      const chartOption = {
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true, title: '保存为图' }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          }
        },
        legend: {},
        grid: { top: '20%' },
        calculable: true,
        xAxis: { type: 'category', data: xData, offset: 12 },
        yAxis: [
          {
            type: 'value',
            name: '辆',
            position: 'left',
            alignTicks: true
          }
        ],
        series: [
          {
            data: onlineData,
            type: 'line',
            name: '在线设备数量',
            itemStyle: {
              color: '#52c41a',
              normal: { label: { show: true } }
            }
          },
          {
            data: aebOffData,
            type: 'line',
            name: '关闭设备数量',
            itemStyle: {
              color: '#ff4d4f',
              normal: { label: { show: true, position: 'bottom' } }
            }
          }
        ]
      };
      setCountChartOption(chartOption);
      setCountChartLoading(false);
    } catch (error) {
      setCountChartLoading(false);
    }
  };

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams: any = operateSearchParams(values);
    if (newSearchParams) {
      setSearchParams({
        ...searchParams,
        ...newSearchParams
      });
      setHasSearched(true);
      getAebCloseTimeChart(newSearchParams);
      getAebCloseCountChart(newSearchParams);
    }
  };

  // 获取导出参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return { ...searchParams, ...operateSearchParams(values, true) };
  };

  // 左侧车辆选择
  const treeCheck = (carIds: Array<number>) => {
    setSelectCarIds(carIds);
  };

  // 表格的排序
  const onSort = (pagination: any, filters: any, sorter: any) => {
    const { columnKey: newOrderField, order } = sorter;
    const newOrderType = order === 'ascend' ? 'ASC' : 'DESC';
    const { orderField, orderType } = searchParams.orderQueries[0];
    // 触发排序时修改参数重新请求数据
    if (orderField !== newOrderField || orderType !== newOrderType) {
      setSearchParams({
        ...searchParams,
        orderQueries: [
          {
            orderField: newOrderField,
            orderType: newOrderType
          }
        ]
      });
    }
  };

  // 表格列显示
  const columns: any = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '车辆分组',
      dataIndex: 'catePathName',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '总在线时长(小时)',
      dataIndex: 'onlineTime',
      width: 130,
      render: (value: number) => ownEffectiveRound(value / 3600, 2)
    },
    {
      title: '关闭时长总计(小时)',
      dataIndex: 'aebClosedTime',
      key: 'aeb_closed_time',
      width: 150,
      render: (value: number) => ownEffectiveRound(value / 3600, 2),
      sorter: true,
      defaultSortOrder:
        searchParams.orderQueries[0].orderType === 'DESC'
          ? 'descend'
          : 'ascend',
      sortDirections: ['descend', 'ascend', 'descend']
    },
    {
      title: '关闭时长占比',
      dataIndex: 'percent',
      key: 'percent',
      width: 120,
      render: (value: number) => `${value}%`,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend']
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 60,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="link"
              onClick={() => setOperateInfo({ type: 'info', data: item })}
            >
              详情
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className={styles.spaceBetweenPageContentBox}>
      <div className={styles.carTree}>
        <CarTree checkable treeCheck={treeCheck} />
      </div>
      <div className={styles.contentBox} style={{ overflow: 'auto' }}>
        <Form onFinish={onSearch} form={form}>
          <Row gutter={formSearchGutter}>
            <Col span={16}>
              <Form.Item
                label="时间范围"
                name="time"
                initialValue={[
                  dayjs(searchParams.beginDate),
                  dayjs(searchParams.endDate)
                ]}
              >
                <RangePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  disabledDate={(currentDate: any) =>
                    currentDate &&
                    (currentDate > dayjs().subtract(1, 'days') ||
                      currentDate < dayjs('2024-01-21'))
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                  <ExportButton
                    url={aebCloseListExportRequestPath}
                    fileName="AEB制动关闭"
                    getParamsFunc={getExportParams}
                    permissionKey="/car-admin/aeb-off/export"
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {hasSearched ? (
          <Fragment>
            <LoadingWrapper spinning={timeChartLoading}>
              <EchartsWrapper
                chartOption={timeChartOption}
                style={{ height: '300px' }}
                useTabKey="/car-admin/aeb-off"
              />
            </LoadingWrapper>
            <LoadingWrapper spinning={countChartLoading}>
              <EchartsWrapper
                chartOption={countChartOption}
                style={{ height: '300px' }}
                useTabKey="/car-admin/aeb-off"
              />
            </LoadingWrapper>
            <div className={styles.tableBox} style={{ overflow: 'initial' }}>
              <CommonTable
                rowKey="carId"
                columns={columns}
                dataSource={data}
                pagination={{
                  total: totalCount,
                  current: page,
                  pageSize: limit,
                  onChange: onPageChange
                }}
                bordered
                loading={loading}
                columnFilter={false}
                onChange={onSort}
                scroll={{ y: 400 }}
              />
            </div>
          </Fragment>
        ) : (
          <div className={styles.tipBox}>请在左侧选择车辆后点击“查询”按钮</div>
        )}
      </div>
      {/* 详细信息弹窗 */}
      {operateInfo.type === 'info' && (
        <AebOffInfoModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
          searchParams={searchParams}
        />
      )}
    </div>
  );
};

export default AebOff;
