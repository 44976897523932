/**
 * 传感器方案-设备添加/编辑
 */
import React, { useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';
import SensorSupplierSelect from '@/components/sensorSupplierSelect';
import { upsertSensorDeviceRequest } from '@/service/sensorDeviceManage';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();

  // 编辑时赋值
  useEffect(() => {
    if (['edit', 'detail'].includes(type)) {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存传感器设备信息
  const upsertSensorDevice = async (values: any) => {
    const params = { ...values };
    if (type === 'edit') {
      params.id = data.id;
    }
    const res = await upsertSensorDeviceRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  return (
    <Modal
      title={
        type === 'detail'
          ? '设备详情'
          : type === 'add'
          ? '新增传感器设备'
          : '编辑传感器设备'
      }
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      maskClosable={false}
      okButtonProps={{ style: type === 'detail' ? { display: 'none' } : {} }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={upsertSensorDevice}
        layout="vertical"
        disabled={type === 'detail'}
      >
        <Form.Item
          label="设备名称"
          name="name"
          rules={[{ required: true, message: '请输入设备名称' }]}
        >
          <Input placeholder="请输入设备名称" />
        </Form.Item>
        <Form.Item
          label="设备类型(供应商)"
          name="supplierId"
          rules={[{ required: true, message: '请选择设备类型(供应商)' }]}
        >
          <SensorSupplierSelect />
        </Form.Item>
        <Form.Item
          label="设备型号"
          name="model"
          rules={[{ required: true, message: '请输入设备型号' }]}
        >
          <Input placeholder="请输入设备型号" allowClear />
        </Form.Item>
        <Form.Item
          label="设备版本号"
          name="version"
          rules={[{ required: true, message: '请输入设备版本号' }]}
        >
          <Input placeholder="请输入设备版本号" allowClear />
        </Form.Item>
        <Form.Item label="备注" name="ps">
          <Input.TextArea placeholder="请输入备注" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
