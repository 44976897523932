/**
 * 超速车辆相关请求
 */
import request from "@/utils/request";

export interface OverspeedParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cate_id?: number | Array<number>;
  speed?: number | string;
}

// 获取超速车辆
export const overspeedRequestPath = '/api/v2/car/overspeed';
export const overspeedRequest = (data: OverspeedParams) => {
  return request.post(overspeedRequestPath, data)
}