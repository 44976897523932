/**
 * 异常车辆汇总
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, message, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { errTypeData, klsErrTypeData, adasErrTypeData, defaultTableFormData, klsDefaultTableFormData, adasDefaultTableFormData } from "@/constants/abnormalSummary";
import { abnormalSummaryRequest, klsAbnormalSummaryRequest, adasAbnormalSummaryRequest } from '@/service/abnormalSummary';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import DatePicker from '@/components/datePicker';
import OperationGroupSelect from '@/components/operationGroupSelect';
import AbnormalTable from './components/abnormalTable';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const AbnormalSummary = () => {
  const [form] = Form.useForm();
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    cateId: cateIdArr,
  }); // 搜索相关的参数

  // 搜索
  const onSearch = async (values: any) => {
    if (values.cateId == '') {
      message.warning('分类选择不能为空')
    } else {
      const diffDay = dayjs(values.day[1]).diff(values.day[0], 'day');
      if (diffDay > 30) {
        message.error('时间范围最大支持30天！');
        return;
      }
      const newSearchParams = { ...values };
      newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
      newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
      delete newSearchParams.day;
      setSearchParams(newSearchParams);
    }
  };

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId,
      })
    }
  }

  return (
    <Card bordered={false} className={styles.fixPageTabContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="day"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: any) => currentDate && (currentDate > dayjs().subtract(1, 'days') || currentDate < dayjs('2022-07-13'))}
              />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/abnormal-summary/showOperationGroup'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cateId}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Tabs>
        <Tabs.TabPane tab="黄河设备" key="huanghe">
          <AbnormalTable
            searchParams={searchParams} 
            errTypeData={errTypeData}
            defaultTableFormData={defaultTableFormData}
            requestMethod={abnormalSummaryRequest}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="昆仑山设备" key="kls">
          <AbnormalTable
            searchParams={searchParams}
            errTypeData={klsErrTypeData}
            defaultTableFormData={klsDefaultTableFormData}
            requestMethod={klsAbnormalSummaryRequest}
            isKls
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="adas设备" key="adas">
          <AbnormalTable
            searchParams={searchParams}
            errTypeData={adasErrTypeData}
            defaultTableFormData={adasDefaultTableFormData}
            requestMethod={adasAbnormalSummaryRequest}
            isKls
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export default AbnormalSummary;
  