/**
 * 视频轮巡
 */
import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Button,
  Select,
  Form,
  Row,
  Col,
  Badge,
  Modal,
  Table,
  message,
} from "antd";
import { FullscreenOutlined } from "@ant-design/icons";
import { initCarTreeData, removeCarTreeData } from "@/store/carTree/actions";
import MultipleWindowLayout from "@/components/multipleWindowLayout";
import LiveVideo from "@/components/liveVideo";
import { filterMultipleCateData } from "@/utils/cate";
import { formSearchGutter } from "@/constants/common";
import styles from "@/styles/pageContent.module.scss";
import ownStyles from "./index.module.scss";
import Icon from "@/components/icon";
import { useWebSocket } from "@/utils/useWebSocket";
import { unwatchCarRequest, watchCarRequest } from "@/service/realTimeMonitor";
const alarmMp = require("../../assets/video/alarm.mp3");
import { getInnerPermissionEnable } from "@/utils/permission";

const VideoTurning = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: any) => state.userStore);
  const { carTreeData } = useSelector((state: any) => state.carTreeStore);
  const videoRef = useRef<any>(); // 视频区域DOM
  const switchSecondRef = useRef<number>(10); // 视频切换间隔(秒)
  const windowCountRef = useRef<number>(4); // 视频播放窗口数量
  const timerRef = useRef<any>(null); // 视频定时切换定时器
  const playStartIndexRef = useRef<number>(0); // 页面中视频播放开始的索引
  const allChannelDataRef = useRef<any>([]); // 所有在线的通道数据
  const selectChannelDataRef = useRef<any>([]); // 选择的在线通道数据
  const isUseSelectDataRef = useRef<boolean>(false); // 是否使用选择的数据
  const liveChannelDataRef = useRef<any>([]); // 播放的通道数据
  const [windowCount, setWindowCount] = useState(4); // 视频播放窗口数量
  const [liveChannelData, setLiveChannelData] = useState([]); // 播放的通道数据
  const [isPause, setIsPause] = useState(false); // 是否在暂停轮巡
  const [allCarList, setAllCarList] = useState([]); // 可供选择的车辆数据
  const [withAudio, setWithAudio] = useState(false); // 是否在暂停轮巡
  const [startedWatch, setStartWatch] = useState(false);
  const [isFullScreen, setisFullScreen] = useState(false);
  const fullStatus = useRef(false);
  const [alarmCount, setAlarmCount] = useState<any[]>([]);
  const alarmCountRef = useRef<any[]>([]);
  const [operateModalVisible, setOperateModalVisible] = useState(false);
  const [batchSelectList, setBatchSelectList] = useState<any[]>([]);

  const alarmType = {
    "1": "疲劳驾驶",
    "2": "打电话",
    "3": "抽烟",
    "6": "设备遮挡",
  };

  const operateAlarm = (item: any) => {
    alarmCountRef.current = alarmCountRef.current.filter((inner) => {
      if (inner.alarmId !== item.alarmId) {
        return inner;
      }
    });
    setAlarmCount([...alarmCountRef.current]);
    // setAlarmCount(
    //   alarmCount.filter((inner) => {
    //     if (inner.alarmId !== item.alarmId) {
    //       return inner;
    //     }
    //   })
    // );
  };

  useEffect(() => {
    const music = document.getElementById("alarmVideo") as HTMLElement as any;
    if (alarmCount.length) {
      music?.play();
    } else {
      music?.pause();
      if (music) {
        music.currentTime = 0;
      }
    }
  }, [alarmCount]);

  useEffect(() => {
    if (carTreeData.length === 0) {
      dispatch(initCarTreeData(false, true) as any);
    } else {
      dispatch({
        type: "addCarTree",
      });
    }
    return () => {
      dispatch(removeCarTreeData() as any);
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = null;
    };
  }, []);

  // 获取可以播放视频的通道数据
  const getOnlineChannelData = (treeData: any) => {
    if (Array.isArray(treeData) && treeData.length > 0) {
      const allCarData: any = [];
      const allOnlineData: any = [];
      const deep = (innerData: any) => {
        innerData.forEach((item: any) => {
          const {
            type,
            title,
            jt808IsOnline,
            acc,
            channelNoList,
            jt808DeviceId,
            terminalPhone,
            version,
            jt808OnlineFlag,
            metaId,
          } = item;
          if (
            type === "car" &&
            jt808DeviceId &&
            (jt808OnlineFlag || jt808IsOnline) &&
            acc
          ) {
            (channelNoList || []).forEach((channel: number) => {
              allOnlineData.push({
                channel,
                jt808DeviceId,
                terminalPhone,
                version,
                plateNumber: title,
                metaId,
              });
            });
          }
          if (type === "car" && jt808DeviceId) {
            allCarData.push({
              channel: channelNoList,
              jt808DeviceId,
              terminalPhone,
              version,
              plateNumber: title,
              disabled: !(jt808OnlineFlag || jt808IsOnline) || !acc,
              metaId,
            });
          }
          if (Array.isArray(item.children) && item.children.length > 0) {
            deep(item.children);
          }
        });
      };
      deep(treeData);
      return { allOnlineData, allCarData };
    } else {
      return {};
    }
  };

  const operateAlarmMonitorData = (data: any) => {
    if (
      data?.alarmType == 2 &&
      getInnerPermissionEnable("/car-admin/video-turning/alarm") &&
      fullStatus.current &&
      Object.keys(alarmType).some((item) => {
        return item == data.warningType;
      })
    ) {
      alarmCountRef.current.push(data);
      setAlarmCount([...alarmCountRef.current]);
    }
  };

  // 初始化websocket
  const { wsConnected, openWs } = useWebSocket({
    webSocketPath: "/ws/monitor",
    webSocketFlag: "车辆实时监控",
    onmessage: (data: string) => {
      operateAlarmMonitorData(JSON.parse(data));
    },
  });

  useEffect(() => {
    let newCarTreeData = carTreeData;
    // 多分组用户车辆树形数据特殊处理(兼容选择多分组形式但只有一个分组的形式)
    if (userDetail.multiCate && JSON.parse(userDetail.multiCate)?.length > 1) {
      newCarTreeData = filterMultipleCateData(
        carTreeData,
        JSON.parse(userDetail.multiCate)
      );
    }
    const { allOnlineData = [], allCarData = [] } =
      getOnlineChannelData(newCarTreeData);
    setAllCarList(allCarData);
    allChannelDataRef.current = allOnlineData;
    if (allOnlineData.length && !timerRef.current && !isPause) {
      onPlay();
    }
  }, [carTreeData]);

  useEffect(() => {
    if (
      allCarList.length &&
      !startedWatch &&
      getInnerPermissionEnable("/car-admin/video-turning/alarm")
    ) {
      setStartWatch(true);
      watchCarRequest({
        carIds: allCarList.map((item: any) => {
          return item.metaId;
        }),
      });
    }
  }, [allCarList]);

  useEffect(() => {
    return () => {
      unwatchCarRequest({
        carIds: allCarList.map((item: any) => {
          return item.metaId;
        }),
      });
    };
  }, []);

  useEffect(() => {
    if (getInnerPermissionEnable("/car-admin/video-turning/alarm")) {
      openWs();
    }
  }, []);

  // 全屏
  const fullScreen = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen();
      setisFullScreen(true);
      fullStatus.current = true;
    }
  };

  const exitScreen = () => {
    if (videoRef.current) {
      document.exitFullscreen();
      setAlarmCount([]);
      alarmCountRef.current = [];
      setisFullScreen(false);
      fullStatus.current = false;
    }
  };

  const coloum = [
    {
      title: "序号",
      dataIndex: "index",
      render: (text: string, record: any, index: number) => (
        <span>{index + 1}</span>
      ),
    },
    {
      title: "车牌号",
      dataIndex: "plateNumber",
    },
    {
      title: "DMS报警类型",
      dataIndex: "warningType",
      render: (text: string) => {
        // @ts-ignore
        return alarmType[`${text}`];
      },
    },
    {
      title: "报警时间",
      dataIndex: "reportedAt",
      // render: (text: string) => {
      //   return dayjs(text, "YYYY-MM-DD HH:mm");
      // },
    },
    {
      title: "操作",
      dataIndex: "operate",
      render: (_: string, record: any) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                operateAlarm(record);
              }}
            >
              处理
            </Button>
            <Button
              type="link"
              onClick={() => {
                operateAlarm(record);
              }}
            >
              误报
            </Button>
          </>
        );
      },
    },
  ];

  // 播放
  const onPlay = () => {
    const matchData = isUseSelectDataRef.current
      ? selectChannelDataRef
      : allChannelDataRef; // 区分使用全量数据还是选择的数据
    const playEndIndex = playStartIndexRef.current + windowCountRef.current;
    const channelDataLength = matchData.current.length;
    const newLiveChannelData = matchData.current.slice(
      playStartIndexRef.current,
      playEndIndex
    );
    // 兼容循环结束的情况
    if (
      playEndIndex > channelDataLength &&
      channelDataLength >= windowCountRef.current
    ) {
      newLiveChannelData.push(
        ...matchData.current.slice(0, playEndIndex - channelDataLength)
      );
    }
    liveChannelDataRef.current = newLiveChannelData;
    setLiveChannelData(newLiveChannelData);
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
    // 没有暂停轮巡时定时切换
    if (!isPause) {
      timerRef.current = setTimeout(() => {
        onNext();
      }, switchSecondRef.current * 1000);
    }
  };

  // 上一页
  const onPrev = () => {
    const matchData = isUseSelectDataRef.current
      ? selectChannelDataRef
      : allChannelDataRef; // 区分使用全量数据还是选择的数据
    const channelDataLength = matchData.current.length;
    const startLiveChannel: any = liveChannelDataRef.current[0]; // 正在播放的第一条数据
    if (matchData.current.length && startLiveChannel) {
      // 所有数据中找到当前播放的第一条数据索引时，新的开始索设置为该索引
      const endLiveChannelIndex = matchData.current.findIndex(
        (item: any) =>
          item.channel === startLiveChannel.channel &&
          item.terminalPhone === startLiveChannel.terminalPhone
      );
      if (endLiveChannelIndex >= 0) {
        playStartIndexRef.current = endLiveChannelIndex;
      }
    }
    if (playStartIndexRef.current - windowCountRef.current < 0) {
      playStartIndexRef.current =
        channelDataLength +
        (playStartIndexRef.current - windowCountRef.current);
    } else {
      playStartIndexRef.current =
        playStartIndexRef.current - windowCountRef.current;
    }
    onPlay();
  };

  // 下一页
  const onNext = () => {
    const matchData = isUseSelectDataRef.current
      ? selectChannelDataRef
      : allChannelDataRef; // 区分使用全量数据还是选择的数据
    const channelDataLength = matchData.current.length;
    const endLiveChannel: any =
      liveChannelDataRef.current[liveChannelDataRef.current.length - 1]; // 正在播放的最后一条数据
    let newPlayStartIndex = playStartIndexRef.current + windowCountRef.current; // 新播放数据的起始索引
    if (matchData.current.length && endLiveChannel) {
      // 所有数据中找到当前播放的最后一条数据索引时，新的开始索引为结束索引加1
      const endLiveChannelIndex = matchData.current.findIndex(
        (item: any) =>
          item.channel === endLiveChannel.channel &&
          item.terminalPhone === endLiveChannel.terminalPhone
      );
      if (endLiveChannelIndex >= 0) {
        newPlayStartIndex = endLiveChannelIndex + 1;
      }
    }
    if (newPlayStartIndex >= channelDataLength) {
      playStartIndexRef.current = newPlayStartIndex - channelDataLength;
    } else {
      playStartIndexRef.current = newPlayStartIndex;
    }
    onPlay();
  };

  // 改变轮巡状态
  const changePauseState = () => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
    if (isPause) {
      // 继续轮巡时重新设置定时器
      timerRef.current = setTimeout(() => {
        onNext();
      }, switchSecondRef.current * 1000);
    }
    setIsPause(!isPause);
  };

  // 选择车辆改变
  const onSelectCarChange = (value: any, data: any) => {
    const selectChannel: any = [];
    data.forEach((car: any) => {
      car.channel.forEach((channel: any) => {
        selectChannel.push({
          ...car,
          channel,
        });
      });
    });
    selectChannelDataRef.current = selectChannel;
  };

  const batchOperate = () => {
    if (!batchSelectList.length) {
      message.warning("请选择数据");
    }
    alarmCountRef.current = alarmCountRef.current.filter((inner) => {
      if (
        !batchSelectList.some((select) => {
          return select.alarmId == inner.alarmId;
        })
      ) {
        return inner;
      }
    });
    setAlarmCount([...alarmCountRef.current]);
    setBatchSelectList([]);
  };

  const channelChange = (value: any) => {
    if (!value.length) {
      return;
    }
    const matchData = isUseSelectDataRef.current
      ? selectChannelDataRef
      : allChannelDataRef;
    selectChannelDataRef.current = matchData.current.filter((item: any) => {
      return !!value.find((channel: number) => {
        return item.channel == channel;
      });
    });
  };

  // 确定选择的车辆
  const onConfirmSelectCar = () => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
    isUseSelectDataRef.current = true;
    playStartIndexRef.current = 0;
    onPlay();
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setBatchSelectList([...selectedRows]);
    },
  };

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={styles.spaceBetweenFlex}>
        <Row gutter={formSearchGutter}>
          <Col>
            <Form.Item label="轮巡间隔">
              <Select
                options={[
                  { label: "10秒", value: 10 },
                  { label: "20秒", value: 20 },
                  { label: "30秒", value: 30 },
                  { label: "1分钟", value: 60 },
                  { label: "2分钟", value: 120 },
                ]}
                defaultValue={10}
                onChange={(value: number) => (switchSecondRef.current = value)}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="窗口数量">
              <Select
                options={[
                  { label: "4", value: 4 },
                  { label: "9", value: 9 },
                  { label: "16", value: 16 },
                ]}
                defaultValue={windowCount}
                onChange={(value: number) => {
                  windowCountRef.current = value;
                  setWindowCount(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="轮巡车辆">
              <Select
                options={allCarList}
                maxTagCount="responsive"
                fieldNames={{ label: "plateNumber", value: "jt808DeviceId" }}
                placeholder="请选择轮巡车辆"
                showSearch
                optionFilterProp="plateNumber"
                onChange={onSelectCarChange}
                mode="multiple"
                style={{ width: "180px" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="视频通道">
              <Select
                placeholder="请选择视频通道"
                options={[
                  {
                    label: "CH1",
                    value: 1,
                  },
                  {
                    label: "CH2",
                    value: 2,
                  },
                  {
                    label: "CH3",
                    value: 3,
                  },
                  {
                    label: "CH4",
                    value: 4,
                  },
                ]}
                onChange={channelChange}
                mode="multiple"
                style={{ width: "260px" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="">
              <Button type="primary" onClick={onConfirmSelectCar}>
                确定轮巡车辆
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.operateBox}>
          <Button
            type="primary"
            onClick={() => {
              setWithAudio(!withAudio);
            }}
          >
            {withAudio ? "关闭声音" : "打开声音"}
          </Button>
          <Button type="primary" onClick={changePauseState}>
            {isPause ? "继续轮巡" : "暂停轮巡"}
          </Button>
          <Button type="primary" onClick={onPrev}>
            上一页
          </Button>
          <Button type="primary" onClick={onNext}>
            下一页
          </Button>
          <Button icon={<FullscreenOutlined />} onClick={fullScreen}>
            全屏
          </Button>
        </div>
      </div>
      <div className={ownStyles.videoBox} ref={videoRef}  style={{display: "flex",flexDirection: "column",}}>
        <audio
          style={{ height: "0", width: "0" }}
          controls
          src={alarmMp}
          id="alarmVideo"
          loop
        ></audio>
        <Modal
          getContainer={false}
          title="DMS报警信息"
          open={operateModalVisible}
          onCancel={() => {
            setOperateModalVisible(false);
          }}
          onOk={() => {
            setOperateModalVisible(false);
          }}
          maskClosable={false}
          width={800}
        >
          <div
            style={{
              marginBottom: "30px",
              position: "relative",
              maxHeight: '400px',
              overflow: 'auto'

            }}
          >
            <Table
              pagination={false}
              columns={coloum}
              dataSource={alarmCount}
              rowKey={"alarmId"}
              rowSelection={rowSelection}
            ></Table>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {" "}
              <Button
                type="primary"
                onClick={() => {
                  batchOperate();
                }}
              >
                批量处理
              </Button>{" "}
              <Button
                onClick={() => {
                  batchOperate();
                }}
              >
                批量误报
              </Button>
            </div>
          </div>
        </Modal>
        {getInnerPermissionEnable("/car-admin/video-turning/alarm") &&
          isFullScreen && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 10px 5px 10px",
              }}
            >
              {" "}
              <div
                style={{
                  paddingRight: "20px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
                onClick={() => {
                  setOperateModalVisible(true);
                }}
              >
                {/* https://img.tukuppt.com/newpreview_music/08/99/14/5c88e51168d3728345.mp3 */}

                <Badge count={alarmCount.length}>
                  <Icon
                    type="icon-alarm"
                    style={{ fontSize: "24px", color: "red" }}
                  ></Icon>
                </Badge>
              </div>
              <Button
                onClick={() => {
                  exitScreen();
                }}
              >
                退出全屏
              </Button>
            </div>
          )}
        <div style={{ flex: 1 }}>
          <MultipleWindowLayout
            windowCount={windowCount}
            windowAllData={liveChannelData}
            gap={4}
            className={ownStyles.multipleWindowLayout}
          >
            <LiveVideo audio={withAudio} />
          </MultipleWindowLayout>
        </div>
      </div>
    </Card>
  );
};

export default VideoTurning;
