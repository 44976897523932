/**
 * 单条维保记录的展示(兼容通话记录形式)
 */
import React from "react";
import { Descriptions, Tag } from "antd";
import Thumbnail from '@/components/thumbnail';
import { renderTypeColumns } from '@/utils/workOrder';
import PermissionButton from "@/components/permissionButton";
import { DownloadOutlined, FileZipOutlined } from '@ant-design/icons';
import { downloadFile } from '@/utils/download';

interface MaintenanceItemProps {
  column: {[key: string]: number};
  data: any;
  type: string;
}
const MaintenanceItem = (props: MaintenanceItemProps) => {
  const { column, data, type } = props;
  if (type === 'maintenance') {
    return (
      <Descriptions bordered size="small" column={column}>
        <Descriptions.Item label="联系人">{data.contactName ? `${data.contactName}(${data.contactType === 1 ? '负责人' : '司机'})` : ''}</Descriptions.Item>
        <Descriptions.Item label="联系人电话">{data.contactPhone}</Descriptions.Item>
        <Descriptions.Item label="维保员">{data.maintainer}</Descriptions.Item>
        <Descriptions.Item label="维保员电话">{data.maintainerPhone}</Descriptions.Item>
        <Descriptions.Item label="工单类型">{renderTypeColumns(data.type)}</Descriptions.Item>
        <Descriptions.Item label="问题来源">{data.category}</Descriptions.Item>
        <Descriptions.Item label="现场问题" span={2}>{data.problemType}</Descriptions.Item>
        <Descriptions.Item label="地址" span={2}>{data.address}</Descriptions.Item>
        <Descriptions.Item label="问题原因" span={2}>
          {(Object.values(data.tags || {})[0] as any || []).map(((item1: any) => <Tag key={item1.tagId}>{item1.title}</Tag> ))}
        </Descriptions.Item>
        <Descriptions.Item label="处理故障项" span={2}>{(data.malfunctions || []).map((data: string) => <div key={data}>{`· ${data}`}</div>)}</Descriptions.Item>
        <Descriptions.Item label="维护明细" span={2}>{(data.maintenances || []).map((data: string) => <div key={data}>{`· ${data}`}</div>)}</Descriptions.Item>
        {/* <Descriptions.Item label="维保图片/视频" span={2}>
          <Thumbnail
            dataSource={data.resources || []}
            downloadImgName="维保图片"
            downloadVideoName="维保视频"
          />
        </Descriptions.Item> */}
        <Descriptions.Item label="压缩文件" span={2}>
          {(data.zipResources || []).map((item:any) => 
            <div style={{width:'240px',marginRight:'20px',display:'flex',marginBottom:'10px',border:'1px solid rgb(217,217,217)',padding:'5px'}}>
            <div style={{width:'205px',paddingLeft:'5px',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <FileZipOutlined style={{paddingRight:'5px'}} />{item.fileName}
            </div>
            <div style={{width:'30px', display:'flex',justifyContent:'center',alignItems:'center'}}>
              <PermissionButton
                operateType='/car-admin/car-diagnosis/maintenance-file-download'
                type="link"
                onClick={() => downloadFile(item.url, item.fileName)}
                style={{ paddingLeft: 0 }}
              >
                <DownloadOutlined />
              </PermissionButton>
            </div>
          </div>)}
        </Descriptions.Item>
      </Descriptions>
    )
  } else if (type === 'callRecord') {
    return (
      <Descriptions bordered size="small" column={column}>
        <Descriptions.Item label="客服名称">{data.agentNickName}</Descriptions.Item>
        <Descriptions.Item label="客户电话">{data.callNumber}</Descriptions.Item>
        <Descriptions.Item label="呼叫类型">{data.callType}</Descriptions.Item>
        <Descriptions.Item label="通话结果">{data.callResult}</Descriptions.Item>
        <Descriptions.Item label="通话时间">{data.callStartAt}</Descriptions.Item>
        <Descriptions.Item label="通话时长">{data.callTime}</Descriptions.Item>
        {Boolean(data.recordUrl) && <Descriptions.Item label="通话记录" span={2}>
          <audio controls src={data.recordUrl}></audio>
        </Descriptions.Item>}
      </Descriptions>
    )
  } else {
    return (
      <Descriptions bordered size="small" column={column}>
      <Descriptions.Item label="客服">{data.maintainer}</Descriptions.Item>
      <Descriptions.Item label="问题来源">{data.category}</Descriptions.Item>
      <Descriptions.Item label="联系结果">{data.result}</Descriptions.Item>
      <Descriptions.Item label="问题原因" span={3}>
        {<div>{(Object.values(data.customerTags || {})[0] as any || []).map(((item1: any) => <Tag key={item1.tagId}>{item1.title}</Tag>))}</div>}
      </Descriptions.Item>
      <Descriptions.Item label="记录内容" span={3}>{data.problemType}</Descriptions.Item>
      <Descriptions.Item label="客服记录图片/视频" span={3}>
          <Thumbnail
            dataSource={data.resources || []}
            downloadImgName="客服记录图片"
            downloadVideoName="客服记录视频"
          />
      </Descriptions.Item>
    </Descriptions>
    )
  }
}

export default MaintenanceItem;