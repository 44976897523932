// extracted by mini-css-extract-plugin
export default {"reportBox":"offerManage_reportBox__Ib-tZ","searchBox":"offerManage_searchBox__bRkw5","analysisMap":"offerManage_analysisMap__l3+u5","innerTitle":"offerManage_innerTitle__Q52X4","tableOperateColumn":"offerManage_tableOperateColumn__KsYxI"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"reportBox\":\"offerManage_reportBox__Ib-tZ\",\"searchBox\":\"offerManage_searchBox__bRkw5\",\"analysisMap\":\"offerManage_analysisMap__l3+u5\",\"innerTitle\":\"offerManage_innerTitle__Q52X4\",\"tableOperateColumn\":\"offerManage_tableOperateColumn__KsYxI\"}";
        // 1737772154762
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  