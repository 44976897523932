// 控制盒固件选择项数据
export const controlData = [
  { label: '全部', value: 0 },
  { label: '已升级', value: 1 },
  { label: '未升级', value: 2 },
]

// 故障项数据(黄河设备)
export const errTypeData = [
  { label: '车速为0(最小值)', value: 'vs0' },
  { label: 'GPS速度为0(最小值)', value: 'gs0' },
  { label: '车速超120(最小值)', value: 'vsm120' },
  { label: '故障码2(最小值)', value: 'err2' },
  { label: '故障码31(最小值)', value: 'err31' },
  { label: '故障码91(最小值)', value: 'err91' },
  { label: '故障码130(最小值)', value: 'err130' },
  { label: '故障码133(最小值)', value: 'err133' },
  { label: '百公里hmw预警次数(最大值)', value: 'hmwMax' },
  { label: '百公里超声波制动次数(最大值)', value: 'ultrMax' },
  { label: '百公里hmw预警次数(最小值)', value: 'hmwMin' },
  { label: '百公里双目制动次数(最小值)', value: 'binMin' },
  { label: '百公里超声波制动次数(最小值)', value: 'ultrMin' },
  { label: '离线天数(最小值)', value: 'offline' },
  { label: 'AEB开关关闭次数(最小值)', value: 'aebOff' },
  { label: 'GPS定位异常跳跃次数(最小值)', value: 'gpsJump' },
  { label: '风险次数(最小值)', value: 'hmwRisk' },
]

// 故障项数据(昆仑山设备)
export const klsErrTypeData = [
  { label: '车速为0(最小值)', value: 'vs0' },
  { label: 'GPS速度为0(最小值)', value: 'gs0' },
  { label: '车速超120(最小值)', value: 'vsm120' },
  { label: '故障码2(最小值)', value: 'err2' },
  { label: '故障码31(最小值)', value: 'err31' },
  { label: '故障码91(最小值)', value: 'err91' },
  { label: '百公里hmw预警次数(最大值)', value: 'hmwMax' },
  { label: '百公里超声波制动次数(最大值)', value: 'ultrMax' },
  { label: '百公里hmw预警次数(最小值)', value: 'hmwMin' },
  { label: '百公里双目制动次数(最小值)', value: 'binMin' },
  { label: '百公里超声波制动次数(最小值)', value: 'ultrMin' },
  { label: '离线天数(最小值)', value: 'offline' },
  { label: 'AEB开关关闭次数(最小值)', value: 'aebOff' },
  { label: '百公里右侧盲区毫米波制动次数(最小值)', value: 'bsdBrakMin' },
  { label: '百公里控制器故障次数(最小值)', value: 'controllerErrMin' },
  { label: '百公里比例阀故障次数(最小值)', value: 'proportionalErrMin' },
  { label: '百公里4G故障次数(最小值)', value: 'err4gMin' },
  { label: '百公里GPS故障次数(最小值)', value: 'gpsMin' },
  { label: '百公里右侧盲区毫米波故障次数(最小值)', value: 'bsdErrMin' },
  { label: '百公里IMU故障次数(最小值)', value: 'imuErrMin' },
  { label: 'GPS定位异常跳跃次数(最小值)', value: 'gpsJump' },
  { label: '风险次数(最小值)', value: 'hmwRisk' }
]

// 故障项数据(adas设备)
export const adasErrTypeData = [
  // { label: '紧急报警次数', value: 'urgentAlarm' },
  // { label: '超速报警次数', value: 'overSpeedAlarm' },
  // { label: '疲劳报警次数', value: 'tirAlarm' },
  // { label: '车道偏离报警次数', value: 'outlineAlarm' },
  // { label: '前向碰撞报警次数', value: 'fcwAlarm' },
  // { label: '右后方接近报警次数', value: 'rightAlarm' },
  // { label: '抽烟报警次数', value: 'smokeAlarm' },
  // { label: '分神报警次数', value: 'distractionAlarm' },
  // { label: '驾驶员异常次数', value: 'abnormalAlarm' }
  { label: '驾驶员异常次数', value: 'daw' }
]

// 表格过滤条件的初始值(黄河设备)
export const defaultTableFormData = [
  { count: 100, mcuVersion: 0, hmwMax: 0, ultrMax: null, mileageMax: null, mileageMin: 10, aebMax: null },
  { count: 100, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: 0, mileageMin: null, aebMax: null },
  { count: 50, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 10, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: 0, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 100, mcuVersion: 0, hmwMax: 0, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: 10, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: null, ultrMax: 0, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 100, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 0, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 60, aebMax: null },
  { count: 0, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 300, aebMax: null },
  { count: 250, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 40, aebMax: null },
  { count: 60, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 60, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 40, aebMax: null },
  { count: 15, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 1, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 1, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 50, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 5, aebMax: null },
]

// 表格过滤条件的初始值(昆仑山设备)
export const klsDefaultTableFormData = [
  { count: 50, mcuVersion: 0, hmwMax: 0, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 100, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: 0, mileageMin: null, aebMax: null },
  { count: 50, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 10, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: 0, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 100, mcuVersion: 0, hmwMax: 0, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: 10, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 0, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 60, aebMax: null },
  { count: 0, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 300, aebMax: null },
  { count: 250, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 40, aebMax: null },
  { count: 60, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 0, aebMax: null },
  { count: 60, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 40, aebMax: null },
  { count: 15, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 1, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 30, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 5, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 5, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 10, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 1, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: null, aebMax: null },
  { count: 50, mcuVersion: 0, hmwMax: null, ultrMax: null, mileageMax: null, mileageMin: 5, aebMax: null },
]

// 表格过滤条件的初始值(adas设备)
export const adasDefaultTableFormData = [
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  // { count: 10, mileageMax: null, mileageMin: 10 },
  { count: 5, mileageMax: null, mileageMin: 1 },
]
