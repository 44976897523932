/**
 * 指标说明弹窗
 */
import React from 'react';
import { Modal } from 'antd';
import CommonTable from '@/components/commonTable';

interface IndicatorDescriptionModalProps {
  closeModal: () => void; // 关闭弹窗的方法
}
const IndicatorDescriptionModal = (props: IndicatorDescriptionModalProps) => {
  const { closeModal } = props;
  const columns = [
    {
      title: '指标名称',
      dataIndex: 'title',
    },
    {
      title: '指标定义',
      dataIndex: 'definition',
    },
    {
      title: '详细描述',
      dataIndex: 'desc',
    },
    {
      title: '计算方式',
      dataIndex: 'compute',
    },
    {
      title: '默认权重',
      dataIndex: 'weight',
    },
    {
      title: '权重说明',
      dataIndex: 'weightDesc',
    },
  ];
  const tableData = [
    {
      title: '故障持续时间',
      definition: '设备故障从第一次出现到运维后故障修复的时间长度，以自然日计算',
      desc: '1. 故障的定义，与异常车辆汇总的定义相同，例如车速为0超过100次算作车速为0故障。2. 监控的故障项： 车速为0， gps车速为0， 故障码2， 故障码31， 故障码91 、故障码133、车速超120等。3. 故障第一次出现，指在统计周期内的第一次',
      compute: '统计周期内，有1个及以上故障的全部车，带故障运行的总天数， 除以总的故障车辆数',
      weight: '50%',
      weightDesc: '设备运行不正常影响降损及司机感受；反应各运维小组对各自负责车队运行状况的信息掌握程度及处理能力'
    },
    {
      title: '工单重开率',
      definition: '同一台车同一故障运维完成后经验证故障未解决，记作一次重开。', 
      desc: '未解决故障是指单一故障，且以重开时间为基准进行统计（前提条件是，派单时为每个故障单独派单）',
      compute: '统计周期内，因验证未通过的工单数，除以验证通过的工单数',
      weight: '10%',
      weightDesc: '反应的是运维小组+安装队的故障处理质量'
    },
    {
      title: '客诉率',
      definition: '因为服务质量客诉占客诉总体的比例',
      desc: '服务质量客诉包括客服质量、安装服务质量、运维服务质量等',
      compute: '统计周期内，服务质量客诉条数，除以总客诉条数',
      weight: '10%',
      weightDesc: '反应除产品原因外，客户对服务的满意程度'
    },
    {
      title: '故障率',
      definition: '有1个及以上故障类型的车辆的出车天数与总车次的比值，总车次指规定时间范围内出车的天数',
      desc: '1. 故障的定义，与异常车辆汇总的定义相同。2. 监控的故障项： 车速为0， gps车速为0， 故障码2， 故障码31， 故障码91 、故障码133、车速超120等 ',
      compute: '统计周期内， 有1个及以上故障的车的出车次数，除以总的出车次数',
      weight: '30%',
      weightDesc: '仅作为参考指标，与所分配车辆状况直接相关',
    },
  ]
  return (
    <Modal
      title="指标说明"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      width='80%'
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <div>
        <h3 className='boldText'>统计范围定义：</h3>
        <ul>
          <li>运维组评价时间区间： 以自然月为单位进行评价报告的生成。 不支持当前月和未来月份的报告生成。</li>
          <li>运维组评价的范围： 仅展示统计周期内已经存在的运维组</li>
          <li>故障指标统计范围：vs0，gs0，err2，err31，err91，err130，err133， vsm120</li>
        </ul>
        <h3 className='boldText'>统计指标定义：</h3>
        <CommonTable
          rowKey='title'
          columnFilter={false}
          size='small'
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
          scroll={{}}
        />
        <h3 className='boldText'>分值计算公式：</h3>
        <div>对于每个运维组，其最终得分的计算公式为：</div>
        <div className='boldText'>得分 =（1-故障持续时间）*  100  * 权重  + （1-故障率）*  100  * 权重  + （1-工单重开率）*  100  * 权重  + （1-客诉率）*  100  * 权重 </div>
        <h3 className='boldText'>注意：</h3>
        <div className='boldText'>当前无工单重开率和客诉率的数据，所以此2项得分均设置为满分，其比重降低至10%。 </div>
      </div>
    </Modal>
  )
}

export default IndicatorDescriptionModal;
    