/**
 * 车辆诊断设备记录仪异常数据(昆仑山设备)
 */
import React, { useState, useEffect, Fragment } from 'react';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import {
  carDiagnosisModemErrorRequest,
  carDiagnosisModemErrorRequestPath
} from '@/service/carDiagnosis';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';

interface KlsVersionTableProps {
  diagnosisSearchParams: any;
  getExportParams: () => any;
}

const KlsModemErrorTable = (props: KlsVersionTableProps) => {
  const { diagnosisSearchParams, getExportParams } = props;
  const [searchParams, setSearchParams] = useState(diagnosisSearchParams);
  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    setSearchParams(diagnosisSearchParams);
  }, [diagnosisSearchParams]);

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carDiagnosisModemErrorRequest,
    initRequest: Boolean(diagnosisSearchParams),
    initPageSize: 40,
    searchParams
  });

  const columns = [
    {
      title: '上报时间',
      dataIndex: 'reportedTime',
      width: 170,
      render: (value: any) => formatTime(value),
      fixed: 'left' as const
    },
    {
      title: '设备SN',
      dataIndex: 'mcuSn',
      width: 170
    },
    {
      title: '异常信息',
      dataIndex: 'eventCodeDesc'
    },
    {
      title: '异常码',
      dataIndex: 'eventCode',
      width: 100
    }
  ];

  return (
    <Fragment>
      <div style={{ margin: '12px 0', textAlign: 'right' }}>
        <ExportButton
          type="primary"
          disabled={!diagnosisSearchParams}
          url={carDiagnosisModemErrorRequestPath}
          fileName={`${diagnosisSearchParams?.plateNumber}记录仪异常信息`}
          getParamsFunc={getExportParams}
          requestMethod="GET"
          permissionKey="/car-admin/car-diagnosis/modem-error-export"
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        scroll={{ x: '100%', y: 400 }}
      />
    </Fragment>
  );
};

export default KlsModemErrorTable;
