/**
 * 操作保司信息
 */
import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, message, Select} from 'antd';
import {upsertInsurer} from '@/service/insurerManage';
import {log} from "console";

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}

const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const {reloadData, closeModal, type, data} = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [areaCode, setAreaCode] = useState<any>([]); // 车队选择

  useEffect(() => {
    carInterviewEdit()
  }, []);

  // 编辑的时候赋值
  const carInterviewEdit = async () => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
  }

  //状态
  const safeState = [
    {label: '启用', value: '启用'},
    {label: '停用', value: '停用'},
  ]

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }

      //检测项目id
      if (params.projectId) {
        if (!/^\d+$/.test(params.projectId)) {
          message.error('项目id只能为整数');
          setSubmitNoRepetition(true)
          return;
        }
      }

      const res = await upsertInsurer(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }


  return (
    <Modal
      title={type === 'add' ? '添加保司' : '编辑保司'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{maxHeight: '70vh', overflow: 'auto'}}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError>
        <Form.Item
          label="归属项目id"
          name="projectId"
          rules={[{required: true, message: '请输入归属项目id'}]}>
          <Input placeholder="请输入归属项目id" allowClear/>
        </Form.Item>
        <Form.Item
          label="保司"
          name="name"
          rules={[{required: true, message: '请输入保司'}]}>
          <Input placeholder="请输入保司" allowClear/>
        </Form.Item>
        <Form.Item
          label="状态"
          name="state"
          rules={[{required: true, message: '请选择状态'}]}
        >
          <Select
            placeholder="请选择状态"
            options={safeState}
            allowClear
            showArrow
            optionFilterProp="label"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
