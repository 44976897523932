/**
 * 渠道评价指标
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Form, Row, Col, Button, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { chunk } from 'lodash';
import NP from 'number-precision';
import { channelAssessmentRequest, channelAssessmentRequestPath } from '@/service/channelAssessment';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import OperationGroupSelect from '@/components/operationGroupSelect';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import { limitDayRange } from '@/utils/operateSearchParams';
import { ownRound } from '@/utils/math';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { getPagePermissionEnable, getInnerPermissionEnable } from '@/utils/permission';
import commonStyles from '@/styles/pageContent.module.scss';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;
const ChannelAssessment = () => {
  const cateIdArr = useUserCateIdArr();
  const dispatch = useDispatch();
  // 是否有跳转驾驶行为分析的权限
  const canJumpDriveAnalysis = getPagePermissionEnable('/car-admin/drive-analysis');
  // hmw阈值是否显示
  const hmwThresholdIsShow = getInnerPermissionEnable('/car-admin/channel-assessment/hmw-threshold-is-show');
  // 在线时长阈值是否显示
  const timeThresholdIsShow = getInnerPermissionEnable('/car-admin/channel-assessment/time-threshold-is-show');
  // 阈值是否可以修改
  const channelAssessDisabled = !getInnerPermissionEnable('/car-admin/channel-assessment/threshold-normal');
  const [allData, setAllData] = useState([]); // 全量数据
  const [data, setData] = useState([]); // 数据
  const [summary, setSummary] = useState<any>({}); // 数据汇总信息
  const [dayColumns, setDayColumns] = useState([]); // 日期列数据
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [pageNum, setPageNum] = useState(1); // 页码
  const [pageSize, setPageSize] = useState(40); // 每一页显示的数量
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    hmwThreshold: 80,
    runtimeThreshold: 0,
    cateId: cateIdArr
  }); // 搜索相关的参数
  const [form] = Form.useForm();

  useEffect(() => {
    getData({
      page: 1,
      limit: 30,
      ...searchParams
    })
  }, [])

  // 获取数据
  const getData = async (params: any) => {
    try {
      setLoading(true);
      const res = await channelAssessmentRequest(params); 
      const resData = res.data?.data?.carAssessDtos || [];
      setData(resData.slice(0, 40));
      setAllData(resData);
      setTotalCount(resData.length);
      setDayColumns(res.data?.data?.days || []);
      setSummary({
        hmwOkRate: res.data?.data?.hmwOkRate,
        onlineRate: res.data?.data?.onlineRate,
        amendRate: res.data?.data?.amendRate,
        suspendedRate: res.data?.data?.suspendedRate,
        abnormalUseRate: res.data?.data?.abnormalUseRate
      });
      setLoading(false);
      setPageNum(params.page);
    } catch (error) {
      setLoading(false);
    }
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId,
      })
    }
  }

  // 搜索
  const onSearch = async (values: any) => {
    if (values.hmwThreshold == undefined) {
      values.hmwThreshold = 80
    }
    if (values.runtimeThreshold == undefined) {
      values.runtimeThreshold = 0
    }
    const newSearchParams = limitDayRange(values, 31);
    if (newSearchParams) {
      setSearchParams(newSearchParams);
      getData({
        page: 1,
        limit: pageSize,
        ...newSearchParams, 
      });
    }
  };

  // 翻页器信息改变
  const onPageChange = (page: number, newPageSize: number) => {
    const newPageNum = newPageSize !== pageSize ? 1 : page;
    setPageNum(newPageNum);
    setPageSize(newPageSize);
    setData(chunk(allData, newPageSize)[newPageNum - 1]);
  }

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return limitDayRange(values, 31, true);
  }

  // 渲染表格标题
  const renderTableTitle = () => {
    if (summary?.onlineRate || summary?.hmwOkRate || summary?.amendRate || summary?.suspendedRate) {
      const dispalyOnlineRate = ownRound(NP.times(summary.onlineRate, 100), 1);
      const dispalyAmendRate = ownRound(NP.times(summary.amendRate, 100), 1);
      const allOnlineRate = NP.plus(dispalyOnlineRate, dispalyAmendRate);
      const dispalyHmwOkRate = ownRound(NP.times(summary.hmwOkRate, 100), 1);
      const dispalySuspendedRate = ownRound(NP.times(summary.suspendedRate, 100), 1);
      const dispalyAbnormalRate = ownRound(NP.times(summary.abnormalUseRate, 100), 1);
      return (
        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
          {`在线率 : ${dispalyOnlineRate}% ; HMW频次达标率 : ${dispalyHmwOkRate}% ; 停驶率 : ${dispalySuspendedRate}% ; 异常率 : ${dispalyAbnormalRate}%`}
        </div>
      );
    } else {
      return null;
    }
  }

  // 点击日期数量跳转到驾驶行为分析页面
  const onDayClick = (plateNumber: string, date: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '驾驶行为分析',
        key: '/car-admin/drive-analysis',
        params: { plateNumber, date }
      }
    })
  }

  // 渲染每天数据的单元格背景
  const renderDayColumnCell = (record: any, currentDay: string) => {
    const value = (record?.assessItems || []).find((item: any) => item.day === currentDay)?.viewType;
    // 不同viewType对应的背景颜色
    const colorObj: { [key: number]: string } = {
      0: 'successTableCell',
      1: 'warningTableCell',
      2: 'dangerTableCell',
      3: 'correctTableCell',
    }
    const matchColor = colorObj[value];
    if (matchColor) {
      return { className: matchColor };
    } else {
      return {};
    }
  }

  // 渲染每一天数据
  const renderDayColumn = (record: any, currentDay: string) => {
    const matchDayData = (record?.assessItems || []).find((item: any) => item.day === currentDay);
    return (
      <div
        onClick={() => canJumpDriveAnalysis ? onDayClick(record.plateNumber, currentDay) : null}
        style={{ cursor: canJumpDriveAnalysis ? 'pointer' : 'default' }}
      >
        {ownRound(matchDayData?.hmwCount, 1)}
      </div>
    )
  }

  // 表格列显示
  const columns: any = [
    {
      title: () => {
        return (
          <div className={styles.topTableItem}>
            <div className={styles.topTableItemMain}>
              <div className={styles.topTableItemMainThree}></div>
              <div className={styles.topTableItemMainSix}></div>
              <div className={styles.topTableItemMainC}>车牌号</div>
              <div className={styles.topTableItemMainP}>频次</div>
              <div className={styles.topTableItemMainR}>日期</div>
            </div>
          </div>
        )
      },
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left',
    },
  ];
  
  // 添加日期列
  dayColumns.forEach((item: string) => columns.push({
    title: item,
    dataIndex: item,
    width: 120,
    render: (value: any, record: any) => renderDayColumn(record, item),
    onCell: (record: any) => renderDayColumnCell(record, item),
  }))

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout='vertical' form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }} 
                disabledDate={(currentDate: any) => currentDate && currentDate > dayjs().subtract(1, 'days')}
              />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/channel-assessment/showOperationGroup'
            span={3}
          />
          <Col span={4}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cateId}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          {hmwThresholdIsShow &&
            <Col span={3}>
              <Form.Item label="HMW频次阈值" name="hmwThreshold" initialValue={searchParams.hmwThreshold}>
                <InputNumber placeholder='HMW频次阈值' style={{ width: '100%' }} addonAfter="次" disabled={channelAssessDisabled} />
              </Form.Item>
            </Col>
          }
          {timeThresholdIsShow &&
            <Col span={3}>
              <Form.Item label="在线时长阈值" name="runtimeThreshold" initialValue={searchParams.runtimeThreshold}>
                <InputNumber placeholder='在线时长阈值' style={{ width: '100%' }} addonAfter="分钟" disabled={channelAssessDisabled} />
              </Form.Item>
            </Col>
          }
          <Col span={5}>
            <Form.Item label="&nbsp;">
              <div className={commonStyles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={channelAssessmentRequestPath}
                  fileName='渠道评价指标'
                  getParamsFunc={getExportParams}
                  permissionKey="/car-admin/channel-assessment/export"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey='id'
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: pageNum,
          pageSize,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        tableTitle={renderTableTitle()}
      />
    </Card>
  )
}

export default ChannelAssessment;
  