/**
 * 视频预览弹窗
 */
import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { downloadFile } from '@/utils/download';

interface VideoPreviewModalProps {
  url: string; // 播放资源url
  fileName?: string; // 名称
  closeModal: () => void; // 关闭弹窗的方法
  showDownload?: boolean; // 是否显示下载按钮
}
const VideoPreviewModal = (props: VideoPreviewModalProps) => {
  const { url, closeModal, fileName = "视频", showDownload = true } = props;
  const [downloadLoading, setDownloadLoading] = useState(false); // 下载按钮的loading

  // 下载视频
  const downloadVideo = async (url: string, name: string) => {
    try {
      setDownloadLoading(true);
      await downloadFile(url, name);
      setDownloadLoading(false);
    } catch (error) {
      setDownloadLoading(false);
    }
  }

  return (
    <Modal
      title="视频播放"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      maskClosable={false}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <video width="100%" height="240" controls src={url} id="playbackVideo">
        您的浏览器不支持 video 标签。
      </video>
      {showDownload && 
        <Button
          type="primary"
          onClick={() => downloadVideo(url, `${fileName}.mp4`)}
          loading={downloadLoading}
        >
          下载
        </Button>
      }
    </Modal>
  )
}

export default VideoPreviewModal;
    