/**
 * 车辆信息更新记录相关请求
 */
import request from "@/utils/request";

export interface carDeviceChargeListRequestParams {
  page: number;
  limit: number;
  sn?: string;
  carmaSn?:string;
  adasSn?:string;
  plateNumber?: string;
  cateId?: number | Array<number>;
  frameNumber?: string;
  beginTime?: string;
  endTime?: string;
  deviceCharge?: number;
}

// 获取车辆列表数据
export const carDeviceChargeListRequestPath = '/api/v2/carDeviceCharge/list';
export const carDeviceChargeListRequest = (data: carDeviceChargeListRequestParams) => {
  return request.post(carDeviceChargeListRequestPath, data)
}