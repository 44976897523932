/**
 * 传感器方案-供应商添加/编辑
 */
import React, { useEffect } from 'react';
import { Form, Input, InputNumber, message, Modal } from 'antd';
import { Rule } from 'antd/lib/form';
import SensorDeviceTypeSelect from '@/components/sensorDeviceTypeSelect';
import { millDeviceTypeId, ultraDeviceTypeId } from '@/constants/sensorScheme';
import { upsertSensorSupplierRequest } from '@/service/sensorSupplierManage';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();

  // 编辑时赋值
  useEffect(() => {
    if (['edit', 'detail'].includes(type)) {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存供应商信息
  const upsertSupplier = async (values: any) => {
    const params = { ...values };
    if (type === 'edit') {
      params.id = data.id;
    }
    const res = await upsertSensorSupplierRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  return (
    <Modal
      title={
        type === 'detail'
          ? '供应商详情'
          : type === 'add'
          ? '新增供应商'
          : '编辑供应商'
      }
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okButtonProps={{ style: type === 'detail' ? { display: 'none' } : {} }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={upsertSupplier}
        layout="vertical"
        disabled={type === 'detail'}
      >
        <Form.Item
          label="供应商名称"
          name="name"
          rules={[{ required: true, message: '请输入供应商名称' }]}
        >
          <Input placeholder="请输入供应商名称" />
        </Form.Item>
        <Form.Item
          label="设备类型"
          name="componentTypeId"
          rules={[{ required: true, message: '请选择设备类型' }]}
        >
          <SensorDeviceTypeSelect />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const componentTypeId = form.getFieldValue('componentTypeId');
            // 根据选择的设备类型区分校验规则和提示信息
            const rules: Array<Rule> = [
              { required: true, message: '请输入厂商ID' }
            ];
            if (
              [ultraDeviceTypeId, millDeviceTypeId].includes(componentTypeId)
            ) {
              rules.push({
                min: 0,
                max: 7,
                type: 'number',
                message: '请输入0 - 7范围内的数值'
              });
            } else {
              rules.push({
                min: 0,
                max: 15,
                type: 'number',
                message: '请输入0 - 15范围内的数值'
              });
            }
            return (
              <Form.Item label="厂商ID" name="flag" rules={rules}>
                <InputNumber
                  placeholder={
                    componentTypeId
                      ? [ultraDeviceTypeId, millDeviceTypeId].includes(
                          componentTypeId
                        )
                        ? '0 - 7'
                        : '0 -15'
                      : '请输入厂商ID'
                  }
                  step={1}
                  precision={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item label="备注" name="ps">
          <Input.TextArea placeholder="请输入备注" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
