/**
 * route入口文件
 */
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import Login from "@/pages/login";
import NoPermission from "@/pages/noPermission";
import AuthRoute from "./authRoute";
import VehicleAdminRoute from "./vehicleAdminRoute";
import DataSize from "./dataSizeDetails";
import ZhiFu from "./zhiFu";
import history from "@/utils/history";
import { getPageTitle } from "@/utils/common";
import { getFirstJumpPath } from "@/utils/permission";
import { operateUserInfo } from "@/utils/user";
import { PlayVideo } from "@/pages/playVideo";

const Routes = () => {
  const dispatch = useDispatch();
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;

  // 获取用户信息
  const getUserInfo = async () => {
    const {
      userDetail,
      menuData,
      allPagePermission,
      allInnerPermission,
      allSubMenuKeys,
    } = await operateUserInfo();
    // 存储用户信息
    dispatch({
      type: "refreshUserInfo",
      payload: {
        userDetail,
        menuData,
        allPagePermission,
        allInnerPermission,
        allSubMenuKeys,
      },
    });
    // 根据用户权限处理要显示的tab
    getFirstJumpPath(allPagePermission);
  };

  useEffect(() => {
    // 在登录页面时不获取用户信息
    if (!window.location.pathname.includes("/login") && !window.location.pathname.includes('play-video')) {
      getUserInfo();
    }
  }, []);

  return (
    <Fragment>
      <Helmet
        link={[{ rel: "shortcut icon", href: userDetail?.iconUrl }]}
        title={getPageTitle(userDetail)}
      />
      <Router history={history}>
        <Switch>
          <Route exact path="/play-video" component={PlayVideo} />
          <Redirect exact from="/" to="/vehicle-admin" />
          <Route exact path="/login" component={Login} />
          <AuthRoute
            exact
            path="/vehicle-admin"
            component={VehicleAdminRoute}
          />
          <AuthRoute exact path="/data-size-details" component={DataSize} />
          <AuthRoute exact path="/zhi-fu-bao" component={ZhiFu} />
          <Route exact path="/no-permssion" component={NoPermission} />
          {/* 携带组织参数的登录形式 */}
          <Redirect exact from="/:organization" to="/:organization/login" />
          <Route path="/:organization/login" component={Login} />
          
        </Switch>
      </Router>
      <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#eaeaea', color:'#999', fontSize:'12px', lineHeight: '20px', padding: '0px 8px' }}>
        <span>ICP备案号 : 京ICP备2022035052号-1</span>
        <span>技术支持[电话：400 990 9171]公司版权所有</span>
        <span>版本信息：2024-8-18 15:30:00</span>
      </div>
    </Fragment>
  );
};

export default Routes;
