/**
 * 部标机设备相关请求
 */
import request from '@/utils/request';

export interface JtDeviceListRequestParams {
  page: number;
  limit: number;
  terminalId?: string;
  plateNumber?: string;
  phone?: string;
  version?: string;
}

export const jtDeviceListRequestPath = '/api/v2/jt808Device/list';
// 获取部标机设备列表数据
export const jtDeviceListRequest = (params: JtDeviceListRequestParams) => {
  return request.get(jtDeviceListRequestPath, {
    params
  });
};

// 获取部标机设备属性
export const deviceAttrFn = (url: string, phone: string) => {
  return request.get(url, {headers: {'sn-agent': phone}});
};

// 添加、编辑部标机设备
export const upsertJtDeviceRequest = (data: any) => {
  return request.post('/api/v2/jt808Device/upsert', data);
};

// 获取部标机设备详情
export const jtDeviceDetailRequest = (id: number) => {
  return request.get(`/api/v2/jt808Device/detail/${id}`);
};

// 删除部标机设备
export const deleteJtDeviceRequest = (id: number) => {
  return request.delete(`/api/v2/jt808Device/${id}`);
};

// 解绑部标机设备
export const unbindJtDeviceRequest = (data: string) => {
  return request.post('/api/v2/jt808Device/unbind', data, {
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
  });
};

// 获取部标机通道号对应关系
export const jtDeviceChannelEnumRequest = () => {
  return request.get('/api/v2/jt808Device/channelEnum');
};

// 部标机参数获取
export const jt808Command8106Request = (phone: string, params: any) => {
  return request.get(`/808/jt808/8106/${phone}`, {
    params,
    headers: {'sn-agent': phone}
  },);
};

// 骏驰参数获取
export const jt808JC8106Request = (phone: string) => {
  return request.get(`/808/jt808/8106/${phone}`, { params: {paramIds: 62820},  headers: {'sn-agent': phone}},);
};

// 部标机参数配置
export const jt808Command8103Request = (data: any) => {
  // 处理请求参数
  const reuqestData = { ...data };
  delete reuqestData.phone;
  return request.post(`/808/jt808/8103/video/${data.phone}`, reuqestData, {headers: {'sn-agent': data.phone}});
};

// 部标机参数配置
export const jt808JC8103Request = (data: any) => {
  // 处理请求参数
  const reuqestData = { ...data };
  delete reuqestData.phone;
  return request.post(`/808/jt808/8103/jc/param/${data.phone}`, reuqestData, {headers: {'sn-agent': data.phone}});
};
