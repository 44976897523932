/**
 * 营销指标查询
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Form, Row, Col, Button } from 'antd';
import dayjs from 'dayjs';
import ChannelSelect from '@/components/channelSelect';
import DatePicker from '@/components/datePicker';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { marketingIndicatorsRequest, marketingIndicatorsRequestPath } from '@/service/marketingIndicators';
import { channelData, userCateIdMatchChannelData } from '@/constants/channel';
import styles from '@/styles/pageContent.module.scss';

const MarketingIndicators = () => {
  const { userDetail } = useSelector((state: any) => state.userStore);
  // 根据用户分组默认选中的渠道
  const selectChannelValues = () => {
    const userCateIdArr = userDetail?.cateIdArr || [];
    const hasMatchChannelIds = userCateIdArr.find((item: number) => Boolean(userCateIdMatchChannelData[item]));
    if (hasMatchChannelIds) {
      return channelData.filter((item: any) => userCateIdArr.includes(item.value)).map((item: any) => item.value);
    } else {
      return channelData.map((item: any) => item.value);
    }
  }
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}`,
    cateIds: selectChannelValues(),
  }); // 搜索相关的参数
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: marketingIndicatorsRequest,
    initPageSize: 40,
    searchParams,
  });

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    delete newParams.time;
    newParams.beginDate = dayjs(params.time).startOf('month').format('YYYY-MM-DD');
    newParams.endDate = dayjs(params.time).endOf('month').format('YYYY-MM-DD');
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 表格列显示
  const columns = [
    {
      title: '渠道名称',
      dataIndex: 'title',
      width: 110,
      fixed: 'left' as 'left',
    },
    {
      title: '车队名称',
      dataIndex: 'motorcade',
      width: 180,
      fixed: 'left' as 'left',
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as 'left',
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '行驶里程(KM)',
      dataIndex: 'diffMileage',
      width: 120,
    },
    {
      title: 'HMW指标过高',
      dataIndex: 'hmwCount',
      width: 130,
      render: (value: boolean) => value ? '是' : '否'
    },
    {
      title: '7天以上不在线',
      dataIndex: 'isOnline',
      width: 130,
      render: (value: boolean) => value ? '是' : '否'
    },
    {
      title: '车速为0频次',
      dataIndex: 've0Count',
      width: 120,
    },
    {
      title: '遮挡相机频次',
      dataIndex: 'e91Count',
      width: 120,
    },
    {
      title: '电路故障频次',
      dataIndex: 'e133Count',
      width: 120,
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="time"
              initialValue={dayjs(searchParams.beginDate)}
            >
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: any) => currentDate && (currentDate < dayjs().subtract(3, 'months') || currentDate >= dayjs().subtract(1, 'months'))}
                picker="month"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="渠道选择"
              name="cateIds"
              rules={[
                { required: true, message: '请选择渠道' }
              ]}
              initialValue={searchParams.cateIds}
            >
              <ChannelSelect mode="multiple" maxTagCount="responsive" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={marketingIndicatorsRequestPath}
                  fileName='营销指标查询'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/marketing-indicators/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="plateNumber"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
    </Card>
  )
}

export default MarketingIndicators;