/**
 * 演示车辆管理相关请求
 */
import request from '@/utils/request';

// 获取演示车辆
export const getGuestCarRequest = (params: { plateNumber?: string }) => {
  return request.get('/api/v2/car/guest/list', { params });
};

// 添加演示车辆
export const addGuestCarRequest = (data: { carIds: number[] }) => {
  return request.post('/api/v2/car/guest/add', data);
};

// 删除演示车辆
export const deleteGuestCarRequest = (data: { carIds: number[] }) => {
  return request.delete('/api/v2/car/guest', { data });
};
