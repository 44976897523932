/**
 * 设备运行状况
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, Tabs } from 'antd';
import dayjs from 'dayjs';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import DatePicker from '@/components/datePicker';
import ExportButton from '@/components/exportButton';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { operateDayRange } from '@/utils/operateSearchParams';
import { getDateRange } from '@/utils/formatTime';
import { deviceOperationExportPath } from '@/service/deviceOperation';
import { formSearchGutter } from '@/constants/common';
import OperationTable from './components/operationTable';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const DeviceOperation = () => {
  const [form] = Form.useForm();
  const cateIdArr = useUserCateIdArr();
  const [dayTabs, setDayTabs] = useState([{ label: '数据汇总', value: 'all' }]); // 需要展示的天
  const [searchParams, setSearchParams] = useState({
    beginDate: dayjs(dayjs().subtract(7, 'day')).format('YYYY-MM-DD'),
    endDate: dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD'),
    cateIds: cateIdArr
  }); // 搜索参数

  useEffect(() => {
    form.submit();
  }, []);

  // 搜索
  const onSearch = (values: any) => {
    const searchParams = operateDayRange(values);
    const dayRange: any = getDateRange(
      searchParams.beginDate,
      searchParams.endDate
    ).map((day: string) => ({
      label: day,
      value: day
    }));
    dayRange.unshift({ label: '数据汇总', value: 'all' });
    setDayTabs(dayRange);
    setSearchParams(searchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateDayRange(values, true);
  };

  return (
    <Card bordered={false} className={styles.fixPageTabContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={formSearchGutter}>
          <Col span={8}>
            <Form.Item
              label="日期"
              name="time"
              initialValue={[
                dayjs(searchParams.beginDate),
                dayjs(searchParams.endDate)
              ]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: any) =>
                  currentDate && currentDate > dayjs().subtract(1, 'days')
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="分组选择"
              name="cateIds"
              initialValue={searchParams.cateIds}
              rules={[{ required: true, message: '请选择分组' }]}
            >
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <ExportButton
                  url={deviceOperationExportPath}
                  fileName="设备运行状况"
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey="/car-admin/device-operation/export"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Tabs>
        {dayTabs.map((item: any) => (
          <Tabs.TabPane tab={item.label} key={item.value}>
            <div className={styles.commonTableWrapper}>
              <OperationTable
                operationSearchParams={{
                  beginDate:
                    item.value === 'all' ? searchParams.beginDate : item.value,
                  endDate:
                    item.value === 'all' ? searchParams.endDate : item.value,
                  cateIds: searchParams.cateIds
                }}
              />
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default DeviceOperation;
