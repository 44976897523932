/**
 * 制动减速异常车辆
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { breakAbnormalRequest, breakAbnormalRequestPath } from '@/service/breakAbnormal';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import OperationGroupSelect from '@/components/operationGroupSelect';
import { operateDayRange } from '@/utils/operateSearchParams';
import { ownRound } from '@/utils/math';
import { useTable } from '@/utils/useTable';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { snColumnConfig, cameraSnColumnConfig, cateGroupColumnConfig } from '@/constants/tableColumn';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const BreakAbnormal = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    min: 0,
    max: 4.0,
    cate_id: cateIdArr,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: breakAbnormalRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateDayRange(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateDayRange(values, true);
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cate_id: options.cateId,
      })
    }
  }

  // 渲染开始结束时间列
  const renderTimeColumn = (value: string) => (
    <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} />
  )

  // 渲染AEB制动类型列
  const renderAebTypeColumn = (value: string) => {
    const aebTypeObj: any = {
      0: 'AEB制动 (无制动)',
      1: 'AEB制动 (双目制动)',
      2: 'AEB制动 (毫米波制动)',
      3: 'AEB制动 (超声波制动)',
    }
    let key = value;
    if (typeof value === 'object') {
      key = value[0]; 
    }
    return <RenderEllipsisColumn text={aebTypeObj[key] || ''} />;
  }

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
    },
    snColumnConfig,
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
    },
    cameraSnColumnConfig,
    cateGroupColumnConfig,
    {
      title: 'AEB制动类型',
      dataIndex: 'AebsStatus',
      width: 140,
      ellipsis: { showTitle: false },
      render: (value: string) => renderAebTypeColumn(value),
    },
    {
      title: '开始速度(km/h)',
      dataIndex: 'begin_speed',
      width: 140,
      render: (value: string) => ownRound(value, 1),
    },
    {
      title: '结束速度(km/h)',
      dataIndex: 'end_speed',
      width: 140,
      render: (value: string) => ownRound(value, 1),
    },
    {
      title: '开始时间',
      dataIndex: 'begin_time',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => renderTimeColumn(value),
    },
    {
      title: '结束时间',
      dataIndex: 'end_time',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => renderTimeColumn(value),
    },
    {
      title: '减速度(m/s²)',
      dataIndex: 'deceleration',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 220,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'contact', data: item })}>联系方式</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout='vertical' form={form}>
        <Row gutter={12} wrap>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/break-abnormal/show-operation-group'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cate_id" initialValue={searchParams.cate_id}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="减速度大于" name="max" initialValue={searchParams.max}>
              <InputNumber placeholder='减速度上限阈值' style={{ width: '100%' }} addonAfter="m/s²" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="减速度小于" name="min" initialValue={searchParams.min}>
              <InputNumber placeholder='减速度下限阈值' style={{ width: '100%' }} addonAfter="m/s²" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label=" ">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={breakAbnormalRequestPath}
                  fileName='制动减速异常车辆'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/break-abnormal/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Card>
  )
}

export default BreakAbnormal;
  