/**
 * 车辆在线
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button } from 'antd';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { operateDayRange } from '@/utils/operateSearchParams';
import DatePicker from '@/components/datePicker';
import { useTable } from '@/utils/useTable';
import { carDayStatisticListRequest, carDayStatisticListRequestPath } from '@/service/carOnline'
import styles from '@/styles/pageContent.module.scss';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import { useUserCateIdArr } from '@/utils/ownHooks';

const { RangePicker } = DatePicker;
const CarOnline = () => {
    const cateIdArr = useUserCateIdArr();
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useState({
        beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
        endDate: `${dayjs().format('YYYY-MM-DD')}`,
        cateIds: cateIdArr
    }); // 搜索相关的参数
    const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
        requestFunc: carDayStatisticListRequest,
        initRequest: true,
        initPageSize: 40,
        searchParams,
    });


    // 搜索
    const onSearch = async (values: any) => {
        const newSearchParams = operateDayRange(values);
        setSearchParams(newSearchParams);
    };


    // 获取导出的参数
    const getExportParams = () => {
        const values = form.getFieldsValue();
        return operateDayRange(values, true);
    }

    // 表格列显示
    const columns = [
        {
            title: '车辆',
            dataIndex: 'plateNum',
            width: 110,
        },
        {
            title: '在线率(%)',
            dataIndex: 'value',
            width: 110,
        }
    ]
    return (
        <Card bordered={false} className={styles.fixPageContentBox}>
            <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="日期"
                            name="time"
                            initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
                        >
                            <RangePicker allowClear={false} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="分组" name="cateIds" initialValue={searchParams.cateIds}>
                            <CategoryTreeSelect treeCheckable />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="&nbsp;">
                            <div className={styles.searchBox}>
                                <Button type="primary" htmlType="submit">搜索</Button>
                                <ExportButton
                                    url={carDayStatisticListRequestPath}
                                    fileName='车辆在线率'
                                    getParamsFunc={getExportParams}
                                    requestMethod="POST"
                                    permissionKey='/car-admin/carDay-tatistic/export'
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    total: totalCount,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                }}
                bordered
                loading={loading}
            />
        </Card>
    )
}

export default CarOnline;
