/**
 * 维护联系方式的弹窗
 */
import React from 'react';
import { Modal, Form, Input, message, Select, Divider } from 'antd';
import { upsertCarRequest } from '@/service/carManage';
import { updateCallRecordRequest, CallRecordStatusEnum } from '@/service/callRecord';
import CarSelect from '@/components/carSelect';

interface MaintainModalProps {
  successCallback: () => void;
  closeModal: () => void;
  data: any;
}
const MaintainModal = (props: MaintainModalProps) => {
  const [form] = Form.useForm();
  const { successCallback, closeModal, data } = props;

  // 提交
  const onSubmit = async(values: any) => {
    const { id, carDriverPhone, carPrincipalPhone } = values;
    const updateCarParams = {
      id,
      carDriverPhone,
      carPrincipalPhone,
    }
    // 更新车辆联系方式字段
    const res = await upsertCarRequest(updateCarParams);
    if (res.data.code === 0) {
      // 更新通信方式
      const maintainRes = await updateCallRecordRequest({
        id: data.id,
        status: CallRecordStatusEnum.maintained,
      });
      if (maintainRes.data.code === 0) {
        message.success('维护成功');
        closeModal();
        successCallback();
      }
    }
  }

  // 选择更新到时更新联系方式数据
  const onCarSelect = (value: any, option: any) => {
    form.setFieldsValue({
      carPrincipalPhoneBefore: option.car_principal_phone,
      carDriverPhoneBefore: option.car_driver_phone,
      carPrincipalPhone: '',
      carDriverPhone: '',
      updateTo: [],
      // 额外存储原来的联系方式数据，用于赋值更新后的数据
      phones: {
        carPrincipalPhone: option.car_principal_phone,
        carDriverPhone: option.car_driver_phone,
      }
    })
  }

  // 选择车辆时修改更新后的联系方式数据
  const onUpdateToChange = (value: any) => {
    const phones = form.getFieldValue('phones');
    const newValues: {[key: string]: string} = {
      carPrincipalPhone: form.getFieldValue('phones')?.carPrincipalPhone,
      carDriverPhone: form.getFieldValue('phones')?.carDriverPhone,
    };
    value.forEach((item: string) => {
      newValues[item] = `${phones[item] ? `${phones[item]}/` : ''}${data.callNumber}`;
    })
    form.setFieldsValue(newValues);
  }

  return (
    <Modal
      title="客服记录维护"
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="车辆选择"
          name="id"
          rules={[{ required: true, message: '请选择车辆' }]}
        >
          <CarSelect valueField='id' onChange={onCarSelect} />
        </Form.Item>
        <Form.Item
          label="更新到"
          name="updateTo"
          rules={[{ required: true, message: '请选择联系方式更新内容' }]}
        >
          <Select
            options={[
              { 'label': '队长电话', value: 'carPrincipalPhone' },
              { 'label': '司机电话', value: 'carDriverPhone' },
            ]}
            placeholder="请选择联系方式更新内容"
            mode="multiple"
            onChange={onUpdateToChange}
          />
        </Form.Item>
        <Divider orientation='center'>更新前</Divider>
        <Form.Item
          label="队长电话"
          name="carPrincipalPhoneBefore"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="司机电话"
          name="carDriverPhoneBefore"
        >
          <Input disabled />
        </Form.Item>
        <Divider orientation='center'>更新后</Divider>
        <Form.Item
          label="队长电话"
          name="carPrincipalPhone"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="司机电话"
          name="carDriverPhone"
        >
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MaintainModal;
    