/**
 * 维保照片
 */
import React, {useEffect, useState} from 'react';
import {Modal, Switch} from 'antd';
import Thumbnail from '@/components/thumbnail';
import styles from './index.module.scss';
import {downloadFile} from '@/utils/download';
import PermissionButton from '@/components/permissionButton';
import JSZip from 'jszip';
import {DeviceTypeEnum} from "@/constants/deviceManage";

interface MaintenancePictureModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}

const MaintenancePictureModal = (props: MaintenancePictureModalProps) => {
  const {data, closeModal} = props;
  const [deviceType, setDeviceType] = useState<number>(0); // 设备类型
  const [validateResource, setValidateResource] = useState<any>([]); // 前期验车影像
  const [vehicleResources, setVehicleResources] = useState<any>([]); // 车辆信息影像
  const [deviceResources, setDeviceResources] = useState<any>([]); // 设备信息影像
  const [deviceInstallResources, setDeviceInstallResources] = useState<any>([]); // 设备安装影像
  const [deviceTestResources, setDeviceTestResources] = useState<any>([]); // 设备测试影像
  const [deviceStandardResources, setDeviceStandardResources] = useState<any>([]); // 设备规范影像
  const [deliverResources, setDeliverResources] = useState<any>([]); // 交车影像
  const [one, setOne] = useState<any>();
  const [two, setTwo] = useState<any>();
  const [three, setThree] = useState<any>();
  const [four, setFour] = useState<any>();
  const [five, setFive] = useState<any>();
  const [six, setSix] = useState<any>();
  const [seven, setSeven] = useState<any>();
  const [eight, setEight] = useState<any>();
  const validateResourceList: any = [ //前期验车影像
    {
      name: '车辆中控屏幕照片(通电)',
      order: 1,
      number: 1,
    },
    {
      name: '车辆信号灯正常视频(左右刹车)',
      order: 1,
      number: 2
    },
    {
      name: '车辆内室全景照片(主工作台)',
      order: 1,
      number: 3
    },
    {
      name: '安装前视频',
      order: 1,
      number: 4
    },
  ]
  const vehicleResourcesList: any = [ //车辆信息影像
    {
      name: '车辆大驾照片',
      order: 2,
      number: 1
    },
    {
      name: '车辆铭牌照片',
      order: 2,
      number: 2
    },
    {
      name: '车辆行驶证照片',
      order: 2,
      number: 3
    },
  ]
  const deviceResourcesList: any = [ //设备信息影像
    {
      name: '4G模块卡号留存(SN+SIM)',
      order: 3,
      number: 1
    },
    {
      name: '双目相机固定后编码留存(SN+PN)',
      order: 3,
      number: 2
    },
    {
      name: 'DMS摄像机编码',
      order: 3,
      number: 3
    },
    {
      name: 'ADAS摄像机编码',
      order: 3,
      number: 4
    },
    {
      name: '昆仑山主机照片(终端号+SIM卡)',
      order: 3,
      number: 5
    },
    {
      name: '非标主机照片(终端号+SIM卡)',
      order: 3,
      number: 6
    },
  ]

  const deviceInstallResourcesList: any = [ //设备安装影像
    {
      name: '气路整体照片',
      order: 4,
      number: 1
    },
    {
      name: ' 内室整体照片 （安装后）',
      order: 4,
      number: 2
    },
    {
      name: '电路整体照片',
      order: 4,
      number: 3
    },
    {
      name: '前盲摄像头位置照片（近照）',
      order: 4,
      number: 4
    },
    {
      name: '右盲摄像头位置照片（近照）',
      order: 4,
      number: 5
    },
    {
      name: '声光报警器位置照片（近照）',
      order: 4,
      number: 6
    },
    {
      name: '拉线电机安装位置',
      order: 4,
      number: 7
    },
    {
      name: '安装完成视频',
      order: 4,
      number: 8
    },
  ]
  const deviceTestResourcesList: any = [ //设备测试影像
    {
      name: ' 示波器调试  “放大” 照片',
      order: 5,
      number: 1
    },
    {
      name: ' 示波器调试  “基准” 照片',
      order: 5,
      number: 2
    },
    {
      name: ' 示波器调试  “输出” 照片',
      order: 5,
      number: 3
    },
    {
      name: '路测 “$1车速>60迈” 截图',
      order: 5,
      number: 4
    },
    {
      name: ' 蓝牙-调参  配置-截图',
      order: 5,
      number: 5
    },
    {
      name: '蓝牙-调参-配置隐藏界面-截图',
      order: 5,
      number: 6
    },
    {
      name: '蓝牙-调参-配置外设检测-截图',
      order: 5,
      number: 7
    },
    {
      name: '蓝牙-调参-配置硬线检测-截图',
      order: 5,
      number: 8
    },
    {
      name: '蓝牙-调参-配置信号检测-截图',
      order: 5,
      number: 9
    },
    {
      name: '蓝牙-报警开关设置-截图',
      order: 5,
      number: 10
    },
    {
      name: '蓝牙-ADAS报警设置-截图',
      order: 5,
      number: 11
    },
    {
      name: '蓝牙-DMS报警设置-截图',
      order: 5,
      number: 12
    },
    {
      name: '蓝牙-BSD报警设置-截图',
      order: 5,
      number: 13
    },
    {
      name: '相机俯仰角照片',
      order: 5,
      number: 14
    },
    {
      name: '蓝牙-调参-配置外设检测-截图',
      order: 5,
      number: 15
    },
    {
      name: '车道线标定照片',
      order: 5,
      number: 16
    },
    {
      name: '鼎奕蓝牙工具-ADAS标定设置-截图',
      order: 5,
      number: 17
    },
    {
      name: '鼎奕蓝牙工具-DMS标定设置-截图',
      order: 5,
      number: 18
    },
    {
      name: '鼎奕蓝牙工具-BSD标定设置-截图',
      order: 5,
      number: 19
    },
    {
      name: '系统设置-上线参数照片',
      order: 5,
      number: 20
    },
    {
      name: '系统设置-网络信息照片',
      order: 5,
      number: 21
    },
  ]
  const deviceStandardResourcesList: any = [ //设备规范影像
    {
      name: '盲区显示屏小程序预览(前向)',
      order: 6,
      number: 1
    },
    {
      name: '盲区显示屏小程序预览(右)',
      order: 6,
      number: 2
    },
    {
      name: '“易碎纸”封口照片',
      order: 6,
      number: 3
    },
    {
      name: '预警显示屏“车道线”照片',
      order: 6,
      number: 4
    },
    {
      name: '盲区显示屏小程序预览(DMS)',
      order: 6,
      number: 5
    },
    {
      name: '客服电话贴纸照片',
      order: 6,
      number: 6
    },
    {
      name: '4G定位天线固定照(车顶)',
      order: 6,
      number: 7
    },
    {
      name: 'DMS画面照片',
      order: 6,
      number: 8
    },
    {
      name: '盲区显示屏照片',
      order: 6,
      number: 9
    },
  ]
  const deliverResourcesList: any = [ //交车影像
    {
      name: '车头正面照片(着工装,手指设备)',
      order: 7,
      number: 1
    },
    {
      name: '车头左前斜面照片',
      order: 7,
      number: 2
    },
    {
      name: '车头右前斜面照片',
      order: 7,
      number: 3
    },
    {
      name: '车头左后斜面照片',
      order: 7,
      number: 4
    },
    {
      name: '车头右后斜面照片',
      order: 7,
      number: 5
    },
    {
      name: '车尾左后斜面照片',
      order: 7,
      number: 6
    },
    {
      name: '车尾右后斜面照片',
      order: 7,
      number: 7
    },
    {
      name: '车尾正面照片',
      order: 7,
      number: 8
    },
    {
      name: '安装验收单照片',
      order: 7,
      number: 9
    },
    {
      name: '交车视频',
      order: 7,
      number: 10
    },
  ]

  useEffect(() => {
    const deviceType = data?.device_type || -1;
    setDeviceType(deviceType);

    data?.validateResource?.map((item: any) => {
      validateResourceList[item.order - 1].data = item
    })
    data?.vehicleResources?.map((item: any) => {
      vehicleResourcesList[item.order - 1].data = item
    })
    data?.deviceResources?.map((item: any) => {
      deviceResourcesList[item.order - 1].data = item
    })
    data?.deviceInstallResources?.map((item: any) => {
      deviceInstallResourcesList[item.order - 1].data = item
    })
    data?.deviceTestResources?.map((item: any) => {
      deviceTestResourcesList[item.order - 1].data = item
    })
    data?.deviceStandardResources?.map((item: any) => {
      deviceStandardResourcesList[item.order - 1].data = item
    })
    data?.deliverResources?.map((item: any) => {
      deliverResourcesList[item.order - 1].data = item
    })
    setValidateResource(validateResourceList)
    setVehicleResources(vehicleResourcesList)
    //针对TLY3.4的特殊处理，TLY3.4设备类型为12。TLY3.4设备不显示4G模块卡号留存图片
    if (deviceType === DeviceTypeEnum.adas3) {
      // console.log(deviceResourcesList)
      // debugger
      deviceResourcesList.shift()
    }
    setDeviceResources(deviceResourcesList)
    setDeviceInstallResources(deviceInstallResourcesList)
    setDeviceTestResources(deviceTestResourcesList)
    setDeviceStandardResources(deviceStandardResourcesList)
    setDeliverResources(deliverResourcesList)

    // 设置默认选中状态
    setcheckState();
    // setOne(true);
    // console.log("one:",one)
    // setTwo(true);
    // setThree(true);
    // setFour(true);
    // setFive(true);
    // setSix(true);
    // setSeven(true);
  }, []);

  // 批量导出照片
  const getChangePicture = () => {
    const changePhoto: any = []
    if (one == true) {
      data.resources?.map((item: any) => {
        const aaa = {data: item}
        changePhoto.push(aaa)
      })
    }
    if (two == true) {
      validateResource?.map((item: any) => changePhoto.push(item))
    }
    if (three == true) {
      vehicleResources?.map((item: any) => changePhoto.push(item))
    }
    if (four == true) {
      deviceResources?.map((item: any) => changePhoto.push(item))
    }
    if (five == true) {
      deviceInstallResources.map((item: any) => changePhoto.push(item))
    }
    if (six == true) {
      deviceTestResources?.map((item: any) => changePhoto.push(item))
    }
    if (seven == true) {
      deviceStandardResources?.map((item: any) => changePhoto.push(item))
    }
    if (eight == true) {
      deliverResources?.map((item: any) => {
        if (item.number !== 10) {
          changePhoto.push(item)
        }
      })
    }
    let picture = -1
    const piliang = setInterval(function () {
      picture++
      if (picture == changePhoto.length) {
        clearInterval(piliang)
      } else {
        downloadFile(changePhoto[picture].data.url, `维保照片(${picture + 1}).${changePhoto[picture].data.contentType.slice(6)}`)
      }
    }, 500);
  }
  const setcheckState = () => {
    setOne(true);
    setTwo(true);
    setThree(true);
    setFour(true);
    setFive(true);
    setSix(true);
    setSeven(true);
    setEight(true);

  }

  // 批量导出压缩包
  const getChangeZip = () => {
    //获取选中需要导出的图片
    const changePhoto: any = []
    if (one == true) {
      data.resources?.map((item: any) => {
        const aaa = {data: item}
        changePhoto.push(aaa)
      })
    }
    if (two == true) {
      validateResource?.map((item: any) => changePhoto.push(item))
    }
    if (three == true) {
      vehicleResources?.map((item: any) => changePhoto.push(item))
    }
    if (four == true) {
      deviceResources?.map((item: any) => changePhoto.push(item))
    }
    if (five == true) {
      deviceInstallResources?.map((item: any) => changePhoto.push(item))
    }
    if (six == true) {
      deviceTestResources?.map((item: any) => changePhoto.push(item))
    }
    if (seven == true) {
      deviceStandardResources?.map((item: any) => changePhoto.push(item))
    }
    if (eight == true) {
      deliverResources?.map((item: any) => {
        if (item.number !== 10) {
          changePhoto.push(item)
        }
      })
    }
    const allTypeCodeFile: any = []
    const allTypeCodeFileType: any = []
    changePhoto.map((item: any) => {
      if (item.data) {
        allTypeCodeFile.push(item.data.url)
        allTypeCodeFileType.push(item.data.contentType.slice(6))
      }
    })
    const zip = new JSZip();
    let result = [];
    for (let i in allTypeCodeFile) {
      let promise = getFileBlob(allTypeCodeFile[i]).then((res: any) => {
        if (res) {
          zip.file(Number(i) + 1 + '.' + allTypeCodeFileType[i], res, {binary: true});
        }
      });
      result.push(promise);
    }
    Promise.all(result).then(() => {
      zip.generateAsync({type: 'blob'}).then((res) => {
        let evt = document.createEvent('HTMLEvents');
        let aLink = document.createElement('a');
        evt.initEvent('click', true, true);
        aLink.download = '维保照片.zip';
        aLink.href = URL.createObjectURL(res);
        aLink.click();
      });
    });
  }

  const getFileBlob = (url: string) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      request.onload = (res: any) => {
        if (res.target.status === 200) {
          resolve(res.target.response);
        } else {
          reject(res);
        }
      };
      request.send();
    });
  };

  const getMaintenancePicture = (value: any) => {
    setOne(value)
  }

  const getValidateResource = (value: any) => {
    setTwo(value)
  }

  const getVehicleResources = (value: any) => {
    setThree(value)
  }

  const getDeviceResources = (value: any) => {
    setFour(value)
  }

  const getDeviceInstallResources = (value: any) => {
    setFive(value)
  }

  const getDeviceTestResources = (value: any) => {
    setSix(value)
  }

  const getDeviceStandardResources = (value: any) => {
    setSeven(value)
  }

  const getDeliverResources = (value: any) => {
    setEight(value)
  }

  return (
    <Modal
      title="维保照片"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      width={1100}
      bodyStyle={{maxHeight: '70vh', overflow: 'auto'}}
    >
      <PermissionButton operateType='/car-admin/car-diagnosis/maintenance-batch-picture' style={{marginRight: '20px'}}
                        type="primary" onClick={getChangePicture}>批量导出照片</PermissionButton>
      <PermissionButton operateType='/car-admin/car-diagnosis/maintenance-batch-picture' type="primary"
                        onClick={getChangeZip}>批量导出压缩包</PermissionButton>
      {data.resources ? <div>
        <div className={styles.photoBigTitle}>维保照片 <Switch checked={one} onChange={getMaintenancePicture}/></div>
        <Thumbnail
          dataSource={data.resources || []}
          downloadImgName="维保图片"
          downloadVideoName="维保视频"
        />
      </div> : null}
      {data.type == 2 ? <div>
        <div className={styles.photoBigTitle}>前期验车影像 <Switch checked={two} onChange={getValidateResource}/></div>
        <div style={{display: 'flex'}}>
          {(validateResource || []).map((item: any, index: number) => (
            item.data ?
              <div className={styles.photoItem} key={index}>
                <div className={styles.photoItemTitle}>{item.name}</div>
                <div className={styles.photoItemPhoto}>
                  {item.data ? <Thumbnail
                    dataIndex={index}
                    dataArray={validateResource || []}
                    dataSource={[item.data] || []}
                    downloadImgName="维保图片"
                    downloadVideoName="维保视频"
                  /> : null}
                </div>
              </div> : null
          ))}
        </div>
        <div className={styles.photoBigTitle}>车辆信息影像 <Switch checked={three} onChange={getVehicleResources}/>
        </div>
        <div style={{display: 'flex'}}>
          {(vehicleResources || []).map((item: any, index: number) => (
            item.data ?
              <div className={styles.photoItem} key={index}>
                <div className={styles.photoItemTitle}>{item.name}</div>
                <div className={styles.photoItemPhoto}>
                  {item.data ? <Thumbnail
                    dataIndex={index}
                    dataArray={vehicleResources || []}
                    dataSource={[item.data] || []}
                    downloadImgName="维保图片"
                    downloadVideoName="维保视频"
                  /> : null}
                </div>
              </div> : null
          ))}
        </div>
        <div className={styles.photoBigTitle}>设备信息影像 <Switch checked={four} onChange={getDeviceResources}/></div>
        <div style={{display: 'flex'}}>
          {(deviceResources || []).map((item: any, index: number) => (
            item.data ?
              <div className={styles.photoItem} key={index}>
                <div className={styles.photoItemTitle}>{item.name}</div>
                <div className={styles.photoItemPhoto}>
                  {item.data ? <Thumbnail
                    dataIndex={index}
                    dataArray={deviceResources || []}
                    dataSource={[item.data] || []}
                    downloadImgName="维保图片"
                    downloadVideoName="维保视频"
                  /> : null}
                </div>
              </div> : null
          ))}
        </div>
        <div className={styles.photoBigTitle}>设备安装影像 <Switch checked={five} onChange={getDeviceInstallResources}/>
        </div>
        <div style={{display: 'flex'}}>
          {(deviceInstallResources || []).map((item: any, index: number) => (
            item.data ?
              <div className={styles.photoItem} key={index}>
                <div className={styles.photoItemTitle}>{item.name}</div>
                <div className={styles.photoItemPhoto}>
                  {item.data ? <Thumbnail
                    dataIndex={index}
                    dataArray={deviceInstallResources || []}
                    dataSource={[item.data] || []}
                    downloadImgName="维保图片"
                    downloadVideoName="维保视频"
                  /> : null}
                </div>
              </div> : null
          ))}
        </div>
        <div className={styles.photoBigTitle}>设备测试影像 <Switch checked={six} onChange={getDeviceTestResources}/>
        </div>
        <div style={{display: 'flex'}}>
          {(deviceTestResources || []).map((item: any, index: number) => (
            item.data ?
              <div className={styles.photoItem} key={index}>
                <div className={styles.photoItemTitle}>{item.name}</div>
                <div className={styles.photoItemPhoto}>
                  {item.data ? <Thumbnail
                    dataIndex={index}
                    dataArray={deviceTestResources || []}
                    dataSource={[item.data] || []}
                    downloadImgName="维保图片"
                    downloadVideoName="维保视频"
                  /> : null}
                </div>
              </div> : null
          ))}
        </div>
        <div className={styles.photoBigTitle}>设备规范影像 <Switch checked={seven}
                                                                   onChange={getDeviceStandardResources}/></div>
        <div style={{display: 'flex'}}>
          {(deviceStandardResources || []).map((item: any, index: number) => (
            item.data ?
              <div className={styles.photoItem} key={index}>
                <div className={styles.photoItemTitle}>{item.name}</div>
                <div className={styles.photoItemPhoto}>
                  {item.data ? <Thumbnail
                    dataIndex={index}
                    dataArray={deviceStandardResources || []}
                    dataSource={[item.data] || []}
                    downloadImgName="维保图片"
                    downloadVideoName="维保视频"
                  /> : null}
                </div>
              </div> : null
          ))}
        </div>
        <div className={styles.photoBigTitle}>交车影像 <Switch checked={eight} onChange={getDeliverResources}/></div>
        <div style={{display: 'flex'}}>
          {(deliverResources || []).map((item: any, index: number) => (
            item.data ?
              <div className={styles.photoItem} key={index}>
                <div className={styles.photoItemTitle}>{item.name}</div>
                <div className={styles.photoItemPhoto}>
                  {item.data ? <Thumbnail
                    dataIndex={index}
                    dataArray={deliverResources || []}
                    dataSource={[item.data] || []}
                    downloadImgName="维保图片"
                    downloadVideoName="维保视频"
                  /> : null}
                </div>
              </div> : null
          ))}
        </div>
      </div> : null}
    </Modal>
  )
}

export default MaintenancePictureModal;
    