/**
 * 单条保单支付记录
 */
import { Descriptions } from "antd";

interface MaintenanceItemProps {
  column: {[key: string]: number};
  data: any;
  type: string;
}
const InsurePayItem = (props: MaintenanceItemProps) => {
  const { column, data } = props;
  return (
    <Descriptions bordered size="small" column={column}>
      <Descriptions.Item label="车架号">{data.frameNumber}</Descriptions.Item>
      <Descriptions.Item label="保单号">{data.insuranceCode}</Descriptions.Item>
      <Descriptions.Item label="实支付金额" >{data.paidFee}</Descriptions.Item>
      <Descriptions.Item label="应支付金额">{data.payableFee}</Descriptions.Item>
    </Descriptions>
  )

}

export default InsurePayItem;