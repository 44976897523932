/**
 * 审批原因弹窗
 */
import React from 'react';
import { Modal, message, Form, Input } from 'antd';
import { insertAuditRequest } from '@/service/approveSearch';

interface ApproveReasonModalProps {
  origin: any; // 修改前的数据
  target: any; // 修改后的数据
  type: string; // 修改的类型
  sn: string; // 设备SN
  deviceType: number; // 设备类型
  closeModal: () => void; // 关闭弹窗的方法
  successCallback: () => void; // 提交审批成功后的方法
}
const ApproveReasonModal = (props: ApproveReasonModalProps) => {
  const { origin, target, type, sn, deviceType, closeModal, successCallback } =
    props;
  const [form] = Form.useForm();

  // 提交审批
  const insertAudit = async (values: any) => {
    const { reason } = values;
    const res = await insertAuditRequest({
      origin: JSON.stringify(origin),
      target: JSON.stringify(target),
      type,
      sn,
      deviceType,
      reason
    });
    if (res.data?.data) {
      message.success('提交审批成功');
      closeModal();
      successCallback();
    }
  };

  return (
    <Modal
      title="提交审批"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <div
        style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}
      >
        该操作需要管理员进行审核，审核通过后所有修改将写入平台
      </div>
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={insertAudit}
      >
        <Form.Item
          label="操作原因"
          name="reason"
          rules={[{ required: true, message: '请输入操作原因' }]}
        >
          <Input.TextArea placeholder="请输入操作原因" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ApproveReasonModal;
