import { decimalToBinary } from '@/utils/math';

// 获取adas报警类型
export const getAdasAlarmType = (type: number, aebString?: string) => {
  if (type === 9) {
    return '常规报警';
  } else if (type === 10) {
    return 'ADAS报警';
  } else if (type === 11) {
    return 'BSD报警';
  } else if (type === 12) {
    return 'DSM报警';
  } else {
    return type;
  }
}

// // 获取详细的报警类型
// export const getDetailAlarmType = (data: any) => {
//   if (data.alarm_type === 1) {
//     return 'FCW预警';
//   } else if (data.alarm_type === 2) {
//     if (data.alarm_info) {
//       if (data.alarm_info.AebsStatus) {
//         let key = data.alarm_info.AebsStatus;
//         if (typeof(data.alarm_info.AebsStatus) === "object") {
//           key = data.alarm_info.AebsStatus[0];
//         }
//         const alarmTypeObj: any = {
//           0: 'AEB制动 (无制动)',
//           1: 'AEB制动 (双目制动)',
//           2: 'AEB制动 (毫米波制动)',
//           3: 'AEB制动 (超声波制动)',
//           4: 'AEB制动 (角雷达制动)'
//         }
//         // 针对崇明公交做临时的报警过滤展示
//         if (data.cate_path && /^#0#1#3438#3439#1712#1713.*/.test(data.cate_path)) {
//           if (Number(key) === 3) {
//             return 'AEB制动';
//           }
//         }
//         return alarmTypeObj[key] || 'AEB制动';
//       } else {
//         return 'AEB制动';
//       }
//     } else {
//       return 'AEB制动';
//     }
//   } else if (data.alarm_type === 3) {
//     return '起步阻止信息';
//   } else if (data.alarm_type === 4) {
//     return 'LDW预警信息';
//   } else if (data.alarm_type === 5) {
//     return 'HMW预警信息';
//   } else if (data.alarm_type === 7) {
//     return '角雷达报警';
//   }
// }

// 报警查询获取详细的报警类型
export const getAlarmTypeInfo = (data: any) => {
  if (data.alarmType === 1) {
    return 'FCW预警';
  } else if (data.alarmType === 2) {
    if (data.info) {
      if (data.info.AebsStatus) {
        let key = data.info.AebsStatus;
        if (typeof(data.info.AebsStatus) === "object") {
          key = data.info.AebsStatus[0];
        }
        const alarmTypeObj: any = {
          0: 'AEB制动 (无制动)',
          1: 'AEB制动 (双目制动)',
          2: 'AEB制动 (毫米波制动)',
          3: 'AEB制动 (超声波制动)',
          4: 'AEB制动 (角雷达制动)'
        }
        // 针对崇明公交做临时的报警过滤展示
        if (data.catePath && /^#0#1#3438#3439#1712#1713.*/.test(data.catePath)) {
          if (Number(key) === 3) {
            return 'AEB制动';
          }
        }
        return alarmTypeObj[key] || 'AEB制动';
      } else {
        return 'AEB制动';
      }
    } else {
      return 'AEB制动';
    }
  } else if (data.alarmType === 3) {
    return '起步阻止信息';
  } else if (data.alarmType === 4) {
    return 'LDW预警信息';
  } else if (data.alarmType === 5) {
    return 'HMW预警信息';
  } else if (data.alarmType === 7) {
    return '角雷达报警';
  } else if (data.alarmType === 8) {
    return '右转制动';
  } else if (data.alarmType === 9) {
    if (data.alarmTypeSmall == 0) {
      return '常规报警 (紧急报警)'
    } else if (data.alarmTypeSmall == 1) {
      return '常规报警 (超速报警)'
    } else if (data.alarmTypeSmall == 2) {
      return '常规报警 (疲劳驾驶)'
    } else if (data.alarmTypeSmall == 3) {
      return '常规报警 (危险驾驶)'
    } else if (data.alarmTypeSmall == 4) {
      return '常规报警 (GNSS模块发生故障)'
    } else if (data.alarmTypeSmall == 5) {
      return '常规报警 (GPS 天线开路)'
    } else if (data.alarmTypeSmall == 6) {
      return '常规报警 (GNSS天线短路)'
    } else if (data.alarmTypeSmall == 7) {
      return '常规报警 (终端主电源欠压)'
    } else if (data.alarmTypeSmall == 8) {
      return '常规报警 (终端主电源掉电)'
    } else if (data.alarmTypeSmall == 9) {
      return '常规报警 (终端LCD或显示器故障)'
    } else if (data.alarmTypeSmall == 10) {
      return '常规报警 (TTS模块故障)'
    } else if (data.alarmTypeSmall == 11) {
      return '常规报警 (摄像头故障)'
    } else if (data.alarmTypeSmall == 12) {
      return '常规报警 (道路运输证IC卡模块故障)'
    } else if (data.alarmTypeSmall == 13) {
      return '常规报警 (超速预警)'
    } else if (data.alarmTypeSmall == 14) {
      return '常规报警 (疲劳驾驶预警)'
    } else if (data.alarmTypeSmall == 15) {
      return '常规报警 (违规行驶预警)'
    } else if (data.alarmTypeSmall == 16) {
      return '常规报警 (胎压预警)'
    } else if (data.alarmTypeSmall == 17) {
      return '常规报警 (右转盲区异常预警)'
    } else if (data.alarmTypeSmall == 18) {
      return '常规报警 (当天累计驾驶超时)'
    } else if (data.alarmTypeSmall == 19) {
      return '常规报警 (超时停车)'
    } else if (data.alarmTypeSmall == 20) {
      return '常规报警 (进出区域)'
    } else if (data.alarmTypeSmall == 21) {
      return '常规报警 (进出路线)'
    } else if (data.alarmTypeSmall == 22) {
      return '常规报警 (路段行驶时间不足/过长)'
    } else if (data.alarmTypeSmall == 23) {
      return '常规报警 (路线偏离报警)'
    } else if (data.alarmTypeSmall == 24) {
      return '常规报警 (车辆VSS故障)'
    } else if (data.alarmTypeSmall == 25) {
      return '常规报警 (车辆油量异常)'
    } else if (data.alarmTypeSmall == 26) {
      return '常规报警 (车辆被盗(通过车辆防盗器))'
    } else if (data.alarmTypeSmall == 27) {
      return '常规报警 (车辆非法点火)'
    } else if (data.alarmTypeSmall == 28) {
      return '常规报警 (车辆非法位移)'
    } else if (data.alarmTypeSmall == 29) {
      return '常规报警 (碰撞预警)'
    } else if (data.alarmTypeSmall == 30) {
      return '常规报警 (侧翻预警)'
    } else if (data.alarmTypeSmall == 31) {
      return '常规报警 (非法开门报警(终端未设置区域时,不判断非法开门))'
    } else if (data.alarmTypeSmall == 32) {
      return '常规报警 (未知)'
    } else {
      return '常规报警'
    }
  } else if (data.alarmType === 10) {
    if (data.alarmTypeSmall == 1) {
      return 'ADAS报警 (前向碰撞报警)'
    } else if (data.alarmTypeSmall == 2) {
      return 'ADAS报警 (车道偏离报警)'
    } else if (data.alarmTypeSmall == 3) {
      return 'ADAS报警 (车距过近报警)'
    } else if (data.alarmTypeSmall == 4) {
      return 'ADAS报警 (行人碰撞报警)'
    } else if (data.alarmTypeSmall == 5) {
      return 'ADAS报警 (频繁变道报警)'
    } else if (data.alarmTypeSmall == 6) {
      return 'ADAS报警 (道路标识超限报警)'
    } else if (data.alarmTypeSmall == 7) {
      return 'ADAS报警 (障碍物报警)'
    } else if (data.alarmTypeSmall == 8) {
      return 'ADAS报警 (驾驶辅助功能失效报警)'
    } else if (data.alarmTypeSmall == 9) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 10) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 11) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 12) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 13) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 14) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 15) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 16) {
      return 'ADAS报警 (道路标志识别事件)'
    } else if (data.alarmTypeSmall == 17) {
      return 'ADAS报警 (主动抓拍事件)'
    } else if (data.alarmTypeSmall == 18) {
      return 'ADAS报警 (实线变道报警)'
    } else if (data.alarmTypeSmall == 19) {
      return 'ADAS报警 (车厢过道行人检测报警)'
    } else if (data.alarmTypeSmall == 20) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 21) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 22) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 23) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 24) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 25) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 26) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 27) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 28) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 29) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 30) {
      return 'ADAS报警 (未知)'
    } else if (data.alarmTypeSmall == 31) {
      return 'ADAS报警 (未知)'
    } else {
      return 'ADAS报警'
    }
  } else if (data.alarmType === 11) {
    if (data.alarmTypeSmall == 1) {
      return 'BSD报警 (后方接近报警)'
    } else if (data.alarmTypeSmall == 2) {
      return 'BSD报警 (左侧后方接近报警)'
    } else if (data.alarmTypeSmall == 3) {
      return 'BSD报警 (右侧后方接近报警)'
    } else {
      return 'BSD报警(未知)'
    }
  } else if (data.alarmType === 12) {
    if (data.alarmTypeSmall == 1) {
      return 'DSM报警 (疲劳驾驶报警)'
    } else if (data.alarmTypeSmall == 2) {
      return 'DSM报警 (接打电话报警)'
    } else if (data.alarmTypeSmall == 3) {
      return 'DSM报警 (抽烟报警)'
    } else if (data.alarmTypeSmall == 4) {
      return 'DSM报警 (长时间不目视前方)'
    } else if (data.alarmTypeSmall == 5) {
      return 'DSM报警 (未检测到驾驶员报警)'
    } else if (data.alarmTypeSmall == 6) {
      return 'DSM报警 (双手同时脱离方向盘报警)'
    } else if (data.alarmTypeSmall == 7) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 8) {
      return 'DSM报警 (超时驾驶报警)'
    } else if (data.alarmTypeSmall == 9) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 10) {
      return 'DSM报警 (未系安全带报警)'
    } else if (data.alarmTypeSmall == 11) {
      return 'DSM报警 (红外阻断型墨镜失效报警)'
    } else if (data.alarmTypeSmall == 12) {
      return 'DSM报警 (双脱把报警(双手同时脱离方向盘))'
    } else if (data.alarmTypeSmall == 13) {
      return 'DSM报警 (玩手机报警)'
    } else if (data.alarmTypeSmall == 14) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 15) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 16) {
      return 'DSM报警 (自动抓拍事件)'
    } else if (data.alarmTypeSmall == 17) {
      return 'DSM报警 (驾驶员变更事件)'
    } else if (data.alarmTypeSmall == 18) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 19) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 20) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 21) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 22) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 23) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 24) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 25) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 26) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 27) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 28) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 29) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 30) {
      return 'DSM报警 (未知)'
    } else if (data.alarmTypeSmall == 31) {
      return 'DSM报警 (未知)'
    } else {
      return 'DSM报警'
    }
  } else if (data.alarmType === 13) {
    if (data.alarmTypeSmall == 0) {
      return '视频信号丢失报警'
    } else if (data.alarmTypeSmall == 1) {
      return '视频信号遮挡报警'
    } else if (data.alarmTypeSmall == 2) {
      return '存储单元故障报警'
    } else if (data.alarmTypeSmall == 3) {
      return '其他视频设备故障报警'
    } else if (data.alarmTypeSmall == 4) {
      return 'GNSS模块发生故障'
    } else if (data.alarmTypeSmall == 5) {
      return 'GPS 天线开路'
    } else if (data.alarmTypeSmall == 6) {
      return 'GNSS天线短路'
    } else if (data.alarmTypeSmall == 7) {
      return '终端主电源欠压'
    } else if (data.alarmTypeSmall == 8) {
      return '终端主电源掉电'
    } else if (data.alarmTypeSmall == 9) {
      return '终端LCD或显示器故障'
    } else if (data.alarmTypeSmall == 10) {
      return 'TTS模块故障'
    } else if (data.alarmTypeSmall == 11) {
      return '摄像头故障'
    } else if (data.alarmTypeSmall == 12) {
      return '道路运输证IC卡模块故障'
    } else {
      return '设备故障报警'
    }
  }
}
