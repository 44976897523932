/**
 * 历史视频下载弹窗
 */
import React from 'react';
import { Modal, Tabs } from 'antd';
import JtDeviceDownload from './jtDeviceDownload';
import DvrDownload from './dvrDownload';

interface VideoDownloadModalProps {
  data: any; // 车辆相关信息
  closeModal: () => void; // 关闭弹窗的方法
}
const VideoDownloadModal = (props: VideoDownloadModalProps) => {
  const { data, closeModal } = props;
  const { jt808DeviceId, hasModem } = data;

  return (
    <Modal
      title="历史视频下载"
      open
      footer={null}
      onCancel={closeModal}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      width="60%"
      centered
      maskClosable={false}
    >
      <Tabs>
        {/* 车辆安装部标机时显示tab */}
        {Boolean(jt808DeviceId) && (
          <Tabs.TabPane tab="视频设备" key="jtDevice">
            <JtDeviceDownload carDetail={data} />
          </Tabs.TabPane>
        )}
        {/* 车辆安装行车记录仪时显示tab */}
        {Boolean(hasModem) && (
          <Tabs.TabPane tab="行车记录仪" key="dvr">
            <DvrDownload carDetail={data} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export default VideoDownloadModal;
