/**
 * 客服记录
 */
import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Tag,
  message,
  Popconfirm,
  Input,
  Select,
} from "antd";
import dayjs from "dayjs";
import {
  serviceRecoedRequest,
  deleteServiceRecoed,
  serviceRecoedRequestPath,
} from "@/service/carDiagnosis";
import CategoryTreeSelect from "@/components/categoryTreeSelect";
import CommonTable from "@/components/commonTable";
import ExportButton from "@/components/exportButton";
import DatePicker from "@/components/datePicker";
import Thumbnail from "@/components/thumbnail";
import { operateDayRange } from "@/utils/operateSearchParams";
import { useUserCateIdArr } from "@/utils/ownHooks";
import { useTable } from "@/utils/useTable";
import styles from "@/styles/pageContent.module.scss";
import PermissionButton from "@/components/permissionButton";
import OperateService, { concatResultList } from "@/pages/carDiagnosis/components/operateService";
import { tagListRequest } from "@/service/tagManage";
import { TagCategoryEnum } from "@/constants/tagManage";

const { RangePicker } = DatePicker;
const ServiceRecord = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`,
    endDate: `${dayjs().subtract(1, "day").format("YYYY-MM-DD")}`,
    cateId: cateIdArr,
  }); // 搜索相关的参数
  const [operateVisible, setOperateVisible] = useState<any>({}); // 添加客服记录的弹窗
  const [form] = Form.useForm();
  const [carRelationResult, setCarRelationResult] = useState<any>([]); // 车辆联系结果
  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: serviceRecoedRequest,
      initPageSize: 40,
      searchParams,
    });

  useEffect(() => {
    getCarRelationResult();
  }, []);

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateDayRange(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateDayRange(values, true);
  };

  // 删除客服记录
  const deleteServiceRecoedItem = async (id: number) => {
    const res = await deleteServiceRecoed(id);
    if (res.data?.code === 0) {
      message.success("删除成功");
      getData();
    }
  };

  // 获取车辆联系结果
  const getCarRelationResult = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carRelationResult],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setCarRelationResult(tags);
  };

  //处理状态
  const manageType = (value: any) => {
    if (value == 0) {
      return "关闭";
    } else if (value == 1) {
      return "未创建";
    } else if (value == 2) {
      return "已创建";
    } else if (value == 3) {
      return "已派单";
    }
  };

  const anyData = () => {};

  // 表格列显示
  const columns = [
    {
      title: "车牌号",
      dataIndex: "plateNumber",
      width: 110,
    },
    {
      title: "车架号",
      dataIndex: "frameNumber",
      width: 110,
    },
    {
      title: "客服",
      dataIndex: "nickName",
      width: 110,
    },
    {
      title: "问题来源",
      dataIndex: "category",
      width: 110,
    },
    {
      title: "联系结果",
      dataIndex: "result",
      width: 110,
    },
    {
      title: "联系电话",
      dataIndex: "tel",
      width: 110,
    },
    // {
    //   title: '处理状态',
    //   dataIndex: 'state',
    //   width: 110,
    //   render: (value: number) => manageType(value),
    // },
    {
      title: "问题原因",
      dataIndex: "tags",
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        <div className={styles.tableTagColumn}>
          {((Object.values(value || {})[0] as any) || []).map((item1: any) => (
            <Tag key={item1.tagId}>{item1.title}</Tag>
          ))}
        </div>
      ),
    },
    {
      title: "记录内容",
      dataIndex: "content",
      width: 150,
    },
    {
      title: "用户配合度",
      dataIndex: "adaptability",
      width: 150,
    },
    {
      title: "创建日期",
      dataIndex: "createdAt",
      width: 150,
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : value,
    },
    {
      title: "客服记录图片/视频",
      dataIndex: "viewResources",
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        <Thumbnail dataSource={value || []} downloadImgName="客服图片" />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      // fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              type="link"
              operateType="/car-admin/car-diagnosis/service-recoed-edit"
              onClick={() =>
                setOperateVisible({ show: true, type: "edit", data: item })
              }
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该客服记录吗?"
              onConfirm={() => deleteServiceRecoedItem(item.id)}
            >
              <PermissionButton
                operateType="/car-admin/car-diagnosis/service-recoed-delete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[
                dayjs(searchParams.beginDate),
                dayjs(searchParams.endDate),
              ]}
            >
              <RangePicker allowClear={false} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="分类选择"
              name="cateId"
              initialValue={searchParams.cateId}
            >
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input
                placeholder="请输入车牌号"
                allowClear
                onChange={(e) => {
                  const newV = e.target.value.replace(/\s+/g, "");
                  form.setFieldValue("plateNumber", newV);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="车架号" name="frameNumber">
              <Input
                placeholder="请输入车架号"
                allowClear
                onChange={(e) => {
                  const newV = e.target.value.replace(/\s+/g, "");
                  form.setFieldValue("frameNumber", newV);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="联系结果" name="result">
              <Select
                placeholder="请选择联系结果"
                options={concatResultList}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="联系电话" name="tel">
              <Input placeholder="请输入联系电话" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="用户配合度" name="adaptability">
              <Select
                placeholder="请选择用户配合度"
                options={[
                  { name: "配合", value: "配合" },
                  { name: "一般配合", value: "一般配合" },
                  { name: "完全不配合", value: "完全不配合" },
                ]}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <ExportButton
                  url={serviceRecoedRequestPath}
                  fileName="客服记录"
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey="/car-admin/car-diagnosis/service-recoed-export"
                />
              </div>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <div className={styles.operateBox}>
                <PermissionButton
                  type="primary"
                  operateType="/car-admin/car-diagnosis/service-recoed-add"
                  style={{ marginBottom: "16px" }}
                  onClick={() =>
                    setOperateVisible({ show: true, type: "serviceRecordAdd" })
                  }
                >
                  新增客服记录
                </PermissionButton>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加客服记录的弹窗 */}
      {operateVisible.show && (
        <OperateService
          type={operateVisible.type}
          onClose={() =>
            setOperateVisible({ show: false, type: "serviceRecordAdd" })
          }
          reloadData={() => getData()}
          anyData={anyData}
          editData={operateVisible.data}
        />
      )}
    </Card>
  );
};

export default ServiceRecord;
