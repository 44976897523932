/**
 * 车辆运行表格数据(黄河设备/昆仑山设备)
 */
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { carDiagnosisRequest, carDiagnosisRequestPath } from '@/service/carDiagnosis';
import pageStyles from '@/styles/pageContent.module.scss';

interface CarOperationTableProps {
  diagnosisSearchParams: any;
  getExportParams: () => any;
  isKls?: boolean;
  noTitle?: boolean;
}

const CarOperationTable = (props: CarOperationTableProps) => {
  const { diagnosisSearchParams, getExportParams, isKls, noTitle } = props;
  const [searchParams, setSearchParams] = useState(diagnosisSearchParams);
  const dispatch = useDispatch();

  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    setSearchParams(diagnosisSearchParams);
  }, [diagnosisSearchParams]);

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carDiagnosisRequest,
    initRequest: Boolean(diagnosisSearchParams),
    initPageSize: 40,
    searchParams,
  });

  // 渲染表格title
  const renderColumnTitle = (showText: string, title: string) => (
    <Tooltip
      title={<div dangerouslySetInnerHTML={{ __html: showText }} />}
    >
      {title}
    </Tooltip>
  )

  // 渲染车速为0、GPS速度为0、车速超120 列的单元格
  const renderSpeedColumnCell = (record: any, field: string) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage } = record;
        // 里程 >= 10   100≤次数＜200 标浅黄 ;200≤次数＜300  标橙 ; 300≤次数  标红
        if (mileage >= 10) {
          //车速为0
          if (field == 've0') {
            //如果是昆仑山设备
            if (isKls) {
              if (record[field] >= 50 && record[field] < 150) {
                return { className: 'minorWarninmgTableCell' }
              } else if (record[field] >= 150 && record[field] < 300) {
                return { className: 'warningTableCell' }
              } else if (record[field] >= 300) {
                return { className: 'dangerTableCell' }
              }
              return {};
            } else {
              if (record[field] >= 100 && record[field] < 200) {
                return { className: 'minorWarninmgTableCell' }
              } else if (record[field] >= 200 && record[field] < 300) {
                return { className: 'warningTableCell' }
              } else if (record[field] >= 300) {
                return { className: 'dangerTableCell' }
              }
              return {};
            }
          }
          if (field == 'vm120') {
            if (record[field] >= 50 && record[field] < 70) {
              return { className: 'minorWarninmgTableCell' }
            } else if (record[field] >= 70 && record[field] <= 90) {
              return { className: 'warningTableCell' }
            } else if (record[field] >= 90) {
              return { className: 'dangerTableCell' }
            }
            return {};
          }
        }
        if (mileage > 0) {
          if (field == 'ge0') {
            if (record[field] >= 100 && record[field] < 300) {
              return { className: 'minorWarninmgTableCell' }
            } else if (record[field] >= 300) {
              return { className: 'warningTableCell' }
            }
            return {}
          }
        } else if (mileage == 0) {
          if (record[field] >= 100) {
            return { className: 'dangerTableCell' }
          }
        }
      }
    }
  }

  // 渲染故障码列
  const renderFaultCodeColumnCell = (record: any, field: string) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage, ultrCount, hmwHumanCount } = record;
        //故障码2 /e91： 10≤次数＜50   50≤次数＜100   100≤次数
        if (mileage >= 10 && hmwHumanCount <= 10) {
          if (field === 'e91') {
            if (record[field] >= 10 && record[field] < 50) {
              return { className: 'minorWarninmgTableCell' }
            } else if (record[field] >= 50 && record[field] < 100) {
              return { className: 'warningTableCell' }
            } else if (record[field] >= 100) {
              return { className: 'dangerTableCell' }
            }
            return {};
          }
        }
        if (field === 'e2') {
          if (record[field] >= 10 && record[field] < 50) {
            return { className: 'minorWarninmgTableCell' }
          } else if (record[field] >= 50 && record[field] < 100) {
            return { className: 'warningTableCell' }
          } else if (record[field] >= 100) {
            return { className: 'dangerTableCell' }
          }
          return {};
          //故障码31/ e133: 100≤次数＜200  200≤次数＜300  300≤次数
        } else if (field === 'e31' || field === 'e133') {
          if (record[field] >= 100 && record[field] < 200) {
            return { className: 'minorWarninmgTableCell' }
          } else if (record[field] >= 200 && record[field] < 300) {
            return { className: 'warningTableCell' }
          } else if (record[field] >= 300) {
            return { className: 'dangerTableCell' }
          }
          return {};
        }
        if (ultrCount == 0) {
          if (field === 'e130') {
            if (record[field] >= 30 && record[field] < 600) {
              return { className: 'minorWarninmgTableCell' }
            } else if (record[field] >= 60 && record[field] < 90) {
              return { className: 'warningTableCell' }
            } else if (record[field] >= 90) {
              return { className: 'dangerTableCell' }
            }
            return {};
          }
        }
      }
    }
  }


  // 渲染 AEB数量、双目制动数量、超声波制动数列
  const renderBreakColumnCell = (record: any, field: string) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        //  里程 > 0，且数量为0，标红；数量 >100，标红
        if ((record.mileage > 0 && !record[field]) || record[field] >= 100) {
          return { className: 'dangerTableCell' }
        }
        return {};
      }
    }
  }

  // 渲染 HMW数量、LDW数量列
  const renderAlarmColumnCell = (record: any, field: string) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const value = record[field];
        //  里程 > 0，且数量为0，标红；数量 > 500，标黄；数量 > 1000，标红
        if ((record.mileage > 0 && !value) || value >= 1000) {
          return { className: 'dangerTableCell' }
        }
        if (value >= 500) {
          return { className: 'warningTableCell' }
        }
        return {};
      }
    }
  }

  // 渲染HMW频次列
  const renderHmwBiCell = (record: any, field: string) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage } = record;
        const value = record[field];
        //  里程 > 40；数量 > 300，标黄；数量 > 400，标红
        if (mileage > 40) {
          if (value > 400) {
            return { className: 'dangerTableCell' }
          }
          if (value > 300) {
            return { className: 'warningTableCell' }
          }
        }
        return {};
      }
    }
  }

  // 渲染AEB频次列
  const renderAebBiCell = (record: any) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage, aebBi } = record;
        //  里程 > 40；数量 > 30，标黄；数量 > 50，标红
        if (mileage > 40) {
          if (aebBi > 50) {
            return { className: 'dangerTableCell' }
          }
          if (aebBi > 30) {
            return { className: 'warningTableCell' }
          }
        }
        return {};
      }
    }
  }

  // 渲染车身信息次数列
  const renderSignCountCell = (record: any) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage, signCount, breakCount, leftCount, rightCount } = record;
        //  里程 >= 1；
        if (mileage >= 5 && signCount !== 0) {
          if (signCount == breakCount || signCount == leftCount || signCount == rightCount) {
            return { className: 'dangerTableCell' }
          }
        }
        return {};
      }
    }
  }
  // 渲染刹车信号次数列
  const renderBreakCountCell = (record: any) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage, breakCount, signCount } = record;
        //  里程 >= 5 且次数为0；
        if (mileage >= 5) {
          if (breakCount == 0 || breakCount == signCount) {
            return { className: 'dangerTableCell' }
          }
        }
        return {};
      }
    }
  }
  // 渲染左转信号次数列
  const renderLeftCountCell = (record: any) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage, leftCount } = record;
        //  里程 >= 10 且次数为0；
        if (mileage >= 10) {
          if (leftCount == 0) {
            return { className: 'dangerTableCell' }
          }
        }
        return {};
      }
    }
  }
  // 渲染右转信号次数列
  const renderRightCountCell = (record: any) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { mileage, rightCount } = record;
        //  里程 >= 10 且次数为0；
        if (mileage >= 10) {
          if (rightCount == 0) {
            return { className: 'dangerTableCell' }
          }
        }
        return {};
      }
    }
  }

  //渲染AEB开关关闭列
  const renderAebCloseCell = (record: any) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { aebOffCount } = record;
        //  1≤ 次数 ＜5 标浅黄；5≤次数＜10 橙色; 10≤次数标红
        if (aebOffCount >= 1 && 5 > aebOffCount) {
          return { className: 'minorWarninmgTableCell' }
        } else if (aebOffCount >= 5 && aebOffCount < 10) {
          return { className: 'warningTableCell' }
        } else if (10 <= aebOffCount) {
          return { className: 'dangerTableCell' }
        } else {
          return {}
        }
      }
    }
  }

  // 渲染相机其它故障列
  const renderCameraFailure = (value: any, item: any) => {
    if (data[0]) {
      if (data[0].deviceTypeStr !== 'TLY0.4') {
        const { e4, e5, e6, e7, e11, e32, e33, e34, e41, e45, e46, e81 } = item;
        const showText = `
      故障码4: ${e4}<br>
      故障码5: ${e5}<br>
      故障码6: ${e6}<br>
      故障码7: ${e7}<br>
      故障码11: ${e11}<br>
      故障码32: ${e32}<br>
      故障码33: ${e33}<br>
      故障码34: ${e34}<br>
      故障码41: ${e41}<br>
      故障码45: ${e45}<br>
      故障码46: ${e46}<br>
      故障码81: ${e81}<br>
    `;
        return (
          <Tooltip placement="topLeft" title={<div dangerouslySetInnerHTML={{ __html: showText }} />}>
            {e4 + e5 + e6 + e7 + e11 + e32 + e33 + e34 + e41 + e45 + e46 + e81}
          </Tooltip>
        )
      }
    }
  }

  // 点击表格日期跳转到驾驶行为分析页面
  const onDateClick = (plateNumber: string, date: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '驾驶行为分析',
        key: '/car-admin/drive-analysis',
        params: { plateNumber, date }
      }
    })
  }

  // 表格列显示
  const columns: any = [
    {
      title: '日期',
      dataIndex: 'date',
      width: 70,
      render: (value: string, item: any) => (
        <div className={pageStyles.tableOperateColumn}>
          <Button
            type="link"
            onClick={() => onDateClick(item.plate_number, dayjs(value).format('YYYY-MM-DD'))}
          >
            {dayjs(value).format('MM-DD')}
          </Button>
        </div>
      ),
      fixed: 'left' as 'left'
    },
    {
      title: '运行时长(分)',
      dataIndex: 'effectMinute',
      width: 120,
    },
    {
      title: '运行里程(km)',
      dataIndex: 'mileage',
      width: 120,
    },
    {
      title: 'AEB开关关闭',
      dataIndex: 'aebOffCount',
      width: 120,
      onCell:(record:any)=> renderAebCloseCell(record)
    },
    {
      title: '车身信息次数',
      dataIndex: 'signCount',
      width: 120,
      onCell:(record:any)=>renderSignCountCell(record)
    },
    {
      title: '刹车信号次数',
      dataIndex: 'breakCount',
      width: 120,
      onCell:(record:any) =>renderBreakCountCell(record)
    },
    {
      title: '左转信号次数',
      dataIndex: 'leftCount',
      width: 120,
      onCell:(record:any) =>renderLeftCountCell(record)
    },
    {
      title: '右转信号次数',
      dataIndex: 'rightCount',
      width: 120,
      onCell:(record:any) =>renderRightCountCell(record)

    },
    {
      title: renderColumnTitle('平台监测到连续报车速为0，且伴随HMW预警频次显著低时（百公里预警次数低于1次），则必须高优先级维护<br>（如人为遮挡，则按公司相关规定对车主进行相应处罚）','车速为0'),
      dataIndex: 've0',
      width: 90,
      onCell: (record: any) => renderSpeedColumnCell(record, 've0')
    },
    {
      title: renderColumnTitle(isKls ? '1. 重连GPS天线<br>2. 如定位无改善，则更换GPS天线' : '1. 检查4G模块供电，开关是否打开<br>2. 重连GPS天线<br>3. 如定位无改善，则更换GPS天线或4G模块测试', 'GPS速度为0'),
      dataIndex: 'ge0',
      width: 110,
      onCell: (record: any) => renderSpeedColumnCell(record, 'ge0')
    },
    {
      title: renderColumnTitle('1. 检查轮速传感器是否进水损坏，损坏即更换<br>2. 滑阻及AEB主机内车速参数是否正确调校', '车速超120'),
      dataIndex: 'vm120',
      width: 110,
      onCell: (record: any) => renderSpeedColumnCell(record, 'vm120')
    },
    {
      title: renderColumnTitle('2:相机异常<br>平台监测到连续错误代码02，且伴随HMW预警频次显著低时（百公里预警次数低于1次），需拆回相机返修', '故障码2'),
      dataIndex: 'e2',
      width: 90,
      onCell: (record: any) => renderFaultCodeColumnCell(record, 'e2'),
    },
    // 黄河设备不展示该列
    {
      title: renderColumnTitle('故障码4:内部看门狗错误<br>故障码5:内部看门狗错误<br>故障码6:没有remap数据<br>故障码7:ISP检查返回错误<br>故障码11:视差数据异常<br>故障码32:无法获取雷达等的传感器信号<br>故障码33:通道 0 总线异常（仅表示发送异常）<br>故障码34:通道 1 总线异常（仅表示发送异常）<br>故障码41:IMU 信号异常<br>故障码45:GPS 信号异常<br>故障码46:4G 信号异常<br>故障码81:高温保护', '相机其它故障'),
      dataIndex: 'e4',
      width: 120,
      hide: !isKls,
      ellipsis: { showTitle: false },
      render: renderCameraFailure,
    },
    {
      title: renderColumnTitle('31:相机CAN通信异常<br>1. 重新连接CAN线<br>2. 如重连无效，则更换线束测试<br>3. 如更换线束无效，则更换AEB主机测试<br>4. 如以上皆无效，则更换相机测试<br>5. 如有更换相机或AEB主机设备，设备需返厂维修', '故障码31'),
      dataIndex: 'e31',
      width: 100,
      onCell: (record: any) => renderFaultCodeColumnCell(record, 'e31'),
    },
    {
      title: renderColumnTitle('91:相机被遮挡<br>1. 检查镜头玻璃是否脏污或遮挡<br>2. 如人为遮挡，则按公司相关规定对车主进行相应处罚', '故障码91'),
      dataIndex: 'e91',
      width: 100,
      onCell: (record: any) => renderFaultCodeColumnCell(record, 'e91'),
    },
    // 昆仑山设备不展示该列
    {
      title: renderColumnTitle('130:超声波通讯异常<br>平台监测到连续错误代码130，且伴随超声波制动频次显著低（百公里制动次数低于1次）或超声波制动频次显著高时（百公里制动次数大于100次），需拆回超声波雷达返修<br>1. 重连超声波雷达电源线及CAN线<br>2. 检查雷达探头是否被污染、遮挡，清理遮挡物<br>3. 如以上无效，则更换线束或相应雷达控制器<br>', '故障码130'),
      dataIndex: 'e130',
      width: 100,
      onCell: (record: any) => renderFaultCodeColumnCell(record, 'e130'),
      hide: isKls,
    },
    // 昆仑山设备不展示该列
    {
      title: renderColumnTitle('133:4G/GPS通讯故障<br>平台持续跟踪到设备上报“133”，根因为4G模块与AEB主控制器之间通信超时，故上报“133”。<br>1. 重连AEB主机与4G模块之间的线束<br>2. 如重连无效，则更换4G模块或AEB控制器主机', '故障码133'),
      dataIndex: 'e133',
      width: 100,
      onCell: (record: any) => renderFaultCodeColumnCell(record, 'e133'),
      hide: isKls,
    },
    {
      title: 'FCW数量',
      dataIndex: 'fcwCount',
      width: 100,
    },
    // {
    //   title: '起步阻止数量',
    //   dataIndex: 'stsCount',
    //   width: 120,
    // },
    {
      title: 'LDW数量',
      dataIndex: 'ldwCount',
      width: 100,
      onCell: (record: any) => renderAlarmColumnCell(record, 'ldwCount'),
    },
    // {
    //   title: 'HMW数量',
    //   dataIndex: 'hmwCount',
    //   width: 100,
    //   onCell: (record: any) => renderAlarmColumnCell(record, 'hmwCount'),
    // },
    {
      title: '修正后HMW数量',
      dataIndex: 'hmwHumanCount',
      width: 140,
      onCell: (record: any) => renderAlarmColumnCell(record, 'hmwHumanCount'),
    },
    // {
    //   title: 'HMW最大值',
    //   dataIndex: 'hmwMax',
    //   width: 120,
    // },
    // {
    //   title: 'HMW最小值',
    //   dataIndex: 'hmwMin',
    //   width: 120,
    // },
    // {
    //   title: 'HMW平均值',
    //   dataIndex: 'hmwAvg',
    //   width: 120,
    // },
    // {
    //   title: 'HMW频次',
    //   dataIndex: 'hmwBi',
    //   width: 100,
    //   onCell: (record: any) => renderHmwBiCell(record, 'hmwBi'),
    // },
    {
      title: '修正后HMW频次',
      dataIndex: 'hmwHumanBi',
      width: 140,
      onCell: (record: any) => renderHmwBiCell(record, 'hmwHumanBi'),
    },
    {
      title: 'AEB数量',
      dataIndex: 'aebCount',
      width: 90,
      onCell: (record: any) => renderBreakColumnCell(record, 'aebCount'),
    },
    {
      title: 'AEB频次',
      dataIndex: 'aebBi',
      width: 100,
      onCell: (record: any) => renderAebBiCell(record),
    },
    {
      title: '双目制动数量',
      dataIndex: 'binCount',
      width: 120,
      onCell: (record: any) => renderBreakColumnCell(record, 'binCount'),
    },
    // {
    //   title: '双目制动HMW最大值',
    //   dataIndex: 'binocularHmwMax',
    //   width: 170,
    // },
    // {
    //   title: '双目制动HMW最小值',
    //   dataIndex: 'binocularHmwMin',
    //   width: 170,
    // },
    // {
    //   title: '双目制动HMW平均值',
    //   dataIndex: 'binocularHmwAvg',
    //   width: 170,
    // },
    // {
    //   title: '毫米波制动数',
    //   dataIndex: 'millCount',
    //   width: 120,
    // },
    {
      title: '超声波制动数',
      dataIndex: 'ultrCount',
      width: 120,
      onCell: (record: any) => renderBreakColumnCell(record, 'ultrCount'),
    },
    {
      title: '角雷达制动次数',
      dataIndex: 'angleRadarCount',
      width: 150,
      hide: !isKls,
    },
    {
      title: '车身异常数量',
      dataIndex: 'vehicleBodyErrCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: '控制器异常数量',
      dataIndex: 'controllerErrCount',
      width: 140,
      hide: !isKls,
    },
    {
      title: '4g异常数量',
      dataIndex: 'err4gCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: 'gps异常数量',
      dataIndex: 'gpsErrCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: 'imu异常数量',
      dataIndex: 'imuErrCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: '比例阀异常数量',
      dataIndex: 'proportionErrorCount',
      width: 140,
      hide: !isKls,
    },
    {
      title: '角雷达预警次数',
      dataIndex: 'bsdCount',
      width: 140,
      hide: !isKls,
    },
    {
      title: '角雷达故障次数',
      dataIndex: 'milimeterWaveErrCount',
      width: 140,
      hide: !isKls,
    },
  ];

  // 过滤不展示的列
  const showColumns = columns.filter((item: any) => !item.hide);

  return (
    <Fragment>
      <div style={{ margin: '12px 0', textAlign: 'right', position: 'relative' }}>
      {/* {!noTitle && <div style={{position:'absolute', top: '50px', left: '10px', fontSize: '18px'}}>AEB运行数据</div>} */}
        <ExportButton
          type='primary'
          disabled={!diagnosisSearchParams}
          url={carDiagnosisRequestPath}
          fileName={`${diagnosisSearchParams?.plateNumber}车辆分析`}
          getParamsFunc={getExportParams}
          requestMethod="GET"
          permissionKey='/car-admin/car-diagnosis/car-operation-export'
        />
      </div>
      <CommonTable
        rowKey="date"
        columns={showColumns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        scroll={{ x: '100%', y: 400 }}
      />
    </Fragment>
  )
}

export default CarOperationTable;