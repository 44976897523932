/**
 * 路线标注添加/编辑
 */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { upsertCrossMarkRequest } from '@/service/crossMark';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false); // 保存按钮的loading

  // 编辑时赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存路线
  const saveRoad = async (values: any) => {
    try {
      setSaveLoading(true);
      const { name } = values;
      const params: { [key: string]: string | number } = {
        name
      };
      if (type === 'edit') {
        params.id = data.id;
      } else {
        params.content = '';
      }
      const res = await upsertCrossMarkRequest(params);
      if (res.data?.data) {
        message.success(type === 'edit' ? '编辑成功' : '添加成功');
        closeModal();
        reloadData();
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  };

  return (
    <Modal
      title={type === 'add' ? '添加路线' : '编辑路线'}
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okButtonProps={{
        loading: saveLoading
      }}
    >
      <Form form={form} autoComplete="off" onFinish={saveRoad}>
        <Form.Item
          label="路线名称"
          name="name"
          rules={[{ required: true, message: '请输入路线名称' }]}
        >
          <Input placeholder="请输入路线名称" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
