/**
 * 工单详情
 */
import React, { Fragment, useEffect, useState } from "react";
import { Descriptions, Drawer, Timeline, Tag } from 'antd';
import { workOrderDetailRequest } from '@/service/workOrder';
import { renderTypeColumns, renderStatusColumns } from '@/utils/workOrder';
import { formatTime } from "@/utils/formatTime";
import { WorkOrderStatusEnum } from '@/constants/workOrder';
import LoadingWrapper from '@/components/loadingWrapper';
import PermissionButton from '@/components/permissionButton';
import AssignModal from '../assignModal';
import styles from './index.module.scss';

interface WorkOrderDetailProps {
  item: any; // 工单信息
  onClose: () => void; // 关闭工单详情弹层的方法
}
const WorkOrderDetail = (props: WorkOrderDetailProps) => {
  const { item, onClose } = props;
  const [orderDetail, setOrderDetail] = useState<any>({}); // 工单详情
  const [loading, setLoading] = useState<boolean>(false) // 工单详情是否在请求中
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息

  // 获取工单详情
  const getOrderDetail = async() => {
    try {
      setLoading(true);
      const res = await workOrderDetailRequest(item.id);
      const data = res.data?.data || {};
      setOrderDetail(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrderDetail();
  }, []);

  // 过滤出来评论的数据
  const commentData = (orderDetail?.events || []).filter((item: any) => Boolean(item.type === 'ps' && item.reason));

  return (
    <Drawer
      title='工单详情'
      visible
      size="large"
      onClose={onClose}
    >
      <LoadingWrapper spinning={loading}>
        <Descriptions bordered size="small" column={{ xs: 1, sm: 2 }}>
          <Descriptions.Item label="工单编号" span={2}>
            {orderDetail.no}
            {(orderDetail.delay && [WorkOrderStatusEnum.assigned, WorkOrderStatusEnum.pending, WorkOrderStatusEnum.progress].includes(orderDetail.stat)) ? <span className="dangerText">(已逾期)</span> : ''}
          </Descriptions.Item>
          <Descriptions.Item label="车牌号">{orderDetail.plateNumber}</Descriptions.Item>
          <Descriptions.Item label="工单标签">
            <div className={styles.tableTagColumn}>
              {(Object.values(orderDetail.tags || {})[0] as any || []).map(((item1: any) => <Tag key={item1.tagId}>{item1.title}</Tag>))}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="创建日期">{formatTime(orderDetail.createTime)}</Descriptions.Item>
          <Descriptions.Item label="期望完成日期">{formatTime(orderDetail.expire)}</Descriptions.Item>
          <Descriptions.Item label="工单类型">{renderTypeColumns(orderDetail.type)}</Descriptions.Item>
          <Descriptions.Item label="工单状态">{renderStatusColumns(orderDetail.stat)}</Descriptions.Item>
          <Descriptions.Item label="工单创建人">{orderDetail.issu}</Descriptions.Item>
          <Descriptions.Item label="维保人员">{orderDetail.operator}</Descriptions.Item>
          <Descriptions.Item label="工单区域" span={2}>{orderDetail.district}</Descriptions.Item>
          <Descriptions.Item label="工单描述" span={2}>{orderDetail.description}</Descriptions.Item>
        </Descriptions>
        {Boolean(commentData.length) &&
          <Fragment>
            <div className={styles.commentTitle}>工单备注</div>
              <div className={styles.commentBox}>
                {commentData.map((item: any) => (<div className={styles.commentItem}>{item.reason}</div>))}
              </div>
          </Fragment>
        }
        <Timeline style={{ marginTop: '24px' }}>
          {(orderDetail?.events || []).map((item: any) => (
            <Timeline.Item key={item.id}>
              <div>
                {item.type == 'signIn'? item.description + `（${item.address}）` : item.description}
              </div>
              <div>操作时间：{formatTime(item.time)}</div>
              <div>操作人：{item.nickname}</div>
              <div>{item.reason ? `原因：${item.reason}` : ''}</div>
            </Timeline.Item>
          ))}
        </Timeline>
        {/* 未分配的工单才可以指派 */}
        {item.stat === WorkOrderStatusEnum.unassign &&
          <PermissionButton operateType='/car-admin/work-order/assign' type="primary" onClick={() => setOperateInfo({ type: 'assign', data: item })}>指派</PermissionButton>
        }
        {['reassign', 'assign'].includes(operateInfo?.type) &&
         <AssignModal data={operateInfo.data} onClose={() => setOperateInfo({})} reloadData={getOrderDetail} type={operateInfo.type} />
        }
      </LoadingWrapper>
    </Drawer>
  )
}

export default WorkOrderDetail;