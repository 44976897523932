/**
 * 历史联系结果
 */
import { useEffect, useState } from 'react';
import { Modal, Timeline } from 'antd';
import LoadingWrapper from '@/components/loadingWrapper';
import { formatTime } from "@/utils/formatTime";

interface VersionModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const HistoryResult = (props: VersionModalProps) => {
  const { data, closeModal } = props;
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Modal
      title="历史联系结果"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      width={'520px'}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      {/* 根据设备版本显示不同的内容 */}
      <LoadingWrapper spinning={loading}>
      <Timeline style={{ marginTop: '24px' }}>
          {(data || []).map((item: any) => (
            <Timeline.Item key={item.id}>
              <div style={{display:'flex', flexDirection:'row', flexWrap:'nowrap', justifyContent: 'space-between', height:'26px', lineHeight:'26px'}}>
                <div>联系时间：{formatTime(item.createdAt)}</div>
              </div>
              <div>联系内容：{item.content}</div>
            </Timeline.Item>
          ))}
        </Timeline>
      </LoadingWrapper>
    </Modal>
  )
}

export default HistoryResult;