/**
 * 状态查询相关请求
 */
import request from "@/utils/request";

export interface statusListRequestParams {
  page: number;
  limit: number;
  startTime?: string;
  endTime?: string;
  carIds?: Array<number>;
}

export const statusListRequestPath = '/api/v2/car/aebStatus';
// 获取状态查询数据
export const statusListRequest = (data: statusListRequestParams) => {
  return request.post(statusListRequestPath, data)
}
