import RenderEllipsisColumn from "@/components/renderEllipsisColumn";

// 设备sn列
export const snColumnConfig = {
  title: '设备SN',
  dataIndex: 'sn',
  width: 90,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />,
};

// 相机sn列
export const cameraSnColumnConfig = {
  title: '相机SN',
  dataIndex: 'camera_sn',
  width: 90,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />,
};

// 分组列
export const cateGroupColumnConfig = {
  title: '分组',
  dataIndex: 'cate_path_name',
  width: 130,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />,
};

// 车架号列
export const frameNumberColumnConfig = {
  title: '车架号',
  dataIndex: 'frame_number',
  width: 100,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />,
};

// 车辆品牌列
export const carBrandColumnConfig = {
  title: '车辆品牌',
  dataIndex: 'car_brand',
  width: 100,
};

// 车辆型号列
export const carModelColumnConfig = {
  title: '车辆型号',
  dataIndex: 'car_model',
  width: 100,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />,
};