/**
 * 参数配置弹窗
 */
import { Modal } from 'antd';
import CommonTable from '@/components/commonTable';

interface ContactModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const ContactModal = (props: ContactModalProps) => {
  const { data, closeModal } = props;
  // 表格列显示
  const columns = [
    {
      title: '参数',
      dataIndex: 'title',
    },
    {
      title: '参数信息',
      dataIndex: 'name',
    },

  ]

  const tableData = [
    { title: 'AEBSN', name: data?.sn, key: 'AEBSN' },
    { title: 'ADASSN', name: data?.car_drive,  key: 'ADASSN' },
    { title: 'AEBSIM', name: data?.sim,  key: 'AEBSIM' },
    { title: '相机SN', name: data?.camera_sn,  key: '相机SN' },
    { title: 'ADASSIM', name: data?.ooe,  key: 'ADASSIM' },
    { title: '参数信息', name: data?.paramInfo,  key: '参数信息' },
    { title: '版本信息', name: data?.versionInfo,  key: '版本信息' },
    { title: '硬件配置', name: data?.hardwareConfigTypeStr,  key: '硬件配置' },
  ];

  return (
    <Modal
      title="参数配置信息"
      visible
      footer={null}
      onCancel={closeModal}
      centered
    >
      <CommonTable
        columnFilter={false}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        size='small'
      />
    </Modal>
  )
}

export default ContactModal;
   