/**
 * 传感器方案-设备类型选择器
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { omit } from 'lodash';
import { sensorDeviceTypeListRequest } from '@/service/sensorDeviceTypeManage';
import { actuatorName } from '@/constants/sensorScheme';

interface SensorDeviceTypeSelectProps extends SelectProps {
  value?: string; // 选择的设备类型
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  valueField?: string; // 作为选择器value的字段
}
const SensorDeviceTypeSelect = (props: SensorDeviceTypeSelectProps) => {
  const { value, onChange, valueField } = props;
  const [sensorDeviceTypeList, setSensorDeviceTypeList] = useState<any>([]); // 设备类型列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>(); // 选择的设备类型

  // 获取设备类型列表数据
  const getSensorDeviceTypeList = async () => {
    const res = await sensorDeviceTypeListRequest({
      page: 1,
      limit: 999
    });
    const resData = (res.data?.data || []).map((item: any) => ({
      label:
        item.name === actuatorName ? `${item.name}-${item.typeId}` : item.name,
      value: valueField ? item[valueField] : item.id
    }));
    setSensorDeviceTypeList(resData);
  };

  useEffect(() => {
    getSensorDeviceTypeList();
  }, []);

  // 监听选择的设备类型进行赋值
  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const selectOwnProps = omit(props, 'valueField');
  return (
    <Select
      {...selectOwnProps}
      optionFilterProp="label"
      allowClear
      showSearch
      options={sensorDeviceTypeList}
      onChange={onChange}
      value={selectValue}
      placeholder="请选择"
    />
  );
};

export default SensorDeviceTypeSelect;
