/**
 * 渠道档案相关请求
 */
import request from "@/utils/request";

export interface ChannelListRequestParams {
    page: number;
    limit: number;
    name: string;
}

export const channelListRequestPath = '/api/v2/channel/v1/list';

// 获取数据
export const channelListRequest = (params: ChannelListRequestParams) => {
    return request.get(channelListRequestPath, { params })
}

// 获取设备版本
export const getVersion = (url: string) => {
    return request.get(url)
}

// 添加数据
export const upsertChannel = (data: any) => {
    return request.post('/api/v2/channel/upsert', data)
}

// 删除
export const DeleteChannel = (id: number) => {
    return request.delete(`/api/v2/channel/${id}`)
}