/**
 * 操作部标机设备报警视频上传规则(添加、编辑)
 */
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Form, message, InputNumber, Switch, Select, Input } from 'antd';
import { upsertJtDeviceUploadRuleRequest } from '@/service/jtDeviceUploadRule';
import {
  alarmRuleData,
  warningLevelData
} from '@/constants/jtDeviceUploadRule';

interface OperateModalProps {
  type: string; // 操作类型
  data?: any; // 编辑时传入的数据
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 操作成功后调用的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, onClose, reloadData } = props;
  const [loading, setLoading] = useState<boolean>(false); // 保存接口是否请求中
  const [form] = Form.useForm();

  useEffect(() => {
    if (type === 'edit') {
      const params = { ...data };
      Object.keys(data.ruleMap).forEach((item: string) => {
        params[item] = true;
        params[`level_${item}`] = data.ruleMap[item];
      });
      form.setFieldsValue(params);
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    try {
      setLoading(true);
      const { name, timeOffset } = values;
      const rule: any = {};
      alarmRuleData.forEach((item: any) => {
        if (values[item.key]) {
          rule[item.key] = values[`level_${item.key}`];
        }
      });
      const params: any = {
        name,
        timeOffset,
        rule: JSON.stringify(rule)
      };
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertJtDeviceUploadRuleRequest(params);
      setLoading(false);
      if (res.data?.data) {
        message.success(type === 'add' ? '添加成功' : '编辑成功');
        onClose();
        reloadData();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      title={type === 'add' ? '添加规则' : '编辑规则'}
      open
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="规则名称"
          name="name"
          rules={[{ required: true, message: '请填写规则名称' }]}
        >
          <Input
            placeholder="请填写规则名称"
            style={{ width: '100%' }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="视频时间偏移量"
          name="timeOffset"
          rules={[{ required: true, message: '请填写视频时间偏移量' }]}
        >
          <InputNumber
            placeholder="请填写视频时间偏移量"
            style={{ width: '100%' }}
            addonAfter="秒"
          />
        </Form.Item>
        {alarmRuleData.map((item: any) => (
          <Fragment key={item.title}>
            <Form.Item
              label={`${item.title}触发上传`}
              name={item.key}
              valuePropName="checked"
            >
              <Switch checkedChildren="开" unCheckedChildren="关" />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => {
                const enable = form.getFieldValue(item.key);
                if (enable) {
                  return (
                    <Form.Item
                      label="预警等级"
                      name={`level_${item.key}`}
                      rules={[
                        { required: true, message: '请选择触发上传的预警等级' }
                      ]}
                    >
                      <Select
                        options={warningLevelData}
                        placeholder="请选择预警等级"
                        showArrow
                        allowClear
                      />
                    </Form.Item>
                  );
                } else {
                  return null;
                }
              }}
            </Form.Item>
          </Fragment>
        ))}
      </Form>
    </Modal>
  );
};

export default OperateModal;
