import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { userStore } from './user/reducer';
import { tabPagesStore } from './tabPages/reducer';
import { carTreeStore } from './carTree/reducer';

const rootReducers = combineReducers({
  userStore,
  tabPagesStore,
  carTreeStore
})
const store = createStore(
  rootReducers,
  compose(applyMiddleware(reduxThunk))
);
export default store;