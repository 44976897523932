/**
 * 操作设备投保记录
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { upsertProductInsureRecord } from '@/service/productInsureRecord';
import QuickDatePicker from '@/components/quickDatePicker';
import dayjs from 'dayjs';


interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}

const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  useEffect(() => {
    if (type === 'edit') {
      const params = { ...data };
      if (params.beginTime !== null) {
        params.beginTime = dayjs(data.beginTime)
      }
      form.setFieldsValue(params)
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      if(params.beginTime){
        params.beginTime = dayjs(values.beginTime).format('YYYY-MM-DD')
      }
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertProductInsureRecord(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加设备投保' : '编辑设备投保'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError>
        <Form.Item
          label="aebSN"
          name="aebSn"
          >
          <Input placeholder="请输入aebSN" allowClear />
        </Form.Item>
        <Form.Item
          label="相机SN"
          name="carmaSn"
          rules={[{ required: true, message: '请输入相机SN' }]}>
          <Input placeholder="请输入相机SN" allowClear />
        </Form.Item>
        <Form.Item
          label="adasSn"
          name="adasSn"
        >
          <Input placeholder="请输入adasSn" allowClear />
        </Form.Item>
        <Form.Item 
        label="车牌号" 
        name="plateNumber"
        rules={[{ required: true, message: '请输入车牌号' }]}
        >
            <Input placeholder='请输入车牌号' allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
        </Form.Item>
        <Form.Item
         label="车架号" 
         name="frameNumber"
         rules={[{ required: true, message: '请输入车架号' }]}
         >
              <Input placeholder='请输入车架号'  onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
        </Form.Item>
        <Form.Item
          label="投保日期"
          name="beginTime"
          rules={[{ required: true, message: '请选择投保日期' }]}
        >
          <QuickDatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
