/**
 * 调查问卷汇总
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, Tabs } from 'antd';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';
import TotalTable from './components/totalTable';
import styles from '@/styles/pageContent.module.scss';
import ownStyles from './index.module.scss';
import { questionnaireCompanyPath, questionnaireCompanyRequest, questionnaireMaintainerPath, questionnaireMaintainerRequest, questionnairePlateNumberPath, questionnairePlateNumberRequest, questionnaireQuestionItemPath, questionnaireQuestionItemRequest, questionnaireTotalRequest } from '@/service/questionnaireSurveyTotal';

const { RangePicker } = DatePicker;
const QuestionnaireSurveyTotal = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(0, 'day').format('YYYY-MM-DD')}`,
  }); // 搜索相关的参数
  const [total, setTotal] = useState<any>(null); // 总分

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const res = await questionnaireTotalRequest();
    setTotal(res.data?.data)
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
    newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
    delete newSearchParams.day;
    setSearchParams(newSearchParams);
  };

  return (
    <Card bordered={false} className={`${styles.fixPageContentBox} ${ownStyles.abnormalSummary}`}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="day"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: any) => currentDate && (currentDate > dayjs().subtract(1, 'days') || currentDate < dayjs('2022-07-13'))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{marginBottom:'10px'}}>总体评分：<span style={{fontSize:'18px'}}>{total} </span> 分</div>
      <Tabs>
        <Tabs.TabPane tab="车队评分汇总排名" key="company">
          <TotalTable
            searchParams={searchParams} 
            requestMethod={questionnaireCompanyRequest}
            exportPath={questionnaireCompanyPath}
            tableName={'company'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="车辆评分汇总排名" key="plateNumber">
          <TotalTable
            searchParams={searchParams}
            requestMethod={questionnairePlateNumberRequest}
            exportPath={questionnairePlateNumberPath}
            tableName={'plateNumber'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="安装人员评分汇总排名" key="maintainer">
          <TotalTable
            searchParams={searchParams}
            requestMethod={questionnaireMaintainerRequest}
            exportPath={questionnaireMaintainerPath}
            tableName={'maintainer'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="调查问题项分布情况" key="questionItem">
          <TotalTable
            searchParams={searchParams}
            requestMethod={questionnaireQuestionItemRequest}
            exportPath={questionnaireQuestionItemPath}
            tableName={'questionItem'}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export default QuestionnaireSurveyTotal;