/**
 * 角色添加/编辑
 */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import OrganizationTreeSelect from '@/components/organizationTreeSelect';
import {
  upsertRoleRequest,
  getRoleRelateMenuRequest,
  roleRelateMenuRequest
} from '@/service/roleManage';

interface OperateRoleModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateRoleModal = (props: OperateRoleModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false); // 保存按钮的loading

  // 编辑时赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存角色
  const saveRole = async(values: any) => {
    try {
      setSaveLoading(true);
      const { name, groupId ,tabeBarShows } = values;
      const params: {[key: string]: string | number} = {
        name,
        displayName: name,
        groupId,
        tabeBarShows,
      }
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertRoleRequest(params);
      if (res.data?.data) {
        if (type === 'copy') {
          // 获取要复制角色的权限
          const copyPermissionRes = await getRoleRelateMenuRequest(data.id);
          // 将要复制角色的权限赋值给新创建的角色
          if (copyPermissionRes.data?.data) {
            const copyRes = await roleRelateMenuRequest({
              roleId: res.data?.data,
              menus: copyPermissionRes.data?.data
            });
            if (copyRes.data?.data) {
              message.success('复制成功');
            } else {
              message.info('角色创建成功，权限复制失败');
            }
          }
        } else {
          message.success(type === 'edit' ? '编辑成功' : '添加成功');
        }
        closeModal();
        reloadData();
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  }

  const appletPermissiaon = [
    { label: '工作台', value: 1},
    { label: '文件学习', value: 2 },
    { label: '车辆', value: 3 },
    { label: '工具箱', value: 4}
  ];

  return (
    <Modal
      title={
        type === 'add' ? '添加角色' : type === 'copy' ? '复制角色' : '编辑角色'
      }
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okButtonProps={{
        loading: saveLoading
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={saveRole}
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="角色名称"
          name="name"
          rules={[{ required: true, message: '请输入角色名称' }]}
        >
          <Input placeholder="请输入角色名称" />
        </Form.Item>
        <Form.Item
          label="角色所属组织"
          name="groupId"
          rules={[{ required: true, message: '请输入角色所属组织' }]}
        >
          <OrganizationTreeSelect />
        </Form.Item>
        <Form.Item
          label="配置小程序权限"
          name="tabeBarShows"
          rules={[{ required: true, message: '请选择小程序权限' }]}
        >
          <Select
            options={appletPermissiaon}
            placeholder="请选择小程序权限"
            showSearch
            mode="multiple"
            showArrow
            maxTagCount="responsive"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateRoleModal;
     