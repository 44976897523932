/**
 * 机器人推送记录
 */
import request from "@/utils/request";

export interface RobotPushRequestParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
}

// 获取车辆工时数据
export const robotPushRequest = (params: RobotPushRequestParams) => {
  return request.get('/api/v2/resource/reportDocs', {
    params,
  })
}