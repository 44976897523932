/**
 * 操作支付记录
 */

import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,

} from "antd";
import UploadFile from "@/components/uploadFile";

import {
  payRecordListRequest,
  upsertInsurePayRequest,
  deleteInsurePayRequest,
} from "@/service/underwritingRecord";

import PdfPreviewModal from "@/components/pdfPreviewModal";
import UploadImageVideo from "@/components/uploadImageVideo";


interface DoucumentCommentDetailProps {
  reloadData: () => void;
  closeModal: () => void;
  item?: any;
}

const UploadPdf = (props: DoucumentCommentDetailProps) => {
  const [form] = Form.useForm();
  const { item, closeModal, reloadData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false); // 记录弹框是否开启
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传文件
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求


  // 提交
  const submit = async () => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false);
      const res = await upsertInsurePayRequest({...item, resources: uploadSuccessList})
      setIsModalOpen(false);
      closeModal()
      reloadData()
    }
  };
  const updateFileList = (item: any) => {
    setUploadSuccessList(item);
  };

  return (
    <Modal
      title={"上传附件"}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: "70vh", overflow: "auto" }}
      centered
      maskClosable={false}
      width={800}
      open={isModalOpen}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item label="支付凭证" name="resources">
          {/* <UploadFile
            accept="application/pdf"
            uploadFilesChange={(successList: any) =>
              setUploadSuccessList(successList)
            }
            defaultUploadFiles={item.resources || []}
            canUploadVideo
            limitSize={500}
            multiple
          /> */}
          <UploadImageVideo
            businessName={"上传附件"}
            defaultUploadFiles={item.resources || []}
            uploadFilesChange={updateFileList}
            canUploadVideo={false}
            limitSize={10}
            maxCount={20}
            multiple={true}
          />
        </Form.Item>
      </Form>
      {pdfPreviewInfo && (
        <PdfPreviewModal
          title={pdfPreviewInfo?.fileName}
          pdfUrl={pdfPreviewInfo?.url}
          closeModal={() => setPdfPreviewInfo(null)}
        />
      )}
    </Modal>
  );
};

export default UploadPdf;
