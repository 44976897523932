/**
 * 传感器方案-设备类型管理相关请求
 */
import request from '@/utils/request';

export interface SensorDeviceTypeListParams {
  page: number;
  limit: number;
  name?: string;
  userId?: number;
  beginTime?: string;
  endTime?: string;
}
// 获取设备类型管理列表
export const sensorDeviceTypeListRequestPath = '/api/v2/componentType/list';
export const sensorDeviceTypeListRequest = (
  params: SensorDeviceTypeListParams
) => {
  return request.get(sensorDeviceTypeListRequestPath, {
    params
  });
};

export interface UpsertSensorDeviceTypeParams {
  name: string;
  typeId?: number;
  ps?: string;
  id?: number;
}
// 添加、编辑设备类型
export const upsertSensorDeviceTypeRequest = (
  data: UpsertSensorDeviceTypeParams
) => {
  return request.post('/api/v2/componentType/upsert', data);
};
