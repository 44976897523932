/**
 * 故障码筛选相关请求
 */
import request from "@/utils/request";

export interface FaultCodeFilterParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cate_id?: number;
  device_status?: Array<number>;
}

// 获取故障码筛选数据
export const faultCodeFilterRequestPath = '/api/v2/car/deviceWarns';
export const faultCodeFilterRequest = (data: FaultCodeFilterParams) => {
  return request.post(faultCodeFilterRequestPath, data)
}