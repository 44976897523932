/**
 * 固件管理相关请求
 */
import request from '@/utils/request';

export interface FirmwareListRequestParams {
  page: number;
  limit: number;
  version?: string;
  type?: string;
  packet?: number;
}

// 获取固件列表数据
export const firmwareListRequest = (params: FirmwareListRequestParams) => {
  return request.get('/api/v2/firmware/list', {
    params
  });
};

// 删除固件数据
export const firmwareDeleteRequest = (data: any) => {
  return request.delete('/api/v2/firmware/delete', {
    data
  });
};

// 添加固件数据
export const firmwareUpsertRequest = (data: any) => {
  return request.post('/api/v2/firmware/upsert', data);
};

// 绑定车辆数据
export const firmwareBindRequest = (data: any) => {
  return request.post('/api/v2/firmware/bindCar', data);
};

export interface FirmwareBindListRequestParams {
  page: number;
  limit: number;
  id: string;
}

// 获取固件已绑定车辆数据
export const firmwareBindListRequestPath = '/api/v2/firmware/bindList';
export const firmwareBindListRequest = (
  params: FirmwareBindListRequestParams
) => {
  return request.get(firmwareBindListRequestPath, {
    params
  });
};

// 删除固件绑定车辆数据
export const firmwareBindDeleteRequest = (data: { ids: Array<string> }) => {
  return request.delete('/api/v2/firmware/deleteBind', {
    data
  });
};
