/**
 * 部标机设备报警相关常量
 */
// 部标机设备报警类型数据
export const jtDeviceAlarmTypeData = [
  {
    label: '高级驾驶辅助系统报警',
    value: 'adas',
    children: [
      { label: '前向碰撞报警', value: 'adas_1' },
      { label: '车道偏离报警', value: 'adas_2' },
      { label: '车距过近报警', value: 'adas_3' },
      { label: '行人碰撞报警', value: 'adas_4' },
      { label: '频繁变道报警', value: 'adas_5' },
      { label: '道路标识超限报警', value: 'adas_6' },
      { label: '障碍物报警', value: 'adas_7' },
      { label: '道路标志识别事件', value: 'adas_16' },
      { label: '主动抓拍事件', value: 'adas_17' },
      // 粤标start
      { label: '实线变道报警', value: 'adas_18' },
      { label: '车厢过道行人检测报警', value: 'adas_19' },
      // 粤标end
      { label: '前向制动', value: 'adas_96' },
      { label: '行人制动', value: 'adas_97' }
    ]
  },
  {
    label: '盲区监测系统报警',
    value: 'blind',
    children: [
      { label: '后方接近报警', value: 'blind_1' },
      { label: '左侧后方接近报警', value: 'blind_2' },
      { label: '右侧后方接近报警', value: 'blind_3' },
      { label: '前盲碰撞报警', value: 'blind_4' },
      { label: '前盲行人碰撞报警', value: 'blind_5' },
      { label: '右盲碰撞报警', value: 'blind_6' },
      { label: '右盲行人碰撞报警', value: 'blind_7' },
      { label: '左盲碰撞报警', value: 'blind_8' },
      { label: '左盲行人碰撞报警', value: 'blind_9' },
      { label: '后盲碰撞报警', value: 'blind_10' },
      { label: '后盲行人碰撞报警', value: 'blind_11' },
      { label: '前盲制动', value: 'blind_12' },
      { label: '前盲行人制动', value: 'blind_13' },
      { label: '右盲制动', value: 'blind_14' },
      { label: '右盲行人制动', value: 'blind_15' },
      { label: '左盲制动', value: 'blind_16' },
      { label: '左盲行人制动', value: 'blind_17' },
      { label: '后盲制动', value: 'blind_18' },
      { label: '后盲行人制动', value: 'blind_19' },
      { label: '起步前盲预警', value: 'blind_20' },
      { label: '起步前盲制动', value: 'blind_21' }
    ]
  },
  {
    label: '驾驶员状态监测系统报警',
    value: 'driver',
    children: [
      { label: '疲劳驾驶报警', value: 'driver_1' },
      { label: '接打电话报警', value: 'driver_2' },
      { label: '抽烟报警', value: 'driver_3' },
      { label: '分神驾驶报警', value: 'driver_4' },
      { label: '驾驶员异常报警', value: 'driver_5' },
      // 粤标start
      { label: '探头遮挡报警', value: 'driver_6' },
      { label: '超时驾驶报警', value: 'driver_8' },
      { label: '未系安全带报警', value: 'driver_10' },
      { label: '红外阻断型墨镜失效报警', value: 'driver_11' },
      { label: '双脱把报警（双手同时脱离方向盘）', value: 'driver_12' },
      { label: '玩手机报警', value: 'driver_13' },
      // 粤标end
      { label: '主动抓拍事件', value: 'driver_16' },
      { label: '驾驶员变更事件', value: 'driver_17' },
      { label: '打哈欠', value: 'driver_87' },
      { label: '闭眼', value: 'driver_91' }
    ]
  }
  // {
  //   label: '胎压监测系统报警',
  //   options: [

  //   ]
  // },
];
