/**
 * 工单统计报告
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button } from 'antd';
import dayjs from 'dayjs';
import { isNull } from 'lodash';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { workOrderStatisticsIndexRequest, workOrderStatisticsChartRequest } from '@/service/workOrder';
import { displayTime } from '@/utils/formatTime';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import StatisticsCard from '@/pages/home/components/statisticsCard';
import styles from '@/styles/pageContent.module.scss';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
const WorkOrderStatistics = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // 数据是否在请求中
  const [searchParams] = useState({
    date: `${dayjs().format('YYYY-MM-01')}`,
  }); // 搜索相关的参数
  const [summaryData, setSummaryData] = useState<any>({}); // 统计数据

  useEffect(() => {
    getData(searchParams);
  }, []);

  // 获取数据
  const getData = async (params: any) => {
    try {
      setLoading(true);
      const indexRes = await workOrderStatisticsIndexRequest(params);
      setSummaryData(indexRes.data?.data || {});
      const chartRes = await workOrderStatisticsChartRequest(params);
      const chartData = chartRes.data?.data || [];
      const xData: Array<string> = [];
      const addCounts: Array<number> = []; // 新建工单数据
      const confirmCounts: Array<number> = []; // 关闭工单数据
      chartData.forEach((item: any, index: number) => {
        xData.push(item.date);
        addCounts.push(item.addCount);
        confirmCounts.push(item.confirmCount);
      });
      const series: any = [
        {
          name: '新建工单',
          type: 'line',
          data: addCounts,
        },
        {
          name: '关闭工单',
          type: 'line',
          data: confirmCounts,
        },
      ];
      const chartConfig = {
        tooltip: { trigger: 'axis' },
        legend: { data: ['新建工单', '关闭工单'] },
        toolbox: {
          feature: { saveAsImage: { title: '保存为图' } }
        },
        xAxis: { type: 'category', data: xData },
        yAxis: { type: 'value' },
        series,
      };
      const orderStatisticsChart: any = echarts.init(document.getElementById('orderStatisticsChart') as HTMLElement);
      orderStatisticsChart.setOption(chartConfig, true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.date = dayjs(values.date).format('YYYY-MM-01');
    getData(newSearchParams)
  };

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="月份"
              name="date"
              initialValue={dayjs(searchParams.date)}
            >
              <DatePicker picker="month" allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <Row gutter={24}>
          <Col span={5}>
            <StatisticsCard
              style={{ border: '1px solid #eee '}}
              title="接单率"
              count={isNull(summaryData?.takeRate) ? '/' : `${summaryData?.takeRate || 0}%`}
            />
          </Col>
          <Col span={5}>
            <StatisticsCard
              style={{ border: '1px solid #eee '}}
              title="接单平均时长"
              count={displayTime(summaryData?.takeAvgTime)?.timeString}
            />
          </Col>
          <Col span={5}>
            <StatisticsCard
              style={{ border: '1px solid #eee '}}
              title="按时完工率"
              count={isNull(summaryData?.punctualityRate) ? '/' : `${summaryData?.punctualityRate || 0}%`}
            />
          </Col>
          <Col span={5}>
            <StatisticsCard
              style={{ border: '1px solid #eee '}}
              title="工单平均处理时长"
              count={displayTime(summaryData?.confirmAvgTime)?.timeString}
            />
          </Col>
          <Col span={4}>
            <StatisticsCard
              style={{ border: '1px solid #eee '}}
              title="重开率"
              count={isNull(summaryData?.reopenRate) ? '/' : `${summaryData?.reopenRate || 0}%`}
            />
          </Col>
        </Row>
        <div id="orderStatisticsChart" style={{ height: '400px', width: '100%', marginTop: '16px' }} />
      </LoadingWrapper>
    </Card>
  )
}

export default WorkOrderStatistics;