import dayjs from "dayjs";
import { message } from "antd";

// 限制选择的天数处理搜索参数
export const limitDayRange = (params: any, dayLimit: number, isExport?: boolean, showLimitPlus?: boolean) => {
  const diffDay = dayjs(params.time[1]).diff(params.time[0], 'day');
  if (diffDay > dayLimit) {
    message.error(`时间范围最大支持${showLimitPlus ? dayLimit + 1 : dayLimit}天！`);
    return;
  }
  const newParams = { ...params };
  delete newParams.time;
  newParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD');
  newParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD');
  if (isExport) {
    newParams.export = true;
  }
  return newParams;
}

// 处理区间时间搜索参数
export const operateDayRange = (params: any, isExport?: boolean) => {
  const newParams = { ...params };
  delete newParams.time;
  newParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD');
  newParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD');
  if (isExport) {
    newParams.export = true;
  }
  return newParams;
}

// 处理区间时间搜索参数(有时分秒的形式)
export const operateTimeRange = (params: any, useTimeParams?: boolean, isExport?: boolean) => {
  const newParams = { ...params };
  if (newParams.time) {
    delete newParams.time;
    if (useTimeParams) {
      newParams.beginTime = dayjs(params.time[0]).format('YYYY-MM-DD HH:mm:ss');
      newParams.endTime = dayjs(params.time[1]).format('YYYY-MM-DD HH:mm:ss');
    } else {
      newParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD HH:mm:ss');
      newParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD HH:mm:ss');
    }
  }
  if (isExport) {
    newParams.export = true;
  }
  return newParams;
}