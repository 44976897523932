/**
 * 多窗口布局
 */
import React, { cloneElement, ReactElement } from 'react';

interface MultipleWindowLayoutProps {
  windowCount: number; // 窗口数量
  children: ReactElement; // 每个窗口渲染的内容
  windowAllData?: any; // 所有窗口对应的数据(用于生成每个窗口的数据)
  closeWindow?: (index: number) => void; // 单独窗口内容关闭的方法
  className?: string; // 外层布局的className
  gap?: number; // 窗口之间间距
  onClick?: Function; // 点击事件
  audio?: boolean;
}
const MultipleWindowLayout = (props: MultipleWindowLayoutProps) => {
  const {
    windowCount,
    children,
    windowAllData,
    closeWindow,
    className,
    gap,
    onClick,
    audio = true
  } = props;
  let repeatCount = Math.ceil(Math.sqrt(windowCount));
  // 8窗口布局特殊设置行列数量
  if (windowCount === 8) {
    repeatCount = 4;
  }
  return (
    <div
      className={className}
      onClick={() => {
        onClick && onClick(windowCount);
      }}
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${repeatCount}, 1fr)`,
        gridTemplateRows: `repeat(${repeatCount}, 1fr)`,
        gridGap: `${gap}px ${gap}px`
      }}
    >
      {new Array(windowCount).fill(1).map((item: number, index: number) => {
        const currentWindowData = windowAllData?.[index] || {}; // 当前窗口对应的数据
        // 6、8窗口布局的第一项特殊样式
        let style = undefined;
        if ([6, 8].includes(windowCount) && index === 0) {
          style = {
            gridArea: `1/1/${repeatCount}/${repeatCount}`
          };
        }
        // 每一个窗口的渲染
        return cloneElement(children, {
          ...currentWindowData,
          style,
          closeWindow: closeWindow ? () => closeWindow(index) : null
        });
      })}
    </div>
  );
};

export default MultipleWindowLayout;
