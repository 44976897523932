/**
 * 车辆报警情况相关请求
 */
import request from "@/utils/request";

export interface CarAlarmParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cateId?: Array<number>;
}

// 获取车辆报警情况
export const carAlarmRequestPath = '/api/v2/car/carAlarmCount';
export const carAlarmRequest = (data: CarAlarmParams) => {
  return request.post(carAlarmRequestPath, data)
}
