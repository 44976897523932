/**
 * 指派/重新指派工单的弹窗
 */
import React, { useState, useEffect } from "react";
import { Modal, Form, message, Input } from "antd";
import { updateContactCarRequest } from '@/service/carManage';


interface AssignModalProps {
  data: any; // 工单信息
  type: string; // 操作类型(指派/重新指派)
  onClose: () => void; // 关闭弹窗的方法
  onOk?: () => void
}

const EditPhoneModal = (props: AssignModalProps) => {
  const { data, onClose, type, onOk } = props;
  const [loading, setLoading] = useState<boolean>(false); // 提交接口是否请求中
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  useEffect(() => {
    const oldValue = {
      carPrincipal: data.car_principal,
      carPrincipalPhone: data.car_principal_phone,
      carDriver: data.car_driver,
      carDriverPhone: data.car_driver_phone,
    }
    form.setFieldsValue(oldValue);
  }, []);

  // 指派工单
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      try {
        const params:any = {};
        params.id = data.id
        params.carPrincipal = values.carPrincipal
        params.carPrincipalPhone = values.carPrincipalPhone
        params.carDriver = values.carDriver
        params.carDriverPhone = values.carDriverPhone
        const res = await updateContactCarRequest(params);
        setLoading(true);
        if (res.status == 200) {
          setSubmitNoRepetition(true)
        }
        setLoading(false);
        if (res.data?.code === 0) {
          message.success('修改成功');
          onClose();
          onOk && onOk()
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <Modal
      title={'编辑联系方式'}
      visible
      centered
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item label="负责人" style={{ marginBottom: 0 }}>
          <Form.Item
            name="carPrincipal"
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <Input placeholder="请输入负责人姓名" />
          </Form.Item>
          <Form.Item
            name="carPrincipalPhone"
            style={{ display: 'inline-block', width: 'calc(60% - 8px)', margin: '0 8px' }}
          >
            <Input placeholder="请输入负责人联系方式" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="司机" style={{ marginBottom: 0 }}>
          <Form.Item
            name="carDriver"
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <Input placeholder="请输入司机姓名" />
          </Form.Item>
          <Form.Item
            name="carDriverPhone"
            style={{ display: 'inline-block', width: 'calc(60% - 8px)', margin: '0 8px' }}
          >
            <Input placeholder="请输入司机联系方式" />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditPhoneModal;