import ultrasonicImg from '@/assets/image/ultrasonic.jpg';
import ultrasonicTaxiImg from '@/assets/image/ultrasonicTaxi.jpg';
import ultrasonicBusImg from '@/assets/image/ultrasonicBus.jpg';
import millimeterWaveImg from '@/assets/image/millimeterWave.jpg';

// 雷达编号说明数据
export const radarNumberData = [
  {
    title: '超声波雷达',
    key: 'ultrasonic',
    selectable: false,
    children: [
      { 
        title: '默认',
        key: 'ultrasonicDefault',
        img: ultrasonicImg,
      },
      { 
        title: '鹏电出租',
        key: 'taxi',
        img: ultrasonicTaxiImg,
      },
      { 
        title: '公交车',
        key: 'bus',
        img: ultrasonicBusImg,
      }
    ]
  },
  {
    title: '毫米波',
    key: 'millimeterWave',
    selectable: false,
    children: [
      { 
        title: '默认',
        key: 'millimeterWaveDefault',
        img: millimeterWaveImg,
      },
    ]
  }
]