/**
 * 分组添加自分类或者编辑
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { upsertGroupRequest } from '@/service/groupManage';
import { operationGroupListRequest, upsertOperationGroupRequest } from '@/service/operationGroupManage';
import { getInnerPermissionEnable } from '@/utils/permission';

interface OperateGroupProps {
  data?: any;
  type?: string;
  closeModal: () => void;
  reloadData: () => void;
}
const OperateGroup = (props: OperateGroupProps) => {
  const [form] = Form.useForm();
  const { data, type, closeModal, reloadData } = props;
  const [operationGroupList, setOperationGroupList] = useState<any>([]); // 运维组列表数据
  const [preOperationGroup, setPreOperationGroup] = useState<any>(null); // 编辑分组时该分组所属的运维组数据
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 是否显示运维组数据
  const showOperationGroup = getInnerPermissionEnable('/car-admin/group-manage/showOperationGroup');

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
    if (showOperationGroup) {
      getOperationGroupList();
    }
  }, [data])

  // 获取运维组列表
  const getOperationGroupList = async() => {
    const res = await operationGroupListRequest();
    const operationGroupData = res.data?.data || [];
    setOperationGroupList(operationGroupData);
    // 编辑的时候显示该分组所属的运维组
    if (type === 'edit') {
      operationGroupData.forEach((item: any) => {
        if (item.cateId.includes(data.id)) {
          form.setFieldsValue({
            operationGroup: item.id,
          });
          setPreOperationGroup({
            id: item.id,
            title: item.title,
            cateId: item.cateId,
          })
        }
      });
    }
  }

  // 操作分组时更新运维组的数据
  const changeOperationGroup = async(selectOperationGroup: any, newCateId: number) => {
    let updateParams = null;
    if (type === 'edit') {
      if (!preOperationGroup && selectOperationGroup) {
        // 第一次选择分组归属的运维组
        operationGroupList.forEach((item: any) => {
          if (item.id === selectOperationGroup) {
            updateParams = [{
              id: item.id,
              title: item.title,
              cateIds: [...item.cateId, data.id]
            }];
          }
        })
      }
      if (preOperationGroup && !selectOperationGroup) {
        // 取消选择分组所属的运维组
        updateParams = [{
          id: preOperationGroup.id,
          title: preOperationGroup.title,
          cateIds: preOperationGroup.cateId.filter((item1: number) => item1 !== data.id)
        }];
      }
      if (preOperationGroup && selectOperationGroup && preOperationGroup.id !== selectOperationGroup) {
        // 修改分组所属的运维组
        updateParams = [
          {
            id: preOperationGroup.id,
            title: preOperationGroup.title,
            cateIds: preOperationGroup.cateId.filter((item1: number) => item1 !== data.id)
          },
        ];
        operationGroupList.forEach((item: any) => {
          if (item.id === selectOperationGroup) {
            updateParams.push({
              id: item.id,
              title: item.title,
              cateIds: [...item.cateId, data.id]
            });
          }
        })
      }
    }
    if (type === 'add' && selectOperationGroup) {
      // 添加分组时选择了所属的运维组
      operationGroupList.forEach((item: any) => {
        if (item.id === selectOperationGroup) {
          updateParams = [{
            id: item.id,
            title: item.title,
            cateIds: [...item.cateId, newCateId]
          }];
        }
      })
    }
    if (updateParams) {
      const allUpsertRequest = updateParams.map((item: any) => upsertOperationGroupRequest(item));
      const upsertRes = await Promise.all(allUpsertRequest);
      if (upsertRes.every((item: any) => item.data?.code === 0)) {
        message.success("该分组对应运维组数据已更新");
      } else {
        message.warning("该分组对应运维组数据更新失败，请手动修改运维组数据");
      }
    }
  }

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const isEdit = type === 'edit';
      const params: any = { title: values.title, parentId: data.id };
      if (isEdit) {
        params.id = data.id;
        params.parentId = data.parentId;
      }
      const res = await upsertGroupRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(isEdit ? '修改成功' : '添加成功');
        // 显示运维组选项时才处理运维组的相关逻辑
        if (showOperationGroup) {
          changeOperationGroup(values.operationGroup, res.data?.data);
        }
        closeModal();
        reloadData();
      }
    }
  }
  
  return (
    <Modal
      title={type === 'add' ? '添加分组' : '编辑分组'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
      >
        <Form.Item
          label="分组名称"
          name="title"
          rules={[{ required: true, message: '请输入分组名称' }]}
        >
          <Input placeholder='请输入分组名称' />
        </Form.Item>
        {showOperationGroup && <Form.Item
          label="所属运维组"
          name="operationGroup"
        >
          <Select
            placeholder='请选择所属运维组'
            options={operationGroupList}
            allowClear
            fieldNames={{
              label: 'title',
              value: 'id',
            }}
          />
        </Form.Item>}
      </Form>
    </Modal>
  )
}

export default OperateGroup;
     