/**
 * 充值详情界面
 */
import React, { useEffect, useState } from "react";
import { Modal, Form, message } from 'antd';
import QRCode from 'qrcode.react';
import { videoInvestQueryexportRequest } from "@/service/monitoringClientRecord";
interface WorkOrderDetailProps {
    item?: any; // 数据 
    onClose: () => void; // 关闭工单详情弹层的方法
    onOpenPay?: any
    orderNumber?: any //订单号
}
const PaymentDetail = (props: WorkOrderDetailProps) => {
    const [form] = Form.useForm();
    const { item, onClose, onOpenPay, orderNumber } = props;
    const [isModalOpen, setIsModalOpen] = useState(true); // 充值详情弹框
    const [aaa, setAaa] = useState<any>(); // 支付方式 

    useEffect(() => {
        setAaa(localStorage.getItem("contactDetail"))
        getOrderDetail()
    }, []);

    // 轮询结果
    const getOrderDetail = () => {
        let data = {
            payment: Number(localStorage?.getItem("zhifubaoPayment")),
            orderNo: localStorage?.getItem("zhifubaoOrderNo")
        };

        let startTime = Date.now(); // 记录开始时间
        console.log('startTime',startTime);
        let intervalId = setInterval(() => { // 设置定时器
            // 检查是否已经过去60秒
            if (Date.now() - startTime >= 60000) {
                clearInterval(intervalId); // 如果超过60秒，清除定时器
                setIsModalOpen(false)
                onClose();
                console.log('轮询结束');
                // 提示未支付
                message.error('未支付');
                return;
            }
            videoInvestQueryexportRequest(data).then(res => {
                console.log(res, 'res');
                if (res.data.data === '未支付') {
                    console.log('未支付');
                } else {
                    clearInterval(intervalId); // 支付成功，清除定时器
                    //支付成功的提示
                    message.success('支付成功');
                    setIsModalOpen(false)
                }
            })
        }, 1000) // 设置轮询间隔为1秒
    }

    return (
        <>
            <Modal
                title="扫一扫付款(元)"
                open={isModalOpen}
                onOk={() => form.submit()}
                onCancel={() => {
                    setIsModalOpen(false)
                    onOpenPay(true);
                }}
                footer={null} >
                <div style={{ fontSize: '26px', color: '#ff6600',fontWeight:'bold' ,marginBottom:'10px'}}>
                ¥ {item}
                </div>
                <div style={{ display: 'flex',alignItems:' center',justifyContent:'center' }}>
                   <div>
                   <QRCode
                        value={aaa}  //value参数为生成二维码的链接
                        size={150} //二维码的宽高尺寸
                        fgColor="#000000"  //二维码的颜色
                      
                    />
                   </div>
                    <div style={{ fontSize: '14px', color: '#d1d1d1' ,marginLeft:'20px'}}>
                        打开手机支付宝
                        <br />
                        扫一扫继续付款
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default PaymentDetail;
