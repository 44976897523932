/**
 * 车速获取异常相关请求
 */
import request from "@/utils/request";

export interface SpeedAbnormalParams {
  beginDate: string;
  endDate: string;
  page: number,
  limit: number;
  cate_id?: number | Array<number>;
  speed_diff?: number;
}

// 车速获取异常
export const speedAbnormalRequestPath = '/api/v2/car/vehicleSpeedAnomaly';
export const speedAbnormalRequest = (data: SpeedAbnormalParams) => {
  return request.post(speedAbnormalRequestPath, data)
}