import {useEffect, useState} from "react";
import {carTypeListRequest} from "@/service/offerRecord";
import {fullTypeGen, rootNodeFilter} from "@/pages/offerRecord/utils/utils";

const useVehicleType = () => {
  const [allVehicleType, setAllVehicleType] = useState<any>([]); // 车辆类型

  //获取车辆类型（不是车型，车型是前端写死的，直接读取）
  const getVehicleType = async () => {
    const res = await carTypeListRequest();
    const resData = res.data?.data || [];

    return resData
      .filter(rootNodeFilter)//筛选出根节点（就是parentCode为空的节点）  下同
      .map(fullTypeGen(resData))//生成嵌套的树  下同
  }

  useEffect(() => {

    //获取车辆类型
    getVehicleType()
      .then(res => setAllVehicleType(res))

  }, []);

  return{
    allVehicleType,
  }
}

export default useVehicleType;