/**
 * 车辆档案相关请求
 */
import request from "@/utils/request";

export interface CarArchiveParams {
  carId: number,
  querySort?: Array<number>;
  queryTagId?: Array<number>;
}

// 获取车辆档案
export const carArchiveRequestPath = '/api/v2/carArchives/queryCarArchivesNew';
export const carArchiveRequest = (params: CarArchiveParams) => {
  return request.get(carArchiveRequestPath, {
    params
  })
}

// 获取车辆档案所有标签
export const carArchiveTagsRequest = (params: CarArchiveParams) => {
  return request.get('/api/v2/carArchives/queryAllTagByCarId', {
    params
  })
}
