/**
 * 我的车辆相关请求
 */
import request from "@/utils/request";

// 收藏车辆
export const favoriteUpsertCarRequest = (data: any) => {
  return request.post('/api/v2/favorites/upsert', data)
}

// 收藏车辆列表
export const favoriteCarListRequest = (params?: any) => {
  return request.get('/api/v2/favorites/list', {
    params
  })
}

// 取消车辆收藏收藏
export const deleteFavoriteCarRequest = (id : number) => {
  return request.delete(`/api/v2/favorites/${id}`)
}

// 收藏车辆分组的操作
export const favoriteGroupUpsertRequest = (data: any) => {
  return request.post('/api/v2/favorites/group/upsert', data)
}

// 收藏车辆分组列表
export const favoriteGroupListRequest = (params?: any) => {
  return request.get('/api/v2/favorites/group/list', {
    params
  })
}

// 删除收藏车辆分组
export const deleteFavoriteGroupRequest = (id : number) => {
  return request.delete(`/api/v2/favorites/group/${id}`)
}
