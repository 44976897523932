/**
 * 定位状态表格
 */
import React, { useMemo } from 'react';
import CommonTable from '@/components/commonTable';
import { positionStatusColumns } from '@/columns/positionStatus';

interface LocationStatusTableProps {
  dataSource: any;
}
const LocationStatusTable = (props: LocationStatusTableProps) => {
  const { dataSource } = props;

  // 表格列配置(只显示GPS数据类型相关的列且不显示数据类型列)
  const columns = useMemo(() => {
    const columns = positionStatusColumns.slice(0, 5);
    columns.splice(2, 1);
    return columns;
  }, []);

  return (
    <CommonTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      size="small"
      columnFilter={false}
    />
  );
};

export default LocationStatusTable;
