/**
 * 车辆工时统计相关请求
 */
import request from "@/utils/request";

export interface CarTimeParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cate_id?: number | Array<number>;
}

// 获取车辆工时数据
export const carTimeRequestPath = '/api/v2/car/runtime';
export const carTimeRequest = (data: CarTimeParams) => {
  return request.post(carTimeRequestPath, data)
}