/**
 * 编辑车辆诊断运维备注
 */
import React, {useState, useEffect} from "react";
import {Modal, Form, message, Input, Checkbox, Radio, Select} from "antd";
import {upsetOperateContentRequests, updateVehicleCoordinateAbilityRequests} from "@/service/carManage";

interface AssignModalProps {
  data: any; // 工单信息
  type: string; // 操作类型(指派/重新指派)
  onClose: () => void; // 关闭弹窗的方法
  onOk: () => void; // 确认弹窗后触发的方法
}

const options = [
  {label: '配合', value: '配合'},
  {label: '一般配合', value: '一般配合'},
  {label: '完全不配合', value: '完全不配合'}
]

const EditServiceState = (props: AssignModalProps) => {
  const {data, onClose, onOk, type} = props;
  const [loading, setLoading] = useState<boolean>(false); // 提交接口是否请求中
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  useEffect(() => {
    const oldValue = {
      content: data.operateRemark,
      coordinateAbility: data.coordinateAbility
    };
    form.setFieldsValue(oldValue);
  }, []);

  // 编辑请求
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false);
      try {
        const params: any = {
          // state: values.state,
          content: values.content,
          carId: data.carId,
          id: data.carId,
          coordinateAbility: values.coordinateAbility
        };
        const url = type === 'coordinateAbility' ? updateVehicleCoordinateAbilityRequests : upsetOperateContentRequests
        const res = await url(params);
        setLoading(true);
        if (res.status == 200) {
          setSubmitNoRepetition(true);
        }
        setLoading(false);
        if (res.data?.code === 0) {
          message.success("修改成功");
          onClose();
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };


  return (
    <Modal title={"服务备注"} visible centered
           onCancel={() => {
             onClose()
             form.resetFields()
           }}
           onOk={
             () => {
               form.submit()
               onOk()
             }
           }
           okButtonProps={{loading}} maskClosable={false} width={580}>
      <Form form={form} autoComplete="off" layout="vertical" scrollToFirstError onFinish={submit}>
        {type === 'editServiceState' && <Form.Item label="" name="content">
          <Input.TextArea placeholder="请输入服务备注" allowClear/>
        </Form.Item>}
        {type === 'coordinateAbility' && <Form.Item label="客户配合度" name="coordinateAbility">
          <Select placeholder="请选择用户配合度" allowClear options={options}>
            
          </Select>
        </Form.Item>}
      </Form>
    </Modal>
  );
};

export default EditServiceState;
