/**
 * 有效报警统计相关请求
 */
import request from "@/utils/request";

export interface EffectiveAlarmParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cate_id?: number | Array<number>;
}

// 获取有效报警数据
export const effectiveAlarmRequestPath = '/api/v2/car/validAlarm';
export const effectiveAlarmRequest = (data: EffectiveAlarmParams) => {
  return request.post(effectiveAlarmRequestPath, data)
}