/**
 * 查看角色关联的权限
 */
import React, { useEffect, useState } from 'react';
import { Drawer, Tree, Empty } from 'antd';
import { operateMenuData } from '@/utils/menuManage';
import { getRoleRelateMenuRequest } from '@/service/roleManage';
import LoadingWrapper from '@/components/loadingWrapper';

interface PermissionModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const PermissionModal = (props: PermissionModalProps) => {
  const { data, closeModal } = props;
  const [rolePermission, setRolePermission] = useState([]); // 角色相关联的权限数据
  const [loading, setLoading] = useState(false); // 数据是否在请求中
  const [isLoaded, setIsLoaded] = useState(false); // 数据是否请求完

  // 获取角色关联的菜单权限
  const getRoleRelateMenu = async() => {
    try {
      setLoading(true);
      const res = await getRoleRelateMenuRequest(data.id);
      setRolePermission(operateMenuData(res.data?.data || []));
      setLoading(false);
      setIsLoaded(true);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRoleRelateMenu();
  }, []);

  return (
    <Drawer
      title={`${data.name}角色相关权限`}
      visible
      onClose={closeModal}
      size="large"
    >
      <LoadingWrapper spinning={loading} style={{ height: '100%' }} autoHeight>
        {rolePermission?.length ?
          <Tree
            treeData={rolePermission}
          /> :
          (isLoaded && <Empty description="暂无权限配置" />)
        }
      </LoadingWrapper>
    </Drawer>
  )
}

export default PermissionModal;
     