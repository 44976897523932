/**
 * 监测车留档编辑
 */
import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { upsertServiceTask } from '@/service/serviceTask';
import { tagListRequest } from '@/service/tagManage';
import { TagCategoryEnum } from '@/constants/tagManage';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}

const OperateModalTask = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [vehicleState, setVehicleState] = useState<any>([]); // 车辆状态
  const [vehicleSource, setVehicleSource] = useState<any>([]); // 监测类型
  const [showState, setShowState] = useState(false); // 车辆状态的显示

  // 编辑的时候赋值
  useEffect(() => {
    form.setFieldsValue({type: '未处理'})
    getVehicleState()
    getVehicleSource()
    if (type == 'edit') {
      if (data.taskId) {
        setShowState(true)
        if (data.taskState == 6 || data.taskState == 7 || data.taskState == 8 || data.taskState == 10) {
          setShowState(false)
        }
      }
      form.setFieldsValue(data)
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertServiceTask(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      } 
    }
  }

  // 获取车辆状态
  const getVehicleState = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.problemVehicleState],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setVehicleState(tags);
  }

  // 获取监测类型
  const getVehicleSource = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.monitorCarType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setVehicleSource(tags);
  }

  return (
    <Modal
      title={type === 'add' ? '添加监测车' : '编辑监测车'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item
          label="车架号"
          name="frameNumber"
          rules={[
            { required: true, message: '请输入车架号' }
          ]}
        >
          <Input placeholder="请输入车架号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
        </Form.Item>
        <Form.Item 
          label="车辆状态" 
          name="state" 
          rules={[{ required:true, message: '请选择车辆状态' }]}
        >
          <Select placeholder="请选择车辆状态" options={vehicleState} optionFilterProp="label" disabled={showState} />
        </Form.Item>
        <Form.Item
          label="车辆情况说明"
          name="suggest"
          rules={[{ required:true, message: '请输入车辆情况说明' }]}
        >
          <Input placeholder="请输入车辆情况说明" />
        </Form.Item>
        <Form.Item 
          label="监测类型" 
          name="monitorType" 
          rules={[{ required:true, message: '请选择监测类型' }]}
        >
          <Select placeholder="请选择监测类型" options={vehicleSource} optionFilterProp="label"  />
        </Form.Item>
        <Form.Item
          label="监测天数"
          name="occurrenceDays"
        >
          <Input placeholder="请输入监测天数" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModalTask;