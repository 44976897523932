/**
 * 有快捷选项的DatePicker
 */
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';

interface QuickSelectDatePickerProps {
  value?: any; // 选择的数据
  onChange?: (value: any) => void; // 选择时调用的方法
  style?: any; // 样式
}

const QuickDatePicker = (props: QuickSelectDatePickerProps) => {
  const { value, onChange, style } = props;
  const [open, setOpen] = useState<boolean>(false); // 弹层是否展开
  const [selectValue, setSelectValue] = useState<any>(value); // 选择的日期

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 弹出日历和关闭日历的回调
  const onOpenChange = (value: boolean) => {
    setOpen(value);
  }

  // 快捷选择
  const quickSelect = (type: string) => {
    const dateObj: any = {
      'currentMonth': dayjs().endOf('month'),
      'nextMonth': dayjs().add(1, 'month').endOf('month'),
    }
    onDateChange(dateObj[type]);
    setOpen(false);
  }

  // 日期选择
  const onDateChange = (date: any) => {
    setSelectValue(date);
    onChange && onChange(date);
  }

  return (
    <DatePicker
      open={open}
      onOpenChange={onOpenChange}
      showToday={false}
      style={style}
      value={selectValue}
      onChange={onDateChange}
    />
  )
}

export default QuickDatePicker;
  