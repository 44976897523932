/**
 * 操作客户人脸(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import UploadImageVideo from "@/components/uploadImageVideo";
import { upsertFaceRequest } from '@/service/clientFace';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      params.resource = uploadSuccessList[0].key
      const res = await upsertFaceRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      } 
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加人脸' : '编辑人脸'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item
          label="姓名"
          name="name"
          rules={[{ required: true, message: '请输入姓名' }]}
        >
          <Input placeholder='请输入人名' />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item
              label="分类选择"
              name="cateId"
              rules={[{ required: true, message: '请选择分类' }]}
            >
              <CategoryTreeSelect treeCheckable={form.getFieldsValue()?.multigroup} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label="图片上传">
          <UploadImageVideo
            businessName="人脸"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            defaultUploadFiles={type === 'add' ? [] : [{ contentType: "image/jpeg", key: new URL(data.imageUrl).pathname.slice(1), preview: data.imageUrl }]}
            multiple
          />
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default OperateModal;
    