/**
 * 808虚拟设备相关请求
 */
import request from '@/utils/request';

export interface VirtualDeviceListRequestParams {
  page: number;
  limit: number;
}

export const virtualDeviceListRequestPath = '/api/v2/jt808Virtual/list';
// 获取虚拟设备列表数据
export const virtualDeviceListRequest = (
  params: VirtualDeviceListRequestParams
) => {
  return request.get(virtualDeviceListRequestPath, {
    params
  });
};

// 添加、编辑虚拟设备
export const upsertVirtualDeviceRequest = (data: any) => {
  return request.post('/api/v2/jt808Virtual/upsert', data);
};

// 删除虚拟设备
export const virtualDeviceDeleteRequest = (id: number) => {
  return request.delete(`/api/v2/jt808Virtual/${id}`);
};
