/**
 * ftp服务器资源列表
 */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Button } from "antd";
import CommonTable from "@/components/commonTable";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import PermissionButton from "@/components/permissionButton";
import { ftpResourceRequest } from "@/service/jtDevicePlayback";
import { formatTime } from "@/utils/formatTime";
import { deviceOperateFtpResource, operateFtpResource } from "@/utils/jtDevice";
import { downloadFile, downloadUrl } from "@/utils/download";
import styles from "@/styles/pageContent.module.scss";
import { jtDeviceDetailRequest } from "@/service/jtDevice";
const Mux = require('mux.js')

export function prepareSourceBuffer (combined, outputType, bytes, id) {
  setTimeout(() => {
    var buffer;
    const video = document.getElementById(
      id || 'playbackVideo'
    ) as HTMLMediaElement;
    video.controls = true;
    // MediaSource Web API: https://developer.mozilla.org/zh-CN/docs/Web/API/MediaSource
    var mediaSource = new MediaSource(); 
    video.src = URL.createObjectURL(mediaSource);

    // 转换后mp4的音频格式 视频格式
    var codecsArray = ["avc1.64001f", "mp4a.40.5"];

    mediaSource.addEventListener('sourceopen', function () {
      const logevent = (data) => {console.log(data)}
      // MediaSource 实例默认的duration属性为NaN
      mediaSource.duration = 0;
      // 转换为带音频、视频的mp4
      if (combined) {
        buffer = mediaSource.addSourceBuffer('video/mp4;codecs="' + 'avc1.64001f,mp4a.40.5' + '"');
      } else if (outputType === 'video') {
        // 转换为只含视频的mp4
        buffer = mediaSource.addSourceBuffer('video/mp4;codecs="' + codecsArray[0] + '"');
      } else if (outputType === 'audio') {
        // 转换为只含音频的mp4
        buffer = mediaSource.addSourceBuffer('audio/mp4;codecs="' + (codecsArray[1] ||codecsArray[0]) + '"');
      }

      buffer.addEventListener('updatestart', logevent);
      buffer.addEventListener('updateend', logevent);
      buffer.addEventListener('error', logevent);
      video.addEventListener('error', logevent);
      // mp4 buffer 准备完毕，传入转换后的数据
      // 将 bytes 放入 MediaSource 创建的sourceBuffer中
      // https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/appendBuffer
      buffer.appendBuffer(bytes);
      // 自动播放
      video.play();
    });
  }, 100)
};

export function  streamTransfer (stream, id) {
  var segment = new Uint8Array(stream); 
      var combined = false;
      var outputType = 'video';
      var remuxedSegments = [];
      var remuxedBytesLength = 0;
      var remuxedInitSegment = null;
      var transmuxer = new Mux.mp4.Transmuxer({remux: false});
      transmuxer.on('data', function(event: { type: string; data: { byteLength: number; }; initSegment: any; }) {
        console.log(event);
        if (event.type === outputType) {
          remuxedSegments.push(event);
          remuxedBytesLength += event.data.byteLength;
          remuxedInitSegment = event.initSegment;
        }
      });
      transmuxer.on('done', function () {
        var offset = 0;
        var bytes = new Uint8Array(remuxedInitSegment.byteLength + remuxedBytesLength)
        bytes.set(remuxedInitSegment, offset);
        offset += remuxedInitSegment.byteLength;

        for (var j = 0, i = offset; j < remuxedSegments.length; j++) {
          bytes.set(remuxedSegments[j].data, i);
          i += remuxedSegments[j].byteLength;
        }
        remuxedSegments = [];
        remuxedBytesLength = 0;
        // 解析出转换后的mp4相关信息，与最终转换结果无关
        var vjsParsed = Mux.mp4.tools.inspect(bytes);
        console.log('transmuxed', vjsParsed);

        prepareSourceBuffer(combined, outputType, bytes, id);
      });
      // push方法可能会触发'data'事件，因此要在事件注册完成后调用
      transmuxer.push(segment); // 传入源二进制数据，分割为m2ts包，依次调用上图中的流程
      // flush的调用会直接触发'done'事件，因此要事件注册完成后调用
      transmuxer.flush(); // 将所有数据从缓存区清出来
}


interface FtpResourceTableProps {
  plateNumber: string; // 车牌号
  downloadPermissionKey?: string; // 下载按钮的权限key
  reportedTime?: string; // 上报时间
  playFtpVideo?: (url: string) => void; // 播放视频的处理逻辑
  jt808DeviceId?: any; // 设备id
}
const FtpResourceTable = (props: FtpResourceTableProps, ref: any) => {
  const {
    plateNumber,
    downloadPermissionKey = "",
    reportedTime,
    playFtpVideo,
    jt808DeviceId,
  } = props;
  const [resourceList, setResourceList] = useState([]); // ftp服务器资源列表
  const [loading, setLoading] = useState<boolean>(false); // 请求ftp服务器资源列表的loading
  const [ftpPlayLoading, setFtpPlayLoading] = useState<any>({}); // 播放按钮loading控制
  const [ftpDownloadLoading, setFtpDownloadLoading] = useState<any>({}); // 下载按钮loading控制

  // 获取ftp服务器资源列表
  const getFtpResource = async () => {
    try {
      setLoading(true);
      const params: any = { plateNumber };
      if (reportedTime) {
        params.reportedTime = reportedTime;
      }
      const res = await ftpResourceRequest(params);
      setResourceList(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getFtpResource();
    }, 100);
  }, []);

  // reload是暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    reload: () => {
      getFtpResource();
    },
  }));


  // 播放
  const onPlay = async (data: any) => {
    // if (data.manufacturer == '4') {
    //   fetch(data.url)
    //   .then((res) => {
    //     if (!res.body) {
    //       throw new Error("没有视频");
    //     }
    //     // return res.body;
    //     return res.arrayBuffer()
    //   })
    //   .then((stream) => {
    //     console.log(stream, 'streamstreamstream')
    //     playFtpVideo && playFtpVideo(data.url);
    //     streamTransfer(stream)
    //   });
    // } else {
    //   playFtpVideo && playFtpVideo(data.url);
    // }
    playFtpVideo && playFtpVideo(data.url);
    // try {
    //   const jtDeviceRes = await jtDeviceDetailRequest(jt808DeviceId);
    //   setFtpPlayLoading({ ...ftpPlayLoading, [data.id]: true });
    //   if (jtDeviceRes.data?.data?.filePath) {
    //     const params = { ...data };
    //     params.jtDeviceRes = jtDeviceRes.data?.data?.filePath;
    //     const url = await deviceOperateFtpResource(params);
    //     playFtpVideo && playFtpVideo(url);
    //   } else {
    //     const url = await operateFtpResource(data);
    //     playFtpVideo && playFtpVideo(url);
    //   }
    //   setFtpPlayLoading({ ...ftpPlayLoading, [data.id]: false });
    // } catch (error) {
    //   setFtpPlayLoading({ ...ftpPlayLoading, [data.id]: false });
    // }
  };

  // 下载
  const onDownload = async (data: any) => {
    const { id, beginTime, endTime } = data;
    // try {
    //   const jtDeviceRes = await jtDeviceDetailRequest(jt808DeviceId);
    //   setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: true });
    //   if (jtDeviceRes.data?.data?.filePath) {
    //     const params = { ...data };
    //     params.jtDeviceRes = jtDeviceRes.data?.data?.filePath;
    //     const url = await deviceOperateFtpResource(params);
    //     downloadUrl(url, `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`);
    //   } else {
    //     const url = await operateFtpResource(data);
    //     downloadUrl(url, `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`);
    //   }
    //   setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    // } catch (error) {
    //   setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    // }
    // downloadUrl(data.url, `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`);
    setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: true });
    try {
      await downloadFile(
        data.url,
        `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`,
        false
      );
      setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    } catch (e) {
      setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    }
  };

  // 资源表格列显示
  const resourceColumns = [
    {
      title: "开始时间",
      dataIndex: "beginTime",
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      ),
    },
    {
      title: "结束时间",
      dataIndex: "endTime",
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      ),
    },
    {
      title: "上传时间",
      dataIndex: "createdAt",
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      ),
    },
    {
      title: "通道号",
      dataIndex: "channelNo",
      width: 100,
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 150,
      fixed: "right" as const,
      render: (value: any, item: any) => (
        <div className={styles.tableOperateColumn}>
          {item.url && (
            <>
              <Button
                type="link"
                onClick={() => onPlay(item)}
                loading={Boolean(ftpPlayLoading[item.id])}
              >
                播放
              </Button>
              <PermissionButton
                type="link"
                operateType={downloadPermissionKey}
                onClick={() => onDownload(item)}
                loading={Boolean(ftpDownloadLoading[item.id])}
              >
                下载
              </PermissionButton>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <CommonTable
      dataSource={resourceList}
      columns={resourceColumns}
      columnFilter={false}
      size="small"
      scroll={{ y: 200 }}
      loading={loading}
      pagination={false}
      bordered
    />
  );
};

export default forwardRef(FtpResourceTable);
