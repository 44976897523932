/**
 * echarts图表的容器
 */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as echarts from 'echarts/core';
import { useThrottle } from '@/utils/ownHooks';

interface EchartsWrapperProps {
  style?: any;
  className?: string;
  chartOption: any;
  chartClick?: (params: any) => void;
  useTabKey?: string; // 当前图表使用的页面key
}
const EchartsWrapper = (props: EchartsWrapperProps) => {
  const { style, className, chartOption, chartClick, useTabKey } = props;
  const { activeKey } = useSelector((state: any) => state.tabPagesStore);
  const echartsWrapperRef = useRef(null);
  const echartsRef = useRef<any>(null);
  const activeKeyRef = useRef<string>(activeKey); // 存储activeKey防止在chartResize方法中获取不到新的activeKey

  // 图表自适应
  const chartResize = () => {
    if (!useTabKey || useTabKey === activeKeyRef.current) {
      // 只调整当前激活tab的图表或者没有传useTabKey的图表
      echartsRef.current?.resize();
    }
  };

  // 节流的图表自适应
  const throttleChartResize = useThrottle(chartResize);

  // 监听图表配置初始化图表
  useEffect(() => {
    if (chartOption) {
      // 没有初始化图表实例时初始化图表实例
      if (!echartsRef.current) {
        echartsRef.current = echarts.init(
          echartsWrapperRef.current as unknown as HTMLElement
        );
        if (chartClick) {
          echartsRef.current.on('click', (params: any) => {
            chartClick(params);
          });
        }
      }
      // 设置图表的配置项
      echartsRef.current.setOption(chartOption, true);
    }
  }, [chartOption]);

  // 注册resize事件，组件销毁时将图表实例销毁
  useEffect(() => {
    window.addEventListener('resize', throttleChartResize);
    return () => {
      echartsRef.current?.dispose();
      window.removeEventListener('resize', throttleChartResize);
    };
  }, []);

  // 监听tab激活时调用resize方法显示图表(tab隐藏时调用resize事件图表无法显示)
  useEffect(() => {
    activeKeyRef.current = activeKey;
    if (useTabKey && useTabKey === activeKey) {
      setTimeout(() => {
        chartResize();
      }, 200);
    }
  }, [activeKey]);

  return <div ref={echartsWrapperRef} className={className} style={style} />;
};

export default EchartsWrapper;
