// 标签类别的枚举值
export enum TagCategoryEnum {
  distribute = 2, // 派单
  maintenance = 3, // 维保 
  accident = 21, // 出险记录的事故标签
  accidentType = 5, // 出险记录的事故类型
  accidentCollisionLocation = 6, // 出险记录的碰撞方位
  accidentClassification = 7, // 出险记录的事故分类
  videoWeather = 9, // 视频天气
  videoRoad = 10, // 视频路况
  videoCarBehavior = 11, // 视频自车行为
  videoTargetType = 12, // 视频目标种类
  videoTargetPosition = 13, // 视频目标位置
  videoTargetBehavior = 14, // 视频目标行为
  videoLight = 15, // 视频逆光
  videoTimely = 16, // 视频及时性
  videoResult = 17, // 视频预警结果
  videoEffect = 18, // 视频预警效果
  videoRate = 19,  // 视频预警评分
  videoTime = 20, // 视频时间
  organization = 25, // 承保机构
  serviceProblemType = 31, // 客服问题类型
  maintainType = 33, // 问题原因
  sceneProblemType = 29, // 现场问题原因
  maintainDetailItem = 32, // 维护明细类型
  malfunctionItem = 34, // 故障项
  carOffLineCause = 35, // 车辆离线原因
  carServeState = 36, // 运维状态
  interviewType = 37, // 走访类别
  interviewReason = 38, // 走访原因
  interviewCoordinate = 39, // 走访配合度
  carRelationResult = 40, // 车辆联系结果
  serveType = 41, // 服务类型
  businessUnit = 42, // 业务渠道
  businessState = 43, // 业务状态
  problemVehicleState = 45, // 数据管理-问题表-车辆状态
  problemVehicleSource = 46, // 数据管理-问题表-车辆来源
  offVehicleSource = 48, // 数据管理-离线车-车辆来源
  monitorCarType = 49, // 数据管理-监测车-监测类型
  visitCarType = 50, // 数据管理-回访车-回访类型
  documentData  = 52, //知识库类型
  carTypeData  = 53, //车辆类型
  fuelType  = 54, //燃料类型
  lawsuitState  = 55, //诉讼阶段
  commercialCompany  = 58, //承保保司
  serviceState = 59, //服务状态
  mainBodyChange = 60, //主体变更
  mode = 61, //承保模式
  managerAdaptability = 62, //负责人配合度
  otherAdaptability = 63, //涉及人员配合度
  carPredictWorkTime = 64, // 预计复工时间
  closeFeeState = 66, // 结算状态
  carEvaluation = 67 //运维评级
}

