/**
 * 车辆运维评级记录
 */
import React, { useState } from "react";
import { Card, Form, Row, Col, Button, Input, message, Popconfirm } from "antd";
import dayjs from "dayjs";
import CommonTable from "@/components/commonTable";
import { useTable } from "@/utils/useTable";
import styles from "@/styles/pageContent.module.scss";
import DatePicker from "@/components/datePicker";
import {
  carEvaluationRequest,
  DeleteInsurer,
} from "@/service/carEvaluationRecord";
import PermissionButton from "@/components/permissionButton";
import OperateModal from "./components/operateModal";
import { DeleteOutlined } from "@ant-design/icons";

const carEvaluationRecords = () => {
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [form] = Form.useForm();

  const [searchParams, setSearchParams] = useState({
    startTime: `${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`,
    endTime: `${dayjs().format("YYYY-MM-DD")}`,
  }); // 搜索相关的参数
  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: carEvaluationRequest,
      initRequest: true,
      initPageSize: 40,
      searchParams,
    });
  const { RangePicker } = DatePicker;

  // 处理搜索参数
  const operateSearchParams = (params: any) => {
    const newParams = { ...params };
    newParams.startTime = dayjs(params.day[0]).format("YYYY-MM-DD");
    newParams.endTime = dayjs(params.day[1]).format("YYYY-MM-DD");
    delete newParams.day;
    setSearchParams(newParams);
    return newParams;
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  const deleteRecord = async (id: number) => {
    const res = await DeleteInsurer(id);
    if (res.data?.code === 0) {
      message.success("删除成功");
      getData();
    }
  };

  // 表格列显示
  const columns = [
    {
      title: "车架号",
      dataIndex: "frameNumber",
      width: 110,
    },
    {
      title: "评级",
      dataIndex: "grade",
      width: 110,
    },
    {
      title: "评级时间",
      dataIndex: "createdAt",
      width: 130,
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD") : value,
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 160,
      fixed: "right" as "right",
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/car-evaluation/edit"
              type="link"
              onClick={() => setOperateInfo({ type: "edit", data: item })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除此条评级吗?"
              onConfirm={() => deleteRecord(item.id)}
            >
              <PermissionButton  operateType="/car-admin/car-evaluation/delete" type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form
        form={form}
        onFinish={onSearch}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder="请输入车架号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="时间范围"
              name="day"
              initialValue={[
                dayjs(searchParams.startTime),
                dayjs(searchParams.endTime),
              ]}
            >
              <RangePicker allowClear={false} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/car-evaluation/add"
          type="primary"
          onClick={() => setOperateInfo({ type: "add" })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑记录 */}
      {["add", "edit"].includes(operateInfo.type) && (
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      )}
    </Card>
  );
};

export default carEvaluationRecords;
