/**
 * 电子围栏相关常量
 */
// 航向角默认浮动区间
export const defaultAngleRange = 10;
// 电子围栏默认最小半径
export const defaultMinRadius = 10;
// 电子围栏默认最大半径
export const defaultMaxRadius = 100;
// 路口类型
export const intersectionTypeObj: any = {
  1: '小路口',
  2: '大路口'
};
export const intersectionType = [
  { label: '小路口', value: 1 },
  { label: '大路口', value: 2 }
];

// 速度限制
export const speedLimit = [
  { label: '无限制', value: 0 },
  { label: '限10KM/H', value: 1 },
  { label: '限20KM/H', value: 2 },
  { label: '限30KM/H', value: 3 },
  { label: '限40KM/H', value: 4 },
  { label: '限50KM/H', value: 5 },
  { label: '限60KM/H', value: 6 },
  { label: '限70KM/H', value: 7 },
  { label: '限80KM/H', value: 8 },
  { label: '限90KM/H', value: 9 }
];

// 转向角限制
export const angleLimit = [
  { label: '无限制', value: 0 },
  { label: '限30°', value: 1 },
  { label: '限60°', value: 2 },
  { label: '限90°', value: 3 },
  { label: '限120°', value: 4 },
  { label: '限150°', value: 5 },
  { label: '限180°', value: 6 },
  { label: '限210°', value: 7 },
  { label: '限240°', value: 8 },
  { label: '限270°', value: 9 },
  { label: '限300°', value: 10 },
  { label: '限330°', value: 11 },
  { label: '限360°', value: 12 },
  { label: '限390°', value: 13 },
  { label: '限420°', value: 14 },
  { label: '限450°', value: 15 },
  { label: '限480°', value: 16 },
  { label: '限510°', value: 17 },
  { label: '限540°', value: 18 }
];
