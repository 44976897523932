/**
 * 菜单权限相关请求
 */
import request from "@/utils/request";

// 获取菜单权限树
export const menuTreeRequest = (params: any) => {
  return request.get('/api/uaa/menu/tree', {
    params
  })
}

// 获取菜单权限列表
export const menuListRequest = () => {
  return request.get('/api/uaa/menu/list')
}

// 添加/编辑菜单权限
export const upsertMenuRequest = (data: any) => {
  return request.post('/api/uaa/menu/upsert', data)
}

// 获取菜单详情
export const menuDetailRequest = (id: number) => {
  return request.get(`/api/uaa/menu/detail/${id}`)
}

// 删除菜单权限
export const deleteMenuRequest = (id: number) => {
  return request.delete(`/api/uaa/menu/${id}`)
}
