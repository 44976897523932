/**
 * 概况(统计卡片)
 */
import React from 'react';
import { Tag } from 'antd';
import styleConfig from '@/styles/common.scss';
import styles from './index.module.scss';

interface StatisticsCardProps {
  title: string;
  tag?: string;
  count: number | string;
  clickFunc?: Function;
  style?: any;
  percent?: string;
  bordered?: boolean;
}
const StatisticsCard = (props: StatisticsCardProps) => {
  const {
    title,
    tag,
    count,
    clickFunc,
    style = {},
    percent,
    bordered = false
  } = props;
  const { primaryColor, backColor } = styleConfig || {};

  // 处理卡片容器的样式
  const wrapperStyle = { ...style };
  if (bordered) {
    wrapperStyle.border = `1px solid ${backColor}`;
  }
  if (clickFunc) {
    wrapperStyle.cursor = 'pointer';
  }
  return (
    <div
      className={styles.statisticsCard}
      onClick={() => (clickFunc ? clickFunc() : null)}
      style={wrapperStyle}
    >
      <div className={styles.title}>
        <div className={styles.titleContent} title={title}>
          {title}
        </div>
        {Boolean(tag) && <Tag color={primaryColor}>{tag}</Tag>}
      </div>
      <div className={styles.count}>
        <div>{count}</div>
        <div>{percent}</div>
      </div>
    </div>
  );
};

export default StatisticsCard;
