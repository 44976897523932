// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","alarmMonitor":"alarmMonitor_alarmMonitor__j8hDi","alarmTabs":"alarmMonitor_alarmTabs__YGJzX","alarmTabItemBox":"alarmMonitor_alarmTabItemBox__-l1wS","alarmTabItem":"alarmMonitor_alarmTabItem__X7yrn","macthActiveAlarmTabItem":"alarmMonitor_macthActiveAlarmTabItem__iQiUY","macthAlarmTabItem":"alarmMonitor_macthAlarmTabItem__EQ34p","activeAlarmTabItem":"alarmMonitor_activeAlarmTabItem__H7OvI","iconBox":"alarmMonitor_iconBox__o8KeN"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"alarmMonitor\":\"alarmMonitor_alarmMonitor__j8hDi\",\"alarmTabs\":\"alarmMonitor_alarmTabs__YGJzX\",\"alarmTabItemBox\":\"alarmMonitor_alarmTabItemBox__-l1wS\",\"alarmTabItem\":\"alarmMonitor_alarmTabItem__X7yrn\",\"macthActiveAlarmTabItem\":\"alarmMonitor_macthActiveAlarmTabItem__iQiUY\",\"macthAlarmTabItem\":\"alarmMonitor_macthAlarmTabItem__EQ34p\",\"activeAlarmTabItem\":\"alarmMonitor_activeAlarmTabItem__H7OvI\",\"iconBox\":\"alarmMonitor_iconBox__o8KeN\"}";
        // 1737772157128
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  