/**
 * 登录
 */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Helmet from "react-helmet";
import { Form, Input, Button, message, Modal } from "antd";
import type { RouteComponentProps } from "react-router-dom";
import { loginRequest, dingtalkLogin } from "@/service/login";
import { getOrganizationRequest } from "@/service/organizationManage";
import localStorageUtil from "@/utils/localStorageUtil";
import { getFirstJumpPath } from "@/utils/permission";
import { operateUserInfo } from "@/utils/user";
import cbsLogoImg from "@/assets/image/cbsLogo.jpg";
import { getCustomer } from "@/service/login";
import styles from "./index.module.scss";
import { useDebounceFn } from "ahooks";

const Login = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history, match } = props;
  const [logo, setLogo] = useState(""); // logo
  const [pageTitle, setPageTitle] = useState(""); // 页面Title
  const [dingdingLogin, setDingdingLogin] = useState(false);
  const { run: handleClick } = useDebounceFn(
    (authCode: any) => {
      // 防抖事件
      dingtalkLogin({ authCode }).then(async (res: any) => {
        const data = res?.data || {};
        console.log(data, "datadata");
        if (data.code === 0) {
          const accessToken = data?.data?.accessToken || "";
          localStorageUtil.setItem("access_token", accessToken);
          const {
            userDetail,
            menuData,
            allPagePermission,
            allInnerPermission,
            allSubMenuKeys,
          } = await operateUserInfo();
          // 存储登录的用户信息
          dispatch({
            type: "login",
            payload: {
              access_token: accessToken,
              userDetail,
              menuData,
              allPagePermission,
              allInnerPermission,
              allSubMenuKeys,
            },
          });
          getcustomerInfo();
          message.success("登录成功");
          // 根据用户权限处理要显示的tab
          getFirstJumpPath(allPagePermission);
          history.replace("/vehicle-admin");
        }
      });
    },
    { leading: true, trailing: false }
  );

  // 获取平台展示的logo
  const getPlateformLogo = async () => {
    const routeParams: any = match.params;
    if (routeParams?.organization) {
      const res = await getOrganizationRequest(routeParams?.organization);
      setLogo(res?.data?.data?.iconUrl || cbsLogoImg);
      setPageTitle(res?.data?.data?.pageTitle || "风险减量运维管理系统");
    } else {
      setLogo(cbsLogoImg);
      setPageTitle("风险减量运维管理系统");
    }
  };

  const getcustomerInfo = async () => {
    const routeParams: any = match.params;
    if (routeParams?.organization) {
      const res = await getCustomer();
      if (res.data.data == "") {
        localStorage.setItem("code", "000000");
      } else {
        localStorage.setItem("code", res.data.data.number);
      }
    } else {
      setLogo(cbsLogoImg);
    }
  };

  useEffect(() => {
    if (localStorageUtil.getItem("access_token")) {
      getCustomer().then( async (res: any) => {
        if (res.data.data == "") {
          localStorage.setItem("code", "000000");
        } else {
          localStorage.setItem("code", res.data.data.number);
        }
        const {
          userDetail,
          menuData,
          allPagePermission,
          allInnerPermission,
          allSubMenuKeys,
        } = await operateUserInfo();
        // 存储登录的用户信息
        dispatch({
          type: "login",
          payload: {
            access_token: localStorageUtil.getItem("access_token"),
            userDetail,
            menuData,
            allPagePermission,
            allInnerPermission,
            allSubMenuKeys,
          },
        });
        getcustomerInfo();
        message.success("登录成功");
        // 根据用户权限处理要显示的tab
        getFirstJumpPath(allPagePermission);
        history.replace("/vehicle-admin");
      });
    } else {
      getPlateformLogo();
    }
    
  }, []);

  // 登录
  const onLogin = async (values: any) => {
    const params = { ...values };
    if (values.mobile) {
      params.mobile = values.mobile.trim();
    }
    const { data } = await loginRequest(params);
    setDingdingLogin(false)
    if (data.code === 0) {
      const accessToken = data?.data?.accessToken || "";
      localStorageUtil.setItem("access_token", accessToken);
      const {
        userDetail,
        menuData,
        allPagePermission,
        allInnerPermission,
        allSubMenuKeys,
      } = await operateUserInfo();
      // 存储登录的用户信息
      dispatch({
        type: "login",
        payload: {
          access_token: accessToken,
          userDetail,
          menuData,
          allPagePermission,
          allInnerPermission,
          allSubMenuKeys,
        },
      });
      getcustomerInfo();
      message.success("登录成功");
      // 根据用户权限处理要显示的tab
      getFirstJumpPath(allPagePermission);
      history.replace("/vehicle-admin");
    }
  };

  const dingdingLoginFn = () => {
    // setDingdingLogin(true);
    // STEP3：在需要的时候，调用 window.DTFrameLogin 方法构造登录二维码，并处理登录成功或失败的回调。

    setTimeout(() => {
      // @ts-ignore
      if (document.getElementById("dingdingBox")) {
        // @ts-ignore
        window.DTFrameLogin(
          {
            id: "dingdingBox",
            width: 300,
            height: 300,
          },
          {
            redirect_uri: encodeURIComponent(`${window.location.href}`),
            client_id: "dingf5ik2f0t5ibqqgfk",
            scope: "openid",
            response_type: "code",
            state: "xxxxxxxxx",
            prompt: "consent",
          },
          (loginResult: any) => {
            const { redirectUrl, authCode, state } = loginResult;
            // 这里可以直接进行重定向
            // window.location.href = redirectUrl;
            // 也可以在不跳转页面的情况下，使用code进行授权
            console.log(loginResult);
            // setDingdingLogin(false)
            handleClick(authCode);
          },
          (errorMsg: any) => {
            // setDingdingLogin(false)
            // 这里一般需要展示登录失败的具体原因
            alert(`Login Error: ${errorMsg}`);
          }
        );
      }
    }, 100);
  };

  useEffect(() => {
    dingdingLoginFn();
  }, []);

  return (
    <div className={styles.loginBg}>
      <Helmet link={[{ rel: "shortcut icon", href: logo }]} title={pageTitle} />
      <div className={styles.loginBox}>
        <div className={styles.logo}>
          <img src={logo} />
        </div>
        <div className={styles.loginForm}>
          <div className={styles.title}>欢迎回来</div>
          <div className={styles.desc}>请先登录</div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onLogin}
            autoComplete="off"
          >
            <Form.Item
              label="账号"
              name="mobile"
              rules={[{ required: true, message: "请输入账号" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="密码"
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                登入
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <Button type="link" size="small" onClick={dingdingLoginFn}>
                钉钉登录
              </Button>
            </Form.Item> */}
          </Form>
        </div>
        <div className={styles.dingdingLogin}>
          <div>
            <div className={styles.title} style={{ paddingBottom: "10px" }}>
              钉钉登录
            </div>
            <div
              style={{ height: "300px", width: "300px" }}
              id="dingdingBox"
            ></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="link" onClick={dingdingLoginFn}>刷新</Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.icp}>
        <a href="https://beian.miit.gov.cn">
          {" "}
          ICP备案号 : 京ICP备2022035052号-1;
        </a>
      </div>
      <Modal
        title="钉钉登录"
        open={dingdingLogin}
        onCancel={() => {
          setDingdingLogin(false);
        }}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{ height: "300px", width: "300px" }}
            id="dingdingBox"
          ></div>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
