/**
 * 车辆标签添加和编辑
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, TreeSelect, Radio } from 'antd';
import { upsertTagRequest, tagSortTreeRequest } from '@/service/tagManage';

interface TagOperateModalProps {
  data?: any;
  type?: string;
  closeModal: () => void;
  reloadData: () => void;
}
const TagOperateModal = (props: TagOperateModalProps) => {
  const [form] = Form.useForm();
  const { data, type, closeModal, reloadData } = props;
  const [tagSortTree, setTagSortTree] = useState<any>([]); // 标签分组数据
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    getTagSortTree();
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, [])

  // 获取标签分组数据
  const getTagSortTree = async() => {
    const res = await tagSortTreeRequest();
    const resData = res.data?.data;
    setTagSortTree(resData ? [resData] : []);
  }

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      const isEdit = type === 'edit';
      if (isEdit) {
        params.id = data.id;
      }
      const res = await upsertTagRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(isEdit ? '编辑成功' : '添加成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加标签' : '编辑标签'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="标签名称"
          name="title"
          rules={[{ required: true, message: '请输入标签名称' }]}
        >
          <Input placeholder='请输入标签名称' />
        </Form.Item>
        <Form.Item
          label="标签分组"
          name="tagSort"
          rules={[{ required: true, message: '请选择标签分组' }]}
        >
          <TreeSelect
            treeData={tagSortTree}
            placeholder='请选择标签分组'
            fieldNames={{ value: 'id' }}
            treeNodeFilterProp="title"
            showSearch
          />
        </Form.Item>
        <Form.Item label="标签状态" name="isDisabled" initialValue={false}>
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>禁用</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="标签说明" name="ps">
          <Input.TextArea placeholder='请输入标签说明' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TagOperateModal;
    