/**
 * 绑定车辆
 */
import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import { firmwareBindRequest } from '@/service/firmwareManage';

interface BindModalProps {
  getFirmwareData: () => void;
  closeModal: () => void;
  id: string;
}
const BindModal = (props: BindModalProps) => {
  const [form] = Form.useForm();
  const { getFirmwareData, closeModal, id } = props;

  // 提交
  const submit = async () => {
    try {
      const values = await form.validateFields();
      const params = { ...values };
      params.id = id;
      const res = await firmwareBindRequest(params);
      if (res.data?.code === 0) {
        message.success('绑定成功');
        closeModal();
        getFirmwareData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={'绑定车辆'}
      open
      onCancel={closeModal}
      onOk={submit}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form form={form} autoComplete="off">
        <Form.Item
          name="sn"
          rules={[{ required: true, message: '请输入车辆SN号' }]}
        >
          <Input.TextArea placeholder="请输入车辆SN号，多个用#隔开，例如：A123#B456#C789" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BindModal;
