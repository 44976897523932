/**
 * 数据大屏S
 */
import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.scss';
import { Button } from 'antd';


const DataSize = () => {
  useEffect(() => {
    ceshi()
  }, []);

  const ceshi = () => {
    window.open('data-size-details')
  }
 
  return (
    <div>
      <Button onClick={ceshi}>跳转到数据大屏详情</Button>
    </div>
  )
}

export default DataSize;
