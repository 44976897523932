import {useEffect, useState} from "react";
import {areaListRequest} from "@/service/offerRecord";
import {fullTypeGen, rootNodeFilter} from "@/pages/offerRecord/utils/utils";

const useAllCities = () => {
  const [allCities, setAllCities] = useState<any>([]); // 城市
  //获取城市
  const getAllCities = async () => {
    const res = await areaListRequest();
    const resData = res.data?.data || [];

    return resData
      .filter(rootNodeFilter)
      .map(fullTypeGen(resData))
  }

  useEffect(() => {
    getAllCities()
      .then(res => setAllCities(res))
  }, []);

  return {
    allCities
  }
}

export default useAllCities;