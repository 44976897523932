/**
 * 状态查询
 */
import React, { useState } from 'react';
import { Form, Button, message } from 'antd';
import dayjs from 'dayjs';
import { statusListRequest, statusListRequestPath } from '@/service/statusSearch';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import AlarmInfoModal from '@/components/alarmInfoModal';
import ExportButton from '@/components/exportButton';
import PositionModal from '@/components/positionModal';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { snColumnConfig } from '@/constants/tableColumn';
import { getAlarmTypeDisplay } from '@/utils/alarm';
import { ownRound } from '@/utils/math';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;

const StatusSearch = () => {
  const [selectCarIds, setSelectCarIds] = useState<Array<number>>([]); // 选择的车辆id
  const [hasSearched, setHasSearched] = useState<boolean>(false); // 是否搜索过
  const [searchParams, setSearchParams] = useState({
    startTime: `${dayjs().format('YYYY-MM-DD')} 00:00:00`,
    endTime: `${dayjs().format('YYYY-MM-DD')} 23:59:59`,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: statusListRequest,
    initRequest: false,
    initPageSize: 90,
    searchParams,
  });

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    if (selectCarIds.length === 0) {
      message.info("请选择车辆");
      return;
    }
    const newParams = { ...params };
    delete newParams.time;
    newParams.startTime = dayjs(params.time[0]).format('YYYY-MM-DD HH:mm:ss');
    newParams.endTime = dayjs(params.time[1]).format('YYYY-MM-DD HH:mm:ss');
    newParams.carIds = selectCarIds;
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams: any = operateSearchParams(values);
    if (newSearchParams) {
      setSearchParams(newSearchParams);
      setHasSearched(true)
    }
  }

  // 获取导出参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 左侧车辆选择
  const treeCheck = (carIds: Array<number>) => {
    setSelectCarIds(carIds)
  }

  // 显示最近位置
  const showPosition = (item: any) => {
    item.plate_number = item.car.plateNumber;
    setOperateInfo({ type: 'position', data: item });
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string, item: any) => <RenderEllipsisColumn text={item.car.plateNumber} />,
    },
    snColumnConfig,
    {
      title: '报警类型',
      dataIndex: 'alarmType',
      width: 130,
      // AEB制动显示为AEB状态
      render: (value: string, item: any) =>
        getAlarmTypeDisplay(item).includes('AEB制动')
          ? 'AEB状态'
          : getAlarmTypeDisplay(item)
    },
    {
      title: '上报时间',
      dataIndex: 'reportedTime',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={formatTime(value)} />,
    },
    {
      title: '车速',
      dataIndex: 'speed',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={ownRound(value, 1)} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 150,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'info', data: item })}>详细信息</Button>
            <Button type="link" onClick={() => showPosition(item)}>位置</Button>
          </div>
        )
      },
    },
  ]

  return (
    <div className={styles.spaceBetweenPageContentBox}>
      <div className={styles.carTree}>
        <CarTree
          checkable
          treeCheck={treeCheck}
        />
      </div>
      <div className={styles.contentBox}>
        <div>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={onSearch} form={form}>
            <Form.Item label="时间范围" name="time" initialValue={[dayjs(searchParams.startTime), dayjs(searchParams.endTime)]}>
              <RangePicker showTime allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item>
              <div className={styles.searchButtonBox}>
                <Button type="primary" htmlType="submit">查询</Button>
                <ExportButton
                  url={statusListRequestPath}
                  fileName='aebStatus'
                  getParamsFunc={getExportParams}
                  permissionKey="/car-admin/status-search/export"
                />
              </div>
            </Form.Item>
          </Form>
        </div>
        {hasSearched ?
          <div className={styles.tableBox}>
            <CommonTable
              rowKey="id"
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalCount,
                current: page,
                pageSize: limit,
                onChange: onPageChange,
              }}
              bordered
              loading={loading}
            />
          </div> :
          <div className={styles.tipBox}>
            请在左侧选择车辆后点击“查询”按钮
          </div>
        }
      </div>
      {/* 详细信息弹窗 */}
      {operateInfo.type === 'info' &&
        <AlarmInfoModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
          version='1.0'
          infoKey='info'
        />
      }
      {/* 位置弹窗 */}
      {operateInfo.type === 'position' &&
        <PositionModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
        />
      }
    </div>
  )
}

export default StatusSearch;
   