/**
 * 渠道选择框
 */
import React, { useEffect, useState, useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { useSelector } from 'react-redux';
import { channelData, userCateIdMatchChannelData } from '@/constants/channel';

interface ChannelSelectProps extends SelectProps {
  value?: string; // 选择的数据
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
}
const ChannelSelect = (props: ChannelSelectProps) => {
  const { value, onChange, ...restProps } = props;
  const { userDetail } = useSelector((state: any) => state.userStore);
  const [selectValue, setSelectValue] = useState<any>([]);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 选择渠道
  const channelChange = (value: string, options: any) => {
    setSelectValue(value);
    onChange && onChange(value, options)
  }

  // 根据用户分组显示不同的渠道数据
  const showChannelData = useMemo(() => {
    const userCateIdArr = userDetail?.cateIdArr || [];
    const hasMatchChannelIds = userCateIdArr.find((item: number) => Boolean(userCateIdMatchChannelData[item]));
    if (hasMatchChannelIds) {
      return channelData.filter((item: any) => userCateIdArr.includes(item.value))
    } else {
      return channelData
    }
  }, [])

  return (
    <Select
      options={showChannelData}
      onChange={channelChange}
      value={selectValue}
      allowClear
      optionFilterProp="label"
      placeholder="请选择渠道"
      {...restProps}
    />
  )
}

export default ChannelSelect;
  