/**
 * 记录仪流控配置
 */

import React, { useEffect, useState } from 'react';
import { Card, Popconfirm, message } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import {
  getRecorderFlowConfigurationRequest,
  saveRecorderFlowConfigurationRequest
} from '@/service/recorderFlowConfiguration';
import { recorderSensitivity } from '@/constants/parameterConfiguration';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';

const RecorderFlowConfiguration = () => {
  const [loading, setLoading] = useState(false); // 是否loading
  const [data, setData] = useState([]); // 数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的数据存储

  // 获取配置规则
  const getRecorderFlowConfiguration = async () => {
    try {
      setLoading(true);
      const res = await getRecorderFlowConfigurationRequest();
      setData(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecorderFlowConfiguration();
  }, []);

  // 删除规则
  const deleteRule = async (deleteId: number) => {
    const saveData = data.filter((item: any) => item.id !== deleteId);
    const res = await saveRecorderFlowConfigurationRequest(saveData);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getRecorderFlowConfiguration();
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '当月消耗流量阈值',
      dataIndex: 'threshold',
      width: 160,
      render: (value: number) => (value ? `${value / 1024 / 1024} MB` : value)
    },
    {
      title: '灵敏性',
      dataIndex: 'sensitivity',
      width: 80,
      render: (value: number) =>
        recorderSensitivity.find((item: any) => item.value === value)?.label
    },
    {
      title: '拍摄时长',
      dataIndex: 'duration',
      width: 90
    },
    {
      title: 'FCW触发开关',
      dataIndex: 'fcwEnable',
      width: 120,
      render: (value: boolean) => (value ? '是' : '否')
    },
    {
      title: 'HMW触发开关',
      dataIndex: 'hmwEnable',
      width: 120,
      render: (value: boolean) => (value ? '是' : '否')
    },
    {
      title: 'LDW触发开关',
      dataIndex: 'ldwEnable',
      width: 120,
      render: (value: boolean) => (value ? '是' : '否')
    },
    {
      title: 'AEB触发开关',
      dataIndex: 'aebEnable',
      width: 120,
      render: (value: boolean) => (value ? '是' : '否')
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      fixed: 'right' as const,
      render: (value: any, item: any, index: number) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/recorder-flow-configuration/edit"
              type="link"
              onClick={() =>
                setOperateInfo({
                  type: 'edit',
                  data,
                  editIndex: index
                })
              }
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除此规则吗?"
              onConfirm={() => deleteRule(item.id)}
            >
              <PermissionButton
                operateType="/car-admin/recorder-flow-configuration/delete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/recorder-flow-configuration/add"
          type="primary"
          onClick={() =>
            setOperateInfo({
              type: 'add',
              data
            })
          }
        >
          添加规则
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
      />
      {['add', 'edit'].includes(operateInfo.type) && (
        <OperateModal
          type={operateInfo.type}
          data={operateInfo.data}
          editIndex={operateInfo.editIndex}
          closeModal={() => setOperateInfo({})}
          reloadData={getRecorderFlowConfiguration}
        />
      )}
    </Card>
  );
};

export default RecorderFlowConfiguration;
