/**
 * 电子围栏区域列表
 */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { message, Tag, Popconfirm } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SettingOutlined
} from '@ant-design/icons';
import PermissionButton from '@/components/permissionButton';
import {
  fenceAreaListRequest,
  deleteFenceAreaRequest
} from '@/service/electronFence';
import OperateModal from '../operateModal';
import IntersectionListModal from '../intersectionListModal';
import commonStyles from '@/styles/pageContent.module.scss';
import styles from '@/pages/crossMark/index.module.scss';

const { CheckableTag } = Tag;
interface FenceAreaListProps {
  selectFenceAreaId: null | number;
  setSelectFenceAreaId: (id: null) => void;
  clearMap: () => void;
  onFenceAreaChange: (checked: boolean, item: any) => void;
  selectFenceAreaPointCount: null | number;
}
const FenceAreaList = (props: FenceAreaListProps, ref: any) => {
  const {
    selectFenceAreaId,
    setSelectFenceAreaId,
    clearMap,
    onFenceAreaChange,
    selectFenceAreaPointCount
  } = props;
  const [fenceAreaList, setFenceAreaList] = useState([]); // 围栏区域列表
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息

  // 暴露给父组件的内容
  useImperativeHandle(ref, () => ({
    fenceAreaList
  }));

  // 获取围栏区域列表
  const getFenceAreaList = async () => {
    const res = await fenceAreaListRequest();
    setFenceAreaList(res.data?.data || []);
  };

  useEffect(() => {
    getFenceAreaList();
  }, []);

  // 删除围栏区域
  const deleteFenceArea = async (id: number) => {
    const res = await deleteFenceAreaRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      if (id === selectFenceAreaId) {
        setSelectFenceAreaId(null);
        clearMap();
      }
      getFenceAreaList();
    }
  };

  return (
    <div className={styles.leftPart}>
      <div className={styles.buttonBox}>
        <PermissionButton
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
          operateType="/car-admin/electron-fence/add-area"
        >
          添加围栏区域
        </PermissionButton>
      </div>
      <div className={styles.roadBox}>
        {fenceAreaList.map((item: any) => (
          <div className={styles.roadItem} key={item.id}>
            <CheckableTag
              key={item.id}
              checked={selectFenceAreaId === item.id}
              onChange={(checked: boolean) => onFenceAreaChange(checked, item)}
            >
              {item.name}
              {selectFenceAreaId === item.id
                ? `（${selectFenceAreaPointCount}个）`
                : null}
            </CheckableTag>
            <div className={commonStyles.tableOperateColumn}>
              <PermissionButton
                type="link"
                size="small"
                onClick={() => setOperateInfo({ type: 'edit', data: item })}
                operateType="/car-admin/electron-fence/edit-area"
                icon={<EditOutlined />}
                title="编辑"
              />
              <PermissionButton
                type="link"
                size="small"
                onClick={() =>
                  setOperateInfo({ type: 'intersection', data: item })
                }
                operateType="/car-admin/electron-fence/edit-area"
                icon={<SettingOutlined />}
                title="路口类型"
              />
              <Popconfirm
                title="确定要删除该围栏区域吗？"
                onConfirm={() => deleteFenceArea(item.id)}
              >
                <PermissionButton
                  type="link"
                  size="small"
                  operateType="/car-admin/electron-fence/delete-area"
                  icon={<DeleteOutlined />}
                  title="删除"
                />
              </Popconfirm>
            </div>
          </div>
        ))}
      </div>
      {['add', 'edit'].includes(operateInfo.type) && (
        <OperateModal
          {...operateInfo}
          closeModal={() => setOperateInfo({})}
          reloadData={getFenceAreaList}
        />
      )}
      {operateInfo.type === 'intersection' && (
        <IntersectionListModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
    </div>
  );
};

export default forwardRef(FenceAreaList);
