/**
 * 车辆运行状态列表弹窗(根据车辆ids获取列表数据)
 */
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import { snColumnConfig, cameraSnColumnConfig, cateGroupColumnConfig, frameNumberColumnConfig, carBrandColumnConfig, carModelColumnConfig } from '@/constants/tableColumn';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import PositionModal from '@/components/positionModal';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { deviceAggRequest, deviceAggRequestPath, deviceAdasAggRequest, deviceAdasAggRequestPath } from '@/service/abnormalSummary';
import { ownRound } from '@/utils/math';
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';

interface CarStatusListModalProps {
  carIds: any; // 车辆Ids
  day: string; // 日期
  closeModal: () => void; // 关闭弹窗的方法
  exportFileName: string; // 导出文件的文件名
  beginDate?: string; // 点击车牌跳转的参数
  endDate?: string; // 点击车牌跳转的参数
  isKls?: boolean; // 是否为昆仑山设备
  exportPermissionKey: string; // 导出按钮的权限key
  errTypeLength: number;
}
const CarStatusListModal = (props: CarStatusListModalProps) => {
  const dispatch = useDispatch();
  const { carIds, exportFileName, day, closeModal, beginDate, endDate, isKls, exportPermissionKey, errTypeLength } = props;
  const [searchParams] = useState({
    beginDate: day,
    endDate: day,
    carIds,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [visitedCar, setVisitedCar] = useState<any>([]); // 已经查看的车辆

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: errTypeLength == 8 ? deviceAdasAggRequest : deviceAggRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
    frontEndPageChange: true,
  });

  // 点击车牌跳转到车辆诊断页面
  const onPlateNumberClick = (plateNumber: string) => {
    setVisitedCar([...visitedCar, plateNumber]);
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: { plateNumber, beginDate, endDate, }
      }
    })
  }

  // 表格列显示
  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
    },
    snColumnConfig,
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 130,
      render: (value: string) => (
        <div className={styles.tableOperateColumn}>
          {visitedCar.includes(value) ?
            <span style={{ cursor: 'pointer' }} onClick={() => onPlateNumberClick(value)}>{value}(已查看)</span> :
            <Button type="link" onClick={() => onPlateNumberClick(value)}>{value}</Button>
          }
        </div>
      )
    },
    cameraSnColumnConfig,
    cateGroupColumnConfig,
    frameNumberColumnConfig,
    carBrandColumnConfig,
    carModelColumnConfig,
    {
      title: '是否在线',
      dataIndex: 'online',
      width: 120,
    },
    {
      title: '最后更改时间',
      dataIndex: 'updated_at',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} />
    },
    {
      title: '安装队长',
      dataIndex: 'installCaptain',
      width: 130,
    },
    {
      title: '行驶里程(KM)',
      dataIndex: 'mileage',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '车速为0数量',
      dataIndex: 've0',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: 'GPS速度为0数量',
      dataIndex: 'ge0',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '车速超120数量',
      dataIndex: 'vm120',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '故障码2数量',
      dataIndex: 'e2',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '故障码31数量',
      dataIndex: 'e31',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '故障码91数量',
      dataIndex: 'e91',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '故障码130数量',
      dataIndex: 'e130',
      width: 130,
      hide: isKls,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '故障码133数量',
      dataIndex: 'e133',
      width: 130,
      hide: isKls,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: 'hmw数量',
      dataIndex: 'hmwCount',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '修正后hmw数量',
      dataIndex: 'hmwHumanCount',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '百公里hmw预警次数',
      dataIndex: 'hmwBi',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '修正后百公里hmw预警次数',
      dataIndex: 'hmwHumanBi',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '百公里超声波制动次数',
      dataIndex: 'ultrBi',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '百公里双目制动次数',
      dataIndex: 'binBi',
      width: 130,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '百公里刹车次数',
      dataIndex: 'breakCountPm',
      width: 140,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: 'AEB次数',
      dataIndex: 'aebCount',
      width: 90,
      render: (value: number) => ownRound(value, 0)
    },
    {
      title: '角雷达制动次数',
      dataIndex: 'angleRadarCount',
      width: 150,
      hide: !isKls,
    },
    {
      title: '车身异常数量',
      dataIndex: 'vehicleBodyErrCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: '控制器异常数量',
      dataIndex: 'controllerErrCount',
      width: 140,
      hide: !isKls,
    },
    {
      title: '4g异常数量',
      dataIndex: 'err4gCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: 'gps异常数量',
      dataIndex: 'gpsErrCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: 'imu异常数量',
      dataIndex: 'imuErrCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: '比例阀异常数量',
      dataIndex: 'proportionErrorCount',
      width: 140,
      hide: !isKls,
    },
    {
      title: '角雷达预警次数',
      dataIndex: 'bsdCount',
      width: 130,
      hide: !isKls,
    },
    {
      title: '角雷达故障次数',
      dataIndex: 'milimeterWaveErrCount',
      width: 140,
      hide: !isKls,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 340,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'contact', data: item })}>联系方式</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'position', data: item })}>最近位置</Button>
          </div>
        )
      },
    },
  ] 

  // 过滤不展示的列
  const showColumns = columns.filter((item: any) => !item.hide);

  return (
    <Fragment>
      <Modal
        title="详细数据"
        visible
        footer={null}
        onCancel={closeModal}
        width='70%'
        centered
        getContainer={false}
        maskClosable={false}
      >
        <ExportButton 
          type="primary"
          style={{ marginBottom: '12px' }}
          url={errTypeLength == 8 ? deviceAdasAggRequestPath : deviceAggRequestPath}
          fileName={`${exportFileName}(${day}).xlsx`}
          getParamsFunc={() => ({
            beginDate: day,
            endDate: day,
            carIds,
            export: true,
          })}
          permissionKey={exportPermissionKey}
        />
        <CommonTable
          columnFilter={false}
          rowKey='id'
          columns={showColumns}
          dataSource={data}
          pagination={{
            total: totalCount,
            current: page,
            pageSize: limit,
            onChange: onPageChange,
          }}
          bordered
          scroll={{ y: '50vh' }}
          loading={loading}
        />
      </Modal>
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'position' &&
        <PositionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Fragment>
    
  )
}

export default CarStatusListModal;
    