/**
 * 运维工单
 */
import dayjs from 'dayjs';
import { Button, Tag } from 'antd';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { renderTypeColumns, renderStatusColumns, renderDelayColumns } from '@/utils/workOrder';
import styles from '@/styles/pageContent.module.scss';
import Thumbnail from '@/components/thumbnail';
import { useDispatch } from 'react-redux';
// 工单类别数据
export const workOrderTypeData = [
  { label: '故障运维', value: 1 },
  { label: '安装运维', value: 2 },
  { label: '拆除运维', value: 3 },
  { label: '专项走访', value: 6 },
  { label: '设备关停', value: 7 },
  { label: '其他', value: 5 },

]

// 工单状态的枚举
export enum WorkOrderStatusEnum {
  unassign = 1,
  assigned = 2,
  pending = 3,
  progress = 4, 
  refuse = 5, 
  finished = 6, 
  close = 7, 
}

// 工单状态数据
export const workOrderStatusData = [
  { label: '未分派', value: WorkOrderStatusEnum.unassign, color: 'processText' },
  { label: '已分派', value: WorkOrderStatusEnum.assigned, color: 'processText' },
  { label: '待处理', value: WorkOrderStatusEnum.pending, color: 'processText' },
  { label: '正在处理', value: WorkOrderStatusEnum.progress, color: 'processText' },
  { label: '被拒绝', value: WorkOrderStatusEnum.refuse, color: 'dangerText' },
  { label: '已完成', value: WorkOrderStatusEnum.finished, color: 'warningText' },
  { label: '已关闭', value: WorkOrderStatusEnum.close, color: 'successText' },
]

export const workOrderListColumns = [
  {
    title: '工单编号',
    dataIndex: 'no',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />,
    fixed: 'left' as 'left',
  },
  {
    title: '创建日期',
    dataIndex: 'createTime',
    width: 160,
    render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
  },
  {
    title: '期望完成时间',
    dataIndex: 'expire',
    width: 120,
    render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD') : value,
  },
  {
    title: '工单类型',
    dataIndex: 'type',
    width: 100,
    render: (value: number) => renderTypeColumns(value),
  },
  {
    title: '工单状态',
    dataIndex: 'stat',
    width: 100,
    render: (value: number) => renderStatusColumns(value),
  },
  {
    title: '工单标签',
    dataIndex: 'tags',
    width: 100,
    render: (value: any) => <div className={styles.tableTagColumn}>
      {(Object.values(value || {})[0] as any || []).map(((item1: any) => <Tag key={item1.tagId}>{item1.title}</Tag>))}
    </div>,
  },
  {
    title: '工单创建人',
    dataIndex: 'issu',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />,
  },
  {
    title: '工单处理人',
    dataIndex: 'operator',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />,
  },
  {
    title: '工单区域',
    dataIndex: 'district',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />,
  },
  {
    title: '客户电话',
    dataIndex: 'tel',
    width: 110,
    ellipsis: { showTitle: false },
  },
  {
    title: '工单描述',
    dataIndex: 'description',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />,
  },
  {
    title: '是否逾期',
    dataIndex: 'delay',
    width: 90,
    render: (value: boolean, item: any) => renderDelayColumns(value, item),
  },
  {
    title: '渠道',
    dataIndex: 'channel',
    width: 90,
    render: (value: string) => <RenderEllipsisColumn text={value} />,
  },
  {
    title: '所属公司',
    dataIndex: 'company',
    width: 150,
    render: (value: string) => <RenderEllipsisColumn text={value} />,
  },
  {
    title: '影像',
    dataIndex: 'resources',
    width: 170,
    ellipsis: { showTitle: false },
    render: (value: any) => (<Thumbnail
      dataSource={value || []}
      downloadImgName="图片"
      downloadVideoName="视频"
    />),
  },
  {
    title: '所属业务渠道',
    dataIndex: 'businessUnit',
    width: 150,
    render: (value: string) => <RenderEllipsisColumn text={value} />,
  },
  {
    title: '是否重开工单',
    dataIndex: 'doubleAssign',
    width: 150,
    render: (value: boolean) =><RenderEllipsisColumn text={value === true ? '是':'否'} />,
  },
];
