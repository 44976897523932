/**
 * 定位状态表格配置
 */
import { formatTime } from '@/utils/formatTime';
import { renderImuStatus } from '@/utils/imu';

// 表格列配置
export const positionStatusColumns = [
  {
    title: '车牌号',
    dataIndex: 'plateNumber',
    width: 110,
    fixed: 'left' as const
  },
  {
    title: '时间',
    dataIndex: 'time',
    width: 160,
    fixed: 'left' as const,
    render: (value: string) => formatTime(value)
  },
  {
    title: '数据类型',
    dataIndex: 'type',
    width: 100,
    fixed: 'left' as const
  },
  {
    title: 'GPS卫星数量',
    dataIndex: 'gnssCount',
    width: 130
  },
  {
    title: 'GPS定位状态',
    dataIndex: 'gpsLocationStatus',
    width: 130,
    render: (value: string) => {
      if (value === 'A') {
        return '已定位';
      } else if (value === 'V') {
        return '未定位';
      } else {
        return value;
      }
    }
  },
  {
    title: 'GPS连接状态',
    dataIndex: 'gpsConnectStatus',
    width: 130
  },
  {
    title: '4G信号强度',
    dataIndex: 'signal4g',
    width: 120
  },
  {
    title: 'IMU故障',
    dataIndex: 'imuFailureId',
    width: 120,
    render: (value: number) => renderImuStatus(value)
  }
];
