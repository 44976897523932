/**
 * 传感器配置方案相关请求
 */
import request from '@/utils/request';

export interface SensorConfigListRequestParams {
  page: number;
  limit: number;
  projectId?: number;
  name?: string;
  id?: string;
  userId?: number;
  beginTime?: string;
  endTime?: string;
}

// 获取传感器配置方案列表
export const sensorConfigListRequestPath = '/api/v2/program/list';
export const sensorConfigListRequest = (
  params: SensorConfigListRequestParams
) => {
  return request.get(sensorConfigListRequestPath, {
    params
  });
};

export interface UpsertSensorConfigParams {
  name: string;
  projectId: number;
  ps?: string;
  id?: number;
}
// 添加/编辑传感器配置方案
export const upsertSensorConfigRequest = (data: UpsertSensorConfigParams) => {
  return request.post('/api/v2/program/upsert', data);
};

// 删除传感器配置方案
export const deleteSensorConfigRequest = (id: number) => {
  return request.delete(`api/v2/program/${id}`);
};

// 获取传感器配置方案详情
export const sensorConfigDetailRequest = (id: number) => {
  return request.get(`api/v2/program/detail/${id}`);
};
