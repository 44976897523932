// extracted by mini-css-extract-plugin
export default {"layoutHeader":"mainHeader_layoutHeader__vZUD5","checkBox":"mainHeader_checkBox__UCamP","logoTitle":"mainHeader_logoTitle__I-FWc","headerContent":"mainHeader_headerContent__es-iT","toggleIcon":"mainHeader_toggleIcon__Z2QOV","quickMenu":"mainHeader_quickMenu__vO5g7","userName":"mainHeader_userName__v7nWZ","informationText":"mainHeader_informationText__AQL-S","informationBox":"mainHeader_informationBox__8GYmd","information":"mainHeader_information__kPmlT","informationBoxReadAll":"mainHeader_informationBoxReadAll__enkrz","informationBoxItem":"mainHeader_informationBoxItem__aO2f2","informationBoxCenter":"mainHeader_informationBoxCenter__v6yfO","informationBoxButton":"mainHeader_informationBoxButton__xOqR6","informationBoxButtonItem":"mainHeader_informationBoxButtonItem__NhyNM","messageCard":"mainHeader_messageCard__Wq4sr","informationNum":"mainHeader_informationNum__Xdv4f","menuBox":"mainHeader_menuBox__eQZVN","menuGroup":"mainHeader_menuGroup__BUvto","menuTitle":"mainHeader_menuTitle__10ETX","menuItemGroup":"mainHeader_menuItemGroup__GtAMq","menuItem":"mainHeader_menuItem__2p5eN"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"layoutHeader\":\"mainHeader_layoutHeader__vZUD5\",\"checkBox\":\"mainHeader_checkBox__UCamP\",\"logoTitle\":\"mainHeader_logoTitle__I-FWc\",\"headerContent\":\"mainHeader_headerContent__es-iT\",\"toggleIcon\":\"mainHeader_toggleIcon__Z2QOV\",\"quickMenu\":\"mainHeader_quickMenu__vO5g7\",\"userName\":\"mainHeader_userName__v7nWZ\",\"informationText\":\"mainHeader_informationText__AQL-S\",\"informationBox\":\"mainHeader_informationBox__8GYmd\",\"information\":\"mainHeader_information__kPmlT\",\"informationBoxReadAll\":\"mainHeader_informationBoxReadAll__enkrz\",\"informationBoxItem\":\"mainHeader_informationBoxItem__aO2f2\",\"informationBoxCenter\":\"mainHeader_informationBoxCenter__v6yfO\",\"informationBoxButton\":\"mainHeader_informationBoxButton__xOqR6\",\"informationBoxButtonItem\":\"mainHeader_informationBoxButtonItem__NhyNM\",\"messageCard\":\"mainHeader_messageCard__Wq4sr\",\"informationNum\":\"mainHeader_informationNum__Xdv4f\",\"menuBox\":\"mainHeader_menuBox__eQZVN\",\"menuGroup\":\"mainHeader_menuGroup__BUvto\",\"menuTitle\":\"mainHeader_menuTitle__10ETX\",\"menuItemGroup\":\"mainHeader_menuItemGroup__GtAMq\",\"menuItem\":\"mainHeader_menuItem__2p5eN\"}";
        // 1737772154780
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  