/**
 * 轨迹回放相关请求
 */
import request from "@/utils/request";

// 获取车辆坐标
export const currentLocationRequest = (id: string) => {
  return request.get(`/api/v2/trackPlayback/currentLocation/${id}`);
}

export interface DayInfoRequestParams {
  trickDate: string;
  plateNumber: string;
  isKls?: boolean;
}

// 获取GPS点位数据(某一天)
export const dayGpsRequest = (params: DayInfoRequestParams) => {
  return request.get('/api/v2/trackPlayback/dayGpsStatistic', {
    params
  });
}

// 获取GPS点位数据(10月之前某一天)
export const historyDayGpsRequest = (params: DayInfoRequestParams) => {
  return request.get('/api/v2/trackPlayback/history/dayGpsStatistic', {
    params
  });
}


// 获取GPS点位数据(时间区间)
export const listGpsRequestPath = '/api/v2/trackPlayback/listGps';
export const listGpsRequest = (params: any) => {
  return request.get(listGpsRequestPath, {
    params
  });
}

// 获取报警数据
export const dayAlarmRequest = (params: DayInfoRequestParams) => {
  return request.get('/api/v2/trackPlayback/dayAlarm', {
    params
  });
}

export const gpsListExportPath = '/api/v2/trackPlayback/gpsListExport';
