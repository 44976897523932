/**
 * 用户账号充值记录
 */
import dayjs from 'dayjs';
import React, {useState } from 'react';
import { Card, Form, Row, Col, Button,} from 'antd';
import { useSelector } from 'react-redux';
import CommonTable from '@/components/commonTable';
import { getInnerPermissionEnable } from '@/utils/permission';
import DatePicker from '@/components/datePicker';
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';
import { videoClientRequest } from '@/service/videoInvest';
import PermissionButton from '@/components/permissionButton';
import PaymentDetail from './components/PaymentDetail';
import RechargeModal from './components/rechargeModal';


const { RangePicker } = DatePicker;
const VideoInvestRecord = () => {
  const { userDetail } = useSelector((state: any) => state.userStore);
  const [operateInfo, setOperateInfo] = useState<any>({}); // 充值弹框
  const [isOpenPaymentDetail, setIsOpenPaymentDetail] = useState<any>(false); //是否打开充值详情
  const [getPayDetMoney, setGetPayDetMoney] = useState<any>({});
  const [getOrderNumber, setGetOrderNumber] = useState<any>({});

  // 获取接口请求的issuId参数值
  const getSearchIssuId = () => {
    if (getInnerPermissionEnable('/car-admin/work-order/work-order-super')) {
      return undefined;
    } else {
      return userDetail.id;
    }
  }

  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    userId: getSearchIssuId()
  }); // 搜索相关的参数

  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: videoClientRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
    newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
    delete newSearchParams.day;
    setSearchParams(newSearchParams);
    getData(newSearchParams)
  };
  //充值按钮
  const rechargeModel = () => {
    // 清除本地缓存的订单号以及支付方式
    localStorage.removeItem("zhifubaoPayment")
    localStorage.removeItem("zhifubaoOrderNo")
    localStorage.removeItem("contactDetail")
    setOperateInfo({ type: 'contact', data: userDetail.id })
  }

  const onOpenPays = () => {
    // 在这里根据标识打开 PaymentDetail 组件
    setIsOpenPaymentDetail(!isOpenPaymentDetail)
  };

  //充值套餐界面金额 传入 充值详情界面
  const getMoney = (data: any) => {
    setGetPayDetMoney(data)
  };

  //充值套餐界面订单号 传入 充值详情界面
  const getorderNumber = (data: any) => {
    setGetOrderNumber(data)
  };
  // 表格列显示
  const columns = [
    {
      title: '订单号',
      dataIndex: 'orderNo',
      width: 110
    },
    {
      title: '下单日期',
      dataIndex: 'investTime',
      width: 110,
    
    },
    {
      title: '充值时长(分钟)',
      dataIndex: 'duration',
      width: 110,
    },
    {
      title: '充值费用(元)',
      dataIndex: 'cost',
      width: 110
    },
    {
      title: '是否充值',
      dataIndex: 'payFlag',
      width: 110,
      render: (value:Number) => value == 1 ? '是' : '否',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
    }
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="day"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
              <Button type="primary" htmlType="submit">搜索</Button>
                <PermissionButton
                  operateType='/car-admin/rechargeModel'
                  type="primary"
                  onClick={rechargeModel}
                >充值</PermissionButton>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'contact' &&
        <RechargeModal data={operateInfo.data} closeModal={() => setOperateInfo({})} onOpenPay={onOpenPays} moneyData={getMoney} orderNumber={getorderNumber} />
      }
      {isOpenPaymentDetail && <PaymentDetail onClose={() => setOperateInfo({})} item={getPayDetMoney} orderNumber={getOrderNumber} onOpenPay={onOpenPays} />}
    </Card>
  )
}

export default VideoInvestRecord;
