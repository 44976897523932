/**
 * 客服任务处理
 */
import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  message,
  Input,
  Popover,
  Modal,
  Select,
  Tag,
  Popconfirm,
  Tooltip,
  Table,
  Collapse,
} from "antd";
import dayjs from "dayjs";
import CommonTable from "@/components/commonTable";
import ExportButton from "@/components/exportButton";
import DatePicker from "@/components/datePicker";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { useTable } from "@/utils/useTable";
import styles from "@/styles/pageContent.module.scss";
import PermissionButton from "@/components/permissionButton";
// import OperateModalTask from './components/operateModal';
import {
  serviceTaskListRequestPath,
  serviceTaskListRequest,
  DeleteServiceTask,
} from "@/service/serviceTask";
import OperateService from "@/pages/carDiagnosis/components/operateService";
import OperateModal from "../workOrder/components/operateModal"; //操作工单
import { upsertServiceTask } from "@/service/serviceTask";
import { useDispatch, useSelector } from "react-redux";
import TaskDetail from "../serviceTask/components/taskDetail";
import HistoryResult from "./components/historyResult";
import AllServiceRecord from "./components/allServiceRecord";
import Thumbnail from "@/components/thumbnail";
import SendBackCause from "./components/sendBackCause";
import { tagListRequest } from "@/service/tagManage";
import { TagCategoryEnum } from "@/constants/tagManage";
import type { TableRowSelection } from "antd/es/table/interface";

const { RangePicker } = DatePicker;

const ServiceTaskDispose = () => {
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { activeKey, tabParams } = tabPagesStore;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [addWorkOrder, setAddWorkOrder] = useState<any>({}); // 创建工单的弹窗
  const [operateVisible, setOperateVisible] = useState<any>({}); // 添加客服记录的弹窗
  const [serveTypeList, setServeTypeList] = useState<any>([]); // 问题来源的标签
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: serviceTaskListRequest,
      initPageSize: 40,
      searchParams,
    });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // 表格选择的项

  // 编辑的时候赋值
  useEffect(() => {
    getServeType();
  }, []);

  // 监听跳转时携带参数的形式
  useEffect(() => {
    tabParamsSearch();
  }, [activeKey, tabParams]);

  // 跳转时携带参数时搜索
  const tabParamsSearch = async () => {
    if (tabParams) {
      const newTabParams = {
        plateNumber: tabParams.plateNumber,
      };
      // form.setFieldsValue(newTabParams);
      form.submit();
      const detailItem = {
        plateNumber: tabParams.obj,
      };
      //弹出评论的框
      // setOperateInfo({ type: 'detail', data: detailItem })
    }
  };

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams: any = { ...params };
    if (params.time) {
      newSearchParams.beginDate = dayjs(params.time[0]).format("YYYY-MM-DD");
      newSearchParams.endDate = dayjs(params.time[1]).format("YYYY-MM-DD");
      delete newSearchParams.time;
    }
    if (params.disTime) {
      newSearchParams.dealBeginDate = dayjs(params.disTime[0]).format(
        "YYYY-MM-DD"
      );
      newSearchParams.dealEndDate = dayjs(params.disTime[1]).format(
        "YYYY-MM-DD"
      );
      delete newSearchParams.disTime;
    }
    if (params.expectTime) {
      newSearchParams.expectBeginDate = dayjs(params.expectTime[0]).format(
        "YYYY-MM-DD"
      );
      newSearchParams.expectEndDate = dayjs(params.expectTime[1]).format(
        "YYYY-MM-DD"
      );
      delete newSearchParams.expectTime;
    }
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams); //搜索相关参数
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  };

  // 任务状态
  const stateData = [
    { label: "未处理", value: 0 },
    { label: "处理中-待呼叫", value: 1 },
    { label: "处理中-已呼叫", value: 2 },
    { label: "处理中-待处理", value: 9 },
    { label: "协助车辆", value: 10 },
    { label: "处理中-已创建工单", value: 3 },
    { label: "处理中-已派工单", value: 4 },
    { label: "处理中-已完成现场处理", value: 5 },
    { label: "处理完成", value: 6 },
    { label: "已退回", value: 7 },
    { label: "已关闭", value: 8 },
  ];

  // 获取问题来源
  const getServeType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.serveType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setServeTypeList(tags);
  };

  // 点击车牌跳转车辆诊断
  const skipCarDiagnosis = (value: any) => {
    dispatch({
      type: "addTab",
      payload: {
        tab: "车辆诊断",
        key: "/car-admin/car-diagnosis",
        params: {
          plateNumber: value,
          beginDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
          endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        },
      },
    });
    getData();
  };

  // 删除单条
  const deleteCarInterviewItem = async (id: number) => {
    const res = await DeleteServiceTask(id);
    if (res.data?.code === 0) {
      message.success("删除成功");
      getData();
    }
  };

  // 接受任务
  const confireItem = async (item: any) => {
    const params = { ...item };
    params.taskState = 1;
    const res = await upsertServiceTask(params);
    if (res.data?.code === 0) {
      message.success("修改成功");
      getData();
    }
  };

  // // 拒绝任务
  // const refuseItem = async(item: any) => {
  //   const params = {...item}
  //   params.taskState = 7
  //   const res = await upsertServiceTask(params);
  //   if (res.data?.code === 0) {
  //     message.success('修改成功');
  //     getData();
  //   }
  // }

  // 客服记录回调
  const anyData = async (one: any, two: any) => {
    const params = one;
    params.taskState = two.treatmentStateType;
    params.expectedTime = dayjs(two.expectedTime);
    const res = await upsertServiceTask(params);
    if (two.treatmentStateType == 2) {
      const paramsss = { ...one };
      paramsss.category = two.category; // 问题来源
      paramsss.types = two.types; // 问题原因
      paramsss.reason = two.reason; // 问题原因(自定义)
      setAddWorkOrder({ type: "servicrTaskAdd", data: paramsss });
    }
    getData();
  };

  // 创建工单
  const getWorkOrderId = async (
    orderId: any,
    customerId: any,
    anyData: any
  ) => {
    const params = anyData;
    params.orderId = orderId;
    params.taskState = 3;
    const res = await upsertServiceTask(params);
    getData();
  };

  // 关闭评论
  const taskCommentDetail = () => {
    setOperateInfo({});
    getData();
  };

  //处理完成
  const finishedWork = async (item: any) => {
    const params = { ...item };
    params.taskState = 6;
    const res = await upsertServiceTask(params);
    if (res.data?.code === 0) {
      message.success("修改成功");
      getData();
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  //批量添加客服记录
  const addserviceRecodeBatch = () => {
    setOperateVisible({
      show: true,
      type: "serviceTaskBatchAdd",
      data: selectedRowKeys,
    });
  };

  //批量派发工单
  const distributeBatch = () => {
    const aaa: any = [];
    selectedRowKeys.map((item: any) => {
      data.map((items: any) => {
        console.log("items", items);
        if (item == items.id) {
          aaa.push(items);
        }
      });
    });
    setAddWorkOrder({
      show: true,
      type: "servicBatchDispatchOrder",
      data: aaa,
    });
  };

  // 表格列显示
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      width: 50,
      ellipsis: { showTitle: false },
    },
    {
      title: "车牌号",
      dataIndex: "plateNumber",
      width: 110,
      ellipsis: { showTitle: false },
      // 王美丽新增 悬浮车牌号显示离线天数
      render: (value: string, item: any) => (
        <div
          style={{ color: "#1890ff", cursor: "pointer" }}
          onClick={() => skipCarDiagnosis(value)}
        >
          <Tooltip placement="topLeft" title={item.onlineState}>
            {value}
          </Tooltip>
        </div>
      ),
    },
    {
      title: "任务类型",
      dataIndex: "type",
      width: 100,
    },
    {
      title: "分配客服",
      dataIndex: "assignCustomer",
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: "任务状态",
      dataIndex: "taskState",
      width: 180,
      ellipsis: { showTitle: false },
      render: (value: any) => {
        return value == 0 ? (
          <Tag>未处理</Tag>
        ) : value == 1 ? (
          <Tag>处理中-待呼叫</Tag>
        ) : value == 2 ? (
          <Tag>处理中-已呼叫</Tag>
        ) : value == 3 ? (
          <Tag>处理中-已创建工单</Tag>
        ) : value == 4 ? (
          <Tag>处理中-已派工单</Tag>
        ) : value == 5 ? (
          <Tag>处理中-已完成现场处理</Tag>
        ) : value == 6 ? (
          <Tag>处理完成</Tag>
        ) : value == 7 ? (
          <Tag>已退回</Tag>
        ) : value == 8 ? (
          <Tag>已关闭</Tag>
        ) : value == 9 ? (
          <Tag>处理中-待处理</Tag>
        ) : value == 10 ? (
          <Tag>协助车辆</Tag>
        ) : null;
      },
    },
    {
      title: "在线状态",
      dataIndex: "onlineState",
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "主问题",
      dataIndex: "masterProblem",
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "次问题",
      dataIndex: "slaveProblem",
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车辆情况说明",
      dataIndex: "ex",
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "负责人",
      dataIndex: "linkman",
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: "负责人电话",
      dataIndex: "tel",
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: "司机",
      dataIndex: "carDriver",
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: "司机电话",
      dataIndex: "carDriverPhone",
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: "渠道",
      dataIndex: "channel",
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "所属公司",
      dataIndex: "company",
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车辆地址",
      dataIndex: "district",
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车架号",
      dataIndex: "frameNumber",
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "保司",
      dataIndex: "insurer",
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },

    {
      title: "影像",
      dataIndex: "resourceViewDtos",
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        <Thumbnail
          dataSource={value || []}
          downloadImgName="图片"
          downloadVideoName="视频"
        />
      ),
    },
    {
      title: "备注",
      dataIndex: "remark",
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "任务创建人",
      dataIndex: "creator",
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : value,
    },
    {
      title: "客服处理人",
      dataIndex: "dealCustomer",
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: "处理时间",
      dataIndex: "beginDealTime",
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : value,
    },
    {
      title: "期望完成时间",
      dataIndex: "expectedTime",
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : value,
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 240,
      fixed: "right" as "right",
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <>
              <PermissionButton
                operateType="/car-admin/car-diagnosis/service-recoed-all"
                type="link"
                onClick={() => setOperateInfo({ type: "record", data: item })}
              >
                客服记录
              </PermissionButton>
            </>
            {/* <Popconfirm
              title="您确定要删除该任务吗?"
              onConfirm={() => deleteCarInterviewItem(item.id)}
            >
              <PermissionButton operateType='/car-admin/service-task/delete' type="link">删除</PermissionButton>
            </Popconfirm> */}
            {/* {item?.onePersonFlag == 0 ? <div>
              <PermissionButton operateType='/car-admin/car-diagnosis/service-recoed-all'  type="link" onClick={() => setOperateInfo({ type: 'record', data: item })}>客服记录</PermissionButton>
            </div> : null} */}
            {item.taskState == 0 ? (
              <>
                <PermissionButton
                  operateType="/car-admin/service-task-dispose/comment"
                  type="link"
                  onClick={() => setOperateInfo({ type: "detail", data: item })}
                >
                  评论
                </PermissionButton>
                <Popconfirm
                  title="您确定要接收此任务吗?"
                  onConfirm={() => confireItem(item)}
                >
                  <PermissionButton
                    operateType="/car-admin/service-task-dispose/confire"
                    type="link"
                  >
                    接收任务
                  </PermissionButton>
                </Popconfirm>
                {/* <Popconfirm
                title="您确定要拒绝此任务吗?
                onConfirm={() => refuseItem(item)}>
                <PermissionButton operateType='/car-admin/service-task-dispose/refuse' type="link">拒绝任务</PermissionButton>
              </Popconfirm> */}
                <PermissionButton
                  operateType="/car-admin/service-task-dispose/refuse"
                  type="link"
                  onClick={() =>
                    setOperateInfo({
                      type: "sendback",
                      data: item,
                    })
                  }
                >
                  拒绝任务
                </PermissionButton>
              </>
            ) : item.taskState == 1 ? (
              <>
                <PermissionButton
                  operateType="/car-admin/service-task-dispose/comment"
                  type="link"
                  onClick={() => setOperateInfo({ type: "detail", data: item })}
                >
                  评论
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/car-diagnosis/service-recoed-add"
                  type="link"
                  onClick={() =>
                    setOperateVisible({
                      show: true,
                      type: "serviceTaskDisposeAdd",
                      data: item,
                    })
                  }
                >
                  新增客服记录
                </PermissionButton>
              </>
            ) : item.taskState == 10 ? (
              <>
                <PermissionButton
                  operateType="/car-admin/service-task-dispose/comment"
                  type="link"
                  onClick={() => setOperateInfo({ type: "detail", data: item })}
                >
                  评论
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/car-diagnosis/service-recoed-add"
                  type="link"
                  onClick={() =>
                    setOperateVisible({
                      show: true,
                      type: "serviceTaskDisposeAdd",
                      data: item,
                    })
                  }
                >
                  新增客服记录
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/car-diagnosis/service-recoed-edit"
                  type="link"
                  onClick={() =>
                    setOperateVisible({
                      show: true,
                      type: "serviceTaskDisposeEdit",
                      data: item,
                    })
                  }
                >
                  编辑上条客服记录
                </PermissionButton>
                {/* <PermissionButton
                  operateType="/car-admin/car-diagnosis/service-recoed-history"
                  type="link"
                  onClick={() =>
                    setOperateInfo({
                      type: "history",
                      data: item,
                    })
                  }
                >
                  历史联系结果
                </PermissionButton> */}
              </>
            ) : item.taskState == 9 ? (
              <>
                <PermissionButton
                  operateType="/car-admin/service-task-dispose/comment"
                  type="link"
                  onClick={() => setOperateInfo({ type: "detail", data: item })}
                >
                  评论
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/car-diagnosis/service-recoed-add"
                  type="link"
                  onClick={() =>
                    setOperateVisible({
                      show: true,
                      type: "serviceTaskDisposeAdd",
                      data: item,
                    })
                  }
                >
                  新增客服记录
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/car-diagnosis/service-recoed-edit"
                  type="link"
                  onClick={() =>
                    setOperateVisible({
                      show: true,
                      type: "serviceTaskDisposeEdit",
                      data: item,
                    })
                  }
                >
                  编辑上条客服记录
                </PermissionButton>
                {/* <PermissionButton
                  operateType="/car-admin/car-diagnosis/service-recoed-history"
                  type="link"
                  onClick={() =>
                    setOperateInfo({
                      type: "history",
                      data: item,
                    })
                  }
                >
                  历史联系结果
                </PermissionButton> */}
              </>
            ) : item.taskState == 2 ? (
              <>
                <PermissionButton
                  operateType="/car-admin/service-task-dispose/comment"
                  type="link"
                  onClick={() => setOperateInfo({ type: "detail", data: item })}
                >
                  评论
                </PermissionButton>
                <PermissionButton
                  operateType="/car-admin/service-task-dispose/orderId"
                  type="link"
                  onClick={() =>
                    setAddWorkOrder({
                      type: "servicrTaskAdd",
                      data: item,
                    })
                  }
                >
                  派工单
                </PermissionButton>
                <Popconfirm
                  title="确认是否处理完成?"
                  onConfirm={() => finishedWork(item)}
                >
                  <PermissionButton
                    operateType="/car-admin/service-task-dispose/finished"
                    type="link"
                  >
                    处理完成
                  </PermissionButton>
                </Popconfirm>
              </>
            ) : (
              <PermissionButton
                operateType="/car-admin/service-task-dispose/comment"
                type="link"
                onClick={() =>
                  setOperateInfo({
                    type: "detail",
                    data: item,
                  })
                }
              >
                评论
              </PermissionButton>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Collapse defaultActiveKey={"column"}>
        <Collapse.Panel header="搜索参数" key="column">
          <Form
            onFinish={onSearch}
            form={form}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="创建日期" name="time">
                  <RangePicker allowClear={true} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="客服处理日期" name="disTime">
                  <RangePicker allowClear={true} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="车牌号" name="plateNumber">
                  <Input
                    placeholder="请输入车牌号"
                    allowClear
                    onChange={(e) => {
                      const newV = e.target.value.replace(/\s+/g, "");
                      form.setFieldValue("plateNumber", newV);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="车架号" name="frameNumber">
                  <Input
                    placeholder="请输入车架号"
                    allowClear
                    onChange={(e) => {
                      const newV = e.target.value.replace(/\s+/g, "");
                      form.setFieldValue("frameNumber", newV);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="所属公司" name="company">
                  <Input placeholder="请输入所属公司" allowClear />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="渠道" name="channel">
                  <Input placeholder="请输入渠道" allowClear />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="联系人电话" name="tel">
                  <Input placeholder="请输入联系人电话" allowClear />
                </Form.Item>
              </Col>
              <Col span={5}>
                {/* 王美丽新增 需确认字段名称name */}
                <Form.Item label="任务状态" name="state">
                  <Select
                    options={stateData}
                    placeholder="请选择任务状态"
                    showSearch
                    mode="multiple"
                    showArrow
                    maxTagCount="responsive"
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="任务类型" name="type">
                  <Select
                    options={serveTypeList}
                    placeholder="请选择任务类型"
                    showSearch
                    mode="multiple"
                    showArrow
                    maxTagCount="responsive"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="分配客服" name="assignCustomer">
                  <Input placeholder="请输入分配客服" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="期望完成日期" name="expectTime">
                  <RangePicker allowClear={true} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item>
                  <div
                    className={styles.searchBox}
                    style={{ marginTop: "32px" }}
                  >
                    <Button type="primary" htmlType="submit">
                      搜索
                    </Button>
                    <ExportButton
                      url={serviceTaskListRequestPath}
                      fileName="客服任务"
                      getParamsFunc={getExportParams}
                      requestMethod="GET"
                      permissionKey="/car-admin/service-task-dispose/export"
                    />
                    <PermissionButton
                      operateType="/car-admin/car-diagnosis/service-recoed/add-batch"
                      type="primary"
                      onClick={addserviceRecodeBatch}
                    >
                      批量新增客服记录
                    </PermissionButton>
                    <PermissionButton
                      operateType="/car-admin/car-diagnosis/service-recoed/work-order"
                      type="primary"
                      onClick={distributeBatch}
                      style={{ margin: "0 0px 0px 8px" }}
                    >
                      批量建工单
                    </PermissionButton>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        rowSelection={rowSelection}
        scroll={{ y: "100%" }}
      />
      {/* 创建工单的弹窗 */}
      {[
        "add",
        "edit",
        "servicrAdd",
        "servicrTaskAdd",
        "servicBatchDispatchOrder",
      ].includes(addWorkOrder?.type) && (
        <OperateModal
          type={addWorkOrder.type}
          workOrderInfo={addWorkOrder.data}
          onClose={() => setAddWorkOrder({})}
          reloadData={() => getData()}
          workId={getWorkOrderId}
        />
      )}
      {/* 评论 */}
      {operateInfo?.type === "detail" && (
        <TaskDetail
          item={operateInfo.data}
          onClose={() => taskCommentDetail()}
        />
      )}
      {/* 添加客服记录的弹窗 */}
      {operateVisible.show && (
        <OperateService
          type={operateVisible.type}
          onClose={() => setOperateVisible({ show: false, type: "add" })}
          reloadData={() => getData()}
          data={operateVisible.data}
          anyData={anyData}
        />
      )}
      {/* 历史联系结果 */}
      {operateInfo.type === "history" && (
        <HistoryResult
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {/* 客服记录 */}
      {operateInfo.type === "record" && (
        <AllServiceRecord
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {/* 添加退回原因（即留言） */}
      {operateInfo?.type === "sendback" && (
        <SendBackCause
          id={operateInfo.data}
          onClose={() => setOperateInfo({})}
          reloadData={getData}
        />
      )}
    </Card>
  );
};

export default ServiceTaskDispose;
