/**
 * 操作保前查勘记录
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Radio, DatePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { upsertCarExplorationRequest } from '@/service/carExploration';
import { formatTime } from '@/utils/formatTime';
import UploadImageVideo from '@/components/uploadImageVideo';

const radioData = [
  { label: '是', value: '是' },
  { label: '否', value: '否' },
]
interface OperateModalProps {
  data?: any;
  type?: string;
  closeModal: () => void;
  reloadData: () => void;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { data, type, closeModal, reloadData } = props;
  const [uploadResources, setUploadResources] = useState<any>([
    {
      order: 1,
      fileName: '查勘表',
    },
    {
      order: 2,
      fileName: '车头照（安装前审核）',
    },
    {
      order: 3,
      fileName: '前保险杠照片',
    },
    {
      order: 4,
      fileName: '正后方照片',
    },
    {
      order: 5,
      fileName: '左前45°照片',
    },
    {
      order: 6,
      fileName: '右前45°照片',
    },
    {
      order: 7,
      fileName: '左后45°照片',
    },
    {
      order: 8,
      fileName: '右后45°照片',
    },
    {
      order: 9,
      fileName: '信号灯视频',
    },
    {
      order: 10,
      fileName: '无漏气视频',
    },
    {
      order: 11,
      fileName: '右侧外饰板照片',
    },
    {
      order: 12,
      fileName: '气路照片',
    },
    {
      order: 13,
      fileName: '仪表盘照片',
    },
    {
      order: 14,
      fileName: '人车合影',
    },
    {
      order: 15,
      fileName: '行驶证',
    },
  ]); // 上传成功的资源

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      const params: any = { ...data };
      params.date = dayjs(data.date)
      params.firstRegistrationDate = dayjs(data.firstRegistrationDate)
      params.checkDate = dayjs(data.checkDate)
      setUploadResources(JSON.parse(data.photos))
      form.setFieldsValue(params);
    }
  }, []);

  // 上传改变的方法
  const uploadFilesChange = (file: any, index: number) => {
    const newUploadResources = [...uploadResources];
    if (file.length > 0) {
      newUploadResources[index] = {...file[0], ...uploadResources[index]}
    } else {
      // 兼容删除处理
      newUploadResources[index] = { order: uploadResources[index].order, fileName: uploadResources[index].fileName }
    }
    setUploadResources(newUploadResources);
  }

  // 提交
  const submit = async (values: any) => {
    const params: any = { ...values };
    params.date = formatTime(values.date, 'YYYY-MM-DD')
    params.firstRegistrationDate = formatTime(values.firstRegistrationDate, 'YYYY-MM-DD')
    params.checkDate = formatTime(values.checkDate, 'YYYY-MM-DD')
    if (type === 'edit') {
      params.id = data.id;
    }
    const unUpload = uploadResources.find((item: any) => !item.key)
    if (unUpload) {
      message.warning(`请上传${unUpload?.fileName}`);
      return;
    }
    params.resources = uploadResources;
    const res = await upsertCarExplorationRequest(params);
    if (res.data?.code === 0) {
      message.success(type === 'edit' ? '修改成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };


  return (
    <Modal
      title={type === 'add' ? '添加记录' : '编辑记录'}
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <Form form={form} autoComplete="off" onFinish={submit} layout="vertical">
        <Form.Item
          label="车牌号"
          name="plateNumber"
          // rules={[{ required: true, message: '请输入车牌号' }]}
        >
          <Input placeholder='请输入车牌号' onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
        </Form.Item>
        <Form.Item
          label="业务模式"
          name="mode"
          // rules={[{ required: true, message: '请输入业务模式' }]}
        >
          <Input placeholder='请输入业务模式' />
        </Form.Item>
        <Form.Item
          label="车辆所有人"
          name="company"
          // rules={[{ required: true, message: '请输入车辆所有人' }]}
        >
          <Input placeholder='请输入车辆所有人' />
        </Form.Item>
        <Form.Item
          label="配单日期"
          name="date"
          // rules={[{ required: true, message: '请输入配单日期' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="承保保司"
          name="insurer"
          // rules={[{ required: true, message: '请输入承保保司' }]}
        >
          <Input placeholder='请输入承保保司' />
        </Form.Item>
        <Form.Item
          label="需求群信息"
          name="groupInfo"
          // rules={[{ required: true, message: '请输入需求群信息' }]}
        >
          <Input placeholder='请输入需求群信息' />
        </Form.Item>
        <Form.Item
          label="查勘联系电话"
          name="tel"
          // rules={[{ required: true, message: '请输入查勘联系电话' }]}
        >
          <Input placeholder='请输入查勘联系电话' />
        </Form.Item>
        <Form.Item
          label="评分"
          name="score"
          // rules={[{ required: true, message: '请输入评分' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} placeholder='请输入评分' />
        </Form.Item>
        <Form.Item
          label="评级"
          name="grade"
          // rules={[{ required: true, message: '请输入评级' }]}
        >
          <Input placeholder='请输入评级' />
        </Form.Item>
        <Form.Item
          label="初登日期"
          name="firstRegistrationDate"
          // rules={[{ required: true, message: '请输入初登日期' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="出险次数"
          name="riskCount"
          // rules={[{ required: true, message: '请输入出险次数' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} placeholder='请输入出险次数' />
        </Form.Item>
        <Form.Item
          label="NCD系数"
          name="ncd"
          // rules={[{ required: true, message: '请输入NCD系数' }]}
        >
          <Input placeholder='请输入NCD系数' />
        </Form.Item>
        <Form.Item
          label="出险金额(元)"
          name="fee"
          // rules={[{ required: true, message: '请输入出险金额(元)' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} placeholder='请输入出险金额(元)' />
        </Form.Item>
        <Form.Item
          label="个车赔付率"
          name="carLossRation"
          // rules={[{ required: true, message: '请输入车队整体赔付率' }]}
        >
          <Input placeholder='请输入车队整体赔付率' />
        </Form.Item>
        <Form.Item
          label="车队整体赔付率"
          name="companyLossRation"
          // rules={[{ required: true, message: '请输入车队整体赔付率' }]}
        >
          <Input placeholder='请输入车队整体赔付率' />
        </Form.Item>
        <Form.Item
          label="合同状态"
          name="contractState"
          // rules={[{ required: true, message: '请输入合同状态' }]}
        >
          <Input placeholder='请输入合同状态' />
        </Form.Item>
        <Form.Item
          label="设备状态"
          name="deviceState"
          // rules={[{ required: true, message: '请输入设备状态' }]}
        >
          <Input placeholder='请输入设备状态' />
        </Form.Item>
        <Form.Item
          label="承保类型"
          name="insureType"
          // rules={[{ required: true, message: '请输入承保类型' }]}
        >
          <Input placeholder='请输入承保类型' />
        </Form.Item>
        <Form.Item
          label="查勘日期"
          name="checkDate"
          // rules={[{ required: true, message: '请输入查勘日期' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="查勘人员"
          name="checker"
          // rules={[{ required: true, message: '请输入查勘人员' }]}
        >
          <Input placeholder='请输入查勘人员' />
        </Form.Item>
        <Form.Item
          label="实控人公司名称"
          name="practicalOwnerCompany"
          // rules={[{ required: true, message: '请输入实控人公司名称' }]}
        >
          <Input placeholder='请输入实控人公司名称' />
        </Form.Item>
        <Form.Item
          label="查勘地点"
          name="address"
          // rules={[{ required: true, message: '请输入查勘地点' }]}
        >
          <Input placeholder='请输入查勘地点' />
        </Form.Item>
        <Form.Item
          label="使用情况"
          name="useInfo"
          // rules={[{ required: true, message: '请输入使用情况' }]}
        >
          <Input placeholder='请输入使用情况' />
        </Form.Item>
        <Form.Item
          label="小额案件是否自修情况"
          name="smallCaseDeal"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="是否挂靠"
          name="attachFlag"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="是否固定司机"
          name="fixedDriverFlag"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="是否固定线路"
          name="fixedLineFlag"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="是否专人管理"
          name="fixedManagerFlag"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="是否具有培训"
          name="trainFlag"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="使用频次"
          name="frequency"
          // rules={[{ required: true, message: '请输入使用频次' }]}
        >
          <Input placeholder='请输入使用频次' />
        </Form.Item>
        <Form.Item
          label="查勘时里程"
          name="beginMileage"
          // rules={[{ required: true, message: '请输入查勘时里程' }]}
        >
          <Input placeholder='请输入查勘时里程' />
        </Form.Item>
        <Form.Item
          label="用途"
          name="purpose"
          // rules={[{ required: true, message: '请输入用途' }]}
        >
          <Input placeholder='请输入用途' />
        </Form.Item>
        <Form.Item
          label="车牌是否有改装"
          name="plateRefitFlag"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="是否是空车且可上路测试"
          name="carTestFlag"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={radioData} />
        </Form.Item>
        <Form.Item
          label="查勘结果"
          name="result"
          // rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group options={[
            { label: '通过', value: '通过' },
            { label: '不通过', value: '不通过' },
          ]} />
        </Form.Item>
        <Form.Item
          label="任务状态"
          name="taskState"
          // rules={[{ required: true, message: '请输入任务状态' }]}
        >
          <Input placeholder='请输入任务状态' />
        </Form.Item>
        {uploadResources?.map((item: any, index: number) => (
          <Form.Item label={item.fileName}>
            <UploadImageVideo
              businessName="保前查勘"
              uploadFilesChange={(successList: any) =>
                uploadFilesChange(successList, index)
              }
              defaultUploadFiles={
                data?.photos ?
                  [{...JSON.parse(data.photos).find((item1: any) => item1.order === item.order), preview: data.resources[index]?.url}] :
                  []
              }
              maxCount={1}
            />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default OperateModal;
