/**
 * 生成报告
 */
import React from 'react';
import { Modal, Form, Radio, Button } from 'antd';
import CarSelect from '@/components/carSelect';
import FleetSelect from '@/components/fleetSelect';
import DatePicker from '@/components/datePicker';
import ExportButton from '@/components/exportButton';
import { formatTime } from '@/utils/formatTime';
import { accidentReportRequestPath } from '@/service/accidentRecord';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
interface ReportModalProps {
  closeModal: () => void;
}
const ReportModal = (props: ReportModalProps) => {
  const { closeModal } = props;
  const [form] = Form.useForm();

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const params = {...values};
    delete params.time;
    delete params.type;
    params.beginDate = formatTime(values.time[0], 'YYYY-MM-DD');
    params.endDate = formatTime(values.time[1], 'YYYY-MM-DD');
    return params;
  }

  return (
    <Modal
      title="生成报告"
      visible
      onCancel={closeModal}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={closeModal}>取消</Button>,
        <ExportButton
          key="ok"
          type="primary"
          url={accidentReportRequestPath}
          fileName='出险报告'
          getParamsFunc={getExportParams}
          requestMethod="GET"
          fileSuffix='docx'
          exportSuccessFunc={closeModal}
          permissionKey='/car-admin/accident-record/export'
        >
          导出报告
        </ExportButton>
      ]}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
      >
        <Form.Item label="报告类型" name="type" initialValue="plateNumber">
          <Radio.Group>
            <Radio value='plateNumber'>单车报告</Radio>
            <Radio value='motorcade'>车队报告</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const formValues = form.getFieldsValue();
            if (formValues.type === 'plateNumber') {
              return (
                <Form.Item
                  label="车牌号"
                  name="plateNumber"
                  rules={[{ required: true, message: '请选择车牌号' }]}
                >
                  <CarSelect valueField='plate_number' />
                </Form.Item>
              )
            } else {
              return (
                <Form.Item
                  label="车队"
                  name="motorcade"
                  rules={[{ required: true, message: '请选择车队' }]}
                >
                  <FleetSelect valueField="label" />
                </Form.Item>
              )
            }
          }}
        </Form.Item>
        <Form.Item
          label="时间区间"
          name="time"
          rules={[{ required: true, message: '请选择时间区间' }]}
          initialValue={[dayjs().subtract(1, 'years'), dayjs()]}
        >
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReportModal;
    