import store from '@/store';
import { message } from 'antd';
import { collapsedKeys } from '@/constants/common';
// 处理菜单权限数据(页面内部按钮级别权限可以正常显示)
export const operateMenuData = (innerData: any) => {
  const newData: any = [];
  innerData.forEach((item: any) => {
    const { permissions, children } = item;
    const obj: any = {
      ...item,
      children: permissions?.length ? permissions.map((item1: any) => ({
        ...item1,
        title: item1.displayName,
        key: item1.permissionKey,
        allPermissions: permissions,
      })) : null,
    }
    if (Array.isArray(children) && children.length > 0) {
      const tempData = operateMenuData(item.children);
      obj.children = tempData?.length ? tempData : null;
    }
    newData.push(obj);
  });
  return newData;
}

// 获取菜单权限所有的key
export const getAllMenuKeys = (data: any) => {
  if (Array.isArray(data) && data.length > 0) {
    const allMenuKeys: any = [];
    const deep = (innerData: any) => {
      innerData.forEach((item: any) => {
        if (item.permissions?.length) {
          item.permissions.forEach((item1: any) => {
            allMenuKeys.push(item1.permissionKey);
          })
        }
        if (Array.isArray(item.children) && item.children.length > 0) {
          deep(item.children);
        }
        if (!item.children?.length && !item.permissions?.length) {
          allMenuKeys.push(item.key);
        }
      });
    };
    deep(data);
    return allMenuKeys;
  } else {
    return [];
  }
}

// 根据选择的key生成菜单tree数据
export const filterMenuData = (innerData: any, selectKeys: any) => {
  const newData: any = [];
  innerData.forEach((item: any) => {
    const { permissions, children } = item;
    const obj: any = {
      ...item,
      permissions: permissions.filter((item1: any) => selectKeys.includes(item1.permissionKey))
    }
    if (Array.isArray(children) && children.length > 0) {
      const tempData = filterMenuData(item.children, selectKeys);
      obj.children = tempData?.length ? tempData : null;
    }
    if (selectKeys.includes(obj.key) || Boolean(obj.children) || Boolean(obj.permissions?.length)) {
      newData.push(obj);
    }
  });
  return newData;
}

// 菜单的选择
export const onMenuSelect = (
  key: string,
  title: string,
  toggleCollapsed: any
) => {
  const {
    userStore: { userDetail },
    tabPagesStore: { tabPages }
  }: { userStore: any; tabPagesStore: any } = store.getState();
  if (key === '/car-admin/datav') {
    if (userDetail.datavUrl) {
      // 点击新开页面
      window.open(userDetail.datavUrl);
    } else {
      message.warning('暂无链接');
    }
  } else {
    // 收起左侧menu
    if (collapsedKeys.includes(key)) {
      toggleCollapsed(true);
    }

    if (tabPages.find((tabItem: any) => tabItem.key === key)) {
      // 修改激活key
      store.dispatch({
        type: 'changeActive',
        payload: {
          key
        }
      });
    } else {
      // 添加tab
      store.dispatch({
        type: 'addTab',
        payload: {
          tab: title,
          key
        }
      });
    }
  }
};
