/**
 * 图表弹窗
 */
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { chartModalCompanyRequest, chartModalPlateNumberRequest, chartModalMaintainerRequest } from '@/service/questionnaireSurveyTotal';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
interface ChartModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  searchParams: any; // 异常汇总的搜索参数
}
const ChartModal = (props: ChartModalProps) => {
  const { data, closeModal, searchParams } = props;
  const [chartData, setChartData] = useState<any>([]); // 建议

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    const params = {...searchParams}
    if (data == 'company') {
      const res = await chartModalCompanyRequest(params);
      const chartData = [{name:'低评分',value:Number(res.data?.data?.min)},{name:'高评分',value:Number(res.data?.data?.max)}]
      const option = {
        title: {
          left: 'center',
          text: '评分情况汇总',
        },
        tooltip: { trigger: 'item' },
        legend: { orient: 'vertical', left: 'left' },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      const chart = echarts.init(document.getElementById('chartModal') as HTMLElement)
      chart.setOption(option, true);
    } else if (data == 'plateNumber') {
      const res = await chartModalPlateNumberRequest(params);
      const chartData = [{name:'低评分',value:Number(res.data?.data?.min)},{name:'高评分',value:Number(res.data?.data?.max)}]
      const option = {
        title: {
          left: 'center',
          text: '评分情况汇总',
        },
        tooltip: { trigger: 'item' },
        legend: { orient: 'vertical', left: 'left' },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      const chart = echarts.init(document.getElementById('chartModal') as HTMLElement)
      chart.setOption(option, true);
    } else if (data == 'maintainer') {
      const res = await chartModalMaintainerRequest(params);
      const chartData = [{name:'低评分',value:Number(res.data?.data?.min)},{name:'高评分',value:Number(res.data?.data?.max)}]
      const option = {
        title: {
          left: 'center',
          text: '评分情况汇总',
        },
        tooltip: { trigger: 'item' },
        legend: { orient: 'vertical', left: 'left' },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      const chart = echarts.init(document.getElementById('chartModal') as HTMLElement)
      chart.setOption(option, true);
    }
  }

  return (
    <Modal
      title="图表趋势"
      visible
      footer={null}
      onCancel={closeModal}
      width='70%'
      centered
    >
      <div id="chartModal" style={{ height: '400px' }} />
    </Modal>
  )
}

export default ChartModal;