/**
 * 车辆诊断设备参数变更表格信息(昆仑山设备)
 */
import React, { useState, useEffect, Fragment } from 'react';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import {
  carDiagnosisParamsRequest,
  carDiagnosisParamsRequestPath
} from '@/service/carDiagnosis';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { getKlsAebParamsColumns } from '@/columns/klsAebParams';

interface KlsParamsTableProps {
  diagnosisSearchParams: any;
  getExportParams: () => any;
  isKlsV3: boolean;
}

const KlsParamsTable = (props: KlsParamsTableProps) => {
  const { diagnosisSearchParams, getExportParams, isKlsV3 } = props;
  const [searchParams, setSearchParams] = useState(diagnosisSearchParams);
  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    setSearchParams(diagnosisSearchParams);
  }, [diagnosisSearchParams]);

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carDiagnosisParamsRequest,
    initRequest: Boolean(diagnosisSearchParams),
    initPageSize: 40,
    searchParams
  });

  const columns = [
    {
      title: '上报时间',
      dataIndex: 'reportedTime',
      width: 80,
      render: (value: any) => (
        <RenderEllipsisColumn text={formatTime(value)} ellipsis />
      ),
      fixed: 'left' as const
    },
    ...getKlsAebParamsColumns(data, isKlsV3)
  ];

  return (
    <Fragment>
      <div style={{ margin: '12px 0', textAlign: 'right' }}>
        <ExportButton
          type="primary"
          disabled={!diagnosisSearchParams}
          url={carDiagnosisParamsRequestPath}
          fileName={`${diagnosisSearchParams?.plateNumber}设备参数变更`}
          getParamsFunc={getExportParams}
          requestMethod="GET"
          permissionKey="/car-admin/car-diagnosis/params-export"
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        scroll={{ x: '100%', y: 400 }}
      />
    </Fragment>
  );
};

export default KlsParamsTable;
