/**
 * 电子围栏
 */
import React, { useEffect, useRef, useState } from 'react';
import { message, Input, Pagination, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import NP from 'number-precision';
import setPng from '@/assets/image/set.png';
import {
  importAliMap,
  initMap,
  destroyAliMap,
  calcLatLngAngle,
  calcAnotherPoint
} from '@/utils/map';
import { getMiddleAngle, getMinMaxAngle } from '@/utils/math';
import { useDebounce } from '@/utils/ownHooks';
import { registerClipboard } from '@/utils/common';
import { defaultMaxRadius } from '@/constants/electronFence';
import LoadingWrapper from '@/components/loadingWrapper';
import PermissionButton from '@/components/permissionButton';
import {
  fenceAreaDetailRequest,
  upsertFencePointRequest,
  deleteFencePointRequest,
  publishFenceAreaRequest,
  batchUpsertFencePointRequest,
  lngLatTransformRequest
} from '@/service/electronFence';
import FirmwareAddModal from '@/pages/firmwareManage/components/addModal';
import DownloadModal from './components/downloadModal';
import FenceConfigModal from './components/fenceConfigModal';
import FenceAreaList from './components/fenceAreaList';
import styles from '@/pages/crossMark/index.module.scss';

const ElectronFence = () => {
  const [loading, setLoading] = useState(false); // loading
  const [selectFenceAreaId, setSelectFenceAreaId] = useState<any>(null); // 选择的围栏区域ID
  const [selectFenceAreaPointCount, setSelectFenceAreaPointCount] =
    useState<any>(null); // 选择的围栏区域的围栏个数
  const [selectFenceAreaName, setSelectFenceAreaName] = useState<any>(null); // 选择的围栏区域名称
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息
  const [abnormalLoading, setAbnormalLoading] = useState(false); // 查找未配置围栏的loading
  const [abnormalData, setAbnormalData] = useState<any>([]); // 未配置的电子围栏数据
  const [currentPage, setCurrentPage] = useState<number>(0); // 查找未配置围栏时当前定位到的个数
  const [transformLngLat, setTransformLngLat] = useState<string>(''); // 转换的经纬度
  const mapDomRef: any = useRef(null); // 渲染地图的DOM
  const mapRef: any = useRef(null); // 存储地图实例
  const fenceMapOverlayRef: any = useRef({}); // 存储电子围栏相关地图覆盖物
  const selectFenceAreaIdRef: any = useRef(null); // 选择的围栏区域ID
  const markerClustererRef: any = useRef(null); // 聚合点实例
  const drawEndFlagRef: any = useRef(true); // 添加围栏结束(防止按键多次触发多次)
  const fenceAreaListRef: any = useRef(); // 围栏区域列表组件ref
  // const lngLatMarkerRef: any = useRef(); // 显示经纬度的marker

  // 地图移动完后渲染地图范围内的围栏
  const mapMoveEnd = async () => {
    setLoading(true);
    if (selectFenceAreaIdRef.current) {
      clearMap();
      const res = await fenceAreaDetailRequest(selectFenceAreaIdRef.current);
      const fencePoints = res.data?.data?.points || [];
      setSelectFenceAreaPointCount(fencePoints.length);
      if (fencePoints.length) {
        // 以地图边界创建多边形用于判断围栏是否在地图边界内
        const mapRectangle = new window.AMap.Rectangle({
          bounds: mapRef.current.getBounds()
        });
        const points: any = [];
        fencePoints.forEach((item: any) => {
          const center = new window.AMap.LngLat(item.lng, item.lat);
          if (mapRectangle.contains(center)) {
            points.push({
              lnglat: center,
              fenceData: item
            });
          }
        });
        // 渲染每一个电子围栏
        const _renderClustererItem = (context: any) => {
          context.marker.setContent(' ');
          const {
            lng,
            lat,
            radius,
            id,
            headingAngleMin,
            headingAngleMax,
            electronicFenceConfigId,
            type
          } = context.data[0].fenceData;
          if (fenceMapOverlayRef.current[id]) {
            return;
          }
          const center = new window.AMap.LngLat(lng, lat);
          const circle = new window.AMap.Circle({
            center,
            radius,
            map: mapRef.current
          });
          circle.on('click', (e: any) => {
            circleMouseover(e);
          });
          // circle.on('mouseout', () => {
          //   circleMouseout()
          // })
          const isAbnormal = !electronicFenceConfigId && !type; // 是否为未配置围栏
          // 生成圆形编辑器
          const circleEditor = new window.AMap.CircleEditor(
            mapRef.current,
            circle,
            // 未配置围栏显示红色
            {
              editOptions: { fillColor: isAbnormal ? '#ff0000' : '#00B2D5' }
            }
          );
          // 根据最大最小角度计算航向角线的角度和角度浮动区间
          const { angle, angleRange } = getMiddleAngle(
            headingAngleMin,
            headingAngleMax
          );
          // 存储圆形编辑器
          fenceMapOverlayRef.current[id] = {
            circle,
            circleEditor,
            angle,
            angleRange
          };
          // 生成航向角相关内容
          generateAnglePolyline(center, radius, id);
          // 生成删除的marker
          generateDeleteMarker(center, id);
          // 生成设置动作的marker
          generateActionMarker(center, id);
          // 注册圆形编辑器编辑时的方法
          onCircleEditorChange(id);
          // 打开圆形编辑器
          circleEditor.open();
        };
        markerClustererRef.current = new window.AMap.MarkerCluster(
          mapRef.current,
          points,
          {
            renderMarker: _renderClustererItem
          }
        );
        // 点击聚合点增大地图缩放级别
        markerClustererRef.current.on('click', (e: any) => {
          // 点击聚合点时才进行地图缩放
          if (e.clusterData?.length > 1) {
            let curZoom = mapRef.current.getZoom();
            if (curZoom < 20) {
              curZoom += 2;
            }
            mapRef.current.setZoomAndCenter(curZoom, e.lnglat);
          }
        });
      }
    }
    setLoading(false);
  };

  const debounceMapMoveEnd = useDebounce(mapMoveEnd);

  // 监听N键按下时可以添加围栏
  const handleNPress = (event: any) => {
    if (
      event.code === 'KeyN' &&
      drawEndFlagRef.current &&
      selectFenceAreaIdRef.current
    ) {
      drawFence();
      drawEndFlagRef.current = false;
    }
  };

  // 初始化地图
  useEffect(() => {
    importAliMap(() =>
      initMap(mapDomRef, mapRef, 'top', () => {
        mapRef.current.on('moveend', debounceMapMoveEnd);
        mapRef.current.on('mapmove', debounceMapMoveEnd);
      })
    );
    document.addEventListener('keydown', handleNPress);
    registerClipboard('fenceCopyButton');
    return () => {
      destroyAliMap(mapDomRef.current, mapRef.current);
      document.removeEventListener('keydown', handleNPress);
    };
  }, []);

  // 清空地图和所有编辑器
  const clearMap = () => {
    markerClustererRef.current && markerClustererRef.current.setMap(null);
    markerClustererRef.current && markerClustererRef.current.setData([]);
    markerClustererRef.current = null;
    mapRef.current.clearMap();
    Object.values(fenceMapOverlayRef.current).forEach((editorGroup: any) => {
      editorGroup?.anglePolylineEditor?.destroy();
      editorGroup?.circleEditor?.destroy();
    });
    fenceMapOverlayRef.current = {};
  };

  // 添加电子围栏点标记
  const upsertFencePoint = async (data: any) => {
    try {
      const res = await upsertFencePointRequest({
        ...data,
        electronicFenceId: selectFenceAreaIdRef.current
      });
      const resData = res.data?.data;
      if (resData) {
        message.success('自动保存成功', 1);
      } else {
        message.error('自动保存失败，请重新调整围栏');
      }
      return resData;
    } catch (error) {
      return false;
    }
  };

  // 删除电子围栏点标记
  const deleteFencePoint = async (id: number) => {
    try {
      const res = await deleteFencePointRequest(id);
      const resData = res.data?.data;
      if (resData) {
        message.success('删除成功', 1);
      } else {
        message.error('删除失败');
      }
      return resData;
    } catch (error) {
      return false;
    }
  };

  // 移入圆圈显示经纬度
  const circleMouseover = async (e: any) => {
    const {
      target: {
        _opts: {
          center: { lng, lat }
        }
      }
    } = e;
    const res = await lngLatTransformRequest({ lng, lat });
    const transformLngLat = res.data?.data;
    setTransformLngLat(transformLngLat.join(', '));
    // lngLatMarkerRef.current = new window.AMap.Text({
    //   map: mapRef.current,
    //   position: new window.AMap.LngLat(lng, lat),
    //   text: `${transformLngLat.join(',')}`,
    //   offset: new window.AMap.Pixel(0, -40)
    // })
  };

  // 移出圆圈不显示经纬度
  // const circleMouseout = async() => {
  //   console.log('circleMouseout', lngLatMarkerRef.current);
  //   lngLatMarkerRef.current && mapRef.current.remove(lngLatMarkerRef.current);
  //   lngLatMarkerRef.current = null;
  // }

  // 获取选择的电子围栏的详细信息
  const getSelectFenceAreaInfo = () => {
    const fenceAreaList = fenceAreaListRef.current?.fenceAreaList || [];
    const { radius, headingAngleHalfRange } = fenceAreaList.find(
      (item: any) => item.id === selectFenceAreaIdRef.current
    );
    return {
      defaultMinRadius: radius,
      headingAngleHalfRange
    };
  };

  // 生成航向角线的编辑器
  const generateAnglePolylineEditor = (path: any, fenceId: number) => {
    // 绘制航向角的线
    const anglePolyline = new window.AMap.Polyline({
      map: mapRef.current,
      path,
      strokeOpacity: 1,
      strokeWeight: 4,
      showDir: true
    });
    // 航向角线的编辑器
    const anglePolylineEditor = new window.AMap.PolylineEditor(
      mapRef.current,
      anglePolyline,
      {
        midControlPoint: {
          radius: 0,
          clickable: false,
          cursor: 'auto'
        },
        controlPoint: {
          radius: 5.5,
          clickable: false,
          strokeWeight: 1,
          strokeOpacity: 0.6
        }
      }
    );
    // 调整航向角线时调整显示的角度
    anglePolylineEditor.on('adjust', () => {
      const {
        _opts: { path: adjustPath }
      } = anglePolylineEditor.getTarget();
      const { angleRange } = fenceMapOverlayRef.current[fenceId];
      const newAngle = calcLatLngAngle(mapRef, adjustPath[0], adjustPath[1]);
      const { headingAngleMin, headingAngleMax } = getMinMaxAngle(
        newAngle,
        angleRange
      );
      upsertFencePoint({
        headingAngleMin,
        headingAngleMax,
        id: fenceId
      });
      fenceMapOverlayRef.current[fenceId].angle = newAngle;
    });
    // 点击航向角线时删除节点的事件(重新绘制线避免线被删除)
    anglePolylineEditor.on('removenode', () => {
      const {
        _opts: { path: adjustPath }
      } = anglePolylineEditor.getTarget();
      fenceMapOverlayRef.current[fenceId].anglePolylineEditor?.destroy();
      mapRef.current.remove(fenceMapOverlayRef.current[fenceId].anglePolyline);
      generateAnglePolylineEditor(adjustPath, fenceId);
    });
    anglePolylineEditor.open();
    fenceMapOverlayRef.current[fenceId].anglePolyline = anglePolyline;
    fenceMapOverlayRef.current[fenceId].anglePolylineEditor =
      anglePolylineEditor;
  };

  // 生成航向角线相关内容
  const generateAnglePolyline = (
    center: any,
    radius: number,
    fenceId: number
  ) => {
    const { angle } = fenceMapOverlayRef.current[fenceId];
    // 获取航向角线的一个点经纬度
    const anglePolylineSecondPoint = calcAnotherPoint(
      center.lng,
      center.lat,
      radius,
      angle
    );
    generateAnglePolylineEditor([center, anglePolylineSecondPoint], fenceId);
  };

  // 圆形编辑器编辑时绑定的方法
  const onCircleEditorChange = (fenceId: number) => {
    const { circleEditor, deleteMarker, actionMarker } =
      fenceMapOverlayRef.current[fenceId];
    // 航向角的线的长度自适应圆的半径
    const _adjustAnglePolyline = (lngLat: any, radius: number) => {
      const { angle, anglePolyline, anglePolylineEditor } =
        fenceMapOverlayRef.current[fenceId];
      const anglePolylineSecondPoint = calcAnotherPoint(
        lngLat.lng,
        lngLat.lat,
        radius,
        angle
      );
      // 调整航向角折线
      anglePolyline.setPath([lngLat, anglePolylineSecondPoint]);
      anglePolylineEditor.close();
      anglePolylineEditor.setTarget(anglePolyline);
      anglePolylineEditor.open();
    };
    // 圆形编辑器编辑半径大于限制区间时重新生成圆形编辑器
    const _adjustCircleEditorRange = (adjustRadius: number) => {
      circleEditor.destroy();
      const { circle } = fenceMapOverlayRef.current[fenceId];
      circle.setRadius(adjustRadius); // 调整圆形半径
      // 生成新的圆形编辑器
      const newCircleEditor = new window.AMap.CircleEditor(
        mapRef.current,
        circle
      );
      newCircleEditor.open();
      // 存储圆形和圆形编辑器
      fenceMapOverlayRef.current[fenceId].circle = circle;
      fenceMapOverlayRef.current[fenceId].circleEditor = newCircleEditor;
      onCircleEditorChange(fenceId);
    };
    // 圆形覆盖物移动时调整相关marker的位置以及上报数据
    circleEditor.on('move', (moveEvent: any) => {
      const {
        lnglat,
        target: {
          _opts: { radius }
        }
      } = moveEvent;
      upsertFencePoint({
        id: fenceId,
        lng: lnglat.lng,
        lat: lnglat.lat
      });
      // 调整航向角线
      _adjustAnglePolyline(lnglat, radius);
      deleteMarker.setPosition(lnglat);
      actionMarker.setPosition(lnglat);
    });
    // 圆形覆盖物调整半径时上报数据
    circleEditor.on('adjust', (adjustEvent: any) => {
      const {
        radius,
        target: {
          _opts: { center }
        }
      } = adjustEvent;
      // 控制半径取值范围
      let adjustRadius = radius;
      const { defaultMinRadius } = getSelectFenceAreaInfo();
      if (radius < defaultMinRadius) {
        adjustRadius = defaultMinRadius;
        message.warning(`半径最小为${defaultMinRadius}`);
        _adjustCircleEditorRange(adjustRadius);
      }
      if (radius > defaultMaxRadius) {
        adjustRadius = defaultMaxRadius;
        message.warning(`半径最大为${defaultMaxRadius}`);
        _adjustCircleEditorRange(adjustRadius);
      }
      upsertFencePoint({ id: fenceId, radius: adjustRadius });
      // 调整航向角线
      _adjustAnglePolyline(center, adjustRadius);
    });
  };

  // 显示删除围栏的marker
  const generateDeleteMarker = (center: any, fenceId: number) => {
    const deleteMarker = new window.AMap.Marker({
      map: mapRef.current,
      position: center,
      icon: 'https://webapi.amap.com/images/destroy.png',
      offset: new window.AMap.Pixel(-7, 7)
    });
    deleteMarker.on('click', async () => {
      const deleteRes = await deleteFencePoint(fenceId);
      if (deleteRes) {
        const {
          circle,
          circleEditor,
          anglePolyline,
          anglePolylineEditor,
          actionMarker
        } = fenceMapOverlayRef.current[fenceId];
        // 销毁圆形编辑器
        circleEditor.destroy();
        // 销毁折线编辑器
        anglePolylineEditor.destroy();
        // 移除当前围栏的相关内容
        mapRef.current.remove([
          deleteMarker,
          circle,
          anglePolyline,
          actionMarker
        ]);
        // 删除存储的圆形、折线编辑器
        delete fenceMapOverlayRef.current[fenceId];
      }
    });
    fenceMapOverlayRef.current[fenceId].deleteMarker = deleteMarker;
  };

  // 显示配置动作的marker
  const generateActionMarker = (center: any, fenceId: number) => {
    const actionMarker = new window.AMap.Text({
      map: mapRef.current,
      position: center,
      icon: setPng,
      offset: new window.AMap.Pixel(7, -7)
    });
    actionMarker.on('click', () => {
      const { angle, angleRange } = fenceMapOverlayRef.current[fenceId];
      setOperateInfo({
        type: 'config',
        data: {
          fenceId,
          fenceAreaId: selectFenceAreaIdRef.current,
          angle,
          angleRange
        }
      });
    });
    fenceMapOverlayRef.current[fenceId].actionMarker = actionMarker;
  };

  // 选择围栏区域(地图中心定位到第一个电子围栏)
  const onFenceAreaChange = async (checked: boolean, fence: any) => {
    clearMap();
    setAbnormalData([]);
    if (checked) {
      setSelectFenceAreaId(fence.id);
      setSelectFenceAreaName(fence.name);
      selectFenceAreaIdRef.current = fence.id;
      const res = await fenceAreaDetailRequest(fence.id);
      const fencePoints = res.data?.data?.points || [];
      setSelectFenceAreaPointCount(fencePoints.length);
      if (fencePoints.length) {
        window.AMap.plugin(
          ['AMap.CircleEditor', 'AMap.PolylineEditor', 'AMap.MarkerCluster'],
          () => {
            const mapCenter = mapRef.current.getCenter();
            const mapZoom = mapRef.current.getZoom();
            const { lng: firstLng, lat: firstLat } = fencePoints[0];
            if (
              mapCenter.lng === firstLng &&
              mapCenter.lat === firstLat &&
              mapZoom === 18
            ) {
              // 触发地图移动后的逻辑
              mapMoveEnd();
            } else {
              // 地图定位到第一个点
              mapRef.current.setZoomAndCenter(18, [firstLng, firstLat]);
            }
          }
        );
      }
    } else {
      setSelectFenceAreaId(null);
      setSelectFenceAreaName(null);
      selectFenceAreaIdRef.current = null;
    }
  };

  // 绘制电子围栏
  const drawFence = () => {
    if (!drawEndFlagRef.current) {
      return;
    }
    window.AMap.plugin(
      [
        'AMap.MouseTool',
        'AMap.CircleEditor',
        'AMap.PolylineEditor',
        'AMap.MarkerCluster'
      ],
      () => {
        const circleMouseTool = new window.AMap.MouseTool(mapRef.current); // 鼠标工具(画圆模式)
        drawEndFlagRef.current = false;
        circleMouseTool.on('draw', async (event: any) => {
          drawEndFlagRef.current = true;
          const {
            obj: {
              _opts: { center, radius }
            }
          } = event;
          // 控制半径取值范围
          let adjustRadius = radius;
          // 不使用鼠标工具生成的圆，自行生成圆
          event.obj.destroy();
          const { defaultMinRadius, headingAngleHalfRange } =
            getSelectFenceAreaInfo();
          if (radius < defaultMinRadius) {
            adjustRadius = defaultMinRadius;
            message.warning(`半径最小为${defaultMinRadius}`);
          }
          if (radius > defaultMaxRadius) {
            adjustRadius = defaultMaxRadius;
            message.warning(`半径最大为${defaultMaxRadius}`);
          }
          const circle = new window.AMap.Circle({
            center,
            radius: adjustRadius,
            map: mapRef.current
          });
          circle.on('click', (e: any) => {
            circleMouseover(e);
          });
          // circle.on('mouseout', () => {
          //   circleMouseout()
          // })
          // 画圆后存储围栏信息
          const upsertId = await upsertFencePoint({
            lng: center.lng,
            lat: center.lat,
            headingAngleMin: 360 - headingAngleHalfRange,
            headingAngleMax: 0 + headingAngleHalfRange,
            radius: Number(adjustRadius)
          });
          if (upsertId) {
            // 生成圆形编辑器
            const circleEditor = new window.AMap.CircleEditor(
              mapRef.current,
              circle
            );
            // 存储圆形编辑器
            fenceMapOverlayRef.current[upsertId] = {
              circle,
              circleEditor,
              angle: 0,
              angleRange: headingAngleHalfRange
            };
            // 生成航向角相关内容
            generateAnglePolyline(center, adjustRadius, upsertId);
            // 生成删除的marker
            generateDeleteMarker(center, upsertId);
            // 生成设置动作的marker
            generateActionMarker(center, upsertId);
            // 注册圆形编辑器编辑时的方法
            onCircleEditorChange(upsertId);
            // 打开圆形编辑器
            circleEditor.open();
            // 关闭鼠标工具
            circleMouseTool.close();
          } else {
            // 存储围栏信息失败时清除画的内容
            circleMouseTool.close();
            mapRef.current.remove(circle);
          }
        });
        // 开启鼠标工具画圆模式
        circleMouseTool.circle();
      }
    );
  };

  // 发布
  const publishFenceArea = async () => {
    const res = await publishFenceAreaRequest(selectFenceAreaId);
    const resData = res?.data?.data;
    if (resData) {
      setOperateInfo({
        type: 'firmware',
        data: {
          type: 'fence',
          resourcePath: resData.preview,
          size: resData.fileSize,
          md5: resData.key
        }
      });
    }
  };

  // 下载围栏文件
  const downloadFenceArea = async () => {
    const res = await publishFenceAreaRequest(selectFenceAreaId);
    const fileUrl = res?.data?.data?.preview;
    if (fileUrl) {
      setOperateInfo({
        type: 'download',
        data: {
          url: fileUrl,
          name: selectFenceAreaName
        }
      });
    }
  };

  // 生成模拟数据
  const mockData = async () => {
    const lng = 117.232194;
    let lat = 38.970915;
    const data = [
      {
        electronicFenceId: selectFenceAreaId,
        lng,
        lat,
        radius: 60,
        headingAngleMin: 60,
        headingAngleMax: 90,
        type: 1,
        straight: 1,
        leftTurn: 1,
        rightTurn: 1,
        angle: 1,
        distance: 20
      }
    ];
    new Array(5000).fill(1).forEach((outerItem: number, outerIndex: number) => {
      lat = NP.plus(lat, outerIndex === 0 ? 0.0012 : 0.0006); // 新的纬度值
      new Array(4).fill(1).forEach((innerItem: number, innerIndex: number) => {
        const currentLng = NP.plus(lng, NP.times(0.0007, innerIndex));
        data.push({
          electronicFenceId: selectFenceAreaId,
          lng: currentLng,
          lat,
          radius: 30,
          headingAngleMin: 60,
          headingAngleMax: 90,
          type: 1,
          straight: 1,
          leftTurn: 1,
          rightTurn: 1,
          angle: 1,
          distance: 20
        });
      });
    });
    const res = await batchUpsertFencePointRequest(data);
    if (res.data?.data) {
      message.success('生成成功');
    }
  };

  // 快速定位
  const onLocation = (value: string) => {
    if (value) {
      window.AMap.plugin('AMap.Geocoder', function () {
        const geocoder = new window.AMap.Geocoder();
        geocoder.getLocation(value, (status: string, result: any) => {
          if (status === 'complete' && result.geocodes?.length) {
            const geocodes = result.geocodes;
            if (geocodes?.length) {
              mapRef.current.setCenter(geocodes?.[0]?.location);
            }
          } else {
            message.error('根据地址快速定位失败');
          }
        });
      });
    }
  };

  // 查找没有配置的电子围栏
  const findAbnormalFencePoint = async () => {
    try {
      setAbnormalLoading(true);
      const res = await fenceAreaDetailRequest(selectFenceAreaIdRef.current);
      const fencePoints = res.data?.data?.points || [];
      const abnormalFencePoint: any = [];
      fencePoints.forEach((item: any) => {
        const { electronicFenceConfigId, type, lng, lat } = item;
        if (!electronicFenceConfigId && !type) {
          abnormalFencePoint.push({
            lng,
            lat
          });
        }
      });
      setAbnormalData(abnormalFencePoint);
      setCurrentPage(0);
      setAbnormalLoading(false);
    } catch (error) {
      setAbnormalLoading(false);
    }
  };

  // 定位到具体的没有配置的电子围栏
  const onAbnormalFencePointChange = (page: number) => {
    setCurrentPage(page);
    const { lng, lat } = abnormalData[page - 1];
    mapRef.current.setZoomAndCenter(18, [lng, lat]);
  };

  return (
    <div className={styles.crossMark}>
      <FenceAreaList
        selectFenceAreaId={selectFenceAreaId}
        setSelectFenceAreaId={(id: null) => {
          setSelectFenceAreaId(id);
          selectFenceAreaIdRef.current = id;
        }}
        clearMap={() => {
          clearMap();
          setAbnormalData([]);
        }}
        onFenceAreaChange={onFenceAreaChange}
        ref={fenceAreaListRef}
        selectFenceAreaPointCount={selectFenceAreaPointCount}
      />
      <div className={styles.rightPart}>
        <div className={styles.title}>
          <Input.Search
            placeholder="输入地址可快速定位到位置"
            style={{ width: '280px' }}
            allowClear
            enterButton="定位"
            onSearch={onLocation}
          />
          <PermissionButton
            onClick={drawFence}
            disabled={!selectFenceAreaId}
            operateType="/car-admin/electron-fence/add-fence"
          >
            添加围栏
          </PermissionButton>
          {/* <PermissionButton
            onClick={publishFenceArea}
            type="primary"
            disabled={!selectFenceAreaId}
            operateType="/car-admin/electron-fence/publish-fence"
          >
            发布
          </PermissionButton> */}
          <PermissionButton
            onClick={downloadFenceArea}
            type="primary"
            disabled={!selectFenceAreaId}
            operateType="/car-admin/electron-fence/download-fence"
          >
            下载文件
          </PermissionButton>
          {/* <PermissionButton
            onClick={mockData}
            type="primary"
            disabled={!selectFenceAreaId}
          >
            生成模拟数据
          </PermissionButton> */}
          <PermissionButton
            onClick={findAbnormalFencePoint}
            type="primary"
            disabled={!selectFenceAreaId}
            loading={abnormalLoading}
          >
            查找未配置围栏
          </PermissionButton>
          {Boolean(abnormalData.length) && (
            <Pagination
              defaultCurrent={0}
              total={abnormalData.length}
              defaultPageSize={1}
              simple
              onChange={onAbnormalFencePointChange}
              current={currentPage}
            />
          )}
          {transformLngLat}
          <Button
            style={{ visibility: transformLngLat ? 'visible' : 'hidden' }}
            type="link"
            icon={<CopyOutlined />}
            data-clipboard-text={transformLngLat}
            id="fenceCopyButton"
          />
        </div>
        <LoadingWrapper spinning={loading} autoHeight>
          <div ref={mapDomRef} style={{ height: '100%' }} />
        </LoadingWrapper>
      </div>
      {operateInfo.type === 'firmware' && (
        <FirmwareAddModal
          fileData={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {operateInfo.type === 'download' && (
        <DownloadModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {/* 配置电子围栏 */}
      {operateInfo.type === 'config' && (
        <FenceConfigModal
          {...operateInfo.data}
          closeModal={() => setOperateInfo({})}
          configSuccessCallback={(angleRange: number, fenceId: number) => {
            fenceMapOverlayRef.current[fenceId].angleRange = angleRange;
            fenceMapOverlayRef.current[fenceId].circle.setOptions({
              fillColor: '#00B2D5'
            });
          }}
        />
      )}
    </div>
  );
};

export default ElectronFence;
