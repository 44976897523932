/**
 * 表格通用的操作列(联系方式、参数信息、版本信息)
 */
import React, { Fragment, useState } from 'react';
import { Button } from 'antd';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import PositionModal from '@/components/positionModal';
import styles from '@/styles/pageContent.module.scss';

interface CommonTableOperateColumnProps {
  operateInfoData: any; // 操作相关的数据传递
  showContact?: boolean; // 是否显示联系方式
  showPosition?: boolean; // 是否显示最近位置按钮
  style?: any; // 样式
}
const CommonTableOperateColumn = (props: CommonTableOperateColumnProps) => {
  const {
    operateInfoData,
    showContact = true,
    showPosition = false,
    style = {}
  } = props;
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息存储

  return (
    <Fragment>
      <div className={styles.tableOperateColumn} style={style}>
        {showContact && (
          <Button
            type="link"
            onClick={() =>
              setOperateInfo({ type: 'contact', data: operateInfoData })
            }
          >
            联系方式
          </Button>
        )}
        <Button
          type="link"
          onClick={() =>
            setOperateInfo({ type: 'params', data: operateInfoData })
          }
        >
          参数信息
        </Button>
        <Button
          type="link"
          onClick={() =>
            setOperateInfo({ type: 'version', data: operateInfoData })
          }
        >
          版本信息
        </Button>
        {showPosition && (
          <Button
            type="link"
            onClick={() =>
              setOperateInfo({ type: 'position', data: operateInfoData })
            }
          >
            最近位置
          </Button>
        )}
      </div>
      {/* 联系方式弹窗 */}
      {operateInfo.type === 'contact' && (
        <ContactModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {/* 参数信息弹窗 */}
      {operateInfo.type === 'params' && (
        <ParamsModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {/* 版本信息弹窗 */}
      {operateInfo.type === 'version' && (
        <VersionModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
      {/* 最近位置弹窗 */}
      {operateInfo.type === 'position' && (
        <PositionModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
    </Fragment>
  );
};

export default CommonTableOperateColumn;
