/**
 * 导出按钮(有操作权限控制)
 */
import React, { Fragment, useState } from 'react';
import { Button, message, Modal, Progress, ButtonProps } from 'antd';
import { omit } from 'lodash';
import qs from 'qs';
import localStorageUtil from '@/utils/localStorageUtil';
import { getInnerPermissionEnable } from '@/utils/permission';

interface ExportButtonProps extends ButtonProps {
  url: string, // 导出接口地址
  getParamsFunc: () => any, // 获取导出参数的方法
  fileName: string; // 导出文件名
  fileSuffix?: string; // 文件后缀
  progress?: boolean; // 是否分步下载多个文件
  requestMethod?: string; // 接口请求方法
  exportSuccessFunc?: () => void; // 导出成功之后调用的方法
  permissionKey: string, // 导出按钮的权限key
}
const ExportButton = (props: ExportButtonProps) => {
  const [loading, setLoading] = useState(false); // 导出按钮的loading效果
  const [visible, setVisible] = useState(false); // 下载进度弹窗的显示
  const [percent, setPercent] = useState(0); // 下载进度

  // 导出方法
  const exportFile = async (currentIndex: number) => {
    const { getParamsFunc, progress, requestMethod = 'POST', fileSuffix = "xlsx", exportSuccessFunc } = props;
    let { url, fileName } = props;
    console.log(url, fileName, '121313212313123123')
    setLoading(true);
    const params = await getParamsFunc();
    if (!params) {
      setLoading(false);
      return;
    }
    // GET请求拼接参数
    if (requestMethod === 'GET') {
      let urlParams = '';
      if (params) {
        urlParams = qs.stringify(params, { arrayFormat: 'brackets' });
      }
      if (urlParams) {
        url = `${url}?${urlParams}`;
      }
    }
    // 分步下载添加参数
    if (progress) {
      setVisible(true);
      params.shard = true;
      params.page = currentIndex;
    }
    const req = new XMLHttpRequest();
    req.open(requestMethod, url, true);
    req.responseType = 'blob';
    req.setRequestHeader('Content-Type', 'application/json');
    req.setRequestHeader('access_token', localStorageUtil.getItem('access_token'));
    req.onload = function () {
      if (req.status === 200) {
        let total: any = 1;
        let current: any = 1;
        if (progress) {
          // 分步下载特殊处理
          total = req.getResponseHeader("x-total-shards")
          current = req.getResponseHeader("x-current-shards")
          setPercent(Math.ceil(((current) / total) * 100));
          fileName = `${fileName}(${current}/${total})`;
        }
        const data = req.response;
        if (data.type === 'application/json') { // 针对导出excel失败之后的处理
          // 将blob转为json
          const reader = new FileReader();
          reader.readAsText(data, 'utf-8');
          reader.onload = function() {
            // @ts-ignore
            const parseObj = JSON.parse(reader.result);
            message.error(parseObj.msg || '导出失败');
          }
          setLoading(false);
          return;
        }
        const blob = new Blob([data]);
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = `${fileName}.${fileSuffix}`;
        a.href = blobUrl;
        a.click();
        if (progress) {
          // 分布下载特殊处理
          if (current === total) {
            setLoading(false);
            setVisible(false);
            setPercent(0);
            exportSuccessFunc && exportSuccessFunc();
            message.success('导出成功');
          } else {
            // 继续下载下一个文件
            exportFile(currentIndex + 1)
          }
        } else {
          setLoading(false);
          exportSuccessFunc && exportSuccessFunc();
          message.success('导出成功');
        }
      } else {
        message.error(req.status === 504 ? '请求超时' : '导出失败');
        setLoading(false);
      }
    };
    req.onerror = function(e) {
      console.log(e, '333333333333333333')
    }
    req.send(JSON.stringify(params));
  }
  
  // 是否有导出的权限
  const hasExportPermission = getInnerPermissionEnable(props.permissionKey);
  if (hasExportPermission) {
    const buttonOwnProps = omit(props, ['url', 'getParamsFunc', 'fileName', 'fileSuffix', 'progress', 'requestMethod', 'permissionKey']);
    buttonOwnProps.children = buttonOwnProps.children || '导出';
    return (
      <Fragment>
        <Button
          {...buttonOwnProps}
          onClick={() => exportFile(1)}
          loading={loading}
        >
        </Button>
        <Modal
          title="下载进度"
          visible={visible}
          footer={null}
          maskClosable={false}
          onCancel={() => setVisible(false)}
          centered
        >
          <Progress percent={percent} />
        </Modal>
      </Fragment>
    )
  } else {
    return null;
  }
}

export default ExportButton;
  