// 固件类型数据
export const firmwareType = [
  { label: '控制器', value: 'controller' },
  { label: '显示器', value: 'monitor' },
  { label: '摄像头', value: 'camera' },
  { label: 'bootloader', value: 'bootloader' },
  { label: '电子围栏', value: 'fence' },
  { label: 'AEB_MDVR_APP', value: 'AEB_MDVR_APP' },
  { label: 'MDVR_APP', value: 'MDVR_APP' },
  { label: 'KERNEL_IMAGE', value: 'KERNEL_IMAGE' }
];

// 固件分组数据
export const firmwareGroup = [
  { label: '测试组', value: 0 },
  { label: '生产组', value: 1 }
];
