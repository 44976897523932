/**
 * 车辆列表弹窗
 */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import { carListRequest } from '@/service/carManage';
import { snColumnConfig, cameraSnColumnConfig, cateGroupColumnConfig, frameNumberColumnConfig, carBrandColumnConfig, carModelColumnConfig } from '@/constants/tableColumn';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import PositionModal from '@/components/positionModal';
import CommonTable from '@/components/commonTable';
import styles from '@/styles/pageContent.module.scss';
import {useUserCateIdArr} from "@/utils/ownHooks";

interface CarListModalProps {
  desc?: any; // 描述信息
  searchValues: any; // 车辆搜索的数据
  closeModal: () => void; // 关闭弹窗的方法
  plateNumberClick?: Function; // 点击车牌的方法
}
const CarListModal = (props: CarListModalProps) => {
  const cateIdArr = useUserCateIdArr();
  const { desc, searchValues, closeModal, plateNumberClick } = props;
  const pageNumRef: any = useRef(0); // 当前请求数据的页码数
  const loadingMoreFlagRef: any = useRef(true); // 是否可以加载更多(避免重复请求)
  const [data, setData] = useState<any>([]); // 车辆列表数据
  const [loading, setLoading] = useState(false); // 是否loading
  const [totalCount, setTotalCount] = useState(0); // 车辆列表数据总数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息

  useEffect(() => {
    getData();
  }, []);

  // 获取车辆数据
  const getData = async () => {
    try {
      // 如果正在请求数据中则不执行任何操作
      if (loadingMoreFlagRef.current) {
        loadingMoreFlagRef.current = false;
        setLoading(true);
        const searchParams = {
          page: pageNumRef.current + 1,
          limit: 50,
          plate_number: searchValues.plateNumber,
          sn: searchValues.sn,
          frameNumber: searchValues.frameNumber,
          cate_id: cateIdArr
        };
        const res = await carListRequest(searchParams);
        const currentPageData = res.data?.data || [];
        setData((prevData: any) => {
          return [...prevData, ...currentPageData]
        });
        setTotalCount(res.data?.count || 0);
        setLoading(false);
        pageNumRef.current += 1;
        loadingMoreFlagRef.current = true;
      }
    } catch (error) {
      setLoading(false);
      loadingMoreFlagRef.current = true;
    }
  }

  // 点击车牌跳转到车辆诊断页面
  const onPlateNumberClick = (data: any) => {
    closeModal();
    plateNumberClick && plateNumberClick(data);
  }

  // 渲染设备状态列
  const renderDeviceStatusCloumn = (value: string, item: any) => {
    // 昆仑山设备显示按钮
    if (item.device_type && (Number(item.device_type) === 2 || Number(item.device_type) === 6 || Number(item.device_type) === 7)) {
      return (
        <div className={styles.tableOperateColumn}>
          <Button type="link" onClick={() => setOperateInfo({ type: 'kls', data: item })}>设备状态</Button>
        </div>
      )
    }
    return (
      <RenderEllipsisColumn text={value || ''} />
    )
  }

  // 渲染表格的footer内容
  const renderTableFooter = () => {
    if (data.length === totalCount) {
      return `共搜索到${totalCount}条，已加载全部数据`;
    } else {
      return (
        <div className={styles.scrollMoreTableFooter}>
          <span className={styles.desc}>
            {`共搜索到${totalCount}条，已加载${data.length}条数据`}
          </span>
          <Button loading={loading} type="primary" onClick={getData}>加载更多</Button>
        </div>
      )
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
      fixed: 'left' as 'left',
      render: (value: string, item: any) => (
        <div className={styles.tableOperateColumn}>
          <Button type="link" onClick={() => onPlateNumberClick(item)}>{value}</Button>
        </div>
      )
    },
    snColumnConfig,
    cameraSnColumnConfig,
    cateGroupColumnConfig,
    frameNumberColumnConfig,
    carBrandColumnConfig,
    carModelColumnConfig,
    {
      title: '是否在线',
      dataIndex: 'online',
      width: 120,
    },
    {
      title: '设备状态',
      dataIndex: 'deviceStatusDesc',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string, item: any) => renderDeviceStatusCloumn(value, item),
    },
    {
      title: '最后更改时间',
      dataIndex: 'updated_at',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} />
    },
    {
      title: '安装队长',
      dataIndex: 'install_captain',
      width: 130,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 300,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'contact', data: item })}>联系方式</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'position', data: item })}>最近位置</Button>
          </div>
        )
      },
    },
  ]

  return (
    <Fragment>
      <Modal
        title="车辆数据"
        visible
        footer={null}
        onCancel={closeModal}
        width='70%'
        centered
      >
        {Boolean(desc) && <div style={{ fontWeight: 'bold', lineHeight: '20px' }}>{desc}</div>}
        <CommonTable
          rowKey={'id'}
          size='small'
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          scroll={{ y: 400 }}
          loading={loading}
          scrollLoadMore={data.length !== totalCount}
          scrollLoadMoreFunc={data.length === totalCount ? () => getData() : null}
          footer={
            data.length ? renderTableFooter : undefined}
        />
      </Modal>
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'position' &&
        <PositionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Fragment>
    
  )
}

export default CarListModal;
    