/**
 * 工单未过审并重新打开工单的弹窗
 */
import React, { useState } from "react";
import { Modal, Form, Input, message } from "antd";
import dayjs from "dayjs";
import DatePicker from '@/components/datePicker';
import { reopenWorkOrderRequest } from '@/service/workOrder';

interface ReopenModalProps {
  id: number; // 工单id
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 添加成功后重新请求工单的方法
}

const ReopenModal = (props: ReopenModalProps) => {
  const { id, onClose, reloadData } = props;
  const [loading, setLoading] = useState<boolean>(false); // 提交接口是否请求中
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 重新打开工单
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      try {
        const params = {...values};
        params.expire = dayjs(values.expire).format("YYYY-MM-DD") + ' 23:59:59';
        params.id = id;
        setLoading(true);
        const res = await reopenWorkOrderRequest(params);
        if (res.status == 200) {
          setSubmitNoRepetition(true)
        }
        setLoading(false);
        if (res.data?.code === 0) {
          message.success('重新打开工单成功');
          onClose();
          reloadData();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <Modal
      title="重新打开工单"
      visible
      centered
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="重新打开工单原因"
          name="reason"
          rules={[
            { required: true, message: '请输入重新打开工单原因' }
          ]}
        >
          <Input.TextArea placeholder="请输入重新打开工单原因" allowClear />
        </Form.Item>
        <Form.Item
          label="期望完成时间"
          name="expire"
          rules={[
            { required: true, message: '请选择期望完成时间' }
          ]}
        >
          <DatePicker
            // showTime
            placeholder="请选择期望完成时间"
            style={{ width: '100%' }}
            disabledDate={(currentDate: any) => currentDate && currentDate < dayjs()}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReopenModal;