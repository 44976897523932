/**
 * 视频充值相关请求
 */
import request from "@/utils/request";

export interface VideoRequestParams {
    beginDate?: string;
    endDate?: string;
}

// 查询所有账户充值记录
export const videoRequest = (params: VideoRequestParams) => {
    return request.get('/api/v2/videoInvest/list', {
        params,
    })
}


// 查询当前登录账户的充值记录
export const videoClientRequest = (params: VideoRequestParams) => {
    return request.get('/api/v2/videoInvest/myList', {
        params,
    })
}


// 添加用户视频充值记录
export const upsertVideoRequest  = (params: any) => {
    return request.post('/api/v2/videoInvest/upsert', 
        params
    )
}


//删除用户视频充值记录
export const delectVideoRequest  = (id: number) => {
    return request.delete(`/api/v2/videoInvest/${id}`)
}


//获取登录
export const videoInvestDurationRequest = (params: any) => {
    return request.get('/api/v2/videoInvest/my/duration', {
        params,
    })
}