/**
 * 表格列显示省略号(hover显示气泡)
 */
import React from 'react';
import { Tooltip } from 'antd';

const RenderEllipsisColumn = (props: {
  text: string | number;
  children?: any;
  ellipsis?: boolean;
}) => {
  const { text, children, ellipsis = false } = props;

  return (
    <Tooltip
      placement="topLeft"
      title={text}
      overlayClassName="tooltipOverlay"
    >
      {ellipsis ? (
        <div
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {children || text}
        </div>
      ) : (
        <span>{children || text}</span>
      )}
    </Tooltip>
  );
};

export default RenderEllipsisColumn;
