/**
 * 客户文件相关请求
 */
import request from "@/utils/request";

export interface ClientListRequestParams {
    page: number; 
    limit: number;
    // nickname?: string;
    // mobile?: string;
    // roleIds?: Array<number>;
}

export const clientListRequestPath = '/api/v2/file/list';

// 获取文件列表数据
export const clientFileListRequest = (params: ClientListRequestParams) => {
    return request.get(clientListRequestPath, {
        params,
    })
}

// 添加文件列表数据
export const upsertFileRequest = (data: any) => {
    return request.post('/api/v2/file/upsert', data)
}

// 删除用户
export const fileDeleteRequest = (id: number) => {
    return request.delete(`/api/v2/file/${id}`)
}


