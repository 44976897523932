/**
 * 支付详情
 */
import { videoInvestRequest, videoInvestQueryexportRequest } from '@/service/monitoringClientRecord';
import { useEffect, useState } from 'react';

const ZhiFuDetails = () => {
  const [startTime, setStartTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);
  
  useEffect(() => {
    zhiFuDetail()
  }, [])

  const zhiFuDetail = async () => {
    let data = {
      payment: Number(localStorage?.getItem("zhifubaoPayment")),
      orderNo: localStorage?.getItem("zhifubaoOrderNo")
    };
    // 轮询结果
    const getOrderDetail = () => {
    //轮询方法如果请求返回结果,则说明支付成功,否则继续轮询
    setTimeout(() => {
        videoInvestQueryexportRequest(data).then(res => {
          console.log(res, 'res');
          if (res.data.msg === '未支付') {
            getOrderDetail()
          } else {
            // 支付成功
            console.log('支付成功',res);
          }
        })
      }, 10000)

      //  ts+ react 设置定时器进行轮询，轮询间隔设为 3秒，轮询总时长 60 秒左右
    }
    const formsData = localStorage.getItem("contactDetail")
    if (formsData) {
      const div = document.createElement('div')
      div.innerHTML = formsData
      document.body.appendChild(div)
      // document.forms[0].setAttribute('target', '_self'); //不打开新窗口
      document.forms[0].setAttribute('target', '_blank'); //打开新窗口
      document.forms[0].submit();
      div.remove()
      getOrderDetail()
    }
  }

  return (
    <div>
    </div>
  )
}

export default ZhiFuDetails;
