/**
 * 部标机接口请求相关方法
 */
// 部标机相关接口通用的请求参数
export interface Jt808CommonParams {
  phone: string;
  jt808DeviceId: number;
  channelNo: number;
  codeStream: number;
}

// 部标机视频回放通用的请求参数
export interface Jt808PlaybackCommonParams extends Jt808CommonParams {
  start: string;
  end: string;
  type: number;
  storageType: number;
}

// 请求url上添加版本信息的处理(2011、2013都添加2013版本)
export const operateJt808VersionPath = (version: string, manufacturer?: string) => {
  if (manufacturer == '6') {
    return '2013'
  }
  return ['2011', '2013'].includes(version) ? '2013' : '2019';
}


// 处理部标机相关接口参数
export const operateJt808Params = (
  params: any,
  url: string,
  needDeviceId?: boolean,
  needVersion?: boolean
) => {
  const { phone, jt808DeviceId, version } = params;
  const newParams = { ...params };
  delete newParams.phone;
  delete newParams.jt808DeviceId;
  let newUrl = url;
  if (needVersion) {
    newUrl = `${newUrl}/${operateJt808VersionPath(version)}`;
    delete newParams.version;
  }
  // 请求url上添加终端手机号
  newUrl = `${newUrl}/${phone}`;
  if (needDeviceId) {
    // 请求url上添加设备ID
    newUrl = `${newUrl}/${jt808DeviceId}`;
  }
  return { url: newUrl, newData: newParams, phone };
};
