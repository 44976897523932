/**
 * 车辆实时监控相关请求
 */
import request from '@/utils/request';

// 添加车辆进行报警监控
export const watchCarRequest = (data: { carIds: number[] }) => {
  return request.post('/api/v2/jt808Device/watchCar', data);
};

// 删除车辆进行报警监控
export const unwatchCarRequest = (data: { carIds: number[] }) => {
  return request.post('/api/v2/jt808Device/unwatchCar', data);
};
