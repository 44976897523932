/**
 * 用户管理相关请求
 */
import qs from 'qs';
import request from "@/utils/request";

export interface UserListRequestParams {
  page: number; 
  limit: number;
  nickname?: string;
  mobile?: string;
  roleIds?: Array<number>;
}

export const userListRequestPath = '/api/uaa/user/list';
// 获取用户列表数据
export const userListRequest = (params: UserListRequestParams) => {
  return request.get(userListRequestPath, {
    params,
  })
}

// 删除用户
export const userDeleteRequest = (id: number) => {
  return request.delete(`/api/uaa/user/${id}`)
}

// 添加、编辑用户数据
export const upsertUserRequest = (data: any) => {
  return request.post('/api/uaa/user/upsert', data)
}

// 修改密码
export const modifyPasswordRequest = (data: any) => {
  return request.post('/api/v2/user/modify', data)
}

// 获取登录的用户权限信息
export const getUserPermissionRequest = (params?: any) => {
  return request.get('/api/uaa/user/menuPermissions', {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false });
    }
  });
}
