/**
 * 车辆管理的route入口文件(各页面使用Tab渲染)
 */
import React, { Fragment } from 'react';
import { getPagePermissionEnable } from '@/utils/permission';
import AlarmPush from '@/pages/alarmPush';
import FatalNotification from '@/pages/fatalNotification';
import TabPages from '@/pages/tabPages';
import { VersionList } from '@/pages/versionList';

const VehicleAdminRoute = () => {
  // 是否有查看右侧实时状态推送的权限
  const hasAlarmPushPermission = getPagePermissionEnable('/car-admin/alarm-push');
  // 是否有查看严重故障通知的权限
  const hasFatalNotificationPermission = getPagePermissionEnable('/car-admin/fatal-notification');
  return (
    <Fragment>
      {/* 路由的渲染 */}
      <TabPages />
      {/* 右侧实时状态推送 */}
      {hasAlarmPushPermission && <AlarmPush />}
      {/* 严重故障通知 */}
      {hasFatalNotificationPermission && <FatalNotification />}
      {/* 版本列表 */}
      <VersionList />
    </Fragment>
  )
}

export default VehicleAdminRoute;
