/**
 * 车辆协议添加和编辑
 */
import React, { Fragment, useEffect, useRef } from 'react';
import {
  Modal,
  Form,
  message,
  Button,
  Upload,
  Select,
  Row,
  Col,
  Input
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { upsertCanInfoRequest } from '@/service/carCan';
import { carCANSignal, carCANSignalValueData } from '@/constants/carCan';
import localStorageUtil, {
  LocalStorageKeyEnum
} from '@/utils/localStorageUtil';
import { downloadFile } from '@/utils/download';

interface OperateModalProps {
  data?: any;
  type?: string;
  closeModal: () => void;
  reloadData?: () => void;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const hasUploadingRef = useRef(false);
  const { data, closeModal, reloadData } = props;

  useEffect(() => {
    getCanDetail();
  }, []);

  // 编辑时处理文件相关内容
  const operateFileDetail = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item: any) => ({
        name: item.fileName,
        url: item.preview,
        status: 'done',
        response: { data: { key: item.key, preview: item.preview } }
      }));
    } else {
      return [];
    }
  };

  // 获取车辆协议详情并赋值
  const getCanDetail = () => {
    const setValues = {
      ...data,
      // canFileKey: operateFileDetail(data.canFiles),
      // dbcFileKey: operateFileDetail(data.dbcFiles),
      attachFileKey: operateFileDetail(data.attachFiles)
    };
    form.setFieldsValue(setValues);
  };

  // 处理上传组件的value
  const formatUploadValue = (e: any) => {
    if (Array.isArray(e)) {
      return e[0]?.fileList;
    }
    return e?.fileList;
  };

  // 保存时处理上传的文件数据
  const operateFile = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item: any) => {
        if (item?.status === 'uploading') {
          hasUploadingRef.current = true;
        }
        return item?.response?.data?.key;
      });
    } else {
      return [];
    }
  };

  // 调用接口保存协议
  const onSave = async (params: any) => {
    if (data.id) {
      params.id = data.id;
    }
    const res = await upsertCanInfoRequest(params);
    if (res.data?.code === 0) {
      message.success('保存成功');
      closeModal();
      reloadData && reloadData();
    }
  };

  // 保存车辆协议信息
  const onFinish = async (values: any) => {
    try {
      const params = { ...values };
      // 处理文件内容
      // params.canFileKey = operateFile(values.canFileKey);
      // params.dbcFileKey = operateFile(values.dbcFileKey);
      params.attachFileKey = operateFile(values.attachFileKey);
      if (hasUploadingRef.current) {
        message.info('当前有正在上传的文件，请等待上传完毕再保存。');
        hasUploadingRef.current = false;
      } else {
        onSave(params);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 下载已经上传成功的文件
  const uploadFileClick = (file: any) => {
    downloadFile(file?.response?.data?.preview, file.name);
  };

  return (
    <Modal
      title="编辑车辆CAN协议"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="车辆品牌"
          name="brand"
          rules={[{ required: true, message: '请填写车辆品牌' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="车型"
          name="model"
          rules={[{ required: true, message: '请填写车型' }]}
        >
          <Input disabled />
        </Form.Item>
        {/* 暂时隐藏CAN报文、DBC文件的上传 */}
        {/* <Form.Item
          label="CAN报文上传"
          name="canFileKey"
          valuePropName="fileList"
          getValueFromEvent={formatUploadValue}
        >
          <Upload
            action="/api/v2/resource/upload/canFile"
            headers={{ access_token: localStorageUtil.getItem(LocalStorageKeyEnum.access_token) }}
            multiple
            onPreview={uploadFileClick}
          >
            <Button icon={<UploadOutlined />}>
              CAN报文上传
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="DBC文件上传"
          name="dbcFileKey"
          valuePropName="fileList"
          getValueFromEvent={formatUploadValue}
        >
          <Upload
            action="/api/v2/resource/upload/canFile"
            headers={{ access_token: localStorageUtil.getItem(LocalStorageKeyEnum.access_token) }}
            multiple
            onPreview={uploadFileClick}
          >
            <Button icon={<UploadOutlined />}>
              DBC文件上传
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const dbcFileKeys = form.getFieldValue('dbcFileKey');
            const canFileKeys = form.getFieldValue('canFileKey');
            if (dbcFileKeys?.length > 0) {
              const canFileOptions = canFileKeys.map((item: any) => ({
                label: item?.name,
                value: item?.response?.data?.key
              }))
              return (
                <Fragment>
                  <div style={{ marginBottom: '12px', fontWeight: 600 }}>DBC文件-CAN报文对应</div>
                  {dbcFileKeys.map((item: any) => (
                    <Form.Item
                      label={item.name}
                      name={item?.response?.data?.key}
                      rules={[
                        { required: true, message: '请选择该DBC文件对应的CAN报文文件' }
                      ]}
                    >
                      <Select
                        options={canFileOptions}
                        placeholder="请选择DBC文件对应的CAN报文文件"
                        mode="multiple"
                        showSearch
                        showArrow
                      />
                    </Form.Item>
                  ))}
                </Fragment>
              )
            } else {
              return null;
            }
          }}
        </Form.Item> */}
        <Form.Item
          label="验证录屏上传"
          name="attachFileKey"
          valuePropName="fileList"
          getValueFromEvent={formatUploadValue}
        >
          <Upload
            action="/api/v2/resource/upload/canFile"
            headers={{
              access_token: localStorageUtil.getItem(
                LocalStorageKeyEnum.access_token
              )
            }}
            multiple
            onPreview={uploadFileClick}
          >
            <Button icon={<UploadOutlined />}>验证录屏上传</Button>
          </Upload>
        </Form.Item>
        <div style={{ marginBottom: '12px', fontWeight: 600 }}>信号状态</div>
        <Row wrap gutter={8}>
          {carCANSignal.map((item: any) => (
            <Col span={8} key={item.value}>
              <Form.Item label={item.label} name={item.value}>
                <Select options={carCANSignalValueData} />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};

export default OperateModal;
