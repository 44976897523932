/**
 * 操作虚拟设备(添加、编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, InputNumber } from 'antd';
import { upsertVirtualDeviceRequest } from '@/service/jt808VirtualDevice';

interface OperateModalProps {
  type: string; // 操作类型
  data?: any; // AT指令详情
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 操作成功后重新请求虚拟设备列表的方法
}

const OperateModal = (props: OperateModalProps) => {
  const { type, data, onClose, reloadData } = props;
  const [loading, setLoading] = useState<boolean>(false); // 接口是否请求中
  const [form] = Form.useForm();

  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    try {
      setLoading(true);
      const params = { ...values };
      if (type === 'edit') {
        params.id = data.id;
        params.token = data.token;
      }
      const res = await upsertVirtualDeviceRequest(params);
      setLoading(false);
      if (res.data?.data) {
        message.success(type === 'add' ? '添加成功' : '编辑成功');
        onClose();
        reloadData();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      title={type === 'add' ? '添加虚拟设备' : '编辑虚拟设备'}
      open
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="设备SN"
          name="sn"
          rules={[{ required: true, message: '请填写设备SN' }]}
        >
          <Input placeholder="请填写设备SN" />
        </Form.Item>
        <Form.Item
          label="车牌号"
          name="plateNumber"
          rules={[{ required: true, message: '请填写车牌号' }]}
        >
          <Input placeholder="请填写车牌号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
        </Form.Item>
        <Form.Item
          label="终端手机号"
          name="phone"
          rules={[{ required: true, message: '请填写终端手机号' }]}
        >
          <Input placeholder="请填写终端手机号" />
        </Form.Item>
        <Form.Item
          label="省城ID"
          name="provinceId"
          rules={[{ required: true, message: '请填写省城ID' }]}
        >
          <Input placeholder="请填写省城ID" />
        </Form.Item>
        <Form.Item
          label="市县城ID"
          name="cityId"
          rules={[{ required: true, message: '请填写市县城ID' }]}
        >
          <Input placeholder="请填写市县城ID" />
        </Form.Item>
        <Form.Item
          label="制造商ID"
          name="makerId"
          rules={[{ required: true, message: '请填写制造商ID' }]}
        >
          <Input placeholder="请填写制造商ID" />
        </Form.Item>
        <Form.Item
          label="终端型号"
          name="deviceModel"
          rules={[{ required: true, message: '请填写终端型号' }]}
        >
          <Input placeholder="请填写终端型号" />
        </Form.Item>
        <Form.Item
          label="车牌颜色"
          name="plateColor"
          rules={[{ required: true, message: '请填写车牌颜色' }]}
        >
          <InputNumber placeholder="请填写车牌颜色" style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
