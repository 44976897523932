/**
 * 退保记录
 */
import React, {  useState } from 'react';
import { Card, Form, Row, Col, Button, Input, } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import {
    surrenderRecordListRequest,
    surrenderRecordListRequestPath,
} from "@/service/underwritingRecord";
import styles from '@/styles/pageContent.module.scss';
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import { downloadFile } from '@/utils/download';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import DatePicker from '@/components/datePicker';

const SurrenderRecord = () => {
    const [searchParams, setSearchParams] = useState({
        startDay: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
        endDay: `${dayjs().format('YYYY-MM-DD')}`,
    }); // 搜索相关的参数
    const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
    const [form] = Form.useForm();
    const { page, limit, loading, data, totalCount, onPageChange } = useTable({
        requestFunc: surrenderRecordListRequest,
        initRequest: true,
        initPageSize: 40,
        searchParams,
    });
    const { RangePicker } = DatePicker;


    // 处理搜索参数
    const operateSearchParams = (params: any, isExport?: boolean) => {
        const newParams = { ...params };
        newParams.startDay = dayjs(params.day[0]).format('YYYY-MM-DD');
        newParams.endDay = dayjs(params.day[1]).format('YYYY-MM-DD');
        delete newParams.day;
    
        if (isExport) {
            newParams.export = true;
        }
        return newParams;
    }

    // 获取导出的参数
    const getExportParams = () => {
        const values = form.getFieldsValue();
        return operateSearchParams(values, true);
    }

    // 搜索
    const onSearch = async (values: any) => {
        const newSearchParams = operateSearchParams(values);
        setSearchParams(newSearchParams);
    };

    // PDF合同预览
    const onPdfPreview = (url: string, fileName: string) => {
        setPdfPreviewInfo({
            url,
            fileName,
        });
    }

    // 表格列显示
    const columns = [
        {
            title: '承保单号',
            dataIndex: 'insuranceCode',
        },
        {
            title: '车架号',
            dataIndex: 'frameNumber',
        },
        {
            title: '退保日期',
            dataIndex: 'createdAt',
            render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')} /> : '/'
        },
        {
            title: '退费金额(元)',
            dataIndex: 'fee',
        },
        {
            title: '退费凭证',
            dataIndex: 'resources',
            width: 200,
            ellipsis: { showTitle: false },
            render: (value: any) => (
                value?.map((item: any, index: any) => (
                    <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
                        <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
                        </div>
                        <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                type="text"
                                onClick={() => onPdfPreview(item.url, item.fileName)}
                            >
                                <EyeOutlined />
                            </Button>
                            <Button
                                type="link"
                                onClick={() => downloadFile(item.url, item.fileName)}
                                style={{ paddingLeft: 0 }}
                            >
                                <DownloadOutlined />
                            </Button>
                        </div>
                    </div>
                ))
            ),
        },
        {
            title: '操作人',
            dataIndex: 'opt',
        }
    ]

    return (
        <Card bordered={false} className={styles.fixPageContentBox}>
            <Form onFinish={onSearch} layout="vertical" autoComplete="off" form={form}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item label="承保单号" name="insuranceCode">
                            <Input placeholder='请输入承保单号' allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="车架号" name="frameNumber">
                            <Input placeholder='请输入车架号' allowClear  onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="时间范围"
                            name="day"
                            initialValue={[dayjs(searchParams.startDay), dayjs(searchParams.endDay)]}>
                            <RangePicker allowClear={false} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="&nbsp;">
                            <div className={styles.searchBox}>
                                <Button type="primary" htmlType="submit">搜索</Button>
                                <ExportButton
                                    url={surrenderRecordListRequestPath}
                                    fileName='退保记录'
                                    getParamsFunc={getExportParams}
                                    requestMethod="GET"
                                    permissionKey='/car-admin/surrender-recodes/export'
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    total: totalCount,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                }}
                bordered
                loading={loading}
            />
            {pdfPreviewInfo && <PdfPreviewModal
                title={pdfPreviewInfo?.fileName}
                pdfUrl={pdfPreviewInfo?.url}
                closeModal={() => setPdfPreviewInfo(null)}
            />}
        </Card>
    )
}

export default SurrenderRecord;
