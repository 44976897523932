/**
 * 客户文件
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Input, Button } from 'antd';
import styles from '@/styles/pageContent.module.scss';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import { useTable } from '@/utils/useTable';
import { clientLearnListRequest } from '@/service/clientLearn'

const ClientLearn = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: clientLearnListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    newSearchParams.nickName = (values.nickName || '').trim();
    newSearchParams.mobile = (values.mobile || '').trim();
    setSearchParams(newSearchParams);
  };

  // 表格列显示
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 50,
      ellipsis: { showTitle: false },
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: 70,
      ellipsis: { showTitle: false },
    },
    {
      title: '使用账号',
      dataIndex: 'userName',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '学习时长(s)',
      dataIndex: 'duration',
      width: 60,
      ellipsis: { showTitle: false },
    },
    {
      title: '学习时间',
      dataIndex: 'createdAt',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '文档名称',
      dataIndex: 'fileName',
      width: 110,
      ellipsis: { showTitle: false },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" autoComplete="off">
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="姓名" name="name">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="文档名称" name="fileName">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="分类选择" name="cateId" initialValue={searchParams}>
            <CategoryTreeSelect treeCheckable />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="&nbsp;">
            <div className={styles.searchBox}>
              <Button type="primary" htmlType="submit">搜索</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
    </Card>
  )
}

export default ClientLearn;
