/**
 * 车辆CAN协议
 */

export const carCANSignal = [
  { label: '车速', value: 'vehicleSpeedSignal' },
  { label: '左右转向', value: 'turnSignal' },
  { label: '司机刹车', value: 'breakSignal' },
  { label: '挡位', value: 'gearSignal' },
  { label: '里程', value: 'mileageSignal' },
  { label: '方向转角', value: 'forwardSignal' },
  { label: '油门深度', value: 'throttleDepthSignal' },
  { label: '刹车深度', value: 'breakDepthSignal' },
  { label: '偏航角', value: 'yawAngleSignal' },
  { label: '发动机转速', value: 'engineSpeedSignal' },
  { label: '俯仰角', value: 'pitchAngleSignal' },
  { label: '加速度', value: 'accSpeedSignal' },
  { label: '危险灯', value: 'hazardLightsSignal' }
];

export const carCANSignalValue: any = {
  0: '未破解',
  1: '已破解',
  2: '验证通过',
  3: '验证未通过'
};

export const carCANSignalColor: any = {
  0: 'warningTableCell',
  1: 'processTableCell',
  2: 'successTableCell',
  3: 'dangerTableCell'
};

export const carCANSignalValueData: any = [
  { label: '未破解', value: 0 },
  { label: '已破解', value: 1 },
  { label: '验证通过', value: 2 },
  { label: '验证未通过', value: 3 }
];
export const customCANSignalData: any = [
  {
    label: '车速',
    value: 'AEBS_Speed',
    childrenItems: [
      {
        field: 'unit',
        title: '车速单位',
        formType: 'radio',
        props: {
          options: [
            { label: '千米/小时', value: 0 },
            { label: '米/秒', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择车速单位' }]
      }
    ]
  },
  {
    label: '左转向',
    value: 'AEBS_LeftTurn',
    childrenItems: [
      {
        field: 'leftTurnOff',
        title: '没有左转向时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写没有左转向时的值' },
          { type: 'number', max: 7, message: '请填写0-7的数值' },
          { type: 'number', min: 0, message: '请填写0-7的数值' }
        ],
        compareField: ['leftTurnOn']
      },
      {
        field: 'leftTurnOn',
        title: '左转向时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写左转向时的值' },
          { type: 'number', max: 7, message: '请填写0-7的数值' },
          { type: 'number', min: 0, message: '请填写0-7的数值' }
        ],
        compareField: ['leftTurnOff']
      }
    ]
  },
  {
    label: '右转向',
    value: 'AEBS_RightTurn',
    childrenItems: [
      {
        field: 'rightTurnOff',
        title: '没有右转向时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写没有右转向时的值' },
          { type: 'number', max: 7, message: '请填写0-7的数值' },
          { type: 'number', min: 0, message: '请填写0-7的数值' }
        ],
        compareField: ['rightTurnOn']
      },
      {
        field: 'rightTurnOn',
        title: '右转向时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写右转向时的值' },
          { type: 'number', max: 7, message: '请填写0-7的数值' },
          { type: 'number', min: 0, message: '请填写0-7的数值' }
        ],
        compareField: ['rightTurnOff']
      }
    ]
  },
  {
    label: '司机刹车',
    value: 'AEBS_Brake',
    childrenItems: [
      {
        field: 'brakeOff',
        title: '司机没刹车时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写司机没刹车时的值' },
          { type: 'number', max: 7, message: '请填写0-7的数值' },
          { type: 'number', min: 0, message: '请填写0-7的数值' }
        ],
        compareField: ['brakeOn']
      },
      {
        field: 'brakeOn',
        title: '司机刹车时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写司机刹车时的值' },
          { type: 'number', max: 7, message: '请填写0-7的数值' },
          { type: 'number', min: 0, message: '请填写0-7的数值' }
        ],
        compareField: ['brakeOff']
      }
    ]
  },
  {
    label: '档位',
    value: 'AEBS_Gears',
    childrenItems: [
      {
        field: 'pGears',
        title: 'P档位值',
        formType: 'inputNumber',
        span: 4,
        rules: [
          { required: true, message: '请填写P档位值' },
          { type: 'number', max: 255, message: '请填写0-255的数值' },
          { type: 'number', min: 0, message: '请填写0-255的数值' }
        ],
        compareField: ['nGears', 'dGears', 'rGears']
      },
      {
        field: 'nGears',
        title: 'N档位值',
        formType: 'inputNumber',
        span: 4,
        rules: [
          { required: true, message: '请填写N档位值' },
          { type: 'number', max: 255, message: '请填写0-255的数值' },
          { type: 'number', min: 0, message: '请填写0-255的数值' }
        ],
        compareField: ['pGears', 'dGears', 'rGears']
      },
      {
        field: 'dGears',
        title: 'D档位值',
        formType: 'inputNumber',
        span: 4,
        rules: [
          { required: true, message: '请填写D档位值' },
          { type: 'number', max: 255, message: '请填写0-255的数值' },
          { type: 'number', min: 0, message: '请填写0-255的数值' }
        ],
        compareField: ['pGears', 'nGears', 'rGears']
      },
      {
        field: 'rGears',
        title: 'R档位值',
        formType: 'inputNumber',
        span: 4,
        rules: [
          { required: true, message: '请填写R档位值' },
          { type: 'number', max: 255, message: '请填写0-255的数值' },
          { type: 'number', min: 0, message: '请填写0-255的数值' }
        ],
        compareField: ['pGears', 'nGears', 'dGears']
      }
    ]
  },
  {
    label: '里程数',
    value: 'AEBS_Distance',
    childrenItems: [
      {
        field: 'unit',
        title: '里程数单位',
        formType: 'radio',
        props: {
          options: [
            { label: '千米', value: 0 },
            { label: '米', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择里程数单位' }]
      }
    ]
  },
  {
    label: '方向盘转角',
    value: 'AEBS_SteerWheel',
    childrenItems: [
      {
        field: 'unit',
        title: '方向盘转角单位',
        formType: 'radio',
        props: {
          options: [
            { label: '角度', value: 0 },
            { label: '弧度', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择方向转角单位' }]
      },
      {
        field: 'rightFlag',
        title: '方向盘右打标识',
        formType: 'radio',
        props: {
          options: [
            { label: '右打计数增加', value: 0 },
            { label: '右打计数减少', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择方向转角单位' }]
      }
    ]
  },
  {
    label: '油门深度',
    value: 'AEBS_AccelPedal',
    childrenItems: [
      {
        field: 'unit',
        title: '油门深度的表示方式',
        formType: 'radio',
        props: {
          options: [
            { label: '百分比', value: 0 },
            { label: '其它', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择油门深度的表示方式' }]
      }
    ]
  },
  {
    label: '刹车深度',
    value: 'AEBS_BrakePedal',
    childrenItems: [
      {
        field: 'unit',
        title: '刹车深度的表示方式',
        formType: 'radio',
        props: {
          options: [
            { label: '百分比', value: 0 },
            { label: '其它', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择刹车深度的表示方式' }]
      }
    ]
  },
  {
    label: '航偏角',
    value: 'AEBS_YawRate',
    childrenItems: [
      {
        field: 'unit',
        title: '偏航角单位',
        formType: 'radio',
        props: {
          options: [
            { label: '角度', value: 0 },
            { label: '弧度', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择偏航角单位' }]
      }
    ]
  },
  {
    label: '发动机转速',
    value: 'AEBS_EngineSpeed',
    childrenItems: [
      {
        field: 'unit',
        title: '发动机转速单位',
        formType: 'radio',
        props: {
          options: [
            { label: '转/分钟', value: 0 },
            { label: '转/秒', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择发动机转速单位' }]
      }
    ]
  },
  {
    label: '俯仰角',
    value: 'AEBS_PitchAngle',
    childrenItems: [
      {
        field: 'unit',
        title: '俯仰角单位',
        formType: 'radio',
        props: {
          options: [
            { label: '角度', value: 0 },
            { label: '弧度', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择俯仰角单位' }]
      }
    ]
  },
  {
    label: '车辆加速度',
    value: 'AEBS_Acceleration',
    childrenItems: [
      {
        field: 'unit',
        title: '加速度单位',
        formType: 'radio',
        props: {
          options: [
            { label: '米/(秒*秒)', value: 0 },
            { label: '千米/(小时*小时)', value: 1 }
          ]
        },
        span: 6,
        rules: [{ required: true, message: '请选择加速度单位' }]
      }
    ]
  },
  {
    label: '危险灯',
    value: 'AEBS_Hazard',
    childrenItems: [
      {
        field: 'hazardOff',
        title: '没有危险灯时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写没有危险灯时的值' },
          { type: 'number', max: 255, message: '请填写0-255的数值' },
          { type: 'number', min: 0, message: '请填写0-255的数值' }
        ],
        compareField: ['hazardOn']
      },
      {
        field: 'hazardOn',
        title: '有危险灯时的值',
        formType: 'inputNumber',
        span: 6,
        rules: [
          { required: true, message: '请填写有危险灯时的值' },
          { type: 'number', max: 255, message: '请填写0-255的数值' },
          { type: 'number', min: 0, message: '请填写0-255的数值' }
        ],
        compareField: ['hazardOff']
      }
    ]
  }
];
