/**
 * 车辆诊断设备版本变更表格信息(昆仑山设备)
 */
import React, { useState, useEffect, Fragment } from 'react';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import {
  carDiagnosisVersionRequest,
  carDiagnosisVersionRequestPath
} from '@/service/carDiagnosis';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { renderDifferentTableCell } from '@/utils/common';

interface KlsVersionTableProps {
  diagnosisSearchParams: any;
  getExportParams: () => any;
}

const KlsVersionTable = (props: KlsVersionTableProps) => {
  const { diagnosisSearchParams, getExportParams } = props;
  const [searchParams, setSearchParams] = useState(diagnosisSearchParams);
  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    setSearchParams(diagnosisSearchParams);
  }, [diagnosisSearchParams]);

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carDiagnosisVersionRequest,
    initRequest: Boolean(diagnosisSearchParams),
    initPageSize: 40,
    searchParams
  });

  // 格式化无效版本号的展示形式
  const formatValue = (value: any) =>
    /fail to get/i.test(value) ? '无效版本号' : value;

  // 渲染表格列内容
  const renderColumnText = (value: any) => {
    return <RenderEllipsisColumn text={formatValue(value)} ellipsis />;
  };

  const columns = [
    {
      title: '上报时间',
      dataIndex: 'reportedTime',
      width: 80,
      render: (value: any) => (
        <RenderEllipsisColumn text={formatTime(value)} ellipsis />
      ),
      fixed: 'left' as const,
      noCommonRender: true,
      noCommonCell: true
    },
    {
      title: '控制器软件版本号',
      dataIndex: 'aebsSwVersion',
      width: 80
    },
    {
      title: '控制器SN序列号',
      dataIndex: 'aebsSn',
      width: 80
    },
    {
      title: '控制器PN序列号',
      dataIndex: 'aebsPn',
      width: 80
    },
    {
      title: '双目相机软件版本号',
      dataIndex: 'cameraSwVersion',
      width: 90
    },
    {
      title: '双目相机硬件版本号',
      dataIndex: 'cameraHwVersion',
      width: 90
    },
    {
      title: '双目相机SN序列号',
      dataIndex: 'cameraSn',
      width: 90
    },
    {
      title: '双目相机PN序列号',
      dataIndex: 'cameraPn',
      width: 90
    },
    {
      title: '显示屏软件版本号',
      dataIndex: 'displayerSwVersion',
      width: 80
    },
    {
      title: '显示屏SN序列号',
      dataIndex: 'displayerSn',
      width: 80
    },
    {
      title: '显示屏PN序列号',
      dataIndex: 'displayerPn',
      width: 80
    },
    {
      title: '超声波雷达版本号',
      dataIndex: 'uradarVersion',
      width: 80
    },
    {
      title: '毫米波雷达版本号',
      dataIndex: 'mradarVersion',
      width: 80
    },
    {
      title: '记录仪SN序列号',
      dataIndex: 'carDvrSn',
      width: 80
    },
    {
      title: '记录仪版本号',
      dataIndex: 'carDvrVersion',
      width: 70
    },
    {
      title: '相机CAN协议版本号',
      dataIndex: 'cameraCanProtocoiVersion',
      width: 90
    },
    {
      title: 'PC端串口协议版本号',
      dataIndex: 'aebsAtPcProtocoiVersion',
      width: 90
    },
    {
      title: '蓝牙串口协议版本号',
      dataIndex: 'aebsAtBtProtocoiVersion',
      width: 90
    },
    {
      title: '控制器CAN协议版本号',
      dataIndex: 'aebsCanProtocoiVersion',
      width: 90
    },
    {
      title: '显示器CAN协议版本号',
      dataIndex: 'displayerCanProtocoiVersion',
      width: 90
    },
    {
      title: '云平台协议版本号',
      dataIndex: 'cloudPlatformProtocoiVersion',
      width: 80
    }
  ].map((item: any) => {
    const returnItem = { ...item };
    const { noCommonRender, noCommonCell, dataIndex } = item;
    if (!noCommonRender) {
      returnItem.render = (value: any) => renderColumnText(value);
    }
    if (!noCommonCell) {
      returnItem.onCell = (record: any, rowIndex: number) =>
        renderDifferentTableCell(
          record,
          rowIndex,
          dataIndex,
          data,
          formatValue
        );
    }
    return returnItem;
  });

  return (
    <Fragment>
      <div style={{ margin: '12px 0', textAlign: 'right' }}>
        <ExportButton
          type="primary"
          disabled={!diagnosisSearchParams}
          url={carDiagnosisVersionRequestPath}
          fileName={`${diagnosisSearchParams?.plateNumber}设备版本变更`}
          getParamsFunc={getExportParams}
          requestMethod="GET"
          permissionKey="/car-admin/car-diagnosis/version-export"
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        scroll={{ x: '100%', y: 400 }}
      />
    </Fragment>
  );
};

export default KlsVersionTable;
