/**
 * 概况相关请求
 */
import request from "@/utils/request";

// 获取统计数量
export const statisticsCountRequest = (params?: { cateId?: Array<string> }) => {
  return request.get('/api/v2/home/headerData', { params })
}

// 获取车辆报警分布
export const alarmDistributionRequest = (params?: { cateId?: Array<string> }) => {
  return request.get('/api/v2/home/homeAlarmPieEcharts', { params })
}

// 获取车辆运行工时
export const operateTimeRequest = (params?: { cateId?: Array<string> }) => {
  return request.get('/api/dataV/effectiveOnlineMonth', { params })
}

// 获取有效预警数量
export const effectiveAlarmRequest = (params?: { cateId?: Array<string> }) => {
  return request.get('/api/dataV/effectiveAlarmCountMonth', { params })
}

// 获取车辆在线情况
export const carOnlineRequest = (params?: { cateId?: Array<string>, beginDate: string, endDate: string }) => {
  return request.get('/api/v2/home/homeOnlineEcharts', { params })
}

// 获取预警信息对比
export const alarmCompareRequest = (params?: { cateId?: Array<string> }) => {
  return request.get('/api/v2/home/homeAlarmEcharts', { params })
}

// 获取车辆更新情况
export const carUpdateRequest = (params?: { cateId?: Array<string>, beginDate: string, endDate: string }) => {
  return request.get('/api/v2/home/homeDataHistogram', { params })
}


// 更新版本
export const versionInfoUpsert = (params?: { id?: string, name: string, onlineTime:string, content:string }) => {
  return request.post('/api/v2/system/version/info/upsert',  params )
}


// 获取版本信息 
export const versionInfoList = () => {
  return request.get('/api/v2/system/version/info/list')
}

// 删除版本信息
export const delVersionInfo = (id: any) => {
  return request.delete(`/api/v2/system/version/info/${id}`)
}
