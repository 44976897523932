/**
 * 部标机设备视频监控相关请求
 */
import request from '@/utils/request';
import {
  Jt808CommonParams,
  operateJt808Params,
  operateJt808VersionPath
} from '@/utils/jtDeviceRequest';

export interface Jt808Command9101RequestParams extends Jt808CommonParams {
  type: number;
}
// 部标机开始视频监控
export const jt808Command9101Request = (
  data: Jt808Command9101RequestParams,
  messageConfig?: any
) => {
  const config = messageConfig || {}
  const { url, newData } = operateJt808Params(data, '/808/jt808/9101');
  return request.post(url, newData, {...messageConfig, ...{headers: {'sn-agent': data.phone}}});
};

export interface Jt808Command9102RequestParams extends Jt808CommonParams {
  cmd: number;
  closeType: number;
}
// 控制部标机视频监控
export const jt808Command9102Request = (
  data: Jt808Command9102RequestParams
) => {
  const { url, newData } = operateJt808Params(data, '/808/jt808/9102');
  return request.post(url, newData, {headers: {'sn-agent': data.phone}});
};

export interface Jt808Command8300RequestParams {
  phone: string;
  flag: number;
  text: string;
  version: string;
  type?: number;
}
// 下发tts语音
export const jt808Command8300Request = (
  data: Jt808Command8300RequestParams
) => {
  const { url, newData } = operateJt808Params(
    data,
    '/808/jt808/8300',
    false,
    true
  );
  return request.post(url, newData, {headers: {'sn-agent': data.phone}});
};

// 获取当前视频监控正在推流的通道
export const onlineChannelListRequest = (version: string) => {
  return request.get(
    `/jtt1078/onlineList/${operateJt808VersionPath(version)}`
  );
};
