/**
 * 部标机设备资源列表
 */
import React, { Fragment, useState, useEffect } from 'react';
import { Button } from 'antd';
import { formatTime } from '@/utils/formatTime';
import { jt808Command9205Request } from '@/service/jtDevicePlayback';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import CommonTable from '@/components/commonTable';
import UploadTimeRangeModal from '../uploadTimeRangeModal';
import styles from '@/styles/pageContent.module.scss';

interface JtDeviceResourceTableProps {
  requestParams: Array<any>; // 获取部标机设备资源列表的请求参数
  onPlayback?: (data: any) => void; // 视频回放
  selectJtDeviceInfo: any; // 选择车辆对应的部标机设备信息
}
const JtDeviceResourceTable = (props: JtDeviceResourceTableProps) => {
  const { requestParams, onPlayback, selectJtDeviceInfo } = props;
  const [dataSource, setDataSource] = useState<any>([]); // 部标机设备资源列表
  const [loading, setLoading] = useState<boolean>(false); // 请求部标机设备资源列表的loading
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的信息存储

  // 获取部标机设备资源列表
  const getJtDeviceResource = async () => {
    const { phone, jt808DeviceId } = selectJtDeviceInfo;
    try {
      setLoading(true);
      const allChannelRequest = requestParams.map((currRequestParams: any) => {
        const { channelNo, codeStream, time } = currRequestParams;
        const params = {
          phone,
          jt808DeviceId,
          channelNo,
          codeStream,
          start: formatTime(time[0]),
          end: formatTime(time[1]),
          type: 0, // 音视频类型
          storageType: 0, // 所有存储器
          alarmFlag: 0,
          alarmFlag1: 0
        };
        return jt808Command9205Request(params);
      });
      const resourceData: any = [];
      const res = await Promise.allSettled(allChannelRequest);
      res.forEach((item: any) => {
        resourceData.push(...(item.value?.data?.data?.resourceList || []));
      });
      setDataSource(resourceData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requestParams?.length) {
      getJtDeviceResource();
    } else {
      setDataSource([]);
    }
  }, [requestParams]);

  // 表格列显示
  const resourceColumns = [
    {
      title: '开始时间',
      dataIndex: 'start',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '结束时间',
      dataIndex: 'end',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '通道号',
      dataIndex: 'channelNo',
      width: 100
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: onPlayback ? 150 : 100,
      fixed: 'right' as const,
      render: (value: any, item: any) => (
        <div className={styles.tableOperateColumn}>
          {Boolean(onPlayback) && (
            <Button type="link" onClick={() => onPlayback && onPlayback(item)}>
              播放
            </Button>
          )}
          <PermissionButton
            operateType="/car-admin/dvr-playback/upload-ftp"
            type="link"
            onClick={() => setOperateInfo({ type: 'upload', data: item })}
          >
            下载到服务器
          </PermissionButton>
        </div>
      )
    }
  ];

  return (
    <Fragment>
      <CommonTable
        dataSource={dataSource}
        columns={resourceColumns}
        columnFilter={false}
        size="small"
        scroll={{ y: 200 }}
        loading={loading}
        pagination={false}
        bordered
      />
      {/* 部标机设备资源上传时选择时间区间 */}
      {operateInfo.type === 'upload' && (
        <UploadTimeRangeModal
          selectJtDeviceInfo={selectJtDeviceInfo}
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
    </Fragment>
  );
};

export default JtDeviceResourceTable;
