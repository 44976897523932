/**
 * 异常车辆汇总
 */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Input, Select, Checkbox } from 'antd';
import { uniq, cloneDeep } from "lodash";
import CommonTable from '@/components/commonTable';
import { controlData } from "@/constants/abnormalSummary";
import CarStatusListModal from '@/components/carStatusListModal';
import FilterTypeTableModal from '../filterTypeTableModal';
import ChartModal from '../chartModal';
import styles from '@/styles/pageContent.module.scss';

interface AbnormalTableProps {
  searchParams: any; // 搜索相关的参数
  errTypeData: any; // 故障项数据
  defaultTableFormData: any; // 表格过滤条件的初始值
  requestMethod: any; // 请求方法
  isKls?: boolean; // 是否是昆仑山设备
}
const AbnormalTable = (props: AbnormalTableProps) => {
  const { searchParams, errTypeData, defaultTableFormData, requestMethod, isKls } = props;
  // 所有过滤项的长度
  const errTypeLength = errTypeData.length;
  const allDataRef = useRef({});
  const [dayColumn, setDayColumn] = useState<any>([]); // 表格要展示日期相关的列内容
  const [tableData, setTableData] = useState<any>([]); // 表格要显示的数据
  const [tableFormValue, setTableFormValue] = useState<any>(cloneDeep(defaultTableFormData)); // 表格内表单筛选数据存储
  const [loading, setLoading] = useState(false);
  const [carModalData, setCarModalData] = useState<any>(null); // 车辆弹窗的相关数据
  const [chartVisible, setChartVisible] = useState(false); // 图表的显示控制
  const [filterTypeData, setFilterTypeData] = useState<any>(null); // 去重弹窗的相关数据
  const [checkIndexs, setCheckIndexs] = useState<any>(errTypeData.map((item: any, index: number) => index)); // 选中的行数据

  // 生成表格过滤的条件参数
  const generateFilterParams = (filterParams: any) => {
    const obj: any = {};
    errTypeData.forEach((item: any, index: number) => {
      obj[item.value] = tableFormValue[index];
    });
    return obj;
  }

  useEffect(() => {
    getData({
      ...searchParams,
      ...generateFilterParams(tableFormValue)
    });
  }, [searchParams]);

  // 处理接口返回数据
  const generateData = (data: any, newCheckIndexs: any) => {
    // 生成每一个故障项的数据
    const newTableData: any = [];
    const newDayColumn: any = [];
    const obj: any = {};
    errTypeData.forEach((item: any, index: number) => {
      let dataCountData: any = {};
      (data[item.value] || []).forEach((item1: any) => {
        dataCountData[item1.day] = item1.carIdArray;
        newDayColumn.push(item1.day);
        // 根据勾选的类型进行车辆合计数的计算
        if (newCheckIndexs.includes(index)) {
          if (obj[item1.day]) {
            obj[item1.day].push(...item1.carIdArray)
          } else {
            obj[item1.day] = cloneDeep(item1.carIdArray)
          }
        }
      })
      newTableData.push({
        errType: item.label,
        ...dataCountData,
      })
    });
    uniq(newDayColumn).forEach((item: any) => {
      obj[item] = uniq(obj[item])
    })
    newTableData.push({
      errType: '车辆合计数',
      ...obj,
    });
    setTableData(newTableData)
    setDayColumn(uniq(newDayColumn));
  }

  // 获取数据
  const getData = async (params: any) => {
    try {
      setLoading(true);
      const res = await requestMethod(params);
      const data: any = res.data?.data || {};
      allDataRef.current = data;
      generateData(data, checkIndexs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 表格内表单value改变
  const onTableFormChange = (value: string | number, index: number, changeField: string, changeData?: boolean) => {
    const newTableFormValue = [...tableFormValue];
    newTableFormValue[index][changeField] = value ? Number(value) : value;
    setTableFormValue(newTableFormValue);
    changeData && getData({
      ...searchParams,
      ...generateFilterParams(newTableFormValue)
    });
  }

  // 渲染每一天数据
  const renderDayColumn = (value: any, errType: string, day: string) => {
    return (
      value?.length ?
      <Button
        type='link'
        onClick={() => setCarModalData({
          carIds: value,
          exportFileName: (errType || '').split('(')[0],
          day
        })}
      >
        {value.length}
      </Button> : 
      <Button type='text'>0</Button>
    )
  }

  // 查重
  const filterType = (value: string, index: number) => {
    setFilterTypeData({
      title: value,
      tableFormValue: tableFormValue,
      index,
      checkIndexs,
      isSum: index === errTypeLength,
      allData: allDataRef.current,
    })
  }

  // 表格的checkBox
  const checkBoxChange = (e: any, index: number) => {
    let newCheckIndexs = [...checkIndexs];
    if (e.target.checked) {
      newCheckIndexs.push(index);
    } else {
      newCheckIndexs = checkIndexs.filter((item: any) => item !== index);
    }
    setCheckIndexs(newCheckIndexs);
    generateData(allDataRef.current, newCheckIndexs);
  }

  // 表格列显示
  const columns: any = [
    {
      title: '',
      dataIndex: 'check',
      width: 50,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => (
        index === errTypeLength ?
        null :
        <Checkbox onChange={(e) => checkBoxChange(e, index)} checked={checkIndexs.includes(index)} />
      ),
    },
    {
      title: '故障项',
      dataIndex: 'errType',
      width: 160,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => (
        <span>
          {value}
          {errTypeLength == 8 ? null : (index === errTypeLength && !checkIndexs.length) ? null : <div>
            <Button type="primary" size="small" onClick={() => filterType(value, index)}>查重</Button>
          </div>}
        </span>
      ),
    },
    {
      title: '次数阈值',
      dataIndex: 'count',
      width: 100,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => (
        index === errTypeLength ?
        null :
        <Input
          value={tableFormValue[index]?.count}
          style={{ width: '100%' }}
          onBlur={(e) => onTableFormChange(e.target.value, index, 'count', true)}
          onChange={(e) => onTableFormChange(e.target.value, index, 'count')}
        />
      ),
    },
    {
      title: '控制盒固件',
      dataIndex: 'mcuVersion',
      width: 100,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => (
        index === errTypeLength ?
        null :
        <Select
          value={tableFormValue[index]?.mcuVersion}
          options={controlData}
          style={{ width: '100%' }}
          onChange={(value) => onTableFormChange(value, index, 'mcuVersion', true)}
        />
      ),
    },
    {
      title: 'HMW频次(最大值)',
      dataIndex: 'hmwMax',
      width: 100,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => {
        return (
          [0, 3, 4, 5].includes(index) ?
          <Input
            value={tableFormValue[index]?.hmwMax}
            style={{ width: '100%' }}
            onBlur={(e) => onTableFormChange(e.target.value, index, 'hmwMax', true)}
            onChange={(e) => onTableFormChange(e.target.value, index, 'hmwMax')}
          /> :
          null
        )
      },
    },
    {
      title: '超声波制动频次(最大值)',
      dataIndex: 'ultrMax',
      width: 100,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => {
        return (
          index === 6 ?
          <Input
            value={tableFormValue[index]?.ultrMax}
            style={{ width: '100%' }}
            onBlur={(e) => onTableFormChange(e.target.value, index, 'ultrMax', true)}
            onChange={(e) => onTableFormChange(e.target.value, index, 'ultrMax')}
          /> :
          null
        )
      },
    },
    {
      title: '行驶里程(最大值)',
      dataIndex: 'mileageMax',
      width: 100,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => {
        return (
          errTypeLength == 8 ? null :
          index === 1 ?
          <Input
            value={tableFormValue[index]?.mileageMax}
            style={{ width: '100%' }}
            onBlur={(e) => onTableFormChange(e.target.value, index, 'mileageMax', true)}
            onChange={(e) => onTableFormChange(e.target.value, index, 'mileageMax')}
          /> : 
          null
        )
      },
    },
    {
      title: '行驶里程(最小值)',
      dataIndex: 'mileageMin',
      width: 100,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => {
        return (
          errTypeLength == 8 ? ([0, 1, 2, 3, 4, 5, 6, 7].includes(index) ?
          <Input
            value={tableFormValue[index]?.mileageMin}
            style={{ width: '100%' }}
            onBlur={(e) => onTableFormChange(e.target.value, index, 'mileageMin', true)}
            onChange={(e) => onTableFormChange(e.target.value, index, 'mileageMin')}
          /> : 
          null) :
          [0, 2, 3, 4, 5, 8, 9, 10, 11, 12,16].includes(index) ?
          <Input
            value={tableFormValue[index]?.mileageMin}
            style={{ width: '100%' }}
            onBlur={(e) => onTableFormChange(e.target.value, index, 'mileageMin', true)}
            onChange={(e) => onTableFormChange(e.target.value, index, 'mileageMin')}
          /> : 
          null
        )
      },
    },
    {
      title: 'AEB次数(最大值)',
      dataIndex: 'aebMax',
      width: 100,
      fixed: 'left' as 'left',
      render: (value: any, item: any, index: number) => {
        return (
          [10].includes(index) ?
          <Input
            value={tableFormValue[index]?.aebMax}
            style={{ width: '100%' }}
            onBlur={(e) => onTableFormChange(e.target.value, index, 'aebMax', true)}
            onChange={(e) => onTableFormChange(e.target.value, index, 'aebMax')}
          /> : 
          null
        )
      },
    },
  ];
  // 昆仑山设备不显示控制盒固件列
  if (isKls) {
    columns.splice(3, 1);
  }

  // adas设备不显示
  if (errTypeLength == 1) {
    columns.splice(3, 2);
    columns.splice(5, 1);
  }

  // 添加日期列
  dayColumn.forEach((item: string) => columns.push({
    title: item,
    dataIndex: item,
    width: 120,
    render: (value: any, record: any) => renderDayColumn(value, record.errType, item),
  }))

  return (
    <div className={styles.commonTableWrapper}>
      <div style={{ textAlign: 'right', margin: '12px 0' }}>
        <Button type='primary' onClick={() => setChartVisible(true)}>显示图表趋势</Button>
      </div>
      <CommonTable
        columnFilter={false}
        rowKey="errType"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        loading={loading}
      />
      {Boolean(carModalData) &&
        <CarStatusListModal
          carIds={carModalData.carIds}
          day={carModalData.day}
          closeModal={() => setCarModalData(null)}
          exportFileName={carModalData.exportFileName}
          beginDate={searchParams.beginDate}
          endDate={searchParams.endDate}
          isKls={isKls}
          exportPermissionKey="/car-admin/abnormal-summary/carExport"
          errTypeLength = {errTypeLength}
        />
      }
      {Boolean(filterTypeData) &&
        <FilterTypeTableModal
          data={filterTypeData}
          closeModal={() => setFilterTypeData(null)}
          dayColumn={dayColumn}
          searchParams={searchParams}
          errTypeData={errTypeData}
          isKls={isKls}
        />
      }
      {chartVisible &&
        <ChartModal
          dayColumn={dayColumn}
          data={tableData}
          tableFormValue={tableFormValue}
          closeModal={() => setChartVisible(false)}
          errTypeData={errTypeData}
        />
      }
    </div>
  )
}

export default AbnormalTable;
  