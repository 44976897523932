/**
 * 车身信息
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, message } from 'antd';
import dayjs from 'dayjs';
import { carInfoListRequest, carInfoListRequestPath } from '@/service/carInfo';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { snColumnConfig } from '@/constants/tableColumn';
import { ownRound } from '@/utils/math';
import { useTable } from '@/utils/useTable';
import { renderOffOnColumn, renderGearColumn, renderWheelStatusColumn } from '@/utils/carInfo';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const CarInfo = () => {
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { activeKey, tabParams } = tabPagesStore;
  const [selectCarIds, setSelectCarIds] = useState<Array<number>>([]); // 选择的车辆id
  const [hasSearched, setHasSearched] = useState<boolean>(false); // 是否搜索过
  const [searchParams, setSearchParams] = useState({
    startTime: `${dayjs().format('YYYY-MM-DD')} 00:00:00`,
    endTime: `${dayjs().format('YYYY-MM-DD')} 23:59:59`,
  }); // 搜索相关的参数
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carInfoListRequest,
    initRequest: false,
    initPageSize: 90,
    searchParams,
  });

  // 监听跳转时携带参数的形式
  useEffect(() => {
    if (activeKey === '/car-admin/car-info' && tabParams) {
      setSelectCarIds(tabParams.carIds);
      const newTabParams = {
        time: [
          dayjs(`${tabParams.day} 00:00:00`),
          dayjs(`${tabParams.day} 23:59:59`),
        ]
      };
      form.setFieldsValue(newTabParams);
      form.submit();
    }
  }, [activeKey, tabParams]);

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    if (selectCarIds.length === 0) {
      message.info("请选择车辆");
      return;
    }
    const newParams = { ...params };
    delete newParams.time;
    newParams.startTime = dayjs(params.time[0]).format('YYYY-MM-DD HH:mm:ss');
    newParams.endTime = dayjs(params.time[1]).format('YYYY-MM-DD HH:mm:ss');
    newParams.idList = selectCarIds;
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams: any = operateSearchParams(values);
    if (newSearchParams) {
      setSearchParams(newSearchParams);
      setHasSearched(true)
    }
  }

    // 获取导出参数
    const getExportParams = () => {
      const values = form.getFieldsValue();
      return operateSearchParams(values, true);
    }

  // 左侧车辆选择
  const treeCheck = (carIds: Array<number>) => {
    setSelectCarIds(carIds)
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    snColumnConfig,
    {
      title: '车身速度',
      dataIndex: 'vehicleSpeed',
      width: 70,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={ownRound(value, 1)} />,
    },
    {
      title: 'GPS车速',
      dataIndex: 'speed',
      width: 70,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={ownRound(value, 1)} />,
    },
    {
      title: 'AEB状态',
      dataIndex: 'aebsOn',
      width: 90,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '左转向灯',
      dataIndex: 'signalLeftOn',
      width: 90,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '右转向灯',
      dataIndex: 'signalRightOn',
      width: 90,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '刹车灯',
      dataIndex: 'signalBreakOn',
      width: 80,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '档位',
      dataIndex: 'gearStatus',
      width: 70,
      render: (value: number) => renderGearColumn(value),
      defaultHide: true,
    },
    {
      title: '设备状态',
      dataIndex: 'deviceStatusDesc',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '油门开度',
      dataIndex: 'throttle',
      width: 90,
      defaultHide: true,
    },
    {
      title: '刹车开度',
      dataIndex: 'brake',
      width: 90,
      defaultHide: true,
    },
    {
      title: '方向盘角度',
      dataIndex: 'steeringWheel',
      width: 110,
      defaultHide: true,
    },
    {
      title: '方向盘状态',
      dataIndex: 'steeringWheelStatus',
      width: 110,
      render: (value: number) => renderWheelStatusColumn(value),
      defaultHide: true,
    },
    {
      title: '时间',
      dataIndex: 'reportedTime',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value} />,
    },
  ]

  return (
    <div className={styles.spaceBetweenPageContentBox}>
      <div className={styles.carTree}>
        <CarTree
          checkable
          treeCheck={treeCheck}
        />
      </div>
      <div className={styles.contentBox}>
        <div>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={onSearch} form={form}>
            <Form.Item label="时间范围" name="time" initialValue={[dayjs(searchParams.startTime), dayjs(searchParams.endTime)]}>
              <RangePicker showTime allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item>
              <div className={styles.searchButtonBox}>
                <Button type="primary" htmlType="submit">查询</Button>
                <ExportButton
                  url={carInfoListRequestPath}
                  fileName='车身信息'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/car-info/export'
                />
              </div>
            </Form.Item>
          </Form>
        </div>
        {hasSearched ?
          <div className={styles.tableBox}>
            <CommonTable
              rowKey="id"
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalCount,
                current: page,
                pageSize: limit,
                onChange: onPageChange,
              }}
              bordered
              loading={loading}
            />
          </div> :
          <div className={styles.tipBox}>
            请在左侧选择车辆后点击“查询”按钮
          </div>
        }
      </div>
    </div>
  )
}

export default CarInfo;
   