/**
 * 操作运营知识库(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { upsertDocument } from '@/service/documentData';
import { tagListRequest } from '@/service/tagManage';
import { TagCategoryEnum } from '@/constants/tagManage';
import UploadFile from "@/components/uploadFile";
import UploadImageVideo from "@/components/uploadImageVideo";


interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [uploadFileList, setUploadFileList] = useState<any>([]); // 上传成功的文件信息
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [classData, setClassData] = useState<any>([]); // 知识库类型的标签

  // 编辑的时候赋值
  useEffect(() => {
    getServiceProblemType()
    if (type === 'edit') {
      if (data?.resource?.contentType == "imag") {
        setUploadSuccessList([data?.resource])
      } else if (data?.resource?.contentType == "application/pdf") {
        setUploadFileList([data?.resource])
      }
      const params = { ...data };
      form.setFieldsValue(params)
    }
  }, []);


  // 获取知识库类型标签
  const getServiceProblemType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.documentData],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setClassData(tags);
  }

  // 提交
  const submit = async (values: any) => {
    // 验证是否重复提交
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      params.keyWords = params.keyWord.split(",");
      if (type === 'edit') {
        params.id = data.id;
      }
      // 如果是文件上传
      if (uploadFileList.length > 0) {
        const aaa = {
          ...params,
          resource: {
            key: uploadFileList[0].key,
            contentType: uploadFileList[0].contentType,
            fileName: uploadFileList[0].fileName
          }
        }
        caseTypeSubmit(aaa)
      }
      // 如果是图片上传
      else if (uploadSuccessList.length > 0) {
        const aaa = {
          ...params,
          resource: {
            key: uploadSuccessList[0].key,
            contentType: uploadSuccessList[0].contentType.slice(0, 4),
            fileName: uploadSuccessList[0].fileName
          }
        }
        caseTypeSubmit(aaa)
      }
      // 如果是内容上传
      else if (uploadSuccessList.length == 0 && uploadFileList.length == 0) {
        if (params.content) {
          caseTypeSubmit(params)
        } else {
          message.error('请添加内容/图片/文件');
          setSubmitNoRepetition(true)
        }
      }
    }
  }

  // 分类提交
  const caseTypeSubmit = async (params: any) => {
    const res = await upsertDocument(params)
    if (res.status == 200) {
      setSubmitNoRepetition(true)
    }
    if (res.data?.code === 0) {
      message.success(type === 'add' ? '添加成功' : '修改成功');
      closeModal();
      reloadData();
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加运营知识' : '编辑运营知识'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form form={form} autoComplete="off" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={submit}>
        <Form.Item label="类别" name="type" rules={[{ required: true, message: '请选择类别' }]} >
          <Select
            options={classData}
            placeholder="请选择类别"
            showSearch
            allowClear
          />
        </Form.Item>
        <Form.Item label="标题" name="title" rules={[{ required: true, message: '请输入标题' }]}>
          <Input placeholder='请输入标题' />
        </Form.Item>
        <Form.Item label="内容" name="content">
          <Input placeholder="请输入内容" />
        </Form.Item>
        <Form.Item label="关键字" name="keyWord" rules={[{ required: true, message: '请输入关键字' }]}>
          <Input placeholder="请输入关键字"  pattern="[A-Za-z,]*"/>
        </Form.Item>
        {/* 文件长度>0则文件被上传过  不显示图片 */}
        {uploadFileList.length > 0 || <Form.Item name="resource" label="图片上传">
          <UploadImageVideo
            businessName="图片"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data?.resource?.contentType == "imag" ? [{ contentType: "image", key: data?.resource.key, preview: data?.resource.url }] : []}
            multiple
            maxCount={1}
          />
        </Form.Item>}
        {uploadSuccessList.length > 0 || <Form.Item label="上传文件" name="photo" >
          <UploadFile
            accept="application/pdf"
            uploadFilesChange={(successList: any) => setUploadFileList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data?.resource?.contentType == "application/pdf" ? [{
              fileName: data?.resource?.fileName,
              contentType: data?.resource?.contentType,
              key: new URL(data?.resource?.url)?.pathname?.slice(1),
              preview: data?.resource?.url
            }] : []}
            maxCount={1}
            canUploadVideo
            limitSize={500}
            multiple
          />
        </Form.Item>}
      </Form>
    </Modal>
  )
}

export default OperateModal;
