/**
 * 部标机报警视频上传规则表格
 */
import React, { Fragment, useState } from 'react';
import { message, Popconfirm } from 'antd';
import { isNil } from 'lodash';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from '@/components/permissionButton';
import {
  alarmRuleData,
  warningLevelData
} from '@/constants/jtDeviceUploadRule';
import { deleteJtDeviceUploadRuleRequest } from '@/service/jtDeviceUploadRule';
import OperateModal from '../operateModal';
import styles from '@/styles/pageContent.module.scss';

interface RuleTableProps {
  loading: boolean;
  data: any;
  getData: any;
  hiddenOperate?: boolean;
  rowSelection?: any;
}
const RuleTable = (props: RuleTableProps) => {
  const { loading, data, getData, hiddenOperate, rowSelection } = props;
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息

  // 删除部标机设备上传配置
  const deleteJtDeviceUploadConfig = async (id: number) => {
    const res = await deleteJtDeviceUploadRuleRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      getData();
    }
  };

  // 渲染报警上传规则列
  const renderAlarmRuleColumn = (rule: any, key: number) => {
    if (isNil(rule[key])) {
      return '不上传';
    } else {
      return (
        warningLevelData.find((item: any) => item.value === rule[key])?.label ||
        ''
      );
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '规则名称',
      dataIndex: 'name',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '视频时间偏移量',
      dataIndex: 'timeOffset',
      width: 130
    },
    ...alarmRuleData.map((item: any) => ({
      title: item.title,
      dataIndex: 'ruleMap',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        <RenderEllipsisColumn text={renderAlarmRuleColumn(value, item.key)} />
      )
    })),
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/dvr-upload-rule/edit"
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该部标机设备上传规则吗?"
              onConfirm={() => deleteJtDeviceUploadConfig(item.id)}
            >
              <PermissionButton
                operateType="/car-admin/dvr-upload-rule/delete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  if (hiddenOperate) {
    columns.splice(columns.length - 1, 1);
  }

  return (
    <Fragment>
      <CommonTable
        rowKey="id"
        reloadFunc={getData}
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
        rowSelection={rowSelection}
      />
      {operateInfo.type === 'edit' && (
        <OperateModal
          type={operateInfo.type}
          data={operateInfo.data}
          onClose={() => setOperateInfo({})}
          reloadData={getData}
        />
      )}
    </Fragment>
  );
};

export default RuleTable;
