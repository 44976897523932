/**
 * 轨迹回放
 */
import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Form, Button, Slider, Row, Col, Input, message, Checkbox, Switch, Space, Drawer, Select, Card} from 'antd';
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import {groupBy} from 'lodash';
import dayjs from 'dayjs';
import gcoord from 'gcoord';
import {
  currentLocationRequest,
  dayGpsRequest,
  dayAlarmRequest,
  DayInfoRequestParams,
  gpsListExportPath,
  historyDayGpsRequest
} from '@/service/trackPlayback';
import {adasCurrentLocationRequest} from '@/service/adasTrackPlayback';
import {carLastLocationRequest, findOneCarRequest} from '@/service/carManage';
import {importAliMap} from '@/utils/map';
import {ownRound} from '@/utils/math';
import {formatTime} from '@/utils/formatTime';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import ExportButton from '@/components/exportButton';
import CommonTable from '@/components/commonTable';
import LoadingWrapper from '@/components/loadingWrapper';
import {filterNotInChina, filterAdjoinPoint} from '@/utils/gps';
import {getAlarmTypeDisplay} from '@/utils/alarm';
import {getAdasAlarmType} from '@/utils/adasAlarm';
import carPointImg from '@/assets/image/forward.png';
import styles from './index.module.scss';
import {
  FastForwardFilled,
  PauseCircleFilled,
  PlayCircleFilled,
  CloseCircleFilled,
  FullscreenOutlined
} from '@ant-design/icons';

const TrackPlayback = () => {
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const {activeKey, tabParams} = tabPagesStore;
  const mapRef: any = useRef(null); // 存储地图实例
  const ruleRef: any = useRef([]); // 存储尺子实例(可能会有多个)
  const animationMarkerRef: any = useRef(null); // 存储轨迹回放的marker
  const trackAniRef: any = useRef({}); // 存储轨迹回放动画数据
  const alarmLabelsRef: any = useRef({1: [], 2: [], 3: [], 4: [], 5: [], 6: []}); // 存储报警的label信息
  const [form] = Form.useForm();
  const [alarmLabelsChecked, setAlarmLabelsChecked] = useState<any>({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false
  }); // 是否勾选报警的label
  const [itineraryList, setItineraryList] = useState([]); // 行程数据
  const [loading, setLoading] = useState(false); // 数据是否在加载中
  const [hasSearch, setHasSearch] = useState(false); // 是否搜索过
  const [adasSwitch, setAdasSwitch] = useState(Boolean)
  const [operationStartTime, SetOperationStartTime] = useState(''); // 运营开始时间
  const [operationStoptTime, SetOperationStoptTime] = useState(''); // 运营停驶时间
  const [runningTime, setRunningTime] = useState(''); // 运行时长
  const [downTime, setDownTime] = useState(''); // 停驶时长
  const [stopTimes, setStopTimes] = useState(''); // 停驶次数
  const [MaxtopTimes, setMaxtopTimes] = useState('');  //最长停驶时长
  const [MinStopTimes, setMinStopTimes] = useState('');  //最短停驶时长
  const [progressValue, setProgressValue] = useState(Number);
  const [progressMaxValue, setProgressMaxValue] = useState(Number);

  const [currentItiner, setCurrentItiner] = useState<any>(); // 当前选中的行程数据
  const [currentItinerId, setCurrentItinerId] = useState(''); // 当前选中的行程数据d
  const [open, setOpen] = useState(false); //抽屉状态 
  const [travelId, setTravelId] = useState<any>([]); // 行驶Id的显示 
  const [currentId, setcurrentId] = useState(''); // 当前行程段id
  const [currentData, setcurrentData] = useState<any>([]) //当前行程段gpsData
  const [currentSpeed, setCurrentSpeed] = useState('');// 速度
  const [isPlaying, setIsPlaying] = useState(false); // 是否播放中
  const [val, setVal] = useState(''); // 存储value
  const [clicking, setClicking] = useState(false); //设置点击之后样式
  const [groupGpsData, setGroupGpsData] = useState<any>([]); //存储行程数据
  const [showSwitch, setShowSwitch] = useState<Boolean>(true)
  const [selectCarInfo, setSelectCarInfo] = useState<{[key: string]: any}>({})

  useEffect(() => {
    importAliMap(initMap);
  }, [])

  // 监听跳转时携带参数的形式
  useEffect(() => {
    tabParamsSearch()
  }, [activeKey, tabParams]);

  // 跳转时携带参数时搜索
  const tabParamsSearch = async () => {
    let mapSuccess: any = false;
    if (window.BMap && mapRef.current) {
      mapSuccess = true;
    } else {
      mapSuccess = await importAliMap(initMap);
    }
    if (mapSuccess && activeKey === '/car-admin/track-playback' && tabParams) {
      const newTabParams = {
        plateNumber: tabParams.plateNumber,
        trickDate: dayjs(tabParams.day),
      };
      form.setFieldsValue(newTabParams);
      form.submit();
    }
  }

  // 地图初始化
  const initMap = async () => {
    try {
      mapRef.current = new window.AMap.Map('trackMap', {
        center: [116.368904, 39.913423],
        zoom: 15,
        animateEnable: false,
        WebGLParams: {
          preserveDrawingBuffer: true
        }
      });
      // 加载插件
      window.AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.MapType'], function () {
        mapRef.current.addControl(new window.AMap.ToolBar({
          position: {
            bottom: '60px',
            left: '20px'
          }
        }));
        mapRef.current.addControl(new window.AMap.Scale());
        mapRef.current.addControl(new window.AMap.MapType());
      });
    } catch (error) {
      message.error('地图初始化失败');
    }
  }


  // ADAS开关
  const adasSwitchover = (checked: boolean) => {
    setAdasSwitch(checked)
  }

  useEffect(() => {
    if (selectCarInfo.metaId) {
      getSelectCarLocation(selectCarInfo)
    }
  }, [adasSwitch])

  // 获取选择车辆的位置(在地图中心显示)
  const getSelectCarLocation = async (car: any, type?:Boolean) => {
    const adasSwitchType = type === undefined ? adasSwitch : type
      const res = await carLastLocationRequest({carId: car.metaId, plateNumber: car.title_base, isKls: adasSwitchType ? false : true });
      const locationData = res?.data?.data || {};
      if (locationData.lon && locationData.lat) {
        const transformGcoord = gcoord.transform([locationData.lat, locationData.lon], gcoord.WGS84, gcoord.AMap);
        const lnglat = new window.AMap.LngLat(transformGcoord[0], transformGcoord[1]);
        const icon = new window.AMap.Icon({
          image: carPointImg,
          imageSize: new window.AMap.Size(36, 36)
        });
        const marker = new window.AMap.Marker({
          position: lnglat,
          offset: new window.AMap.Pixel(-10, -10),
          icon,
        });
        mapRef.current.setCenter(lnglat);
        mapRef.current.add(marker);
      }

    // const params: any = await operateSearchParams(form.getFieldsValue());
    // getDayGps(params);
  }

  // 选择车辆
  const treeSelect = (selectCarInfo: any) => {
    const {selected, metaId, title, sn, jt808DeviceId } = selectCarInfo;
    console.log(selectCarInfo, '12312312312323123')
    cancelAllAlarmChecked();
    setSelectCarInfo(selectCarInfo)
    if (!selected) {
      let type = false;
      form.setFieldsValue({plateNumber: title});
      if (sn && jt808DeviceId) {
        setShowSwitch(true)
      } else {
        setShowSwitch(false)
      }
      if (sn && !jt808DeviceId) {
        type = false
        adasSwitchover(type)
        getSelectCarLocation(selectCarInfo, type);
      }
      if (jt808DeviceId && !sn) {
        type = true
        adasSwitchover(type)
        getSelectCarLocation(selectCarInfo, type);
      }
      if (jt808DeviceId && sn) {
        getSelectCarLocation(selectCarInfo);
      }
      
      

    } else {
      form.setFieldsValue({plateNumber: ''})
    }
  }

  // 信息弹窗展示
  const showInfoWindow = (showPoint: any) => {
    window.AMap.plugin('AMap.Geocoder', function () {
      const geocoder = new window.AMap.Geocoder();
      geocoder.getAddress([showPoint.lnglat.lng, showPoint.lnglat.lat], (status: string, result: any) => {
        if (status === 'complete' && result.regeocode) {
          const originData = showPoint?.target?.originData || {};
          const {speed, vehicleSpeed, reportedTime} = originData;
          // let content = `速度: ${ownRound(vehicleSpeed || speed, 1)}<br>时间: ${formatTime(reportedTime, 'YYYY-MM-DD HH:mm:ss')}<br>地址: ${result.regeocode.formattedAddress}`;
          let content = `GPS速度: ${speed}<br>车身速度: ${vehicleSpeed}<br>时间: ${formatTime(reportedTime, 'YYYY-MM-DD HH:mm:ss')}<br>地址: ${result.regeocode.formattedAddress}`;
          const mapInfoWindow = new window.AMap.InfoWindow({content});
          mapInfoWindow.open(mapRef.current, [showPoint.lnglat.lng, showPoint.lnglat.lat]);
        }
        const originData = showPoint?.target?.originData || {};
        const {speed, vehicleSpeed, reportedTime} = originData;
        let content = `GPS速度: ${speed}<br>车身速度: ${vehicleSpeed}<br>时间: ${formatTime(reportedTime, 'YYYY-MM-DD HH:mm:ss')}`;
        const mapInfoWindow = new window.AMap.InfoWindow({content});
        mapInfoWindow.open(mapRef.current, [showPoint.lnglat.lng, showPoint.lnglat.lat]);
      });
    });

  }

  const getDayAdasGps = async (params: DayInfoRequestParams) => {
    const res = await dayGpsRequest({...params, isKls: false});
    const gpsData = res.data?.data.gps || [];
    const beginTime = formatTime(res.data?.data.beginTime);
    const endTime = formatTime(res.data?.data.endTime);
    SetOperationStartTime(beginTime)
    SetOperationStoptTime(endTime)
    setRunningTime(res.data?.data.operateTime)
    setDownTime(res.data?.data.stoppingTime)
    setStopTimes(res.data?.data.stopCount)
    setMaxtopTimes(res.data?.data.longStoppingTime)
    setMinStopTimes(res.data?.data.shortStoppingTime)
    const filterGpsData = gpsData.filter(filterNotInChina).filter(filterAdjoinPoint);
    if (filterGpsData.length) {
      // 根据GPS数据生成行程列表数据
      const groupGpsData = groupBy(filterGpsData, 'itineraryId');
      const newItineraryList: any = [];
      Object.keys(groupGpsData).reverse().forEach((item: any) => {
        newItineraryList.push({
          id: item,
          gpsData: groupGpsData[item] || [],
        });
      })
      // 地图实例用于显示位置
      newItineraryList.map((item: any, index: any) => {
        window.AMap.plugin('AMap.Geocoder', function () {
          const geocoder = new window.AMap.Geocoder();
          geocoder.getAddress([item.gpsData[item.gpsData.length - 1].lat, item.gpsData[item.gpsData.length - 1].lon], (status: string, result: any) => {
            if (status === 'complete' && result.regeocode) {
              newItineraryList[index].describeLocation = result.regeocode.formattedAddress
            }
            if (index === newItineraryList.length - 1) {
              setItineraryList(newItineraryList);
            }
          });
        });
      })
      setGroupGpsData(newItineraryList)
      drawLinesOnMap(filterGpsData)
    } else {
      setItineraryList([]);
    }

  }
  const getDayAebGps = async (params: DayInfoRequestParams) => {

    //非adas GPS点位信息
    //根据搜索日期调用不同接口
    let dateValue = Number(params.trickDate.split('-').map(item => item.padStart(2, '0')).join(''))
    if (dateValue <= 20231001) {
      var res = await historyDayGpsRequest(params);
    } else {
      var res = await dayGpsRequest({...params, isKls: true});
    }
    const gpsData = res.data?.data.gps || [];
    const beginTime = formatTime(res.data?.data.beginTime);
    const endTime = formatTime(res.data?.data.endTime);
    SetOperationStartTime(beginTime)
    SetOperationStoptTime(endTime)
    setRunningTime(res.data?.data.operateTime)
    setDownTime(res.data?.data.stoppingTime)
    setStopTimes(res.data?.data.stopCount)
    setMaxtopTimes(res.data?.data.longStoppingTime)
    setMinStopTimes(res.data?.data.shortStoppingTime)
    const filterGpsData = gpsData.filter(filterNotInChina).filter(filterAdjoinPoint);
    if (filterGpsData.length) {
      // 根据GPS数据生成行程列表数据
      const groupGpsData = groupBy(filterGpsData, 'itineraryId');
      const newItineraryList: any = [];
      const ll: any = [];
      Object.keys(groupGpsData).reverse().forEach((item: any) => {
        newItineraryList.push({
          id: item,
          gpsData: groupGpsData[item] || [],
          describeLocation: ""
        });
      })
      // 地图实例用于显示位置
      newItineraryList.map((item: any, index: any) => {
        window.AMap.plugin('AMap.Geocoder', function () {
          const geocoder = new window.AMap.Geocoder();
          geocoder.getAddress([item.gpsData[item.gpsData.length - 1].lat, item.gpsData[item.gpsData.length - 1].lon], (status: string, result: any) => {
            if (status === 'complete' && result.regeocode) {
              newItineraryList[index].describeLocation = result.regeocode.formattedAddress
            }
            if (index === newItineraryList.length - 1) {
              setItineraryList(newItineraryList);
            }
          });
        });
      })
      setGroupGpsData(newItineraryList)
      drawLinesOnMap(filterGpsData);
    } else {
      setItineraryList([]);
    }
  }

  const drawLinesOnMap = (filterGpsData: any) => {
    mapRef.current.clearMap()
    const paths: any = [];
    filterGpsData.forEach((item: any) => {
      const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
      paths.push(new window.AMap.LngLat(transformGcoord[0], transformGcoord[1]));
    })
    // 根据gps点位画线
    const polyline = new window.AMap.Polyline({
      path: paths,
      borderWeight: 5,
      strokeColor: '#BB2299',
      lineJoin: 'round'
    });
    mapRef.current.add(polyline);

    if (adasSwitch){
      // 创建一条时间轴
      const timeline = new window.AMap.Timeline({
        autoPlay: true,
        duration: 10000,
        data: paths,
      });

      // 将时间轴添加到地图
      mapRef.current.add(timeline);
    }
    // 绘制GPS点位
    paths.forEach((item: any, index: number) => {
      let content = `<div class=${styles.gpsPoint} />`;
      let pixelNumber = -6;
      let zIndex = 120;
      if (index === 0) {
        content = `<div class=${styles.textIcon}>起</div>`;
        pixelNumber = -10;
        zIndex = 122;
      }
      if (index === paths.length - 1) {
        content = `<div class=${styles.textIcon}>终</div>`;
        pixelNumber = -10;
        zIndex = 122;
      }
      const point = new window.AMap.Text({
        position: item,
        content,
        title: `GPS速度: ${ownRound(filterGpsData[index].speed, 1)}; 时间: ${formatTime(filterGpsData[index].reportedTime, 'YYYY-MM-DD HH:mm:ss')}`,
        offset: new window.AMap.Pixel(pixelNumber, pixelNumber),
        zIndex,
      });
      mapRef.current.add(point);
      point.originData = filterGpsData[index];
      point.on('click', showInfoWindow);
    })
    mapRef.current.setCenter(paths[0]);

  }

  // 获取adas GPS点位信息(连线显示)
  const getDayGps = async (params: DayInfoRequestParams) => {
    if (adasSwitch) {
      getDayAdasGps(params)
    } else {
      getDayAebGps(params)
    }
  }

  const getDayAdasAlarm = async (params: DayInfoRequestParams) => {
    try {
      setLoading(true);
      const res = await dayAlarmRequest({...params, isKls: false});
      const alarmData = res.data?.data || [];
      if (alarmData.length) {
        const data = alarmData.filter(filterNotInChina).filter(filterAdjoinPoint);
        // 用于承载LabelMarker的图层
        const labelsLayer = new window.AMap.LabelsLayer({
          collision: false,
          allowCollision: false,
          zooms: [0, 20],
        });
        data.forEach((item: any) => {
          const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
          const lnglat = new window.AMap.LngLat(transformGcoord[0], transformGcoord[1]);
          // 创建一个 LabelMarker 实例
          const alarmMarker = new window.AMap.LabelMarker({
            position: lnglat,
            icon: {
              image: 'https://webapi.amap.com/ui/1.1/ui/overlay/SimpleMarker/assets/default/default-fs8.png',
              anchor: 'bottom-center',
              size: [25, 34],
              clipOrigin: [0, 0],
              clipSize: [36, 46]
            },
          });
          alarmMarker.originData = item;
          alarmMarker.on('click', showInfoWindow);
          // 将 LabelMarker 实例添加到 LabelsLayer 上
          labelsLayer.add(alarmMarker);
          // 存储报警信息文本标注，勾选信息时显示
          const labelString = `<div class=${styles.alarmLable}>报警类型: ${getAdasAlarmType(item.alarmType)}
            <br/>速度: ${item.speed}
            <br/>时间: ${formatTime(item.reportedTime, 'YYYY-MM-DD HH:mm:ss')}</div>`;
          // 创建文本标注
          const label = new window.AMap.Text({
            position: lnglat,
            content: labelString,
            offset: new window.AMap.Pixel(-10, -10),
            zIndex: 121,
            topWhenClick: true,
          })
          switch (item.alarmType) {
            case 9:
              alarmLabelsRef.current[1].push(label);
              break;
            case 10:
              alarmLabelsRef.current[2].push(label);
              break;
            case 11:
              alarmLabelsRef.current[3].push(label);
              break;
            case 12:
              alarmLabelsRef.current[4].push(label);
              break;
          }
        });
        // 将 LabelsLayer 添加到地图上
        mapRef.current.add(labelsLayer);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  const getDayAebAlarm = async (params: DayInfoRequestParams) => {
    try {
      setLoading(true);
      const res = await dayAlarmRequest({...params, isKls: true});
      const alarmData = res.data?.data || [];
      if (alarmData.length) {
        const data = alarmData.filter(filterNotInChina).filter(filterAdjoinPoint);
        // 用于承载LabelMarker的图层
        const labelsLayer = new window.AMap.LabelsLayer({
          collision: false,
          allowCollision: false,
          zooms: [0, 20],
        });
        data.forEach((item: any) => {
          window.AMap.plugin('AMap.Geocoder', function () {
            const geocoder = new window.AMap.Geocoder();
            geocoder.getAddress([item.lat, item.lon], (status: string, result: any) => {
              if (status === 'complete' && result.regeocode) {
                const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
                const lnglat = new window.AMap.LngLat(transformGcoord[0], transformGcoord[1]);
                // 创建一个 LabelMarker 实例
                const alarmMarker = new window.AMap.LabelMarker({
                  position: lnglat,
                  icon: {
                    image: 'https://webapi.amap.com/ui/1.1/ui/overlay/SimpleMarker/assets/default/default-fs8.png',
                    anchor: 'bottom-center',
                    size: [25, 34],
                    clipOrigin: [0, 0],
                    clipSize: [36, 46]
                  },
                });
                alarmMarker.originData = item;
                alarmMarker.on('click', showInfoWindow);
                // 将 LabelMarker 实例添加到 LabelsLayer 上
                labelsLayer.add(alarmMarker);
                // 存储报警信息文本标注，勾选信息时显示
                const labelString = `<div class=${styles.alarmLable}>报警类型: ${getAlarmTypeDisplay(item)}
                  ${item.vehicleSpeed === null ? '' : `<br>车身速度: ${ownRound(item.vehicleSpeed, 1)}`}
                  <br/>时间: ${formatTime(item.reportedTime, 'YYYY-MM-DD HH:mm:ss')}
                  ${item.gpsSpeed === null ? '' : `<br>gps速度: ${ownRound(item.gpsSpeed, 1)}`}
                  ${item.riskLevel === null || item.riskLevel !== 1 && item.riskLevel !== 2 && item.riskLevel !== 3 ? '' : `<br>风险等级: ${getRiskLevel(item.riskLevel)}`}
                  ${item.sateNum === null ? '' : `<br>4G信号强度: ${getSateNum(item.sateNum)}`}
                  ${item.lat === null ? '' : `<br>地址: ${result.regeocode.formattedAddress}`}
                  </div>`;
                // 创建文本标注
                const label = new window.AMap.Text({
                  position: lnglat,
                  content: labelString,
                  offset: new window.AMap.Pixel(-10, -10),
                  zIndex: 121,
                  topWhenClick: true,
                })
                switch (item.alarmType) {
                  case 1:
                    alarmLabelsRef.current[1].push(label);
                    break;
                  case 2:
                    alarmLabelsRef.current[2].push(label);
                    break;
                  case 3:
                    alarmLabelsRef.current[3].push(label);
                    break;
                  case 4:
                    alarmLabelsRef.current[4].push(label);
                    break;
                  case 5:
                    alarmLabelsRef.current[5].push(label);
                    break;
                  default:
                    alarmLabelsRef.current[6].push(label);
                }
              }
              //如果失败则不显示地址
              const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
              const lnglat = new window.AMap.LngLat(transformGcoord[0], transformGcoord[1]);
              // 创建一个 LabelMarker 实例
              const alarmMarker = new window.AMap.LabelMarker({
                position: lnglat,
                icon: {
                  image: 'https://webapi.amap.com/ui/1.1/ui/overlay/SimpleMarker/assets/default/default-fs8.png',
                  anchor: 'bottom-center',
                  size: [25, 34],
                  clipOrigin: [0, 0],
                  clipSize: [36, 46]
                },
              });
              alarmMarker.originData = item;
              alarmMarker.on('click', showInfoWindow);
              // 将 LabelMarker 实例添加到 LabelsLayer 上
              labelsLayer.add(alarmMarker);
              // 存储报警信息文本标注，勾选信息时显示
              const labelString = `<div class=${styles.alarmLable}>报警类型: ${getAlarmTypeDisplay(item)}
                  ${item.vehicleSpeed === null ? '' : `<br>车身速度: ${ownRound(item.vehicleSpeed, 1)}`}
                  ${item.gpsSpeed === null ? '' : `<br>gps速度: ${ownRound(item.gpsSpeed, 1)}`}
                  <br/>时间: ${formatTime(item.reportedTime, 'YYYY-MM-DD HH:mm:ss')}</div>`;
              // 创建文本标注
              const label = new window.AMap.Text({
                position: lnglat,
                content: labelString,
                offset: new window.AMap.Pixel(-10, -10),
                zIndex: 121,
                topWhenClick: true,
              })
              switch (item.alarmType) {
                case 1:
                  alarmLabelsRef.current[1].push(label);
                  break;
                case 2:
                  alarmLabelsRef.current[2].push(label);
                  break;
                case 3:
                  alarmLabelsRef.current[3].push(label);
                  break;
                case 4:
                  alarmLabelsRef.current[4].push(label);
                  break;
                case 5:
                  alarmLabelsRef.current[5].push(label);
                  break;
                default:
                  alarmLabelsRef.current[6].push(label);
              }
            });
          });
        });
        // 将 LabelsLayer 添加到地图上
        mapRef.current.add(labelsLayer);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }


  // 获取报警点位信息(存储报警的label信息)
  const getDayAlarm = async (params: DayInfoRequestParams) => {
    if (adasSwitch) {
      getDayAdasAlarm(params)
    } else {
      getDayAebAlarm(params)
    }
  }

  // 处理搜索参数
  const operateSearchParams = async (params: any, isExport?: boolean) => {
    const newParams = {...params};
    newParams.plateNumber = (params.plateNumber || '').trim();
    let carId = '';
    if (newParams.plateNumber) {
      const carRes = await findOneCarRequest({
        plateNumber: newParams.plateNumber
      });
      carId = carRes.data?.data?.id;
    }
    newParams.trickDate = dayjs(params.trickDate).format('YYYY-MM-DD');
    if (isExport) {
      newParams.export = true;
      newParams.carId = carId;
    }
    return newParams;
  }
  //风险数据
  const getRiskLevel = (value: any) => {
    if (value == 1) {
      return '低风险'
    } else if (value == 2) {
      return '中风险'
    } else if (value == 3) {
      return '高风险'
    }
  }

  // 4G信号强度
  const getSateNum = (value: any) => {
    if (value == 0) {
      return '初始化'
    } else if (0 < value && value <= 4) {
      return '低'
    } else if (5 <= value && value <= 14) {
      return '中下等'
    } else if (15 <= value && value <= 25) {
      return '中等'
    } else if (value >= 26) {
      return '高'
    }
  }

  // 获取轨迹数据1
  const onSearch = async (values: any) => {
    if (!values.plateNumber) {
      message.info('请选择车辆');
    } else {
      cancelAllAlarmChecked();
      const params: any = await operateSearchParams(values);
      getDayGps(params);
      getDayAlarm(params);
      setHasSearch(true);
      setcurrentData([])  // 清空当前行驶段数据
      travelItineraryId('')  // 重新给行驶Id赋值 
    }
  }


  // 获取导出的参数
  const getExportParams = async () => {
    const params = form.getFieldsValue();
    if (!params.plateNumber) {
      message.info('请选择车辆');
      return;
    }
    return await operateSearchParams(params, true)
  }

  // 尺子工具
  const openRule = () => {
    // 加载尺子工具的插件
    window.AMap.plugin(['AMap.MouseTool'], function () {
      // 当前的鼠标工具
      const currentRule = new window.AMap.MouseTool(mapRef.current);
      ruleRef.current.push(currentRule);
      currentRule.on('draw', (event: any) => {
        setTimeout(() => currentRule.close(), 200)
      });
      // 开启距离量测模式
      currentRule.rule();
    });
  }
  // 拖动 进度条

  const dragProgress = (value: number) => {
    // 暂停当前的点
    // 移动到指定的点
    if (animationMarkerRef.current) {
      animationMarkerRef.current.stopMove();
      animationMarkerRef.current.hide();
    }

    const paths: any = [];
    const newItineraryList = currentItiner.slice(value)
    newItineraryList.forEach((item: any) => {
      const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
      paths.push(new window.AMap.LngLat(transformGcoord[0], transformGcoord[1])); //经纬度进行push 数组
    })
    // 路过的轨迹标识为黑色
    const passedPolyline = new window.AMap.Polyline({
      map: mapRef.current,
      strokeColor: "#000",
      strokeWeight: 5,
    });

    animationMarkerRef.current = null
    window.AMap.plugin('AMap.MoveAnimation', function () {
      // 轨迹播放的maker 
      if (animationMarkerRef.current) {
        animationMarkerRef.current.hide();
        animationMarkerRef.current.stopMove();
      }
      // 设置当前播放的maker 为行程的首段
      animationMarkerRef.current = new window.AMap.Text({
        position: paths[0],
        content: `<div class=${styles.gpsPointLarge} />`,
        zIndex: 122,
        map: mapRef.current,
      });
      // 存储变为黑色的轨迹(用于后续删除)
      const newTrackAni = {...trackAniRef.current};
      if (newTrackAni[currentItinerId]) {
        newTrackAni[currentItinerId].push(passedPolyline);
      } else {
        newTrackAni[currentItinerId] = [passedPolyline];
      }
      trackAniRef.current = newTrackAni;
      animationMarkerRef.current.on('moving', function (e: any) {
        passedPolyline.setPath(e.passedPath);
        mapRef.current.setCenter(e.target.getPosition(), true);
        setProgressValue(e.index + value)
        if (e.progress === 1) {
          // 显示点位信息
          const matchData = newItineraryList[e.index + 1] || {};
          setCurrentSpeed(ownRound(matchData.speed, 1))
          const content = `速度: ${ownRound(matchData.speed, 1)}<br>车身速度: ${ownRound(matchData.vehicleSpeed, 1)}<br>时间: ${formatTime(matchData.reportedTime, 'YYYY-MM-DD HH:mm:ss')}`;
          const mapInfoWindow = new window.AMap.InfoWindow({content});
          mapInfoWindow.open(mapRef.current, paths[e.index + 1]);
        }
      });
      animationMarkerRef.current.moveAlong(paths, {
        duration: 500,
        autoRotation: true,
      });
      const mapInfoWindow = new window.AMap.InfoWindow({content: `速度: ${ownRound(newItineraryList[0]?.speed, 1)}<br>车身速度: ${ownRound(newItineraryList[0]?.speed, 1)}<br>时间: ${formatTime(newItineraryList[0]?.reportedTime, 'YYYY-MM-DD HH:mm:ss')}`});
      mapInfoWindow.open(mapRef.current, paths[0]);
    });
  }

  // 播放行程
  const onPlay = (id: string, itineraryData: any) => {
    if (isPlaying) {
      return
    }
    setIsPlaying(true); // 设置播放状态为true
    setProgressValue(0) //点击播放先清空
    console.log(itineraryData?.length, 'itineraryData?.length');
    if (!itineraryData?.length) {
      message.info('此行程没有数据');
      return;
    }

    console.log("该段轨迹数量：", itineraryData.length)
    setProgressMaxValue(itineraryData.length)
    setCurrentItiner(itineraryData)
    setCurrentItinerId(id)

    // 创建播放路线对象
    const paths: any = [];
    itineraryData.forEach((item: any) => {
      const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
      paths.push(new window.AMap.LngLat(transformGcoord[0], transformGcoord[1])); //经纬度进行push 数组
    })
    // 路过的轨迹标识为黑色
    const passedPolyline = new window.AMap.Polyline({
      map: mapRef.current,
      strokeColor: "#000",
      strokeWeight: 5,
    });

    animationMarkerRef.current = null
    window.AMap.plugin('AMap.MoveAnimation', function () {
      // 轨迹播放的maker 
      if (animationMarkerRef.current) {
        animationMarkerRef.current.hide();
        animationMarkerRef.current.stopMove();
      }
      // 设置当前播放的maker 为行程的首段
      animationMarkerRef.current = new window.AMap.Text({
        position: paths[0],
        content: `<div class=${styles.gpsPointLarge} />`,
        zIndex: 122,
        map: mapRef.current,
      });
      // 存储变为黑色的轨迹(用于后续删除)
      const newTrackAni = {...trackAniRef.current};
      if (newTrackAni[id]) {
        newTrackAni[id].push(passedPolyline);
      } else {
        newTrackAni[id] = [passedPolyline];
      }
      trackAniRef.current = newTrackAni;
      animationMarkerRef.current.on('moving', function (e: any) {
        passedPolyline.setPath(e.passedPath);
        mapRef.current.setCenter(e.target.getPosition(), true);
        // console.log("play: ", e)
        setProgressValue(e.index + 1)
        if (e.progress === 1) {
          // 显示点位信息
          const matchData = itineraryData[e.index + 1] || {};
          setCurrentSpeed(ownRound(matchData.speed, 1))
          const content = `GPS速度: ${ownRound(matchData.speed, 1)}<br>车身速度: ${ownRound(matchData.vehicleSpeed, 1)}<br>时间: ${formatTime(matchData.reportedTime, 'YYYY-MM-DD HH:mm:ss')}`;
          const mapInfoWindow = new window.AMap.InfoWindow({content});
          mapInfoWindow.open(mapRef.current, paths[e.index + 1]);
        }
      });
      animationMarkerRef.current.moveAlong(paths, {
        duration: 500,
        autoRotation: true,
      });
      const mapInfoWindow = new window.AMap.InfoWindow({content: `GPS速度: ${ownRound(itineraryData[0]?.speed, 1)}<br>车身速度: ${ownRound(itineraryData[0]?.vehicleSpeed, 1)}<br>时间: ${formatTime(itineraryData[0]?.reportedTime, 'YYYY-MM-DD HH:mm:ss')}`});
      mapInfoWindow.open(mapRef.current, paths[0]);
    });
  }

  // 暂停播放行程
  const onPause = (id: string) => {
    setIsPlaying(false);
    animationMarkerRef.current.pauseMove();
  }

  // 继续播放行程
  const onContinue = (id: string) => {
    animationMarkerRef.current.resumeMove();
  }

  // 终止行程
  const onDestory = (id: string) => {
    if (animationMarkerRef.current) {
      animationMarkerRef.current.stopMove();
      animationMarkerRef.current.hide();
    }
    // 轨迹路线变黑色的清除掉
    if (trackAniRef.current[id]) {
      trackAniRef.current[id].map((item: any) => item.hide());
      const newTrackAni = {...trackAniRef.current};
      delete newTrackAni[id];
      trackAniRef.current = newTrackAni;
    }
    setProgressValue(0)
  }

  // 切换报警类型的勾选
  const toggleAlarmChecked = (checked: boolean, label: number) => {
    setAlarmLabelsChecked({...alarmLabelsChecked, [label]: checked});
    const currentAlarmLabels = alarmLabelsRef.current[label];
    // console.log('alarmLabelsRef', alarmLabelsRef);
    // console.log('currentAlarmLabels', currentAlarmLabels);
    if (checked) {
      // 选中显示报警信息浮层
      currentAlarmLabels.forEach((item: any) => {
        mapRef.current.add(item);
      })
    } else {
      // 取消选中隐藏报警浮层
      currentAlarmLabels.forEach((item: any) => {
        mapRef.current.remove(item)
      })
    }
  }

  // 取消所有报警类型的勾选
  const cancelAllAlarmChecked = () => {
    if (animationMarkerRef.current) {
      animationMarkerRef.current.stopMove();
      animationMarkerRef.current.hide();
    }
    // 清除测距工具所画的线
    ruleRef.current.forEach((item: any) => {
      item.close(true)
    });
    ruleRef.current = [];
    alarmLabelsRef.current = {1: [], 2: [], 3: [], 4: [], 5: [], 6: []};
    // 清空所有轨迹动画
    trackAniRef.current = {};
    // 清除标注点
    if (mapRef.current) {
      mapRef.current.clearMap();
    }
    setAlarmLabelsChecked({1: false, 2: false, 3: false, 4: false, 5: false, 6: false});
  }

  // 渲染行程时间
  const renderItineraryTime = (value: any) => {
    const startTime = formatTime(value[0]?.reportedTime, 'YYYY-MM-DD HH:mm:ss', '无');
    const endTime = formatTime(value[value.length - 1]?.reportedTime, 'YYYY-MM-DD HH:mm:ss', '无');
    return `起始时间：${startTime}，结束时间：${endTime}`;
  }

  //抽屉打开 
  const showDrawer = () => {
    setIsPlaying(false); // 设置播放状态为false
    form.setFieldsValue({travelId: null}) //清空行驶Id
    setCurrentSpeed('')  //清空速度 
    setOpen(true);
    setHasSearch(false);
    setProgressValue(0)
  };

  //抽屉关闭
  const onClose = () => {
    setOpen(false);
    if (itineraryList.length > 0) {
      //得到id push新数组 
      const idArray: any = []
      itineraryList.forEach((item: any) => {
        // console.log('item', item);
        idArray.push({lable: item.id, value: item.id})
      })
      setTravelId(idArray);
    } else {
      return;
    }
  };
  //表格内对当前行驶Id赋值  
  const travelItineraryId = (value: any) => {
    if (value) {
      setcurrentId(value);
      setVal(value)
      setOpen(false);
      message.success(`查询的行程Id是${value}`);
      //提示选择行驶id成功
      itineraryList.forEach((item: any) => {
          if (item.id === value) {
            setcurrentData(item.gpsData);
            drawLinesOnMap(item.gpsData);
          }
        }
      )
    }
  }

  // 打开行程数据
  const openTripData = () => {
    setOpen(true);
  }

  // 表格列显示
  const columns = [
    {
      title: '行程ID',
      dataIndex: 'id',
      width: 150,
      render: (value: string, item: any) => (
        <div style={{color: '#007acc', cursor: 'pointer'}} onClick={() => travelItineraryId(value)}> {value}</div>
      )
    },
    {
      title: '时间',
      dataIndex: 'gpsData',
      width: 280,
      render: (value: any) => renderItineraryTime(value)
    },
    {
      title: '停驶位置',
      dataIndex: 'describeLocation',
      width: 150,
    }
  ]

  return (
    <div className={styles.trackPlaybackBox}>
      <div className={styles.carTree}>
        <CarTree
          treeSelect={treeSelect}
          selectableTypes={['car']}
        />
      </div>
      <div className={styles.playbackBox}>
        <div className={styles.mapTitle}>
          地图
          {showSwitch && <Switch className={styles.switchTitle} checkedChildren="ADAS" unCheckedChildren="AEB"
                  onChange={adasSwitchover}/>}
        </div>
        <LoadingWrapper spinning={loading} autoHeight>
          <div className={styles.mapBox}>
            <div id="trackMap" className={styles.map}/>
            <div className={styles.alarmCheckbox} style={{display: adasSwitch ? 'block' : 'none'}}>
              {['常规报警', 'ADAS报警', 'BSD报警', 'DSM报警'].map((item: string, index: number) => (
                <div key={item}>
                  <Checkbox
                    checked={alarmLabelsChecked[index + 1]}
                    onChange={(e: CheckboxChangeEvent) => toggleAlarmChecked(e.target.checked, index + 1)}
                  >
                    {item}
                  </Checkbox>
                </div>
              ))}
            </div>
            <div className={styles.alarmCheckbox} style={{display: adasSwitch ? 'none' : 'block'}}>
              {['FCW预警', 'AEB报警', '起步阻止信息', 'LDW预警信息', 'HMW预警信息', '其他'].map((item: string, index: number) => (
                <div key={item}>
                  <Checkbox
                    checked={alarmLabelsChecked[index + 1]}
                    onChange={(e: CheckboxChangeEvent) => toggleAlarmChecked(e.target.checked, index + 1)}
                  >
                    {item}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        </LoadingWrapper>
        <Slider defaultValue={0} value={progressValue} max={progressMaxValue} onChange={dragProgress}/>
        <div className={styles.box}>
          <Button style={{width: 100}} type="dashed">速度：{currentSpeed}</Button>
          <PlayCircleFilled className={styles.myCustomIcon} onClick={() => onPlay(currentId, currentData)}/>
          <PauseCircleFilled className={styles.myCustomIcon} onClick={() => onPause(currentId)}/>
          <FastForwardFilled className={styles.myCustomIcon} onClick={() => onContinue(currentId)}/>
          <CloseCircleFilled className={styles.myCustomIcon} onClick={() => onDestory(currentId)}/>
        </div>
        <Form labelCol={{span: 8}} wrapperCol={{span: 26}} onFinish={onSearch} form={form} autoComplete="off"
              style={{marginTop: '16px'}}>
          <Row>
            <Col span={7}>
              <Form.Item label="时间" name="trickDate" initialValue={dayjs()}>
                <DatePicker allowClear={false} style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="车牌号" name="plateNumber">
                <Input onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}/>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item wrapperCol={{span: 24}}>
                <div className={styles.buttonBox}>
                  <Button type="primary" htmlType="submit" onClick={showDrawer}>查找行程</Button>
                  <Button type="primary" onClick={openRule}>尺子工具</Button>
                  <ExportButton
                    url={gpsListExportPath}
                    fileName='gps'
                    getParamsFunc={getExportParams}
                    permissionKey="/car-admin/track-playback/export"
                  />
                  <FullscreenOutlined className={styles.myCustomIcon} onClick={openTripData}/>
                </div>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col span={7}>  
              <Form.Item label="行驶Id:" style={{ marginLeft: '16px' }} name="travelId" >
                <Select  
                 allowClear 
                 placeholder='请选择行驶Id'  
                 options={travelId}  
                 optionFilterProp="label" 
                 onSelect={handleChange}
                  />
              </Form.Item>
            </Col>
          </Row> */}
        </Form>
      </div>
      <Drawer title="车辆行程列表" width={400} placement={'bottom'} onClose={onClose} open={open}>
        <div>
          {hasSearch ? <div className={styles.tableBox}>
              <div style={{marginBottom: '10px', display: 'flex'}}>
                <div>运营开始时间：<span style={{fontSize: '16px', marginRight: '10px'}}>{operationStartTime} </span></div>
                <div>运营停驶时间：<span style={{fontSize: '16px', marginRight: '10px'}}>{operationStoptTime} </span></div>
                <div>运行时长<span style={{fontSize: '16px', marginRight: '10px'}}>{runningTime} </span></div>
                <div>停驶时长<span style={{fontSize: '16px', marginRight: '10px'}}>{downTime} </span></div>
                <div>停驶次数<span style={{fontSize: '16px', marginRight: '10px'}}>{stopTimes} </span></div>
                <div>最长停驶时长<span style={{fontSize: '16px', marginRight: '10px'}}>{MaxtopTimes} </span></div>
                <div>最短停驶时长<span style={{fontSize: '16px', marginRight: '10px'}}>{MinStopTimes} </span></div>
              </div>
              <div style={{height: '10px'}}></div>
              <CommonTable
                rowKey="id"
                columns={columns}
                dataSource={itineraryList}
                bordered
                loading={loading}
                pagination={false}
                scroll={{y: 400}}
                columnFilter={false}

              />
            </div> :
            <div className={styles.tipsBox}
                 style={{fontSize: '20px', fontWeight: 'bold'}}>请输入车牌号，点击查找行程后，进行轨迹回放</div>}</div>
      </Drawer>
    </div>
  )
}

export default TrackPlayback;