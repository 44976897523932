/**
 *  钉钉问题汇总
 */
import {Button, Card, Col, DatePicker, Form, Input, Row} from "antd";
import styles from "@/styles/pageContent.module.scss";
import React, {useState} from "react";
import CommonTable from "@/components/commonTable";
import {useTable} from "@/utils/useTable";
import {getDingTalkIssue} from "@/service/dingTalkIssue";


const DingTalkIssues = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({})
  const {page, limit, loading, data, totalCount, onPageChange,} = useTable({
    requestFunc: getDingTalkIssue,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  const columns = [
    {title: "车牌号", dataIndex: "plateNumber"},
    {title: "车架号", dataIndex: "frameNumber"},
    {title: "操作时间", dataIndex: "operateTime"},
    {title: "上传的设备终端号", dataIndex: "phone"},
    {title: "上传的sim卡号", dataIndex: "sim"},
    {title: "出现问题的类型", dataIndex: "type"},
    {title: "问题明细", dataIndex: "reason"},
  ]

  const onSearch = () => {
    const params = {
      ...form.getFieldsValue()
    }

    if (params.optTime && params.optTime[0] && params.optTime[1]) {
      params.beginDate = params.optTime[0].format('YYYY-MM-DD');
      params.endDate = params.optTime[1].format('YYYY-MM-DD');

      delete params.optTime;
    }

    setSearchParams(params);
  }

  return (
    <Card className={styles.fixPageContentBox}>
      <Form form={form} onFinish={onSearch}>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="操作时间" name='optTime'>
              <DatePicker.RangePicker picker="date"></DatePicker.RangePicker>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input allowClear={true} onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}></Input>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button type="primary" htmlType='submit'>搜索</Button>
          </Col>
        </Row>

      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        scroll={{x: '100%', y: '100%'}}
      />
    </Card>
  )
}


export {
  DingTalkIssues
}