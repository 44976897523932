/**
 * 报价配置相关请求
 */
import request from "@/utils/request";

export interface OfferPremiumRequestParams {
    id: number;  //唯一标识
    item: any; //指标名称
    area?: any; //行政区域
    factor: any; //系数
    type: any; //指标类型
}

export const offerPremiumListRequestPath = '/api/v2/car/premium/config/list';

// 查询配置列表数据
export const offerPremiumListRequest = () => {
    return request.get(offerPremiumListRequestPath)
}

// 添加配置列表数据
export const offerPremiumAdd = (OfferPremiumRequestParams: any) => {
    return request.post('/api/v2/car/premium/config/upsert', OfferPremiumRequestParams)
}

// 删除配置记录
export const offerPremiumDelete = (id: number) => {
    return request.delete(`/api/v2/car/premium/config/${id}`)
}

// 获取区域
export const areaInsuranceListRequest = () => {
    return request.get('/api/v2/car/area/insurance/list')
}