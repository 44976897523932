/**
 * 操作客服任务
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { upsertServiceTask } from '@/service/serviceTask';
import Icon from "@/components/icon";
import MapLocationModal from '@/components/mapLocationModal';
import CarSelect from '@/components/carSelect';
import UploadImageVideo from "@/components/uploadImageVideo";
import { tagListRequest } from '@/service/tagManage';
import { TagCategoryEnum } from '@/constants/tagManage';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const { Search } = Input;
const OperateModalTask = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [operateInfo, setOperateInfo] = useState<any>({}); // 弹窗的信息
  const [lnglat, setLnglat] = useState<any>(null); // 选择地址的经纬度信息
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [serveTypeList, setServeTypeList] = useState<any>([]); // 问题来源的标签

  // 编辑的时候赋值
  useEffect(() => {
    getServeType()
    if (type == 'edit') {
      setLnglat({lng:Number(data.lat),lat:Number(data.lon)})
      data.type = Number(data.type)
      form.setFieldsValue(data)
    }
  }, []);

  // 选择车辆获取车架号
  const onCarSelect = (value: any, options: any) => {
    const carPathName = options.cate_path_name || '';
    form.setFieldsValue({
      frameNumber: options.frame_number,
      linkman: options.car_principal,
      tel: options.car_principal_phone,
      carDriver: options.car_driver,
      carDriverPhone: options.car_driver_phone,
      insurer:carPathName.split('/')[carPathName.split('/').length - 3],
      channel:carPathName.split('/')[carPathName.split('/').length - 2],
      company: carPathName.split('/').pop()
    })
  }

  // 确认地址的方法
  const confirmLocation = (lnglat: any, address: string) => {
    setLnglat(lnglat);
    form.setFieldsValue({
      district: address,
    });
  }

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      if (lnglat !== null) {
        params.lat = lnglat.lng;
        params.lon = lnglat.lat;
      }
      if (uploadSuccessList.length) {
        params.resources = uploadSuccessList;
      }
      const res = await upsertServiceTask(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      } 
    }
  }

  // 获取车辆联系结果
  const getServeType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.serveType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setServeTypeList(tags);
  }

  return (
    <Modal
      title={type === 'add' ? '添加客服任务' : '编辑客服任务'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item 
          label="任务类型" 
          name="type" 
          rules={[{ required:true, message: '请选择任务类型' }]}
        >
          <Select placeholder="请选择任务类型" options={serveTypeList} optionFilterProp="label"  />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            if (form.getFieldValue('type') === '安装车辆') {
              return (
                <Form.Item
                  label="请输入车牌号"
                  name="plateNumber"
                >
                  <Input placeholder="请输入车牌号" />
                </Form.Item>
              )
            } else {
              return (
                <Form.Item
                  label="请选择车辆"
                  name="plateNumber"
                  rules={[
                    { required: true, message: '请选择车辆' }
                  ]}
                >
                  <CarSelect valueField="plate_number" onChange={onCarSelect} />
                </Form.Item>
              )
            }
          }}
        </Form.Item>
        <Form.Item
          label="车架号"
          name="frameNumber"
          rules={[
            { required: true, message: '请输入车架号' }
          ]}
        >
          <Input placeholder="请输入车架号" />
        </Form.Item>
        <Form.Item
          label="保司"
          name="insurer"
        >
          <Input placeholder="请输入保司" />
        </Form.Item>
        <Form.Item
          label="渠道"
          name="channel"
        >
          <Input placeholder="请输入渠道" />
        </Form.Item>
        <Form.Item
          label="所属公司"
          name="company"
        >
          <Input placeholder="请输入所属公司" />
        </Form.Item>
        <Form.Item
          label="车辆情况说明"
          name="ex"
        >
          <Input placeholder="请输入车辆情况说明" />
        </Form.Item>
        <Form.Item
          label="主问题"
          name="masterProblem"
        >
          <Input placeholder="请输入主问题" />
        </Form.Item>
        <Form.Item
          label="次问题"
          name="slaveProblem"
        >
          <Input placeholder="请输入次问题" />
        </Form.Item>
        <Form.Item
          label="负责人"
          name="linkman"
          rules={[
            { required: true, message: '请输入负责人' }
          ]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px' }}
        >
          <Input placeholder="请输入负责人" />
        </Form.Item>
        <Form.Item
          label="负责人电话"
          name="tel"
          rules={[
            { required: true, message: '请输入负责人电话' }
          ]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input placeholder="请输入负责人电话" />
        </Form.Item>
        <Form.Item
          label="司机"
          name="carDriver"
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px' }}
        >
          <Input placeholder="请输入司机" />
        </Form.Item>
        <Form.Item
          label="司机电话"
          name="carDriverPhone"
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input placeholder="请输入司机电话" />
        </Form.Item>
        <Form.Item
          label="车辆地址"
          name="district"
        >
          <Search
            placeholder='点击右侧按钮可进行地址选择'
            enterButton={<Icon type="icon-location" />}
            onSearch={() => setOperateInfo({ type: 'mapLocation' })}
          />
        </Form.Item>
        <Form.Item label="上传影像">
          <UploadImageVideo
            businessName="客服任务影像"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            canUploadVideo
            defaultUploadFiles={type === 'add' ? [] : data.resourceViewDtos == null ? [] : data.resourceViewDtos.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            limitSize={500}
            multiple
          />
        </Form.Item>
        <Form.Item
          name="remark"
          label="请输入备注"
        >
          <Input.TextArea showCount placeholder="请输入备注" style={{ height: 120 }}/>
        </Form.Item>
      </Form>
      {operateInfo.type === 'mapLocation' && 
        <MapLocationModal
          closeModal={() => setOperateInfo({})}
          confirmLocationFunc={confirmLocation}
        />
      }
    </Modal>
  )
}

export default OperateModalTask;
    