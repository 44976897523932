/**
 * 修改密码
 */
import React, { useState } from "react";
import { Modal, Form, Input, message } from 'antd';
import { upsertUserRequest } from '@/service/userManage';

interface EditPasswordModalProps {
  closeModal: () => void;
  data?: any;
}
const EditPasswordModal = (props: EditPasswordModalProps) => {
  const [form] = Form.useForm();
  const { closeModal, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      params.id = data.id;
      const res = await upsertUserRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success('修改成功');
        closeModal();
      }
    }
  }
  
  return (
    <Modal
      title='修改密码'
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          label="新密码"
          name="password"
          rules={[{ required: true, message: '请输入新密码' }]}
        >
          <Input.Password placeholder='请输入新密码' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditPasswordModal;
    