/**
 * 某一个信息弹窗
 */
import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Timeline } from 'antd';
import CommonTable from '@/components/commonTable';
import { questionnaireMaintainerRequest, questionnairePlateNumberRequest, questionnaireQuestionItemRequest, questionnaireSuggestionRequest } from '@/service/questionnaireSurveyTotal';

interface SomeOneTableModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  searchParams: any; // 异常汇总的搜索参数
}
const SomeOneTableModal = (props: SomeOneTableModalProps) => {
  const { data, closeModal, searchParams } = props;
  const [tableData, setTableData] = useState<any>([]); // 表格数据
  const [loading, setLoading] = useState(false); // 表格loading
  const [suggestion, setSuggestion] = useState<any>([]); // 建议

  useEffect(() => {
    getData();
  }, [])

  // 获取数据
  const getData = async() => {
    try {
      setLoading(true);
      const params = {...searchParams}
      if (data.type == 'company') {
        params.company = data.name
        const res = await questionnairePlateNumberRequest(params);
        const tableData: any = res.data.data || [];
        setTableData(tableData)
      } else if (data.type == 'plateNumber') {
        params.plateNumber = data.name
        const res = await questionnaireMaintainerRequest(params);
        const tableData: any = res.data.data || [];
        setTableData(tableData)
      } else if (data.type == 'maintainer') {
        params.maintainer = data.name
        const res = await questionnaireQuestionItemRequest(params);
        setTableData(res.data.data)
        const resss = await questionnaireSuggestionRequest(params);
        const bbb: any = resss.data.data || [];
        setSuggestion(bbb)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  // 表格列显示
  const columns: any = [
    {
      title: '车辆',
      dataIndex: 'name',
      width: 100,
      ellipsis: { showTitle: false },
    },
    {
      title: '安装人员',
      dataIndex: 'name',
      width: 100,
      ellipsis: { showTitle: false },
    },
    {
      title: '评分',
      dataIndex: 'score',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: any) => Number(value).toFixed(2)
    },
    {
      title: '调研项',
      dataIndex: 'name',
      width: 500,
      ellipsis: { showTitle: false },
    },
    {
      title: '是',
      dataIndex: 'yesFlag',
      width: 100,
      ellipsis: { showTitle: false },
    },
    {
      title: '否',
      dataIndex: 'noFlag',
      width: 100,
      ellipsis: { showTitle: false },
    },
  ];

  if (data.type == 'company') {
    columns.splice(1,1);
    columns.splice(2);
  } else if (data.type == 'plateNumber') {
    columns.splice(0,1);
    columns.splice(2);
  } else if (data.type == 'maintainer') {
    columns.splice(0,3);
  }

  return (
    <Fragment>
      <Modal
        title={`评价详情`}
        visible
        footer={null}
        onCancel={closeModal}
        width='70%'
        centered
        getContainer={false}
        maskClosable={false}
      >
        <CommonTable
          columnFilter={false}
          size='small'
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
          scroll={{ y: 400 }}
          loading={loading}
        />
        {data.type == 'maintainer' ? <Timeline style={{ marginTop: '24px' }}>
        {(suggestion || []).map((item: any) => (
          <Timeline.Item>
            <div>建议或者意见：{item.name}</div>
          </Timeline.Item>
        ))}
      </Timeline> : null}
      </Modal>
    </Fragment>
    
  )
}

export default SomeOneTableModal;