/**
 * 图片或视频的缩略图显示
 */
import React, { useState } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import ImagePreviewModal from '@/components/imagePreviewModal';
import VideoPreviewModal from '@/components/videoPreviewModal';
import styles from './index.module.scss';

interface ThumbnailItem {
  url: string;
  contentType: string;
  preview: string;
}
interface ThumbnailArr {
  data:any
}
interface ThumbnailProps {
  dataSource: Array<ThumbnailItem>;
  downloadImgName?: string;
  downloadVideoName?: string;
  dataIndex?:number;
  dataArray?: Array<ThumbnailArr>;
  size?: 'small' | 'middle' | 'large';
  showDownload?: boolean;
}
const Thumbnail = (props: ThumbnailProps) => {
  const { dataSource, downloadImgName = '图片', downloadVideoName = '视频' ,dataIndex,dataArray, size = 'small', showDownload = true,} = props;
  const [previewUrl, setPreviewUrl] = useState<string>('') // 预览照片的url
  const [videoUrl, setVideoUrl] = useState<string>('') // 视频播放的url
  const [dataSourceIndex,setDataSourceIndex]=useState<number>()  // 下标

  // 预览
  const onPreview = (file: ThumbnailItem,index:number ) => {
    setDataSourceIndex(index)
    if (file.contentType.startsWith('image')) {
      setPreviewUrl(file?.url || file?.preview);
    } else {
      setVideoUrl(file?.url || file?.preview)
    }
  };

  return (
    <div className={styles.imgBox}>
      {(dataSource || []).map((item: any, index: number) => (
        <div className={styles[`${size}ImgItem`]} key={index} onClick={() => onPreview(item,index)}>
          {item.contentType.startsWith('image') ? 
            <img src={item.url || item.preview} alt="图片" /> :
            <PlayCircleOutlined />
          }
        </div>
      ))}
      {/* 预览照片的弹窗 */}
      {Boolean(previewUrl) && <ImagePreviewModal
        dataIndex={dataIndex}
        dataArray={dataArray}
        pubIndex ={dataSourceIndex}
        pubArray={dataSource}
        url={previewUrl}
        fileName={downloadImgName}
        closeModal={() => setPreviewUrl('')}
        showDownload={showDownload}
      />}
      {/* 视频播放的弹窗 */}
      {Boolean(videoUrl) && <VideoPreviewModal
        url={videoUrl}
        fileName={downloadVideoName}
        closeModal={() => setVideoUrl('')}
        showDownload={showDownload}
      />}
    </div>
  )
}

export default Thumbnail;