/**
 * 报警查询相关请求
 */
import request from "@/utils/request";

export interface AlarmListRequestParams {
  page: number;
  limit: number;
  startTime?: string;
  endTime?: string;
  idList?: Array<number>;
  typeList?: Array<number>;
}

// export const alarmListRequestPath = '/test-api/v2/car/alarmListV2';
export const alarmListRequestPath = '/api/v2/car/alarmListV2';
// 获取报警数据
export const alarmListRequest = (data: AlarmListRequestParams) => {
  return request.post(alarmListRequestPath, data,
    // {
    //   headers: {
    //     'x-admin-id': '1',
    //     'x-cate-id': '1674'
    //   }
    // }
  )
}

// 获取单条报警数据
export const alarmSingleRequest = (data: any) => {
  return request.post('/api/v2/car/alarmStatistic', data)
}