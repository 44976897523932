/**
 * 数据大屏
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, message } from 'antd';

const DataV = () => {
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;

  const goDatav = () => {
    if (userDetail.datavUrl) {
      // 点击新开页面
      window.open(userDetail.datavUrl);
    } else {
      message.warning('暂无链接');
    }
  }
  
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Button type="primary" onClick={goDatav}>跳转到数据大屏</Button>
    </div>
  );
}

export default DataV;