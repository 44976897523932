/**
 * 部标机设备相关方法
 */
import { useEffect, useState } from 'react';
import { jtDeviceChannelEnumRequest } from '@/service/jtDevice';
import { getFtpResourceRequest } from '@/service/jtDevicePlayback';
import { formatTime } from '@/utils/formatTime';

// 获取通道号对应关系
export const useJtDeviceChannel = () => {
  const [jtDeviceChannelData, setJtDeviceChannelData] = useState([]);
  const getJtDeviceChannelEnum = async () => {
    const res = await jtDeviceChannelEnumRequest();
    const data = (res.data?.data || []).map((item: any) => ({
      label: `通道${item.no}`,
      value: item.no
    }));
    setJtDeviceChannelData(data);
  };
  useEffect(() => {
    getJtDeviceChannelEnum();
  }, []);
  return jtDeviceChannelData;
};

// 处理ftp资源(获取播放/下载链接)
export const operateFtpResource = async (data: any) => {
  const { phone, channelNo, beginTime, endTime } = data;
  const res = await getFtpResourceRequest(
    `${phone}/${channelNo}/${formatTime(
      beginTime,
      'YYYY-MM-DDTHH:mm:ss'
    )}-${formatTime(endTime, 'YYYY-MM-DDTHH:mm:ss')}`
  );
  const blobUrl = window.URL.createObjectURL(res.data);
  return blobUrl;
};

// 处理ftp资源(获取播放/下载链接)
export const deviceOperateFtpResource = async (data: any) => {
  const { phone, channelNo, beginTime, endTime, jtDeviceRes } = data;
  const res = await getFtpResourceRequest(
    `${jtDeviceRes}/${phone}/${channelNo}/${formatTime(
      beginTime,
      'YYYY-MM-DDTHH:mm:ss'
    )}-${formatTime(endTime, 'YYYY-MM-DDTHH:mm:ss')}`
  );
  const blobUrl = window.URL.createObjectURL(res.data);
  return blobUrl;
};
