/**
 * 车身信息相关请求
 */
import request from "@/utils/request";

export interface CarInfoListRequestParams {
  page: number;
  limit: number;
  startTime?: string;
  endTime?: string;
  idList?: Array<number>;
}

// 获取车身信息数据
export const carInfoListRequestPath = '/api/v2/car/signInfo/list';
export const carInfoListRequest = (data: CarInfoListRequestParams) => {
  return request.post(carInfoListRequestPath, data)
}
