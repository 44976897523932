/**
 * 工单相关请求
 */
import request from "@/utils/request";

export interface WorkOrderListRequestParams {
  page: number;
  limit: number;
  types?: Array<number>;
  stats?: Array<number>;
  beginTime?: string;
  endTime?: string;
  issuId?: number;
  plateNumber?:string
}
// 获取工单列表
export const workOrderListRequestPath = '/api/v2/order/list';
export const workOrderListRequest = (params?: WorkOrderListRequestParams) => {
  return request.get(workOrderListRequestPath, {
    params
  })
}

// 添加或编辑工单
export const upsertWorkOrderRequest = (data: any) => {
  return request.post('/api/v2/order/upsert', data)
}

// 删除工单
export const deleteWorkOrderRequest = (id: number) => {
  return request.delete(`/api/v2/order/delete/${id}`)
}

// 工单详情
export const workOrderDetailRequest = (id: number) => {
  return request.get(`/api/v2/order/detail/${id}`)
}

export interface ConfirmWorkOrderRequestParams {
  id: number;
}
// 工单过审并关闭
export const confirmWorkOrderRequest = (data: ConfirmWorkOrderRequestParams) => {
  return request.post('/api/v2/order/orderConfirm', data)
}

// 工单过审并关闭
export const updateEditingState = (data: {id: any, editingState: boolean}) => {
  return request.post('api/v2/operation/updateEditingState', data)
}

export interface ReopenWorkOrderRequestParams {
  id: number;
  reason: string;
  expire: string
}
// 工单未过审并重开
export const reopenWorkOrderRequest = (data: ReopenWorkOrderRequestParams) => {
  return request.post('/api/v2/order/orderReopen', data)
}


export interface ReassignWorkOrderRequestParams {
  id: number;
  operatorId: number;
  operator: string;
  expire: string
}

// 指派工单
export const assignWorkOrderRequest = (data: ReassignWorkOrderRequestParams) => {
  return request.post('/api/v2/order/assign', data)
}

// 工单被拒绝并重新指派
export const reassignWorkOrderRequest = (data: ReassignWorkOrderRequestParams) => {
  return request.post('/api/v2/order/orderReassign', data)
}

// 工单备注
export const commentWorkOrderRequest = (data: any) => {
  return request.post('/api/v2/order/ps', data)
}

// 工单运维报告
export const workOrderReportRequest = (data: any) => {
  return request.post('/api/v2/order/report', data)
}

// 工单统计报告-指标
export const workOrderStatisticsIndexRequest = (params: any) => {
  return request.get('/api/v2/order/summary/index', {
    params
  })
}

// 工单统计报告-图表
export const workOrderStatisticsChartRequest = (params: any) => {
  return request.get('/api/v2/order/summary/chart', {
    params
  })
}
