/**
 * 客服任务评论
 */
import React, { useEffect, useState } from "react";
import { Drawer, Timeline, Input, message, Modal, Form, Popconfirm, Button } from 'antd';
import { formatTime } from "@/utils/formatTime";
import LoadingWrapper from '@/components/loadingWrapper';
import { commentListRequest, upsertTaskDetail, deleteTaskRecoed } from "@/service/serviceTask";
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import PermissionButton from "@/components/permissionButton";

interface WorkOrderDetailProps {
  item: any; // 客服任务信息
  onClose: () => void; // 关闭工单详情弹层的方法
}
const TaskDetail = (props: WorkOrderDetailProps) => {
  const [form] = Form.useForm();
  const { item, onClose } = props;
  const [orderDetail, setOrderDetail] = useState<any>([]); // 工单详情
  const [loading, setLoading] = useState<boolean>(false) // 工单详情是否在请求中
  const [isModalOpen, setIsModalOpen] = useState(false); // 评论
  const [isModalOpenItem, setIsModalOpenItem] = useState<any>(); // 评论那条信息的数据

  useEffect(() => {
    getCommentDetail();
  }, []);

  // 获取评论
  const getCommentDetail = async() => {
    try {
      setLoading(true);
      const params:any = {taskId:item.id}
      const res = await commentListRequest(params);
      const data = res.data?.data || {};
      setOrderDetail(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 评论
  const submit = async (values:any) => {
    const params = values;
    params.taskId = isModalOpenItem.id
    const res = await upsertTaskDetail(params);
    if (res.data?.code === 0) {
      message.success('评论成功');
      getCommentDetail()
    } 
    setIsModalOpen(false);
  }

  // 删除评论
  const deleteComment = async (id:number) => {
    const res = await deleteTaskRecoed(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getCommentDetail()
    }
  }

  const showModal = (item:any) => {
    setIsModalOpenItem(item)
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Drawer
      title='客服任务评论'
      visible
      size="large"
      onClose={onClose}
    >
      <LoadingWrapper spinning={loading}>
      {/* 王美丽新增 数据部门 评论按钮的权限 */}
      {/* <Button type="primary" onClick={() => showModal(item)}>新增评论</Button> */}
      <PermissionButton operateType='/car-admin/service-task/addcomment'  type="primary" onClick={() => showModal(item)}>新增评论</PermissionButton>
        <Timeline style={{ marginTop: '24px' }}>
          {(orderDetail || []).map((item: any) => (
            <Timeline.Item key={item.id}>
              <div style={{display:'flex', flexDirection:'row', flexWrap:'nowrap', justifyContent: 'space-between', height:'26px', lineHeight:'26px'}}>
                <div>评论时间：{formatTime(item.tt)}</div>
                <div style={{display:'flex', paddingRight:'30px'}}>
                  <div>
                    评论人：{item.user}
                  </div>
                  <div style={{width:'20px'}}>
                    {(Math.round(new Date(item.tt).getTime() / 1000) + 30) > Math.round(new Date(dayjs().format('YYYY-MM-DD HH:mm:ss')).getTime() / 1000) ?
                      <Popconfirm
                        title="是否删除此条评论?"
                        onConfirm={() => deleteComment(item.id)}
                      >
                        <Button
                          type="link"
                          style={{ padding: '0 0 0 6px', height: '26px' }}
                        >
                          <DeleteOutlined style={{ color: 'red' }} />
                        </Button>
                      </Popconfirm> : null}
                  </div>
                </div>
              </div>
              <div>评论内容：{item.content}</div>
            </Timeline.Item>
          ))}
        </Timeline>
      </LoadingWrapper>
      <Modal title="评论" open={isModalOpen} onOk={() => form.submit()} onCancel={handleCancel}>
        <Form
          form={form}
          autoComplete="off"
          onFinish={submit}
          layout="vertical"
          scrollToFirstError
        >
          <Form.Item
            label="评论内容"
            name="content"
          >
            <Input placeholder="请输入评论内容" />
          </Form.Item>
        </Form>
      </Modal>
    </Drawer>
  )
}

export default TaskDetail;