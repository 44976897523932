/**
 * 传感器方案相关常量
 */
export const ultraDeviceTypeId = 5; // 超声波雷达设备类型ID
export const millDeviceTypeId = 6; // 毫米波雷达设备类型ID
export const actuatorName = '执行机构'; // 执行机构名称

// 控制器编号数据
export const controlNoData = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 }
];

// 传感器配置管理配置项的默认值
export const sensorConfigInitialValue = [
  {
    deviceType: '双目相机',
    location: '',
    isInstall: false,
    device: null,
    field: 'binocular',
    deviceTypeId: 3
  },
  {
    deviceType: '单目相机',
    location: '',
    isInstall: false,
    device: null,
    field: 'monocular',
    deviceTypeId: 4
  },
  {
    deviceType: '记录仪',
    location: '',
    isInstall: false,
    device: null,
    field: 'modem',
    deviceTypeId: 8
  },
  {
    deviceType: actuatorName,
    location: '',
    isInstall: false,
    device: null,
    field: 'actuator',
    deviceTypeId: 9
  },
  {
    deviceType: '4G模块',
    location: '',
    isInstall: false,
    device: null,
    field: 'p4g',
    deviceTypeId: 1
  },
  {
    deviceType: 'GPS模块',
    location: '',
    isInstall: false,
    device: null,
    field: 'gps',
    deviceTypeId: 2
  },
  {
    deviceType: 'IMU模块',
    location: '',
    isInstall: false,
    device: null,
    field: 'imu',
    deviceTypeId: 7
  },
  {
    deviceType: '小屏幕',
    location: '',
    isInstall: false,
    device: null,
    field: 'screen',
    deviceTypeId: 10
  },
  {
    deviceType: '蓝牙模块',
    location: '',
    isInstall: false,
    device: null,
    field: 'bt',
    deviceTypeId: 11
  },
  {
    deviceType: '毫米波雷达',
    location: '前方',
    isInstall: false,
    device: null,
    field: 'millFront',
    deviceTypeId: millDeviceTypeId
  },
  {
    deviceType: '毫米波雷达',
    location: '后方',
    isInstall: false,
    device: null,
    field: 'millBehind',
    deviceTypeId: millDeviceTypeId
  },
  {
    deviceType: '毫米波雷达',
    location: '左侧',
    isInstall: false,
    device: null,
    field: 'millLeft',
    deviceTypeId: millDeviceTypeId
  },
  {
    deviceType: '毫米波雷达',
    location: '右侧',
    isInstall: false,
    device: null,
    field: 'millRight',
    deviceTypeId: millDeviceTypeId
  },
  {
    deviceType: '超声波雷达',
    location: null,
    isInstall: false,
    device: null,
    field: 'ultra',
    deviceTypeId: ultraDeviceTypeId
  }
];
