/**
 * 传感器方案-设备管理相关请求
 */
import request from '@/utils/request';

export interface SensorDeviceListParams {
  page: number;
  limit: number;
  name?: string;
  supplierId?: number;
  userId?: number;
  beginTime?: string;
  endTime?: string;
  componentTypeId?: number;
}
// 获取传感器设备管理列表
export const sensorDeviceListRequestPath = '/api/v2/component/list';
export const sensorDeviceListRequest = (params: SensorDeviceListParams) => {
  return request.get(sensorDeviceListRequestPath, {
    params
  });
};

export interface UpsertSensorDeviceParams {
  name: string;
  supplierId: number;
  model: string;
  version: string;
  ps?: string;
  id?: number;
}
// 添加/编辑传感器设备
export const upsertSensorDeviceRequest = (data: UpsertSensorDeviceParams) => {
  return request.post('/api/v2/component/upsert', data);
};

// 删除传感器设备
export const deleteSensorDeviceRequest = (id: number) => {
  return request.delete(`/api/v2/component/${id}`);
};
