// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","pageContentBox":"pageContent_pageContentBox__dkVFn","fixPageContentBox":"pageContent_fixPageContentBox__H9O-9","fixPageTabContentBox":"pageContent_fixPageTabContentBox__Sw3i3","fixModalTab":"pageContent_fixModalTab__0KF37","searchBox":"pageContent_searchBox__vflI3","operateBox":"pageContent_operateBox__+80Mi","tableTagColumn":"pageContent_tableTagColumn__6TSCG","operateTextButton":"pageContent_operateTextButton__tct+n","tableOperateColumn":"pageContent_tableOperateColumn__+Zub5","scrollMoreTableFooter":"pageContent_scrollMoreTableFooter__6uck7","desc":"pageContent_desc__lsill","spaceBetweenFlex":"pageContent_spaceBetweenFlex__RhYIk","spaceBetweenPageContentBox":"pageContent_spaceBetweenPageContentBox__GKndR","carTree":"pageContent_carTree__6YqZh","contentBox":"pageContent_contentBox__rqqPA","searchButtonBox":"pageContent_searchButtonBox__k0FnE","tipBox":"pageContent_tipBox__vJm14","tableBox":"pageContent_tableBox__JfvYK","drawerTableBox":"pageContent_drawerTableBox__W8xUC","commonTableWrapper":"pageContent_commonTableWrapper__H69S7"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"pageContentBox\":\"pageContent_pageContentBox__dkVFn\",\"fixPageContentBox\":\"pageContent_fixPageContentBox__H9O-9\",\"fixPageTabContentBox\":\"pageContent_fixPageTabContentBox__Sw3i3\",\"fixModalTab\":\"pageContent_fixModalTab__0KF37\",\"searchBox\":\"pageContent_searchBox__vflI3\",\"operateBox\":\"pageContent_operateBox__+80Mi\",\"tableTagColumn\":\"pageContent_tableTagColumn__6TSCG\",\"operateTextButton\":\"pageContent_operateTextButton__tct+n\",\"tableOperateColumn\":\"pageContent_tableOperateColumn__+Zub5\",\"scrollMoreTableFooter\":\"pageContent_scrollMoreTableFooter__6uck7\",\"desc\":\"pageContent_desc__lsill\",\"spaceBetweenFlex\":\"pageContent_spaceBetweenFlex__RhYIk\",\"spaceBetweenPageContentBox\":\"pageContent_spaceBetweenPageContentBox__GKndR\",\"carTree\":\"pageContent_carTree__6YqZh\",\"contentBox\":\"pageContent_contentBox__rqqPA\",\"searchButtonBox\":\"pageContent_searchButtonBox__k0FnE\",\"tipBox\":\"pageContent_tipBox__vJm14\",\"tableBox\":\"pageContent_tableBox__JfvYK\",\"drawerTableBox\":\"pageContent_drawerTableBox__W8xUC\",\"commonTableWrapper\":\"pageContent_commonTableWrapper__H69S7\"}";
        // 1737772153445
        var cssReload = require("../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  