/**
 * 双向对讲弹窗
 */
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Space, message, Progress } from 'antd';
import {
  AudioOutlined,
  AudioMutedOutlined,
  SoundOutlined
} from '@ant-design/icons';
import { green } from '@ant-design/colors';
import PCMPlayer from '@/utils/PCMPlayer';
import { PCM2G711A, G711A2PCM } from '@/utils/PCMtransG711A';
import { getWebSocketPath } from '@/utils/common';
import { operateJt808VersionPath } from '@/utils/jtDeviceRequest';
import {
  jt808Command9101Request,
  jt808Command9102Request
} from '@/service/jtDeviceMonitor';

interface InterphoneModalProps {
  data: any; // 部标机设备数据
  closeModal: () => void; // 关闭弹窗方法
}
// 麦克风声音对象
let ctxAudio: any, sourceAudio: any, streamAudio: any, scriptProcessor: any;
const InterphoneModal = (props: InterphoneModalProps) => {
  const { data, closeModal } = props;
  const wsRef = useRef<any>(null); // WebSocket连接实例
  const [connectSuccess, setConnectSuccess] = useState(false); // WebSocket是否连接成功
  const [recording, setRecording] = useState(false); // 是否正在采集麦克风数据
  const [voicePercentage, setVoicePercentage] = useState(0); // 声音进度条

  // 初始化浏览器麦克风
  const initMedia = () => {
    if (!window.navigator.mediaDevices) {
      message.error('该浏览器或协议不支持麦克风');
    } else {
      window.navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          const tracks = stream.getAudioTracks();
          for (let i = 0, len = tracks.length; i < len; i++) {
            tracks[i].stop();
          }
        });
    }
  };

  useEffect(() => {
    initMedia();
    return () => {
      stopInterphone();
    };
  }, []);

  // 关闭双向对讲指令下发
  const closeInterphoneCmd = async () => {
    const { phone, jt808DeviceId } = data;
    const closeParams = {
      phone,
      jt808DeviceId,
      channelNo: 36,
      codeStream: 0,
      cmd: 4, // 双向对讲
      closeType: 2
    };
    await jt808Command9102Request(closeParams);
  };

  // 初始化PCM播放器
  const wsLiveG711A = () => {
    if (!window.wsLivePlayer) {
      window.wsLivePlayer = new PCMPlayer({
        encoding: '16bitInt',
        channels: 1,
        sampleRate: 8000,
        flushingTime: 1000
      });
    }
  };

  // 点击开始对讲建立WebSocket连接并初始化PCM播放器
  const startInterphone = async () => {
    await closeInterphoneCmd();
    const { phone, jt808DeviceId, version } = data;
    const params = {
      phone,
      jt808DeviceId,
      channelNo: 36,
      codeStream: 0,
      type: 2 // 双向对讲
    };
    // 下发双向对讲指令
    const res = await jt808Command9101Request(params);
    if (res?.data?.data) {
      // const webSocketPath = getWebSocketPath(
      //   `ws://${res?.data?.data?.ip}:${res?.data?.data?.port}/ws/audio/${operateJt808VersionPath(version)}`
      // );
      // const webSocketPath = `wss://${res?.data?.data?.domain}/ws/audio/${operateJt808VersionPath(version)}`
      const webSocketPath = `wss://va36.cheposhi.com/ws/audio/${operateJt808VersionPath(version)}`

      wsRef.current = new WebSocket(webSocketPath);
      new AudioContext({ sampleRate: 8000 });
      wsRef.current.onopen = () => {
        wsRef.current.send(`${data.phone}-36`);
        wsLiveG711A(); // 初始化PCM播放器
        setConnectSuccess(true);
      };
      wsRef.current.onmessage = (evt: any) => {
        if (typeof evt.data == 'string') {
          // 非二进制数据
        } else {
          // 二进制数据
          evt.data.arrayBuffer().then((res: any) => {
            // 播放接收的音频数据
            const pcm = G711A2PCM(new Int8Array(res));
            window.wsLivePlayer.feed(pcm);
          });
        }
      };
      wsRef.current.onerror = () => {
        message.error('WebSocket连接失败');
        setConnectSuccess(false);
      };
      wsRef.current.onclose = () => {
        console.log('双向对讲WebSocket连接断开');
        setConnectSuccess(false);
      };
    }
  };

  // 点击结束对讲关闭WebSocket连接
  const stopInterphone = () => {
    // 停止麦克风数据采集
    stopRecord();
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    setConnectSuccess(false);
    closeInterphoneCmd();
  };

  // 将压缩过的数据转成pcm格式数据
  const floatTo16BitPCM = (bytes: any) => {
    let offset = 0;
    const dataLen = bytes.length;
    // 默认采样率以16计算，而不是8位
    const buffer = new ArrayBuffer(dataLen * 2);
    const data = new DataView(buffer);

    for (let i = 0; i < bytes.length; i++, offset += 2) {
      // 保证采样帧的值在-1到1之间
      const s = Math.max(-1, Math.min(1, bytes[i]));
      // 将32位浮点映射为16位整形 值
      // 16位的划分的是 2^16=65536，范围是-32768到32767
      //  获取到的数据范围是[-1,1]之间，所以要转成16位的话，需要负数*32768，正数*32767，就可以得到[-32768，32767]范围内的数据
      // 第三个参数，true 含义是  是否是小端字节序 这里设置为true
      data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return data;
  };

  // 压缩声音数据
  const compress = (
    data: any,
    inputSampleRate: number,
    outputSampleRate: number
  ) => {
    const rate = inputSampleRate / outputSampleRate;
    const compression = Math.max(rate, 1);
    const length = Math.floor(data.length / rate);
    const result = new Float32Array(length);
    let index = 0;
    let j = 0;
    while (index < length) {
      // 取整
      const temp = Math.floor(j);
      result[index] = data[temp];
      index++;
      j += compression;
    }
    // 将压缩过的数据转成pcm格式数据
    return floatTo16BitPCM(result);
  };

  // 麦克风数据采集方法
  const initRecordMicro = (stream: any) => {
    streamAudio = stream;
    ctxAudio = new window.AudioContext();
    sourceAudio = ctxAudio.createMediaStreamSource(streamAudio);
    // 通过 AudioContext 获取麦克风中音频音量
    // 256, 512, 1024, 2048, 4096, 8192, 16384
    // 默认支持2的整数次幂的数字，数字越大越保熟，低了容易延迟
    scriptProcessor = ctxAudio.createScriptProcessor(4096, 1, 1);
    sourceAudio.connect(scriptProcessor);
    scriptProcessor.connect(ctxAudio.destination);
    scriptProcessor.onaudioprocess = (audioProcessingEvent: any) => {
      // buffer处理
      // 只处理了单声道
      const buffer = audioProcessingEvent.inputBuffer.getChannelData(0);
      let outputData: any = [];
      let sum = 0;
      for (let i = 0; i < buffer.length; i++) {
        sum += buffer[i] * buffer[i];
      }
      // 设置声音进度条
      setVoicePercentage(Math.round(Math.sqrt(sum / buffer.length) * 100));
      // 浏览器麦克风采样率为 ctxAudio.sampleRate 一般是44100
      const inputSampleRate = ctxAudio.sampleRate;
      // 跟流对接，他们那边需要我提供的是8000采样率的，所以需要压缩一次
      outputData = compress(buffer, inputSampleRate, 8000);
      // 将PCM转为G711.A数据传给设备
      const g711a = PCM2G711A(new Int16Array(outputData.buffer));
      if (wsRef.current) {
        wsRef.current.send(g711a);
      } else {
        message.error('WS未连接!');
      }
    };
  };

  // 开始麦克风数据采集
  const startRecord = () => {
    setRecording(true);
    window.navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(initRecordMicro)
      //用户拒绝授权
      .catch(e => {
        setRecording(false);
        switch (e.message || e.name) {
          case 'PERMISSION_DENIED':
            message.error('用户拒绝提供权限');
            break;
          case 'PermissionDeniedError':
            message.error('用户拒绝提供权限');
            break;
          case 'NOT_SUPPORTED_ERROR':
            message.error('浏览器不支持您当前选择的设备');
            break;
          case 'NotSupportedError':
            message.error('浏览器不支持您当前选择的设备');
            break;
          case 'MANDATORY_UNSATISFIED_ERROR':
            message.error('无法发现指定的硬件设备');
            break;
          case 'MandatoryUnsatisfiedError':
            message.error('无法发现指定的硬件设备');
            break;
          default:
            message.error(`无法打开麦克风,原因：${e.code || e.name}`);
        }
      });
  };

  // 结束麦克风数据采集
  const stopRecord = () => {
    // 正在采集麦克风数据时结束采集
    if (recording) {
      const tracks = streamAudio.getAudioTracks();
      for (let i = 0, len = tracks.length; i < len; i++) {
        tracks[i].stop();
      }
      // 把init里建立的audio链接都关闭
      sourceAudio?.disconnect();
      scriptProcessor?.disconnect();
      sourceAudio = null;
      scriptProcessor = null;
      setVoicePercentage(0);
      setRecording(false);
    }
  };

  return (
    <Modal
      title="语音对讲"
      open
      footer={null}
      onCancel={() => {stopInterphone(); setTimeout(() => {closeModal()}, 100)}}
      bodyStyle={{
        height: '160px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      centered
      maskClosable={false}
    >
      <div style={{ marginBottom: '8px' }}>
        <SoundOutlined style={{ marginRight: '4px' }} />
        <Progress
          showInfo={false}
          percent={voicePercentage ? voicePercentage * 3 : 0}
          steps={10}
          strokeColor={green[6]}
        />
      </div>
      <Space>
        <Button type="primary" onClick={startInterphone}>
          开始对讲
        </Button>
        <Button type="primary" danger onClick={stopInterphone}>
          结束对讲
        </Button>
        <Button
          type="primary"
          onClick={recording ? stopRecord : startRecord}
          disabled={!connectSuccess}
          title={
            recording
              ? '点击按钮结束麦克风数据采集'
              : '点击按钮开始麦克风数据采集'
          }
        >
          {recording ? <AudioOutlined /> : <AudioMutedOutlined />}
        </Button>
      </Space>
    </Modal>
  );
};

export default InterphoneModal;
