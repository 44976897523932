/**
 * 批量导入存在重复时的弹窗
 */
import React, { useState } from 'react';
import { Modal, message, Button, Tooltip } from 'antd';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { insertBatchAccidentRecordRequest } from '@/service/accidentRecord';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  data?: any;
}
const RepeatModal = (props: OperateModalProps) => {
  const { reloadData, closeModal, data } = props;
  const [selectIds, setSelectIds] = useState<any>([]); // 选择的记录id
  
  /**
   * 批量插入重复的记录
   * @param ignoreRepeat: 是否忽略冲突记录直接插入
   */
  const insertBatchRepeat = async(ignoreRepeat?: boolean) => {
    const params = {
      insertData: data.insertData,
      updateData: ignoreRepeat ? [] : (data.updateData || []).filter((item: any) => selectIds.includes(item.id)),
    }
    const res = await insertBatchAccidentRecordRequest(params);
    if (res.data?.code === 0) {
      message.success('导入成功');
      closeModal();
      reloadData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as 'left',
    },
    {
      title: '报案号',
      dataIndex: 'caseNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '被保险人',
      dataIndex: 'insurer',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '出险时间段',
      dataIndex: 'insureDateTime',
      width: 110,
    },
    {
      title: '承保机构',
      dataIndex: 'channel',
      width: 110,
    },
    {
      title: '出险金额(整案预估)',
      dataIndex: 'claimAmount',
      width: 160,
    },
    {
      title: '事故类型',
      dataIndex: 'accidentTypeStr',
      width: 100,
    },
    {
      title: '事故碰撞方位',
      dataIndex: 'accidentCollisionLocationStr',
      width: 120,
    },
    {
      title: '事故分类',
      dataIndex: 'accidentClassificationStr',
      width: 120,
    },
    {
      title: '备注',
      dataIndex: 'ps',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
  ]

  return (
    <Modal
      title="报案号冲突记录"
      visible
      onCancel={closeModal}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
      width="70%"
      footer={[
        <Button key="cancel" onClick={closeModal}>取消导入</Button>,
        <Button key="ignore" type="primary" onClick={() => insertBatchRepeat(true)}>忽略全部冲突</Button>,
        <Tooltip title={selectIds.length ? '' : '请先选择要覆盖的记录'} key="ok">
          <Button
            type="primary"
            disabled={!selectIds.length}
            style={{ marginLeft: '8px' }}
            onClick={() => insertBatchRepeat(false)}
          >
            覆盖冲突记录
          </Button>
        </Tooltip>
      ]}
    >
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data?.updateData || []}
        pagination={false}
        bordered
        rowSelection={{
          onChange: (keys: any) => setSelectIds(keys)
        }}
        scroll={{ y: 400 }}
      />
    </Modal>
  )
}

export default RepeatModal;
    