/**
 * 数据管理
 */
import { Card, Tabs } from 'antd';
import styles from '@/styles/pageContent.module.scss';
import ownStyles from './index.module.scss';
import ProblemCarTable from './components/problemCarTable';
import ProblemCarRecord from './components/problemCarRecord';
import OfflineCarTable from './components/offlineCarTable';
import OfflineCarRecord from './components/offlineCarRecord';
import MonitorCarTable from './components/monitorCarTable';
import MonitorCarRecord from './components/monitorCarRecord';
import VisitCarTable from './components/visitCarTable';
import { getInnerPermissionEnable } from '@/utils/permission';
import { dataDepartmentListRequest } from '@/service/dataDepartment';

const QuestionnaireSurveyTotal = () => {
  // 显示问题车总表
  const showProblemCarTable = getInnerPermissionEnable('/car-admin/data-department/problem-car-table');
  
  // 显示问题车留档
  const showProblemCarRecord = getInnerPermissionEnable('/car-admin/data-department/problem-car-record');

  // 显示离线车总表
  const showOfflineCarTable = getInnerPermissionEnable('/car-admin/data-department/offline-car-table');

  // 显示离线车留档
  const showOfflineCarRecord = getInnerPermissionEnable('/car-admin/data-department/offline-car-record');

  // 显示监测车总表
  const showMonitorCarTable = getInnerPermissionEnable('/car-admin/data-department/monitor-car-table');

  // 显示监测车留档
  const showMonitorCarRecord = getInnerPermissionEnable('/car-admin/data-department/monitor-car-record');

  // 显示回访车总表
  const showVisitCarTable = getInnerPermissionEnable('/car-admin/data-department/visit-car-table');

  return (
    <Card bordered={false} className={`${styles.fixPageContentBox} ${ownStyles.abnormalSummary}`}>
      <Tabs>
        {showProblemCarTable && <Tabs.TabPane tab="问题车总表" key="one">
          <ProblemCarTable
            requestMethod={dataDepartmentListRequest}
          />
        </Tabs.TabPane>}
        {showProblemCarRecord && <Tabs.TabPane tab="问题车留档" key="two">
          <ProblemCarRecord
            requestMethod={dataDepartmentListRequest}
          />
        </Tabs.TabPane>}
        {showOfflineCarTable && <Tabs.TabPane tab="离线车总表" key="three">
          <OfflineCarTable
            requestMethod={dataDepartmentListRequest}
          />
        </Tabs.TabPane>}
        {showOfflineCarRecord && <Tabs.TabPane tab="离线车留档" key="four">
          <OfflineCarRecord
            requestMethod={dataDepartmentListRequest}
          />
        </Tabs.TabPane>}
        {showMonitorCarTable && <Tabs.TabPane tab="监测车总表" key="five">
          <MonitorCarTable
            requestMethod={dataDepartmentListRequest}
          />
        </Tabs.TabPane>}
        {showMonitorCarRecord && <Tabs.TabPane tab="监测车留档" key="six">
          <MonitorCarRecord
            requestMethod={dataDepartmentListRequest}
          />
        </Tabs.TabPane>}
        {showVisitCarTable && <Tabs.TabPane tab="回访车总表" key="seven">
          <VisitCarTable
            requestMethod={dataDepartmentListRequest}
          />
        </Tabs.TabPane>}
      </Tabs>
    </Card>
  )
}

export default QuestionnaireSurveyTotal;