/**
 * 充值套餐(卡片)
 */
import React, { useState } from 'react';
import { Col, Tag } from 'antd';
import styles from './index.module.scss';

interface StatisticsCardProps {
  title: string;
  tag?: string;
  count: number | string;
  clickFunc: Function;
  style?: any;
  percent?: string
}
const RechargeCard = (props: StatisticsCardProps) => {
  const { title, tag, count, clickFunc } = props;

  const changeWays = () => {
    clickFunc(count,title)
  }

  return (
    <div className={styles.rechargeCard} onClick={changeWays}>
      <div className={styles.title}>
        <div>{title}</div>
        {Boolean(tag) && <Tag color="#1890ff">{tag}</Tag>}
      </div>
      <div className={styles.count}>
        <div>￥
          <span className={styles.countNum}>{count}</span>
        </div>
      </div>
    </div>
  )
}

export default RechargeCard;