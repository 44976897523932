/**
 * 超速车辆
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { overspeedRequest, overspeedRequestPath } from '@/service/overspeed';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import OperationGroupSelect from '@/components/operationGroupSelect';
import { operateDayRange } from '@/utils/operateSearchParams';
import { ownRound } from '@/utils/math';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { useTable } from '@/utils/useTable';
import { snColumnConfig, cameraSnColumnConfig, cateGroupColumnConfig } from '@/constants/tableColumn';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const Overspeed = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    speed: 110,
    speed_diff: 20,
    cate_id: cateIdArr,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const { page, limit, loading, data, totalCount, onPageChange, submitNoRepetition } = useTable({
    requestFunc: overspeedRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams,
  });
  const [form] = Form.useForm();

  // 搜索
  const onSearch = async (values: any) => {
    if (submitNoRepetition) {
      const newSearchParams = operateDayRange(values);
      setSearchParams(newSearchParams);
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateDayRange(values, true);
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cate_id: options.cateId,
      })
    }
  }

  // 渲染开始结束时间列
  const renderTimeColumn = (value: string) => (
    <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} />
  )

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
    },
    snColumnConfig,
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
    },
    cameraSnColumnConfig,
    cateGroupColumnConfig,
    {
      title: '速度(km/h)',
      dataIndex: 'speed',
      width: 120,
      render: (value: string) => ownRound(value, 1),
    },
    {
      title: '速度差(km/h)',
      dataIndex: 'speed_diff',
      width: 120,
      render: (value: string) => ownRound(value, 1),
    },
    {
      title: '卫星数量',
      dataIndex: 'stat',
      width: 120,
      render: (value: string) => ownRound(value, 1),
    },
    {
      title: '时间',
      dataIndex: 'reported_time',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => renderTimeColumn(value),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 220,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'contact', data: item })}>联系方式</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout='vertical' form={form}>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/overspeed/show-operation-group'
            span={4}
          />
          <Col span={4}>
            <Form.Item label="分类选择" name="cate_id" initialValue={searchParams.cate_id}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车速大于" name="speed" initialValue={searchParams.speed}>
              <InputNumber placeholder='速度比值' style={{ width: '100%' }} addonAfter="km/h" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="速度差小于" name="speed_diff" initialValue={searchParams.speed_diff}>
              <InputNumber placeholder='速度差小于' style={{ width: '100%' }} addonAfter="km/h" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="卫星数量大于" name="stat">
              <InputNumber placeholder='卫星数量大于' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
         <Col span={4}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={overspeedRequestPath}
                  fileName='超速车辆'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/overspeed/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Card>
  )
}

export default Overspeed;
  