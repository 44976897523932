/**
 * 参数信息弹窗(兼容展示昆仑山设备、黄河设备)
 */
import React, { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Select, message } from "antd";
import { isKlsAeb, isKlsV3Aeb } from "@/utils/device";
import AebParamsModal from "@/pages/parameterConfiguration/components/aebParamsModal";
import JtKlsAebParamsModal from "@/pages/parameterConfiguration/components/jtKlsAebParamsModal";
import { getJCConfigInfoRequest } from "@/service/deviceManage";
import { alarmVolumeList, brakeModeList } from "../parameterConfigurationModal";

interface ParamsModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const ParamsModal = (props: ParamsModalProps) => {
  const { data, closeModal } = props;

  // 是否是昆仑山设备
  const deviceTypeCategory = data.deviceTypeCategory;
  const isKls = isKlsAeb(deviceTypeCategory);
  const isKlsV3 = isKlsV3Aeb(deviceTypeCategory);
  const [isJC, setIsJC] = useState(data.deviceType == 14);
  // 黄河设备的参数信息
  const paramInfo = data.param_info || data.paramInfo;
  const [jcForm] = Form.useForm();

  useEffect(() => {
    // 黄河设备没有参数信息时给提示
    if (!isKls && !paramInfo && !isJC) {
      message.info("暂无参数信息");
      closeModal();
    }
  }, []);

  // 黄河设备没有参数信息时不显示弹窗
  if (!isKls && !paramInfo && !isJC) {
    return null;
  }

  if (isJC) {
    getJCConfigInfoRequest(data.carId).then((res: any) => {
      if (res?.data?.code == 0) {
        jcForm.setFieldsValue(res.data.data);
      } else {
        message.error("参数获取错误");
      }
    });
    return (
      <Modal
        title="骏驰参数"
        open={isJC}
        onCancel={() => {
          closeModal()
        }}
        onOk={() => {
          closeModal()
        }}
      >
        <Form form={jcForm} labelCol={{ span: 14 }}>
          <Row>
            <Col span={12}>
              <Form.Item label="刹车模式" name="brakeMode">
                <Select
                disabled
                  placeholder="请输入刹车模式"
                  options={brakeModeList}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="刹车等级" name="brakeLevel">
                <Input disabled></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="刹车灵敏度等级" name="brakeSensitivityLevel">
                <Input disabled></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="报警音量" name="alarmVolume">
                <Select
                disabled
                  placeholder="请设置报警音量"
                  options={alarmVolumeList}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }

  // 昆仑山设备和黄河设备区分显示
  if (isKls) {
    // 昆仑山设备区分显示3.0版本
    if (isKlsV3) {
      return (
        <JtKlsAebParamsModal data={data} closeModal={closeModal} isViewParam />
      );
    } else {
      return <AebParamsModal data={data} closeModal={closeModal} isViewParam />;
    }
  } else {
    return (
      <Modal
        title="参数信息"
        visible
        footer={null}
        onCancel={closeModal}
        centered
      >
        {paramInfo}
      </Modal>
    );
  }
};

export default ParamsModal;
