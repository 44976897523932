/**
 * 有快捷选项的RangePicker
 */
import React, { useState, useEffect } from 'react';
import { Button, Tag } from 'antd';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';

interface QuickSelectDatePickerProps {
  value?: any; // 选择的数据
  onChange?: (value: any) => void; // 选择时调用的方法
  style?: any; // 样式
}

const { RangePicker } = DatePicker;
const QuickSelectRangePicker = (props: QuickSelectDatePickerProps) => {
  const { value, onChange, style } = props;
  const [open, setOpen] = useState<boolean>(false); // 弹层是否展开
  const [selectValue, setSelectValue] = useState<any>(value); // 选择的日期

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 弹出日历和关闭日历的回调
  const onOpenChange = (value: boolean) => {
    setOpen(value);
  }

  // 快捷选择
  const quickSelect = (type: string) => {
    const dateObj: any = {
      'today': [dayjs(`${dayjs().subtract(0, 'day').format('YYYY-MM-DD')}`), dayjs(`${dayjs().subtract(0, 'day').format('YYYY-MM-DD')}`)],
      'yesterday': [dayjs(`${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`), dayjs(`${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`)],
    }
    onDateChange(dateObj[type]);
    setOpen(false);
  }

  // 日期选择
  const onDateChange = (date: any) => {
    setSelectValue(date);
    onChange && onChange(date);
  }

  return (
    <RangePicker
      open={open}
      // renderExtraFooter={() => <div>
      //   <Tag style={{cursor:'pointer'}} color="blue" onClick={() => quickSelect('today')}>今天</Tag>
      //   <Tag style={{cursor:'pointer'}} color="blue" onClick={() => quickSelect('yesterday')}>昨天</Tag>
      // </div>}
      onOpenChange={onOpenChange}
      allowClear={true}
      style={style}
      value={selectValue}
      onChange={onDateChange}
    />
  )
}

export default QuickSelectRangePicker;
  