/**
 * AEB参数弹窗
 */
import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  message,
  Form,
  InputNumber,
  Button,
  Row,
  Col,
  Switch,
  Select,
  Tooltip,
  Collapse
} from 'antd';
import { cloneDeep, isNil } from 'lodash';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import { formatTime } from '@/utils/formatTime';
import { getInnerPermissionEnable } from '@/utils/permission';
import { RequestErrorCode } from '@/constants/requestErrorCode';
import { setAebParamsRequest } from '@/service/parameterConfiguration';
import { getAebConfigInfoRequest } from '@/service/deviceManage';
import {
  getAebConfigParams,
  angularRadarJudgeFields,
  demoJudgeFields,
  trBrkJudgeFields,
  rearUltrasonicFields
} from '@/constants/parameterConfiguration';
import { formSearchGutter } from '@/constants/common';
import ApproveReasonModal from '../approveReasonModal';

const { Panel } = Collapse;
interface AebParamsModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  isViewParam?: boolean; // 是否只查看参数(用于参数信息的查看)
}
const AebParamsModal = (props: AebParamsModalProps) => {
  const { data, closeModal, isViewParam = false } = props;
  const [initLoading, setInitLoading] = useState(false); // 获取配置接口的loding
  const [loading, setLoading] = useState(false); // 配置接口的loding
  const [deveiceOffline, setDeveiceOffline] = useState(false); // 设备不在线(不在线的设备配置按钮disabled)
  const [angularRadarVisible, setAngularRadarVisible] = useState(true); // 是否显示角雷达配置区域
  const [demoVisible, setDemoVisible] = useState(true); // 是否显示演示场景刹车参数区域
  const [trBrkVisible, setTrBrkVisible] = useState(true); // 是否显示右转点刹功能参数区域
  const [rearUltrasonicVisible, setRearUltrasonicVisible] = useState(true); // 是否显示后向超声波参数区域
  const [offlineInfo, setOfflineInfo] = useState<any>(null); // 离线设备获取到设备参数时的相关信息
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作信息存储
  const [activeKeys, setActiveKeys] = useState<Array<string>>([]); // Collapse激活的key
  const [form] = Form.useForm();
  const originDataRef = useRef<any>(null); // 存储读取到的设备参数

  useEffect(() => {
    getAebParams();
  }, []);

  // 获取AEB参数
  const getAebParams = async () => {
    try {
      setInitLoading(true);
      const resquestFunc = getAebConfigInfoRequest;
      const res = await resquestFunc(data.sn);
      setInitLoading(false);
      // 设备不在线，配置按钮禁用
      if (res.data?.code === RequestErrorCode.deviceOffline) {
        setDeveiceOffline(true);
      } else {
        setDeveiceOffline(false);
      }
      if (res.data?.code === 0) {
        const aebParams = res.data?.data || {};
        // 获取所有的有区间范围限制的数据
        const allAebParamsRegion: any = {};
        getAebConfigParams().forEach((item: any) => {
          item.formItems.forEach((item1: any) => {
            item1.forEach((item2: any) => {
              const { name, type, min, max } = item2;
              if (type === 'inputNumber') {
                allAebParamsRegion[name] = {
                  min,
                  max
                };
              }
            });
          });
        });
        // 处理有区间范围限制的表单字段，使值符合区间范围
        const formAebValues = cloneDeep(aebParams);
        for (const key in allAebParamsRegion) {
          const max = allAebParamsRegion[key].max;
          const min = allAebParamsRegion[key].min;
          if (max && formAebValues[key] > max) {
            formAebValues[key] = max;
          }
          if (min && formAebValues[key] < min) {
            formAebValues[key] = min;
          }
        }
        originDataRef.current = { ...formAebValues, sn: data.sn };
        form.setFieldsValue(formAebValues);
        // 没有获取到角雷达参数时不显示角雷达配置区域
        if (
          angularRadarJudgeFields.every(
            (item: string) => aebParams[item] === 0 || isNil(aebParams[item])
          )
        ) {
          setAngularRadarVisible(false);
        }
        // 没有获取到演示场景刹车参数时不显示演示场景刹车参数配置区域
        if (
          ['aebTtcMagicSw', 'aebTtcDemoSw'].every((item: string) =>
            isNil(aebParams[item])
          )
        ) {
          setDemoVisible(false);
        }
        // 没有获取到右转点刹参数时不显示右转点刹参数配置区域
        if (trBrkJudgeFields.every((item: string) => isNil(aebParams[item]))) {
          setTrBrkVisible(false);
        }
        // 没有获取到后向超声波参数时不显示后向超声波参数配置区域
        if (
          rearUltrasonicFields.every((item: string) => isNil(aebParams[item]))
        ) {
          setRearUltrasonicVisible(false);
        }
        // 设备不在线获取到设备信息时存储相关信息
        if (aebParams?.offline) {
          setOfflineInfo({ updatedAt: aebParams.updatedAt });
        } else {
          setOfflineInfo(null);
        }
      }
    } catch (error) {
      console.log(error);
      setInitLoading(false);
    }
  };

  // 配置AEB参数
  const setAebParams = async (params: any) => {
    try {
      setLoading(true);
      const res = await setAebParamsRequest(params);
      setLoading(false);
      if (res.data?.code === 0) {
        message.success('配置成功');
        closeModal();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // 配置
  const onConfigure = async (values: any) => {
    const needApprove = getInnerPermissionEnable(
      '/car-admin/parameter-configuration/aeb-params-config-approve'
    );
    const params = {
      ...originDataRef.current,
      ...values,
      sn: data.sn
    };
    delete params.updatedAt;
    delete params.offline;
    // 角雷达参数区域不显示时删除参数
    if (!angularRadarVisible) {
      angularRadarJudgeFields.forEach((item: string) => {
        delete params[item];
      });
    }
    // 演示场景参数区域不显示时删除参数
    if (!demoVisible) {
      demoJudgeFields.forEach((item: string) => {
        delete params[item];
      });
    }
    if (needApprove) {
      setOperateInfo({
        type: 'approve',
        data: {
          origin: originDataRef.current,
          target: params,
          type: 'aebConfig',
          sn: data.sn,
          deviceType: data.deviceType
        }
      });
    } else {
      setAebParams(params);
    }
  };

  // 表单校验失败时展开Collapse.Panel进行明显提示
  const onFormFailed = (info: any) => {
    const { errorFields } = info;
    const errorFieldsNames = errorFields
      .map((item: any) => item.name)
      .flat(Infinity);
    const panelKeys: Array<string> = [];
    // 获取各个Collapse.Panel下对应的form表单
    getAebConfigParams().forEach((item: any) => {
      const { panelKey, formItems } = item;
      formItems.forEach((item1: any) => {
        item1.forEach((item2: any) => {
          if (errorFieldsNames.includes(item2.name)) {
            panelKeys.push(panelKey);
          }
        });
      });
    });
    setActiveKeys(panelKeys.flat(Infinity));
    form.scrollToField(panelKeys[0]);
  };

  // 表单操作时的处理
  const onFormChange = (changedValues: any) => {
    // AEB演示场景V2版本开关开启时AEB演示场景V1版本开关强制关闭
    if (changedValues.aebTtcDemoSw) {
      form.setFieldsValue({
        aebTtcMagicSw: 0
      });
    }
  };

  // 渲染弹窗的title
  const renderModalTitle = () => {
    const offlineStr = offlineInfo ? (
      <span className="dangerText">
        （离线数据，数据时间：
        {formatTime(offlineInfo?.updatedAt, 'YYYY-MM-DD HH:mm:ss')}）
      </span>
    ) : (
      ''
    );
    return (
      <span>
        {isViewParam ? '参数信息' : 'AEB参数配置'}
        {offlineStr}
      </span>
    );
  };

  // Collapse收起展开
  const onCollapseChange = (keys: any) => {
    setActiveKeys(keys);
  };

  return (
    <Modal
      title={renderModalTitle()}
      open
      onCancel={closeModal}
      centered
      width="80%"
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          取消
        </Button>,
        <Button
          type="primary"
          key="read"
          onClick={getAebParams}
          loading={initLoading}
        >
          读取
        </Button>,
        // 用作参数信息查看时不显示配置按钮
        isViewParam || (
          <Tooltip
            title={
              deveiceOffline
                ? '当前设备不在线，可尝试重新读取设备参数信息。'
                : ''
            }
            key="ok"
          >
            <span style={{ marginLeft: '8px' }}>
              <PermissionButton
                operateType="/car-admin/parameter-configuration/aeb-params-config"
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
                disabled={deveiceOffline || initLoading || offlineInfo}
              >
                配置
              </PermissionButton>
            </span>
          </Tooltip>
        )
      ]}
      // 用作参数信息查看时点击浮层可以关闭弹窗
      maskClosable={isViewParam}
    >
      <LoadingWrapper spinning={initLoading}>
        <Form
          form={form}
          autoComplete="off"
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
          onFinish={onConfigure}
          onFinishFailed={onFormFailed}
          labelWrap
          scrollToFirstError
          onValuesChange={onFormChange}
        >
          <Row gutter={formSearchGutter} wrap>
            <Col span={8}>
              <Form.Item
                label="参数版本"
                name="paramsVersion"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  { required: true, message: '参数版本读取失败' },
                  {
                    type: 'number',
                    min: 1,
                    message: '参数版本读取值不合理(合理区间1-4294967295)'
                  },
                  {
                    type: 'number',
                    max: 4294967295,
                    message: '参数版本读取值不合理(合理区间1-4294967295)'
                  }
                ]}
              >
                <InputNumber
                  placeholder="参数版本"
                  style={{ width: '100%' }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Collapse activeKey={activeKeys} onChange={onCollapseChange}>
            {getAebConfigParams().map((panel: any) => {
              const { panelTitle, panelKey } = panel;
              if (panelKey === 'angularRadar' && !angularRadarVisible) {
                return null;
              }
              if (panelKey === 'demo' && !demoVisible) {
                return null;
              }
              if (panelKey === 'imu' && !trBrkVisible) {
                return null;
              }
              if (panelKey === 'rearUltrasonic' && !rearUltrasonicVisible) {
                return null;
              }
              return (
                <Panel header={panelTitle} key={panelKey} forceRender>
                  {panel.formItems.map(
                    (formItemRow: any, formItemIndex: number) => (
                      <Row
                        gutter={formSearchGutter}
                        wrap
                        key={`${panelKey}-${formItemIndex}`}
                      >
                        {formItemRow.map((formItem: any) => {
                          const {
                            label,
                            name,
                            type,
                            step,
                            min,
                            max,
                            addonAfter,
                            disabledField,
                            visibleField,
                            extra,
                            at,
                            getValueFromEvent,
                            optionsData,
                            judgeVisible
                          } = formItem;
                          const formItemCom: any = {
                            switch: Switch,
                            inputNumber: InputNumber,
                            select: Select
                          };
                          let formItemProps = {};
                          let matchComProps = {};
                          if (type === 'switch') {
                            formItemProps = {
                              valuePropName: 'checked',
                              getValueFromEvent
                            };
                            matchComProps = {
                              checkedChildren: '开',
                              unCheckedChildren: '关'
                            };
                          }
                          if (type === 'inputNumber') {
                            formItemProps = {
                              rules: [
                                { required: true, message: `请输入${label}` },
                                {
                                  type: 'number',
                                  min: min,
                                  max: max,
                                  message: `请输入指定区间的值(${min} ~ ${max})`
                                }
                              ]
                            };
                            matchComProps = {
                              placeholder: `${min} ~ ${max}`,
                              addonAfter,
                              step,
                              style: { width: '100%' }
                            };
                          }
                          if (type === 'select') {
                            formItemProps = {
                              rules: [
                                { required: true, message: `请选择${label}` }
                              ]
                            };
                            matchComProps = {
                              placeholder: `请选择${label}`,
                              options: optionsData
                            };
                          }
                          const MatchCom = formItemCom[type];
                          if (
                            judgeVisible &&
                            originDataRef.current &&
                            isNil(originDataRef.current?.[name])
                          ) {
                            // 当没有读取到值时需要特殊判断是否显示的表单项不显示
                            return null;
                          }
                          return (
                            <Form.Item shouldUpdate noStyle key={name}>
                              {() => {
                                // 控制表单项是否显示
                                let isShow = true;
                                if (visibleField) {
                                  const visibleFieldArr = Array.isArray(
                                    visibleField
                                  )
                                    ? visibleField
                                    : [visibleField];
                                  const visibleFieldValue =
                                    form.getFieldsValue(visibleFieldArr);
                                  isShow = Object.values(
                                    visibleFieldValue
                                  ).some((value: any) => Boolean(value));
                                }
                                // 控制表单项是否disabled
                                let isDisabled = false;
                                if (disabledField) {
                                  const disabledFieldArr = Array.isArray(
                                    disabledField
                                  )
                                    ? disabledField
                                    : [disabledField];
                                  const disabledFieldValue =
                                    form.getFieldsValue(disabledFieldArr);
                                  isDisabled = Object.values(
                                    disabledFieldValue
                                  ).some((value: any) => Boolean(value));
                                }
                                // 用作参数信息查看时表单禁用
                                if (isViewParam) {
                                  isDisabled = true;
                                }
                                if (isShow) {
                                  return (
                                    <Col span={8}>
                                      <Form.Item
                                        tooltip={at ? `AT指令：${at}` : ''}
                                        label={label}
                                        name={name}
                                        extra={extra}
                                        {...formItemProps}
                                      >
                                        <MatchCom
                                          disabled={isDisabled}
                                          {...matchComProps}
                                        />
                                      </Form.Item>
                                    </Col>
                                  );
                                } else {
                                  return null;
                                }
                              }}
                            </Form.Item>
                          );
                        })}
                      </Row>
                    )
                  )}
                </Panel>
              );
            })}
          </Collapse>
        </Form>
      </LoadingWrapper>
      {/* 填写审批原因的弹窗 */}
      {operateInfo.type === 'approve' && (
        <ApproveReasonModal
          {...operateInfo.data}
          closeModal={() => setOperateInfo({})}
          successCallback={closeModal}
        />
      )}
    </Modal>
  );
};

export default AebParamsModal;
