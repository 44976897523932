/**
 * 批量导入
 */
import React, { useState } from 'react';
import { Button, Modal, Form, message } from 'antd';
import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import PermissionButton from '@/components/permissionButton';
import UploadExcel from '@/components/uploadExcel';
import { dataURLtoBlob } from '@/utils/download';
import { carImportRequestPath } from '@/service/carManage';
import styles from './index.module.scss';

const CarImport = () => {
  const [selectCategory, setSelectCategory] = useState(''); // 选择渠道
  const [uploadVisible, setUploadVisible] = useState(false); // 上传的弹窗显示控制

  // 切换上传弹窗的显示
  const toggleUploadVisible = () => {
    setUploadVisible(!uploadVisible)
  }

  // 上传失败的方法
  const uploadFail = (resData: any, resCode: any) => {
    if (resCode === 1413) {
      const blob = dataURLtoBlob(resData);
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = 'upload_car_error.xlsx';
      a.href = blobUrl;
      a.click();
    }
  }

  return (
    <div className={styles.carImport}>
      <div className={styles.title}>车辆批量导入</div>
      <div className={styles.content}>
        <div className={styles.tips}>
          <div className={styles.desc}>此页面适用于设备车辆批量添加</div>
          <div className={styles.desc}>步骤：</div>
          <div className={styles.step}>
            <div>1.下载示例文件。</div>
            <div>2.把要导入到信息填写到示例文件中。</div>
            <div>3.选择需要导入的渠道。</div>
            <div>4.点击导入按钮选择准备好的文件。</div>
            <div>5.检查车辆是否添加成功。</div>
          </div>
        </div>
        <Button href="/car_import_example.xlsx" type="primary" block icon={<DownloadOutlined />}>下载导入示例文件</Button>
        <PermissionButton operateType='/car-admin/car-import/import' block icon={<CloudUploadOutlined />} onClick={toggleUploadVisible}>上传车辆excel文件</PermissionButton>
      </div>
      <Modal title="上传车辆信息" footer={null} visible={uploadVisible} onCancel={toggleUploadVisible} centered>
        <div className={styles.importModal}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <Form.Item label="渠道选择">
              <CategoryTreeSelect onChange={(value) => setSelectCategory(value)} />
            </Form.Item>
          </Form>
          <UploadExcel
            uploadRequestPath={carImportRequestPath}
            uploadExtraData={{ cateId: selectCategory }}
            uploadButtonProps={{ children: '上传文件', block: true, disabled: !selectCategory }}
            uploadSuccessFunc={() => {
              message.success('上传成功');
              toggleUploadVisible();
            }}
            uploadFailFunc={uploadFail}
          />
        </div>
      </Modal>
    </div>
  )
}

export default CarImport;