/**
 * 联系方式弹窗
 */
import React from 'react';
import { Modal } from 'antd';
import CommonTable from '@/components/commonTable';

interface ContactModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const ContactModal = (props: ContactModalProps) => {
  const { data, closeModal } = props;
  // 表格列显示
  const columns = [
    {
      title: '',
      dataIndex: 'title',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '电话',
      dataIndex: 'phone',
    },
  ]

  const tableData = [
    { title: '车队长', name: data?.car_principal || data?.carPrincipal, phone: data?.car_principal_phone || data?.carPrincipalPhone, key: '车队长' },
    { title: '司机', name: data?.car_driver || data?.carDriver, phone: data?.car_driver_phone || data?.carDriverPhone, key: '司机' },
  ];

  return (
    <Modal
      title="联系方式"
      visible
      footer={null}
      onCancel={closeModal}
      centered
    >
      <CommonTable
        columnFilter={false}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        size='small'
        scroll={{}}
      />
    </Modal>
  )
}

export default ContactModal;
   