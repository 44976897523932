/**
 * 严重故障通知的相关请求
 */
import request from "@/utils/request";

// 获取严重故障通知
export const fatalInfoRequest = () => {
  return request.get('/api/dataAnalyze/fatalInfo')
}

interface FatalCarSubscribeRequestParams {
  carId: number;
  plateNumber: string;
  groupTitle: string;
}
// 收藏故障车辆
export const fatalCarSubscribeRequest = (data: FatalCarSubscribeRequestParams) => {
  return request.post('/api/v2/fatal/subscribe', data)
}
