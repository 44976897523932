/**
 * 组织管理相关请求
 */
import request from "@/utils/request";

// 获取组织列表
export const organizationListRequest = () => {
  return request.get('/api/uaa/group/list')
}

// 添加/编辑组织
export const upsertOrganizationRequest = (data: any) => {
  return request.post('/api/uaa/group/upsert', data)
}

// 删除组织
export const deleteOrganizationRequest = (id: number) => {
  return request.delete(`/api/uaa/group/${id}`)
}

// 根据组织key或者组织name获取组织信息
export const getOrganizationRequest = (groupKey: string) => {
  return request.get(`/api/v2/system/nologin/getGroup/${groupKey}`)
}
