/**
 * 车辆在线情况相关请求
 */
import request from "@/utils/request";

export interface OnlineDayParams {
  page: number,
  limit?: number;
  beginDate: string;
  endDate: string;
  cate_id?: number;
}

// 获取车辆在线情况
export const onlineDayRequestPath = '/api/v2/car/deviceCount';
export const onlineDayRequest = (data: OnlineDayParams) => {
  return request.post(onlineDayRequestPath, data)
}
