export enum DeviceInspectStatusEnum {
  process = 0,
  notStarted = 1,
  closed = 2
}

export const deviceInspectStatusData: any = {
  [DeviceInspectStatusEnum.process]: { text: '进行中', class: 'processText' },
  [DeviceInspectStatusEnum.notStarted]: {
    text: '未开始',
    class: 'warningText'
  },
  [DeviceInspectStatusEnum.closed]: { text: '已结束', class: 'successText' }
};
