// 项目主体转换
export const mainBodyChangeData = [
  { label: "京东转中富", value: "京东转中富" },
  { label: "无", value: "无" },
];

// 分期类型
export const installmentTypeData = [
  { label: "分期", value: "分期" },
  { label: "不分期", value: "不分期" },
];

// 支付状态
export const payStateData = [
  { label: "已完成", value: "已完成" },
  { label: "未完成", value: "未完成" },
];

// 合作模式
export const modeData = [
  { label: "保险模式", value: "保险模式" },
  { label: "中富模式", value: "中富模式" },
  { label: "其他模式", value: "其他模式" },
];

// 是否存在诉讼
// export const caseFlagData = [
//   { label: "有", value: 1 },
//   { label: "无", value: 0 },
// ];

// 服务状态
export const serviceStateData = [
  { label: "服务中", value: "服务中" },
  { label: "未服务", value: "未服务" },
];

// 是否存在诉讼
export const caseFlagDataa = [
  { label: "有", value: 1 },
  { label: "无", value: 0 },
  
];

// 合同状态
export const stateData = [
  { label: "正常", value: "正常" },
  { label: "异常", value: "异常" },
  { label: "结束", value: "结束" },
];
