/**
 * 电子围栏的相关请求
 */
import request from '@/utils/request';

// 获取电子围栏区域列表
export const fenceAreaListRequest = () => {
  return request.get('/api/v2/fence/list');
};

export interface UpsertFenceRequestParams {
  id?: number;
  name: string;
  radius: number;
  headingAngleHalfRange: number;
}
// 新增/修改电子围栏区域
export const upsertFenceAreaRequest = (data: UpsertFenceRequestParams) => {
  return request.post('/api/v2/fence/upsert', data);
};

// 获取电子围栏区域详情
export const fenceAreaDetailRequest = (id: number) => {
  return request.get(`/api/v2/fence/detail/${id}`);
};

// 删除电子围栏区域
export const deleteFenceAreaRequest = (id: number) => {
  return request.delete(`/api/v2/fence/${id}`);
};

// 发布生成bin包
export const publishFenceAreaRequest = (id: number) => {
  return request.post('/api/v2/fence/publish', id, {
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
  });
};

interface UpsertFencePointRequestParams {
  id?: number;
  electronicFenceId: number;
  type: number;
  radius: number;
  headingAngleMin: number;
  headingAngleMax: number;
  lng: number;
  lat: number;
}
// 新增/修改电子围栏点标记
export const upsertFencePointRequest = (
  data: UpsertFencePointRequestParams
) => {
  return request.post('/api/v2/fence/upsert/point', data);
};

// 删除电子围栏点标记
export const deleteFencePointRequest = (id: number) => {
  return request.delete(`/api/v2/fence/point/${id}`);
};

// 批量新增/修改电子围栏点标记
export const batchUpsertFencePointRequest = (
  data: UpsertFencePointRequestParams[]
) => {
  return request.post('/api/v2/fence/insertBatch/point', data);
};

// 获取电子围栏点标记详情
export const fencePointDetailRequest = (id: number) => {
  return request.get(`/api/v2/fence/detail/point/${id}`);
};

interface UpsertFenceIntersectionRequestParams {
  id?: number;
  electronicFenceId: number;
  type: number;
  straight: number;
  leftTurn: number;
  rightTurn: number;
  angle: number;
  distance: number;
}
// 新增/修改路口类型
export const upsertFenceIntersectionRequest = (
  data: UpsertFenceIntersectionRequestParams
) => {
  return request.post('/api/v2/fence/upsert/config', data);
};

// 获取路口类型详情
export const fenceIntersectionDetailRequest = (id: number) => {
  return request.get(`/api/v2/fence/detail/config/${id}`);
};

// 火星转84
export const lngLatTransformRequest = (params: any) => {
  return request.get('/api/v2/fence/transform', { params });
};

// 生成四叉树
export const upsertTreeRequest = (data: any) => {
  return request.post('/api/v2/fence/upsert/tree', data);
};

// 验证四叉树
export const verifyTreeRequest = (data: any) => {
  return request.post('/api/v2/fence/verify/tree', data);
};
