/**
 * 传感器方案-超声波硬件方案相关请求
 */
import request from '@/utils/request';

export interface SensorUltrasonicHardwareListParams {
  page: number;
  limit: number;
  name?: string;
  supplierId?: number;
  userId?: number;
  beginTime?: string;
  endTime?: string;
}
// 传感器方案-超声波硬件方案列表
export const sensorUltrasonicHardwareListRequestPath =
  '/api/v2/ultraProbe/list';
export const sensorUltrasonicHardwareListRequest = (
  params: SensorUltrasonicHardwareListParams
) => {
  return request.get(sensorUltrasonicHardwareListRequestPath, {
    params
  });
};

export interface UpsertSensorUltrasonicHardwareParams {
  name: string;
  componentId: number;
  probeJson: string;
  description?: string;
  id?: number;
}
// 添加/编辑传感器方案-超声波硬件方案
export const upsertSensorUltrasonicHardwareRequest = (
  data: UpsertSensorUltrasonicHardwareParams
) => {
  return request.post('/api/v2/ultraProbe/upsert', data);
};

// 删除传感器方案-超声波硬件方案
export const deleteSensorUltrasonicHardwareRequest = (id: number) => {
  return request.delete(`/api/v2/ultraProbe/${id}`);
};

// 获取传感器方案-超声波硬件方案详情
export const sensorUltrasonicHardwareDetailRequest = (id: number) => {
  return request.get(`/api/v2/ultraProbe/detail/${id}`);
};
