/**
 * 我的车辆(收藏的车辆)
 */
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { Card, Button, Popconfirm, message } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from "@/components/permissionButton";
import { favoriteCarListRequest, deleteFavoriteCarRequest, favoriteGroupListRequest } from "@/service/myCar";
import { formatTime } from "@/utils/formatTime";
import FavoriteGroupListModal from './components/favoriteGroupListModal';
import FavoriteCarModal from "./components/favoriteCarModal";
import styles from '@/styles/pageContent.module.scss';

const MyCar = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // 数据是否请求中
  const [favoriteCarList, setFavoriteCarList] = useState([]); // 收藏车辆数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息
  const [favoriteGroupList, setFavoriteGroupList] = useState([]); // 收藏分组数据

  // 获取收藏车辆数据
  const getFavoriteCarList = async() => {
    try {
      setLoading(true);
      const res = await favoriteCarListRequest();
      const resData = res.data?.data || {};
      let carList: any = [];
      Object.keys(resData).forEach((item: any) => {
        carList.push({
          groupTitle: item,
          carData: resData[item] || [],
        })
      })
      // 根据收藏车辆数倒排
      carList = orderBy(carList, (item: any) => item.carData.length, ['desc']);
      setFavoriteCarList(carList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 获取收藏分组数据
  const getFavoriteGroupList = async() => {
    const res = await favoriteGroupListRequest();
    setFavoriteGroupList(res.data?.data || []);
  }

  useEffect(() => {
    getFavoriteCarList();
    getFavoriteGroupList();
  }, []);

  // 取消关注车辆
  const deleteFavoriteCar = async(id: number) => {
    const res = await deleteFavoriteCarRequest(id);
    if (res.data?.data) {
      message.success('取消关注成功');
      getFavoriteCarList();
    }
  }

  // 点击车牌跳转到车辆诊断页面
  const onPlateNumberClick = (plateNumber: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: { plateNumber, beginDate: dayjs().subtract(2, 'day').format('YYYY-MM-DD'), endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'), }
      }
    })
  }

  // 点击在线时间跳转到在线记录页面
  const onOnlineTimeClick = (plateNumber: string, day: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '在线记录',
        key: '/car-admin/online-record',
        params: { plateNumber, year: dayjs(day).format('YYYY'), }
      }
    })
  }

  // 点击运行里程跳转到轨迹回放页面
  const onMileageClick = (plateNumber: string, day: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '轨迹回放',
        key: '/car-admin/track-playback',
        params: { plateNumber, day }
      }
    })
  }

  // 点击车身信息次数跳转到车身信息页面
  const onCarInfoClick = (carIds: Array<number>, day: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车身信息',
        key: '/car-admin/car-info',
        params: { carIds, day }
      }
    })
  }

   // 点击hmw频次或aeb频次跳转到报警查询页面
  const onAlarmClick = (carIds: Array<number>, day: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '报警查询',
        key: '/car-admin/alarm-search',
        params: { carIds, day }
      }
    })
  }

  // 重新获取数据
  const reloadData = () => {
    getFavoriteCarList();
    getFavoriteGroupList();
  }

  const columns: any = [
    {
      title: '收藏分组名称',
      dataIndex: 'groupTitle',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '收藏车辆数',
      dataIndex: 'carData',
      width: 130,
      render: (value: any) => value?.length / 2,
    },
    {
      title: '备注',
      dataIndex: 'ps',
      width: 300,
      ellipsis: { showTitle: false },
      render: (value: string, record: any) => {
        const matchGroup: any = favoriteGroupList.find((item: any) => item.title === record.groupTitle);
        return (
          <RenderEllipsisColumn text={matchGroup?.ps} />
        )
      }
    }
  ];

  // 额外的展开行的渲染
  const expandedRowRender = (record: any) => {
    const columns: any = [
      {
        title: '车牌号',
        dataIndex: 'plateNumber',
        width: 110,
        onCell: (record: any, rowIndex: number) => {
          if (rowIndex % 2 === 0) {
            return { rowSpan: 2 };
          } else {
            return { rowSpan: 0 };
          }
        },
        fixed: 'left',
        render: (value: string) => (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => onPlateNumberClick(value)}>
              {value}
            </Button>
          </div>
        ),
      },
      {
        title: 'SN',
        dataIndex: 'sn',
        width: 110,
        ellipsis: { showTitle: false },
        render: (value: string) => <RenderEllipsisColumn text={value} />,
        onCell: (record: any, rowIndex: number) => {
          if (rowIndex % 2 === 0) {
            return { rowSpan: 2 };
          } else {
            return { rowSpan: 0 };
          }
        },
      },
      {
        title: '最后在线时间',
        dataIndex: 'onlineAt',
        width: 130,
        ellipsis: { showTitle: false },
        render: (value: string, record: any) => (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => onOnlineTimeClick(record.plateNumber, record.day)}>
              <RenderEllipsisColumn text={formatTime(value)} />
            </Button>
          </div>
        ),
        onCell: (record: any, rowIndex: number) => {
          if (rowIndex % 2 === 0) {
            return { rowSpan: 2 };
          } else {
            return { rowSpan: 0 };
          }
        },
      },
      {
        title: '日期',
        dataIndex: 'day',
        width: 110,
      },
      {
        title: '运行时长',
        dataIndex: 'effectMinute',
        width: 110,
      },
      {
        title: '运行里程',
        dataIndex: 'mileage',
        width: 110,
        render: (value: string, record: any) => (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => onMileageClick(record.plateNumber, record.day)}>
              {value}
            </Button>
          </div>
        ),
      },
      {
        title: '车身信息次数',
        dataIndex: 'signCount',
        width: 130,
        render: (value: string, record: any) => (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => onCarInfoClick([record.carId], record.day)}>
              {value}
            </Button>
          </div>
        ),
      },
      {
        title: 'HMW频次',
        dataIndex: 'hmwHumanBi',
        width: 110,
        render: (value: string, record: any) => (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => onAlarmClick([record.carId], record.day)}>
              {value}
            </Button>
          </div>
        ),
      },
      {
        title: 'AEB频次',
        dataIndex: 'aebBi',
        width: 110,
        render: (value: string, record: any) => (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => onAlarmClick([record.carId], record.day)}>
              {value}
            </Button>
          </div>
        ),
      },
      {
        title: '操作',
        dataIndex: 'operate',
        width: 150,
        fixed: 'right' as 'right',
        render: (value: any, record: any) => {
          return (
            <div className={styles.tableOperateColumn}>
              <Popconfirm
                title="确定要取消关注该车辆吗？"
                onConfirm={() => deleteFavoriteCar(record.id)}
              >
                <PermissionButton
                  operateType='/car-admin/my-car/cancel-follow'
                  type="link"
                >
                  取消关注
                </PermissionButton>
              </Popconfirm>
              <PermissionButton
                operateType='/car-admin/my-car/move-follow-group'
                type="link"
                onClick={() => setOperateInfo({ type: 'favorite', data: record, operateType: 'edit' })}
              >
                移动收藏分组
              </PermissionButton>
            </div>
          )
        },
        onCell: (record: any, rowIndex: number) => {
          if (rowIndex % 2 === 0) {
            return { rowSpan: 2 };
          } else {
            return { rowSpan: 0 };
          }
        },
      },
    ];
    return <CommonTable
      columnFilter={false}
      columns={columns}
      dataSource={record.carData}
      pagination={false}
      bordered
      scroll={{ y: 400 }}
      size='small'
      isNestTable
    />
  }

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={`${styles.operateBox} ${styles.spaceBetweenFlex}`}>
        <div>
          <PermissionButton
            operateType="/car-admin/my-car/add-follow"
            onClick={() => setOperateInfo({ type: 'favorite', operateType: 'add' })}
            type="primary"
          >
            添加关注车辆
          </PermissionButton>
          <Button onClick={() => setOperateInfo({ type: 'favoriteGroup' })}>收藏分组管理</Button>
        </div>
        <Button icon={<RedoOutlined />} onClick={reloadData}>刷新</Button>
      </div>
      <CommonTable
        rowKey='groupTitle'
        columns={columns}
        dataSource={favoriteCarList}
        pagination={false}
        bordered
        loading={loading}
        expandable={{
          expandedRowRender,
        }}
      />
      {/* 车辆收藏分组列表 */}
      {operateInfo.type === 'favoriteGroup' && 
        <FavoriteGroupListModal onClose={() => setOperateInfo({})} reloadData={reloadData} />
      }
      {operateInfo.type === 'favorite' && 
        <FavoriteCarModal
          closeModal={() => setOperateInfo({})}
          reloadData={reloadData}
          data={operateInfo.data}
          operateType={operateInfo?.operateType}
        />
      }
    </Card>
  )
}

export default MyCar;