/**
 * adas设备管理
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm, Image, Select } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { useTable } from '@/utils/useTable';
import { adasListRequest, adasDeleteRequest, adasListRequestPath } from '@/service/adasEquipmentManage'
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import dayjs from 'dayjs';

const AdasEquipmentManage = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: adasListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    setSearchParams(newSearchParams);
  };
  
  //删除
  const deleteUser = async(id: number) => {
    const res = await adasDeleteRequest(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 厂家
  const renderType = (value:any) => {
    if (value == 1) {
      return '博实结'
    } else if (value == 2) {
      return '锐驰曼'
    }else if (value == 3) {
      return '珠海骏驰'
    }
  }
  
  // 获取导出参数
  const getExportParams = () => {
    const params = form.getFieldsValue();
    const values = form.getFieldsValue();
    params.export = true;
    return params;
  }

    // 是否上线
    const onlineFlag = [
      { label: '未上线', value: 0 },
      { label: '上线', value: 1 }
    ];

  // 表格列显示
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 60,
      ellipsis: { showTitle: false },
    },
    {
      title: 'ADAS终端编号',
      dataIndex: 'sn',
      width: 80,
      ellipsis: { showTitle: false },
    },
    {
      title: 'ADAS流量卡号',
      dataIndex: 'sim',
      width: 80,
      ellipsis: { showTitle: false },
    },
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 80,
      ellipsis: { showTitle: false },
    },
    {
      title: '终端号',
      dataIndex: 'terminal',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: '厂家',
      dataIndex: 'manufacturer',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: number) => renderType(value),
    },
    {
      title: '协议版本',
      dataIndex: 'version',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: '是否上线',
      dataIndex: 'onlineFlag',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: number) => <RenderEllipsisColumn text={value === 1 ? '上线':'未上线'} />,
    },
    {
      title: '最新上线时间',
      dataIndex: 'onlineTime',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')} /> : ''
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 150,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/adas-equipment-manage/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
          
            <Popconfirm
              title="您确定要删除该数据吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/adas-equipment-manage/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item label="ADAS终端编号" name="sn">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="ADAS流量卡号" name="sim">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="车牌号" name="plateNumber">
            <Input placeholder='请输入' allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item label="是否上线" name="onlineFlag">
        <Select options={onlineFlag} allowClear />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="&nbsp;">
            <div className={styles.searchBox}>
              <Button type="primary" htmlType="submit">搜索</Button>
              {/* 王美丽新增导出按钮*/}
              <ExportButton 
                  url={adasListRequestPath}
                  fileName='adas设备管理'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/adas-equipment-manage/export'
                />
            </div>
          </Form.Item>
        </Col>
      </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/adas-equipment-manage/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default AdasEquipmentManage;
