/**
 * 渠道设备管理费用相关请求
 */
import request from "@/utils/request";

// 获取渠道设备管理费用
export const channelManageCostRequestPath = '/api/dataAnalyze/deviceCostReport';
export const channelManageCostRequest = (params: any) => {
  return request.get(channelManageCostRequestPath, {
    params
  })
}

// 导出渠道设备管理费用
export const channelManageCostReportRequestPath = '/api/v2/word/deviceCostReport';
