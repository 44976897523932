/**
 * 操作转保
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import DatePicker from '@/components/datePicker';
import dayjs from 'dayjs';
import { changeUnderwritingRequest } from '@/service/underwritingRecord';
import {upsertUnderwritingRequest } from '@/service/underwritingRecord';


interface OperateModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void;
}
const ChangeCancellationModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      params.revisitDate = dayjs(values.revisitDate).format("YYYY-MM-DD");
      params.id = data.id;
      const res = await changeUnderwritingRequest(params);;
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        //改变当条的服务状态
        const aaa = {...data}
        aaa.serviceState = '转保'
        const res = await upsertUnderwritingRequest(aaa);
        message.success('转保成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={'编辑转保'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item
          label="转保日期"
          name="updataAt"
          rules={[
            { required: true, message: '请选择转保日期' }
          ]}
        >
          <DatePicker
            placeholder="请选择转保日期"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangeCancellationModal;