/**
 * 自定义hooks
 */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from 'react-redux';
import { debounce, throttle } from 'lodash';
import { ownRound } from './math';

// useEffect仅在状态变更时调用(第一次render不调用)
export const useNotFirstRenderEffect = (func: () => void, deps: any) => {
  const hasFirstRender = useRef(false); // 是否已经第一次render
  useEffect(() => {
    if (hasFirstRender.current) {
      func();
    } else {
      hasFirstRender.current = true;
    }
  }, deps);
}

// useState改变状态后支持回调
export const useCallbackState = (initData: any) => {
  const cbRef: any = useRef(); // 存储回调函数
  const [data, setData] = useState(initData);

  useEffect(() => {
    cbRef.current && cbRef.current(data);
  }, [data]);

  return [data, function (newData: any, callback: any) {
    cbRef.current = callback;
    setData(newData);
  }];
}

// 获取用户分组arr
export const useUserCateIdArr = () => {
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;
  return userDetail.cateIdArr || [];
}

// 防抖
export const useDebounce = (
  fun: (...args: any) => any,
  deps: any = [],
  time = 600
) => useCallback(debounce(fun, time), deps);

// 节流
export const useThrottle = (
  fun: (...args: any) => any,
  deps: any = [],
  time = 600
) => useCallback(throttle(fun, time), deps);

// 获取浏览器的高度和宽度和dpr信息
export const useWinSize = () => {
  const [windowSize, setWindowSize] = useState({
    winHeight: document.documentElement.clientHeight,
    winWidth: document.documentElement.clientWidth,
    dpr: window.devicePixelRatio,
    zoom: ownRound(1 / window.devicePixelRatio, 2)
  });

  const handleResize = () => {
    setWindowSize({
      winHeight: document.documentElement.clientHeight,
      winWidth: document.documentElement.clientWidth,
      dpr: window.devicePixelRatio,
      zoom: ownRound(1 / window.devicePixelRatio, 2)
    });
  };

  // 节流的handleResize
  const throttleHandleResize = useThrottle(handleResize);

  useEffect(() => {
    window.addEventListener('resize', throttleHandleResize);
    return () => window.removeEventListener('resize', throttleHandleResize);
  }, []);

  return windowSize;
};
