/**
 * 客服任务相关请求
 */
import request from "@/utils/request";

export interface ServiceTaskListRequestParams {
    page: number; 
    limit: number;
}

export const serviceTaskListRequestPath = '/api/v2/customerTask/list';

// 获取数据
export const serviceTaskListRequest = (params: ServiceTaskListRequestParams) => {
    return request.get(serviceTaskListRequestPath, {
        params,
    })
}

// 添加数据
export const upsertServiceTask = (data: any) => {
    return request.post('/api/v2/customerTask/upsert', data)
}

// 删除用户
export const DeleteServiceTask = (id: number) => {
    return request.delete(`/api/v2/customerTask/${id}`)
}

// 获取数据
export const commentListRequest = (params: ServiceTaskListRequestParams) => {
    return request.get('/api/v2/commentRecord/list', {
        params,
    })
}

// 添加评论
export const upsertTaskDetail = (data: any) => {
    return request.post('/api/v2/commentRecord/upsert', data)
}

// 删除评论
export const deleteTaskRecoed = (id: number) => {
    return request.delete(`/api/v2/commentRecord/${id}`)
}

// 批量导入
export const importServiceTaskRequestPath = '/api/v2/customerTask/import';
