/**
 * 维保记录
 */
import React, { useEffect, useState } from "react";
import { Button, Drawer, Timeline, Divider, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { maintenancesRecordRequest, deleteMaintenancesRecord } from '@/service/carDiagnosis';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import { getInnerPermissionEnable } from '@/utils/permission';
import OperateMaintenance from '../operateMaintenance';
import MaintenanceItem from '../maintenanceItem';
import styles from './index.module.scss';
import { downloadFile } from '@/utils/download';
import MaintenancePictureModal from '@/components/maintenancePictureModal';

interface MaintenanceRecordProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭维保记录弹层的方法
}
const MaintenanceRecord = (props: MaintenanceRecordProps) => {
  const { carInfo, carInfo: { plate_number, id }, onClose } = props;
  const [page, setPage] = useState(1); // 页码
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [recordData, setRecordData] = useState<any>([]); // 维保记录数据
  const [loading, setLoading] = useState<boolean>(false) // 维保记录数据是否在请求中
  const [operateVisible, setOperateVisible] = useState<boolean>(false) // 添加维保记录的弹窗是否显示
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息

  // 获取维保记录
  const getMaintenancesRecord = async(newPage: number) => {
    try {
      setLoading(true);
      const res = await maintenancesRecordRequest({ carId: id, page: newPage });
      const data = res.data?.data || [];
      if (newPage === 1) {
        setRecordData(data);
      } else {
        setRecordData([...recordData, ...data]);
      }
      setPage(newPage);
      setLoading(false);
      setTotalCount(res.data?.count || 0);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMaintenancesRecord(1);
  }, []);

  // 加载更多
  const loadMore = () => {
    getMaintenancesRecord(page + 1);
  }

  // 删除维保记录
  const deleteRecord = async (id: number) => {
    // 有maintenanceDeleteSuper权限的用户可以删除别人创建的维保记录
    const requestData = { id, isForceDel: getInnerPermissionEnable('/car-admin/car-diagnosis/maintenance-super-delete') }
    const res = await deleteMaintenancesRecord(requestData);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getMaintenancesRecord(1);
    }
  }

  // 批量导出照片
  const batchPicture = (value:any) => {
    let picture = -1
    const piliang = setInterval(function(){
      picture++
      if (picture == value.resources.length) {
        clearInterval(piliang)
      } else {
        downloadFile(value.resources[picture].url,`维保照片或视频(${picture + 1}).${value.resources[picture].contentType.slice(6)}`)
      }
    },500);
  }

  //处理状态
  const recordType = (value:any) => {
    if (value == 'customer') {
      return '客服记录'
    } else if (value == 'call') {
      return '通话记录'
    } else if (value == 'maintains') {
      return '维保记录'
    }
  }

  return (
    <Drawer
      title={`${plate_number}维保记录`}
      visible
      onClose={onClose}
      className={styles.recordDrawer}
      size="large"
    >
      <PermissionButton
        type="primary"
        operateType='/car-admin/car-diagnosis/maintenance-add'
        style={{ marginBottom: '16px' }}
        onClick={() => setOperateVisible(true)}
      >
        新增维保记录
      </PermissionButton>
      <LoadingWrapper spinning={loading}>
        <Timeline>
          {recordData.map((item: any) => {
            if (item.messageType == 'maintains') {
              return (
                <Timeline.Item key={item.mainId}>
                  <div>
                    <span style={{color: '#999'}}>{recordType(item.messageType)}&nbsp;&nbsp;&nbsp;</span>
                    {item.time}
                    <Popconfirm
                      title="此操作将删除该条维保记录, 是否继续?"
                      onConfirm={() => deleteRecord(item.mainId)}
                    >
                      <PermissionButton
                        operateType='/car-admin/car-diagnosis/maintenance-delete'
                        type="link"
                        style={{ padding: '0 0 0 6px', height: '26px' }}
                      >
                        <DeleteOutlined style={{ color: 'red' }} />
                      </PermissionButton>
                    </Popconfirm>
                  </div>
                  <MaintenanceItem column={{ xs: 1, sm: 2 }} data={item} type="maintenance" />
                  {/* <PermissionButton
                      operateType='/car-admin/car-diagnosis/maintenance-batch-picture'
                      onClick={() => batchPicture(item)}
                      block={true}
                    >批量导出维保照片</PermissionButton> */}
                  <Button onClick={() => setOperateInfo({ type: 'maintenancePicture', data: item })} block={true}>维保照片</Button>
                </Timeline.Item>
              )
            } else if (item.messageType == 'call') {
              return (
                <Timeline.Item key={item.callStartAt}>
                  <div>
                    <span style={{color: '#999'}}>{recordType(item.messageType)}&nbsp;&nbsp;&nbsp;</span>
                    {item.callStartAt}
                  </div>
                  <MaintenanceItem column={{ xs: 1, sm: 2 }} data={item} type="callRecord" />
                </Timeline.Item>
              )
            } else if (item.messageType == 'customer') {
              return (
                <Timeline.Item key={item.time}>
                  <div>
                    <span style={{color: '#999'}}>{recordType(item.messageType)}&nbsp;&nbsp;&nbsp;</span>
                    {item.time}
                  </div>
                  <MaintenanceItem column={{ xs: 1, sm: 3 }} data={item} type="customerRecord" />
                </Timeline.Item>
              )
            }
          })}
        </Timeline>
        {recordData.length ?
          <Divider plain>
            {totalCount > recordData.length ?
              <Button type="primary" onClick={loadMore}>加载更多</Button> :
              "已加载全部数据"
            }
          </Divider> :
          <Divider plain>暂无维保记录</Divider>
        }
      </LoadingWrapper>
      {/* 添加维保记录的弹窗 */}
      {operateVisible && <OperateMaintenance
        carInfo={carInfo}
        onClose={() => setOperateVisible(false)}
        reloadData={() => getMaintenancesRecord(1)}
      />}
      {operateInfo.type === 'maintenancePicture' &&
        <MaintenancePictureModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Drawer>
  )
}

export default MaintenanceRecord;