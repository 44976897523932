/**
 * 事故报告相关请求
 */
import request from "@/utils/request";

export interface AccidentIndexRequestParams {
  plateNumber: string,
  date: string;
}

// 获取事故报告指标
export const accidentIndexRequest = (params: AccidentIndexRequestParams) => {
  return request.get('/api/v2/accident/accidentIndex', {
    params,
  })
}

// 获取车辆的基本信息及驾驶行为统计信息
export const drivingBehaviorStatisticRequest = (params: AccidentIndexRequestParams) => {
  return request.get('/api/v2/car/drivingBehaviorStatistic', {
    params,
  })
}

// 事故报告请求路径
export const accidentReportRequestPath = '/api/v2/accident/report';
