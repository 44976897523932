/**
 * 客服记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import { Button, Drawer, Timeline, Divider, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { serviceRecoedRequest, deleteServiceRecoed, serviceAndWorkOrder, deleteServiceAndWorkOrder, closeServiceAndWorkOrder } from '@/service/carDiagnosis';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import OperateService from '../operateService';
import OperateModal from '../../../workOrder/components/operateModal';
import WorkOrderDetail from '../../../workOrder/components/workOrderDetail';
import { deleteWorkOrderRequest, workOrderDetailRequest } from '@/service/workOrder';
import ServiceItem from '../serviceItem';
import styles from './index.module.scss';

interface MaintenanceRecordProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭客服记录弹层的方法
}
const ServiceRecoed = (props: MaintenanceRecordProps) => {
  const { carInfo, carInfo: { plate_number, id ,frame_number}, onClose } = props;
  const [page, setPage] = useState(1); // 页码
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [recordData, setRecordData] = useState<any>([]); // 客服记录数据
  const [loading, setLoading] = useState<boolean>(false) // 客服记录数据是否在请求中
  const [operateVisible, setOperateVisible] = useState<any>({}) // 添加客服记录的弹窗
  const [addWorkOrder, setAddWorkOrder] = useState<any>({}); // 创建工单的弹窗
  const [operateInfo, setOperateInfo] = useState<any>({}); // 查看工单的弹窗
  
  // 获取客服记录
  const getMaintenancesRecord = async(newPage: number) => {
    try {
      setLoading(true);
      const res = await serviceRecoedRequest({frameNumber: frame_number,page: newPage, limit:10 });
      const data = res.data?.data || [];
      if (newPage === 1) {
        setRecordData(data);
      } else {
        setRecordData([...recordData, ...data]);
      }
      setPage(newPage);
      setLoading(false);
      setTotalCount(res.data?.count || 0);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMaintenancesRecord(1);
  }, []);

  // 加载更多
  const loadMore = () => {
    getMaintenancesRecord(page + 1);
  }

  // 删除客服记录
  const deleteRecord = async (id: number) => {
    const res = await deleteServiceRecoed(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getMaintenancesRecord(1);
    }
  }

  // 查看工单
  const examineWorkOrder = async (orderId:any) => {
    const res = await workOrderDetailRequest(orderId);
    const data = res.data?.data || {};
    setOperateInfo({ type: 'detail', data: data })
  }

  // 撤销工单
  const deleteWorkOrder = async(value: any) => {
    const params = { orderId: value.orderId, customerId: value.id }
    const res = await deleteServiceAndWorkOrder(params)
    if (res?.data?.code === 0) {
      const res = await deleteWorkOrderRequest(value.orderId);
      if (res?.data?.code === 0) {
        message.success('撤销成功');
        getMaintenancesRecord(1);
      }
    }
  }

  // 结束处理
  const closeWorkOrder = async(value: any) => {
    const params = { customerId: value.id }
    const res = await closeServiceAndWorkOrder(params)
    if (res?.data?.code === 0) {
      message.success('已结束处理');
      getMaintenancesRecord(1);
    }
  }

  // 插入客服记录和工单的关系
  const getWorkOrderId = async (orderId:any,customerId:any) => {
    const params = { orderId:orderId, customerId:customerId }
    const res = await serviceAndWorkOrder(params)
    getMaintenancesRecord(1);
  }

  const anyData = () => {
    
  }

  return (
    <Drawer
      title={`${plate_number}客服记录`}
      visible
      onClose={onClose}
      className={styles.recordDrawer}
      size="large"
    >
      <PermissionButton
        type="primary"
        operateType='/car-admin/car-diagnosis/service-recoed-add'
        style={{ marginBottom: '16px' }}
        onClick={() => setOperateVisible({show: true, type: 'carDiagnosisAdd'})}
      >
        新增客服记录
      </PermissionButton>
      <LoadingWrapper spinning={loading}>
        <Timeline>
          {recordData.map((item: any, index: any) => {
            return (
              <Timeline.Item key={index}>
                <div>
                  {item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : item.createdAt}
                  <Popconfirm
                    title="此操作将删除该条客服记录, 是否继续?"
                    onConfirm={() => deleteRecord(item.id)}
                  >
                    <PermissionButton
                      operateType='/car-admin/car-diagnosis/service-recoed-delete'
                      type="link"
                      style={{ padding: '0 0 0 6px', height: '26px' }}
                    >
                      <DeleteOutlined style={{ color: 'red' }} />
                    </PermissionButton>
                  </Popconfirm>
                </div>
                <ServiceItem column={{ xs: 1, sm: 3 }} data={item} type="maintenance" />
                <div className={styles.threeButton}>
                  <div>
                    {item.state == 1 ? <PermissionButton
                      operateType='/car-admin/car-diagnosis/service-recoed-edit'
                      onClick={() => setOperateVisible({show: true, type: 'edit', data: item})}
                      block={true}
                    >编辑</PermissionButton> : item.state == 2 ? <PermissionButton
                      operateType='/car-admin/car-diagnosis/service-recoed-edit'
                      onClick={() => setOperateVisible({show: true, type: 'edit', data: item})}
                      block={true}
                    >编辑</PermissionButton> : <div></div>}
                  </div>
                  <div>
                    {item.state == 1 ? <PermissionButton 
                      operateType='/car-admin/car-diagnosis/service-recoed-work-order-add'
                      onClick={() => setAddWorkOrder({type: 'servicrAdd', data: item})}
                      block={true}
                    >创建工单</PermissionButton> : item.state == 2 ? <PermissionButton 
                      operateType='/car-admin/car-diagnosis/service-recoed-work-order-examine'
                      onClick={() => examineWorkOrder(item.orderId)} 
                      block={true}
                    >查看工单</PermissionButton> : item.state == 3 ? <PermissionButton 
                      operateType='/car-admin/car-diagnosis/service-recoed-work-order-examine'
                      onClick={() => examineWorkOrder(item.orderId)} 
                      block={true}
                    >查看工单</PermissionButton> : <div></div>}
                  </div>
                  <div>
                    {item.state == 1 ? <Popconfirm
                      title="是否结束处理此工单?"
                      onConfirm={() => closeWorkOrder(item)}
                    >
                      <PermissionButton 
                        operateType='/car-admin/car-diagnosis/service-recoed-work-order-delete'
                        block={true}
                      >结束处理</PermissionButton>
                    </Popconfirm> : item.state == 2 ? <Popconfirm
                      title="是否撤销此工单?"
                      onConfirm={() => deleteWorkOrder(item)}
                    >
                      <PermissionButton 
                        operateType='/car-admin/car-diagnosis/service-recoed-work-order-delete'
                        block={true}
                      >撤销工单</PermissionButton>
                    </Popconfirm> : <div></div>}
                  </div>
                </div>
              </Timeline.Item>
              )
          })}
        </Timeline>
        {recordData.length ?
          <Divider plain>
            {totalCount > recordData.length ?
              <Button type="primary" onClick={loadMore}>加载更多</Button> :
              "已加载全部数据"
            }
          </Divider> :
          <Divider plain>暂无客服记录</Divider>
        }
      </LoadingWrapper>
      {/* 添加客服记录的弹窗 */}
      {operateVisible.show && 
        <OperateService
          type={operateVisible.type}
          onClose={() => setOperateVisible({show: false, type: 'carDiagnosisAdd'})}
          reloadData={() => getMaintenancesRecord(1)}
          carId={carInfo}
          anyData={anyData}
          editData={operateVisible.data}
        />
      }
      {/* 创建工单的弹窗 */}
      {['add', 'edit', 'servicrAdd'].includes(addWorkOrder?.type) &&
        <OperateModal
          type={addWorkOrder.type}
          workOrderInfo={addWorkOrder.data}
          onClose={() => setAddWorkOrder({})}
          reloadData={() => getMaintenancesRecord(1)}
          workId={getWorkOrderId}
        />
      }
      {/* 查看工单的弹窗 */}
      {operateInfo?.type === 'detail' &&
        <WorkOrderDetail item={operateInfo.data} onClose={() => setOperateInfo({})} />
      }
    </Drawer>
  )
}

export default ServiceRecoed;