/**
 * 京东安联财产保险项目
 */
import React, { useEffect, useState, useRef } from 'react';
import styles from '../../index.module.scss';
import * as echarts from 'echarts/core';
import { getTitleDataNum, getCarOperationTime, getCarSpeed, getCarAlarmBraking, getCarAlarmBrakingAverage, getCarPortion, getCarOnline, getCarEarlyWarning, getCarIncrease, getCarEarlyWarningAverage } from '../../../../service/dataSizeDetails'
import { GeoComponent, TooltipComponent, GridComponent, LegendComponent, MarkLineComponent, ToolboxComponent } from 'echarts/components';
import dayjs from 'dayjs';
import { importLocationMap } from '@/utils/map';
import styleJson from '../../custom_map_config (1).json';
import { carLocationRequest } from '@/service/carLocation';
import { drawCar } from '@/utils/location';
import LoadingWarpper from '@/components/loadingWrapper';

echarts.use([ GeoComponent, TooltipComponent, GridComponent, LegendComponent, MarkLineComponent, ToolboxComponent ]);

const PagesOne = () => {
  const [loading, setLoading] = useState(false); // 地图数据请求中
  const [overspeedCarState, setOverspeedCarState] = useState<any>(false); // 判断超速车辆列表
  const [overspeedCarList, setOverspeedCarList] = useState<any>(); // 超速车辆列表
  const [overspeedCarNone, setOverspeedCarNone] = useState<any>(false); // 是否滚动
  const [overspeedCarPx, setOverspeedCarPx] = useState<any>(0); // 滚动
  const [titleState, setTitleState] = useState<any>(false); // 标题
  const [titleData, setTitleData] = useState<any>(); // 标题
  const [echartsCutPx, setEchartsCutPx] = useState<any>(0); // 右边柱状图滚动距离
  const [echartsCutMidPx, setEchartsCutMidPx] = useState<any>(0); // 中间柱状图滚动距离
  const [echartsCutTitle, setEchartsCutTitle] = useState<any>(true); // 小标题切换
  let mapRef: any = useRef(null); // 存储地图实例

  useEffect(() => {
    carAlarmBrakingAverage()
    carEarlyWarningAverage()
    echartsCut()
    carIncrease()
    carSpeed()
    carOperationTime();
    carOnline();
    carPortion()
    carEarlyWarning()
    carAlarmBraking()
    importLocationMap(carDistribution);
  }, [])

  const params = {cateId:[1916,1917,3298,2050,1852]}

  // 柱状图轮播和获取标题数据
  const echartsCut = async () => {
    const params = {cateId:[1916,1917,3298,2050,1852,3455,3456,3457,3461]}
    const res = await getTitleDataNum(params)
    setTitleData(res.data)
    setTitleState(true)
    let ppp = 0
    const echartsTimer = setInterval(function(){
      if (ppp == 3) {
        setEchartsCutPx(0)
        ppp = -1
      }
      if (ppp == 0 || ppp == 2) {
        setEchartsCutTitle(false)
      }
      if (ppp == 1 || ppp == -1) {
        setEchartsCutTitle(true)
      }
      ppp++;
      setEchartsCutPx(ppp*212)
      setEchartsCutMidPx(ppp*152)
    },2500);
  }

  // 车辆运行工时
  const carOperationTime = async () => {
    const res = await getCarOperationTime(params)
    const operateTimeData = res.data || [];
    const timeData: any = [];
    const hour4Data: any = [];
    const hour8Data: any = [];
    const hour12Data: any = [];
    const hour16Data: any = [];
    const moreHour16Data: any = [];
    operateTimeData.forEach((item: any) => {
      if (item.s === '小于4小时') {
        timeData.push(item.x);
        hour4Data.push(item.y);
      }
      if (item.s === '小于8小时') {
        hour8Data.push(item.y);
      }
      if (item.s === '小于12小时') {
        hour12Data.push(item.y);
      }
      if (item.s === '小于16小时') {
        hour16Data.push(item.y);
      }
      if (item.s === '大于16小时') {
        moreHour16Data.push(item.y);
      }
    })
    const operateTimeConfig = {
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        data: timeData,
        axisLabel: {
          textStyle: {
            fontSize: 8,//字体大小
            color: '#ffffff',
          }
        }
      },
      yAxis: [{
        type: 'value',
        axisLabel : {
          textStyle: {
            fontSize: 8,//字体大小
            color: '#ffffff',
          }
        },
        splitLine:{
          show: false
        }
      }],
      legend: {
        data: ['小于4小时', '小于8小时', '小于12小时', '小于16小时', '大于16小时'],
        textStyle:{
          fontSize: 8,//字体大小
          color: '#ffffff'//字体颜色
        },
        icon: 'circle'
      },
      series: [
        {
          name: '小于4小时',
          type: 'bar',
          stack: 'time',
          data: hour4Data
        },
        {
          name: '小于8小时',
          type: 'bar',
          stack: 'time',
          data: hour8Data
        },
        {
          name: '小于12小时',
          type: 'bar',
          stack: 'time',
          data: hour12Data
        },
        {
          name: '小于16小时',
          type: 'bar',
          stack: 'time',
          data: hour16Data
        },
        {
          name: '大于16小时',
          type: 'bar',
          stack: 'time',
          data: moreHour16Data
        },
      ],
      grid:{ // 让图表占满容器
        top:"50px",
        left:"30px",
        right:"10px",
        bottom:"40px"
      },
      barWidth:4
    }
    const fleetRiskChart = echarts.init(document.getElementById('leftChartOne') as HTMLElement)
    fleetRiskChart.setOption(operateTimeConfig, true);
  }

  // 当日超速车辆
  const carSpeed = async () => {
    const res = await getCarSpeed(params)
    if (res.data.length !== 0) {
      setOverspeedCarList(res.data)
      setOverspeedCarState(true)
    }
    if (res.data.length > 7) {
      setOverspeedCarNone(true)
    }
    let timeNum:any = 0;
    setInterval(function(){
      if (timeNum == 5) {
        setOverspeedCarPx(0)
        timeNum = 0
      }
      timeNum++
      setOverspeedCarPx(timeNum*38)
    },2000);
  }

  // 车辆分布
  const carDistribution = async () => {
    setLoading(true);
    mapRef.current = new window.window.BMap.Map("trackMap");
    mapRef.current.centerAndZoom(new window.BMap.Point(105, 37), 4);
    mapRef.current.enableScrollWheelZoom(); // 开启鼠标滚轮缩放
    mapRef.current.setMapStyleV2({
      styleJson: styleJson
    })
    const cateId = params.cateId
    const res = await carLocationRequest({ cate_id: cateId });
    setLoading(false);
    const data = res.data?.data || [];
    drawCar(data, mapRef.current, mapRef.current, 'pagesThree');
  }

  // 报警制动总数
  const carAlarmBraking = async () => {
    const res = await getCarAlarmBraking(params)
    const xData:any = []
    const yDataOne:any = []
    const yDataTwo:any = []
    res.data.forEach((item:any) => {
      if (item.s == '报警数') {
        xData.push(item.x)
        yDataOne.push(item.y)
      }
      if (item.s == '制动数') {
        yDataTwo.push(item.y)
      }
      const chartOption = {
        backgroundColor: 'rgba(24, 8, 74, 0.2)',
        tooltip: {
          trigger: 'axis',
        },
        xAxis: { 
          type: 'category', 
          data: xData,
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          }
        },
        yAxis: { 
          type: 'value',
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          },
          splitLine:{
            show: false
          }
        },
        series: [
          {
            data: yDataOne,
            type: 'bar',
            name: '报警数',
            stack: 'time',
          },
          {
            data: yDataTwo,
            type: 'bar',
            name: '制动数',
            stack: 'time',
          },
        ],
        grid:{ // 让图表占满容器
          top:"10px",
          left:"50px",
          right:"10px",
          bottom:"20px"
        },
        barWidth:8
      };
      const fleetRiskChart = echarts.init(document.getElementById('middleChartOne') as HTMLElement)
      fleetRiskChart.setOption(chartOption, true);
      const fleetRiskCharts = echarts.init(document.getElementById('middleChartOnes') as HTMLElement)
      fleetRiskCharts.setOption(chartOption, true);
    })
  }

  // 报警制动单车平均数
  const carAlarmBrakingAverage = async () => {
    const res = await getCarAlarmBrakingAverage(params)
    const xData:any = []
    const yDataOne:any = []
    const yDataTwo:any = []
    res.data.forEach((item:any) => {
      if (item.s == '报警数') {
        xData.push(item.x)
        yDataOne.push(item.y)
      }
      if (item.s == '制动数') {
        yDataTwo.push(item.y)
      }
      const chartOption = {
        backgroundColor: 'rgba(24, 8, 74, 0.2)',
        tooltip: {
          trigger: 'axis',
        },
        xAxis: { 
          type: 'category', 
          data: xData,
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          }
        },
        yAxis: { 
          type: 'value',
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          },
          splitLine:{
            show: false
          }
        },
        series: [
          {
            data: yDataOne,
            type: 'bar',
            name: '报警数',
            stack: 'time',
          },
          {
            data: yDataTwo,
            type: 'bar',
            name: '制动数',
            stack: 'time',
          },
        ],
        grid:{ // 让图表占满容器
          top:"10px",
          left:"50px",
          right:"10px",
          bottom:"20px"
        },
        barWidth:8
      };
      const fleetRiskChart = echarts.init(document.getElementById('middleChartOneTwo') as HTMLElement)
      fleetRiskChart.setOption(chartOption, true);
      const fleetRiskCharts = echarts.init(document.getElementById('middleChartOneTwos') as HTMLElement)
      fleetRiskCharts.setOption(chartOption, true);
    })
  }

  // 分组车辆份额
  const carPortion = async () => {
    const params = {cateId:[1851]}
    const res = await getCarPortion(params)
    const data:any = []
    res.data.map((item:any) => {
      const aaa = {value: item.value, name: item.type}
      data.push(aaa)
    })
    const option = {
      series: [
        {
          type: 'pie',
          radius: ['70%', '90%'], // 通过设置内径与外径将饼图变为圆环饼图
          emphasis: { // 设置高亮时显示标签
            label: {
              show: true
            },
          },
          label: { // 设置图形标签样式
            show: false, // 未高亮时不显示标签，否则高亮时显示的标签会与静态的标签重叠
            position: 'center',
            color: '#ffffff',
            // 设置标签展示内容,其中{d}、{b}为echarts标签内容格式器
            formatter: '{d_style|{d}}{per_style|%}\n{b_style|{b}}',
            // 为标签内容指定样式，只能设置series-pie.label支持的样式
            rich:{
              d_style:{
                fontSize: 12,
              },
              b_style:{
                fontSize: 10
              }
            }
          },
          data: data
        }
      ]
    };
    const fleetRiskChart = echarts.init(document.getElementById('rightChartOne') as HTMLElement)
    let currentIndex = -1; // 当前高亮图形在饼图数据中的下标
    let changePieInterval = setInterval(selectPie, 2000); // 设置自动切换高亮图形的定时器

    function highlightPie() { // 取消所有高亮并高亮当前图形
      for(var idx in option.series[0].data)
      // 遍历饼图数据，取消所有图形的高亮效果
      fleetRiskChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: idx
      });
    // 高亮当前图形
      fleetRiskChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: currentIndex
      });
    }
    fleetRiskChart.on('mouseover', (params) => { // 用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
      clearInterval(changePieInterval);
      currentIndex = params.dataIndex;
      highlightPie();
    });
    fleetRiskChart.on('mouseout', (params) => { // 用户鼠标移出时，重新开始自动切换
      if(changePieInterval)
        clearInterval(changePieInterval);
      changePieInterval = setInterval(selectPie, 1000);
    });
    function selectPie() { // 高亮效果切换到下一个图形
      var dataLen = option.series[0].data.length;
      currentIndex = (currentIndex + 1) % dataLen;
      highlightPie();
    }
    fleetRiskChart.setOption(option, true);
  }

  // 车辆在线数量
  const carOnline = async () => {
    const res = await getCarOnline(params)
    const xData:any = []
    const yData:any = []
    res.data.map((item:any) => {
      xData.push(item.x)
      yData.push(item.y)
      const onlineChartOption = {
        tooltip: { 
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          data: xData,
          name: '时间',
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          },
        }],
        yAxis: [{
          type: 'value',
          name: '数量',
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
                type: 'dashed',
                color: ['#553F71'],
            }
          },
          axisLine:{
            show:true,
            color: '#553F71',    
          },  
        }],
        series: [{
          name: '总在线数',
          type: 'bar',
          stack: 'cate',
          data: yData,
        }],
        grid:{ // 让图表占满容器
          top:"10px",
          left:"30px",
          right:"10px",
          bottom:"40px"
        }
      };
      const fleetRiskChart = echarts.init(document.getElementById('rightChartTwo') as HTMLElement)
      fleetRiskChart.setOption(onlineChartOption, true);
      const fleetRiskChartTwo = echarts.init(document.getElementById('rightChartTwos') as HTMLElement)
      fleetRiskChartTwo.setOption(onlineChartOption, true);
    })
  }

  //车辆新增数量
  const carIncrease = async () => {
    const res = await getCarIncrease(params)
    const xData:any = []
    const yData:any = []
    res.data.map((item:any) => {
      xData.push(item.x)
      yData.push(item.y)
      const onlineChartOption = {
        tooltip: { 
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          data: xData,
          name: '时间',
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          },
        }],
        yAxis: [{
          type: 'value',
          name: '数量',
          axisLabel: {
            textStyle: {
              fontSize: 8,//字体大小
              color: '#ffffff',
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
                type: 'dashed',
                color: ['#553F71'],
            }
          },
          axisLine:{
            show:true,
            color: '#553F71',    
          },  
        }],
        series: [{
          name: '总在线数',
          type: 'bar',
          stack: 'cate',
          data: yData,
        }],
        grid:{ // 让图表占满容器
          top:"10px",
          left:"30px",
          right:"10px",
          bottom:"40px"
        }
      };
      const fleetRiskChart = echarts.init(document.getElementById('rightChartTwoCut') as HTMLElement)
      fleetRiskChart.setOption(onlineChartOption, true);
      const fleetRiskChartTwo = echarts.init(document.getElementById('rightChartTwoCuts') as HTMLElement)
      fleetRiskChartTwo.setOption(onlineChartOption, true);
    })
  }

  // 有效预警数量
  const carEarlyWarning = async () => {
    const res = await getCarEarlyWarning(params)
    const effectiveAlarmData = res.data || [];
    const xData: any = [];
    const yData: any = [];
    effectiveAlarmData.forEach((item: any) => {
    xData.push(item.x);
    yData.push(item.y);
    })
    const effectiveAlarmConfig = {
      tooltip: {
          trigger: 'axis',
      },
      xAxis: {
        data: xData,
        axisLabel: {
          textStyle: {
            fontSize: 8,//字体大小
            color: '#ffffff',
          }
        },

      },
      yAxis: [{
        type: 'value',
        axisLabel: {
          textStyle: {
            fontSize: 8,//字体大小
            color: '#ffffff',
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
              type: 'dashed',
              color: ['#553F71'],
          }
        },
        axisLine:{
          show:true,
          color: '#553F71',    
        }, 
      }],
      series: [{
        data: yData,
        type: 'bar',
      }],
      grid:{ // 让图表占满容器
        top:"10px",
        left:"40px",
        right:"10px",
        bottom:"30px"
      }
    }
    const fleetRiskChart = echarts.init(document.getElementById('rightChartThree') as HTMLElement)
    fleetRiskChart.setOption(effectiveAlarmConfig, true);
    const fleetRiskCharts = echarts.init(document.getElementById('rightChartThrees') as HTMLElement)
    fleetRiskCharts.setOption(effectiveAlarmConfig, true);
  }

  // 有效预警单车平均数
  const carEarlyWarningAverage = async () => {
    const res = await getCarEarlyWarningAverage(params)
    const effectiveAlarmData = res.data || [];
    const xData: any = [];
    const yData: any = [];
    effectiveAlarmData.forEach((item: any) => {
    xData.push(item.x);
    yData.push(item.y);
    })
    const effectiveAlarmConfig = {
      tooltip: {
          trigger: 'axis',
      },
      xAxis: {
        data: xData,
        axisLabel: {
          textStyle: {
            fontSize: 8,//字体大小
            color: '#ffffff',
          }
        },

      },
      yAxis: [{
        type: 'value',
        axisLabel: {
          textStyle: {
            fontSize: 8,//字体大小
            color: '#ffffff',
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
              type: 'dashed',
              color: ['#553F71'],
          }
        },
        axisLine:{
          show:true,
          color: '#553F71',    
        }, 
      }],
      series: [{
        data: yData,
        type: 'bar',
      }],
      grid:{ // 让图表占满容器
        top:"10px",
        left:"40px",
        right:"10px",
        bottom:"30px"
      }
    }
    const fleetRiskChart = echarts.init(document.getElementById('rightChartThreeCut') as HTMLElement)
    fleetRiskChart.setOption(effectiveAlarmConfig, true);
    const fleetRiskCharts = echarts.init(document.getElementById('rightChartThreeCuts') as HTMLElement)
    fleetRiskCharts.setOption(effectiveAlarmConfig, true);
  }
 
  return (
    <div className={styles.dataBox}>
      <div className={styles.dataBoxTitle}>京东安联财产保险项目</div>
      <div className={styles.dataBoxContent}>
        <div className={styles.dataBoxContentLeft}>
          <div>
            <div className={styles.dataBoxLittleTitle}>车辆运行工时</div>
            <div id="leftChartOne" style={{ height: '280px', marginTop: '12px' }} />
          </div>
          <div>
            <div className={styles.dataBoxLittleTitle}>当日超速车辆</div>
            <div className={styles.dataBoxContentLeftItemTitle}>
                <div style={{width:'25%'}}>车牌号</div><div style={{width:'25%'}}>速度(km/h)</div><div style={{width:'50%'}}>时间</div>
            </div>
            <div className={styles.dataBoxContentLeftList}>
              <div style={{transform:overspeedCarNone?`translateY(-${overspeedCarPx}px)`:'translateY(0px)',transition:'1s'}}>
              {overspeedCarState == false? <div className={styles.dataBoxContentLeftNone}>暂无超速车辆</div>:overspeedCarList.map((item:any) => {
                return (
                  <div className={styles.dataBoxContentLeftItem}>
                    <div className={styles.dataBoxContentLeftItemLeft}>{item.plateNumber}</div>
                    <div className={styles.dataBoxContentLeftItemMiddle}>{item.speed}</div>
                    <div className={styles.dataBoxContentLeftItemRight}>{dayjs(item.time).format('YYYY-MM-DD HH:mm:ss')}</div>
                  </div>
                )
              })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBoxContentMiddle}>
          <div className={styles.dataBoxContentMiddleTitle}>
            <div>车辆总数<p>{titleState?titleData.totalCount:''}</p></div>
            <div>今日出车<p>{titleState?titleData.onlineCount:''}</p></div>
            <div>今日新增<p>{titleState?titleData.addCount:''}</p></div>
          </div>
          <div className={styles.mapBox}>
            <div className={styles.dataBoxLittleTitle}>车辆分布</div>
            <LoadingWarpper spinning={loading} autoHeight>
              <div id="trackMap" className={styles.map} style={{height:'360px'}}/>
            </LoadingWarpper>
          </div>
          <div>
            <div className={styles.dataBoxLittleTitle}>{echartsCutTitle?'报警制动数量':'报警制动单车平均数'}</div>
            <div style={{height:'152px', overflow: 'hidden'}}>
              <div style={{transform:`translateY(-${echartsCutMidPx}px)`, transition:'1s'}}>
                <div id="middleChartOne" style={{ height: '140px', marginTop: '12px' }} />
                <div id="middleChartOneTwo" style={{ height: '140px', marginTop: '12px' }} />
                <div id="middleChartOnes" style={{ height: '140px', marginTop: '12px' }} />
                <div id="middleChartOneTwos" style={{ height: '140px', marginTop: '12px' }} />
              </div>
            </div>
            
          </div>
        </div>
        <div className={styles.dataBoxContentRight}>
          <div>
            <div className={styles.dataBoxLittleTitle}>分组车辆份额</div>
            <div id="rightChartOne" style={{ height: '150px', marginTop: '12px' }} />
          </div>
          <div>
            <div className={styles.dataBoxLittleTitle}>{echartsCutTitle?'车辆在线数量':'车辆新增数量'}</div>
            <div style={{height:'200px', overflow: 'hidden'}}>
              <div style={{transform:`translateY(-${echartsCutPx}px)`, transition:'1s'}}>
                <div id="rightChartTwo" style={{ height: '200px', marginTop: '12px'}} />
                <div id="rightChartTwoCut" style={{ height: '200px', marginTop: '12px'}} />
                <div id="rightChartTwos" style={{ height: '200px', marginTop: '12px'}} />
                <div id="rightChartTwoCuts" style={{ height: '200px', marginTop: '12px'}} />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.dataBoxLittleTitle}>{echartsCutTitle?'有效预警数量':'有效预警单车平均数'}</div>
            <div style={{height:'200px', overflow: 'hidden'}}>
              <div style={{transform:`translateY(-${echartsCutPx}px)`, transition:'1s'}}>
                <div id="rightChartThree" style={{ height: '200px', marginTop: '12px' }} />
                <div id="rightChartThreeCut" style={{ height: '200px', marginTop: '12px'}} />
                <div id="rightChartThrees" style={{ height: '200px', marginTop: '12px' }} />
                <div id="rightChartThreeCuts" style={{ height: '200px', marginTop: '12px'}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PagesOne;
