/**
 * 单条客服记录
 */
import Thumbnail from "@/components/thumbnail";
import { Descriptions, Tag } from "antd";

interface MaintenanceItemProps {
  column: {[key: string]: number};
  data: any;
  type: string;
}
const ServiceItem = (props: MaintenanceItemProps) => {
  const { column, data } = props;

  //处理状态
  const manageType = (value:any) => {
    if (value == 0) {
      return '关闭'
    } else if (value == 1) {
      return '未创建'
    } else if (value == 2) {
      return '已创建'
    } else if (value == 3) {
      return '已派单'
    }
  }

  return (
    <Descriptions bordered size="small" column={column}>
      <Descriptions.Item label="客服" span={1}>{data.nickName || '-'}</Descriptions.Item>
      {/* <Descriptions.Item label="处理状态">
        {manageType(data.state)}
      </Descriptions.Item> */}
      <Descriptions.Item label="问题来源" span={1}>{data.category}</Descriptions.Item>
      {/* <Descriptions.Item label="离线原因">{data.offlineReason}</Descriptions.Item> */}
      {/* <Descriptions.Item label="联系结果" >{data.result}</Descriptions.Item> */}
      {/* <Descriptions.Item label="用户配合度" >{data.adaptability}</Descriptions.Item> */}
      <Descriptions.Item label="问题原因" span={1}>
        {<div>{(Object.values(data.tags || {})[0] as any || []).map(((item1: any) => <Tag key={item1.tagId}>{item1.title}</Tag>))}</div>}
      </Descriptions.Item>
      <Descriptions.Item label="记录内容" span={3}>{data.content}</Descriptions.Item>
      {/* <Descriptions.Item label="客服记录图片/视频" span={3}>
          <Thumbnail
            dataSource={data.viewResources || []}
            downloadImgName="客服记录图片"
            downloadVideoName="客服记录视频"
          />
      </Descriptions.Item> */}
    </Descriptions>
  )
  
}

export default ServiceItem;