/**
 * 操作视频充值记录(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { useSelector } from 'react-redux';
import { upsertVideoRequest, videoInvestDurationRequest } from '@/service/videoInvest';
import { userListRequest } from '@/service/userManage';
import { getInnerPermissionEnable } from '@/utils/permission';
interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
  durationData?: any
  userId?: number
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data, durationData, userId } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [userData, setUserData] = useState([]); // 人员数据
  const [durationdata, setDurationData] = useState(); // 剩余时长数据
  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
    if (type === 'add') {
      if (data !== '') {
        form.setFieldsValue(data)
      }
    }
  }, []);

  useEffect(() => {
    getUserData()
    getVideoInvestDuration()
  }, []);

  // 获取用户数据
  const getUserData = async () => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setUserData(res.data?.data || [])
  }

  const { userDetail } = useSelector((state: any) => state.userStore);
  // 获取接口请求的issuId参数值
  const getSearchIssuId = () => {
    if (getInnerPermissionEnable('/car-admin/work-order/work-order-super')) {
      return undefined;
    } else {
      return userDetail.id;
    }
  }
  // 获取登录人的剩余观看时长
  const getVideoInvestDuration = async () => {
    const res = await videoInvestDurationRequest({
      userId: getSearchIssuId()
    });
    setDurationData(res.data?.data || null);
  }

  // 提交values
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      params.duration = Number(params.cost)
      params.cost = 0
      params.userId = values.userId
      params.flag = 0

      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertVideoRequest(params);
      console.log('res', res);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }
  return (
    <Modal
      title={type === 'add' ? '添加充值记录' : '编辑充值记录'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item label="用户" name="userId"  rules={[{ required: true, message: '请选择充值用户' }]} >
          <Select
            placeholder="请选择充值用户"
            options={userData}
            allowClear
            showArrow
            showSearch
            fieldNames={{
              label: 'nickname',
              value: 'id'
            }}
            optionFilterProp="nickname"
          />
        </Form.Item>
        <Form.Item
          label="充值时长"
          name="cost"
          rules={[{ required: true, message: '请输入充值时长' }]} >
          <Input placeholder='请输入充值时长' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
