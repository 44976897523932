/**
 * 车辆分组移动相关请求
 */
import request from "@/utils/request";

export interface CarGroupMoveRequestParams {
  carIds: Array<number>;
  cateId: number;
}

// 车辆分组移动
export const carGroupMoveRequest = (data: CarGroupMoveRequestParams) => {
  return request.post('/api/v2/car/moveCate', data)
}