/**
 * 报警信息
 */
import React, { useState } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import AlarmInfoModal from '@/components/alarmInfoModal';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { aebAlarmColumns } from '@/columns/aebAlarm';
import { alarmTypeValue } from '@/constants/alarm';
import { alarmListRequest, alarmListRequestPath } from '@/service/alarmSearch';
import styles from '@/styles/pageContent.module.scss';

const AlarmTable = (props: any) => {
  const { beginTime, endTime, virtualCarId, mcuType } = props;
  const [searchParams, setSearchParams] = useState({
    startTime: beginTime,
    endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss'),
    typeList: alarmTypeValue,
    idList: [virtualCarId],
    deviceType: mcuType
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: alarmListRequest,
    initPageSize: 40,
    searchParams
  });

  // 获取导出的参数
  const getExportParams = () => {
    return {
      ...searchParams,
      export: true
    };
  };

  // 刷新列表数据
  const onRefresh = () => {
    // 没有结束时间的检测任务使用当前时间作为endTime
    setSearchParams({
      ...searchParams,
      endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss')
    });
  };

  // 表格列显示
  const columns = [
    ...aebAlarmColumns.slice(1),
    {
      title: '操作',
      dataIndex: 'operate',
      width: 100,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="link"
              onClick={() => setOperateInfo({ type: 'info', data: item })}
            >
              详细信息
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className={styles.commonTableWrapper}>
      <div className={styles.operateBox} style={{ textAlign: 'right' }}>
        <Button onClick={onRefresh}>刷新</Button>
        <ExportButton
          type="primary"
          url={alarmListRequestPath}
          fileName="报警信息"
          getParamsFunc={getExportParams}
          permissionKey="/car-admin/device-inspect/export"
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        size="small"
      />
      {/* 详细信息弹窗 */}
      {operateInfo.type === 'info' && (
        <AlarmInfoModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
          infoKey="info"
        />
      )}
    </div>
  );
};

export default AlarmTable;
