/**
 * 车身信息
 */
import React, { useState } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { snColumn } from '@/columns/carDetail';
import { isKlsAeb } from '@/utils/device';
import { ownRound } from '@/utils/math';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import {
  renderOffOnColumn,
  renderGearColumn,
  renderWheelStatusColumn
} from '@/utils/carInfo';
import { carInfoListRequest, carInfoListRequestPath } from '@/service/carInfo';
import {
  vehicleBaseInfoRequest,
  vehicleBaseInfoRequestPath
} from '@/service/deviceInspect';
import styles from '@/styles/pageContent.module.scss';

const CarInfoTable = (props: any) => {
  const { beginTime, endTime, virtualCarId, mcuType, sn } = props;
  // 是否是昆仑山设备(昆仑山和黄河调用不同的接口)
  const isKls = isKlsAeb(mcuType);
  const [searchParams, setSearchParams] = useState(
    isKls
      ? {
          startTime: beginTime,
          endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss'),
          idList: [virtualCarId]
        }
      : {
          beginTime: beginTime,
          endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss'),
          sn
        }
  ); // 搜索相关的参数
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: isKls ? carInfoListRequest : vehicleBaseInfoRequest,
    initPageSize: 40,
    searchParams
  });

  // 获取导出参数
  const getExportParams = () => {
    return {
      ...searchParams,
      export: true
    };
  };

  // 刷新列表数据
  const onRefresh = () => {
    // 没有结束时间的检测任务使用当前时间作为endTime
    setSearchParams({
      ...searchParams,
      endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss')
    });
  };

  // 表格列显示
  const columns = [
    snColumn,
    {
      title: '车速',
      dataIndex: 'speed',
      width: 70,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={ownRound(value, 1)} />
      )
    },
    {
      title: 'AEB状态',
      dataIndex: 'aebsOn',
      width: 90,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '左转向灯',
      dataIndex: 'signalLeftOn',
      width: 90,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '右转向灯',
      dataIndex: 'signalRightOn',
      width: 90,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '刹车灯',
      dataIndex: 'signalBreakOn',
      width: 80,
      render: (value: boolean) => renderOffOnColumn(value)
    },
    {
      title: '档位',
      dataIndex: 'gearStatus',
      width: 70,
      render: (value: number) => renderGearColumn(value)
    },
    {
      title: '设备状态',
      dataIndex: 'deviceStatusDesc',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '油门开度',
      dataIndex: 'throttle',
      width: 90
    },
    {
      title: '刹车开度',
      dataIndex: 'brake',
      width: 90
    },
    {
      title: '方向盘角度',
      dataIndex: 'steeringWheel',
      width: 110
    },
    {
      title: '方向盘状态',
      dataIndex: 'steeringWheelStatus',
      width: 110,
      render: (value: number) => renderWheelStatusColumn(value)
    },
    {
      title: '时间',
      dataIndex: 'reportedTime',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    }
  ];

  return (
    <div className={styles.commonTableWrapper}>
      <div className={styles.operateBox} style={{ textAlign: 'right' }}>
        <Button onClick={onRefresh}>刷新</Button>
        <ExportButton
          type="primary"
          url={isKls ? carInfoListRequestPath : vehicleBaseInfoRequestPath}
          fileName={isKls ? '车身信息' : '设备基本信息'}
          getParamsFunc={getExportParams}
          permissionKey="/car-admin/device-inspect/export"
          requestMethod={isKls ? 'POST' : 'GET'}
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        size="small"
      />
    </div>
  );
};

export default CarInfoTable;
