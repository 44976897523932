/**
 * 运行状况表格
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { isNil } from 'lodash';
import CommonTable from '@/components/commonTable';
import {
  deviceOperationRequest,
  DeviceOperationRequestParams
} from '@/service/deviceOperation';
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';

interface OperationTableProps {
  operationSearchParams: Omit<DeviceOperationRequestParams, 'page' | 'limit'>;
}
const OperationTable = (props: OperationTableProps) => {
  const { operationSearchParams } = props;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState(operationSearchParams);

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: deviceOperationRequest,
    initPageSize: 40,
    searchParams
  });

  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    setSearchParams(operationSearchParams);
  }, [
    operationSearchParams.beginDate,
    operationSearchParams.endDate,
    operationSearchParams.cateIds
  ]);

  // 渲染列数据
  const renderCountColumn = (value: any) => (isNil(value) ? '/' : value);

  // 点击车牌跳转到车辆诊断页面
  const onPlateNumberClick = (plateNumber: string) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: {
          plateNumber,
          beginDate: operationSearchParams.beginDate,
          endDate: operationSearchParams.endDate
        }
      }
    });
  };

  // 表格列显示
  const columns: any = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left',
      render: (value: string) => (
        <div className={styles.tableOperateColumn}>
          <Button type="link" onClick={() => onPlateNumberClick(value)}>
            {value}
          </Button>
        </div>
      )
    },
    {
      title: '行驶里程(km)',
      dataIndex: 'diffMileage',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '行驶时长(h)',
      dataIndex: 'minute',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'AEB关闭次数',
      dataIndex: 'aebOffCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '转向灯开启次数',
      dataIndex: 'leftRightCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '车身信息次数',
      dataIndex: 'signCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '刹车次数',
      dataIndex: 'breakCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'FCW次数',
      dataIndex: 'fcwCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'FCW百公里报警频次',
      dataIndex: 'fcwPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '起步阻止次数',
      dataIndex: 'stsCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '起步阻止百公里报警频次',
      dataIndex: 'stsPer100Km',
      width: 110,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'LDW次数',
      dataIndex: 'ldwCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'LDW百公里报警频次',
      dataIndex: 'ldwPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'HMW修正次数',
      dataIndex: 'hmwHumanCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'HMW修正百公里报警频次',
      dataIndex: 'hmwHumanPer100Km',
      width: 120,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'AEB制动次数',
      dataIndex: 'aebCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'AEB百公里制动频次',
      dataIndex: 'aebPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '双目制动次数',
      dataIndex: 'binocularCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '双目制动百公里频率',
      dataIndex: 'binocularPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '毫米波制动次数',
      dataIndex: 'millCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '毫米波百公里制动频次',
      dataIndex: 'millPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '超声波制动次数',
      dataIndex: 'ultraCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '超声波百公里制动频次',
      dataIndex: 'ultraPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '角雷达制动次数',
      dataIndex: 'angleRadarCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '角雷达百公里制动频次',
      dataIndex: 'angleRadarPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '右转制动次数',
      dataIndex: 'rtbCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '右转制动百公里频次',
      dataIndex: 'rtbPer100Km',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '车速超120',
      dataIndex: 'vm120Count',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '车速为0',
      dataIndex: 've0Count',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'GPS速度为0',
      dataIndex: 'ge0Count',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '相机故障2',
      dataIndex: 'e2Count',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '相机故障91',
      dataIndex: 'e91Count',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '相机其他故障',
      dataIndex: 'cameraOtherErrCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '比例阀故障',
      dataIndex: 'proportionErrorCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '车身故障',
      dataIndex: 'vehicleBodyErrCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '控制器故障',
      dataIndex: 'controllerErrCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '控制器CAN故障',
      dataIndex: 'canErrCount',
      width: 80,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '超声波故障',
      dataIndex: 'ultrasonicErrorCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '毫米波故障',
      dataIndex: 'milimeterWaveErrCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '4G通讯故障',
      dataIndex: 'err4gCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'GPS通讯故障',
      dataIndex: 'gpsErrCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '蓝牙故障',
      dataIndex: 'btErrorCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '小屏幕故障',
      dataIndex: 'screenErrorCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '记录仪故障',
      dataIndex: 'modemErrCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'IMU故障',
      dataIndex: 'imuErrCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'IMU每小时故障频次',
      dataIndex: 'imuErrPer1Hour',
      width: 100,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '相机离线',
      dataIndex: 'cameraOfflineCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '比例阀离线',
      dataIndex: 'proportionOfflineCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '超声波离线',
      dataIndex: 'ultrasonicOfflineCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '毫米波离线',
      dataIndex: 'milimeterWaveOfflineCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '小屏幕离线',
      dataIndex: 'screenOfflineCount',
      width: 70,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '4G离线',
      dataIndex: 'offline4gCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: 'GPS离线',
      dataIndex: 'gpsOfflineCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    },
    {
      title: '蓝牙离线',
      dataIndex: 'btOfflineCount',
      width: 60,
      render: (value: any) => renderCountColumn(value)
    }
  ];

  return (
    <CommonTable
      loading={loading}
      rowKey="plateNumber"
      columns={columns}
      dataSource={data}
      bordered
      pagination={{
        total: totalCount,
        current: page,
        pageSize: limit,
        onChange: onPageChange
      }}
    />
  );
};

export default OperationTable;
