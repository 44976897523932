/**
 * 严重故障定义的相关请求
 */
import request from "@/utils/request";

interface FatalDefineListRequestParams {
  groupId: number;
  deviceType: number;
}
// 获取严重故障定义
export const fatalDefineListRequest = (params: FatalDefineListRequestParams) => {
  return request.get('/api/v2/fatal/list', {
    params
  })
}

// 获取所有严重故障项
export const fatalMapRequest = (params: { deviceType: number }) => {
  return request.get('/api/v2/fatal/map', {
    params
  })
}

interface UpsertFatalDefineRequestParams {
  id?: number;
  groupId: number;
  deviceType: number;
  content: string;
}
// 更新严重故障定义
export const upsertFatalDefineRequest = (data: UpsertFatalDefineRequestParams) => {
  return request.post('/api/v2/fatal/upsert', data)
}

