/**
 * AEB关闭详细信息(单辆车)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tooltip, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs';
import EchartsWrapper from '@/components/echartsWrapper';
import LoadingWrapper from '@/components/loadingWrapper';
import {
  aebCloseDailyTimeChartRequest,
  aebCloseTimeChartRequest
} from '@/service/aebOff';
import { formatTime } from '@/utils/formatTime';
import { ownEffectiveRound } from '@/utils/math';
import styles from './index.module.scss';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
interface AebOffInfoModalProps {
  data: any; // 单辆车信息
  searchParams: any; // 搜索参数(时间区间)
  closeModal: () => void; // 关闭弹窗的方法
}
const AebOffInfoModal = (props: AebOffInfoModalProps) => {
  const {
    data: { plateNumber, carId },
    searchParams: { beginDate, endDate },
    closeModal
  } = props;
  const [chartLoading, setChartLoading] = useState(false); // 某个时间段图表的loading
  const [dailyChartLoading, setDailyChartLoading] = useState(false); // 某一天图表的loading
  const [chartOption, setChartOption] = useState<any>(null); // 某个时间段图表的配置
  const [dailyChartData, setDailyChartData] = useState<any>({}); // 某一天图表的数据
  const [dailyChartDate, setDailyChartDate] = useState<string>(beginDate); // 某一天图表当前请求的日期

  // 获取某个时间段的每日关闭时长
  const getAebCloseChartRequest = async () => {
    try {
      setChartLoading(true);
      const params: any = {
        beginDate,
        endDate,
        idList: [carId]
      };
      const res = await aebCloseTimeChartRequest(params);
      const xData: string[] = [];
      const closeTimes: number[] = [];
      const onlineTimes: number[] = [];
      (res.data?.data || []).reverse().forEach((item: any) => {
        const { day, aebClosedTime, aebOpenedTime } = item;
        xData.push(day);
        closeTimes.push(ownEffectiveRound(aebClosedTime / 3600, 2));
        onlineTimes.push(
          ownEffectiveRound((aebOpenedTime + aebClosedTime) / 3600, 2)
        );
      });
      const chartOption = {
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true, title: '保存为图' }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          }
        },
        legend: {},
        xAxis: { type: 'category', data: xData, offset: 12 },
        yAxis: [
          {
            type: 'value',
            name: '小时',
            position: 'left',
            alignTicks: true
          }
        ],
        series: [
          {
            data: onlineTimes,
            type: 'line',
            name: '设备在线时长',
            itemStyle: {
              color: '#52c41a',
              normal: { label: { show: true } }
            }
          },
          {
            data: closeTimes,
            type: 'line',
            name: 'AEB关闭时长',
            itemStyle: {
              color: '#ff4d4f',
              normal: { label: { show: true, position: 'bottom' } }
            }
          }
        ]
      };
      setChartOption(chartOption);
      setChartLoading(false);
    } catch (error) {
      setChartLoading(false);
    }
  };

  // 获取某一天的AEB关闭数据
  const getDailyAebCloseChartRequest = async (day: string) => {
    try {
      setDailyChartLoading(true);
      const params: any = {
        carId: carId,
        day
      };
      const res = await aebCloseDailyTimeChartRequest(params);
      const { onlineTimeSlots, aebClosedTimeSlots } = res.data?.data || {};
      const offlineTimeData: any = []; // 设备不在线时间段数据
      if (onlineTimeSlots.length) {
        onlineTimeSlots.reduce((prev: any, curr: any, currIndex: number) => {
          if (currIndex === 0) {
            if (!curr.first.endsWith('00:00:00')) {
              offlineTimeData.push({
                first: `${day} 00:00:00`,
                second: curr.first
              });
            }
          } else {
            if (curr.first !== prev.second) {
              offlineTimeData.push({ first: prev.second, second: curr.first });
            }
            if (
              currIndex === onlineTimeSlots.length - 1 &&
              !curr.second.endsWith('23:59:59')
            ) {
              offlineTimeData.push({
                first: curr.second,
                second: `${day} 23:59:59`
              });
            }
          }
          return curr;
        }, {});
      } else {
        offlineTimeData.push({
          first: `${day} 00:00:00`,
          second: `${day} 23:59:59`
        });
      }
      const onlineTimeData: any = []; // 设备在线时间段数据
      (onlineTimeSlots || []).forEach((item: any) => {
        if (item.first !== item.second) {
          onlineTimeData.push(item);
        }
      });
      const aebClosedTimeData: any = []; // AEB关闭时间段数据
      if (aebClosedTimeSlots.length) {
        aebClosedTimeSlots.reduce((prev: any, curr: any, currIndex: number) => {
          if (prev.second === curr.first) {
            if (currIndex === aebClosedTimeSlots.length - 1) {
              aebClosedTimeData.push({
                first: prev.first,
                second: curr.second
              });
            }
            return { first: prev.first, second: curr.second };
          } else {
            aebClosedTimeData.push(prev);
            if (currIndex === aebClosedTimeSlots.length - 1) {
              aebClosedTimeData.push(curr);
            }
            return curr;
          }
        });
      }
      setDailyChartData({ offlineTimeData, onlineTimeData, aebClosedTimeData });
      setDailyChartDate(day);
      setDailyChartLoading(false);
    } catch (error) {
      setDailyChartLoading(false);
    }
  };

  useEffect(() => {
    getAebCloseChartRequest();
    getDailyAebCloseChartRequest(dailyChartDate);
  }, []);

  // 计算时间区间的宽度占比和位置偏移量
  const generateTimeRange = (timeData: any) => {
    const { first, second } = timeData;
    const leftPositionPercent =
      dayjs(first).diff(dayjs(`${dailyChartDate} 00:00:00`), 'second') / 864;
    const rangePercent = dayjs(second).diff(dayjs(first), 'second') / 864;
    return {
      leftPositionPercent,
      rangePercent,
      rangeTime: `${first.split(' ')[1]} - ${second.split(' ')[1]}`
    };
  };

  // 改变日期请求新的数据
  const changeDate = (type: string) => {
    let newDailyChartDate = '';
    if (type === 'prev') {
      newDailyChartDate = formatTime(
        dayjs(dailyChartDate).subtract(1, 'day'),
        'YYYY-MM-DD'
      );
    } else {
      newDailyChartDate = formatTime(
        dayjs(dailyChartDate).add(1, 'day'),
        'YYYY-MM-DD'
      );
    }
    getDailyAebCloseChartRequest(newDailyChartDate);
  };

  const { offlineTimeData, onlineTimeData, aebClosedTimeData } = dailyChartData;
  // 渲染设备状态时间线
  const timeBarData = [
    {
      data: offlineTimeData,
      className: 'offlineTimeBar',
      text: '设备离线时间'
    },
    {
      data: onlineTimeData,
      className: 'onlineTimeBar',
      text: '设备在线时间'
    },
    {
      data: aebClosedTimeData,
      className: 'closeTimeBar',
      text: 'AEB关闭时间'
    }
  ];
  // 某一天图表图例数据
  const dailyChartLegend = [
    {
      color: '#f0f2f5',
      text: '设备离线时间'
    },
    {
      color: '#52c41a',
      text: '设备在线时间'
    },
    {
      color: '#ff4d4f',
      text: 'AEB关闭时间'
    }
  ];
  return (
    <Modal
      title={`${plateNumber} AEB关闭详情`}
      width="80%"
      open
      onCancel={closeModal}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
    >
      <Tabs>
        <Tabs.TabPane tab="全部" key="all">
          <LoadingWrapper spinning={chartLoading}>
            <EchartsWrapper
              chartOption={chartOption}
              style={{ height: '300px' }}
            />
          </LoadingWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="每日" key="daily">
          <LoadingWrapper spinning={dailyChartLoading}>
            <div className={styles.dailyChart}>
              <div className={styles.legendBox}>
                {dailyChartLegend.map((item: any) => (
                  <div className={styles.legendItem} key={item.color}>
                    <div
                      className={styles.colorBox}
                      style={{ background: item.color }}
                    />
                    <div>{item.text}</div>
                  </div>
                ))}
              </div>
              <div className={styles.timeBar}>
                {timeBarData.map((typeData: any) => {
                  const { data, className, text } = typeData;
                  return (data || []).map((item: any) => {
                    const { leftPositionPercent, rangePercent, rangeTime } =
                      generateTimeRange(item);
                    return (
                      <Tooltip title={`${text} ${rangeTime}`} key={item.first}>
                        <div
                          className={styles[className]}
                          style={{
                            position: 'absolute',
                            width: `${rangePercent}%`,
                            left: `${leftPositionPercent}%`
                          }}
                        />
                      </Tooltip>
                    );
                  });
                })}
              </div>
              <div className={styles.timeLine} />
              <div className={styles.timeDotBox}>
                {[
                  '00:00',
                  '02:00',
                  '04:00',
                  '06:00',
                  '08:00',
                  '10:00',
                  '12:00',
                  '14:00',
                  '16:00',
                  '18:00',
                  '20:00',
                  '22:00'
                ].map((item: string) => (
                  <div key={item} className={styles.timeDot}>
                    {item}
                  </div>
                ))}
                <div className={styles.lastTimeDot}>24:00</div>
              </div>
              <div className={styles.switchDate}>
                <Button
                  type="link"
                  disabled={
                    !dayjs(dailyChartDate).diff(dayjs(beginDate), 'day')
                  }
                  onClick={() => changeDate('prev')}
                >
                  <LeftOutlined />
                  上一天
                </Button>
                {dailyChartDate}
                <Button
                  type="link"
                  disabled={!dayjs(endDate).diff(dayjs(dailyChartDate), 'day')}
                  onClick={() => changeDate('next')}
                >
                  下一天
                  <RightOutlined />
                </Button>
              </div>
            </div>
          </LoadingWrapper>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default AebOffInfoModal;
