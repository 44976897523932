/**
 * 渠道设备管理费用
 */
import React, { Fragment, useState } from "react";
import { Card, Form, Row, Col, Button, InputNumber, Descriptions, Input } from 'antd';
import dayjs from 'dayjs';
import { isEmpty, isNil } from "lodash";
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import ChannelSelect from '@/components/channelSelect';
import LoadingWrapper from "@/components/loadingWrapper";
import { operateDayRange } from '@/utils/operateSearchParams';
import { channelManageCostRequest, channelManageCostReportRequestPath } from '@/service/channelManageCost';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const ChannelManageCost = () => {
  const [loading, setLoading] = useState(false); // 是否在请求数据中
  const [data, setData] = useState<any>({}); // 渠道设备管理费用数据
  const [reducer, setReducer] = useState<any>(''); // 用户自定义减速量值
  const [form] = Form.useForm();

  // 获取渠道设备管理费用
  const getData = async(params: any) => { 
    try {
      setLoading(true);
      const res = await channelManageCostRequest(params);
      setData(res.data?.data || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateDayRange(values);
    if (newSearchParams) {
      getData(newSearchParams);
      setReducer('');
    }
  };

  // 获取导出的参数
  const getExportParams = async() => {
    try {
      const values = await form.validateFields();
      values.reducer = reducer;
      return operateDayRange(values);
    } catch (error) {
    }
  }

  // 表格列显示
  const columns: any = [
    {
      title: '费率指标',
      dataIndex: 'cost',
      width: 130,
      onCell: (record: any, rowIndex: number) => {
        if (rowIndex === 0) {
          return { rowSpan: 1 };
        }
        if (rowIndex === 1) {
          return { rowSpan: 6 };
        }
        return { rowSpan: 0 };
      },
    },
    {
      title: '考核指标',
      dataIndex: 'assessment',
      width: 180,
    },
    {
      title: '实际指标值',
      dataIndex: 'value',
      width: 130,
      render: (value: any, record: any, rowIndex: number) => {
        if (rowIndex === 6) {
          return <Input value={reducer} onChange={(e: any) => setReducer(e.target.value)} />
        } else {
          return value;
        }
      }
    },
  ];

  const tableData = [
    { cost: 'HMW指标', assessment: `百公里车间距预警频次不大于${form.getFieldValue('param')}次/百公里/车`, value: data?.hmwIndex },
    { cost: '有效在线指标', assessment: '车辆不在线应小于7天', value: data?.offlineAvg },
    { cost: '', assessment: '车速为0', value: data?.vs0Count },
    { cost: '', assessment: '故障码91', value: data?.e91Count },
    { cost: '', assessment: '故障码31', value: data?.e31Count },
    { cost: '', assessment: '故障码130', value: data?.e130Count  },
    { cost: '', assessment: '减速量 < 0 km/h', value: ''  },
  ]

  const { carCount, hmwIndexRate, onlineRate } = data;

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout='vertical' form={form}>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs().subtract(7, 'day'), dayjs().subtract(1, 'day')]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: any) => currentDate && (currentDate > dayjs().subtract(1, 'days') || currentDate < dayjs('2022-08-01'))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="渠道选择"
              name="cateId"
              rules={[
                { required: true, message: '请选择渠道' }
              ]}
            >
              <ChannelSelect />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="HMW频次阈值" name="param" initialValue={80}>
              <InputNumber placeholder='HMW频次阈值' style={{ width: '100%' }} addonAfter="次" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" ">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">开始统计</Button>
                <ExportButton 
                  url={channelManageCostReportRequestPath}
                  fileName='渠道设备管理费用'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  fileSuffix="docx"
                  permissionKey="/car-admin/channel-manage-cost/export"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading} autoHeight>
        {isEmpty(data) || <Fragment>
          <Descriptions>
            <Descriptions.Item label="渠道车辆总数">{carCount}</Descriptions.Item>
            <Descriptions.Item label="车间距预警达标率">{isNil(hmwIndexRate) || `${hmwIndexRate}%`}</Descriptions.Item>
            <Descriptions.Item label="有效在线率">{isNil(onlineRate) || `${onlineRate}%`}</Descriptions.Item>
          </Descriptions>
          <CommonTable
            columnFilter={false}
            rowKey='assessment'
            columns={columns}
            dataSource={tableData}
            pagination={false}
            bordered
          />
        </Fragment>}
      </LoadingWrapper>
    </Card>
  )
}

export default ChannelManageCost;
