/**
 * 单条收费记录
 */
import { Descriptions, Tag } from "antd";
import { Image } from 'antd';

interface MaintenanceItemProps {
  column: {[key: string]: number};
  data: any;
  type: string;
}
const CostItem = (props: MaintenanceItemProps) => {
  const { column, data } = props;

  // 收费类型
  const renderType = (value:any) => {
    if (value == 1) {
      return '设备维修'
    } else if (value == 2) {
      return '设备拆除'
    } else if (value == 3) {
      return '设备迁移'
    }
  }

  return (
    <Descriptions bordered size="small" column={column}>
      <Descriptions.Item label="操作人员">{data.userName}</Descriptions.Item>
      <Descriptions.Item label="收费金额(元)">{data.cost}</Descriptions.Item>
      <Descriptions.Item label="收费凭证" span={2}>
        <Image  src={data.imageUrl} style={{height:"100px"}} />
      </Descriptions.Item>
      <Descriptions.Item label="收费类型">
        {renderType(data.type)}
      </Descriptions.Item>
      <Descriptions.Item label="维保项">
        {data.items !== null ?eval(data.items).map(((item: any) => <Tag>{item}</Tag>)):<Tag></Tag>}
      </Descriptions.Item>
      <Descriptions.Item label="收费状态">
        {data.flag == true ? <Tag color="success">已确认</Tag> : <Tag color="error">未确认</Tag>}
      </Descriptions.Item>
      <Descriptions.Item label="备注">{data.remarks}</Descriptions.Item>

    </Descriptions>
  )
  
}

export default CostItem;