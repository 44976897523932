// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","realTimeMonitor":"realTimeMonitor_realTimeMonitor__EnQi4","carTreeWrapper":"realTimeMonitor_carTreeWrapper__CQ9SV","monitorContent":"realTimeMonitor_monitorContent__h+UYE","videoMapMonitor":"realTimeMonitor_videoMapMonitor__u9nNd","videoMonitorWrapper":"realTimeMonitor_videoMonitorWrapper__BMpb3","switchVideoLayout":"realTimeMonitor_switchVideoLayout__U-WRF","blockItem":"realTimeMonitor_blockItem__M8Cpo","blockChildrenItem":"realTimeMonitor_blockChildrenItem__yIxh2","videoMonitor":"realTimeMonitor_videoMonitor__HsEUw"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"realTimeMonitor\":\"realTimeMonitor_realTimeMonitor__EnQi4\",\"carTreeWrapper\":\"realTimeMonitor_carTreeWrapper__CQ9SV\",\"monitorContent\":\"realTimeMonitor_monitorContent__h+UYE\",\"videoMapMonitor\":\"realTimeMonitor_videoMapMonitor__u9nNd\",\"videoMonitorWrapper\":\"realTimeMonitor_videoMonitorWrapper__BMpb3\",\"switchVideoLayout\":\"realTimeMonitor_switchVideoLayout__U-WRF\",\"blockItem\":\"realTimeMonitor_blockItem__M8Cpo\",\"blockChildrenItem\":\"realTimeMonitor_blockChildrenItem__yIxh2\",\"videoMonitor\":\"realTimeMonitor_videoMonitor__HsEUw\"}";
        // 1737772155083
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  