/**
 * 疲劳驾驶监控
 */
import React from 'react';
import { Card, Tabs } from 'antd';
import CurrentFatigue from './components/currentFatigue';
import HistoryFatigue from './components/historyFatigue';
import styles from '@/styles/pageContent.module.scss';

const FatigueDrive = () => {
  return (
    <Card bordered={false} className={styles.fixPageTabContentBox}>
      <Tabs>
        <Tabs.TabPane tab="当前疲劳驾驶车辆" key="current">
          <CurrentFatigue />
        </Tabs.TabPane>
        <Tabs.TabPane tab="历史疲劳驾驶车辆" key="history">
          <HistoryFatigue />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default FatigueDrive;
