/**
 * 车队走访
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, message, Popconfirm, Tag, Select } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import Thumbnail from '@/components/thumbnail';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';
import PermissionButton from '@/components/permissionButton';
import OperateModal from './components/operateModal';
import FleetSelect from '@/components/fleetSelect';
import { DeleteCarInterview, carInterviewListRequest, carInterviewListRequestPath } from '@/service/carInterview';
import {cateAllCompanyRequest } from '@/service/fleetRisk';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const CarInterview = () => {
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(30, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: carInterviewListRequest,
    initPageSize: 40,
    searchParams,
  });

  useEffect(() => {
    getCateAllCompanyData()
  }, []);

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    let newSearchParams:any = {}
    if (params.time) {
      newSearchParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD');
      newSearchParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD');
    }
    if (params.name) {
      newSearchParams.name = params.name
    }
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 删除单条
  const deleteCarInterviewItem = async(id: number) => {
    const res = await DeleteCarInterview(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }
  
  // 获取车队
  const getCateAllCompanyData = async () => {
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data 
    const aa:any  = []
    data.forEach((item: any) => {
      aa.push({ label:item.title,value:item.title})
      })
    setAllCompany(aa)
  }


  // 表格列显示
  const columns = [
    {
      title: '车队名称',
      dataIndex: 'name',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '车辆',
      dataIndex: 'carName',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '走访人员',
      dataIndex: 'userNames',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '走访日期',
      dataIndex: 'day',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD') : value,
    },
    {
      title: '走访类别',
      dataIndex: 'typeName',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '走访内容',
      dataIndex: 'content',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '现场照片',
      dataIndex: 'viewResources',
      width: 230,
      ellipsis: { showTitle: false },
      render: (value: any) => (<Thumbnail
        dataSource={value?.slice(0,3) || []}
        downloadImgName="现场照片"
      />),
    },
    {
      title: '车队联系方式',
      dataIndex: 'contactName',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '走访总结',
      dataIndex: 'summarize',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '是否建群',
      dataIndex: 'buildHome',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 100,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton operateType='/car-admin/car-interview/edit' type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
            <Popconfirm
              title="您确定要删除该用户吗?"
              onConfirm={() => deleteCarInterviewItem(item.id)}
            >
              <PermissionButton operateType='/car-admin/car-interview/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical' initialValues={{time:[dayjs(searchParams.beginDate, dateFormat), dayjs(searchParams.endDate, dateFormat)]}}>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              // initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={true} style={{ width: '100%' }}  />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="车队选择"
              name="name">
            <Select
              showSearch
              placeholder="请选择"
              style={{ width: '100%' }}
              options={allCompany}
            />
              {/* <FleetSelect valueField="label" /> */}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox} style={{marginTop:'32px'}}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={carInterviewListRequestPath}
                  fileName='车队走访'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/car-interview/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/car-interview/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default CarInterview;
