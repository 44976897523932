/**
 * 车辆分布
 */
import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Row, Col, Select, Button, message, Switch } from 'antd';
import gcoord from 'gcoord';
import { isEqual } from 'lodash';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import LoadingWarpper from '@/components/loadingWrapper';
import PermissionButton from '@/components/permissionButton';
import OperationGroupSelect from '@/components/operationGroupSelect';
import { drawCar, showCarInfo } from '@/utils/location';
import { importAliMap } from '@/utils/map';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { formSearchGutter } from '@/constants/common';
import { carLocationRequest } from '@/service/carLocation';
import AlarmRangeModal from './components/alarmRangeModal';
import styles from './index.module.scss';
import { cateAllCompanyRequest } from '@/service/fleetRisk';

const CarLocation = () => {
  const [form] = Form.useForm();
  const cateIdArr = useUserCateIdArr();
  const mapDomRef = useRef(null); // 渲染地图的DOM
  const mapRef: any = useRef(null); // 存储地图实例
  const markerClustererRef: any = useRef(null); // 存储聚合点实例
  const selectThresholdRef: any = useRef(10); // 存储选择的阈值
  const selectStatusRef: any = useRef(''); // 存储选择的车辆状态
  const autoRefreshRef: any = useRef(false); // 存储是否自动刷新
  const refreshTimerRef: any = useRef(null); // 定时刷新的定时器
  const [locationData, setLocationData] = useState([]); // 全量车辆位置信息
  const [filterLocationData, setFilterLocationData] = useState<any>([]); // 筛选后的车辆位置信息
  const [selectCategoryId, setSelectCategoryId] = useState(cateIdArr); // 选择的分组
  const [loading, setLoading] = useState(false); // 车辆位置数据请求中
  const [exportModalVisible, setExportModalVisible] = useState(false); // 导出弹窗的显示
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [changeCompnay, setChangeCompany] = useState(true)

  useEffect(() => {
    getCateAllCompanyData()
  }, []);

  const getCateAllCompanyData = async () => {
    // const res = await companyListRequest({page: 1, limit: 20000, name: ''});
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data
    const aa: any = []
    data.forEach((item: any) => {
      // aa.push({label: item.name, value: item.name})
      aa.push({label: item.title, value: item.title})
    })
    setAllCompany(aa)
  }
  // 地图初始化
  const initMap = () => {
    try {
      mapRef.current = new window.AMap.Map(mapDomRef.current, {
        center: [116.368904, 39.913423],
        zoom: 4
      });
      // 加载插件
      window.AMap.plugin(
        ['AMap.ToolBar', 'AMap.Scale', 'AMap.MapType', 'AMap.MarkerCluster'],
        function () {
          mapRef.current.addControl(
            new window.AMap.ToolBar({
              position: {
                bottom: '60px',
                left: '20px'
              }
            })
          );
          mapRef.current.addControl(new window.AMap.Scale());
          mapRef.current.addControl(new window.AMap.MapType());
          getLocationList(cateIdArr);
        }
      );
    } catch (error) {
      message.error('地图初始化失败');
    }
  };

  useEffect(() => {
    importAliMap(initMap);
    return () => {
      refreshTimerRef.current && clearTimeout(refreshTimerRef.current);
    };
  }, []);

  // 是否匹配车辆状态的筛选
  const getIsMatchStatus = (abnormalDeviceLevel: any, currentLocation: any) => {
    switch (selectStatusRef.current) {
      case 'abnormal':
        return abnormalDeviceLevel.abnormal;
      case 'abnormalHigh':
        return abnormalDeviceLevel.highAbnormal;
      case 'abnormalLow':
        return (
          abnormalDeviceLevel.abnormal && !abnormalDeviceLevel.highAbnormal
        );
      case 'online':
        return currentLocation.online;
      case 'offline':
        return !currentLocation.online;
      default:
        return true;
    }
  };

  // 获取车辆位置信息
  const getLocationList = async (
    cateId: Array<string>,
    autoRefresh?: boolean,
    companyName?: string
  ) => {
    try {
      setLoading(true);
      if (autoRefresh) {
        refreshTimerRef.current = setTimeout(
          () => getLocationList(cateId, autoRefresh),
          60000
        );
      }
      const res = await carLocationRequest({ cate_id: cateId, companyName: companyName || '' });
      const data = res.data?.data || [];
      const { filterCarLocation, markerClusterer } = drawCar(
        data,
        mapRef.current,
        selectThresholdRef.current,
        'locationCopyButton',
        getIsMatchStatus
      );
      markerClustererRef.current && markerClustererRef.current.setMap(null);
      markerClustererRef.current = markerClusterer;
      setLocationData(data);
      setFilterLocationData(filterCarLocation);
      setLoading(false);
      setChangeCompany(false)
    } catch (error) {
      setLoading(false);
      setChangeCompany(false)
    }
  };

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId
      });
    }
  };

  // 搜索
  const onSearch = (values: any) => {
    mapRef.current.setZoomAndCenter(4, [116.368904, 39.913423]);
    const { threshold, status, cateId, autoRefresh, companyName } = values;
    let reDraw = false; // 是否需要重新绘制(状态和阈值变化时重新绘制)
    if (threshold !== selectThresholdRef.current) {
      selectThresholdRef.current = threshold;
      reDraw = true;
    }
    if (status !== selectStatusRef.current) {
      selectStatusRef.current = status;
      reDraw = true;
    }
    // 分组有变化或自动刷新有变化时重新请求接口
    if (
      !isEqual(cateId, selectCategoryId) ||
      autoRefreshRef.current !== autoRefresh || changeCompnay
    ) {
      autoRefreshRef.current = autoRefresh;
      refreshTimerRef.current && clearTimeout(refreshTimerRef.current);
      setSelectCategoryId(cateId);
      reDraw = false; // 分组变化会重新请求接口绘制不再需要单独重新绘制
      getLocationList(cateId, autoRefresh, companyName);
    }
    // 需要重新绘制时前端过滤显示车辆图标
    if (reDraw) {
      setLoading(true);
      const { filterCarLocation, markerClusterer } = drawCar(
        locationData,
        mapRef.current,
        selectThresholdRef.current,
        'locationCopyButton',
        getIsMatchStatus
      );
      markerClustererRef.current.setMap(null);
      markerClustererRef.current = markerClusterer;
      setFilterLocationData(filterCarLocation);
      setLoading(false);
    }
  };

  // 自动刷新改变
  const autoRefreshChange = (checked: boolean) => {
    if (checked) {
      // 开启自动刷新时请求接口重新获取数据
      getLocationList(selectCategoryId, true);
    } else {
      // 关闭自动刷新时清除之前的定时请求
      refreshTimerRef.current && clearTimeout(refreshTimerRef.current);
    }
  };

  // 快速定位
  const onLocation = (values: any) => {
    const trimPlateNumber = (values.plateNumber || '').trim();
    if (trimPlateNumber) {
      let carLnglat = null; // 车辆位置
      let carInfo = null; // 车辆信息
      for (let i = 0; i < locationData.length; i++) {
        const currentLocationData: any = locationData[i];
        // 转换坐标系
        const { lat, lon, plate_number } = currentLocationData;
        if (lat && lon) {
          if (plate_number === trimPlateNumber) {
            carLnglat = gcoord.transform([lat, lon], gcoord.WGS84, gcoord.AMap);
            carInfo = currentLocationData;
            break;
          }
        }
      }
      // 地图中心移动到搜索的车辆
      if (carLnglat) {
        mapRef.current.setZoom(16, true);
        mapRef.current.setCenter(carLnglat, true);
        showCarInfo(
          carInfo,
          new window.AMap.LngLat(carLnglat[0], carLnglat[1]),
          mapRef.current,
          selectThresholdRef.current,
          'locationCopyButton'
        );
      } else {
        message.info('未查找到对应车牌的车辆位置');
      }
    } else {
      message.warn('请输入车牌号');
    }
  };

  return (
    <div className={styles.carLocation}>
      <div className={styles.topBox}>
        <div className={styles.tips}>
          点开聚合点查看车辆详情：离线（黑）；在线（绿），设备故障次数：小于分级阈值（黄）；大于阈值（红）
        </div>
        <Form onFinish={onSearch} form={form}>
          <Row gutter={formSearchGutter}>
            <OperationGroupSelect
              onChange={groupChange}
              operationGroupPermissionKey="/car-admin/car-location/show-operation-group"
              span={3}
            />
            <Col span={4}>
              <Form.Item
                label="车队选择"
                name="companyName"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="请选择"
                  style={{ width: '100%' }}
                  options={allCompany}
                  onChange={() => {setChangeCompany(true)}}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="分组选择"
                name="cateId"
                initialValue={cateIdArr}
                rules={[{ required: true, message: '请选择分组' }]}
              >
                <CategoryTreeSelect treeCheckable />
              </Form.Item>
            </Col>
            
            <Col span={4}>
              <Form.Item
                label="故障码次数分级阈值"
                name="threshold"
                initialValue={10}
              >
                <Select>
                  <Select.Option value={10}>10</Select.Option>
                  <Select.Option value={20}>20</Select.Option>
                  <Select.Option value={50}>50</Select.Option>
                  <Select.Option value={100}>100</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="车辆状态" name="status">
                <Select allowClear>
                  {/* <Select.Option value="abnormal">故障车</Select.Option> */}
                  <Select.Option value="abnormalHigh">
                    大于故障码分级阈值
                  </Select.Option>
                  <Select.Option value="abnormalLow">
                    小于故障码分级阈值
                  </Select.Option>
                  <Select.Option value="online">在线</Select.Option>
                  <Select.Option value="offline">离线</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label="自动刷新"
                name="autoRefresh"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch onChange={autoRefreshChange} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <div className={styles.searchButtonBox}>
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                  <PermissionButton
                    operateType="/car-admin/car-location/export"
                    onClick={() => setExportModalVisible(true)}
                  >
                    导出车辆数据
                  </PermissionButton>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form onFinish={onLocation}>
          <Row gutter={formSearchGutter}>
            <Col span={6}>
              <Form.Item label="车牌号" name="plateNumber">
                <Input
                  type="text"
                  placeholder="请输入车牌号"
                  autoComplete="off"
                  allowClear
                  onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  快速定位
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <LoadingWarpper spinning={loading} autoHeight>
        <div ref={mapDomRef} className={styles.carLocationMap} />
      </LoadingWarpper>
      {exportModalVisible && (
        <AlarmRangeModal
          closeModal={() => setExportModalVisible(false)}
          locationData={filterLocationData}
          mapRef={mapRef}
        />
      )}
    </div>
  );
};

export default CarLocation;
