/**
 * 批量车辆分布查询
 */
import React, {useEffect, useRef, useState} from 'react';
import {Button, Row, Col, message} from 'antd';
import PermissionButton from '@/components/permissionButton';
import LoadingWarpper from '@/components/loadingWrapper';
import OperationGroupSelect from '@/components/operationGroupSelect';
import UploadExcel from '@/components/uploadExcel';
import AlarmRangeModal from '@/pages/carLocation/components/alarmRangeModal';
import {importAliMap} from '@/utils/map';
import {getInnerPermissionEnable} from '@/utils/permission';
import {drawCar} from '@/utils/location';
import {formSearchGutter} from '@/constants/common';
import {carListRequest} from '@/service/carManage';
import {carLocationQueryRequestPath} from '@/service/carLocation';
import styles from './index.module.scss';
import CategoryTreeSelect from "@/components/categoryTreeSelect";

const CarLocationQuery = () => {
  const mapDomRef = useRef(null); // 渲染地图的DOM
  const mapRef: any = useRef(null); // 地图实例
  const markerClustererRef: any = useRef(null); // 聚合点实例
  const [locationData, setLocationData] = useState([]); // 车辆位置信息
  const [loading, setLoading] = useState(false); // 车辆位置数据是否请求中
  const [cateIds, setCateIds] = useState<any[]>([]); // 选择运维组对应的分组数据
  const [exportModalVisible, setExportModalVisible] = useState(false); // 导出弹窗的显示

  // 地图初始化
  const initMap = () => {
    try {
      mapRef.current = new window.AMap.Map(mapDomRef.current, {
        center: [116.368904, 39.913423],
        zoom: 4
      });
      // 加载插件
      window.AMap.plugin(
        ['AMap.ToolBar', 'AMap.Scale', 'AMap.MapType', 'AMap.MarkerCluster'],
        function () {
          mapRef.current.addControl(
            new window.AMap.ToolBar({
              position: {
                bottom: '60px',
                left: '20px'
              }
            })
          );
          mapRef.current.addControl(new window.AMap.Scale());
          mapRef.current.addControl(new window.AMap.MapType());
        }
      );
    } catch (error) {
      message.error('地图初始化失败');
    }
  };

  useEffect(() => {
    importAliMap(initMap);
  }, []);

  // 运维组选择
  const groupChange = (value: any) => {
    if (value) {
      setCateIds([value]);
    } else {
      setCateIds([]);
    }
  };

  // 根据选择的运维组搜索
  const onSearch = async () => {
    if (cateIds?.length) {
      try {
        setLoading(true);
        const res = await carListRequest({
          page: 1,
          limit: 100000,
          cate_id: cateIds
        });
        const data = res?.data?.data || [];
        if (markerClustererRef.current) {
          markerClustererRef.current.setMap(null);
        }
        setLocationData(data);
        const {markerClusterer} = drawCar(
          data,
          mapRef.current,
          10,
          'locationQueryCopyButton'
        );
        markerClustererRef.current = markerClusterer;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      if (markerClustererRef.current) {
        markerClustererRef.current.setMap(null);
      }
      setLocationData([]);
    }
  };

  // 上传成功
  const uploadSuccess = (resData: any) => {
    message.success('上传成功');
    if (markerClustererRef.current) {
      markerClustererRef.current.setMap(null);
    }
    setLocationData(resData || []);
    const {markerClusterer} = drawCar(
      resData || [],
      mapRef.current,
      10,
      'locationQueryCopyButton'
    );
    markerClustererRef.current = markerClusterer;
  };

  return (
    <div className={styles.carLocationQuery}>
      <Row gutter={formSearchGutter} wrap className={styles.exportButtonBox}>
        <Col xl={24} xxl={16}>
          <span className={styles.tips}>
            通过上传excel文件（设备SN必填）显示文件中车辆的位置分布
          </span>
          <Button href="/car_location_example.xlsx">
            下载导入示例文件
          </Button>
          <UploadExcel
            uploadRequestPath={carLocationQueryRequestPath}
            uploadButtonProps={{children: '上传车辆excel文件'}}
            loadingChangeFunc={setLoading}
            uploadSuccessFunc={(resData: any) => uploadSuccess(resData)}
          />
          <PermissionButton
            operateType="/car-admin/car-location-query/export"
            type="primary"
            onClick={() => setExportModalVisible(true)}
          >
            导出车辆数据
          </PermissionButton>
        </Col>
        {getInnerPermissionEnable(
          '/car-admin/car-location-query/show-operation-group'
        ) && (
          <Col
            xl={24}
            xxl={8}
            style={{display: 'flex', alignItems: 'center'}}
          >
            {/*<OperationGroupSelect*/}
            {/*  onChange={groupChange}*/}
            {/*  operationGroupPermissionKey="/car-admin/car-location-query/show-operation-group"*/}
            {/*  inForm={false}*/}
            {/*/>*/}
            <CategoryTreeSelect onChange={groupChange}/>
            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
          </Col>
        )}
      </Row>
      <LoadingWarpper spinning={loading} autoHeight>
        <div ref={mapDomRef} className={styles.carLocationMap}/>
      </LoadingWarpper>
      {exportModalVisible && (
        <AlarmRangeModal
          closeModal={() => setExportModalVisible(false)}
          locationData={locationData}
          mapRef={mapRef}
        />
      )}
    </div>
  );
};

export default CarLocationQuery;
