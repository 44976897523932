/**
 * 图表弹窗
 */
import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { cloneDeep } from 'lodash';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
interface ChartModalProps {
  data: any; // 数据
  dayColumn: Array<string>
  tableFormValue: any; // 表格内表单的数据
  closeModal: () => void; // 关闭弹窗的方法
  errTypeData: any; // 故障项数据
}
const ChartModal = (props: ChartModalProps) => {
  const { data, dayColumn, tableFormValue, closeModal, errTypeData } = props;
  useEffect(() => {
    const xData = cloneDeep(dayColumn);
    const series: any = [];
    data.forEach((item: any, index: number) => {
      series.push({
        name: index === data.length - 1 ? item.errType : `${item.errType}`.replace(')', `: ${tableFormValue[index].count})`),
        type: 'line',
        data: xData.map((item1: string) => item[item1]?.length || 0)
      })
    });
    const legendData = errTypeData.map((item: any, index: number) => `${item.label}`.replace(')', `: ${tableFormValue[index].count})`));
    legendData.push('车辆合计数');
    const chartConfig = {
      tooltip: { trigger: 'axis' },
      legend: { data: legendData },
      toolbox: {
        feature: { saveAsImage: { title: '保存为图' } }
      },
      xAxis: { type: 'category', data: xData },
      yAxis: { type: 'value' },
      series,
    };
    const alarmCompareChart = echarts.init(document.getElementById('abnormalSummaryChart') as HTMLElement);
    alarmCompareChart.setOption(chartConfig, true);
  }, []);

  return (
    <Modal
      title="图表趋势"
      visible
      footer={null}
      onCancel={closeModal}
      width='70%'
      centered
    >
      <div id="abnormalSummaryChart" style={{ height: '400px' }} />
    </Modal>
  )
}

export default ChartModal;
    