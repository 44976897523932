/**
 * loading
 */
import React from 'react';
import { Spin, SpinProps } from 'antd';
import { omit } from 'lodash';
import styles from './index.module.scss';

interface LoadingWrapperProps extends SpinProps {
  autoHeight?: boolean; // loading的内容区域高度不固定(不固定时Spin组件外层加容器)
  style?: any // loading外部容器的自定义样式
  id?: string; // DOM的id
}
const LoadingWrapper = (props: LoadingWrapperProps) => {
  const { autoHeight, style, id } = props;
  const spinOwnProps = omit(props, ['autoHeight', 'style', 'id']);
  if (autoHeight) {
    return (
      <div className={styles.loadingWraaper} style={style || {}} id={id}>
        <Spin {...spinOwnProps} />
      </div>
    );
  } else {
    return (
      <Spin {...spinOwnProps} />
    )
  }
}

export default LoadingWrapper;
   