/**
 * 部标机设备历史视频下载
 */
import React, { useState, useRef } from 'react';
import { Form, Row, Col, Button } from 'antd';
import DatePicker from '@/components/datePicker';
import VideoPreviewModal from '@/components/videoPreviewModal';
import { formSearchGutter } from '@/constants/common';
import JtDeviceResourceTable from '@/pages/jtDevicePlayback/components/jtDeviceResourceTable';
import FtpResourceTable from '@/pages/jtDevicePlayback/components/ftpResourceTable';

const { RangePicker } = DatePicker;

interface JtDeviceDownloadProps {
  carDetail: any;
}
const JtDeviceDownload = (props: JtDeviceDownloadProps) => {
  const {
    carDetail: { terminalPhone, jt808DeviceId, channelNoList, plateNumber }
  } = props;
  const [form] = Form.useForm();
  const ftpResourceTableRef: any = useRef(null); // ftp服务器资源列表实例
  const [jtDeviceResourceRequestParams, setJtDeviceResourceRequestParams] =
    useState<any>([]); // 部标机设备资源列表请求参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的信息存储

  // 设置获取部标机设备资源列表的请求参数
  const onSearch = async (values: any) => {
    const allRequestParams = channelNoList.map((channel: any) => ({
      channelNo: channel,
      codeStream: 0,
      time: values.time
    }));
    setJtDeviceResourceRequestParams(allRequestParams);
  };

  // 重新获取ftp服务器视频列表
  const ftpResourceTableReload = () => {
    ftpResourceTableRef?.current?.reload();
  };

  return (
    <div>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={formSearchGutter} wrap>
          <Col span={16}>
            <Form.Item
              label="时间范围"
              name="time"
              rules={[{ required: true, message: '请选择时间范围' }]}
            >
              <RangePicker style={{ width: '100%' }} showTime />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ marginBottom: '12px' }}>视频设备资源列表</div>
      <JtDeviceResourceTable
        selectJtDeviceInfo={{ phone: terminalPhone, jt808DeviceId }}
        requestParams={jtDeviceResourceRequestParams}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '12px 0'
        }}
      >
        <div>已上传到服务器的视频</div>
        <Button onClick={ftpResourceTableReload}>刷新列表</Button>
      </div>
      <FtpResourceTable
        plateNumber={plateNumber}
        playFtpVideo={(url: string) =>{console.log(23234242341), setOperateInfo({ type: 'play', data: url })}
          
        }
        ref={ftpResourceTableRef}
        downloadPermissionKey="/car-admin/drive-analysis/history-video-download"
        jt808DeviceId={jt808DeviceId}
      />
      {/* 视频播放 */}
      {operateInfo.type === 'play' && (
        <VideoPreviewModal
          url={operateInfo.data}
          closeModal={() => setOperateInfo({})}
          showDownload={false}
        />
      )}
    </div>
  );
};

export default JtDeviceDownload;
