/**
 * 操作维保记录
 */
import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Input, Row, Col, Checkbox, message, Upload, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import { malfunctionsData, maintenancesData } from "@/constants/carDiagnosis";
import { chinaData } from "@/constants/chinaData";
import { TagCategoryEnum } from '@/constants/tagManage';
import localStorageUtil from '@/utils/localStorageUtil';
import { useNotFirstRenderEffect } from '@/utils/ownHooks';
import { tagListRequest } from '@/service/tagManage';
import { addMaintenancesRecord } from '@/service/carDiagnosis';
import UploadImageVideo from "@/components/uploadImageVideo";
import styles from './index.module.scss';

interface OperateMaintenanceProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 添加成功后重新请求维保记录的方法
}

const OperateMaintenance = (props: OperateMaintenanceProps) => {
  const { carInfo, onClose, reloadData } = props;
  const [provinceData] = useState<any>(chinaData['86']); // 维保地点的省份选择数据源
  const [cityData, setCityData] = useState<any>(chinaData['110000']); // 维保地点的城市选择数据源
  const [districtData, setDistrictData] = useState<any>(chinaData['110100']); // 维保地点的区域选择数据源
  const [malfunctionsRequired, setMalfunctionsRequired] = useState<boolean>(true); // 故障项的必选校验
  const [maintenancesRequired, setMaintenancesRequired] = useState<boolean>(true); // 维护明细的必选校验
  const [loading, setLoading] = useState<boolean>(false); // 接口是否请求中
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [tagData, setTagData] = useState<any>([]); // 维保相关的标签
  const [serviceProblemType, setServiceProblemType] = useState<any>([]); // 问题来源的标签
  const [sceneProblemType, setSceneProblemType] = useState<any>([]); // 现场问题原因
  const [malfunctionItem, setMalfunctionItem] = useState<any>([]); // 故障项
  const [maintainDetailItem, setMaintainDetailItem] = useState<any>([]); // 维护明细
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [contractUploadLoading, setContractUploadLoading] = useState<boolean>(false); // 压缩包是否上传中
  const [uploadSuccessZipList, setUploadSuccessZipList] = useState<any>([]); // 上传压缩包成功的文件信息

  // 登录用户信息
  const userDetail = localStorageUtil.getItem('userDetail');

  // 工单类别数据
  const workOrderTypeData = [
    { label: '故障运维', value: 1 },
    { label: '安装运维', value: 2 },
    { label: '拆除运维', value: 3 },
    { label: '专项走访', value: 6 },
    { label: '其它', value: 5 },
    { label: '设备关停', value: 7 },
  ]

  // 初始赋值
  useEffect(() => {
    getServiceProblemType()
    getSceneProblemType()
    getMalfunctionItem()
    getMaintainDetailItem()
    form.setFieldsValue({
      contactName: carInfo.car_principal,
      contactPhone: carInfo.car_principal_phone,
      maintainer: userDetail.nickname,
      maintainerPhone: userDetail.mobile,
    });
    getMaintenanceTags();
  }, [])

  // 处理故障项和维护明细的校验
  useNotFirstRenderEffect(() => form.validateFields(['malfunctions']), [malfunctionsRequired]);
  useNotFirstRenderEffect(() => form.validateFields(['maintenances']), [maintenancesRequired]);

  // 获取维保标签
  const getMaintenanceTags = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.maintainType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title, id, isDisabled } = item;
      tags.push({ label: title, value: id, disabled: isDisabled });
    });
    setTagData(tags);
  }

  // 表单操作时的处理
  const onFormChange = (changedValues: any) => {
    // 修改客户联系人的类型时赋值对应的数据
    if (changedValues.contactType) {
      const contactType = changedValues.contactType;
      form.setFieldsValue({
        contactName: contactType === 1 ? carInfo.car_principal : carInfo.car_driver,
        contactPhone: contactType === 1 ? carInfo.car_principal_phone : carInfo.car_driver_phone,
      });
    }

    // 故障项有输入其它值时不再校验checkBox的选择
    if (changedValues.hasOwnProperty('malfunctionsOthers')) {
      setMalfunctionsRequired(!changedValues.malfunctionsOthers);
    }

    // 维护明细有输入其它值时不再校验checkBox的选择
    if (changedValues.hasOwnProperty('maintenancesOthers')) {
      setMaintenancesRequired(!changedValues.maintenancesOthers);
    }
    
    // 选择省份(重新设置城市数据)
    if (changedValues.hasOwnProperty('province')) {
      const newCityData = chinaData[changedValues.province];
      setCityData(newCityData);
      const firstCity = Object.keys(newCityData)[0];
      const newDistrictData = chinaData[firstCity];
      setDistrictData(newDistrictData);
      form.setFieldsValue({
        city: firstCity,
        district: Object.keys(newDistrictData)[0],
      });
    }

    // 选择城市(重新设置区域数据)
    if (changedValues.hasOwnProperty('city')) {
      const newDistrictData = chinaData[changedValues.city];
      setDistrictData(newDistrictData);
      form.setFieldsValue({
        district: Object.keys(newDistrictData)[0],
      });
    }
  }

  // 提交维保记录
  const submit = async (values: any) => {
    // console.log('value',values)
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      try {
        const {
          province, city, district, addressDetail, contactType, contactName, contactPhone,
          maintainer, maintainerPhone, malfunctions, malfunctionsOthers, maintenances, maintenancesOthers, tagIds,
          type, category, problemType, maintainers
        } = values;
        const maintainersList:any = []
        maintainers?.map((item:any) => {
          maintainersList.push(item.name)
        })
        // 拼接完整地址信息
        const provinceLabel = chinaData['86'][province];
        const cityLabel = chinaData[province][city];
        const districtLabel = chinaData[city][district];
        const address = `${provinceLabel}${cityLabel}${districtLabel}${addressDetail}`;
        // 处理故障项和维护明细的数据
        const newMalfunctions = malfunctions ? [...malfunctions] : [];
        if (malfunctionsOthers) {
          newMalfunctions.push(malfunctionsOthers);
        }
        const newMaintenances = maintenances ? [...maintenances] : [];
        if (maintenancesOthers) {
          newMaintenances.push(maintenancesOthers);
        }
        const params: any = {
          carId: carInfo.id,
          contactType,
          contactName,
          contactPhone,
          maintainer,
          maintainerPhone,
          address,
          malfunctions: newMalfunctions,
          maintenances: newMaintenances,
          tagIds,
          type,
          category,
          problemType,
          maintainers: maintainersList
        }
        if (uploadSuccessList.length) {
          // 过滤掉上传失败的资源
          const filteredResources = uploadSuccessList.filter((resource:any) => resource.key !== null);
          params.resources = filteredResources;
        }
        if (uploadSuccessZipList.length) {
          params.zipResources = uploadSuccessZipList;
        }
        setLoading(true);
        const res = await addMaintenancesRecord(params);
        if (res.status == 200) {
          setSubmitNoRepetition(true)
        }
        setLoading(false);
        if (res.data?.code === 0) {
          message.success('添加成功');
          onClose();
          reloadData();
        }
      } catch (error) {
        console.log(error)
        setLoading(false);
      }
    }
  }

  const provinceKeysData = Object.keys(provinceData);
  const cityKeysData = Object.keys(cityData);
  const districtKeysData = Object.keys(districtData);

  // 获取问题来源
  const getServiceProblemType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.serviceProblemType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setServiceProblemType(tags);
  }

  // 获取现场问题原因
  const getSceneProblemType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.sceneProblemType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setSceneProblemType(tags);
  }

  // 获取故障项
  const getMalfunctionItem = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.malfunctionItem],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push(title);
    });
    setMalfunctionItem(tags);
  }

  // 获取维护明细
  const getMaintainDetailItem = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.maintainDetailItem],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push(title);
    });
    setMaintainDetailItem(tags);
  }

  // 上传压缩包
  const onContractUploadChange = async (info: any) => {
    const zipList = [...uploadSuccessZipList]
    if (info.file.status === 'uploading') {
      setContractUploadLoading(true);
    }
    if (info.file.status === 'error') {
      setContractUploadLoading(false);
      message.error('上传失败');
    }
    if (info.file.status === 'done') {
      setContractUploadLoading(false);
      zipList.push(info.file.response.data)
      setUploadSuccessZipList(zipList)
    }
  }

  // 删除已上传的文件
  const deleteZipItem = (file:any) => {
    const deleteZip:any = []
    uploadSuccessZipList.map((item:any) => {
      if (item.preview == file.response.data.preview) {
        setUploadSuccessZipList(deleteZip)
      } else {
        deleteZip.push(item)
        setUploadSuccessZipList(deleteZip)
      }
    })
  }

  return (
    <Modal
      title="新增维保记录"
      visible
      width={800}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onValuesChange={onFormChange}
        scrollToFirstError
        onFinish={submit}
      >
        <Row gutter={4} wrap>
          <Col sm={6} xs={24}>
            <Form.Item label="工单类型" name="type" rules={[{ required: true, message: '请选择工单类型' }]}>
              <Select placeholder="请选择工单类型" options={workOrderTypeData} allowClear showArrow showSearch optionFilterProp="label"  />
            </Form.Item>
          </Col>
          <Col sm={6} xs={24}>
            <Form.Item label="问题来源" name="category" rules={[{ required: true, message: '请选择问题来源' }]}>
              <Select placeholder="请选择问题来源" options={serviceProblemType} allowClear showArrow showSearch optionFilterProp="label"  />
            </Form.Item>
          </Col>
          <Col sm={6} xs={24}>
            <Form.Item label="运维问题" name="tagIds"  rules={[{ required: true, message: '请选择运维问题' }]}>
              <Select placeholder="请选择运维问题" options={tagData} mode="multiple"  allowClear showArrow showSearch optionFilterProp="label"/>
            </Form.Item>
          </Col>
          <Col sm={6} xs={24}>
            <Form.Item label="问题原因" name="problemType" rules={[{ required: true, message: '请选择问题原因' }]}>
              <Select placeholder="请选择问题原因" options={sceneProblemType} allowClear showArrow showSearch optionFilterProp="label"  />
            </Form.Item>
          </Col>
        </Row>
        {/* 维保地点 */}
        <Form.Item label="维保实际地点" required>
          <Row wrap>
            <Col sm={6} xs={8}>
              <Form.Item name="province" noStyle initialValue={provinceKeysData[0]}>
                <Select>
                  {provinceKeysData.map((item: string) => (
                    <Select.Option value={item} key={item}>{provinceData[item]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={6} xs={8}>
              <Form.Item name="city" noStyle initialValue={cityKeysData[0]}>
                <Select>
                  {cityKeysData.map((item: string) => (
                    <Select.Option value={item} key={item}>{cityData[item]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={6} xs={8}>
              <Form.Item name="district" noStyle initialValue={districtKeysData[0]}>
                <Select>
                  {districtKeysData.map((item: string) => (
                    <Select.Option value={item} key={item}>{districtData[item]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={6} xs={24}>
              <Form.Item
                name="addressDetail"
                noStyle
                rules={[{ required: true, message: '请输入详细地址' }]}
              >
                <Input placeholder="详细地址" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        {/* 客户信息 */}
        <Row gutter={4} wrap>
          <Col sm={12} xs={24}>
            <Form.Item label="客户联系人">
              <Row>
                <Col span={12}>
                  <Form.Item name="contactType" noStyle initialValue={1}>
                    <Select>
                      <Select.Option value={1}>负责人</Select.Option>
                      <Select.Option value={2}>司机</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="contactName" noStyle>
                    <Input placeholder="联系人姓名" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="客户联系方式" name="contactPhone">
              <Input placeholder="联系人电话" />
            </Form.Item>
          </Col>
        </Row>
        {/* 维保员信息 */}
        <Row gutter={4} wrap>
          <Col sm={12} xs={24}>
            <Form.Item label="维保员" name="maintainer">
              <Input placeholder="维保员" disabled />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="维保员联系方式" name="maintainerPhone">
              <Input placeholder="维保员联系方式" />
            </Form.Item>
          </Col>
        </Row>
        {/* 故障项 */}
        <Form.Item
          label="故障项（多选）"
          required
          className={styles.checkBoxGroup}
        >
          <Form.Item
            name="malfunctions"
            noStyle
            rules={[{ required: malfunctionsRequired, message: '请选择故障项' }]}
          >
            <Checkbox.Group>
              <Row gutter={12} wrap>
                {malfunctionItem.map((item: any) => (
                  <Col span={8}>
                    <Checkbox value={item}>
                      {item}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="malfunctionsOthers" noStyle>
            <Input placeholder="可输入其它故障项" />
          </Form.Item>
        </Form.Item>
        {/* 维护明细 */}
        <Form.Item label="维护明细（多选）" required className={styles.checkBoxGroup}>
          <Form.Item
            name="maintenances"
            noStyle
            rules={[{ required: maintenancesRequired, message: '请选择维护明细' }]}
          >
            <Checkbox.Group>
              <Row gutter={12} wrap>
                {maintainDetailItem.map((item: any) => (
                  <Col span={8}>
                    <Checkbox value={item}>
                      {item}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="maintenancesOthers" noStyle>
            <Input placeholder="可输入其它维护明细" />
          </Form.Item>
        </Form.Item>
        <Form.List name="maintainers">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                  >
                    <Input placeholder="请输入维保人员" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>添加维保人员</Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item label="上传图片/视频">
          <UploadImageVideo
            businessName="维保"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            canUploadVideo
            limitSize={500}
            multiple
          />
        </Form.Item>
        <Form.Item label="上传压缩包">
          <Upload
            action="/api/v2/resource/upload"
            headers={{ access_token: localStorageUtil.getItem('access_token') }}
            onChange={onContractUploadChange}
            className={styles.uploadButton}
            multiple
            onRemove={deleteZipItem}
          >
            <Button loading={contractUploadLoading} icon={<UploadOutlined />}>上传压缩包</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateMaintenance;