/**
 * 视频评价详情
 */
import React, { useState, useEffect, Fragment } from 'react';
import { Tag, Timeline, Rate, Popconfirm, message } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getVideoCommentRequest, deleteVideoCommentRequest } from '@/service/driveVideo';
import { getInnerPermissionEnable } from '@/utils/permission';
import PermissionButton from '@/components/permissionButton';
import CommentVideoModal from '../commentVideoModal';
import styles from './index.module.scss';

interface VideoPreviewModalProps {
  resourceKey: string; // 获取视频评论的key
  refreshFlag?: number; // 是否重新获取视频评论的标识
  reloadData?: Function; // 重新获取视频列表的方法
}
const CommentVideoDetail = (props: VideoPreviewModalProps) => {
  const { resourceKey, refreshFlag, reloadData } = props;
  const { userDetail } = useSelector((state: any) => state.userStore);
  const [videoTags, setVideoTags] = useState<any>([]); // 视频的标签
  const [videoComment, setVideoComment] = useState<any>([]); // 视频评论内容
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息存储

  // 获取视频的评论
  const getVideoComment = async() => {
    const res = await getVideoCommentRequest({ resourceKey });
    const tags = res.data?.data?.tags || {};
    const tagsData = Object.values(tags).flat(Infinity);
    setVideoComment(res.data?.data?.comments || []);
    setVideoTags(tagsData);
  }

  useEffect(() => {
    getVideoComment();
  }, [refreshFlag])

  // 删除评论
  const deleteComment = async(data: any) => {
    const { id, userId, resourceKey, updatedAt } = data;
    const res = await deleteVideoCommentRequest({
      id,
      optUser: userId,
      resourceKey,
      updatedAt: updatedAt ? dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss') : null,

    });
    if (res.data?.code === 0) {
      message.success('删除成功');
      getVideoComment();
      reloadData && reloadData();
    }
  }
  
  return (
    <div>
      <div className={styles.tags}>
        {videoTags.map((item: any) => <Tag key={item.tagId}>{`${item.title}(${item.count})`}</Tag>)}
      </div>
      {Boolean(videoComment.length) && <Timeline>
        {videoComment.map((item: any) => {
          const parseComment = JSON.parse(item.comment);
          return (
            <Timeline.Item key={item.id}>
              <div className={styles.commentTitle}>
                <div>{item.userName}</div>
                <div>{dayjs(item.updatedAt || item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                <Rate disabled value={parseComment.rate} />
                {(getInnerPermissionEnable('/car-admin/drive-video/videoCommentEditSuper') || userDetail.id === item.userId) && <Fragment>
                  <PermissionButton
                    operateType='/car-admin/drive-video/videoCommentEdit'
                    type="link"
                    style={{ padding: '0 0 0 6px', height: '26px' }}
                    onClick={() => setOperateInfo({ type: 'editComment', data: item })}
                  >
                    <EditOutlined />
                  </PermissionButton>
                  <Popconfirm
                    title="此操作将删除该条评价, 是否继续?"
                    onConfirm={() => deleteComment(item)}
                  >
                    <PermissionButton
                      operateType='/car-admin/drive-video/videoCommentDelete'
                      type="link"
                      style={{ padding: '0 0 0 6px', height: '26px' }}
                    >
                      <DeleteOutlined style={{ color: 'red' }} />
                    </PermissionButton>
                  </Popconfirm>
                </Fragment> }
              </div>
              <div>{parseComment.comment}</div>
            </Timeline.Item>
          )
        })}
      </Timeline>}
      {/* 视频评价的弹窗 */}
      {operateInfo.type === 'editComment' && <CommentVideoModal
        closeModal={() => setOperateInfo({})}
        resourceKey={operateInfo.data?.resourceKey}
        reloadData={() => {
          getVideoComment();
          reloadData && reloadData();
        }}
        editDetail={operateInfo.data}
      />}
    </div>
  )
}

export default CommentVideoDetail;
    