/**
 * 记录仪配置弹窗
 */
import React from 'react';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import { recorderFormConfig } from '@/constants/parameterConfiguration';
import {
  getRecorderParamsRequest,
  setRecorderParamsRequest
} from '@/service/parameterConfiguration';

interface RecorderModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const RecorderModal = (props: RecorderModalProps) => {
  const { data, closeModal } = props;

  return (
    <ParameterConfigurationModal
      operateParameterValue={data.sn}
      operateParameterField="sn"
      getParameterRequest={getRecorderParamsRequest}
      setParameterRequest={setRecorderParamsRequest}
      formConfig={recorderFormConfig}
      closeModal={closeModal}
      modalTitle="记录仪配置"
      modalWidth={520}
      configKey="/car-admin/parameter-configuration/record-configuration-config"
      approveKey="/car-admin/parameter-configuration/record-configuration-config-approve"
      approveType="recorderConfig"
      labelColSpan={8}
      wrapperColSpan={16}
      deviceType={data.deviceType}
    />
  );
};

export default RecorderModal;
