/**
 * 客服记录维护
 */
import React, { useState } from "react";
import { Card, Form, Row, Col, Input, Radio, Button, Popconfirm, message } from 'antd';
import CommonTable from "@/components/commonTable";
import ExportButton from "@/components/exportButton";
import PermissionButton from "@/components/permissionButton";
import MaintainModal from './components/maintainModal';
import MatchCarModal from './components/matchCarModal';
import { useTable } from "@/utils/useTable";
import { callRecordRequest, callRecordRequestPath, updateCallRecordRequest, CallRecordStatusEnum } from '@/service/callRecord';
import styles from '@/styles/pageContent.module.scss';

const CallRecord = () => {
  const [searchParams, setSearchParams] = useState({
    isMaintained: undefined,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: callRecordRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
    frontEndPageChange: true,
  });

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams = {...params};
    newSearchParams.agentNickName = [(params.agentNickName || '').trim()];
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    setSearchParams(operateSearchParams(values));
  };

  // 获取导出的参数
  const getExportParams = () => {
    const params = form.getFieldsValue();
    return operateSearchParams(params, true);
  }

  // 忽略通话记录
  const ignoreCallRecord = async(id: number) => {
    const res = await updateCallRecordRequest({
      id,
      status: CallRecordStatusEnum.ignore
    });
    if (res.data?.code === 0) {
      message.success('忽略成功');
      getData();
    }
  }

  const columns = [
    {
      title: '通话时间',
      dataIndex: 'callStartAt',
      width: 170,
    },
    {
      title: '客服名称',
      dataIndex: 'agentNickName',
      width: 90,
    },
    {
      title: '客户电话',
      dataIndex: 'callNumber',
      width: 120,
    },
    {
      title: '呼叫类型',
      dataIndex: 'callType',
      width: 90,
    },
    {
      title: '呼叫结果',
      dataIndex: 'callResult',
      width: 90,
    },
    {
      title: '通话时长',
      dataIndex: 'callTime',
      width: 90,
      render: (value: number) => `${value}秒`
    },
    {
      title: '通话记录',
      dataIndex: 'recordUrl',
      width: 300,
      render: (value: string) => value ? <audio controls src={value}></audio> : '无'
    },
    {
      title: '是否已维护',
      dataIndex: 'status',
      width: 110,
      render: (value: number) => value === CallRecordStatusEnum.maintained ? '是' : '否'
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/call-record/maintain'
              type="link"
              onClick={() => setOperateInfo({ type: 'maintain', data: item })}
            >
              维护
            </PermissionButton>
            <Popconfirm
              title="您确定要忽略此通话记录吗?"
              onConfirm={() => ignoreCallRecord(item.id)}
            >
              <PermissionButton operateType='/car-admin/call-record/ignore' type="link">忽略</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ];

  // 维护时根据记录状态渲染不同的组件
  const MatchMaintainCom = operateInfo?.data?.status === CallRecordStatusEnum.matchMultiple ? MatchCarModal : MaintainModal;
  
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="客服姓名"
              name="agentNickName"
            >
              <Input placeholder='请输入客服姓名' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="客户电话"
              name="tel" >
              <Input placeholder='请输入客户电话' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="是否已维护" name="isMaintained" initialValue={searchParams.isMaintained}>
              <Radio.Group>
                <Radio value={undefined}>全部</Radio>
                <Radio value={true}>已维护</Radio>
                <Radio value={false}>未维护</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={callRecordRequestPath}
                  fileName='客服记录维护'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey="/car-admin/call-record/export"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
    {operateInfo.type === 'maintain' &&
      <MatchMaintainCom
        successCallback={getData}
        closeModal={() => setOperateInfo({})}
        data={operateInfo.data}
      />
    }
    </Card>
  )
}

export default CallRecord;