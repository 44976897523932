/**
 * 车辆添加和编辑
 */
import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Form,
  Input,
  message,
  AutoComplete,
  Select,
  Row,
  Col,
  Cascader,
  Button,
} from "antd";
import { debounce, isEqual, values } from "lodash";
import { carListRequests, upsertCarRequest } from "@/service/carManage";
import { deviceDetailBySnRequest } from "@/service/deviceManage";
import {
  deviceListRequest,
  adasListRequest,
  upsertDeviceRequest,
} from "@/service/deviceManage";
import { importAliMap } from "@/utils/map";
import { getInnerPermissionEnable } from "@/utils/permission";
import { RequestErrorCode } from "@/constants/requestErrorCode";
import {
  deviceCategoryData,
  deviceConfigData,
  deviceTypeData,
  carState,
  DeviceTypeEnum,
  DeviceCategoryEnum,
  devicveDelStatusData,
} from "@/constants/deviceManage";
import DatePicker from "@/components/datePicker";
import Icon from "@/components/icon";
import LocationMapModal from "../locationMapModal";
import dayjs from "dayjs";
import { tagListRequest } from "@/service/tagManage";
import { TagCategoryEnum } from "@/constants/tagManage";
import { companyListRequest } from "@/service/companyManage";
import { channelListRequest } from "@/service/channelRecode";
import CategoryTreeSelect from "@/components/categoryTreeSelect";
import { chinaData } from "@/constants/chinaData";
import { sensorDeviceListRequest } from "@/service/sensorDeviceManage";
import JtDeviceOperateModal from "@/pages/jtDevice/components/operateModal";
import AebModal from "@/pages/deviceManage/components/operateModal";
import { jtDeviceListRequest } from "@/service/jtDevice";

const { RangePicker } = DatePicker;
const { Search } = Input;

interface OperateModalProps {
  data?: any;
  type?: string;
  channelOptions?: any;
  closeModal: () => void;
  reloadData: () => void;
}

const { confirm } = Modal;

const hardwareConfigOptions = [
  { value: 1, label: "行驶记录仪 " },
  { value: 2, label: "声光报警器" },
  { value: 3, label: "前亳米波" },
  { value: 4, label: "超声波雷达" },
  { value: 5, label: "IMU" },
  { value: 6, label: '右角雷达'}
];
const modeOptions = [
  {label: '保险模式', value: '保险模式'},
  {label: '中富模式', value: '中富模式'},
  {label: '其他模式', value: '其他模式'},
]
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const geocoderRef = useRef<any>(); // 地理编码工具实例
  const [deviceOptions, setDeviceOptions] = useState<any>([]); // 搜索的设备列表
  const [adasOptions, setAdasOptions] = useState<any>([]); // adas搜索的设备列表
  const [insuranceChecked, setInsuranceChecked] = useState(false); // 是否勾选填写保险信息
  const [carInfo, setCarInfo] = useState<any>({}); // 编辑时的车辆信息
  const [operateInfo, setOperateInfo] = useState<any>({}); // 弹窗的信息
  const [locationLoading, setLocationLoading] = useState<boolean>(false); // 是否在获取定位中
  const { data, type, closeModal, reloadData, channelOptions } = props;
  const { userDetail } = useSelector((state: any) => state.userStore);
  const [businessState, setBusinessState] = useState([]); // 获取业务状态选项
  const [CarType, setCarType] = useState<any>([]); // 车辆类型
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [mode, setMode] = useState<any>([]); // 承保模式
  const [carId, setCarId] = useState<any>(null);

  // 获取承保模式
  const getMode = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.mode],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title} = item;
      tags.push({label: title, value: title});
    });
    setMode(tags);
  }
  //安装地址选项相关
  const chinaAddress = chinaData["86"]; // 中国地址
  const initInstallAddress = Object.entries(chinaAddress).map(
    ([cityKey, cityName]) => {
      return {
        value: cityKey,
        label: cityName,
        children: Object.entries(chinaData[cityKey]).map(
          ([areaKey, areaName]) => {
            return {
              value: areaKey,
              label: areaName,
            };
          }
        ),
      };
    }
  ); // 安装地址选项

  // 获取车辆类型
  const getCarType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carTypeData],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any, index: any) => {
      const { title } = item;
      tags.push({ label: title, value: index + 1 });
    });
    setCarType(tags);
  };

  // 获取车队
  const getCateAllCompanyData = async () => {
    const res = await companyListRequest({ page: 1, limit: 20000, name: "" });
    const data = res.data?.data || [];
    //循环遍历data
    const aa: any = [];
    data.forEach((item: any) => {
      aa.push({ label: item.name, value: item.id });
    });
    setAllCompany(aa);
  };
  // 是否是安装队长
  const isInstallCaptain = !getInnerPermissionEnable(
    "/car-admin/car-manage/list-params-normal"
  );
  // 录入车辆时是否限制分组
  const installGroupLimit = !getInnerPermissionEnable(
    "/car-admin/car-manage/install-group-normal"
  );
  // 编辑的时候赋值
  useEffect(() => {
    getMode()
    getCateAllCompanyData();
    getCarType();
    getBusinessStateType();
    if (type === "edit") {
      getCarDetail(data);
    }
    importAliMap(() => initMapGeocoder());
  }, []);

  // 获取车辆详情赋值
  const getCarDetail = (data: any) => {
    let {
      carId,
      plate_number,
      frame_number,
      company,
      mode,
      installAddress,
      companyId,
      channelId,
      cateId,
      carType,
      engineNumber,
      car_brand: carBrand,
      car_model: carModel,
      manufactureDate,
      registerTime,
      vehicleState,
      carSubmitTime,
      car_principal,
      car_principal_phone,
      car_driver,
      car_driver_phone,
      deviceType,
      deviceRemoveTime,
      // deviceTypeCategory,
      deviceId,
      jt808DeviceId,
      adas_sn: adasSn,
      sn: aebSn,
      hardwareConfigTypeIds,
      carRemark,
      region,
      businessDepartment,
      salesman
    } = data;
    // if (installAddressStr) {
    //   const [provinceStr, cityStr] = installAddressStr.split('/')
    //   const province = Object.entries(chinaAddress).find(([key, value]) => value === provinceStr) || []
    //   const cityGroup = chinaData[(province as string[])[0]] || {}
    //   const city = Object.entries(cityGroup).find(([key, value]) => value === cityStr) || []
    //   if (province[0]&& city[0]){
    //     form.setFieldsValue({installAddress: [province[0], city[0]]})
    //   }
    // }
    let manufactureDateObj;
    let registerTimeObj;
    let carSubmitTimeObj;
    if (manufactureDate) {
      manufactureDateObj = dayjs(manufactureDate);
    }
    if (registerTime) {
      registerTimeObj = dayjs(registerTime);
    }
    if (carSubmitTime) {
      carSubmitTimeObj = dayjs(carSubmitTime);
    }
    if (deviceRemoveTime) {
      deviceRemoveTime = dayjs(deviceRemoveTime);
    }

    // 如果adasSn或者aebSn存在，则先更新一次设备sn列表
    if (aebSn) {
      onDeviceSearch(aebSn);
    }
    if (adasSn) {
      onAdasOptions(adasSn);
    }

    const newValues = {
      carId,
      vehicleState,
      plateNumber: plate_number,
      frameNumber: frame_number,
      companyId,
      channelId,
      cateId: cateId,
      carType,
      engineNumber,
      mode,
      carBrand,
      carModel,
      manufactureDate: manufactureDateObj,
      registerTime: registerTimeObj,
      carSubmitTime: carSubmitTimeObj,
      carPrincipal: car_principal,
      carPrincipalPhone: car_principal_phone,
      carDriver: car_driver,
      carDriverPhone: car_driver_phone,
      deviceType,
      // deviceTypeCategory,
      deviceId,
      jt808DeviceId,
      adasSn,
      aebSn,
      deviceRemoveTime,
      installAddress,
      hardwareConfigTypeIds: hardwareConfigTypeIds || [],
      carRemark,
      region,
      businessDepartment,
      salesman
    };
    form.setFieldsValue(newValues);
    if (carId) {
      setCarId(carId);
    }
  };
  // 修改重复设备的SIM卡号并再次保存车辆信息
  const changeRepeatSim = async (deviceParams: any, carParams: any) => {
    const newDeviceParams: any = {
      id: deviceParams.id,
      sim: `${deviceParams.sim}-`,
    };
    const res = await upsertDeviceRequest(newDeviceParams);
    if (res.data?.code === 0) {
      // 冲突设备修改SIM成功后重新保存车辆信息
      saveCar(carParams);
    }
  };

  // 调用接口保存车辆信息
  const saveCar = async (params: any) => {
    const res = await upsertCarRequest(params);
    const resCode = res.data?.code;
    const resData = res.data?.data;
    if (resCode === 0) {
      message.success(type === "edit" ? "编辑成功" : "添加成功");
      closeModal();
      reloadData();
    } else if (resCode === RequestErrorCode.simRepeatCar) {
      const parseResData = JSON.parse(resData || "{}");
      // SIM卡存在重复设备且设备已绑定车辆
      confirm({
        title: (
          <span>
            您所关联的SIM卡号与车辆{" "}
            <span className="boldText">{parseResData.plateNumber}</span>{" "}
            关联的SIM卡号相同，请修正车辆所关联的SIM卡号
          </span>
        ),
        centered: true,
      });
    } else if (resCode === RequestErrorCode.simRepeatDevice) {
      const parseResData = JSON.parse(resData || "{}");
      // SIM卡存在重复设备且设备未绑定车辆
      confirm({
        title: (
          <span>
            您所关联的SIM卡号与设备{" "}
            <span className="boldText">{parseResData.sn}</span>{" "}
            关联的SIM卡号相同，您可以选择系统自动修改冲突设备的SIM卡号或联系管理员解决此问题
          </span>
        ),
        okText: "系统修改",
        onOk: () => changeRepeatSim(parseResData, params),
        centered: true,
      });
    }
  };

  // 处理提交的参数
  const operateSubmitParams = (values: any) => {
    const {
      plateNumber,
      frameNumber,
      companyId,
      channelId,
      vehicleState,
      cateId,
      carType,
      engineNumber,
      carBrand,
      carModel,
      mode,
      manufactureDate: manufactureDateObj,
      registerTime: registerTimeObj,
      carSubmitTime: carSubmitTimeObj,
      carPrincipal,
      carPrincipalPhone,
      carDriver,
      carDriverPhone,
      deviceType,
      // deviceTypeCategory,
      // deviceId,
      // jt808DeviceId
      deviceRemoveTime,
      adasSn,
      aebSn,
      installAddress,
      hardwareConfigTypeIds,
      carRemark,
      region,
      businessDepartment,
      salesman
      // installCaptain
    } = values;

    const params: any = {
      region,
      businessDepartment,
      salesman,
      plateNumber,
      frameNumber,
      companyId,
      channelId,
      vehicleState,
      cateId,
      mode,
      carType,
      engineNumber,
      carBrand,
      carModel,
      manufactureDate: manufactureDateObj
        ? dayjs(manufactureDateObj).format("YYYY-MM")
        : "",
      registerTime: registerTimeObj
        ? dayjs(registerTimeObj).format("YYYY-MM-DD")
        : "",
      carSubmitTime: carSubmitTimeObj
        ? dayjs(carSubmitTimeObj).format("YYYY-MM-DD HH:mm:ss")
        : "",
      deviceRemoveTime: deviceRemoveTime
        ? dayjs(deviceRemoveTime).format("YYYY-MM-DD HH:mm:ss")
        : "",
      carPrincipal,
      carPrincipalPhone,
      carDriver,
      carDriverPhone,
      deviceType,
      // deviceTypeCategory,
      // deviceId,
      adasSn,
      aebSn,
      installAddress,
      hardwareConfigTypeIds: hardwareConfigTypeIds || [],
      carRemark,
      // installAddress: `${chinaAddress[installAddress[0]]}/${chinaData[installAddress[0]][installAddress[1]]}`,
      // installCaptain
    };
    //根据设备类型处理设备大类
    switch (deviceType) {
      case DeviceTypeEnum.huanghe1:
      case DeviceTypeEnum.huanghe2:
      case DeviceTypeEnum.huanghe3:
      case DeviceTypeEnum.huanghe4:
      case DeviceTypeEnum.new1:
        params.deviceTypeCategory = DeviceCategoryEnum.huanghe; //黄河设备
        break;
      case DeviceTypeEnum.kunlunshan1:
      case DeviceTypeEnum.kunlunshan2:
      case DeviceTypeEnum.kunlunshan3:
      case DeviceTypeEnum.kunlunshan4:
      case DeviceTypeEnum.kunlunshan5:
      case DeviceTypeEnum.new2:
        params.deviceTypeCategory = DeviceCategoryEnum.kunlunshan; //昆仑山设备
        break;
      case DeviceTypeEnum.adas3:
      case DeviceTypeEnum.new3:
        params.deviceTypeCategory = DeviceCategoryEnum.kunlunshanV3; //昆仑山3.0设备
        break;
      case DeviceTypeEnum.adas:
      case DeviceTypeEnum.adas1:
      case DeviceTypeEnum.adas2:
      case DeviceTypeEnum.junchi1:
        params.deviceTypeCategory = DeviceCategoryEnum.adas; //视频机
        break;

      case DeviceTypeEnum.changxing: 
        params.deviceTypeCategory = DeviceCategoryEnum.changxing
        break;
      
      case DeviceTypeEnum.lingxing: 
        params.deviceTypeCategory = DeviceCategoryEnum.lingxing
        break;

      default:
        message.error("设备类型错误");
        break;
    }
    // switch (deviceType) {

    // }

    // if (installAddress) {
    //   params.installAddress = `${chinaAddress[installAddress[0]]}/${chinaData[installAddress[0]][installAddress[1]]}`
    // }
    // 如果adasSN或者aebSn存在，则将Sn转换为id
    // let getDevicePromises = []
    if (params.adasSn) {
      params.jt808DeviceId =
        adasOptions.find((item: any) => item.value === adasSn)?.adasId || "";
      if (!params.jt808DeviceId) {
        message.error("adas设备不存在，请重新选择");
        return;
      }
    }
    if (aebSn) {
      params.deviceId =
        deviceOptions.find((item: any) => `${item.value}` === `${aebSn}`)
          ?.deviceId || "";
      if (!params.deviceId) {
        message.error("aeb设备不存在，请重新选择");
        return;
      }
    }

    const isEdit = type === "edit";
    if (isEdit) {
      params.carId = carId;
      params.id = carId;
    } else {
      // 添加时加上安装队长的字段，取登录人的信息
      // params.installCaptain = userDetail.nickname;
    }
    // checkSn(params);
    saveCar(params);
  };
  // 提交
  const submit = async (values: any) => {
    // try {
    operateSubmitParams(values);
    // } catch (error: any) {
    // message.error(error);
    // }
  };
  const getDeviceTypeName = (type: string | number) => {
    deviceCategoryData.find((item) => {if (item.value == type) {return item}})
  }

  // 关联设备的搜索
  const onDeviceSearch = async (value: string) => {
    if (value) {
      const res = await deviceListRequest({
        page: 1,
        limit: 30,
        sn: value,
      });
      const deviceData = (res.data?.data || []).map((item: any) => ({
        label: `${item.sn}（${deviceCategoryData.find((item1) => {if (item1.value == item.deviceType) {return item}})?.label
          // item.deviceType === 2 ||
          // item.deviceType === 6 ||
          // item.deviceType === 7 ||
          // item.deviceType === 9 ||
          // item.deviceType === 12 ||
          // item.deviceType === 13
          //   ? "昆仑山设备"
          //   : "黄河设备"
        }）`,
        deviceId: item.id,
        value: String(item.sn),
        cameraSn: item.cameraSn,
        sim: item.sim,
        deviceType: item.deviceType,
      }));
      setDeviceOptions(deviceData);
    } else {
      setDeviceOptions([]);
    }
  };

  // 防抖的关联设备的搜索
  const debounceOnDeviceSearch = useMemo(() => {
    return debounce(onDeviceSearch, 600);
  }, []);

  // 选择设备时将设备的相机SN和SIM自动赋值
  const onSelectSn = (value: any, options: any) => {
    form.setFieldsValue({
      cameraSn: options.cameraSn,
      sim: options.sim,
      deviceType: options.deviceType,
    });
  };

  // adas设备sn的搜索
  const onAdasOptions = async (value: string) => {
    if (value) {
      // const res = await adasListRequest({
      const res = await jtDeviceListRequest({
        page: 1,
        limit: 30,
        phone: value, //这里的phone其实是sn
      });
      const adasData = (res.data?.data || []).map((item: any) => ({
        label: `${item.phone}`,
        // label: `${item.id}（${item.sn}）`,
        // value: String(item.id),
        value: item.phone,
        adasSn: item.phone,
        adasId: item.id,
      }));
      setAdasOptions(adasData);
    } else {
      setAdasOptions([]);
    }
  };

  // 防抖adas设备sn的搜索
  const debounceAdasSearch = useMemo(() => {
    return debounce(onAdasOptions, 600);
  }, []);

  // 初始地理编码工具
  const initMapGeocoder = () => {
    window.AMap.plugin("AMap.Geocoder", function () {
      geocoderRef.current = new window.AMap.Geocoder();
    });
  };

  // 获取业务状态
  const getBusinessStateType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.businessState],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setBusinessState(tags);
  };

  //新增AEB设备之后的回调
  const onCreateAeb = (params: any) => {
    const { sn } = params;
    //更新表单中的aebSn
    form.setFieldsValue({ aebSn: sn });
    onDeviceSearch(sn);
  };
  //新增Adas设备之后的回调
  const onCreateAdas = (params: any) => {
    const { phone: sn } = params;
    //更新表单中的adasSn
    form.setFieldsValue({ adasSn: sn });
    onAdasOptions(sn);
  };

  return (
    <>
      <Modal
        title={type === "add" ? "添加车辆" : "编辑车辆"}
        visible
        centered
        maskClosable={false}
        onCancel={closeModal}
        onOk={() => form.submit()}
        bodyStyle={{ maxHeight: "70vh", overflow: "auto" }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onFinish={submit}
        >
          <Form.Item
            label="车牌号"
            name="plateNumber"
            rules={[{ required: true, message: "请输入车牌号" }]}
          >
            <Input placeholder="请输入车牌号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
          </Form.Item>
          <Form.Item
            label="车架号"
            name="frameNumber"
            rules={[{ required: true, message: "请输入车架号" }]}
          >
            <Input placeholder="请输入车架号" onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
          </Form.Item>
          <Form.Item label={'业务模式'} name={'mode'}>
            <Select placeholder="请选择业务模式" options={modeOptions}/>
          </Form.Item>
          <Form.Item label="车队选择" name="companyId">
            <Select
              showSearch
              placeholder="请选择"
              style={{ width: "100%" }}
              options={allCompany}
              allowClear
              filterOption={(input, option) => {
                const label = (option?.label || "") as string;
                return label.indexOf(input.toLowerCase()) >= 0;
              }}
            />
          </Form.Item>
          <Form.Item label="大区" name="region">
            <Input
              placeholder="请输入大区"
            />
          </Form.Item>
          <Form.Item label="所属业务渠道" name="channelId">
            <Select
              placeholder="请选择所属业务渠道"
              options={channelOptions}
              allowClear
              showArrow
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item label="营业部" name="businessDepartment">
            <Input
              placeholder="请输入营业部"
            />
          </Form.Item>
          <Form.Item label="渠道经理" name="salesman">
            <Input
              placeholder="请输入渠道经理"
            />
          </Form.Item>
          <Form.Item
            label="运维分组"
            name="cateId"
            initialValue={undefined}
            rules={[{ required: true, message: "请选择运维分组" }]}
          >
            <CategoryTreeSelect />
          </Form.Item>
          <Form.Item
            label="车辆类型"
            name="carType"
            rules={[{ required: true, message: "请选择车辆类型" }]}
          >
            <Select placeholder="请选择车辆类型" options={CarType} />
          </Form.Item>
          <Form.Item label="发动机号码" name="engineNumber">
            <Input placeholder="请输入发动机号码" />
          </Form.Item>
          <Form.Item label="车辆品牌" name="carBrand">
            <Input placeholder="请输入车辆品牌" />
          </Form.Item>
          <Form.Item label="车辆型号" name="carModel">
            <Input placeholder="请输入车辆型号" />
          </Form.Item>

          <Form.Item label="制造年月" name="manufactureDate">
            <DatePicker
              placeholder="请选择制造年月"
              style={{ width: "100%" }}
              picker="month"
            />
          </Form.Item>
          <Form.Item label="车辆注册日期" name="registerTime">
            <DatePicker
              placeholder="请选择车辆注册日期"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="车辆工作状态" name="vehicleState">
            <Select placeholder="请选择车辆状态" options={carState} />
          </Form.Item>
          {/* 以下内容对安装队长不可见 */}
          {isInstallCaptain || (
            <Fragment>
              <Form.Item label="交车日期" name="carSubmitTime">
                <DatePicker
                  placeholder="请选择交车时间"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Fragment>
          )}
          <Form.Item label="车队长" style={{ marginBottom: 0 }}>
            <Form.Item
              name="carPrincipal"
              style={{ display: "inline-block", width: "calc(40% - 8px)" }}
            >
              <Input placeholder="请输入车队长姓名" />
            </Form.Item>
            <Form.Item
              name="carPrincipalPhone"
              style={{
                display: "inline-block",
                width: "calc(60% - 8px)",
                margin: "0 8px",
              }}
            >
              <Input placeholder="请输入车队长联系方式" />
            </Form.Item>
          </Form.Item>
          <Form.Item label="司机" style={{ marginBottom: 0 }}>
            <Form.Item
              name="carDriver"
              style={{ display: "inline-block", width: "calc(40% - 8px)" }}
            >
              <Input placeholder="请输入司机姓名" />
            </Form.Item>
            <Form.Item
              name="carDriverPhone"
              style={{
                display: "inline-block",
                width: "calc(60% - 8px)",
                margin: "0 8px",
              }}
            >
              <Input placeholder="请输入司机联系方式" />
            </Form.Item>
          </Form.Item>
          {/* 编辑时显示此项，且安装队长角色无法修改此项 */}
          {/*{type === "edit" && (*/}
          {/*  // <Form.Item label="安装队长" name="installCaptain">*/}
          {/*  //   <Input placeholder="请输入安装队长" disabled={isInstallCaptain}/>*/}
          {/*  // </Form.Item>*/}
          {/*)}*/}
          <Form.Item label="安装地址" name="installAddress">
            {/*<Cascader options={initInstallAddress}/>*/}
            <Input placeholder="请输入安装地址" />
          </Form.Item>
          <Form.Item
            label="设备类型"
            name="deviceType"
            rules={[{ required: true, message: "请选择设备类型" }]}
          >
            {/*<Form.Item label="安装设备组合类型" name="deviceType" rules={[{required: true, message: "请选择设备类型"}]}>*/}
            <Select placeholder="请选择设备类型" options={deviceTypeData} />
          </Form.Item>
          <Form.Item label="硬件选配类型" name="hardwareConfigTypeIds" >
            {/*<Form.Item label="安装设备组合类型" name="deviceType" rules={[{required: true, message: "请选择设备类型"}]}>*/}
            <Select
              placeholder="请选择选配类型"
              options={hardwareConfigOptions}
              mode="multiple"
              allowClear
              showArrow
            />
          </Form.Item>
          {/*<Form.Item label="设备拆除状态" name="deviceDelStatus">*/}
          {/*  <Select placeholder="请选择设备拆除状态" options={[{label: '已拆除', value: true}, {label: '未拆除', value: false}]}/>*/}
          {/*</Form.Item>*/}
          {/*<Form.Item label="拆除时间" name="deviceRemoveTime">*/}
          {/*  <DatePicker placeholder="请选择拆除时间" style={{width: "100%"}}/>*/}
          {/*</Form.Item>*/}
          {/*<Form.Item label="设备拆除原因" name="deviceDelReason">*/}
          {/*  <Select placeholder="请选择设备拆除原因" options={devicveDelStatusData}/>*/}
          {/*</Form.Item>*/}
          {/*<Form.Item label="设备大类" name="deviceTypeCategory"*/}
          {/*           rules={[{required: true, message: "请选择设备大类"}]}>*/}
          {/*  <Select placeholder="请选择设备大类" options={deviceCategoryData}/>*/}
          {/*</Form.Item>*/}
          <Form.Item
            label={
              <span>
                {" "}
                aeb设备SN
                <Button
                  type="link"
                  onClick={() => setOperateInfo({ type: "aebDeviceAdd" })}
                >
                  添加aeb设备
                </Button>
              </span>
            }
            name="aebSn"
          >
            <AutoComplete
              placeholder="可根据设备SN进行搜索"
              onSearch={debounceOnDeviceSearch}
              options={deviceOptions}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                adas设备SN
                <Button
                  type="link"
                  onClick={() => setOperateInfo({ type: "jtDeviceAdd" })}
                >
                  添加adas设备
                </Button>
              </span>
            }
            name="adasSn"
          >
            <AutoComplete
              placeholder="可根据设备SN进行搜索"
              onSearch={debounceAdasSearch}
              options={adasOptions}
            />
          </Form.Item>
          <Form.Item label="备注" name="carRemark">
            <Input placeholder="请输入备注"></Input>
          </Form.Item>
        </Form>
      </Modal>
      {/* 部标机设备添加/编辑 */}
      {operateInfo.type === "jtDeviceAdd" && (
        <JtDeviceOperateModal
          type={"add"}
          onClose={() => setOperateInfo({})}
          reloadData={onCreateAdas}
          carManageUse
        />
      )}
      {operateInfo.type === "aebDeviceAdd" && (
        <AebModal
          data={{}}
          type={"add"}
          closeModal={() => setOperateInfo({})}
          reloadData={onCreateAeb}
        />
      )}
    </>
  );
};

export default OperateModal;
