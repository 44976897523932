/**

 * 报价失败记录

 */

import React, { useEffect, useState } from "react";

import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Descriptions,
  InputNumber,
  Select,
  Input,
  TableProps,
  message,
} from "antd";

import DatePicker from "@/components/datePicker";

import LoadingWrapper from "@/components/loadingWrapper";

import CommonTable from "@/components/commonTable";

import ExportButton from "@/components/exportButton";

import { useFailColumns } from "@/pages/offerRecord/utils/columns.hook";

import { carListRequest, CarListRequestParams } from "@/service/carManage";
import { quotationPath, quotationRequest } from "@/service/offerRecord";
import AttachmentModal from "../offerRecord/components/attachmentModal";

const levelOptions = [
  { value: "高", label: "高" },

  { value: "中", label: "中" },

  { value: "低", label: "低" },
];

const { RangePicker } = DatePicker;

const OfferFailRecord = () => {
  const [carData, setCarData] = useState([]); // 车辆数据

  const [loading, setLoading] = useState(false); // 是否在获取报告中

  const [totalCount, setTotalCount] = useState(0); // 车辆总数

  const [pageNum, setPageNum] = useState(1); // 页码

  const [form] = Form.useForm();

  const [pageSize, setPageSize] = useState(10); // 每一页显示的数量

  const [serachParams, setSerachParams] = useState({
    cate_id: [],
  }); // 搜索相关的参数

  const [attachmentModalData, setAttachmentModalData] = useState({}); // 附件弹窗数据

  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false); // 附件弹窗是否显示

  // 搜索

  const onSearch = async (values: any) => {
    const newSearchParams = form.getFieldsValue();

    newSearchParams.beginDate = newSearchParams?.time?.[0]?.format("YYYY-MM-DD");

    newSearchParams.endDate = newSearchParams?.time?.[0]?.format("YYYY-MM-DD");

    delete newSearchParams.time;

    console.log(newSearchParams);

    if (newSearchParams) {
      setSerachParams(newSearchParams);

      getCarData({
        page: 1,

        limit: pageSize,

        ...newSearchParams,
      });
    }
  };

  // 获取导出的参数

  const getExportParams = () => {
    const values = form.getFieldsValue();

    const newSearchParams = Object.assign({}, values, { export: true });

    newSearchParams.beginDate = newSearchParams?.time?.[0]?.format("YYYY-MM-DD");

    newSearchParams.endDate = newSearchParams?.time?.[1]?.format("YYYY-MM-DD");

    delete newSearchParams.time;

    return newSearchParams;
  };

  // 获取车辆数据

  const getCarData = async (params: CarListRequestParams) => {
    try {
      setCarData([]);

      setLoading(true);

      const res = await quotationRequest(params);

      const resData = res.data?.data || [];

      const resDataWithIdxKey = resData.map((item: any, index: number) => {
        return {
          ...item,

          key: `${item.id}-${index}`,
        };
      });

      setCarData(resDataWithIdxKey);

      setTotalCount(res.data?.count || 0);

      setPageNum(params.page);

      setLoading(false);
    } catch (error) {
      console.warn("error", error);

      setLoading(false);
    }
  };

  const onCloseAttachmentModal = () => {
    setAttachmentModalVisible(false);
  };

  //打开关闭查看报价记录附件弹窗
  const openAttachmentModal = (data: any) => {
    setAttachmentModalData(data);
    setAttachmentModalVisible(true);
  };
  // 表格列显示
  const columns = useFailColumns({
    openAttachmentFn: openAttachmentModal,
  });

  // 翻页器信息改变

  const onPageChange = (page: number, newPageSize: number) => {
    const newPageNum = newPageSize !== pageSize ? 1 : page;

    setPageNum(newPageNum);

    setPageSize(newPageSize);

    getCarData({
      page: newPageNum,

      limit: newPageSize,

      ...serachParams,
    });
  };

  useEffect(() => {
    form.submit()
  }, [])

  return (
    <Card bordered={false}>
      <Form
        onFinish={onSearch}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item label="报价时间" name="time">
              <RangePicker allowClear={false} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="车牌号" name="licenseNo">
              <Input placeholder="请输入车牌号" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="车架号" name="carVin">
              <Input placeholder="请输入车架号" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item shouldUpdate>
              {() => (
                <div>
                  <Button type="primary" htmlType="submit" style={{marginRight: '10px'}}>
                    搜索
                  </Button>

                  <ExportButton
                    url={quotationPath}
                    fileName={`${form.getFieldValue("licenseNo")}报价失败记录`}
                    getParamsFunc={getExportParams}
                    requestMethod="GET"
                    permissionKey="/car-admin/fleet-risk/export"
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <LoadingWrapper spinning={loading} style={{}}>
        <CommonTable
          rowKey="key"
          columns={columns}
          dataSource={carData}
          pagination={{
            total: totalCount,

            current: pageNum,

            pageSize,

            onChange: onPageChange,
          }}
          bordered
          loading={loading}
          // tableInScreen={false}

          scroll={{ x: "100%", y: "100%" }}
        />
      </LoadingWrapper>

      {attachmentModalVisible && (
        <AttachmentModal
          open={attachmentModalVisible}
          onClose={onCloseAttachmentModal}
          data={attachmentModalData}
        ></AttachmentModal>
      )}
    </Card>
  );
};

export default OfferFailRecord;
