/**
 * GPS上报频率弹窗
 */
import React from 'react';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import { gpsFrequencyFormConfig } from '@/constants/parameterConfiguration';
import { setGpsFrequencyRequest } from '@/service/parameterConfiguration';

interface GpsFrequencyModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const GpsFrequencyModal = (props: GpsFrequencyModalProps) => {
  const { data, closeModal } = props;

  return (
    <ParameterConfigurationModal
      operateParameterValue={data.sn}
      operateParameterField="sn"
      setParameterRequest={setGpsFrequencyRequest}
      formConfig={gpsFrequencyFormConfig}
      closeModal={closeModal}
      modalTitle="GPS上报频率"
      modalWidth={520}
      configKey="/car-admin/parameter-configuration/gps-config"
      approveKey="/car-admin/parameter-configuration/gps-config-approve"
      approveType="gpsFrequencyConfig"
      labelColSpan={6}
      wrapperColSpan={18}
      deviceType={data.deviceType}
    />
  );
};

export default GpsFrequencyModal;
