/**
 * 参数配置相关请求
 */
import request from '@/utils/request';

// 处理请求参数
const operateRequestParams = (params: any) => {
  const reuqestParams = { ...params };
  delete reuqestParams.sn;
  return {
    reuqestParams,
    sn: params.sn
  };
};

// 获取参数配置列表
export const paramListRequest = (params: any) => {
  return request.get('/api/v2/device/param/list', { params });
};

// 获取车身类型数据
export const getCarTypeRequest = (sn: string) => {
  return request.get(`/api/control/aeb/vehicleType/${sn}`);
};

// 设置车身类型
export const setCarTypeRequest = (data: any) => {
  const { sn, type } = data;
  return request.put(`/api/control/aeb/vehicleType/${sn}/${type}`);
};

// 设置GPS上报频率
export const setGpsFrequencyRequest = (data: any) => {
  const { sn, frequency } = data;
  return request.put(`/api/control/aeb/gpsReportInterval/${sn}/${frequency}`);
};

// 获取软硬件信息数据
export const getWareInfoRequest = (sn: string) => {
  return request.get(`/api/control/aeb/aeb/deviceInfo${sn}`);
};

// 获取AEB参数配置
export const getAebParamsRequest = (sn: string) => {
  return request.get(`/api/control/aeb/aeb/aebConfig/${sn}`);
};

// 设置AEB参数配置
export const setAebParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/api/control/aeb/aeb/aebConfig/${sn}`, reuqestParams);
};

// 获取记录仪配置信息
export const getRecorderParamsRequest = (sn: string) => {
  return request.get(`/api/control/aeb/aeb/modemConfig/${sn}`);
};

// 设置记录仪配置
export const setRecorderParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/api/control/aeb/aeb/modemConfig/${sn}`, reuqestParams);
};

// 获取传感器配置信息
export const getSensorParamsRequest = (sn: string) => {
  return request.get(`/api/control/aeb/aeb/sensorConfig/${sn}`);
};

// 设置传感器配置
export const setSensorParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/api/control/aeb/aeb/sensorConfig/${sn}`, reuqestParams);
};

// 根据参数获取拍摄的视频
export const getShootByParamsRequest = (sn: string, data: any) => {
  return request.post(`/api/control/aeb/aeb/shoot/${sn}`, data);
};

// 获取设备的视频
export const getShootHistoryRequest = (params: any) => {
  const reuqestParams = { ...params };
  delete reuqestParams.sn;
  return request.get(`/api/control/aeb/aeb/shootHistory/${params.sn}`, {
    params: reuqestParams
  });
};

// 获取记录仪日志
export const getRecorderLogRequest = (sn: string) => {
  return request.post(`/api/control/aeb/aeb/fetchLog/${sn}`);
};

// 记录仪日志列表数据获取
export const getRecorderLogListRequest = (params: any) => {
  const reuqestParams = { ...params };
  delete reuqestParams.sn;
  return request.get(`/api/control/aeb/aeb/logHistory/${params.sn}`, {
    params: reuqestParams
  });
};

// 获取相机参数
export const getCameraParamsRequest = (sn: string) => {
  return request.get(`/api/control/aeb/aeb/smarterEyeConfig/${sn}`);
};

// 设置相机参数
export const setCameraParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(
    `/api/control/aeb/aeb/smarterEyeConfig/${sn}`,
    reuqestParams
  );
};

// 获取拉线电机参数
export const getCableMotorParamsRequest = (sn: string) => {
  return request.get(`/api/control/aeb/aeb/pullingMotorConfig/${sn}`);
};

// 设置拉线电机参数
export const setCableMotorParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(
    `/api/control/aeb/aeb/pullingMotorConfig/${sn}`,
    reuqestParams
  );
};
