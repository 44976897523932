/**
 * 配置角色的权限
 */
import React, { useEffect, useState } from 'react';
import { Button, Drawer, message, Tree } from 'antd';
import { uniq } from 'lodash';
import { operateMenuData, getAllMenuKeys, filterMenuData } from '@/utils/menuManage';
import { getRoleRelateMenuRequest, roleRelateMenuRequest } from '@/service/roleManage';
import { getUserPermissionRequest } from '@/service/userManage';

interface PermissionConfigModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const PermissionConfigModal = (props: PermissionConfigModalProps) => {
  const { data, closeModal } = props;
  const [originUserPermission, setOriginUserPermission] = useState([]); // 当前用户的原始权限数据
  const [userPermission, setUserPermission] = useState([]); // 当前用户的权限数据
  const [checkedMenuKeys, setCheckedMenuKeys] = useState<any>([]); // 当前角色已有的权限key
  const [saveLoading, setSaveLoading] = useState(false); // 保存按钮的loading

  // 获取当前用户菜单权限
  const getUserPermission = async() => {
    const res = await getUserPermissionRequest();
    const permissionData = res.data?.data || [];
    setOriginUserPermission(permissionData)
    setUserPermission(operateMenuData(permissionData));
  }

  // 获取当前角色已有的菜单权限
  const getRoleRelateMenu = async() => {
    const res = await getRoleRelateMenuRequest(data.id);
    setCheckedMenuKeys(getAllMenuKeys(res.data?.data || []));
  }

  useEffect(() => {
    getUserPermission();
    getRoleRelateMenu();
  }, []);

  // 菜单权限树勾选
  const onTreeCheck = (checkedKeys: any, e: any) => {
    let newCheckedKeys = [...checkedKeys];
    const allPermissions = e.node?.allPermissions || [];
    // 勾选操作
    if (e.checked) {
      if (allPermissions.length) {
        // 选中任何功能权限后都自动选择查看权限
        newCheckedKeys.push(allPermissions.find((item: any) => item.displayName === '查看')?.permissionKey);
      }
    } else {
      if (e.node?.title === '查看' && allPermissions.length) {
        // 取消查看权限，自动取消同级所有权限 
        const allPermissionKeys = allPermissions.map((item: any) => item.permissionKey);
        newCheckedKeys = newCheckedKeys.filter((item: any) => !allPermissionKeys.includes(item))
      }
    }
    setCheckedMenuKeys(uniq(newCheckedKeys));
  }

  // 保存权限配置
  const saveConfig = async() => {
    try {
      setSaveLoading(true);
      const selectMenuConfig = filterMenuData(originUserPermission, checkedMenuKeys);
      const res = await roleRelateMenuRequest({
        roleId: data.id,
        menus: selectMenuConfig,
      });
      if (res.data?.data) {
        message.success('配置成功');
        closeModal();
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  }

  return (
    <Drawer
      title={`${data.name}角色权限配置`}
      visible
      onClose={closeModal}
      size="large"
      extra={
        <Button type="primary" onClick={saveConfig} loading={saveLoading}>
          保存
        </Button>
      }
    >
      <Tree
        treeData={userPermission}
        checkable
        checkedKeys={checkedMenuKeys}
        onCheck={onTreeCheck}
        selectable={false}
      />
    </Drawer>
  )
}

export default PermissionConfigModal;
     