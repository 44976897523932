/**
 * 设备运行状况相关请求
 */
import request from '@/utils/request';

export interface DeviceOperationRequestParams {
  page: number;
  limit: number;
  beginDate: string;
  endDate: string;
  cateIds: Array<string>;
}

// 获取设备运维状况数据
export const deviceOperationRequest = (
  params: DeviceOperationRequestParams
) => {
  return request.post('/api/v2/car/statistics', params);
};

// 设备运维状况到处路径
export const deviceOperationExportPath = '/api/v2/car/statistics/export';
