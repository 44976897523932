/**
 * 操作客服任务
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { upsertCustomer } from '@/service/serviceManage';
import { userListRequest } from '@/service/userManage';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModalTask = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [servicePerson, setServicePerson] = useState([]); // 维保人员数据

  // 编辑的时候赋值
  useEffect(() => {
    getServicePerson()
    if (type == 'edit') {
      data.userId = Number(data.userId)
      form.setFieldsValue(data)
    }
  }, []);

  const flagList = [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ]

  // 获取客服人员数据
  const getServicePerson = async() => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setServicePerson(res.data?.data || [])
  }

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertCustomer(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      } 
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加客服人员' : '编辑客服人员'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item
          label="客服人员"
          name="userId"
          rules={[
            { required: true, message: '请选择客服人员' }
          ]}
        >
          <Select
            placeholder="请选择客服人员"
            options={servicePerson}
            allowClear
            showArrow
            showSearch
            fieldNames={{
              label: 'nickname',
              value: 'id'
            }}
            optionFilterProp="nickname"
          />
        </Form.Item>
        <Form.Item
          label="客服编码"
          name="number"
          rules={[
            { required: true, message: '请输入客服编码' }
          ]}
        >
          <Input placeholder="请输入客服编码" />
        </Form.Item>
        <Form.Item
          label="全职客服"
          name="flag"
          rules={[
            { required: true, message: '请选择是否全职客服' }
          ]}
        >
          <Select placeholder="请选择是否全职客服" options={flagList} optionFilterProp="label"  />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModalTask;