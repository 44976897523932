/**
 * 营销指标查询相关请求
 */
import request from "@/utils/request";

export interface MarketingIndicatorsParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cateIds?: Array<number>;
}

// 营销指标查询
export const marketingIndicatorsRequestPath = '/api/dataAnalyze/marketingIndicators';
export const marketingIndicatorsRequest = (params: MarketingIndicatorsParams) => {
  return request.get(marketingIndicatorsRequestPath, {
    params,
  })
}