/**
 * 充值套餐界面
 */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Radio, Row } from 'antd';
import styles from './index.module.scss';
import RechargeCard from '../rechargeCard';
import { videoInvestRequest, videoRequest } from "@/service/monitoringClientRecord";


interface ContactModalProps {
    data: any; // 数据
    closeModal: () => void; // 关闭弹窗的方法
    onOpenPay?: any
    moneyData:any; //金额
    orderNumber?:any //订单号
}

const RechargeModal =(props: ContactModalProps) => {
    const { data, closeModal,onOpenPay, moneyData, orderNumber } = props;
    const [rechargeAmount, setRechargeAmount] = useState<any>({ num: 10, title: '600分钟' }); // 充值金额/时长
    const [duration, setDuration] = useState<any>('600分钟'); // 充值时间
    const [value, setValue] = useState<any>('1'); // 支付方式 

    useEffect(() => {
      
    }, []);

    //改变金额
    const changeAmount = (num: any, title: any) => {
        // getOrderNumber()
        setRechargeAmount({ num: num, title: title });
        setDuration(title)
    };

    //支付选择方式按钮
    const tabPayment = (e: any) => {
        setValue(e.target.value);
        console.log(value, '支付方式');
    };

      //取消支付关闭充值详情框
    const cancelPayment = () => {
        closeModal()
    };

      //点击确认支付判断支付方式
    const confirmPayment = async (num: any) => {
        // 获取订单
        const params = {
            userId: data, // 用户id
            duration: Number(duration?.match(/\d+/g).join(''))
        }
        const res1 = await videoRequest(params);
        if (res1.status == 200) { 
            let  orderCode =  res1.data.data 
            closeModal()
            // 对接响应的支付接口，然后关闭弹框 
            let data1 = {
                payment: Number(value),
                orderNo:orderCode,
                cost: rechargeAmount.num
            };
            console.log( '发起支付');
            const res = await videoInvestRequest(data1);
            if (res.status == 200) {
                // 金额
                console.log('支付码生成成功');
                moneyData(rechargeAmount.num)
                //订单号
                orderNumber(orderCode)
                // 本地存储返回data,支付方式以及订单号
                localStorage.setItem("contactDetail", res.data.data.content) 
                localStorage.setItem("zhifubaoPayment",value)
                localStorage.setItem("zhifubaoOrderNo",orderCode)
                onOpenPay(true);
            }else{
                
            }

        }else{

        }

    }

    return (
        <>
            <Modal
                title="充值套餐"
                visible
                onCancel={closeModal}
                centered
                width={500}
                footer={[
                    <Button key="back" onClick={cancelPayment}> 取消支付 </Button>,
                    <Button
                        key="link"
                        type="primary"
                        onClick={confirmPayment}>
                        确认支付
                    </Button>,
                ]}
            >
                <Form className={styles.rechargeBox}>
                    <Row gutter={24} className={styles.rechargeBoxRow}>
                        <Col span={6} >
                            <RechargeCard
                                title="10小时"
                                tag=""
                                count={10}
                                clickFunc={() => { changeAmount(10, '600分钟') }}
                            />
                        </Col>
                        <Col span={6} >
                            <RechargeCard
                                title="20小时"
                                tag=""
                                count={20}
                                clickFunc={() => { changeAmount(20, '1200分钟') }}
                            />
                        </Col>
                        <Col span={6}>
                            <RechargeCard
                                title="50小时"
                                tag=""
                                count={50}
                                clickFunc={() => { changeAmount(50, '3000分钟') }}
                            />
                        </Col>
                        <Col span={6}>
                            <RechargeCard
                                title="100小时"
                                tag=""
                                count={100}
                                clickFunc={() => { changeAmount(100, '6000分钟') }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} >
                        <Col className={styles.rechargeBoxCol}>
                            <div className={styles.rechargeAmount}>
                                <span style={{ fontSize: '14px', }}> 充值金额:</span>  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>￥{rechargeAmount.num}</div>
                                <Form.Item
                                    // label="请选择微信或支付宝点击支付"
                                     label="请使用支付宝支付"
                                    rules={[{ required: true, message: '请选择' }]}>
                                    <Radio.Group value={value} onChange={tabPayment}>
                                        <Radio value={'1'}>支付宝</Radio>
                                        {/* <Radio value={'2'}>微信</Radio> */}
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>

    );
};

export default RechargeModal;
