import {
  DeviceCategoryEnum,
  DeviceType,
  deviceCategoryData
} from '@/constants/deviceManage';

// 是否是非3.0昆仑山设备
export const isNotKlsV3Aeb = (deviceCategory: string | number) => {
  if (deviceCategory) {
    // 将设备类型转为数值类型数据
    const numberDeviceCategory = Number(deviceCategory);
    return DeviceCategoryEnum.kunlunshan === numberDeviceCategory;
  } else {
    return false;
  }
};

// 是否是昆仑山3.0设备
export const isKlsV3Aeb = (deviceCategory: string | number) => {
  if (deviceCategory) {
    // 将设备类型转为数值类型数据
    const numberDeviceCategory = Number(deviceCategory);
    return DeviceCategoryEnum.kunlunshanV3 === numberDeviceCategory;
  } else {
    return false;
  }
};

// 是否是黄河设备
export const isHhAeb = (deviceCategory: string | number) => {
  if (deviceCategory) {
    // 将设备类型转为数值类型数据
    const numberDeviceCategory = Number(deviceCategory);
    return DeviceCategoryEnum.huanghe === numberDeviceCategory;
  } else {
    return false;
  }
};

// 是否为昆仑山设备(昆仑山设备、昆仑山3.0设备两种)
export const isKlsAeb = (deviceCategory: string | number) => {
  if (deviceCategory) {
    return isNotKlsV3Aeb(deviceCategory) || isKlsV3Aeb(deviceCategory);
  } else {
    return false;
  }
};

// 显示设备类型
export const displayDeviceTypeCategory = (deviceCategory: number | null) => {
  const matchData: DeviceType | undefined = deviceCategoryData.find(
    (item: DeviceType) => item.value === deviceCategory
  );
  return matchData?.label || deviceCategory;
};
