/**
 * 车辆诊断部标机设备故障信息(昆仑山设备)
 */
import React, { useState, useEffect, Fragment } from 'react';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import {
  versionInfoListFn,
} from '@/service/carDiagnosis';
import { useTable } from '@/utils/useTable';
import { getJtDeviceErrorColumns, getVersionChangeColumns } from '@/columns/jtDeviceError';

interface JtDeviceErrorTableProps {
  diagnosisSearchParams: any;
  getExportParams: () => any;
  carId?: string
}
const JtDeviceVersionChangeTable = (props: JtDeviceErrorTableProps) => {
  console.log(props)
  const { diagnosisSearchParams, getExportParams, carId } = props;
  const [searchParams, setSearchParams] = useState(null);
  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    if (carId && diagnosisSearchParams ) {
      setSearchParams({...diagnosisSearchParams, carId});
    }
  }, [diagnosisSearchParams, carId]);

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: versionInfoListFn,
    initRequest: Boolean(searchParams),
    initPageSize: 40,
    searchParams
  });

  return (
    <Fragment>
      <CommonTable
        rowKey="id"
        columns={getVersionChangeColumns(data)}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        scroll={{ x: '100%', y: 400 }}
      />
    </Fragment>
  );
};

export default JtDeviceVersionChangeTable;
