/**
 * 路口标注
 */
import React, { useEffect, useRef, useState } from 'react';
import { Button, message, Popconfirm, Tag } from 'antd';
import gcoord from 'gcoord';
import { importAliMap } from '@/utils/map';
import { ownRound } from '@/utils/math';
import LoadingWrapper from '@/components/loadingWrapper';
import {
  crossMarkListRequest,
  deleteCrossMarkRequest,
  upsertCrossMarkRequest
} from '@/service/crossMark';
import OperateModal from './components/operateModal';
import commonStyles from '@/styles/pageContent.module.scss';
import styles from './index.module.scss';

const { CheckableTag } = Tag;
const CrossMark = () => {
  const [loading, setLoading] = useState(false); // loading
  const [roadList, setRoadList] = useState([]); // 路线标注数据
  const [selectRoadId, setSelectRoadId] = useState<any>(null); // 选择的路线ID
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息
  const mapRef: any = useRef(null); // 存储地图实例
  const polygonEditorRef: any = useRef([]); // 存储多边形编辑器

  // 地图初始化
  const initMap = async () => {
    try {
      mapRef.current = new window.AMap.Map('crossMarkMap', {
        center: [116.368904, 39.913423],
        zoom: 12
      });
      // 加载插件
      window.AMap.plugin(
        ['AMap.ToolBar', 'AMap.Scale', 'AMap.MapType'],
        function () {
          mapRef.current.addControl(
            new window.AMap.ToolBar({
              position: {
                bottom: '60px',
                left: '20px'
              }
            })
          );
          mapRef.current.addControl(new window.AMap.Scale());
          mapRef.current.addControl(new window.AMap.MapType());
        }
      );
    } catch (error) {
      message.error('地图初始化失败');
    }
  };

  // 获取路口标注列表
  const getCrossMarkList = async () => {
    try {
      setLoading(true);
      const res = await crossMarkListRequest();
      setRoadList(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    importAliMap(initMap);
    getCrossMarkList();
  }, []);

  // 显示多边形长宽和删除marker
  const getDistance = (
    paths: any,
    polygon: any,
    polygonEditor: any,
    existTextMarker?: any,
    existFlagMarker?: any,
    existCloseMarker?: any
  ) => {
    const allLng = paths.map((item: number[]) => item[0]);
    const allLat = paths.map((item: number[]) => item[1]);
    const length = window.AMap.GeometryUtil.distance(
      [Math.min(...allLng), Math.min(...allLat)],
      [Math.max(...allLng), Math.min(...allLat)]
    );
    const width = window.AMap.GeometryUtil.distance(
      [Math.min(...allLng), Math.min(...allLat)],
      [Math.min(...allLng), Math.max(...allLat)]
    );
    const text = `长:${ownRound(length, 1)};宽:${ownRound(width, 1)}`;
    if (existTextMarker) {
      // 调整多边形时修改marker的内容和位置
      existTextMarker.setPosition(paths[0]);
      existTextMarker.setText(text);
      existFlagMarker.setPosition(paths[1]);
      existCloseMarker.setPosition(paths[2]);
      return { length, width };
    } else {
      const matchIndex = polygonEditorRef.current.findIndex(
        (item: any) => item === polygonEditor
      );
      const textMarker = new window.AMap.Text({
        text,
        position: paths[0],
        cursor: 'default',
        anchor: 'bottom-right'
      }); // 显示长宽的marker
      const flagMarker = new window.AMap.Text({
        text: `<input name="flag${matchIndex}" type="radio" value="in" / >进<input name="flag${matchIndex}" type="radio" value="out" / >出`,
        position: paths[1],
        cursor: 'default',
        anchor: 'top-right'
      }); // 标识路口的marker
      const closeMarker = new window.AMap.Text({
        text: '删除',
        position: paths[2]
      }); // 显示删除的marker
      closeMarker.on('click', () => {
        // 点击删除时清空当前多边形所有内容
        polygonEditorRef.current.splice(matchIndex, 1, null);
        polygonEditor.close();
        polygonEditor = null;
        mapRef.current.remove([polygon, textMarker, flagMarker, closeMarker]);
      });
      textMarker.setMap(mapRef.current);
      flagMarker.setMap(mapRef.current);
      closeMarker.setMap(mapRef.current);
      return { length, width, textMarker, flagMarker, closeMarker };
    }
  };

  // 生成一个多边形编辑器
  const generatePolygon = (paths: any) => {
    const polygon = new window.AMap.Polygon({
      path: paths,
      draggable: true
    });
    polygon.setMap(mapRef.current);
    // 生成多边形编辑器
    const polygonEditor = new window.AMap.PolygonEditor(
      mapRef.current,
      polygon
    );
    polygonEditorRef.current.push(polygonEditor);
    const { textMarker, flagMarker, closeMarker } = getDistance(
      paths,
      polygon,
      polygonEditor
    );
    // 调整多边形
    polygonEditor.on('adjust', function (e: any) {
      getDistance(
        e.target._opts.path,
        polygon,
        polygonEditor,
        textMarker,
        flagMarker,
        closeMarker
      );
    });
    // 移动多边形
    polygon.on('dragend', function (e: any) {
      getDistance(
        e.target._opts.path,
        polygon,
        polygonEditor,
        textMarker,
        flagMarker,
        closeMarker
      );
    });
    // 打开多边形编辑器
    polygonEditor.open();
  };

  // 多边形框选
  const drawPolygon = () => {
    window.AMap.plugin(
      ['AMap.MouseTool', 'AMap.Polygon', 'AMap.PolygonEditor'],
      function () {
        const mouseTool = new window.AMap.MouseTool(mapRef.current);
        mouseTool.on('draw', function (event: any) {
          // 不使用鼠标工具生成的多边形，自定义多边形
          event.obj.destroy();
          generatePolygon(event.obj._opts.path);
          // 关闭鼠标工具
          mouseTool.close();
        });
        mouseTool.polygon();
      }
    );
  };

  // 保存标注
  const save = async () => {
    const content: any = [];
    polygonEditorRef.current.forEach((item: any, index: number) => {
      if (item) {
        const paths = item.getTarget()._opts.path;
        // 将经纬度转为WGS84坐标系进行存储
        const transformPaths = paths.map((item: any) =>
          gcoord.transform(item, gcoord.AMap, gcoord.WGS84)
        );
        const data: any = { paths: transformPaths };
        const radios: any = document.getElementsByName(`flag${index}`);
        for (let i = 0; i < radios.length; i++) {
          if (radios[i]?.checked) {
            data.flag = radios[i].value;
            break;
          }
        }
        content.push(data);
      }
    });
    const res = await upsertCrossMarkRequest({
      content: JSON.stringify(content),
      id: selectRoadId
    });
    if (res.data?.data) {
      message.success('保存成功');
      getCrossMarkList();
    }
  };

  // 清空地图和多边形编辑器
  const clearMap = () => {
    mapRef.current.clearMap();
    polygonEditorRef.current.forEach((item: any) => {
      if (item) {
        item.close();
        item = null;
      }
    });
    polygonEditorRef.current = [];
  };

  // 选择路线标注
  const onRoadChange = (checked: boolean, road: any) => {
    clearMap();
    if (checked) {
      setSelectRoadId(road.id);
      const parseContent = JSON.parse(road.content || '[]');
      parseContent.forEach((item: any, index: number) => {
        window.AMap.plugin(['AMap.Polygon', 'AMap.PolygonEditor'], function () {
          // 将经纬度转为高德地图坐标进行渲染
          const transformPaths = (item.paths || []).map((item1: any) =>
            gcoord.transform(item1, gcoord.WGS84, gcoord.AMap)
          );
          generatePolygon(transformPaths);
        });
        const radios: any = document.getElementsByName(`flag${index}`);
        for (let i = 0; i < radios.length; i++) {
          if (radios[i]?.value === item.flag) {
            radios[i].checked = true;
            break;
          }
        }
      });
    } else {
      setSelectRoadId(null);
    }
  };

  // 删除路线标注
  const deleteRoad = async (id: number) => {
    const res = await deleteCrossMarkRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      if (id === selectRoadId) {
        setSelectRoadId(null);
        clearMap();
      }
      getCrossMarkList();
    }
  };

  return (
    <LoadingWrapper spinning={loading} autoHeight style={{ height: '100%' }}>
      <div className={styles.crossMark}>
        <div className={styles.leftPart}>
          <div className={styles.buttonBox}>
            <Button
              type="primary"
              onClick={() => setOperateInfo({ type: 'add' })}
            >
              添加路线标注
            </Button>
          </div>
          <div className={styles.roadBox}>
            {roadList.map((item: any) => (
              <div className={styles.roadItem} key={item.id}>
                <CheckableTag
                  key={item.id}
                  checked={selectRoadId === item.id}
                  onChange={(checked: boolean) => onRoadChange(checked, item)}
                >
                  {item.name}
                </CheckableTag>
                <div className={commonStyles.tableOperateColumn}>
                  <Button
                    type="link"
                    onClick={() => setOperateInfo({ type: 'edit', data: item })}
                  >
                    编辑
                  </Button>
                  <Popconfirm
                    title="确定要删除该路线吗？"
                    onConfirm={() => deleteRoad(item.id)}
                  >
                    <Button type="link">删除</Button>
                  </Popconfirm>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightPart}>
          <div className={styles.title}>
            <Button onClick={drawPolygon} disabled={!selectRoadId}>
              添加标注
            </Button>
            <Button onClick={save} type="primary" disabled={!selectRoadId}>
              保存标注
            </Button>
          </div>
          <div id="crossMarkMap" style={{ height: '100%' }} />
        </div>
      </div>
      {['add', 'edit'].includes(operateInfo.type) && (
        <OperateModal
          {...operateInfo}
          closeModal={() => setOperateInfo({})}
          reloadData={getCrossMarkList}
        />
      )}
    </LoadingWrapper>
  );
};

export default CrossMark;
