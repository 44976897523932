/* eslint-disable no-var */
var Tab = [
  1, 2, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6,
  6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7,
  7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7
];

var PCM2G711A = function (pcm: any) {
  var buffer = new Uint8Array(pcm.length);
  for (var i = 0; i < pcm.length; i++) {
    var pcm_val = pcm[i],
      mask;

    if (pcm_val >= 0) {
      mask = 0xd5; /* sign (7th) bit = 1 */
    } else {
      mask = 0x55; /* sign bit = 0 */
      pcm_val = -pcm_val - 1;
    }

    /* Convert the scaled magnitude to segment number. */
    var seg = (Tab[(pcm_val >> 8) & 0x7f] || 8) - 1;

    /* Combine the sign, segment, and quantization bits. */
    var aval = seg << 4;
    if (seg < 2) aval |= (pcm_val >> 4) & 15;
    else aval |= (pcm_val >> (seg + 3)) & 15;
    buffer[i] = aval ^ mask;
  }
  return buffer;
};
var G711A2PCM = function (bytes: any) {
  var buffer = new Int16Array(bytes.length);
  for (var i = 0; i < bytes.length; i++) {
    var a_val = bytes[i] ^ 0x55;
    var t = (a_val & 15) << 4;
    var seg = (a_val & 0x70) >> 4;
    switch (seg) {
      case 0:
        t += 8;
        break;
      case 1:
        t += 0x108;
        break;
      default:
        t += 0x108;
        t <<= seg - 1;
    }
    buffer[i] = a_val & 0x80 ? t : -t;
  }
  return buffer;
};
export { PCM2G711A, G711A2PCM };
