/**
 * 客户人脸
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm, Image } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import { useTable } from '@/utils/useTable';
import { clientFaceListRequest, faceDeleteRequest } from '@/service/clientFace'
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';

const ClientFace = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息

  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: clientFaceListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    setSearchParams(newSearchParams);
  };

  //删除
  const deleteUser = async(id: number) => {
    const res = await faceDeleteRequest(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 60,
      ellipsis: { showTitle: false },
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: 80,
      ellipsis: { showTitle: false },
    },
    {
      title: '所属分组',
      dataIndex: 'cateId',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '照片',
      dataIndex: 'imageUrl',
      width: 110,
      ellipsis: { showTitle: false },
      render: (imageUrl:any) => <Image src={imageUrl} style={{height:"100px"}} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/client-face/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
          
            <Popconfirm
              title="您确定要删除该用户吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/client-face/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" autoComplete="off">
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="姓名" name="name">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="分类选择" name="cateId" initialValue={searchParams}>
            <CategoryTreeSelect treeCheckable />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="&nbsp;">
            <div className={styles.searchBox}>
              <Button type="primary" htmlType="submit">搜索</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/client-face/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default ClientFace;
