/**
 * 保前查勘记录相关请求
 */
import request from "@/utils/request";

// 获取保前查勘记录
export const getCarExplorationRequestPath = '/api/v2/carExploration/list';
export const getCarExplorationRequest = (params: any) => {
  return request.get(getCarExplorationRequestPath, { params })
}

// 操作保前查勘记录
export const upsertCarExplorationRequest = (data: any) => {
  return request.post('/api/v2/carExploration/upsert', data)
}

// 删除保前查勘记录
export const deleteCarExplorationRequest = (id: any) => {
  return request.delete(`/api/v2/carExploration/${id}`)
}