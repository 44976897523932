/**
 * 传感器配置弹窗
 */
import React, { Fragment, useState } from 'react';
import { Button } from 'antd';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import RadarNumberModal from '@/components/radarNumberModal';
import {
  getSensorParamsRequest,
  setSensorParamsRequest
} from '@/service/parameterConfiguration';
import {
  getJtKlsSensorParamsRequest,
  setJtKlsSensorParamsRequest
} from '@/service/jtKlsParameterConfiguration';
import { getSensorConfigParams } from '@/constants/parameterConfiguration';

interface SensorModallProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const SensorModal = (props: SensorModallProps) => {
  const {
    data: { isKlsV3, sn, deviceType },
    closeModal
  } = props;
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关信息

  return (
    <Fragment>
      <ParameterConfigurationModal
        operateParameterValue={sn}
        operateParameterField="sn"
        getParameterRequest={
          isKlsV3 ? getJtKlsSensorParamsRequest : getSensorParamsRequest
        }
        setParameterRequest={
          isKlsV3 ? setJtKlsSensorParamsRequest : setSensorParamsRequest
        }
        formConfig={getSensorConfigParams(isKlsV3)}
        closeModal={closeModal}
        modalTitle="传感器参数配置"
        modalRightTitle={
          <Button
            type="primary"
            onClick={() => setOperateInfo({ type: 'radar' })}
          >
            雷达编号说明
          </Button>
        }
        configKey="/car-admin/parameter-configuration/sensor-configuration-config"
        approveKey="/car-admin/parameter-configuration/sensor-configuration-config-approve"
        approveType="sensorConfig"
        deviceType={deviceType}
      />
      {operateInfo?.type === 'radar' && (
        <RadarNumberModal closeModal={() => setOperateInfo({})} />
      )}
    </Fragment>
  );
};

export default SensorModal;
