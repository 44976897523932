/**
 * 审批查询相关请求
 */
import request from '@/utils/request';

// 获取审批列表
export const auditListRequestPath = '/api/control/audit/list';
export const auditListRequest = (params: any) => {
  return request.get(auditListRequestPath, {
    params
  });
};

// 新增审批
export const insertAuditRequest = (data: any) => {
  return request.post('/api/control/audit/insert', data);
};

// 通过审批
export const approveAuditRequest = (id: number) => {
  return request.post(`/api/control/audit/approve/${id}`);
};

// 通过审批(昆仑山3.0设备)
export const approveJtKlsAuditRequest = (id: number) => {
  return request.post(`/808/aebs/approve/${id}`, {}, {headers: {'sn-agent': id}});
};

// 拒绝审批
export const rejectAuditRequest = (id: number) => {
  return request.post(`/api/control/audit/reject/${id}`);
};
