/**
 * 传感器方案-设备类型添加/编辑
 */
import React, { useEffect } from 'react';
import { Form, Input, message, Modal, InputNumber } from 'antd';
import { upsertSensorDeviceTypeRequest } from '@/service/sensorDeviceTypeManage';
import { actuatorName } from '@/constants/sensorScheme';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();

  // 编辑时赋值
  useEffect(() => {
    if (['edit', 'detail'].includes(type)) {
      const formValues = { ...data };
      // 执行机构才显示类型ID
      if (formValues.name !== actuatorName) {
        delete formValues.typeId;
      }
      form.setFieldsValue(formValues);
    }
  }, []);

  // 保存设备类型
  const upsertDeviceType = async (values: any) => {
    const params = { ...values };
    // 没有填写类型ID时默认给0
    params.typeId = values.typeId || 0;
    if (type === 'edit') {
      params.id = data.id;
    }
    const res = await upsertSensorDeviceTypeRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  return (
    <Modal
      title={
        type === 'detail'
          ? '设备类型详情'
          : type === 'add'
          ? '新建设备类型'
          : '编辑设备类型'
      }
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okButtonProps={{ style: type === 'detail' ? { display: 'none' } : {} }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={upsertDeviceType}
        layout="vertical"
        disabled={type === 'detail'}
      >
        <Form.Item
          label="类型名称"
          name="name"
          rules={[{ required: true, message: '请输入类型名称' }]}
        >
          <Input placeholder="请输入类型名称" />
        </Form.Item>
        <Form.Item label="类型ID" name="typeId">
          <InputNumber
            placeholder="仅执行机构需要输入"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label="备注" name="ps">
          <Input.TextArea placeholder="请输入备注" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
