/**
 * 保前查勘记录
 */
import React, { useEffect, useState } from 'react';
import { Card, Tag, message, Button, Popconfirm, Form, Row, Col, Input, Select } from 'antd';
import CommonTable from '@/components/commonTable';
import DatePicker from '@/components/datePicker';
import PermissionButton from '@/components/permissionButton';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { cateAllCompanyRequest } from '@/service/fleetRisk';
import { getCarExplorationRequest, getCarExplorationRequestPath, deleteCarExplorationRequest } from '@/service/carExploration';
import OperateModal from './components/operateModal';
import ResourcesModal from './components/resourcesModal';
import styles from '@/styles/pageContent.module.scss';
import { formatTime } from '@/utils/formatTime';
 
const CarExploration = () => {
  const [form] = Form.useForm();
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择数据源
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateParams, setOperateParams] = useState<any>({}); // 操作时对应的数据

  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: getCarExplorationRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 获取车队
  const getCateAllCompanyData = async () => {
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data 
    const aa: any = []
    data.forEach((item: any) => {
      aa.push({ label: item.title, value: item.id })
    })
    setAllCompany(aa)
  }

  useEffect(() => {
    getCateAllCompanyData();
  }, []);

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    if (params.time) {
      newParams.beginDate = formatTime(params.time[0], "YYYY-MM-DD");
      newParams.endDate = formatTime(params.time[1], "YYYY-MM-DD");
    }
    newParams.plateNumber = (params.plateNumber || "").trim();
    if (isExport) {
      newParams.export = true;
    }
    delete newParams.time;
    return newParams;
  };

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  }

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 删除保前查勘记录
  const deleteCarExploration = async(id: number) => {
    const res = await deleteCarExplorationRequest(id);
    if (res?.data?.data) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
    },
    {
      title: '任务状态',
      dataIndex: 'taskState',
      width: 110,
    },
    {
      title: '查勘日期',
      dataIndex: 'checkDate',
      width: 110,
    },
    {
      title: '业务模式',
      dataIndex: 'mode',
      width: 110,
    },
    {
      title: '车辆所有人',
      dataIndex: 'company',
      width: 110,
    },
    
    {
      title: '配单日期',
      dataIndex: 'date',
      width: 110,
    },
    {
      title: '承保保司',
      dataIndex: 'insurer',
      width: 110,
    },
    {
      title: '风险值',
      dataIndex: 'carRiskEvaluateScore',
      width: 110,
    },
    {
      title: '风险评估等级',
      dataIndex: 'carRiskEvaluateGrade',
      width: 110,
    },
    {
      title: '查勘结果',
      dataIndex: 'result',
      width: 110,
    },
    {
      title: '需求群信息',
      dataIndex: 'groupInfo',
      width: 110,
    },
    {
      title: '查勘联系电话',
      dataIndex: 'tel',
      width: 110,
    },
    {
      title: '评分',
      dataIndex: 'score',
      width: 110,
    },
    {
      title: '评级',
      dataIndex: 'grade',
      width: 110,
    },
    {
      title: '初登日期',
      dataIndex: 'firstRegistrationDate',
      width: 110,
    },
    {
      title: '出险次数',
      dataIndex: 'riskCount',
      width: 110,
    },
    {
      title: 'NCD系数',
      dataIndex: 'ncd',
      width: 110,
    },
    {
      title: '出险金额(元)',
      dataIndex: 'fee',
      width: 110,
    },
    {
      title: '个车赔付率',
      dataIndex: 'carLossRation',
      width: 110,
    },
    {
      title: '车队整体赔付率',
      dataIndex: 'companyLossRation',
      width: 110,
    },
    {
      title: '合同状态',
      dataIndex: 'contractState',
      width: 110,
    },
    {
      title: '设备状态',
      dataIndex: 'deviceState',
      width: 110,
    },
    {
      title: '承保类型',
      dataIndex: 'insureType',
      width: 110,
    },
    {
      title: '查勘人员',
      dataIndex: 'checker',
      width: 110,
    },
    {
      title: '实控人公司名称',
      dataIndex: 'practicalOwnerCompany',
      width: 110,
    },
    {
      title: '查勘地点',
      dataIndex: 'address',
      width: 110,
    },
    {
      title: '使用情况',
      dataIndex: 'useInfo',
      width: 110,
    },   
    {
      title: '小额案件是否自修情况',
      dataIndex: 'smallCaseDeal',
      width: 110,
    },
    {
      title: '是否挂靠',
      dataIndex: 'attachFlag',
      width: 110,
    },
    {
      title: '是否固定司机',
      dataIndex: 'fixedDriverFlag',
      width: 110,
    },
    {
      title: '是否固定线路',
      dataIndex: 'fixedLineFlag',
      width: 110,
    },
    {
      title: '是否专人管理',
      dataIndex: 'fixedManagerFlag',
      width: 110,
    },
    {
      title: '是否具有培训',
      dataIndex: 'trainFlag',
      width: 110,
    },
    {
      title: '使用频次',
      dataIndex: 'frequency',
      width: 110,
    },
    {
      title: '查勘时里程',
      dataIndex: 'beginMileage',
      width: 110,
    },
    {
      title: '用途',
      dataIndex: 'purpose',
      width: 110,
    },
    {
      title: '车牌是否有改装',
      dataIndex: 'plateRefitFlag',
      width: 110,
    },
    {
      title: '是否是空车且可上路测试',
      dataIndex: 'carTestFlag',
      width: 110,
    },
    {
      title: '图片资源',
      dataIndex: 'resources',
      width: 110,
      render: (value: any, item: any) => (
        <div className={styles.tableOperateColumn}>
          <Button type='link' onClick={() => setOperateParams({ type: 'resources', data: item })}>查看</Button>
        </div>
      )
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 110,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/car-exploration/edit'
              type="link"
              onClick={() => setOperateParams({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除此记录吗"
              onConfirm={() => deleteCarExploration(item.id)}
            >
              <PermissionButton
                operateType='/car-admin/car-exploration/delete'
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form form={form} onFinish={onSearch} autoComplete="off">
        <Row gutter={12} wrap>
          <Col span={6}>
            <Form.Item
              label="车队选择"
              name="company"
            >
             <Input placeholder="请输入车队名称" allowClear />
            </Form.Item>
          </Col>
        
          <Col span={6}>
            <Form.Item
              label="车牌号"
              name="plateNumber"
            >
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="任务状态"
              name="taskState"
            >
             <Input placeholder="输入任务状态" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
            >
              <DatePicker.RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <ExportButton
                  url={getCarExplorationRequestPath}
                  fileName='保前查勘记录'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/car-exploration/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/car-exploration/add'
          type="primary"
          onClick={() => setOperateParams({ type: 'add' })}
        >
          添加查勘记录
        </PermissionButton>
      </div>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 操作操作保前查勘记录弹窗 */}
      {['add', 'edit'].includes(operateParams.type) && (
        <OperateModal
          type={operateParams.type}
          data={operateParams.data}
          closeModal={() => setOperateParams({})}
          reloadData={getData}
        />
      )}
      {/* 查看图片资源 */}
      {operateParams.type === 'resources' && (
        <ResourcesModal
          data={operateParams.data}
          closeModal={() => setOperateParams({})}
        />
      )}
    </Card>
  )
}

export default CarExploration;