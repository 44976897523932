/**
 * axios 请求方法
 */
import axios, { AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import qs from 'qs';
import localStorageUtil from './localStorageUtil';
import { noMsgErrorCode } from '@/constants/requestErrorCode';
import store from '@/store';

const throttleErrorMessage: { [key: string]: boolean } = {}; // 相同接口报错节流控制

// token过期退出登录的逻辑
const logout = (msg: string) => {
  message.warning(msg || '请重新登录');
  store.dispatch({ type: 'resetTab' });
  store.dispatch({ type: 'logout', payload: { loginSuccessJumpPath: window.location.pathname } });
}

const request = axios.create({
  baseURL: '',
  timeout: 300000,
  paramsSerializer: function(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }
});

// 请求头添加access_token
request.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = localStorageUtil.getItem('access_token');
  if (accessToken && config.headers && config.url !== '/api/v2/system/login') {
    config.headers.access_token = accessToken;
  }
  return config;
})

request.interceptors.response.use(response => {
  const {
    data: { code, msg },
    config
  } = response;
  // code非0的请求特殊进行提示
  if (code && code !== 0) {
    if (code === 1404) {
      // 用户token过期(如果已经到登录页不再处理，防止error信息过多提示)
      if (!window.location.pathname.includes('/login')) {
        logout(msg);
      }
    } else if (
      !noMsgErrorCode.includes(code) &&
      !(config as any).noErrorMessage
    ) {
      // 避免相同接口报错重复提示
      if (!throttleErrorMessage[config.url as string]) {
        throttleErrorMessage[config.url as string] = true;
        setTimeout(() => {
          throttleErrorMessage[config.url as string] = false;
        }, 3000);
        // 提示接口返回的信息
        message.warning(msg);
      }
    }
  }
  return response;
}, error => {
  if (error.response?.status === 401) {
    if (!window.location.pathname.includes('/login')) {
      logout(error.response.data?.msg);
    } else if (error.config?.url === "/api/v2/system/login") {
      message.warning(error.response.data?.msg || '登录失败', 5);
    }
  } else {
    message.error(error.message || '请求失败，请重试');
  }
  return Promise.reject(error);
})

export default request;