/**
 * 部标机异常表格列配置
 */
import React from 'react';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { formatTime } from '@/utils/formatTime';
import { renderDifferentTableCell } from '@/utils/common';

// 渲染表格列内容
const renderColumnText = (value: number) => (value ? '是' : '否');

// 部标机异常表格列
export const getJtDeviceErrorColumns = (allData: any) => {
  return [
    {
      key: 'reportedAt',
      title: '上报时间',
      dataIndex: 'reportedAt',
      width: 80,
      render: (value: any) => (
        <RenderEllipsisColumn text={formatTime(value)} ellipsis />
      ),
      fixed: 'left' as const,
      noCommonRender: true,
      noCommonCell: true
    },
    {
      key: 'gnssError',
      title: 'GNSS模块发生故障',
      dataIndex: 'gnssError',
      width: 60
    },
    {
      key: 'gnssConnectionFailure',
      title: 'GNSS天线未接或被剪断',
      dataIndex: 'gnssConnectionFailure',
      width: 70
    },
    {
      key: 'gnssShortCircuit',
      title: 'GNSS天线短路',
      dataIndex: 'gnssShortCircuit',
      width: 60
    },
    {
      key: 'powerUndervoltage',
      title: '终端主电源欠压',
      dataIndex: 'powerUndervoltage',
      width: 60
    },
    {
      key:'powerDown',
      title: '终端主电源掉电',
      dataIndex: 'powerDown',
      width: 60
    },
    {
      title: '终端LCD或显示器故障',
      dataIndex: 'lcdError',
      width: 70
    },
    {
      title: '道路运输证IC卡模块故障',
      dataIndex: 'lcdError',
      width: 70
    },
    {
      title: 'TTS模块故障',
      dataIndex: 'ttsError',
      width: 70
    },
    {
      title: '摄像头故障',
      dataIndex: 'cameraError',
      width: 70
    },
    {
      title: '车辆VSS故障',
      dataIndex: 'vehicleVssError',
      width: 70
    },
    {
      title: '车辆油量异常',
      dataIndex: 'vehicleOilError',
      width: 70
    },
    {
      title: '车辆非法点火',
      dataIndex: 'vehicleLaunchIllegally',
      width: 70
    },
    {
      title: '车辆非法位移',
      dataIndex: 'vehicleMoveIllegally',
      width: 70
    },
    {
      title: '车辆被盗(通过车辆防盗器)',
      dataIndex: 'vehicleTheft',
      width: 70
    },
    {
      title: 'CANBUS接线',
      dataIndex: 'canBusWiring',
      width: 70
    },
    {
      title: 'CANBUS车速解析',
      dataIndex: 'canBusSpeedAnalysis',
      width: 70
    },
    {
      title: '定位速度与总线车速故障',
      dataIndex: 'carSpeedStatus',
      width: 70
    },
    {
      title: '执行机构故障',
      dataIndex: 'executingAgencyStatus',
      width: 70
    },
    {
      title: 'SD卡故障',
      dataIndex: 'sdCardStatus',
      width: 70
    },
    {
      title: 'SIM卡故障',
      dataIndex: 'simCardStatus',
      width: 70
    },
    {
      title: '惯导模块故障',
      dataIndex: 'inertialGuidanceModuleStatus',
      width: 70
    },
    {
      title: '定位模块故障',
      dataIndex: 'positioningModuleStatus',
      width: 70
    },
    {
      title: '前盲雷达故障',
      dataIndex: 'frontBlindRadarStatus',
      width: 70
    },
    {
      title: '右盲雷达故障',
      dataIndex: 'rightBlindRadarStatus',
      width: 70
    },
    {
      title: '左盲雷达故障',
      dataIndex: 'leftBlindRadarStatus',
      width: 70
    },
    {
      title: '后盲雷达故障',
      dataIndex: 'rearBlindRadarStatus',
      width: 70
    },
    {
      title: '前向雷达故障',
      dataIndex: 'forwardFacingRadarStatus',
      width: 70
    },
    {
      title: '前盲摄像头故障',
      dataIndex: 'frontBlindCameraStatus',
      width: 70
    },
    {
      title: '右盲摄像头故障',
      dataIndex: 'rightBlindCameraStatus',
      width: 70
    },
    {
      title: '左盲摄像头故障',
      dataIndex: 'leftBlindCameraStatus',
      width: 70
    },
    {
      title: '后盲摄像头故障',
      dataIndex: 'rearBlindCameraStatus',
      width: 70
    },
    {
      title: '前向摄像头故障',
      dataIndex: 'forwardFacingCameraStatus',
      width: 70
    },
    {
      title: '盲区制动主动屏蔽(人为)',
      dataIndex: 'blindBrakeManShieldStatus',
      width: 70
    },
    {
      title: '前向制动主动屏蔽(人为)',
      dataIndex: 'forwardBrakeManShieldStatus',
      width: 70
    },
    {
      title: '盲区制动平台屏蔽',
      dataIndex: 'blindBrakePlatformShieldStatus',
      width: 70
    },
    {
      title: '前向制动平台屏蔽',
      dataIndex: 'forwardBrakePlatformShieldStatus',
      width: 70
    },
    {
      title: ' 系统自检',
      dataIndex: 'systemSelfTest',
      width: 70
    }
  ].map((item: any) => {
    const returnItem = { ...item };
    const { noCommonRender, noCommonCell, dataIndex } = item;
    if (!noCommonRender) {
      returnItem.render = (value: any) => renderColumnText(value);
    }
    if (!noCommonCell) {
      returnItem.onCell = (record: any, rowIndex: number) =>
        renderDifferentTableCell(record, rowIndex, dataIndex, allData);
    }
    returnItem.key = dataIndex
    return returnItem;
  });
};

export const getVersionChangeColumns = (allData: any) => {
  return [
    {
      key: 'reportedTime',
      title: '上报时间',
      dataIndex: 'reportedTime',
      render: (value: any) => (
        <RenderEllipsisColumn text={formatTime(value)} ellipsis />
      ),
      fixed: 'left' as const,
      noCommonRender: true,
      noCommonCell: true
    },
    {
      key: 'createdAt',
      title: '创建时间',
      dataIndex: 'createdAt',
      render: (value: any) => (
        <RenderEllipsisColumn text={formatTime(value)} ellipsis />
      ),
      noCommonRender: true,
      noCommonCell: true
    },
    {
      key: 'hwVersion',
      title: '硬件版本',
      dataIndex: 'hwVersion',
      noCommonRender: true,
    },
    {
      key: 'swVersion',
      title: '软件版本',
      dataIndex: 'swVersion',
      noCommonRender: true,
    },
  ].map((item: any) => {
    const returnItem = { ...item };
    const { noCommonRender, noCommonCell, dataIndex } = item;
    if (!noCommonRender) {
      returnItem.render = (value: any) => renderColumnText(value);
    }
    if (!noCommonCell) {
      returnItem.onCell = (record: any, rowIndex: number) =>
        renderDifferentTableCell(record, rowIndex, dataIndex, allData);
    }
    returnItem.key = dataIndex
    return returnItem;
  });
}
