/**
 * AEB报警表格
 */
import React, { Fragment, useState, useMemo } from 'react';
import { Button } from 'antd';
import { aebAlarmColumns } from '@/columns/aebAlarm';
import AlarmInfoModal from '@/components/alarmInfoModal';
import CommonTable from '@/components/commonTable';
import styles from '@/styles/pageContent.module.scss';

interface AebAlarmTableProps {
  dataSource: any;
}
const AebAlarmTable = (props: AebAlarmTableProps) => {
  const { dataSource } = props;
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息

  // 表格列配置
  const columns = useMemo(() => {
    return [
      ...aebAlarmColumns,
      {
        title: '操作',
        dataIndex: 'operate',
        width: 90,
        fixed: 'right' as const,
        render: (value: any, item: any) => {
          return (
            <div className={styles.tableOperateColumn}>
              <Button
                type="link"
                onClick={() => setOperateInfo({ type: 'info', data: item })}
              >
                详细信息
              </Button>
            </div>
          );
        }
      }
    ];
  }, []);

  return (
    <Fragment>
      <CommonTable
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size="small"
        columnFilter={false}
      />
      {/* 详细信息弹窗 */}
      {operateInfo.type === 'info' && (
        <AlarmInfoModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
          infoKey="info"
        />
      )}
    </Fragment>
  );
};

export default AebAlarmTable;
