/**
 * 传感器方案-项目选择器
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { omit } from 'lodash';
import { sensorProjectListRequest } from '@/service/sensorProjectManage';

interface SensorProjectSelectProps extends SelectProps {
  value?: string; // 选择的项目
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  valueField?: string; // 作为选择器value的字段
}
const SensorProjectSelect = (props: SensorProjectSelectProps) => {
  const { value, onChange, valueField } = props;
  const [sensorProjectList, setSensorProjectList] = useState<any>([]); // 项目列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>(); // 选择的项目类型

  // 获取项目数据
  const getSensorProjectList = async () => {
    const res = await sensorProjectListRequest({
      page: 1,
      limit: 999
    });
    setSensorProjectList(res.data?.data || []);
  };

  useEffect(() => {
    getSensorProjectList();
  }, []);

  // 监听选择的项目进行赋值
  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const selectOwnProps = omit(props, 'valueField');
  return (
    <Select
      {...selectOwnProps}
      fieldNames={{
        label: 'name',
        value: valueField || 'id'
      }}
      optionFilterProp="name"
      allowClear
      showSearch
      options={sensorProjectList}
      onChange={onChange}
      value={selectValue}
      placeholder="请选择"
    />
  );
};

export default SensorProjectSelect;
