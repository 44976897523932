// 设备错误码对应显示文案
export const faultCodeData: any = {
  0: '正常',
  2: '2:相机异常',
  4: '4:相机内部看门狗错误',
  5: '5:相机FPGA端的内存出错',
  6: '6:相机没有remap数据',
  7: '7:相机ISP检查返回错误',
  11: '11:相机视差数据异常',
  31: '31:相机无法通过指定的CAN协议获取汽车信号',
  32: '32:相机无法获取雷达等的传感器信号',
  33: '33:相机通道0总线异常(仅表示发送异常)',
  34: '34:相机通道1总线异常(仅表示发送异常)',
  41: '41:相机IMU信号异常',
  45: '45:相机GPS信号异常',
  46: '46:相机4G信号异常',
  81: '81:相机高温保护',
  91: '91:相机被遮挡',
  128: '128:毫米波通讯异常',
  129: '129:OBD通讯异常',
  130: '130:超声波通讯异常',
  131: '131:AEBS控制器内部通讯异常',
  132: '132:AEBS与GPS通讯异常',
  133: '133:4G/GPS通讯故障'
}