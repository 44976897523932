/**
 * 围栏文件下载弹窗
 */
import React from 'react';
import { Form, Input, Modal } from 'antd';
import { downloadFile } from '@/utils/download';

interface DownloadModalProps {
  data: any; // 下载文件数据
  closeModal: () => void; // 关闭弹窗的方法
}
const DownloadModal = (props: DownloadModalProps) => {
  const { data, closeModal } = props;
  const [form] = Form.useForm();

  // 下载文件
  const download = async (values: any) => {
    const { version } = values;
    const { name, url } = data;
    const res = await downloadFile(url, `${name}_${version}.bin`);
    if (res) {
      closeModal();
    }
  };

  return (
    <Modal
      title="下载文件"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okText="下载"
    >
      <Form form={form} autoComplete="off" onFinish={download}>
        <Form.Item
          label="文件版本"
          name="version"
          rules={[{ required: true, message: '请输入文件版本' }]}
        >
          <Input placeholder="请输入文件版本" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DownloadModal;
