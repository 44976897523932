/**
 * 故障码筛选
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, Select, message } from 'antd';
import dayjs from 'dayjs';
import { faultCodeFilterRequest, faultCodeFilterRequestPath } from '@/service/faultCodeFilter';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import OperationGroupSelect from '@/components/operationGroupSelect';
import { faultCodeData } from '@/constants/faultCode';
import { showFaultCode } from '@/constants/chongming';
import { snColumnConfig, cateGroupColumnConfig, frameNumberColumnConfig, carBrandColumnConfig, carModelColumnConfig } from '@/constants/tableColumn';
import { operateDayRange } from '@/utils/operateSearchParams';
import { useTable } from '@/utils/useTable';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { getInnerPermissionEnable } from '@/utils/permission';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const FaultCodeFilter = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    cateId: cateIdArr,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: faultCodeFilterRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams,
  });

  // 崇明公交的故障码特殊显示
  const isChongming = !getInnerPermissionEnable('/car-admin/faultCode-filter/faultCode-normal');

  // 搜索
  const onSearch = async (values: any) => {
    if (values.cateId == '') {
      message.warning('分类选择不能为空')
    } else {
      if (isChongming) {
        // 崇明公交角色没有选择故障码时只显示平台可以展示的故障码(特殊处理请求的参数)
        if (!values.deviceStatus || !values.deviceStatus.length) {
          values.deviceStatus = showFaultCode;
        }
      }
      const newSearchParams = operateDayRange(values);
      setSearchParams(newSearchParams)
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    if (values.cateId == '') {
      message.warning('分类选择不能为空')
    } else {
      if (isChongming) {
        // 崇明公交角色没有选择故障码时只显示平台可以展示的故障码(特殊处理请求的参数)
        if (!values.deviceStatus || !values.deviceStatus.length) {
          values.deviceStatus = showFaultCode;
        }
      }
      return operateDayRange(values, true);
    }
  }

  // 渲染故障码列
  const renderfaultCodeColumn = (value: string) => {
    let displayText = value;
    if (typeof value === 'string') {
      let arr = value.split(',');
      arr = arr.map(item => faultCodeData[item] || item);
      displayText = arr.join(',');
    }
    return (
      <RenderEllipsisColumn text={displayText} />
    )
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId,
      })
    }
  }

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
    },
    snColumnConfig,
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
    },
    {
      title: '异常码',
      dataIndex: 'deviceStatus',
      width: 160,
      ellipsis: { showTitle: false },
      render: (value: string) => renderfaultCodeColumn(value)
    },
    cateGroupColumnConfig,
    frameNumberColumnConfig,
    carBrandColumnConfig,
    carModelColumnConfig,
    {
      title: '是否在线',
      dataIndex: 'online',
      width: 120,
    },
    {
      title: '最后上传日期',
      dataIndex: 'reportedDay',
      width: 120,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 260,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'contact', data: item })}>联系方式</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
          </div>
        )
      },
    },
  ];

  // 异常码选择框数据
  const faultCodeArray: any = [];
  Object.keys(faultCodeData).forEach(item => {
    if (Number(item)) {
      if (isChongming) {
        // 崇明公交角色只显示平台可以展示的故障码
        if (showFaultCode.includes(item)) {
          faultCodeArray.push({
            value: item,
            label: faultCodeData[item],
          });
        }
      } else {
        faultCodeArray.push({
          value: item,
          label: faultCodeData[item],
        })
      }
    }
  });
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout='vertical' form={form}>
        <Row gutter={12}>
          <Col span={5}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/faultCode-filter/show-operation-group'
            span={5}
          />
          <Col span={5}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cateId}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="异常码（如不勾选则查询所有异常码）" name="deviceStatus">
              <Select mode="multiple" showArrow optionLabelProp="value" maxTagCount="responsive">
                {faultCodeArray.map((item: any) => (
                  <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={faultCodeFilterRequestPath}
                  fileName='设备故障查询'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/faultCode-filter/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Card>
  )
}

export default FaultCodeFilter;
  