/**
 * 事故报告
 */
import React, {useState, useRef, useEffect} from "react";
import {Card, Collapse, Form, Row, Col, Input, Button, Descriptions, message, Select, Modal, Table} from 'antd';
import dayjs from "dayjs";
import gcoord from 'gcoord';
import html2canvas from 'html2canvas';
import DatePicker from "@/components/datePicker";
import LoadingWrapper from "@/components/loadingWrapper";
import ExportButton from "@/components/exportButton";
import RecorderVideoPreviewModal from '@/components/recorderVideoPreviewModal';
import CommonTable from '@/components/commonTable';
import {importAliMap} from '@/utils/map';
import {ownRound} from '@/utils/math';
import {renderEventType, showInfoWindow, renderGps} from '@/utils/driveAnalysis';
import {driveAnalysisRequest} from '@/service/driveAnalysis';
import {
  accidentIndexRequest,
  accidentReportRequestPath,
  drivingBehaviorStatisticRequest
} from '@/service/accidentReport';
import {driveBehaviorData, driveBehaviorValue} from '@/constants/driveBehavior';
import {accidentIndexThirtyDayDataDesc, accidentAccidentDataDesc} from '@/constants/accidentReport';
import styles from './index.module.scss';
import TableEditForm from "@/components/commonEditTable";
import {findOneCarRequest} from "@/service/carManage";

const {Panel} = Collapse;
const AccidentReport = () => {
  const [driveData, setDriveData] = useState<any>([]); // 驾驶行为数据
  const [commentItemData, setCommentItemData] = useState<any>(null); // 当前备注的数据
  const [loading, setLoading] = useState<boolean>(false); // 接口是否请求中
  const [activeKey, setActiveKey] = useState(['basic', 'analysis', 'conclusion']); // 展开的面板
  const [searchPlateNumber, setSearchPlateNumber] = useState<string>(''); // 搜索的车牌号
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息存储
  const [accidentIndexThirtyDayData, setAccidentIndexThirtyDayData] = useState<any>([]); // 事故报告统计30天的指标数据
  const [accidentAccidentData, setAccidentAccidentData] = useState<any>([]); // 事故报告统计结论
  const [mapImageBase64, setMapImageBase64] = useState<any>(); // 上传图片
  const [deviceHwmIndexValue, setDeviceHwmIndexValue] = useState<string>(''); // HWMIndex
  const [deviceHwmValue, setDeviceHwmValue] = useState<string>(''); // HWM
  const [deviceHwmStrValue, setDeviceHwmStrValue] = useState<string>(''); // HWMStr
  const [deviceOfflineValue, setDeviceOfflineValue] = useState<string>(''); // 离线
  const [deviceOfflineStrValue, setDeviceOfflineStrValue] = useState<string>(''); // 离线Str
  const [deviceE91Value, setDeviceE91Value] = useState<string>(''); // e91
  const [deviceE91StrValue, setDeviceE91StrValue] = useState<string>(''); //e91Str
  const [deviceE31Value, setDeviceE31Value] = useState<string>(''); // e31
  const [deviceE31StrValue, setDeviceE31StrValue] = useState<string>(''); // e31Str
  const [deviceE130Value, setDeviceE130Value] = useState<string>(''); // e130
  const [deviceE130StrValue, setDeviceE130StrValue] = useState<string>(''); // e130Str
  const [deviceE2Value, setDeviceE2Value] = useState<string>(''); // e2
  const [deviceE2StrValue, setDeviceE2StrValue] = useState<string>(''); // e2Str
  const [deviceVs0Value, setDeviceVs0Value] = useState<string>(''); // vs0
  const [deviceVs0StrValue, setDeviceVs0StrValue] = useState<string>(''); // vsStr
  const [dbsList, setDbsList] = useState<any>(); // 驾驶行为数据
  const [deviceStateValue, setDeviceStateValue] = useState<string>('事故发生前,设备功能运转: '); // 设备状态
  const [deviceFunctionValue, setDeviceFunctionValue] = useState<string>('事故发生时,设备无预警、无制动,(未触发预警与制动的原因)'); // 设备功能
  const [deviceTimeValue, setDeviceTimeValue] = useState<string>('根据平台数据分析，事故发生时间为  年 月 日 时 分，符合案发时间/与案发时间相差较大'); // 设备时间
  const [deviceAddressValue, setDeviceAddressValue] = useState<string>('根据平台数据分析，事故地点位于“ ”，符合案发地点/与案发地点距离较远'); // 设备位置
  const [deviceDmsValue, setDeviceDmsValue] = useState<string>('设备是否存在DMS人脸: '); // DMS设备
  const [deviceOtherValue, setDeviceOtherValue] = useState<string>('夜间驾驶、长时间驾驶等'); // 设备其他
  const [statisticsData, setStatisticsData] = useState<any>({}); // 设备数据统计
  const [historyColumnsValue, setHistoryColumnsValue] = useState<any>([]); //历史运维
  const [adviseColumnsValue, setAdviseColumnsValue] = useState<any>([]); //建议
  const [HMWValue, setHMWValue] = useState<any>([{}])// HMW
  const mapRef = useRef<any>(null); // 创建的地图实例
  const [form] = Form.useForm();

  useEffect(() => {
    importAliMap(initMap);

  }, [])

  // 初始化地图
  const initMap = async () => {
    try {
      mapRef.current = new window.AMap.Map('map', {
        center: [116.368904, 39.913423],
        zoom: 13,
        animateEnable: false,
        WebGLParams: {
          preserveDrawingBuffer: true
        }
      });
      // 加载插件
      window.AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.MapType'], function () {
        mapRef.current.addControl(new window.AMap.ToolBar());
        mapRef.current.addControl(new window.AMap.Scale());
        mapRef.current.addControl(new window.AMap.MapType());
      });
    } catch (error) {
      message.error('地图初始化失败');
    }
  }

  // 地图上标出报警点位、车身信息、记录仪信息
  const drawMarker = (array: any, clipPosition: number) => {
    // 用于承载LabelMarker的图层
    const labelsLayer = new window.AMap.LabelsLayer({
      collision: false,
      allowCollision: false,
      zooms: [0, 20],
    });
    array.forEach((item: any) => {
      const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
      const lnglat = new window.AMap.LngLat(transformGcoord[0], transformGcoord[1]);
      // 创建一个 LabelMarker 实例
      const alarmMarker = new window.AMap.LabelMarker({
        position: lnglat,
        icon: {
          image: 'https://webapi.amap.com/ui/1.1/ui/overlay/SimpleMarker/assets/default/default-fs8.png',
          anchor: 'bottom-center',
          size: [25, 34],
          clipOrigin: [clipPosition, 0],
          clipSize: [36, 46]
        },
      });
      alarmMarker.originData = item;
      alarmMarker.on('click', (point: any) => showInfoWindow(point, mapRef));
      // 将 LabelMarker 实例添加到 LabelsLayer 上
      labelsLayer.add(alarmMarker);
    })
    // 将 LabelsLayer 添加到地图上
    mapRef.current.add(labelsLayer);
  }

  // 获取事故报告指标
  const getAccidentIndex = async (values: any) => {
    try {
      const params = {...values};
      params.date = dayjs(values.time).format('YYYY-MM-DD');
      delete params.time;
      const res = await accidentIndexRequest(params);
      const resData = res.data?.data || {};
      setDeviceHwmValue(resData.hmwPer100km.first)
      setDeviceHwmStrValue(resData.hmwPer100km.second)
      setDeviceOfflineValue(resData.offline.first)
      setDeviceOfflineStrValue(resData.offline.second)
      setDeviceE91Value(resData.e91.first)
      setDeviceE91StrValue(resData.e91.second)
      setDeviceE31Value(resData.e31.first)
      setDeviceE31StrValue(resData.e31.second)
      setDeviceE130Value(resData.e130.first)
      setDeviceE2Value(resData.e2.first)
      setDeviceE2StrValue(resData.e2.second)
      setDeviceE130StrValue(resData.e130.second)
      setDeviceVs0Value(resData.vs0.first)
      setDeviceVs0StrValue(resData.vs0.second)
      const accidentIndexThirtyDay = accidentIndexThirtyDayDataDesc.map((item: any) => ({
        ...item,
        resultValue: resData[item.field]?.first,
        conclude: resData[item.field]?.second,
      }));
      const accidentAccident = accidentAccidentDataDesc.map((item: any) => ({
        ...item,
        resultValue: resData[item.field]?.first,
        conclude: resData[item.field]?.second,
      }));
      setAccidentIndexThirtyDayData(accidentIndexThirtyDay);
      setAccidentAccidentData(accidentAccident)
    } catch (error) {
    }
  }

  //获取车辆的基本信息及驾驶行为统计信息
  const getCarInfo = async (values: any) => {
    try {
      const params = {...values};
      params.date = dayjs(values.time).format('YYYY-MM-DD');
      delete params.time;
      const ress = await drivingBehaviorStatisticRequest(params)
      const resDataa = ress.data?.data || {};
      //设备数据统计
      setStatisticsData({
        fcw: ress.data?.data.fcw,
        bin: ress.data?.data.bin,
        sts: ress.data?.data.sts,
        ldw: ress.data?.data.ldw,
        hmw: ress.data?.data.hmw,
        gps: ress.data?.data.gps,
        sign: ress.data?.data.sign,
        rtb: ress.data?.data.rtb,
      });
      //对表单进行赋值
      form.setFieldsValue({
        frameNumber: resDataa.frameNumber,
        engineNumber: resDataa.engineNumber,
        device: resDataa.device,
        company: resDataa.company,
        address: resDataa.address,
        caseNumber: resDataa.caseNumber,
      });

    } catch (error) {
    }
  }

  // 获取驾驶行为分析数据
  const getAnalysisData = async () => {
    try {
      const values = await form.validateFields(['plateNumber', 'analysisTime', 'type']);
      const {plateNumber, analysisTime, type} = values;
      if (plateNumber !== searchPlateNumber) {
        // 搜索的车牌号变化后，置空事故相关字段
        form.setFieldsValue({conclusion: '', caseNumber: "", insured: "", reason: ""});
      }
      const requestParams = {
        page: 1,
        limit: 99999,
        plateNumber: plateNumber.trim(),
        type,
        beginDate: dayjs(analysisTime[0]).format("YYYY-MM-DD HH:mm:ss"),
        endDate: dayjs(analysisTime[1]).format("YYYY-MM-DD HH:mm:ss"),
      };
      setSearchPlateNumber(plateNumber);
      setLoading(true);
      mapRef.current.clearMap();
      // 获取车辆详情
      const carRes = await findOneCarRequest({plateNumber});
      const carResData = carRes.data.data || {};
      const carId = carResData.id;
      const res = await driveAnalysisRequest({...requestParams, carId});
      const analysisData = res.data?.data || [];
      if (analysisData.length) {
        setDriveData(analysisData);
        const getDbsList: any = [];
        const gpsData: any = [];
        const alarmData: any = [];
        const signData: any = [];
        const modemData: any = [];
        analysisData.forEach((item: any) => {
          getDbsList.push({time: item.reportedTime, type: item.typeDesc, speed: item.gpsSpeed, desc: item.descText});
          setDbsList(getDbsList)
          if (item.lat && item.lon) {
            if (item.type === "gps") {
              gpsData.push(item);
            } else if (item.type === "signInfo") {
              signData.push(item);
            } else if (item.type === "alarm") {
              alarmData.push(item);
            } else if (item.type === "modem") {
              modemData.push(item);
            }
          }
        });
        gpsData.length && renderGps(gpsData, mapRef, false, true);
        alarmData.length && drawMarker(alarmData, 0);
        signData.length && drawMarker(signData, 108);
        modemData.length && drawMarker(modemData, 36);
        let fcw = 0, aeb = 0, ldw = 0, hmw = 0, sstop = 0, rightBreak = 0;
        alarmData.forEach((item: any) => {
          const numberType = Number(item.alarmType);
          if (numberType === 1) {
            fcw++;
          } else if (numberType === 2) {
            aeb++;
          } else if (numberType === 3) {
            sstop++;
          } else if (numberType === 4) {
            ldw++;
          } else if (numberType === 5) {
            hmw++
          } else if (numberType === 8) {
            rightBreak++
          }
        });
        // 确定地图中心点(根据选择的数据类型不同动态确定地图的中心点)
        const centerPositionData = gpsData[0] || alarmData[0] || signData[0] || modemData[0] || {};
        const {lat, lon} = centerPositionData;
        if (lat && lon) {
          const transformCenterPosition = gcoord.transform([lat, lon], gcoord.WGS84, gcoord.AMap);
          mapRef.current.setCenter(new window.AMap.LngLat(transformCenterPosition[0], transformCenterPosition[1]))
        }
      } else {
        message.warning("该时段未查询到数据");
        setDriveData([]);
        setStatisticsData({});
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // 搜索
  const onSearch = async () => {
    setAccidentAccidentData([])
    setMapImageBase64(null)
    setDeviceHwmIndexValue(HMWValue)
    setDeviceHwmValue('')
    setDeviceHwmStrValue('')
    setDeviceOfflineValue('')
    setDeviceOfflineStrValue('')
    setDeviceE91Value('')
    setDeviceE91StrValue('')
    setDeviceE31Value('')
    setDeviceE31StrValue('')
    setDeviceE130Value('')
    setDeviceE130StrValue('')
    setDeviceE2Value('')
    setDeviceE2StrValue('')
    setDeviceVs0Value('')
    setDeviceVs0StrValue('')
    setDbsList(null)
    setDeviceStateValue('事故发生前,设备功能运转: ')
    setDeviceFunctionValue('事故发生时,设备无预警、无制动,(未触发预警与制动的原因)')
    setDeviceTimeValue('根据平台数据分析，事故发生时间为  年 月 日 时 分，符合案发时间/与案发时间相差较大')
    setDeviceAddressValue('根据平台数据分析，事故地点位于“ ”，符合案发地点/与案发地点距离较远')
    setDeviceDmsValue('设备是否存在DMS人脸: ')
    setDeviceOtherValue('夜间驾驶、长时间驾驶等')
    setStatisticsData({})
    setHistoryColumnsValue([{id: 1}])
    setAdviseColumnsValue([{id: 1}])
    try {
      const values = await form.validateFields(['plateNumber', 'time', 'hmwIndex']);
      const rangeTime = [dayjs(values.time).subtract(5, 'minutes'), dayjs(values.time).subtract(-5, 'minutes')]
      form.setFieldsValue({analysisTime: rangeTime});
      getAccidentIndex(values);
      getCarInfo(values)
      getAnalysisData();
    } catch (error) {
    }
  };

  //上传图片监听粘贴事件
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    //获取剪贴板中的图片数据
    const items = event.clipboardData?.items;
    const img: any = document.getElementById("img")
    if (!items) return;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        //将图片数据转换为 Blob 对象，并创建一个 URL
        if (blob) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setMapImageBase64(reader.result as string);
            // setMapImageBase64(imageData)
            img.src = reader.result
          };
          reader.readAsDataURL(blob);
          break;
        }
      }
    }
    event.preventDefault();
  };

  // 处理车速的显示
  const formatSpeed = (item: any) => {
    if (!item.type) {
      return '';
    }
    if (item.type === "gps") {
      return ownRound(item.gpsSpeed, 1);
    } else if (item.type === "modem") {
      return "";
    } else {
      return ownRound(item.vehicleSpeed, 1);
    }
  }

  // 渲染描述列
  const renderDescText = (item: any, index: number) => {
    if (item.type === 'modem') {
      return (
        <div className={styles.tableOperateColumn}>
          <Button
            type="link"
            onClick={(e: any) => {
              e.stopPropagation();
              setOperateInfo({type: 'video', data: item, index})
            }}
          >
            播放
          </Button>
        </div>
      )
    } else {
      return item.descText;
    }
  }

  // 表格行点击
  const onTableRowClick = (item: any) => {
    if (item.lat && item.lon) {
      const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.AMap);
      const lnglat = new window.AMap.LngLat(transformGcoord[0], transformGcoord[1]);
      const currentPoint = {
        lnglat,
        target: {originData: item}
      }
      showInfoWindow(currentPoint, mapRef, true)
    }
  }

  // 点击评论
  const commentClick = (item: any, e: any) => {
    e.stopPropagation();
    setCommentItemData(item)
  }

  // 添加评论
  const addComment = async () => {
    try {
      const values = await form.validateFields(['comment']);
      const commentStr = `时间: ${commentItemData.reportedTime} 类型: ${renderEventType(commentItemData)} 车速: ${formatSpeed(commentItemData)} 备注：${values.comment}\n`;
      form.setFieldsValue({conclusion: `${form.getFieldValue('conclusion') || ''}${commentStr}`});
      setCommentItemData(null);
      form.resetFields(['comment']);
    } catch (error) {
    }
  }

  // 获取导出的参数
  const getExportParams = async () => {
    try {
      const accidentAccidentList: any = []
      accidentAccidentData.map((item: any) => {
        if (item.field == 'state') {
          accidentAccidentList.push({type: item.type, result: deviceStateValue})
        } else if (item.field == 'function') {
          accidentAccidentList.push({type: item.type, result: deviceFunctionValue})
        } else if (item.field == 'time') {
          accidentAccidentList.push({type: item.type, result: deviceTimeValue})
        } else if (item.field == 'address') {
          accidentAccidentList.push({type: item.type, result: deviceAddressValue})
        } else if (item.field == 'dms') {
          accidentAccidentList.push({type: item.type, result: deviceDmsValue})
        } else if (item.field == 'other') {
          accidentAccidentList.push({type: item.type, result: deviceOtherValue})
        }
      })

      const values: any = await form.validateFields();
      const {
        plateNumber, time, insured, reason, conclusion, caseNumber,
        frameNumber, engineNumber, company, device, deviceAlarmDescribe, point, deviceTroubleDescribe,
        deviceRiskTimeDescribe, oamdescribe, address
      } = values;
      setActiveKey(['basic', 'analysis', 'conclusion'])
      return new Promise((resolve) => {
        setTimeout(async () => {
          const params = {
            mapScreenshot: mapImageBase64 || '',
            plateNumber: searchPlateNumber || plateNumber,
            time: dayjs(time).format('YYYY-MM-DD HH:mm:ss'),
            caseNumber: caseNumber || "",
            insured: insured || "",
            reason: reason || "",
            address: address || "",
            frameNumber: frameNumber || "",
            engineNumber: engineNumber || "",
            company: company || "",
            device: device || "",
            conclusion, // 结论
            allData: driveData, //驾驶行为数据
            ...statisticsData, // 设备数据统计
            deviceAlarmDescribe: deviceAlarmDescribe || '', // 设备预警情况判断
            point: point || '',//案发地点判断
            deviceTroubleDescribe: deviceTroubleDescribe || '',// 设备是否存在故障判断
            deviceRiskTimeDescribe: deviceRiskTimeDescribe || '',// 车辆出险时间判断
            oamdescribe: oamdescribe || '', //运维历史与沟通判断
            hmwPer100kmStr: deviceHwmStrValue || '', //HWM
            hmwPer100km: deviceHwmValue,
            hmwIndex: deviceHwmIndexValue,
            offline: deviceOfflineValue, // 离线
            offlineStr: deviceOfflineStrValue || '',
            e91: deviceE91Value, //e91
            e91Str: deviceE91StrValue || '',
            e31: deviceE31Value,
            e31Str: deviceE31StrValue || '',
            e130: deviceE130Value,
            e130Str: deviceE130StrValue || '',
            e2: deviceE2Value,
            e2Str: deviceE2StrValue || '',
            vs0: deviceVs0Value,
            vs0Str: deviceVs0StrValue || '',
            dbs: dbsList,
            cls: accidentAccidentList,
            sugs: adviseColumnsValue,
            oams: historyColumnsValue,

          }
          resolve(params);
        })
      });
    } catch (error) {
      return null;
    }
  }

  // 视频转码成功后修改源数据
  const transferCallback = (newUrl: string) => {
    const newData: any = [];
    driveData.forEach((item: any, index: number) => {
      if (index === operateInfo.index) {
        newData.push({...item, needTrans: false, attachUrl: newUrl})
      } else {
        newData.push(item);
      }
    })
    setDriveData(newData);
  }

  // 表格列配置
  const analysisColumns = [
    {title: '时间', dataIndex: 'reportedTime', width: 180},
    {
      title: '类型',
      dataIndex: 'alarmTypeStr',
      width: 140
    },
    {
      title: '车速(km/h)',
      dataIndex: 'vehicleSpeed',
      width: 120,
      render: (value: number, item: any) => formatSpeed(item)
    },
    {
      title: '描述',
      dataIndex: 'descText',
      render: (value: number, item: any, index: number) => renderDescText(item, index)
    },
    // {
    //   title: '操作',
    //   width: 100,
    //   dataIndex: 'operate',
    //   render: (value: number, item: any) => <div className={styles.tableOperateColumn}>
    //     <Button type="link" onClick={(e: any) => commentClick(item, e)}>备注</Button>
    //   </div>
    // },
  ];

  // 统计指标表格列
  const indexColumns = [
    {
      title: '类型',
      dataIndex: 'type',
      width: 120,
    },
    {
      title: '异常值',
      dataIndex: 'normalValue',
      width: 120,
      render: (value: any, item: any) =>
        item?.field === 'hmwPer100km' ?
          <span>
             HMW ≥ <Input style={{width: '100px'}} value={deviceHwmIndexValue}
                          onChange={(e: any) => setDeviceHwmIndexValue(e.target.value)}/>
      </span>
          :
          value,
    },
    {
      title: '结果值',
      dataIndex: 'resultValue',
      width: 120,
      render: (value: any, item: any) => item?.field === 'hmwPer100km' ?
        <Input defaultValue={value} onChange={(e: any) => setDeviceHwmValue(e.target.value)}/> :
        item?.field === 'offline' ?
          <Input defaultValue={value} onChange={(e: any) => setDeviceOfflineValue(e.target.value)}/> :
          item?.field === 'e91' ?
            <Input defaultValue={value} onChange={(e: any) => setDeviceE91Value(e.target.value)}/> :
            item?.field === 'e31' ?
              <Input defaultValue={value} onChange={(e: any) => setDeviceE31Value(e.target.value)}/> :
              item?.field === 'e130' ?
                <Input defaultValue={value} onChange={(e: any) => setDeviceE130Value(e.target.value)}/> :
                item?.field === 'e2' ?
                  <Input defaultValue={value} onChange={(e: any) => setDeviceE2Value(e.target.value)}/> :
                  <Input defaultValue={value} onChange={(e: any) => setDeviceVs0Value(e.target.value)}/>
    },
    {
      title: '风险结论',
      dataIndex: 'conclude',
      width: 120,
      render: (value: any, item: any) => item?.field === 'hmwPer100km' ?
        <Input defaultValue={value} onChange={(e: any) => setDeviceHwmStrValue(e.target.value)}/> :
        item?.field === 'offline' ?
          <Input defaultValue={value} onChange={(e: any) => setDeviceOfflineStrValue(e.target.value)}/> :
          item?.field === 'e91' ?
            <Input defaultValue={value} onChange={(e: any) => setDeviceE91StrValue(e.target.value)}/> :
            item?.field === 'e31' ?
              <Input defaultValue={value} onChange={(e: any) => setDeviceE31StrValue(e.target.value)}/> :
              item?.field === 'e130' ?
                <Input defaultValue={value} onChange={(e: any) => setDeviceE130StrValue(e.target.value)}/> :
                item?.field === 'e2' ?
                  <Input defaultValue={value} onChange={(e: any) => setDeviceE2StrValue(e.target.value)}/> :
                  <Input defaultValue={value} onChange={(e: any) => setDeviceVs0StrValue(e.target.value)}/>
    },
  ]

  //历史运维
  const historyIndex = (e: any, index: any) => {
    const aaa = historyColumnsValue
    const bbb = [...aaa, {}]
    bbb[index].index = e
    setHistoryColumnsValue(aaa.slice(0, aaa.length + 1))
  }

  const historyRecord = (e: any, index: any) => {
    const aaa = historyColumnsValue
    const bbb = [...aaa, {}]
    bbb[index].record = e
    setHistoryColumnsValue(aaa.slice(0, aaa.length + 1))
  }

  const historyDate = (e: any, index: any) => {
    const aaa = historyColumnsValue
    const bbb = [...aaa, {}]
    bbb[index].date = e
    setHistoryColumnsValue(aaa.slice(0, aaa.length + 1))
  }

  const historySpeed = (e: any, index: any) => {
    const aaa = historyColumnsValue
    const bbb = [...aaa, {}]
    bbb[index].speed = e
    setHistoryColumnsValue(aaa.slice(0, aaa.length + 1))
  }

  const historyConclusion = (e: any, index: any) => {
    const aaa = historyColumnsValue
    const bbb = [...aaa, {}]
    bbb[index].conclusion = e
    setHistoryColumnsValue(aaa.slice(0, aaa.length + 1))
  }

  const addHistoryColumns = (index: any) => {
    const aaa = historyColumnsValue
    //得到aaa 最后一项  id + 1
    const ind = aaa[aaa.length - 1]?.id + 1
    setHistoryColumnsValue([...aaa, {id: ind}])
  }

  const deleteHistoryColumns = (index: any) => {
    const aaa = historyColumnsValue
    //删除点击的对应那条数据
    const newData = aaa.filter((item: any) => item.id !== index + 1);
    setHistoryColumnsValue(newData)
  }

  //车辆历史运维/沟通记录表格列
  const historyColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 120,
      render: (value: any, item: any, index: any) => <Input value={historyColumnsValue[index]?.index}
                                                            onChange={(e: any) => historyIndex(e.target.value, index)}/>
    },
    {
      title: '运维/沟通记录',
      dataIndex: 'record',
      width: 120,
      render: (value: any, item: any, index: any) => <Input value={historyColumnsValue[index]?.record}
                                                            onChange={(e: any) => historyRecord(e.target.value, index)}/>
    },
    {
      title: '日期',
      dataIndex: 'date',
      width: 120,
      render: (value: any, item: any, index: any) => <Input value={historyColumnsValue[index]?.date}
                                                            onChange={(e: any) => historyDate(e.target.value, index)}/>
    },
    {
      title: '处理过程',
      dataIndex: 'speed',
      width: 120,
      render: (value: any, item: any, index: any) => <Input value={historyColumnsValue[index]?.speed}
                                                            onChange={(e: any) => historySpeed(e.target.value, index)}/>
    },
    {
      title: '结论',
      dataIndex: 'conclusion',
      width: 120,
      render: (value: any, item: any, index: any) => <Input value={historyColumnsValue[index]?.conclusion}
                                                            onChange={(e: any) => historyConclusion(e.target.value, index)}/>
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {/* 只要不是最后一项 其余的新增按钮都为禁用 */}
            {index !== historyColumnsValue.length - 1 ? <Button type="primary" disabled>新增</Button> : <Button
              type="primary" onClick={() => addHistoryColumns(index)}>新增</Button>}
            {/* 如果historyColumnsValue长度为1 时候 删除按钮为禁用模式 */}
            {historyColumnsValue.length === 1 ? <Button disabled>删除</Button> :
              <Button type="primary" danger onClick={() =>
                deleteHistoryColumns(index)}>删除</Button>}
          </div>
        )
      },
    },
  ]

  //结论表格列
  const conclusionColumns = [
    {
      title: '类型',
      width: 120,
      dataIndex: 'type',
    },
    {
      title: '分析结果',
      width: 120,
      render: (value: any, item: any) => item?.field === 'state' ?
        <Input value={deviceStateValue} onChange={(e: any) => setDeviceStateValue(e.target.value)}/> :
        item?.field === 'function' ?
          <Input value={deviceFunctionValue} onChange={(e: any) => setDeviceFunctionValue(e.target.value)}/> :
          item?.field === 'time' ?
            <Input value={deviceTimeValue} onChange={(e: any) => setDeviceTimeValue(e.target.value)}/> :
            item?.field === 'address' ?
              <Input value={deviceAddressValue} onChange={(e: any) => setDeviceAddressValue(e.target.value)}/> :
              item?.field === 'dms' ?
                <Input value={deviceDmsValue} onChange={(e: any) => setDeviceDmsValue(e.target.value)}/> :
                item?.field === 'other' ?
                  <Input value={deviceOtherValue} onChange={(e: any) => setDeviceOtherValue(e.target.value)}/>
                  : value,
    },
  ]

  //建议
  const adviceIndex = (e: any, index: any) => {
    const aaa = adviseColumnsValue
    const bbb = [...aaa, {}]
    bbb[index].index = e
    setAdviseColumnsValue(aaa.slice(0, aaa.length + 1)) //截取数组第一项
  }

  const adviceResult = (e: any, index: any) => {
    const aaa = adviseColumnsValue
    const bbb = [...aaa, {}]
    bbb[index].result = e
    setAdviseColumnsValue(aaa.slice(0, aaa.length + 1))
  }

  const addAdviseColumns = (index: any) => {
    const aaa = adviseColumnsValue
    const ind = aaa[aaa.length - 1]?.id + 1
    setAdviseColumnsValue([...aaa, {id: ind}])
  }

  const deleteAdviseColumns = (index: any) => {
    const aaa = adviseColumnsValue
    const newData = aaa.filter((item: any) => item.id !== index + 1);
    setAdviseColumnsValue(newData)
  }

  //建议表格列
  const adviseColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 120,
      render: (value: any, item: any, index: any) => <Input value={adviseColumnsValue[index]?.index}
                                                            onChange={(e: any) => adviceIndex(e.target.value, index)}/>
    },
    {
      title: '建议情况',
      dataIndex: 'result',
      width: 120,
      render: (value: any, item: any, index: any) => <Input value={adviseColumnsValue[index]?.result}
                                                            onChange={(e: any) => adviceResult(e.target.value, index)}/>
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {/* 只要不是最后一项 其余的新增按钮都为禁用 */}
            {adviseColumnsValue.length !== 1 ? <Button type="primary" onClick={() => addAdviseColumns(index)}
                                                       disabled={adviseColumnsValue.length === 1 ? false : true}
            >新增</Button> : <Button disabled>新增</Button>}
            {/* 如果adviseColumnsValue长度为1 时候 删除按钮为禁用模式 */}
            {adviseColumnsValue.length === 1 ? <Button disabled>删除</Button> :
              <Button type="primary" danger onClick={() =>
                deleteAdviseColumns(index)}>删除</Button>}
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.reportBox}>
      <Form layout="vertical" autoComplete='off' form={form} scrollToFirstError
            initialValues={{managementCode: [{}], adviseColumnsCode: [{}]}}>
        <Collapse
          activeKey={activeKey}
          onChange={(keys: any) => {
            setActiveKey(keys)
          }}>
          <Panel header="基本信息" key="basic">
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item
                  label="设备车辆"
                  name="plateNumber"
                  required
                  rules={[
                    {required: true, message: '请输入车牌号'}
                  ]}
                >
                  <Input placeholder='事故车牌号（必填）'/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="报案时间"
                  name="time"
                  required
                  rules={[
                    {required: true, message: '请选择报案时间'}
                  ]}
                >
                  <DatePicker placeholder="选择报案时间（必填）" showTime style={{width: '100%'}}/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="HMW最小阀值"
                  name="hmwIndex"
                  required
                  rules={[
                    {required: true, message: '请输入HMW值'}
                  ]}>
                  <Input placeholder='请输入HMW值(必填)' onChange={(e: any) => setHMWValue(e.target.value)}/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="报案号" name="caseNumber">
                  <Input placeholder='请输入报案号'/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车架号" name="frameNumber">
                  <Input placeholder='请输入车架号' onChange={(e) => {
                    const newV = e.target.value.replace(/\s+/g, '');
                    form.setFieldValue('frameNumber', newV)
                  }}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="车辆被保险人" name="company">
                  <Input placeholder='请输入车辆被保险人'/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="安装设备" name="device">
                  <Input placeholder='请输入安装设备'/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="发动机号" name="engineNumber">
                  <Input placeholder='请输入发动机号'/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="报案位置" name="address">
                  <Input placeholder='请输入报案位置'/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="报案原因" name="reason">
                  <Input placeholder='请输入报案原因'/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="操作" shouldUpdate>
                  <div className={styles.searchBox}>
                    <Button type="primary" onClick={onSearch}>查询</Button>
                    <ExportButton
                      url={accidentReportRequestPath}
                      fileName={`${form.getFieldsValue().plateNumber}_事故报告`}
                      getParamsFunc={getExportParams}
                      htmlType="submit"
                      fileSuffix="docx"
                      permissionKey="/car-admin/accident-report/export"
                    >
                      导出为Word
                    </ExportButton>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel header="驾驶行为分析" key="analysis">
            <Row gutter={24}>
              <Col span={14}>
                <Form.Item name="type" initialValue={driveBehaviorValue}>
                  <Select mode="multiple" options={driveBehaviorData}/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="analysisTime" rules={[{required: true, message: '请选择时间'}]}>
                  <DatePicker.RangePicker showTime style={{width: '100%'}}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button type="primary" onClick={getAnalysisData}>查询</Button>
              </Col>
            </Row>
            <LoadingWrapper spinning={loading}>
              <div id="map" className={styles.analysisMap}/>
              <input placeholder="请上传发生时GPS轨迹" onPaste={handlePaste} style={{border: "none"}}/>
              <br/>
              <img id="img" src="" alt=""/>
              <div>
                <Form.Item
                  label="案发地点判断："
                  name="point">
                  <Input.TextArea placeholder='请输入案发地点判断' rows={2}/>
                </Form.Item>
              </div>
              <div id="statistics">
                <div className={styles.innerTitle}>设备数据统计</div>
                <Descriptions bordered column={4}>
                  <Descriptions.Item label="FCW预警数">{statisticsData.fcw}</Descriptions.Item>
                  <Descriptions.Item label="双目制动数">{statisticsData.bin}</Descriptions.Item>
                  <Descriptions.Item label="LDW预警数">{statisticsData.ldw}</Descriptions.Item>
                  <Descriptions.Item label="HMW预警数">{statisticsData.hmw}</Descriptions.Item>
                  <Descriptions.Item label="GPS统计数">{statisticsData.gps}</Descriptions.Item>
                  <Descriptions.Item label="车身信息统计数">{statisticsData.sign}</Descriptions.Item>
                  <Descriptions.Item label="起步阻止数">{statisticsData.sts}</Descriptions.Item>
                  <Descriptions.Item label="右转制动数">{statisticsData.rtb}</Descriptions.Item>
                </Descriptions>
                <div>
                  <Form.Item
                    label="设备预警情况判断："
                    name="deviceAlarmDescribe">
                    <Input.TextArea placeholder='请输入设备预警情况判断' rows={2}/>
                  </Form.Item>
                </div>
              </div>
              <div className={styles.innerTitle}>统计指标(统计过往30天数据)</div>
              <CommonTable
                columnFilter={false}
                columns={indexColumns}
                dataSource={accidentIndexThirtyDayData}
                pagination={false}
                scroll={{y: 500}}
                bordered
              />
              <div>
                <Form.Item
                  label="设备是否存在故障判断："
                  name="deviceTroubleDescribe">
                  <Input.TextArea placeholder='请输入设备是否存在故障判断' rows={2}/>
                </Form.Item>
              </div>
              <div className={styles.innerTitle}>驾驶行为数据</div>
              <CommonTable
                columnFilter={false}
                columns={analysisColumns}
                dataSource={driveData}
                pagination={false}
                scroll={{y: 400}}
                bordered
                onRow={(record: any) => {
                  return {
                    onClick: () => onTableRowClick(record), // 点击行
                  };
                }}
              />
              <div>
                <Form.Item
                  label="设备出险时间判断："
                  name="deviceRiskTimeDescribe">
                  <Input.TextArea placeholder='请输入设备出险时间判断' rows={2}/>
                </Form.Item>
              </div>
              <div className={styles.innerTitle}>车辆历史运维/沟通记录</div>
              <Table columns={historyColumns} dataSource={historyColumnsValue}/>
              <div>
                <Form.Item
                  label="运维历史与沟通判断："
                  name="oamdescribe">
                  <Input.TextArea placeholder='请输入运维历史与沟通判断' rows={2}/>
                </Form.Item>
              </div>
            </LoadingWrapper>
          </Panel>
          <Panel header="结论以及建议" key="conclusion">
            <Form.Item
              label="设备介入结论"
              name="">
              <CommonTable
                columnFilter={false}
                columns={conclusionColumns}
                dataSource={accidentAccidentData}
                pagination={false}
                scroll={{y: 500}}
                bordered
              />
            </Form.Item>
            <Form.Item
              label="建议"
              name="">
              <Table columns={adviseColumns} dataSource={adviseColumnsValue}/>
            </Form.Item>
          </Panel>
        </Collapse>
        {commentItemData && <Modal
          title="备注"
          visible={commentItemData}
          onCancel={() => setCommentItemData(null)}
          onOk={addComment}
          width={640}
          centered
        >
          <Form.Item label="运行数据">
            <Descriptions bordered>
              <Descriptions.Item label="时间">{commentItemData.reportedTime}</Descriptions.Item>
              <Descriptions.Item label="类型">{commentItemData.typeDesc}</Descriptions.Item>
              <Descriptions.Item label="车速">{formatSpeed(commentItemData)}</Descriptions.Item>
              <Descriptions.Item label="描述">{commentItemData.descText}</Descriptions.Item>
            </Descriptions>
          </Form.Item>
          <Form.Item
            label="备注"
            name="comment"
            required
            rules={[
              {required: true, message: '请输入评论'}
            ]}
            wrapperCol={{span: 24}}>
            <Input.TextArea placeholder='请输入评论'/>
          </Form.Item>
        </Modal>}
      </Form>
      {/* 视频播放的弹窗 */}
      {operateInfo.type === 'video' && <RecorderVideoPreviewModal
        url={operateInfo.data?.attachUrl}
        fileName={operateInfo.data?.reportedTime}
        closeModal={() => setOperateInfo({})}
        showTransferButton={operateInfo.data?.needTrans}
        resourceKey={operateInfo.data?.resourceKey}
        transferCallback={(url: string) => transferCallback(url)}
        needTrans={operateInfo.data?.needTrans}
        rawUrl={operateInfo.data?.rawUrl}
      />}
    </Card>
  )
}

export default AccidentReport;