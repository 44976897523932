/**
 * 需最新投保的产品弹窗
 */
import { Button, Form, Modal, message } from 'antd';
import CommonTable from '@/components/commonTable';
import { productInsureRecordProductRequestPath, productInsureRecordProductRequest, upsertProductInsureRecordProduct } from '@/service/productInsureRecord';
import ExportButton from '@/components/exportButton';
import dayjs from 'dayjs';
import QuickDatePicker from '@/components/quickDatePicker';
import { useTable } from '@/utils/useTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';


interface IndicatorDescriptionModalProps {
    closeModal: () => void; // 关闭弹窗的方法
    reloadData: () => void; // 成功后重新请求工单的方法
}

const ProductInsureModal = (props: IndicatorDescriptionModalProps) => {
    const { closeModal, reloadData } = props;
    const [form] = Form.useForm();

    const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
        requestFunc: productInsureRecordProductRequest,
        initPageSize: 40,
    });


    // 处理搜索参数 
    const operateSearchParams = (params: any, isExport?: boolean) => {
        const newSearchParams: any = { ...params }
        if (params.insureTime) {
            newSearchParams.insureTime = dayjs(params.insureTime).format('YYYY-MM-DD');
        }
        if (isExport) {
            newSearchParams.export = true;
        }
        return newSearchParams;
    }

    // 获取导出的参数
    const getExportParams = () => {
        const values = form.getFieldsValue();
        return operateSearchParams(values, true);
    }

    //确定
    const submit =async (params: any) => {
        const newSearchParams: any = { ...params }
        if (params.insureTime) {
            newSearchParams.insureTime = dayjs(params.insureTime).format('YYYY-MM-DD');
            const res = await upsertProductInsureRecordProduct(newSearchParams)
            if (res.data?.code === 0) {
                message.success('操作成功');
                closeModal()
                reloadData()
              } 
        } else {
            message.error('请选择投保时间')
        }
    }

    // 表格列显示
    const columns: any = [
        {
            title: '车牌号',
            dataIndex: 'plateNumber',
            width: 150,
            ellipsis: { showTitle: false },
        },
        {
            title: '车架号',
            dataIndex: 'frameNumber',
            width: 150,
            ellipsis: { showTitle: false },
        },
        {
            title: 'adasSN',
            dataIndex: 'adasSn',
            width: 130,
            ellipsis: { showTitle: false },
        },
        {
            title: 'aebSN',
            dataIndex: 'aebSn',
            width: 130,
            ellipsis: { showTitle: false },
        },
        {
            title: '相机SN',
            dataIndex: 'carmaSn',
            width: 130,
            ellipsis: { showTitle: false },
        },
        {
            title: '设备安装时间',
            dataIndex: 'installTime',
            width: 130,
            ellipsis: { showTitle: false },
            render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')} /> : ''
        }
    ];

    return (
        <Modal
            width={700}
            title="需最新投保的产品"
            visible
            onCancel={closeModal}
            centered
            footer={[
                <Button key="cancel" onClick={closeModal}>取消</Button>,
                <ExportButton
                    type="primary"
                    url={productInsureRecordProductRequestPath}
                    fileName='需最新投保的产品'
                    getParamsFunc={getExportParams}
                    requestMethod="GET"
                    exportSuccessFunc={closeModal}
                    permissionKey='/car-admin/accident-record/export'
                >
                    导出
                </ExportButton>,
               <Button key="ok" onClick={() => form.submit()}>确定</Button>
            ]}
        >
            <CommonTable
                rowKey={'id'}
                size='small'
                columns={columns}
                dataSource={data}
                pagination={{
                    total: totalCount,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                }}
                scroll={{ x: '100%', y: 500 }}
                bordered
                loading={loading}

            />
            <Form
                form={form}
                autoComplete="off"
                onFinish={submit}
            >
                <Form.Item
                    label="投保日期"
                    name="insureTime"
                >
                    <QuickDatePicker style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ProductInsureModal;
