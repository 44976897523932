/**
 * 历史联系结果
 */
import React, { useEffect, useState } from 'react';
import { Modal, Timeline } from 'antd';
import LoadingWrapper from '@/components/loadingWrapper';
import { serviceRecoedRequest } from '@/service/carDiagnosis';
import { formatTime } from "@/utils/formatTime";

interface VersionModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const HistoryResult = (props: VersionModalProps) => {
  const { data, closeModal } = props;
  const [loading, setLoading] = useState(false); 
  const [result, setResult] = useState<any>([]); // 工单详情

  useEffect(() => {
    getHistoryResult()
  }, []);

  const getHistoryResult = async() => {
    try {
      setLoading(true);
      const res = await serviceRecoedRequest({ taskId: data.id });
      console.log('res',res)
      setResult(res.data.data)
      setLoading(false);
    } catch (error) {
    }
  }

  return (
    <Modal
      title="历史联系结果"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      width={'520px'}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      {/* 根据设备版本显示不同的内容 */}
      <LoadingWrapper spinning={loading}>
      <Timeline style={{ marginTop: '24px' }}>
          {(result || []).map((item: any) => (
            <Timeline.Item key={item.id}>
              <div style={{display:'flex', flexDirection:'row', flexWrap:'nowrap', justifyContent: 'space-between', height:'26px', lineHeight:'26px'}}>
                <div>联系时间：{formatTime(item.createdAt)}</div>
              </div>
              <div>联系内容：{item.content}</div>
            </Timeline.Item>
          ))}
        </Timeline>
      </LoadingWrapper>
    </Modal>
  )
}

export default HistoryResult;
    