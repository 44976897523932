import { targetPositionTagSort, videoTagGroup } from '@/constants/driveVideo';
import { TagCategoryEnum } from '@/constants/tagManage';

// 分组生成行车视频标签数据
interface VideoTag { 
  label: string;
  value: number;
  tagSort: number;
}

export const getGroupVideoTag = (tagData: any, returnSelectOptions?: boolean) => {
  const resultTag: Array<VideoTag> = [];
  const effectTag: Array<VideoTag> = [];
  const rateTag: Array<VideoTag> = [];
  const timeTag: Array<VideoTag> = [];
  const weatherTag: Array<VideoTag> = [];
  const roadTag: Array<VideoTag> = [];
  const carBehaviorTag: Array<VideoTag> = [];
  const lightTag: Array<VideoTag> = [];
  const timelyTag: Array<VideoTag> = [];
  const targetTypeTag: Array<VideoTag> = [];
  const targetPositionTag: Array<VideoTag> = [];
  const targetBehaviorTag: Array<VideoTag> = [];
  tagData.forEach((item: any) => {
    const { title, id, tagSort, isDisabled } = item;
    const tag = { label: title, value: id, tagSort, disabled: isDisabled };
    if (item.tagSort === TagCategoryEnum.videoResult) {
      resultTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoEffect) {
      effectTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoRate) {
      rateTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoTime) {
      timeTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoWeather) {
      weatherTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoRoad) {
      roadTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoCarBehavior) {
      carBehaviorTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoTargetType) {
      targetTypeTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoTargetPosition) {
      targetPositionTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoTargetBehavior) {
      targetBehaviorTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoLight) {
      lightTag.push(tag);
    }
    if (item.tagSort === TagCategoryEnum.videoTimely) {
      timelyTag.push(tag);
    }
  });
  // 处理目标位置标签的显示顺序
  const newTargetPositionTag: any = [];
  targetPositionTagSort.forEach((item: number) => {
    const matchLabel = targetPositionTag.find((item1: any) => item1.value === item)?.label;
    if (matchLabel) {
      newTargetPositionTag.push({ label: matchLabel, value: item });
    }
  });
  targetPositionTag.forEach((item: any) => {
    if (!targetPositionTagSort.includes(item.value)) {
      newTargetPositionTag.push({ label: item.title, value: item });
    }
  })
  
  const groupTagData: any = {
    resultTag,
    effectTag,
    rateTag,
    timeTag,
    weatherTag,
    roadTag,
    carBehaviorTag,
    targetTypeTag,
    targetPositionTag: newTargetPositionTag,
    targetBehaviorTag,
    lightTag,
    timelyTag,
  }
  if (returnSelectOptions) {
    const groupSelectOptions = videoTagGroup.map((item: any) => ({
      ...item,
      children: groupTagData[item.field],
    }));
    return groupSelectOptions;
  } else {
    return groupTagData;
  }
}