/**
 * 诉讼记录弹窗
 */
import React, { useEffect, useState } from "react";
import { Drawer,Button} from 'antd';
import LoadingWrapper from '@/components/loadingWrapper';
import { carProceedListRequest } from "@/service/contractManage";
import { tagListRequest } from "@/service/tagManage";
import { TagCategoryEnum } from "@/constants/tagManage";
import CommonTable from "@/components/commonTable";
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import { downloadFile } from '@/utils/download';
import PdfPreviewModal from "@/components/pdfPreviewModal";
import PermissionButton from "@/components/permissionButton";
import OperateCarProceed from '../operateCarProceed'


interface DoucumentCommentDetailProps {
  item: any; // 信息
  onClose: () => void; // 关闭详情弹层的方法
}

const JudicialRecordModal = (props: DoucumentCommentDetailProps) => {
  const { item, onClose } = props;
  const [orderDetail, setOrderDetail] = useState<any>([]); // 诉讼记录详情
  const [loading, setLoading] = useState<boolean>(false) // 诉讼记录详情是否在请求中
  const [LawsuitState, setLawsuitState] = useState<any>([]); // 诉讼记录详情
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息

  useEffect(() => {
    getCarProceedDetail();
    getFuelType()
  }, []);

  // 获取诉讼记录
  const getCarProceedDetail = async () => {
    try {
      setLoading(true);
      const params: any = { no: item.no, frameNumber: item.frameNumber, caseNo: item.caseNo }
      const res = await carProceedListRequest(params);
      const data = res.data?.data || {};
      setOrderDetail(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  //获取诉讼阶段
  const getFuelType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.lawsuitState],
    });
    const tags: any = [];
    (res.data?.data || []).map((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setLawsuitState(tags);
  }

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  const columns = [
    {
      title: '案件编号',
      dataIndex: 'caseNo',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '诉讼阶段',
      dataIndex: 'state',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '处理日期',
      dataIndex: 'createdAt',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '附件',
      dataIndex: 'attachments',
      width: 280,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        value?.map((item: any, index: any) => (
          <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
            <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
            </div>
            <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                type="text"
                onClick={() => onPdfPreview(item.url, item.fileName)}
              >
                <EyeOutlined />
              </Button>
              <Button
                type="link"
                onClick={() => downloadFile(item.url, item.fileName)}
                style={{ paddingLeft: 0 }}
              >
                <DownloadOutlined />
              </Button>
            </div>
          </div>
        ))
      ),
    },
    {
      title: '操作人',
      dataIndex: 'opt',
      width: 110,
      ellipsis: { showTitle: false },
    }
  ]

  return (
    <Drawer
      title='诉讼记录'
      visible
      size="large"
      onClose={onClose}
      width={750}
    >
      <LoadingWrapper spinning={loading}>
        {/* <Button type="primary" onClick={() => showModal(item)}>新增诉讼记录</Button> */}
        <PermissionButton
          operateType="/car-admin/carproceed-recode/add"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add', data: item })}
        >
          新增诉讼记录
        </PermissionButton>
        <CommonTable
          rowKey="id"
          columns={columns}
          dataSource={orderDetail}
          bordered
          loading={loading}
        />
      </LoadingWrapper>
      {/* operateInfo */}
      {['add'].includes(operateInfo.type) &&
        <OperateCarProceed
          closeModal={() => setOperateInfo({})}
          reloadData={getCarProceedDetail}
          type={operateInfo.type}
          item={operateInfo.data}
        />
      }
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
    </Drawer>
  )
}

export default JudicialRecordModal;
