/**
 * 记录仪视频日志获取
 */
import React, { useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { isNil } from 'lodash';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { formatTime } from '@/utils/formatTime';
import { useTable } from '@/utils/useTable';
import {
  getRecorderLogRequest,
  getRecorderLogListRequest
} from '@/service/parameterConfiguration';
import styles from '@/styles/pageContent.module.scss';

interface RecorderLogModalProps {
  data: any; // 数据
  onClose: () => void; // 关闭弹窗的方法
}
const RecorderLogModal = (props: RecorderLogModalProps) => {
  const { data, onClose } = props;
  const [searchParams] = useState({}); // 搜索参数
  const {
    page,
    limit,
    loading,
    data: tableData,
    totalCount,
    onPageChange,
    getData
  } = useTable({
    requestFunc: getRecorderLogListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
    extraParams: { sn: data.sn }
  });

  // 获取记录仪日志
  const getRecorderLog = async () => {
    const res = await getRecorderLogRequest(data.sn);
    if (res.data?.code === 0) {
      message.success('获取指令下发成功，稍后可刷新日志列表查看');
      setTimeout(() => {
        getData();
      }, 1000);
    }
  };

  // 表格列配置
  const columns = [
    {
      title: '日志时间',
      dataIndex: 'targetTime',
      render: (value: string) => formatTime(value)
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 90,
      render: (value: any, item: any) => {
        if (!isNil(item.status) && item.status < 150) {
          return (
            <div className={styles.tableOperateColumn}>
              <Button type="link" href={item.resourceUrl}>
                下载
              </Button>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  ];

  return (
    <Drawer
      title="记录仪日志"
      open
      onClose={onClose}
      size="large"
      className={styles.drawerTableBox}
    >
      <div style={{ textAlign: 'right', marginBottom: '12px' }}>
        <PermissionButton
          operateType="/car-admin/parameter-configuration/shoot-log-get"
          type="primary"
          onClick={getRecorderLog}
        >
          获取日志
        </PermissionButton>
        <Button
          icon={<RedoOutlined />}
          onClick={() => getData()}
          style={{ marginLeft: '12px' }}
        >
          刷新日志列表
        </Button>
      </div>
      <CommonTable
        rowKey="id"
        columnFilter={false}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
          showQuickJumper: false
        }}
        bordered
        size="small"
        loading={loading}
      />
    </Drawer>
  );
};

export default RecorderLogModal;
