/**
 * 运营知识库相关请求
 */
import request from "@/utils/request";

export interface DocumentListRequestParams {
    page: number;
    limit: number;
    creator?:any; //创建人
    type?:any; //知识库类型
    title?:any; //标题
}

export interface DocumentCommentListRequestParams {
    documentId: any;
    page: number;
    limit: number;
}

export const documentListRequestPath = '/api/v2/document/list';

// 获取知识库数据
export const documentListRequest = (params: DocumentListRequestParams) => {
    return request.get(documentListRequestPath, {params})
}

// 添加数据
export const upsertDocument = (data: any) => {
    return request.post('/api/v2/document/upsert', data)
}

// 删除数据
export const DeleteDocument = (id: number) => {
    return request.delete(`/api/v2/document/${id}`)
}

// 查询评论
export const documentCommentListRequest = (params: DocumentCommentListRequestParams) => {
    return request.get('/api/v2/documentComment/list', { params })
}

// 添加评论
export const upsertDocumentComment = (data: any) => {
    return request.post('/api/v2/documentComment/upsert', data)
}

// 删除评论
export const deleteDocumentComment = (id: number) => {
    return request.delete(`/api/v2/documentComment/${id}`)
}

