/**
 * 报警时间范围选择弹窗
 */
import React from 'react';
import { Modal, Form } from 'antd';
import dayjs from 'dayjs';
import gcoord from 'gcoord';
import DatePicker from '@/components/datePicker';
import ExportButton from '@/components/exportButton';
import { deviceAggRequestPath } from '@/service/abnormalSummary';

const { RangePicker } = DatePicker;
interface AlarmRangeModalProps {
  closeModal: () => void; // 关闭弹窗的方法
  locationData: any; // 地图点位信息
  mapRef: any,
}
const AlarmRangeModal = (props: AlarmRangeModalProps) => {
  const { closeModal, locationData, mapRef } = props;
  const [form] = Form.useForm();

  // 获取导出的参数
  const getExportParams = async () => {
    try {
      const values = await form.validateFields();
      const bounds = mapRef.current.getBounds();
      const sw = bounds.getSouthWest(); // 获取西南角的经纬度(左下端点)
      const ne = bounds.getNorthEast(); // 获取东北角的经纬度(右上端点)
      const exportIds: Array<string> = []; // 需要导出的车辆id
      locationData.forEach((item: any) => {
        if (item.lat && item.lon) {
          const transformGcoord = gcoord.transform([item.lat, item.lon], gcoord.WGS84, gcoord.BD09);
          if(sw.lng < transformGcoord[0] && transformGcoord[0] < ne.lng && sw.lat < transformGcoord[1] && transformGcoord[1] < ne.lat) {
            exportIds.push(item.id)
          }
        }
      })
      return {
        export: true,
        beginDate: dayjs(values.time[0]).format('YYYY-MM-DD'),
        endDate: dayjs(values.time[1]).format('YYYY-MM-DD'),
        carIds: exportIds,
      }
    } catch (error) {
    }
  }

  return (
    <Modal
      title="选择报警时间范围"
      visible
      onCancel={closeModal}
      centered
      footer={[
        <ExportButton
          url={deviceAggRequestPath}
          fileName='car'
          getParamsFunc={getExportParams}
          type="primary"
          key="serach"
          exportSuccessFunc={closeModal}
          permissionKey="/car-admin/car-location/export"
        >
          查询
        </ExportButton>
      ]}
    >
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} form={form}>
        <Form.Item
          label="日期范围"
          required
          name="time"
          initialValue={[dayjs().subtract(8, 'days'), dayjs().subtract(1, 'days')]}
        >
          <RangePicker
            style={{ width: '100%' }}
            allowClear={false}
            disabledDate={(currentDate: any) => currentDate && currentDate > dayjs().subtract(1, 'days')}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AlarmRangeModal;
    