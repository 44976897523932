/**
 * 有操作权限控制的按钮(用户没有对应权限直接不显示按钮)
 */
import React from 'react';
import { Button, ButtonProps } from 'antd';
import { omit } from 'lodash';
import { getInnerPermissionEnable } from '@/utils/permission';

interface PermissionButtonProps extends ButtonProps {
  operateType?: string, // 按钮的操作类型
}
const PermissionButton = (props: PermissionButtonProps) => {
  const { operateType } = props;
  // 是否有对应操作的权限
  const hasPermission = getInnerPermissionEnable(operateType);
  const buttonOwnProps = omit(props, ['operateType']);
  if (hasPermission) {
    return (
      <Button {...buttonOwnProps} />
    )
  } else {
    return null;
  }
}

export default PermissionButton;
  