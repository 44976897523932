/**
 * 传感器方案-项目管理相关请求
 */
import request from '@/utils/request';

export interface SensorProjectListParams {
  page: number;
  limit: number;
  name?: string;
  userId?: number;
  beginTime?: string;
  endTime?: string;
}
// 获取项目管理列表
export const sensorProjectListRequestPath = '/api/v2/project/list';
export const sensorProjectListRequest = (params: SensorProjectListParams) => {
  return request.get(sensorProjectListRequestPath, {
    params
  });
};

export interface UpsertSensorProjectParams {
  name: string;
  ps?: string;
  id?: number;
}
// 添加、编辑项目
export const upsertSensorProjectRequest = (data: UpsertSensorProjectParams) => {
  return request.post('/api/v2/project/upsert', data);
};

// 删除项目
export const deleteSensorProjectRequest = (id: number) => {
  return request.delete(`/api/v2/project/${id}`);
};
