/**
 * 通过地图获取位置信息
 */
import React, { useEffect, useState, useRef } from 'react';
import { Modal, message, Input } from 'antd';
import { importAliMap } from '@/utils/map';  //引入高德地图

interface MapLocationModalProps {
  initLnglat?: any; // 地图中心的初始经纬度信息
  closeModal: () => void; // 关闭弹窗的方法
  confirmLocationFunc: (lnglat: any, address: string) => void; // 确认位置的方法
}
const { Search } = Input;
const MapLocationModal = (props: MapLocationModalProps) => {
  const { closeModal, confirmLocationFunc, initLnglat } = props;
  const mapRef = useRef<any>(null); // 地图实例
  const geocoderRef = useRef<any>(); // 地理编码工具实例
  const [lnglat, setLnglat] = useState<any>(null); // 选择的位置
  const [selectAddress, setSelectAddress] = useState<string>(''); // 选择的地址信息

  useEffect(() => {
    importAliMap(initMap);
  }, [])

  // 初始化地图
  const initMap = () => {
    try {
      // 能获取当前位置时初始地图位置
      let center = [116.397428, 39.90923];
      if (initLnglat) {
        center = [initLnglat.lng, initLnglat.lat];
      }
      //创建新窗口显示地图 对象赋值给变量 mapRef.current
      mapRef.current = new window.AMap.Map('mapLocationModal', {
        center,
        zoom: 14,
        animateEnable: false,
        WebGLParams:{
          preserveDrawingBuffer: true
        }
      });
      window.AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.MapType', 'AMap.Geocoder'], function() {
        mapRef.current.addControl(new window.AMap.ToolBar());  // 添加工具栏控件
        mapRef.current.addControl(new window.AMap.Scale());  // 添加比例尺控件
        mapRef.current.addControl(new window.AMap.MapType());  // 添加地图类型控件
        geocoderRef.current = new window.AMap.Geocoder();  // 创建地理编码插件实例
      });
      mapRef.current.on('click', mapClick); // 为地图添加点击事件监听
    } catch (error) {
      message.error('地图初始化失败');
    }
  }

  // 添加marker
  const addMarker = (lnglat: any, address: string) => {
    const marker = new window.AMap.Marker({
      position: new window.AMap.LngLat(lnglat.lng, lnglat.lat),
    });
    marker.setLabel({ content: address });
    mapRef.current.clearMap();
    mapRef.current.add(marker);
    setLnglat(lnglat);
    setSelectAddress(address);
  }

  // 输入地址进行地理位置查询
  const onLocation = (value: string) => {
    geocoderRef.current.getLocation(value, function(status: any, result: any) {
      if (status === 'complete' && result.geocodes.length) {
        const lnglat = result.geocodes[0].location;
        mapRef.current.setCenter(lnglat);
        addMarker(lnglat, value);
      } else {
        message.error('根据输入地址查询地理位置失败，可完善地址信息重新查询');
      }
    });
  }

  // 地图点击
  const mapClick = (e: any) => {
    window.AMap.plugin('AMap.Geocoder', function() {
      const geocoder = new window.AMap.Geocoder();
      geocoder.getAddress([e.lnglat.lng, e.lnglat.lat], (status: string, result: any) => {
        if (status === 'complete' && result.regeocode) {
          if (result.regeocode.addressComponent.city == '') {
            const address = result.regeocode.addressComponent.province + result.regeocode.addressComponent.district;
            addMarker(e.lnglat, address);
          } else {
            const address = result.regeocode.addressComponent.city + result.regeocode.addressComponent.district;
            addMarker(e.lnglat, address);
          }
        } else {
          message.error('选择的位置查询地址信息失败');
          setLnglat(null);
          setSelectAddress('');
        }
      });
    });
  }

  // 点击确定
  const onOk = () => {
    confirmLocationFunc(lnglat, selectAddress);
    closeModal();
  }

  return (
    <Modal
      title='安装位置'
      visible
      onCancel={closeModal}
      centered
      onOk={onOk}
      okButtonProps={{ disabled: !lnglat || !selectAddress }}
    >
      <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>可点击地图确定具体地址</div>
      <Search
        placeholder='可输入地址进行地理位置查询'
        onSearch={onLocation}
        enterButton
        style={{ marginBottom: '8px' }}
      />
      <div id="mapLocationModal" style={{ height: '400px' }} />
    </Modal>
  )
}

export default MapLocationModal;
    