/**
 * 报警监控
 */
import React, { useState, useRef } from 'react';
import { Tooltip } from 'antd';
import {
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { useWebSocket } from '@/utils/useWebSocket';
import ResizableWrapper from '@/components/resizableWrapper';
import AebAlarmTable from './aebAlarmTable';
import JtDeviceAlarmTable from './jtDeviceAlarmTable';
// import AebAbnormalTable from './aebAbnormalTable';
import LocationStatusTable from './locationStatusTable';
import OtherDataTable from './otherDataTable';
import styles from './index.module.scss';

const tabData = [
  { title: 'AEB报警', key: 'aeb' },
  { title: 'ADAS报警', key: 'adas' },
  { title: 'BSD报警', key: 'bsd' },
  { title: 'DMS报警', key: 'dms' },
  // { title: '设备异常', key: 'abnormal' },
  { title: '定位异常', key: 'location' },
  { title: '其它', key: 'other' }
];
const AlarmMonitor = ({ onOpenCallback }: { onOpenCallback: Function }) => {
  const resizableWrapperRef = useRef<any>(); // ResizableWrapper组件Ref
  const [activeKey, setActiveKey] = useState('aeb'); // 激活的key
  const [alarmMatchKeys, setAlarmMatchKeys] = useState<{
    [key: string]: boolean;
  }>({}); // 报警匹配的key
  const [height, setHeight] = useState<any>(240); // 组件DOM的高度
  const [iconTooltipOpen, setIconTooltipOpen] = useState(false); // icon的提示气泡是否显示
  const [aebData, setAebData] = useState<any>([]); // AEB报警数据
  const [adasData, setAdasData] = useState<any>([]); // ADAS报警数据
  const [bsdData, setBsdData] = useState<any>([]); // BSD报警数据
  const [dmsData, setDmsData] = useState<any>([]); // DMS报警数据
  // const [aebAbnormalData, setAebAbnormalData] = useState<any>([]); // 设备异常数据
  const [locationData, setLocationData] = useState<any>([]); // 定位状态数据
  const [otherData, setOtherData] = useState<any>([]); // 其它数据

  // 处理报警监控数据
  const operateAlarmMonitorData = (data: any) => {
    const { alarmMonitorType } = data;
    const _updateData = (updateKey: string) => {
      const updateFuncObj: { [key: string]: Function } = {
        aeb: setAebData,
        adas: setAdasData,
        bsd: setBsdData,
        dms: setDmsData,
        // abnormal: setAebAbnormalData,
        location: setLocationData,
        other: setOtherData
      };
      const updateFunc = updateFuncObj[updateKey];
      if (updateFunc) {
        // 更新表格数据(最多展示100条数据)
        updateFunc((prev: any) => {
          const newData = prev.slice(0, 100);
          newData.unshift(data);
          return newData;
        });
        // 更新tab的标红
        setAlarmMatchKeys((prev: any) => ({ ...prev, [updateKey]: true }));
      }
    };
    if (alarmMonitorType === 'jtDevice') {
      if (data?.contentMap?.adas) {
        _updateData('adas');
      } else if (data?.contentMap?.blind) {
        _updateData('bsd');
      } else if (data?.contentMap?.driver) {
        _updateData('dms');
      }
    } else {
      _updateData(alarmMonitorType);
    }
  };

  // 初始化websocket
  const { wsConnected, openWs } = useWebSocket({
    webSocketPath: '/ws/monitor',
    webSocketFlag: '车辆实时监控',
    onmessage: (data: string) => operateAlarmMonitorData(JSON.parse(data)),
    onOpenCallback
  });

  // tab点击
  const tabClick = (tabKey: string) => {
    setActiveKey(tabKey);
    setAlarmMatchKeys({ ...alarmMatchKeys, [tabKey]: false });
  };

  // 修改尺寸样式
  const changeSize = (newHeight: number) => {
    const changeSizeStyle = resizableWrapperRef.current?.changeSizeStyle;
    if (changeSizeStyle) {
      changeSizeStyle({ height: newHeight, width: '100%' });
    }
    setIconTooltipOpen(false);
    setHeight(newHeight);
  };

  return (
    <ResizableWrapper
      resizeHandleDirection="top"
      defaultSizeStyle={{ width: '100%', height: 240 }}
      minConstraints={[10, 38]}
      ref={resizableWrapperRef}
      onResizeStop={(size: { height: number }) => setHeight(size.height)}
    >
      <div className={styles.alarmMonitor}>
        <div className={styles.alarmTabs}>
          <div className={styles.alarmTabItemBox}>
            {tabData.map((item: any) => {
              const { title, key } = item;
              let className = styles.alarmTabItem;
              // 激活的tab样式
              if (activeKey === key) {
                className = styles.activeAlarmTabItem;
              }
              // 有新的报警匹配时的tab样式
              if (alarmMatchKeys?.[key]) {
                className = styles.macthAlarmTabItem;
              }
              // 激活的tab且有新的报警匹配时的tab样式
              if (activeKey === key && alarmMatchKeys?.[key]) {
                className = styles.macthActiveAlarmTabItem;
              }
              return (
                <div
                  className={className}
                  key={key}
                  onClick={() => tabClick(key)}
                >
                  {title}
                </div>
              );
            })}
          </div>
          <div className={styles.iconBox}>
            {/* WebSocket连接异常时显示提示的icon */}
            {!wsConnected && (
              <Tooltip
                placement="left"
                title="报警监控异常，点击图标可重新开始监控"
                getPopupContainer={(node: HTMLElement) => node}
              >
                <div onClick={openWs}>
                  <ExclamationCircleOutlined className="dangerText" />
                </div>
              </Tooltip>
            )}
            {/* 根据组件DOM高度判断展示收起还是展开icon */}
            <Tooltip
              placement="left"
              title={height > 150 ? '收起' : '展开'}
              open={iconTooltipOpen}
              onOpenChange={open => setIconTooltipOpen(open)}
              getPopupContainer={(node: HTMLElement) => node}
            >
              <div>
                {height > 150 ? (
                  <VerticalAlignBottomOutlined onClick={() => changeSize(38)} />
                ) : (
                  <VerticalAlignTopOutlined onClick={() => changeSize(240)} />
                )}
              </div>
            </Tooltip>
          </div>
        </div>
        {activeKey === 'aeb' && <AebAlarmTable dataSource={aebData} />}
        {activeKey === 'adas' && <JtDeviceAlarmTable dataSource={adasData} />}
        {activeKey === 'bsd' && <JtDeviceAlarmTable dataSource={bsdData} />}
        {activeKey === 'dms' && <JtDeviceAlarmTable dataSource={dmsData} />}
        {/* {activeKey === 'abnormal' && (
          <AebAbnormalTable dataSource={aebAbnormalData} />
        )} */}
        {activeKey === 'location' && (
          <LocationStatusTable dataSource={locationData} />
        )}
        {activeKey === 'other' && <OtherDataTable dataSource={otherData} />}
      </div>
    </ResizableWrapper>
  );
};

export default AlarmMonitor;
