/**
 * 客服人员管理
 */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Button, Popconfirm, Row, Select, Tag, message } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { useTable } from '@/utils/useTable';
import { customerListRequest, DeleteCustomerTask, upsertCustomer } from '@/service/serviceManage';
import { userListRequest } from '@/service/userManage';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';

const ServiceManage = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [userData, setUserData] = useState([]); // 客服人员数据
  const [form] = Form.useForm();

  useEffect(() => {
    getUserData();
  }, []);

  // 获取用户数据
  const getUserData = async() => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setUserData(res.data?.data || [])
  }

  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: customerListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    setSearchParams(newSearchParams);
  };

  // 开启接收客服任务
  const openReceiveItem = async (value:any) => {
    const params = {...value}
    params.state = 1
    const res = await upsertCustomer(params);
    if (res.data?.code === 0) {
      message.success('修改成功');
      getData();
    } 
  }

  // 关闭接收客服任务
  const closeReceiveItem = async (value:any) => {
    const params = {...value}
    params.state = 0
    const res = await upsertCustomer(params);
    if (res.data?.code === 0) {
      message.success('修改成功');
      getData();
    } 
  }

  // 删除
  const deleteItem = async (id:number) => {
    const res = await DeleteCustomerTask(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '客服',
      dataIndex: 'userName',
      width: 60,
      ellipsis: { showTitle: false },
    },
    {
      title: '是否全职客服',
      dataIndex: 'flag',
      width: 70,
      ellipsis: { showTitle: false },
      render: (value: any) => {
        return (
          value == 1 ? <div>是</div> : <div>否</div>
        )
      }
    },
    {
      title: '是否接收客服任务',
      dataIndex: 'state',
      width: 85,
      ellipsis: { showTitle: false },
      render: (value: any) => {
        return (
          value == 1 ? <Tag color="success">开启</Tag> : value == 0 ? <Tag color="error">关闭</Tag> : null
        )
      }
    },
    {
      title: '上次接收任务时间',
      dataIndex: 'day',
      width: 85,
      ellipsis: { showTitle: false },
    },
    {
      title: '上次接收任务时间接收任务数量',
      dataIndex: 'current',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '累计接收任务数量',
      dataIndex: 'total',
      width: 85,
      ellipsis: { showTitle: false },
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {item.state == 0 ? <Popconfirm
              title="您确定要开启接收客服任务吗?"
              onConfirm={() => openReceiveItem(item)}
            >
              <PermissionButton operateType='/car-admin/service-manage/open-receive' type="link">开启接收客服任务</PermissionButton>
            </Popconfirm> : item.state == 1 ?<Popconfirm
              title="您确定要关闭接收客服任务吗?"
              onConfirm={() => closeReceiveItem(item)}
            >
              <PermissionButton operateType='/car-admin/service-manage/close-receive' type="link">关闭接收客服任务</PermissionButton>
            </Popconfirm> : null}
            <PermissionButton operateType='/car-admin/service-manage/edit' type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
            <Popconfirm
              title="您确定要删除该客服吗?"
              onConfirm={() => deleteItem(item.id)}
            >
              <PermissionButton operateType='/car-admin/service-task/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item label="客服" name="id">
              <Select
                placeholder="请选择客服"
                options={userData}
                allowClear
                showArrow
                showSearch
                fieldNames={{
                  label: 'nickname',
                  value: 'id'
                }}
                optionFilterProp="nickname"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" ">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/service-manage/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data.records}
        pagination={{
          total: data.total,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default ServiceManage;
